import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  FormGroup,
} from "@angular/forms";
import { UntypedFormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import * as _moment from 'moment';
import { Moment } from "moment";
import { Month } from "./month";
import { TableUtil } from "app/blockchainImpExp/tableUtil";
import { DateAdapter } from "@angular/material/core";
// tslint:disable-next-line:no-duplicate-imports
// import {default as _rollupMoment, Moment} from 'moment';

const moment =   _moment;

var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface MonthType {
  value: string;
  viewValue: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



export interface calendarView {
  value: string;
  viewValue: string;
}


export interface PeriodicElement {
  systemId: string;
  paymentDate: string;
  headCategory: string;
  grandTotal: string;
}
export interface GroupBy {
  totalPayable: string;
  isGroupBy: boolean;
  totalAmt: string;
}

const ELEMENT_DATA: (PeriodicElement | GroupBy)[] = [
  {
    systemId: "1",
    paymentDate: "",
    headCategory: "Duties",
    grandTotal: "",
  },
  {
    systemId: "2",
    paymentDate: "",
    headCategory: "Miscennaleous",
    grandTotal: "",
  },
  {
    systemId: "3",
    paymentDate: "",
    headCategory: "PD Credit",
    grandTotal: "",
  },
  {
    systemId: "4",
    paymentDate: "",
    headCategory: "Overtime",
    grandTotal: "",
  },
  {
    systemId: "5",
    paymentDate: "",
    headCategory: "CRS",
    grandTotal: "",
  },
  { totalPayable: "Total Payable", isGroupBy: true, totalAmt: "20" },
];
const ELEMENT: (PeriodicElement | GroupBy)[] = [
  {
    systemId: "1",
    paymentDate: "",
    headCategory: "CASH",
    grandTotal: "",
  },
  {
    systemId: "2",
    paymentDate: "",
    headCategory: "DD",
    grandTotal: "",
  },
  {
    systemId: "3",
    paymentDate: "",
    headCategory: "IPO",
    grandTotal: "",
  },

  { totalPayable: "Total Payable", isGroupBy: true, totalAmt: "20" },
];
@Component({
  selector: "app-payment-daily-summary",
  templateUrl: "./payment-daily-summary.component.html",
  styleUrls: ["./payment-daily-summary.component.css"],
})
export class PaymentDailySummaryComponent implements OnInit {
  selectedStatus = "New";
  date: any;
  selectedView:any;
  
  allStatus: Status[] = [
    { value: "Incoming Date", viewValue: "Incoming Date" },
    { value: "IEC Code", viewValue: "IEC Code" },
  ];

  allCalendarView: calendarView[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Weekly", viewValue: "Weekly" },
    { value: "Monthly", viewValue: "Monthly" },
  ];

  
 

isMonthly: boolean;
isWeekly: boolean;
isDay: any;
// range = new FormGroup({
//   start: new FormControl(),
//   end: new FormControl(),
// });
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  currentUser: any;
  role: any;
  data1 = ELEMENT_DATA;
  data = ELEMENT;
  pipe = new DatePipe("en-US");

  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  displayedColumns = ["systemId", "paymentDate", "headCategory", "grandTotal"];

  cashColumns = ["cashIn", "prefilledCash", "enterNewCash", "acceptCash"];
  modifiedCurrentDate: any;
  allPaymentSummaryData: any;
  totalPayableAmt: any;
  totalPaymentAmt: any;
  challanDate: any;

  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  todayDate = this.pipe.transform(this.now, "yyyy-MM-dd", "en-US");
  isButtonVisible = false;
  first_day_of_month: any;
  last_day_of_month: any;
  currentDate = new Date();
  firstDay: Date;
  lastDay: Date;

  events: string[] = [];
  events1: string[] = [];
  datepick = true;
  monthselectedValue: any;
  public selectedDate =  new  UntypedFormControl(new  Date());
  public months : Month[];
  public selectedMonth :Month;
  showTable: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
  ) {this.dateAdapter.setLocale('en-GB');}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = this.currentUser.obj.user_type_id;
    let input = {
      challan_date: this.todayDate,
    };
    this.challanDate = this.todayDate;
    this.getPaymentDailySummaryByDate(input);
    this.loadMonths();

    // let date = new Date();
    // let year = date.getFullYear();
    // let month = date.getMonth();
    // let firstday = new Date(year,month - 1,1);
    // let lastday = new Date(year,month ,0);
    // let name=this.pipe.transform(firstday, "MMMM yy", "en-US");
    // let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
    // let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
    // let val = month;
    // if(val == 0) {
    //   val = 12
    // } else {
    //   val = val + 1;
    // }
    // let monthObj = {"name" : name, "value": val, "start": start + " 00:00:00", "end" : end+" 23:59:59"};
    // this.selectedMonth =  monthObj;
  }

  loadMonths() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months=[];
    for(let i=0; i<12; i++) {

      console.log("MOnth : ",month);
      if(month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year,month,1);
      let lastday = new Date(year,month+1,0);
      let name=this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = {"name" : name, "value": month + 1, "start": start + " 00:00:00", "end" : end+" 23:59:59"};
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
    
  }

  monthly(value,event){
    // if(event.isUserInput){
      this.showTable = true;
      this.selectedMonth = value ? value : this.selectedMonth;
      console.log(this.selectedMonth,'month');
      let input = {
        start_date: this.pipe.transform(this.selectedMonth.start, "yyyy-MM-dd", "en-US"),
        end_date: this.pipe.transform(this.selectedMonth.end, "yyyy-MM-dd", "en-US"),
      }
    
      this.challanDate = this.selectedMonth.name;

      this.getPaymentDailySummaryByDate(input);

    // }
  }

  selectCalendarView(event, value) {
    if (event.isUserInput) {
      if (value == "Monthly") {
        this.isMonthly = true;
        this.isDay = false;
        this.isWeekly = false;
        this.showTable = false;
      } else if (value == "Weekly") {
        this.isWeekly = true;
        this.isDay = false;
        this.isMonthly = false;
        this.showTable = false;
      } else {
        this.isDay = true;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  daily(){
    console.log(this.date,'daily')
  }

  weekly(){
    let input = {
      start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
      end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
    }
    console.log(input,'input')
  }

 

  compareMonths(i1,i2) {
    // return i2 && i1 && i1.value === i2.value;
    return i1 && i2 && i1.value === i2.value;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    if (this.isMonthly) {
      if (type === "change") {
        this.showTable = true;
        console.log("change event value : ", event);
        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        //const start =
        let date = new Date(this.today);
        //date = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        this.first_day_of_month = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        let currentMonth = date.getMonth();
        this.last_day_of_month = new Date(
          date.getFullYear(),
          currentMonth,
          this.daysInMonth(currentMonth + 1, date.getFullYear())
        );
        let input = {
          start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
          end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        }
        this.challanDate = input.start_date + '-' + input.end_date;

        this.getPaymentDailySummaryByDate(input);

      }
    } else {
      if (type === "change") {
        this.showTable = true;
        console.log("change event value : ", event);
        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        const start = new Date(this.today + 6 * 24 * 60 * 60 * 1000).getTime();
        const myStartDate = this.pipe.transform(start, "yyyy-MM-dd", "en-US");
        this.last6 = start;
        console.log("CurrentDate=" + myFormattedDate);
        let input = {
          end_date : this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
          start_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        }
        this.challanDate = input.start_date + '-' + input.end_date;
        this.getPaymentDailySummaryByDate(input);
        if (this.today == this.now) {
          this.isButtonVisible = false;
        } else {
          console.log("Today : ", this.today);
          console.log("Now : ", this.now);
          this.isButtonVisible = true;
        }
      }
    }
  }

  getPaymentDailySummaryByDate(input) {
    // this.challanDate = date;
   
    this.blockchainService
      .getPaymentDailySummaryByDate(input)
      .subscribe((resp) => {
        this.allPaymentSummaryData = resp.body.obj;
        console.log(this.allPaymentSummaryData);
        let totalPayableAmt, totalPaymentAmt;
        totalPayableAmt =
          // parseInt(this.allPaymentSummaryData.voluntary_payment) +
          // parseInt(this.allPaymentSummaryData.pre_deposite_for_appellate_proceedings) +
          // parseInt(this.allPaymentSummaryData.baggage_declaration) +
          // parseInt(this.allPaymentSummaryData.manual_challan) +
          // parseInt(this.allPaymentSummaryData.disposal_by_sale)+
          // parseInt(this.allPaymentSummaryData.foreign_post_office)+
          // parseInt(this.allPaymentSummaryData.other);
          parseFloat(this.allPaymentSummaryData.voluntary_payment)+
          parseFloat(this.allPaymentSummaryData.pre_deposite_for_appellate_proceedings) +
          parseFloat(this.allPaymentSummaryData.baggage_declaration) +
          parseFloat(this.allPaymentSummaryData.manual_challan) +
          parseFloat(this.allPaymentSummaryData.disposal_by_sale) +
          parseFloat(this.allPaymentSummaryData.foreign_post_office) +
          parseFloat(this.allPaymentSummaryData.other);

          this.totalPayableAmt = parseFloat(totalPayableAmt).toFixed(2);

        totalPaymentAmt =
          // parseInt(this.allPaymentSummaryData.dd_total) +
          // parseInt(this.allPaymentSummaryData.cash_total) +
          // parseInt(this.allPaymentSummaryData.ipo_total);
          parseFloat(this.allPaymentSummaryData.dd_total) +
          parseFloat(this.allPaymentSummaryData.cash_total) +
          parseFloat(this.allPaymentSummaryData.ipo_total) +
          parseFloat(this.allPaymentSummaryData.sb_collect_facility_total);
          this.totalPaymentAmt = parseFloat(totalPaymentAmt).toFixed(2);
       
      });
  }

  caseDetailsDialogue(data) {
    localStorage.setItem("casedetailsData", JSON.stringify(data));
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.showTable = true;
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    console.log("selected Date=" + this.date);
    this.challanDate = this.pipe.transform(this.date, "dd-MM-yyyy");
    let input = {
      start_date : this.date,
      end_date : this.date 
    }
    this.getPaymentDailySummaryByDate(input);
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  exportNormalTable() {
    TableUtil.exportTableToExcel("ExampleNormalTable");
  }

  reportGeneration() { 
    var displayedCol = []
    displayedCol.push('Date');
    displayedCol.push('Payment Category');
    displayedCol.push('Grand Total');
    let newSummaryData = []
    newSummaryData.push(this.allPaymentSummaryData)
  var tableObj = newSummaryData;
    tableObj.forEach(element => { 
      element['Date'] = element.paymentDate;
      element['Payment Category'] = element.headCategory;
      element['Grand Total'] = element.grandTotal;
    });
    this.downloadFile(
      tableObj,
      'Payment Summary Report' + ' ' + this.todayDate,
      displayedCol
    );
}

downloadFile(data, filename = "data", headers) {
  let csvData = this.ConvertToCSV(data, headers);
  console.log(csvData);
  let blob = new Blob(["\ufeff" + csvData], {
    type: "text/csv;charset=utf-8;",
  });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1;
  if (isSafariBrowser) {
    //if Safari open in new window to save file with random filename.
    dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray, headerList) {
  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  let row = "Sr.No,";

  for (let index in headerList) {
    row += headerList[index] + ",";
  }
  row = row.slice(0, -1);
  str += row + "\r\n";
  for (let i = 0; i < array.length; i++) {
    let line = i + 1 + "";
    for (let index in headerList) {
      let head = headerList[index];

      line += "," + array[i][head];
    }
    str += line + "\r\n";
  }
  return str;
}



}
