import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockchainService } from 'app/blockchainImpExp/blockchain.service';



export interface GreenNote {
 
   noteDate: string;
   name:string;
   designation:string;
   remarks:string;
 }
 export interface LetterType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-processletter',
  templateUrl: './processletter.component.html',
  styleUrls: ['./processletter.component.css']
})
export class ProcessletterComponent implements OnInit {
  pipe = new DatePipe("en-US");
  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  todayDate = this.pipe.transform(this.now, "yyyy-MM-dd", "en-US");

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public blockchainService: BlockchainService) { }

  ngOnInit(): void {
  }

}
