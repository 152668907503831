import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockchainService } from 'app/blockchainImpExp/blockchain.service';

@Component({
  selector: 'app-autherization-request',
  templateUrl: './autherization-request.component.html',
  styleUrls: ['./autherization-request.component.css']
})
export class AutherizationRequestComponent implements OnInit {
  currentUser: any;
  iec: any;
  message:string;
  dataSource: any;
  isSubmission:boolean=false;
  cancellation_id: any;
  cancellationPdf: any;

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.cancellation_id =  JSON.parse(localStorage.getItem("cancellation_id"));
    this.iec = JSON.parse(localStorage.getItem("iec_code"));
    this.isSubmission=false;
    // this.getIecHolderByIecCode(this.iec);
    if(this.currentUser.obj.role=="3" 
    || this.currentUser.obj.user_id=="30"
    || this.currentUser.obj.user_id=="1882"
    || this.currentUser.obj.user_id=="1883"
    || this.currentUser.obj.user_id=="1884"
    || this.currentUser.obj.user_id=="1885"
    || this.currentUser.obj.user_id=="1886"
    || this.currentUser.obj.user_id=="1887"
    ){
      this.isSubmission=true;
      this.download();
    }else{
      this.isSubmission=false;
    }

  }

  getIecHolderByIecCode(input){
    this.isSubmission=false;
    let iec = {
      iec_code:input
    }
    this.blockchainService.getIecHolderByIecCode(iec).subscribe(resp => {
      this.dataSource=resp;
      console.log(this.dataSource)
     if(resp.obj!=null){
      this.download();
      setTimeout(() => {
        this.isSubmission=true;
      }, 4000);
     }
    })
  }


  back(){
    this.router.navigate(["/cancelDetails"])
  }

  download(){
    let input={
      cancellation_id:this.cancellation_id
    }
    this.blockchainService.getCancellationPdfByCancellationId(input).subscribe(
      (resp) => {
        console.log(resp.obj);
        let Pdf=resp.obj;
        this.cancellationPdf = Pdf +'/'+ this.currentUser.obj.access_token;
      });
  }

  // next(){
  //   this.router.navigate(["/appSummary"])
  // }


}
