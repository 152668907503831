import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface RegisterApplication {
  doc_name: string;
  viewPdf: string;
  received_status: string;
  remarks: string;
  application_id:string;
  cancellation_id:string;
}

@Component({
  selector: 'app-cancel-doc-return',
  templateUrl: './cancel-doc-return.component.html',
  styleUrls: ['./cancel-doc-return.component.css']
})
export class CancelDocReturnComponent implements OnInit {
  selectedStatus = "";

  allStatus: Status[] = [
    { value: "0", viewValue: "YES" },
    { value: "1", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  regs:any;

  displayedColumns = [
    "no",
    "doc_name",
    "viewPdf",
    "received_status",
    "remarks",
  ];
  
  displayedColumns1 = [
    "no",
    "doc_name",
    "viewPdf",
    "receivedDoc",
    "remarks",
  ];
  autoFillForm = this.formBuilder.group({
    name: [""],
    section:[""],
    designation: [""],
  });

  caseDetailsFillForm = this.formBuilder.group({
    impexpname: [""],
    iecCode: [""],
    inititionDate: [""],
  });

  

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  register: RegisterApplication[]=[];
  cancel:RegisterApplication[]=[];
  process: any;
  rdm: UntypedFormArray = this.formBuilder.array([]);
  rdm1: UntypedFormArray = this.formBuilder.array([]);
  registerDocuments: UntypedFormGroup = this.formBuilder.group({ rdm_master: this.rdm });
  cancelDocuments: UntypedFormGroup = this.formBuilder.group({ rdm_master1: this.rdm1 });
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));
    this.setFormValue();
    this.process = JSON.parse(localStorage.getItem("process"));
    this.getCancellationReturnDetailsByCancellationId(this.caseDetailsData.cancellation_id,this.currentUser.obj.user_id)
    
  }

  setFormValue(){
    this.autoFillForm.setValue({
      name: this.caseDetailsData.officer_name,
      designation: null,
      section:null,
    });
    this.caseDetailsFillForm.setValue({
      impexpname: this.caseDetailsData.imp_exp_name,
      iecCode: this.caseDetailsData.iec_code,
      inititionDate: this.caseDetailsData.completion_date
    });

  }
  
  getCancellationReturnDetailsByCancellationId(input1,input2){
    let application = {
      cancellation_id: input1,
      user_id:input2
   }
   this.blockchainService.getCancellationReturnDetailsByCancellationId(application).subscribe(
     (resp) => {
       console.log(resp.body.obj);
        this.regs = resp.body.obj;
        this.register = [
          {
          doc_name: "IEC COPY",
          viewPdf:this.regs.iec_letter_path,
          received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id 
        },
        {
          doc_name: "ADVANCED AUTHORIZATION",
          viewPdf: this.regs.advanced_authorization_path,
          received_status: "",
          
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id 
        },
        {
          doc_name: "BOND",
          viewPdf: this.regs.bond_path,
          received_status: "",
         
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id 
          
        },
        {
        
          doc_name: "STAR EXPORT HOUSE COPY",
          viewPdf: this.regs.start_export_house_copy_path,
          received_status: "",
          
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
         
        },
        {
          
          doc_name: "BANK GUARANTEE",
          viewPdf: this.regs.bank_guarantee_path,
          received_status: "",
         
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        
        },
        {
          
          doc_name: "GST REGISTRATION COPY",
          viewPdf: this.regs.gst_registration_path,
          received_status: "",
          
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
          
        },
        {
          
          doc_name: "LICENCE SCRIPT",
          viewPdf: this.regs.license_script_path,
          received_status: "",
          
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
      
        {
          
          doc_name: "POWER OF ATTORNEY",
          viewPdf: this.regs.power_of_attorney_path,
          received_status: "",
          
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
         
        },
        {
          
          doc_name: "AFFADAVIT",
          viewPdf: this.regs.affadavit_path,
          received_status: "",
          
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
          
        },
        {
          
          doc_name: "RCMC",
          viewPdf:  this.regs.rcmc_path,
          received_status: "",
          
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
          
        },
        {
          
          doc_name: "TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)",
          viewPdf: this.regs.turnover_certificate_path,
          received_status: "",
          
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
         
          doc_name: "IDENTITY PROOF - POA",
          viewPdf: this.regs.identity_proof_path,
          received_status: "",
         
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
         
          doc_name: "EXEMPTION SUPPORTING DOCUMENT",
          viewPdf: this.regs.exception_supporting_document,
          received_status: "",
         
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
      ];

       this.cancel=[
        {
          doc_name: "APPLICATION LETTER",
          viewPdf:this.regs.application_letter_path,
          received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        }, 
        // {
        //   doc_name: "IEC LETTER",
        //   viewPdf:this.regs.iec_letter_path,
        //   received_status: "",
        //   remarks: "",
        //   application_id:"0",
        //   cancellation_id:this.caseDetailsData.cancellation_id
        // },
        {
          doc_name: "IEC AUTHORIZATION LETTER",
          viewPdf: this.regs.cancellation_iec_auth,
          received_status: "",
          remarks: "",
          application_id: "0",
          cancellation_id: this.caseDetailsData.cancellation_id
        },
        {
          doc_name: "DGFT EODC CERTIFICATE",
          viewPdf: this.regs.export_obligation_discharge_certificate_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          doc_name: "SURRENDER LETTER",
          viewPdf: this.regs.surrender_letter,
          received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
        
          doc_name: "REGULARIZATION LETTER",
          viewPdf: this.regs.regularization_letter,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "LIST OF OTHER DOCUMENTS BEING UPLOADED ",
          viewPdf: this.regs.list_of_other_documents_being_uploaded_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "OTHER DOCUMENTS 1",
          viewPdf: this.regs.other_documents1_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "OTHER DOCUMENTS 2",
          viewPdf: this.regs.other_documents2_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "OTHER DOCUMENTS 3",
          viewPdf: this.regs.other_documents3_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "OTHER DOCUMENTS 4",
          viewPdf: this.regs.other_documents4_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "OTHER DOCUMENTS 5",
          viewPdf: this.regs.other_documents5_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "OTHER DOCUMENTS 6",
          viewPdf: this.regs.other_documents6_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "OTHER DOCUMENTS 7",
          viewPdf: this.regs.other_documents7_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "OTHER DOCUMENTS 8",
          viewPdf: this.regs.other_documents8_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "OTHER DOCUMENTS 9",
          viewPdf: this.regs.other_documents9_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "OTHER DOCUMENTS 10",
          viewPdf: this.regs.other_documents10_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "EXCEL SHEET 1",
          viewPdf: this.regs.excel_sheet1_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "EXCEL SHEET 2",
          viewPdf: this.regs.excel_sheet2_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "EXCEL SHEET 3",
          viewPdf: this.regs.excel_sheet3_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "EXCEL SHEET 4",
          viewPdf: this.regs.excel_sheet4_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "EXCEL SHEET 5",
          viewPdf: this.regs.excel_sheet5_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "EXCEL SHEET 6",
          viewPdf: this.regs.excel_sheet6_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "EXCEL SHEET 7",
          viewPdf: this.regs.excel_sheet7_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "EXCEL SHEET 8",
          viewPdf: this.regs.excel_sheet8_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "EXCEL SHEET 9",
          viewPdf: this.regs.excel_sheet9_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
        {
          
          doc_name: "EXCEL SHEET 10",
          viewPdf: this.regs.excel_sheet10_path,
         received_status: "",
          remarks: "",
          application_id:"0",
          cancellation_id:this.caseDetailsData.cancellation_id
        },
      
      ];

      this.registerDocuments=this.formBuilder.group({
        rdm: this.formBuilder.array([
         this.register.forEach(abc=>{
           const newRow = this.formBuilder.group({   
             doc_name:[abc.doc_name],
             received_status:[abc.received_status],
             remarks:[abc.remarks],
             application_id:[abc.application_id],
             cancellation_id:[abc.cancellation_id],
             return_status: 1
           })  
           
           this.rdm.push(newRow);
         })
       ]),
       rdm1: this.formBuilder.array([
        this.cancel.forEach(abc=>{
          const newRow1 = this.formBuilder.group({   
            doc_name:[abc.doc_name],
            received_status:[abc.received_status],
            remarks:[abc.remarks],
            application_id:[abc.application_id],
            cancellation_id:[abc.cancellation_id],
            return_status: 1
          })  
          
          this.rdm1.push(newRow1);
          this.rdm.push(newRow1);
        })
      ])
      });
     });
  }

  saveCancelApplication(rdm,rdm1){
    console.log(rdm)
    let input = {
      rdm : rdm.value
    }
    this.blockchainService.saveRegistrationDocument(input).subscribe(
      (resp) => {
        console.log(resp);
        if(resp.body.status==200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
          
        }else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
          
        }
      })

  }



  navigateToRegister() {
    this.router.navigate(["/registerTask"]);
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  statusFn(e, status) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }
  
  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
