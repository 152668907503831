import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { UntypedFormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { Month } from "../payment-daily-summary/month";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface PeriodicElement {
  no:string;
  challanNo:string;
  challanReceipt:string;
  cd:string;
  add:string;
  cess:string;
  igst:string;
  dutiesOthers:string;
  int:string;
  rf:string;
  pp:string;
  rdrbk:string;
  rs:string;
  irs:string;
  meis:string;
  amd:string;
  levy:string;
  bs:string;
  canc:string;
  br:string;
  bft:string;
  cestat:string;
  miscOthers:string;
  pacd:string;
  ot:string;
  crs:string;
  ga:string;
  dd:string;
  cash:string;
  ipo:string;
}

export interface GroupBy {
  totalPayable: string;
  isGroupBy: boolean;
  totalAmt:string;
}

export interface MonthType {
  value: string;
  viewValue: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



export interface calendarView {
  value: string;
  viewValue: string;
}

const ELEMENT_DATA: (PeriodicElement | GroupBy)[] = [
  
  {
    no:'1',
    challanNo:"12345",
    challanReceipt:"",
    cd:"123",
    add:"45",
    cess:"123456",
    igst:"23456",
    dutiesOthers:"567",
    int:"67",
    rf:"87",
    pp:"8",
    rdrbk:"675",
    rs:"98",
    irs:"980",
    meis:"5678",
    amd:"97860",
    levy:"6789",
    bs:"4567",
    canc:"456788",
    br:"8964",
    bft:"3456",
    cestat:"86544",
    miscOthers:"6789",
    pacd:"785",
    ot:"3455",
    crs:"7665",
    ga:"6874",
    dd:"7444",
    cash:"6878",
    ipo:"6974",
  },
  {
    no:'2',
    challanNo:"",
    challanReceipt:"",
    cd:"",
    add:"",
    cess:"",
    igst:"",
    dutiesOthers:"",
    int:"",
    rf:"",
    pp:"",
    rdrbk:"",
    rs:"",
    irs:"",
    meis:"",
    amd:"",
    levy:"",
    bs:"",
    canc:"",
    br:"",
    bft:"",
    cestat:"",
    miscOthers:"",
    pacd:"",
    ot:"",
    crs:"",
    ga:"",
    dd:"",
    cash:"",
    ipo:"",
  },

  
];


@Component({
  selector: 'app-payment-daily-mis',
  templateUrl: './payment-daily-mis.component.html',
  styleUrls: ['./payment-daily-mis.component.css']
})
export class PaymentDailyMISComponent implements OnInit {
  selectedStatus = 'New';
  pipe = new DatePipe("en-US");
  allStatus: Status[] = [
    {value: 'Incoming Date', viewValue: 'Incoming Date'},
    {value: 'IEC Code', viewValue: 'IEC Code'},
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  currentUser: any;
  role: any;
  dataSource = ELEMENT_DATA;

  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  displayedColumns = [
    'no',
    'challanNo',
    'challanReceipt',
    'cd',
    'add',
    'cess',
    'igst',
    'dutiesOthers',
    'int',
    'rf',
    'pp',
    'rdrbk',
    'rs',
    'irs',
    'meis',
    'amd',
    'levy',
    'bs',
    'canc',
    'br',
    'bft',
    'cestat',
    'miscOthers',
    'pacd',
    'ot',
    'crs',
    'ga',
    'dd',
    'cash',
    'ipo'
  ];
  date: any;
  modifiedCurrentDate: any;
  allPaymentSummary: any;
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");

  selectedView:any;

  allCalendarView: calendarView[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Weekly", viewValue: "Weekly" },
    { value: "Monthly", viewValue: "Monthly" },
  ];
 
  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  isButtonVisible = false;
  first_day_of_month: any;
  last_day_of_month: any;
  currentDate = new Date();
  firstDay: Date;
  lastDay: Date;

  events: string[] = [];
  events1: string[] = [];
  datepick = true;
  monthselectedValue: any;
  public selectedDate =  new  UntypedFormControl(new  Date());
  public months : Month[];
  public selectedMonth :Month;
  isMonthly: boolean;
  isDay: boolean;
  isWeekly: boolean;
  showTable: boolean;

  constructor( private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = this.currentUser.obj.user_type_id;
    this.loadMonths();
  }

  getPaymentDailySummaryMis(input){
    this.blockchainService.getPaymentDailySummaryMis(input).subscribe(resp => {
      this.allPaymentSummary = resp.body.obj;
    })
  }
  
  caseDetailsDialogue(data){
    localStorage.setItem('casedetailsData',JSON.stringify(data));
  }
  
  isGroup(index, item): boolean{
    return item.isGroupBy;
  }
  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    let input = {
      start_date : this.date ? this.date : this.todayDate,
      end_date : this.date ? this.date : this.todayDate
    }
    this.showTable = true;
    this.getPaymentDailySummaryMis(input);
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  loadMonths() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months=[];
    for(let i=0; i<12; i++) {

      console.log("MOnth : ",month);
      if(month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year,month,1);
      let lastday = new Date(year,month+1,0);
      let name=this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = {"name" : name, "value": month + 1, "start": start + " 00:00:00", "end" : end+" 23:59:59"};
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
    
  }

  monthly(value,event){
    if(event.isUserInput){
      this.showTable = true;
      this.selectedMonth = value ? value : this.selectedMonth;
      let input = {
        start_date: this.pipe.transform(this.selectedMonth.start, "yyyy-MM-dd", "en-US"),
        end_date: this.pipe.transform(this.selectedMonth.end, "yyyy-MM-dd", "en-US"),
      }
      this.getPaymentDailySummaryMis(input);

    }
  }

  selectCalendarView(event, value) {
    if (event.isUserInput) {
      if (value == "Monthly") {
        this.isMonthly = true;
        this.isDay = false;
        this.isWeekly = false;
        this.showTable = false;
      } else if (value == "Weekly") {
        this.isWeekly = true;
        this.isDay = false;
        this.isMonthly = false;
        this.showTable = false;
      } else {
        this.isDay = true;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  compareMonths(i1,i2) {
    // return i2 && i1 && i1.value === i2.value;
    return i1 && i2 && i1.value === i2.value;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    if (this.isMonthly) {
      if (type === "change") {
        this.showTable = true;

        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        //const start =
        let date = new Date(this.today);
        //date = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        this.first_day_of_month = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        let currentMonth = date.getMonth();
        this.last_day_of_month = new Date(
          date.getFullYear(),
          currentMonth,
          this.daysInMonth(currentMonth + 1, date.getFullYear())
        );
        let input = {
          start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
          end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        }
        this.getPaymentDailySummaryMis(input);

      }
    } else {
      if (type === "change") {
        this.showTable = true;
        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        const start = new Date(this.today + 6 * 24 * 60 * 60 * 1000).getTime();
        const myStartDate = this.pipe.transform(start, "yyyy-MM-dd", "en-US");
        this.last6 = start;
        let input = {
          end_date : this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
          start_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        }
        this.getPaymentDailySummaryMis(input);

        if (this.today == this.now) {
          this.isButtonVisible = false;
        } else {
      
          this.isButtonVisible = true;
        }
      }
    }
  }

  reportGeneration() { 
    var displayedCol = []
    displayedCol.push('Challan No');
    displayedCol.push('Challan Receipt Date');
    displayedCol.push('Duties-CD');
    displayedCol.push('Duties-ADD');
    displayedCol.push('Duties-CESS');
    displayedCol.push('Duties-IGST');
    displayedCol.push('Duties-OTHERS');
    displayedCol.push('Miscellaneous-INT');
    displayedCol.push('Miscellaneous-RF');
    displayedCol.push('Miscellaneous-PP');
    displayedCol.push('Miscellaneous-RDRBK');
    displayedCol.push('Miscellaneous-RS');
    displayedCol.push('Miscellaneous-IRS');
    displayedCol.push('Miscellaneous-MEIS');
    displayedCol.push('Miscellaneous-AMD');
    displayedCol.push('Miscellaneous-LEVY');
    displayedCol.push('Miscellaneous-BS');
    displayedCol.push('Miscellaneous-CANC');
    displayedCol.push('Miscellaneous-BR');
    displayedCol.push('Miscellaneous-BTF');
    displayedCol.push('Miscellaneous-CESTAT');
    displayedCol.push('Miscellaneous-OTHERS');
    displayedCol.push('Others-PACD');
    displayedCol.push('Others-OT');
    displayedCol.push('Others-CRS');
    displayedCol.push('GA');
    displayedCol.push('DD Total');
    displayedCol.push('CASH Total');
    displayedCol.push('IPO Total');

  var tableObj = this.allPaymentSummary;
  console.log(tableObj,'tableobj')
    tableObj.forEach(element => {
      element['Challan No'] = element.challan_no;
      element['Challan Receipt Date'] = element.approval_date;
      element['Duties-CD'] = element.cd;
      element['Duties-ADD'] = element.add;
      element['Duties-CESS'] = element.cess;
      element['Duties-IGST'] = element.igst;
      element['Duties-OTHERS'] = element.duty_OTHERS;
      element['Miscellaneous-INT'] = element.int;
      element['Miscellaneous-RF'] = element.rf;
      element['Miscellaneous-PP'] = element.pp;
      element['Miscellaneous-RDRBK'] = element.rdrbk;
      element['Miscellaneous-RS'] = element.rs;
      element['Miscellaneous-IRS'] = element.irs;
      element['Miscellaneous-MEIS'] = element.meis;
      element['Miscellaneous-AMD'] = element.amd;
      element['Miscellaneous-LEVY'] = element.levy;
      element['Miscellaneous-BS'] = element.bs;
      element['Miscellaneous-CANC'] = element.canc;
      element['Miscellaneous-BR'] = element.br;
      element['Miscellaneous-BTF'] = element.btf;
      element['Miscellaneous-CESTAT'] = element.cestat;
      element['Miscellaneous-OTHERS'] = element.misc_OTHERS;
      element['Others-PACD'] = element.pacd;
      element['Others-OT'] = element.ot;
      element['Others-CRS'] = element.crs;
      element['GA'] = element.ga;
      element['DD Total'] = element.dd;
      element['CASH Total'] = element.cash;
      element['IPO Total'] = element.ipo;


    });
    this.downloadFile(
      tableObj,
      'Payment MIS Report' + ' ' + this.todayDate,
      displayedCol
    );
}

downloadFile(data, filename = "data", headers) {
  let csvData = this.ConvertToCSV(data, headers);
  console.log(csvData);
  let blob = new Blob(["\ufeff" + csvData], {
    type: "text/csv;charset=utf-8;",
  });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1;
  if (isSafariBrowser) {
    //if Safari open in new window to save file with random filename.
    dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray, headerList) {
  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  let row = "Sr.No,";

  for (let index in headerList) {
    row += headerList[index] + ",";
  }
  row = row.slice(0, -1);
  str += row + "\r\n";
  for (let i = 0; i < array.length; i++) {
    let line = i + 1 + "";
    for (let index in headerList) {
      let head = headerList[index];

      line += "," + array[i][head];
    }
    str += line + "\r\n";
  }
  return str;
}
}
