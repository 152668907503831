import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockchainService } from 'app/blockchainImpExp/blockchain.service';
import { AlertService } from "app/_services";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { is } from "date-fns/locale";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaymentsumComponent } from 'app/blockchainImpExp/imp_exp/payment/paymentsum/paymentsum.component';
import { DatePipe } from '@angular/common';
declare var $: any;
export interface GreenNote {
 
  noteDate: string;
  name:string;
  designation:string;
  remarks:string;
}
export interface Summary {
  applicationSummary: string;
  details: string;
}
export interface Documents {
  document: string;
  pdf: string;
  remarks?:boolean;
}
@Component({
  selector: 'app-green-sheet',
  templateUrl: './green-sheet.component.html',
  styleUrls: ['./green-sheet.component.css']
})
export class GreenSheetComponent implements OnInit {
  viewCaseData: any;
  currentUser: any;
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  greenNote: any;
  remarksByLetter: any;
  data: Summary[] = [];
  data1: Summary[] = [];
  documentData: Documents[] = [];

  displayedColumns = [
    'applicationSummary',
    'details',
  ];
  displayedColumns1 = [
    'document',
    'pdf',
    'aiInput',
  ];
  displayedColumns2 = [
    'document',
    'pdf',
  ];

  displayedColumns3 = [
    'data',
    'details',
  ];
// registration remarks
  iec_remarks:boolean=false;
  iec_authorityRemarks:boolean = false;
  epcg_remarks=false;
  aa_remarks:boolean=false;
  bond_remarks:boolean=false;
  star_remarks:boolean=false;
  bg_remarks:boolean=false;
  gst_remarks:boolean=false;
  ls_remarks:boolean=false;
  poa_remarks:boolean=false;
  aff_remarks:boolean=false;
  rcmc_remarks:boolean=false;
  tc_remarks:boolean=false;
  ipod_remarks:boolean=false;
  iec_authority_remarks:boolean= false;
  any_other_document_remarks: boolean=false;
  noBg_doc:boolean=false;
  summary: any;
  process: any;
 
  remarkDisable: any;
  disRemarks: boolean;
  pipe = new DatePipe("en-US");
  searchCaseFlag: string;
  searchCaseData: any;
  appId: any;
 
  constructor( private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,private alertService: AlertService) { }

  ngOnInit(): void {
    localStorage.removeItem('process');
    // localStorage.removeItem('casedetailsData');
    localStorage.setItem('casedetailsData', null);
    this.viewCaseData = JSON.parse(localStorage.getItem("searchCaseData"));
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.searchCaseFlag = localStorage.getItem("searchCase");
    if(this.searchCaseFlag=="DataId"){
      this.searchCaseData = JSON.parse(localStorage.getItem("searchCaseDataId"));
      this.appId=this.searchCaseData;
    }else{
      this.searchCaseData = JSON.parse(localStorage.getItem("searchCaseData"));
       this.appId = this.searchCaseData.am.application_id;

    }
    this.remarksData();
    this.getGreenSheetByApplicationId(this.appId);
    this.getApplicationSummaryByApplicationId(this.appId, this.currentUser.obj.user_id);
  }
  back(){
    this.router.navigate(['/blockData']);
  }
  getGreenSheetByApplicationId(input){
    let application = {
      application_id: input
   }
   this.blockchainService.getGreenSheetByApplicationId(application).subscribe(
     (resp) => {
      //  console.log(resp.body.obj);
        this.greenNote = resp.body.obj;
     }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        location.reload();
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
     
    });
  }

  getApplicationSummaryByApplicationId(input1, input2) {
    this.data = [];
    this.documentData = [];
    let application = {
      application_id: input1,
      user_id: input2
    }

    this.blockchainService.getApplicationSummaryByApplicationIdSearchCase(application).subscribe(
      (resp) => {
        this.summary = resp.body.obj;

          this.data = [
            {
              applicationSummary: 'COMPANY NAME',
              details: this.summary.name,
            },
            {
              applicationSummary: 'IEC NO',
              details: this.summary.iec_code,
            },
            {
              applicationSummary: 'CUSTOMS BROKER NAME',
              details: this.summary.customs_broker_name,
            },
            {
              applicationSummary: 'CUSTOMS BROKER REGISTRATION ID',
              details: this.summary.customs_broker_registration_no,
            },
            {
              applicationSummary: 'ADVANCED AUTHORIZATION NO',
              details: this.summary.advanced_authorization_no,
            },
            {
              applicationSummary: 'ADVANCED AUTHORIZATION DATE',
              details: this.pipe.transform(this.summary.advanced_authorization_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'BOND AMOUNT IN INR',
              details: this.summary.bond_amount_inr,
            },
            {
              applicationSummary: 'FILE NO',
              details: this.summary.file_no,
            },
        
            {
              applicationSummary: 'FILE ISSUE DATE',
              details: this.pipe.transform(this.summary.file_issue_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'BOND NO',
              details: this.summary.bond_no,
            },
            {
              applicationSummary: 'FILE STORAGE LOCATION AT BBG CELL',
              details: this.summary.bbg_cell_location,
            },
          ]
        
        // console.log("data",this.data);
        
          this.documentData = [
            {
              document: 'IEC COPY',
              pdf: this.summary.iec_copy_path,
              remarks:this.iec_remarks
             
            },
            {
              document: 'ADVANCED AUTHORIZATION',
              pdf: this.summary.advanced_authorization_path,
              remarks:this.aa_remarks
            },
            {
              document: 'BOND',
              pdf: this.summary.bond_path,
              remarks:this.bond_remarks
            },
            {
              document: 'STAR EXPORT HOUSE COPY',
              pdf: this.summary.start_export_house_copy_path,
              remarks:this.star_remarks
            },
            {
              document: 'BANK GUARANTEE',
              pdf: this.summary.bank_guarantee_path,
              remarks:this.bg_remarks
            },
            {
              document: 'GST REGISTRATION COPY',
              pdf: this.summary.gst_registration_path,
              remarks:this.gst_remarks
            },
            {
              document: 'LICENCE SCRIPT',
              pdf: this.summary.license_script_path,
              remarks:this.ls_remarks
            },
            {
              document: 'POWER OF ATTORNEY',
              pdf: this.summary.power_of_attorney_path,
              remarks:this.poa_remarks
            },
            {
              document: 'AFFADAVIT',
              pdf: this.summary.affadavit_path,
              remarks:this.aff_remarks
            },
            {
              document: 'RCMC',
              pdf: this.summary.rcmc_path,
              remarks:this.rcmc_remarks
            },
            {
              document: 'TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)',
              pdf: this.summary.turnover_certificate_path,
              remarks:this.tc_remarks
            },
            {
              document: 'IDENTITY PROOF - POA',
              pdf: this.summary.identity_proof_path,
              remarks:this.ipod_remarks
            },
            {
              document: 'IEC HOLDERS AUTHORITY LETTER',
              pdf: this.summary.iec_authority_letter_path,
              remarks:this.iec_authority_remarks
            },
            {
              document: 'ANY OTHER DOCUMENT',
              pdf: this.summary.any_other_document,
              remarks:this.any_other_document_remarks
            },
            {
              document: "BG EXEMPTION SUPPORTING DOCUMENT",
              pdf: this.summary.no_bg_doc,
              remarks: this.noBg_doc,
            }

          ]
        

       
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }


  remarksData() {

      let application = {
         application_id: this.appId
      }
      this.blockchainService.getRegistrationDocumentByApplicationId(application).subscribe(
        (resp) => {
          if (resp.body.obj == null) {
            this.disRemarks = true;
          } else {
            this.remarksByLetter = resp.body.obj;
            this.remarksByLetter.forEach(element => {
              if(element.doc_name=="IEC COPY"){
                if(element.remarks!=null){
                  this.iec_remarks=true
                }
                
              }else if(element.doc_name=="IEC AUTHORIZATION LETTER"){
                if(element.remarks!=null){
                  this.iec_authorityRemarks=true
                }
                
              }
              else if(element.doc_name=="ADVANCED AUTHORIZATION"){
                if(element.remarks!=null){
                  this.aa_remarks=true
                }
                
              }
              else if(element.doc_name=="BOND"){
                if(element.remarks!=null){
                  this.bond_remarks=true
                }
              }
              else if(element.doc_name=="STAR EXPORT HOUSE COPY"){
                if(element.remarks!=null){
                  this.star_remarks=true
                }
              }
              else if(element.doc_name=="BANK GUARANTEE"){
                if(element.remarks!=null){
                  this.bg_remarks=true
                }
              }
              else if(element.doc_name=="GST REGISTRATION COPY"){
                if(element.remarks!=null){
                  this.gst_remarks=true
                }
              }
              else if(element.doc_name=="LICENCE SCRIPT"){
                if(element.remarks!=null){
                  this.ls_remarks=true
                }
              }
              else if(element.doc_name=="POWER OF ATTORNEY"){
                if(element.remarks!=null){
                  this.poa_remarks=true
                }
              }
               else if(element.doc_name=="AFFADAVIT"){
                if(element.remarks!=null){
                  this.aff_remarks=true
                }
              }
              else if(element.doc_name=="RCMC"){
                if(element.remarks!=null){
                  this.rcmc_remarks=true
                }
              }
              else if(element.doc_name=="TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)"){
                if(element.remarks!=null){
                  this.tc_remarks=true
                }
              }
              else if(element.doc_name=="IDENTITY PROOF - POA"){
                if(element.remarks!=null){
                  this.ipod_remarks=true
                }
              }else if(element.doc_name=="EXPORT PROMOTION CAPITAL GOODS SCHEME"){
                if(element.remarks!=null){
                  this.epcg_remarks=true
                }
              }
              else if(element.doc_name == "IEC HOLDERS AUTHORITY LETTER"){
                if(element.remarks!=null){
                  this.iec_authority_remarks=true
                }
              }
              else if(element.doc_name == "ANY OTHER DOCUMENT"){
                if(element.remarks!=null){
                  this.any_other_document_remarks=true
                }
              }
              else if(element.doc_name == "BG EXEMPTION SUPPORTING DOCUMENT"){
                if(element.remarks!=null){
                  this.noBg_doc=true
                }
              }
            });
          }


        })
 
  }

  openRemarks(data) {
    console.log("open",data);
    if (this.remarksByLetter != null) {
      this.remarksByLetter.forEach(remrk => {
        if (remrk.doc_name == data.document) {
          if (remrk.remarks != '') {
            this.openDialog(
              "paymentSum",
              remrk.remarks,
              ""
            );
          } else {
            
          }
        }

      })
    }


  }
  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result.event === "paymentSum" && result.isUpdated === true) {
        this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
