import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { PaymentsumComponent } from "app/blockchainImpExp/imp_exp/payment/paymentsum/paymentsum.component";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { element } from "protractor";
import { threadId } from "worker_threads";
import { AlertService } from "app/_services";
import * as e from "express";
declare var $: any;

export interface Summary {
  applicationSummary: string;
  details: string;
}
export interface Documents {
  document: string;
  pdf: string;
  remarks?:boolean;
}

export interface IntimationDocuments {
  document: string;
  pdf: string;
}

export interface Registration2 {

  date: string;
  name: string;
  designation: string;
  remarks: string;
}

export interface ShowCause {

  data: string;
  details: string;
 
}
@Component({
  selector: 'app-search-case-application-summary',
  templateUrl: './search-case-application-summary.component.html',
  styleUrls: ['./search-case-application-summary.component.scss']
})
export class SearchCaseApplicationSummaryComponent {
  data: Summary[] = [];
  data1: Summary[] = [];
  documentData: Documents[] = [];
  intimationData:IntimationDocuments[] = [];
  showCauseData:ShowCause[]=[];
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  displayedColumns = [
    'applicationSummary',
    'details',
  ];
  loaderCancellation: boolean;
  displayedColumns1 = [
    'document',
    'pdf',
    'aiInput',
  ];
  displayedColumns2 = [
    'document',
    'pdf',
  ];

  displayedColumns3 = [
    'data',
    'details',
  ];
  displayedColumns4 = [
    'document',
    'pdf',
    'aiInput',
  ];
// registration remarks
  iec_remarks:boolean=false;
  iec_authorityRemarks:boolean = false;
  epcg_remarks=false;
  aa_remarks:boolean=false;
  bond_remarks:boolean=false;
  star_remarks:boolean=false;
  bg_remarks:boolean=false;
  gst_remarks:boolean=false;
  ls_remarks:boolean=false;
  poa_remarks:boolean=false;
  aff_remarks:boolean=false;
  rcmc_remarks:boolean=false;
  tc_remarks:boolean=false;
  ipod_remarks:boolean=false;
  iec_authority_remarks:boolean= false;
  any_other_document_remarks: boolean=false;
  noBg_doc:boolean=false;
  // cancellation remarks
aL_remarks:boolean=false;
iec_letter:boolean = false;
iec_cancelRemarks = false;
eodc_remarks:boolean=false;
sL_remarks:boolean=false;
rg_remarks:boolean=false;
od_remarks:boolean=false;
od1_remarks:boolean=false;
od2_remarks:boolean=false;
od3_remarks:boolean=false;
od4_remarks:boolean=false;
od5_remarks:boolean=false;
od6_remarks:boolean=false;
od7_remarks:boolean=false;
od8_remarks:boolean=false;
od9_remarks:boolean=false;
od10_remarks:boolean=false;
es1_remarks:boolean=false;
es2_remarks:boolean=false;
es3_remarks:boolean=false;
es4_remarks:boolean=false;
es5_remarks:boolean=false;
es6_remarks:boolean=false;
es7_remarks:boolean=false;
es8_remarks:boolean=false;
es9_remarks:boolean=false;
es10_remarks:boolean=false;

  currentUser: any;
  caseDetailsData: any;
  summary: any;
  process: any;
  remarksByLetter: any;
  remarkDisable: any;
  disRemarks: boolean;
  pipe = new DatePipe("en-US");
  allDetailsData:boolean=false;
  documentDataReg:Documents[] = [];
  blockId: any;
  constructor(public blockchainService: BlockchainService,private router: Router, public dialog: MatDialog,private alertService: AlertService) { }
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));
    this.process = JSON.parse(localStorage.getItem("process"));
    // this.blockUI.stop();
    // console.log(this.caseDetailsData.am.application_id);
    // if (this.process == 'registration') {
      // if(this.caseDetailsData.am && this.caseDetailsData.cm){
      //   alert(1);
      //   this.allDetailsData=true;
      //   this.remarksData();
      //   console.log(this.caseDetailsData.am.application_id);
      //   console.log(this.caseDetailsData.cm.cancellation_id);
      //   this.getApplicationSummaryByApplicationId(this.caseDetailsData.am.application_id, this.currentUser.obj.user_id);
      //   this.getCancellationSummaryByCancellationId(this.caseDetailsData.cm.cancellation_id, this.currentUser.obj.user_id);    
      // }
      this.loaderCancellation=false;
      console.log(this.caseDetailsData);

      if(this.caseDetailsData.am){
        // this.allDetailsData=false;
        console.log("1");
      this.remarksData();
      console.log(this.caseDetailsData.am.application_id);
      this.getApplicationSummaryByApplicationId(this.caseDetailsData.am.application_id, this.currentUser.obj.user_id);
      }
    // }
    // else {
      // if(this.caseDetailsData.cm){
        // if(this.caseDetailsData.cm){
        if(this.caseDetailsData.cm){
          console.log("2");
        this.remarksData();
        console.log(this.caseDetailsData);
        console.log(this.caseDetailsData.cm.cancellation_id);
        this.getCancellationSummaryByCancellationId(this.caseDetailsData.cm.cancellation_id, this.currentUser.obj.user_id);  
      }
      else{
        console.log("3");
        this.remarksDataBasedOnApplicationId();
        // console.log(this.caseDetailsData);
        // console.log(this.caseDetailsData);
        this.getApplicationSummaryByOnlyApplicationId(this.caseDetailsData, this.currentUser.obj.user_id);
        if(this.caseDetailsData.cancellation_id!=0 || this.caseDetailsData.cancellation_id!='0'){
        this.getCancellationSummaryByOnlyCancellationId(this.caseDetailsData, this.currentUser.obj.user_id);  
        }
      }
     
    // }

  }

  getCancellationSummaryByCancellationId(input1, input2) {

    this.data = [];
    this.documentData = [];
    let application = {
      cancellation_id: input1,
      // user_id: input2
    }
    // this.loaderCancellation=true;
    // this.blockUI.start();
    this.blockchainService.getCancellationSummaryByCancellationId(application).subscribe(
      (resp) => {
        // this.loaderCancellation=false;
        // setTimeout(() => {
        //   this.blockUI.stop();
        // }, 500);
        this.summary = resp.body.obj;
        
        console.log(this.summary);
        console.log(this.caseDetailsData);
        if (this.caseDetailsData.cm.auth_type == 'AA') {
          this.data = [
            {
              applicationSummary: 'COMPANY NAME',
              details: this.summary.comapny_name,
            },
            {
              applicationSummary: 'IEC NO',
              details: this.summary.iec_code,
            },
            {
              applicationSummary: 'BLOCK ID',
              details: this.blockId?this.blockId:'',
            },
            {
              applicationSummary: 'CUSTOMS BROKER NAME',
              details: this.summary.customs_broker_name,
            },
            {
              applicationSummary: 'CUSTOMS BROKER REGISTRATION ID',
              details: this.summary.customs_broker_registration_no,
            },
            {
              applicationSummary: 'ADVANCED AUTHORIZATION NO',
              details: this.summary.advanced_authorization_no,
            },
            {
              applicationSummary: 'ADVANCED AUTHORIZATION DATE',
              details: this.pipe.transform(this.summary.advanced_authorization_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'DGFT SURRENDER LETTER NO',
              details: this.summary.dgft_surrender_letter_no,
            },
            {
              applicationSummary: 'DGFT SURRENDER LETTER ISSUE DATE',
              details: this.pipe.transform(this.summary.dgft_surrender_letter_issue_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'EODC LETTER NO',
              details: this.summary.eodc_letter_no,
            },
            {
              applicationSummary: 'EODC LETTER DATE',
              details: this.pipe.transform(this.summary.eodc_letter_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'DGFT REGULATION LETTER NO',
              details: this.summary.dgft_regularization_letter_no,
            },
            {
              applicationSummary: 'DGFT REGULATION LETTER DATE',
              details: this.pipe.transform(this.summary.dgft_regularization_letter_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'CANCELLATION APPLICATION DATE',
              details: this.pipe.transform(this.summary.cancellation_application_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'ORIGINAL FILE NO',
              details: this.summary.original_file_no,
            },
          ]
        }
        else{
          this.data = [
            {
              applicationSummary: 'COMPANY NAME',
              details: this.summary.comapny_name,
            },
            {
              applicationSummary: 'IEC NO',
              details: this.summary.iec_code,
            },
            {
              applicationSummary: 'BLOCK ID',
              details: this.blockId?this.blockId:'',
            },
            {
              applicationSummary: 'CUSTOMS BROKER NAME',
              details: this.summary.customs_broker_name,
            },
            {
              applicationSummary: 'CUSTOMS BROKER REGISTRATION ID',
              details: this.summary.customs_broker_registration_no,
            },
            {
              applicationSummary: 'EPCG NO',
              details: this.summary.advanced_authorization_no,
            },
            {
              applicationSummary: 'EPCG DATE',
              details: this.pipe.transform(this.summary.advanced_authorization_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'DGFT SURRENDER LETTER NO',
              details: this.summary.dgft_surrender_letter_no,
            },
            {
              applicationSummary: 'DGFT SURRENDER LETTER ISSUE DATE',
              details: this.pipe.transform(this.summary.dgft_surrender_letter_issue_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'EODC LETTER NO',
              details: this.summary.eodc_letter_no,
            },
            {
              applicationSummary: 'EODC LETTER DATE',
              details: this.pipe.transform(this.summary.eodc_letter_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'DGFT REGULATION LETTER NO',
              details: this.summary.dgft_regularization_letter_no,
            },
            {
              applicationSummary: 'DGFT REGULATION LETTER DATE',
              details: this.pipe.transform(this.summary.dgft_regularization_letter_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'CANCELLATION APPLICATION DATE',
              details: this.pipe.transform(this.summary.cancellation_application_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'ORIGINAL FILE NO',
              details: this.summary.original_file_no,
            },
          ]
        }
     
        // console.log("data",this.data);
        this.documentData = [
          {
            document: 'APPLICATION LETTER',
            pdf: this.summary.application_letter_path,
            remarks:this.aL_remarks
          },
          // {
          //   document: 'IEC LETTER',
          //   pdf: this.summary.iec_letter_path,
          //   remarks:this.iec_letter
          // },
          {
            document: 'IEC AUTHORIZATION LETTER',
            pdf: this.summary.cancellation_iec_auth,
            remarks:this.iec_cancelRemarks

          },
          {
            document: 'EXPORT OBLIGATION DISCHARGE CERTIFICATE',
            pdf: this.summary.export_obligation_discharge_certificate_path,
            remarks:this.eodc_remarks
          },
          {
            document: 'SURRENDER LETTER',
            pdf: this.summary.surrender_letter,
            remarks:this.sL_remarks
          },
          {
            document: 'REGULARIZATION LETTER',
            pdf: this.summary.regularization_letter,
            remarks:this.rg_remarks
          },
          {
            document: 'LIST OF OTHER DOCUMENTS BEING UPLOADED ',
            pdf: this.summary.list_of_other_documents_being_uploaded_path,
            remarks:this.od_remarks

          },
          {
            document: 'OTHER DOCUMENT 1',
            pdf: this.summary.other_documents1_path,
            remarks:this.od1_remarks

          },
          {
            document: 'OTHER DOCUMENT 2',
            pdf: this.summary.other_documents2_path,
            remarks:this.od2_remarks
          },
          {
            document: 'OTHER DOCUMENT 3',
            pdf: this.summary.other_documents2_path,
            remarks:this.od3_remarks
          },
          {
            document: 'OTHER DOCUMENT 4',
            pdf: this.summary.other_documents4_path,
            remarks:this.od4_remarks
          },
          {
            document: 'OTHER DOCUMENT 5',
            pdf: this.summary.other_documents5_path,
            remarks:this.od5_remarks
          },
          {
            document: 'OTHER DOCUMENT 6',
            pdf: this.summary.other_documents6_path,
            remarks:this.od6_remarks
          },
          {
            document: 'OTHER DOCUMENT 7',
            pdf: this.summary.other_documents7_path,
            remarks:this.od7_remarks
          },
          {
            document: 'OTHER DOCUMENT 8',
            pdf: this.summary.other_documents8_path,
            remarks:this.od8_remarks
          },
          {
            document: 'OTHER DOCUMENT 9',
            pdf: this.summary.other_documents9_path,
            remarks:this.od9_remarks
          },
          {
            document: 'OTHER DOCUMENT 10',
            pdf: this.summary.other_documents10_path,
            remarks:this.od10_remarks
          },
          {
            document: 'EXCEL SHEET 1',
            pdf: this.summary.excel_sheet1_path,
            remarks:this.es1_remarks
          },
          {
            document: 'EXCEL SHEET 2',
            pdf: this.summary.excel_sheet2_path,
            remarks:this.es2_remarks
          },
          {
            document: 'EXCEL SHEET 3',
            pdf: this.summary.excel_sheet3_path,
            remarks:this.es3_remarks
          },
          {
            document: 'EXCEL SHEET 4',
            pdf: this.summary.excel_sheet4_path,
            remarks:this.es4_remarks
          },
          {
            document: 'EXCEL SHEET 5',
            pdf: this.summary.excel_sheet5_path,
            remarks:this.es5_remarks
          },
          {
            document: 'EXCEL SHEET 6',
            pdf: this.summary.excel_sheet6_path,
            remarks:this.es6_remarks
          },
          {
            document: 'EXCEL SHEET 7',
            pdf: this.summary.excel_sheet7_path,
            remarks:this.es7_remarks
          },
          {
            document: 'EXCEL SHEET 8',
            pdf: this.summary.excel_sheet8_path,
            remarks:this.es8_remarks
          },
          {
            document: 'EXCEL SHEET 9',
            pdf: this.summary.excel_sheet9_path,
            remarks:this.es9_remarks
            
          },
          {
            document: 'EXCEL SHEET 10',
            pdf: this.summary.excel_sheet10_path,
            remarks:this.es10_remarks
          },
        ]

        if (this.caseDetailsData.cm.authorization_type == 'AA') {
          this.documentDataReg = [
            {
              document: 'IEC COPY',
              pdf: this.summary.iec_letter_path,
              remarks:this.iec_letter
             
            },
            {
              document: 'ADVANCED AUTHORIZATION',
              pdf: this.summary.advanced_authorization_path,
              remarks:this.aa_remarks
            },
            {
              document: 'BOND',
              pdf: this.summary.bond_path,
              remarks:this.bond_remarks
            },
            {
              document: 'STAR EXPORT HOUSE COPY',
              pdf: this.summary.start_export_house_copy_path,
              remarks:this.star_remarks
            },
            {
              document: 'BANK GUARANTEE',
              pdf: this.summary.bank_guarantee_path,
              remarks:this.bg_remarks
            },
            {
              document: 'GST REGISTRATION COPY',
              pdf: this.summary.gst_registration_path,
              remarks:this.gst_remarks
            },
            {
              document: 'LICENCE SCRIPT',
              pdf: this.summary.license_script_path,
              remarks:this.ls_remarks
            },
            {
              document: 'POWER OF ATTORNEY',
              pdf: this.summary.power_of_attorney_path,
              remarks:this.poa_remarks
            },
            {
              document: 'AFFADAVIT',
              pdf: this.summary.affadavit_path,
              remarks:this.aff_remarks
            },
            {
              document: 'RCMC',
              pdf: this.summary.rcmc_path,
              remarks:this.rcmc_remarks
            },
            {
              document: 'TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)',
              pdf: this.summary.turnover_certificate_path,
              remarks:this.tc_remarks
            },
            {
              document: 'IDENTITY PROOF - POA',
              pdf: this.summary.identity_proof_path,
              remarks:this.ipod_remarks
            },
            {
              document: 'IEC HOLDERS AUTHORITY LETTER',
              pdf: this.summary.reg_iec_auth,
              remarks:this.iec_authority_remarks
            },
            {
              document: 'ANY OTHER DOCUMENT',
              pdf: this.summary.any_other_document,
              remarks:this.any_other_document_remarks
            },
            {
              document: "BG EXEMPTION SUPPORTING DOCUMENT",
              pdf: this.summary.no_bg_doc,
              remarks: this.noBg_doc,
            }

          ]
        } else {
          this.documentDataReg = [
            {
              document: 'IEC COPY',
              pdf: this.summary.iec_letter_path,
              remarks:this.iec_letter
            },

            {
              document: 'EXPORT PROMOTION CAPITAL GOODS SCHEME',
              pdf: this.summary.advanced_authorization_path,
              remarks:this.epcg_remarks
            },
            {
              document: 'BOND',
              pdf: this.summary.bond_path,
              remarks:this.bond_remarks
            },
            {
              document: 'STAR EXPORT HOUSE COPY',
              pdf: this.summary.start_export_house_copy_path,
              remarks:this.star_remarks
            },
            {
              document: 'BANK GUARANTEE',
              pdf: this.summary.bank_guarantee_path,
              remarks:this.bg_remarks
            },
            {
              document: 'GST REGISTRATION COPY',
              pdf: this.summary.gst_registration_path,
              remarks:this.gst_remarks
            },
            {
              document: 'LICENCE SCRIPT',
              pdf: this.summary.license_script_path,
              remarks:this.ls_remarks
            },
            {
              document: 'POWER OF ATTORNEY',
              pdf: this.summary.power_of_attorney_path,
              remarks:this.poa_remarks
            },
            {
              document: 'AFFADAVIT',
              pdf: this.summary.affadavit_path,
              remarks:this.aff_remarks
            },
            {
              document: 'RCMC',
              pdf: this.summary.rcmc_path,
              remarks:this.rcmc_remarks
            },
            {
              document: 'TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)',
              pdf: this.summary.turnover_certificate_path,
              remarks:this.tc_remarks
            },
            {
              document: 'IDENTITY PROOF - POA',
              pdf: this.summary.identity_proof_path,
              remarks:this.ipod_remarks
            },
            {
              document: 'IEC HOLDERS AUTHORITY LETTER',
              pdf: this.summary.iec_authority_letter_path,
              remarks:this.iec_authority_remarks
            },

            {
              document: 'ANY OTHER DOCUMENT',
              pdf: this.summary.any_other_document,
              remarks:this.any_other_document_remarks
            },
            {
              document: "BG EXEMPTION SUPPORTING DOCUMENT",
              pdf: this.summary.no_bg_doc,
              remarks: this.noBg_doc,
            }

          ]
        }
          this.intimationData = [
            {
              document: 'INSTALLATION CERTIFICATE',
              pdf: this.summary.installation_certificate_path,
            },
            {
              document: 'MID TERM REVIEW CERTIFICATE',
              pdf: this.summary.mid_term_certificate_path,
            },
            {
              document: 'EODC APPLICATION DOCUMENT',
              pdf: this.summary.eodc_document_path,
            },
            {
              document: 'EODC REDEMPTION LETTER BY DGFT',
              pdf: this.summary.eodc_redemption_path,
            },
            {
              document: 'EODC REJECTION LETTER BY DGFT',
              pdf: this.summary.eodc_rejection_path,
            },
            {
              // document: 'OTHER LETTER BY DGFT',
              document: 'OTHER DOCUMENTS',
              pdf: this.summary.others_letters_path,
            },
            {
              document: 'EODC RECEIPT UPDATED BY CUSTOMS',
              pdf: this.summary.eodc_redemption_notice_date,
            },
          ]

          this.showCauseData = [
            {
              data: 'SHOW CAUSE NOTICE PENDING',
              details: this.summary.scn_pending,
            },
          ]
        
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
       
      });
  }

  getCancellationSummaryByOnlyCancellationId(input1, input2) {

    this.data = [];
    this.documentData = [];
    let application = {
      cancellation_id: input1,
      // user_id: input2
    }
    this.blockchainService.getCancellationSummaryByCancellationId(application).subscribe(
      (resp) => {
        this.summary = resp.body.obj;
        console.log(this.summary);
        if (this.caseDetailsData.authorization_type == 'AA') {
          this.data = [
            {
              applicationSummary: 'COMPANY NAME',
              details: this.summary.comapny_name,
            },
            {
              applicationSummary: 'IEC NO',
              details: this.summary.iec_code,
            },
            {
              applicationSummary: 'BLOCK ID',
              details: this.blockId?this.blockId:'',
            },
            {
              applicationSummary: 'CUSTOMS BROKER NAME',
              details: this.summary.customs_broker_name,
            },
            {
              applicationSummary: 'CUSTOMS BROKER REGISTRATION ID',
              details: this.summary.customs_broker_registration_no,
            },
            {
              applicationSummary: 'ADVANCED AUTHORIZATION NO',
              details: this.summary.advanced_authorization_no,
            },
            {
              applicationSummary: 'ADVANCED AUTHORIZATION DATE',
              details: this.pipe.transform(this.summary.advanced_authorization_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'DGFT SURRENDER LETTER NO',
              details: this.summary.dgft_surrender_letter_no,
            },
            {
              applicationSummary: 'DGFT SURRENDER LETTER ISSUE DATE',
              details: this.pipe.transform(this.summary.dgft_surrender_letter_issue_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'EODC LETTER NO',
              details: this.summary.eodc_letter_no,
            },
            {
              applicationSummary: 'EODC LETTER DATE',
              details: this.pipe.transform(this.summary.eodc_letter_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'DGFT REGULATION LETTER NO',
              details: this.summary.dgft_regularization_letter_no,
            },
            {
              applicationSummary: 'DGFT REGULATION LETTER DATE',
              details: this.pipe.transform(this.summary.dgft_regularization_letter_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'CANCELLATION APPLICATION DATE',
              details: this.pipe.transform(this.summary.cancellation_application_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'ORIGINAL FILE NO',
              details: this.summary.original_file_no,
            },
          ]
        }
        else{
          this.data = [
            {
              applicationSummary: 'COMPANY NAME',
              details: this.summary.comapny_name,
            },
            {
              applicationSummary: 'IEC NO',
              details: this.summary.iec_code,
            },
            {
              applicationSummary: 'BLOCK ID',
              details: this.blockId?this.blockId:'',
            },
            {
              applicationSummary: 'CUSTOMS BROKER NAME',
              details: this.summary.customs_broker_name,
            },
            {
              applicationSummary: 'CUSTOMS BROKER REGISTRATION ID',
              details: this.summary.customs_broker_registration_no,
            },
            {
              applicationSummary: 'EPCG NO',
              details: this.summary.advanced_authorization_no,
            },
            {
              applicationSummary: 'EPCG DATE',
              details: this.pipe.transform(this.summary.advanced_authorization_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'DGFT SURRENDER LETTER NO',
              details: this.summary.dgft_surrender_letter_no,
            },
            {
              applicationSummary: 'DGFT SURRENDER LETTER ISSUE DATE',
              details: this.pipe.transform(this.summary.dgft_surrender_letter_issue_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'EODC LETTER NO',
              details: this.summary.eodc_letter_no,
            },
            {
              applicationSummary: 'EODC LETTER DATE',
              details: this.pipe.transform(this.summary.eodc_letter_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'DGFT REGULATION LETTER NO',
              details: this.summary.dgft_regularization_letter_no,
            },
            {
              applicationSummary: 'DGFT REGULATION LETTER DATE',
              details: this.pipe.transform(this.summary.dgft_regularization_letter_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'CANCELLATION APPLICATION DATE',
              details: this.pipe.transform(this.summary.cancellation_application_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'ORIGINAL FILE NO',
              details: this.summary.original_file_no,
            },
          ]
        }
     
        // console.log("data",this.data);
        this.documentData = [
          {
            document: 'APPLICATION LETTER',
            pdf: this.summary.application_letter_path,
            remarks:this.aL_remarks
          },
          // {
          //   document: 'IEC LETTER',
          //   pdf: this.summary.iec_letter_path,
          //   remarks:this.iec_letter
          // },
          {
            document: 'IEC AUTHORIZATION LETTER',
            pdf: this.summary.cancellation_iec_auth,
            remarks:this.iec_cancelRemarks

          },
          {
            document: 'EXPORT OBLIGATION DISCHARGE CERTIFICATE',
            pdf: this.summary.export_obligation_discharge_certificate_path,
            remarks:this.eodc_remarks
          },
          {
            document: 'SURRENDER LETTER',
            pdf: this.summary.surrender_letter,
            remarks:this.sL_remarks
          },
          {
            document: 'REGULARIZATION LETTER',
            pdf: this.summary.regularization_letter,
            remarks:this.rg_remarks
          },
          {
            document: 'LIST OF OTHER DOCUMENTS BEING UPLOADED ',
            pdf: this.summary.list_of_other_documents_being_uploaded_path,
            remarks:this.od_remarks

          },
          {
            document: 'OTHER DOCUMENT 1',
            pdf: this.summary.other_documents1_path,
            remarks:this.od1_remarks

          },
          {
            document: 'OTHER DOCUMENT 2',
            pdf: this.summary.other_documents2_path,
            remarks:this.od2_remarks
          },
          {
            document: 'OTHER DOCUMENT 3',
            pdf: this.summary.other_documents2_path,
            remarks:this.od3_remarks
          },
          {
            document: 'OTHER DOCUMENT 4',
            pdf: this.summary.other_documents4_path,
            remarks:this.od4_remarks
          },
          {
            document: 'OTHER DOCUMENT 5',
            pdf: this.summary.other_documents5_path,
            remarks:this.od5_remarks
          },
          {
            document: 'OTHER DOCUMENT 6',
            pdf: this.summary.other_documents6_path,
            remarks:this.od6_remarks
          },
          {
            document: 'OTHER DOCUMENT 7',
            pdf: this.summary.other_documents7_path,
            remarks:this.od7_remarks
          },
          {
            document: 'OTHER DOCUMENT 8',
            pdf: this.summary.other_documents8_path,
            remarks:this.od8_remarks
          },
          {
            document: 'OTHER DOCUMENT 9',
            pdf: this.summary.other_documents9_path,
            remarks:this.od9_remarks
          },
          {
            document: 'OTHER DOCUMENT 10',
            pdf: this.summary.other_documents10_path,
            remarks:this.od10_remarks
          },
          {
            document: 'EXCEL SHEET 1',
            pdf: this.summary.excel_sheet1_path,
            remarks:this.es1_remarks
          },
          {
            document: 'EXCEL SHEET 2',
            pdf: this.summary.excel_sheet2_path,
            remarks:this.es2_remarks
          },
          {
            document: 'EXCEL SHEET 3',
            pdf: this.summary.excel_sheet3_path,
            remarks:this.es3_remarks
          },
          {
            document: 'EXCEL SHEET 4',
            pdf: this.summary.excel_sheet4_path,
            remarks:this.es4_remarks
          },
          {
            document: 'EXCEL SHEET 5',
            pdf: this.summary.excel_sheet5_path,
            remarks:this.es5_remarks
          },
          {
            document: 'EXCEL SHEET 6',
            pdf: this.summary.excel_sheet6_path,
            remarks:this.es6_remarks
          },
          {
            document: 'EXCEL SHEET 7',
            pdf: this.summary.excel_sheet7_path,
            remarks:this.es7_remarks
          },
          {
            document: 'EXCEL SHEET 8',
            pdf: this.summary.excel_sheet8_path,
            remarks:this.es8_remarks
          },
          {
            document: 'EXCEL SHEET 9',
            pdf: this.summary.excel_sheet9_path,
            remarks:this.es9_remarks
            
          },
          {
            document: 'EXCEL SHEET 10',
            pdf: this.summary.excel_sheet10_path,
            remarks:this.es10_remarks
          },
        ]

        if (this.caseDetailsData.authorization_type == 'AA') {
          this.documentDataReg = [
            {
              document: 'IEC COPY',
              pdf: this.summary.iec_letter_path,
              remarks:this.iec_letter
             
            },
            {
              document: 'ADVANCED AUTHORIZATION',
              pdf: this.summary.advanced_authorization_path,
              remarks:this.aa_remarks
            },
            {
              document: 'BOND',
              pdf: this.summary.bond_path,
              remarks:this.bond_remarks
            },
            {
              document: 'STAR EXPORT HOUSE COPY',
              pdf: this.summary.start_export_house_copy_path,
              remarks:this.star_remarks
            },
            {
              document: 'BANK GUARANTEE',
              pdf: this.summary.bank_guarantee_path,
              remarks:this.bg_remarks
            },
            {
              document: 'GST REGISTRATION COPY',
              pdf: this.summary.gst_registration_path,
              remarks:this.gst_remarks
            },
            {
              document: 'LICENCE SCRIPT',
              pdf: this.summary.license_script_path,
              remarks:this.ls_remarks
            },
            {
              document: 'POWER OF ATTORNEY',
              pdf: this.summary.power_of_attorney_path,
              remarks:this.poa_remarks
            },
            {
              document: 'AFFADAVIT',
              pdf: this.summary.affadavit_path,
              remarks:this.aff_remarks
            },
            {
              document: 'RCMC',
              pdf: this.summary.rcmc_path,
              remarks:this.rcmc_remarks
            },
            {
              document: 'TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)',
              pdf: this.summary.turnover_certificate_path,
              remarks:this.tc_remarks
            },
            {
              document: 'IDENTITY PROOF - POA',
              pdf: this.summary.identity_proof_path,
              remarks:this.ipod_remarks
            },
            {
              document: 'IEC HOLDERS AUTHORITY LETTER',
              pdf: this.summary.reg_iec_auth,
              remarks:this.iec_authority_remarks
            },
            {
              document: 'ANY OTHER DOCUMENT',
              pdf: this.summary.any_other_document,
              remarks:this.any_other_document_remarks
            },
            {
              document: "BG EXEMPTION SUPPORTING DOCUMENT",
              pdf: this.summary.no_bg_doc,
              remarks: this.noBg_doc,
            }

          ]
        } else {
          this.documentDataReg = [
            {
              document: 'IEC COPY',
              pdf: this.summary.iec_letter_path,
              remarks:this.iec_letter
            },

            {
              document: 'EXPORT PROMOTION CAPITAL GOODS SCHEME',
              pdf: this.summary.advanced_authorization_path,
              remarks:this.epcg_remarks
            },
            {
              document: 'BOND',
              pdf: this.summary.bond_path,
              remarks:this.bond_remarks
            },
            {
              document: 'STAR EXPORT HOUSE COPY',
              pdf: this.summary.start_export_house_copy_path,
              remarks:this.star_remarks
            },
            {
              document: 'BANK GUARANTEE',
              pdf: this.summary.bank_guarantee_path,
              remarks:this.bg_remarks
            },
            {
              document: 'GST REGISTRATION COPY',
              pdf: this.summary.gst_registration_path,
              remarks:this.gst_remarks
            },
            {
              document: 'LICENCE SCRIPT',
              pdf: this.summary.license_script_path,
              remarks:this.ls_remarks
            },
            {
              document: 'POWER OF ATTORNEY',
              pdf: this.summary.power_of_attorney_path,
              remarks:this.poa_remarks
            },
            {
              document: 'AFFADAVIT',
              pdf: this.summary.affadavit_path,
              remarks:this.aff_remarks
            },
            {
              document: 'RCMC',
              pdf: this.summary.rcmc_path,
              remarks:this.rcmc_remarks
            },
            {
              document: 'TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)',
              pdf: this.summary.turnover_certificate_path,
              remarks:this.tc_remarks
            },
            {
              document: 'IDENTITY PROOF - POA',
              pdf: this.summary.identity_proof_path,
              remarks:this.ipod_remarks
            },
            {
              document: 'IEC HOLDERS AUTHORITY LETTER',
              pdf: this.summary.iec_authority_letter_path,
              remarks:this.iec_authority_remarks
            },

            {
              document: 'ANY OTHER DOCUMENT',
              pdf: this.summary.any_other_document,
              remarks:this.any_other_document_remarks
            },
            {
              document: "BG EXEMPTION SUPPORTING DOCUMENT",
              pdf: this.summary.no_bg_doc,
              remarks: this.noBg_doc,
            }

          ]
        }
          this.intimationData = [
            {
              document: 'INSTALLATION CERTIFICATE',
              pdf: this.summary.installation_certificate_path,
            },
            {
              document: 'MID TERM REVIEW CERTIFICATE',
              pdf: this.summary.mid_term_certificate_path,
            },
            {
              document: 'EODC APPLICATION DOCUMENT',
              pdf: this.summary.eodc_document_path,
            },
            {
              document: 'EODC REDEMPTION LETTER BY DGFT',
              pdf: this.summary.eodc_redemption_path,
            },
            {
              document: 'EODC REJECTION LETTER BY DGFT',
              pdf: this.summary.eodc_rejection_path,
            },
            {
              // document: 'OTHER LETTER BY DGFT',
              document: 'OTHER DOCUMENTS',
              pdf: this.summary.others_letters_path,
            },
            {
              document: 'EODC RECEIPT UPDATED BY CUSTOMS',
              pdf: this.summary.eodc_redemption_notice_date,
            },
          ]

          this.showCauseData = [
            {
              data: 'SHOW CAUSE NOTICE PENDING',
              details: this.summary.scn_pending,
            },
          ]
        
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
       
      });
  }

  getApplicationSummaryByApplicationId(input1, input2) {
    this.data = [];
    this.documentData = [];
    let application = {
      application_id: input1,
      user_id: input2
    }
    // this.loaderCancellation=true;
    // this.blockUI.start();
    this.blockchainService.getApplicationSummaryByApplicationIdSearchCase(application).subscribe(
      (resp) => {
        // this.loaderCancellation=false;
        // setTimeout(() => {
        //   this.blockUI.stop();
        // }, 500);
        this.summary = resp.body.obj;
        console.log(this.summary);
        console.log(this.summary.blockId);
        this.blockId=this.summary.blockId;
        console.log(this.caseDetailsData.am.application_type_name );
        if (this.caseDetailsData.am.application_type_name == 'AA') {
          this.data = [
            {
              applicationSummary: 'COMPANY NAME',
              details: this.summary.name,
            },
            {
              applicationSummary: 'IEC NO',
              details: this.summary.iec_code,
            },
            {
              applicationSummary: 'BLOCK ID',
              details: this.blockId?this.blockId:'',
            },
            {
              applicationSummary: 'CUSTOMS BROKER NAME',
              details: this.summary.customs_broker_name,
            },
            {
              applicationSummary: 'CUSTOMS BROKER REGISTRATION ID',
              details: this.summary.customs_broker_registration_no,
            },
            {
              applicationSummary: 'ADVANCED AUTHORIZATION NO',
              details: this.summary.advanced_authorization_no,
            },
            {
              applicationSummary: 'ADVANCED AUTHORIZATION DATE',
              details: this.pipe.transform(this.summary.advanced_authorization_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'BOND AMOUNT IN INR',
              details: this.summary.bond_amount_inr,
            },
            {
              applicationSummary: 'FILE NO',
              details: this.summary.file_no,
            },
        
            {
              applicationSummary: 'FILE ISSUE DATE',
              details: this.pipe.transform(this.summary.file_issue_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'BOND NO',
              details: this.summary.bond_no,
            },
            {
              applicationSummary: 'FILE STORAGE LOCATION AT BBG CELL',
              details: this.summary.bbg_cell_location,
            },
          ]
        } else {
          this.data = [
            {
              applicationSummary: 'COMPANY NAME',
              details: this.summary.name,
            },
            {
              applicationSummary: 'IEC NO',
              details: this.summary.iec_code,
            },
            {
              applicationSummary: 'BLOCK ID',
              details: this.blockId?this.blockId:'',
            },
            {
              applicationSummary: 'CUSTOMS BROKER NAME',
              details: this.summary.customs_broker_name,
            },
            {
              applicationSummary: 'CUSTOMS BROKER REGISTRATION ID',
              details: this.summary.customs_broker_registration_no,
            },
            {
              applicationSummary: 'EPCG NO',
              details: this.summary.advanced_authorization_no,
            },
            {
              applicationSummary: 'EPCG DATE',
              details: this.pipe.transform(this.summary.advanced_authorization_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'BOND AMOUNT IN INR',
              details: this.summary.bond_amount_inr,
            },
            {
              applicationSummary: 'FILE NO',
              details: this.summary.file_no,
            },
         
            {
              applicationSummary: 'FILE ISSUE DATE',
              details: this.pipe.transform(this.summary.file_issue_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'BOND NO',
              details: this.summary.bond_no,
            },
            {
              applicationSummary: 'FILE STORAGE LOCATION AT BBG CELL',
              details: this.summary.bbg_cell_location,
            },
          ]
        }

        if (this.caseDetailsData.am.application_type_name == 'AA') {
          this.documentDataReg = [
            {
              document: 'IEC COPY',
              pdf: this.summary.iec_copy_path,
              remarks:this.iec_remarks
             
            },
            {
              document: 'ADVANCED AUTHORIZATION',
              pdf: this.summary.advanced_authorization_path,
              remarks:this.aa_remarks
            },
            {
              document: 'BOND',
              pdf: this.summary.bond_path,
              remarks:this.bond_remarks
            },
            {
              document: 'STAR EXPORT HOUSE COPY',
              pdf: this.summary.start_export_house_copy_path,
              remarks:this.star_remarks
            },
            {
              document: 'BANK GUARANTEE',
              pdf: this.summary.bank_guarantee_path,
              remarks:this.bg_remarks
            },
            {
              document: 'GST REGISTRATION COPY',
              pdf: this.summary.gst_registration_path,
              remarks:this.gst_remarks
            },
            {
              document: 'LICENCE SCRIPT',
              pdf: this.summary.license_script_path,
              remarks:this.ls_remarks
            },
            {
              document: 'POWER OF ATTORNEY',
              pdf: this.summary.power_of_attorney_path,
              remarks:this.poa_remarks
            },
            {
              document: 'AFFADAVIT',
              pdf: this.summary.affadavit_path,
              remarks:this.aff_remarks
            },
            {
              document: 'RCMC',
              pdf: this.summary.rcmc_path,
              remarks:this.rcmc_remarks
            },
            {
              document: 'TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)',
              pdf: this.summary.turnover_certificate_path,
              remarks:this.tc_remarks
            },
            {
              document: 'IDENTITY PROOF - POA',
              pdf: this.summary.identity_proof_path,
              remarks:this.ipod_remarks
            },
            {
              document: 'IEC HOLDERS AUTHORITY LETTER',
              pdf: this.summary.iec_authority_letter_path,
              remarks:this.iec_authority_remarks
            },
            {
              document: 'ANY OTHER DOCUMENT',
              pdf: this.summary.any_other_document,
              remarks:this.any_other_document_remarks
            },
            {
              document: "BG EXEMPTION SUPPORTING DOCUMENT",
              pdf: this.summary.no_bg_doc,
              remarks: this.noBg_doc,
            }

          ]
        } else {
          this.documentDataReg = [
            {
              document: 'IEC COPY',
              pdf: this.summary.iec_copy_path,
              remarks:this.iec_remarks
            },

            {
              document: 'EXPORT PROMOTION CAPITAL GOODS SCHEME',
              pdf: this.summary.advanced_authorization_path,
              remarks:this.epcg_remarks
            },
            {
              document: 'BOND',
              pdf: this.summary.bond_path,
              remarks:this.bond_remarks
            },
            {
              document: 'STAR EXPORT HOUSE COPY',
              pdf: this.summary.start_export_house_copy_path,
              remarks:this.star_remarks
            },
            {
              document: 'BANK GUARANTEE',
              pdf: this.summary.bank_guarantee_path,
              remarks:this.bg_remarks
            },
            {
              document: 'GST REGISTRATION COPY',
              pdf: this.summary.gst_registration_path,
              remarks:this.gst_remarks
            },
            {
              document: 'LICENCE SCRIPT',
              pdf: this.summary.license_script_path,
              remarks:this.ls_remarks
            },
            {
              document: 'POWER OF ATTORNEY',
              pdf: this.summary.power_of_attorney_path,
              remarks:this.poa_remarks
            },
            {
              document: 'AFFADAVIT',
              pdf: this.summary.affadavit_path,
              remarks:this.aff_remarks
            },
            {
              document: 'RCMC',
              pdf: this.summary.rcmc_path,
              remarks:this.rcmc_remarks
            },
            {
              document: 'TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)',
              pdf: this.summary.turnover_certificate_path,
              remarks:this.tc_remarks
            },
            {
              document: 'IDENTITY PROOF - POA',
              pdf: this.summary.identity_proof_path,
              remarks:this.ipod_remarks
            },
            {
              document: 'IEC HOLDERS AUTHORITY LETTER',
              pdf: this.summary.iec_authority_letter_path,
              remarks:this.iec_authority_remarks
            },

            {
              document: 'ANY OTHER DOCUMENT',
              pdf: this.summary.any_other_document,
              remarks:this.any_other_document_remarks
            },
            {
              document: "BG EXEMPTION SUPPORTING DOCUMENT",
              pdf: this.summary.no_bg_doc,
              remarks: this.noBg_doc,
            }

          ]
   
        }

        if(this.caseDetailsData.am.application_type_name == 'AA'){
          this.intimationData = [
            // {
            //   document: 'INSTALLATION CERTIFICATE',
            //   pdf: this.summary.installation_certificate_path,
            // },
            // {
            //   document: 'MID TERM REVIEW CERTIFICATE',
            //   pdf: this.summary.mid_term_certificate_path,
            // },
            {
              document: 'EODC APPLICATION DOCUMENT',
              pdf: this.summary.eodc_document_path,
            },
            {
              document: 'EODC REDEMPTION LETTER BY DGFT',
              pdf: this.summary.eodc_redemption_path,
            },
            {
              document: 'EODC REJECTION LETTER BY DGFT',
              pdf: this.summary.eodc_rejection_path,
            },
            {
              document: 'OTHER DOCUMENTS',
              pdf: this.summary.others_letters_path,
            },
            {
              document: 'EODC RECEIPT UPDATED BY CUSTOMS',
              pdf: this.summary.eodc_redemption_notice_date,
            },

          ]
        }else{
          this.intimationData = [
            {
              document: 'INSTALLATION CERTIFICATE',
              pdf: this.summary.installation_certificate_path,
            },
            {
              document: 'MID TERM REVIEW CERTIFICATE',
              pdf: this.summary.mid_term_certificate_path,
            },
            {
              document: 'EODC APPLICATION DOCUMENT',
              pdf: this.summary.eodc_document_path,
            },
            {
              document: 'EODC REDEMPTION LETTER BY DGFT',
              pdf: this.summary.eodc_redemption_path,
            },
            {
              document: 'EODC REJECTION LETTER BY DGFT',
              pdf: this.summary.eodc_rejection_path,
            },
            {
              document: 'OTHER DOCUMENTS',
              pdf: this.summary.others_letters_path,
            },
            {
              document: 'EODC RECEIPT UPDATED BY CUSTOMS',
              pdf: this.summary.eodc_redemption_notice_date,
            },

          ]
        }
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }

  getApplicationSummaryByOnlyApplicationId(input1, input2) {
    this.data = [];
    this.documentData = [];
    let application = {
      application_id: input1,
      user_id: input2
    }

    this.blockchainService.getApplicationSummaryByApplicationIdSearchCase(application).subscribe(
      (resp) => {
        this.summary = resp.body.obj;
        console.log(this.summary);
        this.blockId=this.summary.blockId;
        if (this.caseDetailsData.application_type == 'AA') {
          this.data = [
            {
              applicationSummary: 'COMPANY NAME',
              details: this.summary.name,
            },
            {
              applicationSummary: 'IEC NO',
              details: this.summary.iec_code,
            },
            {
              applicationSummary: 'BLOCK ID',
              details: this.blockId?this.blockId:'',
            },
            {
              applicationSummary: 'CUSTOMS BROKER NAME',
              details: this.summary.customs_broker_name,
            },
            {
              applicationSummary: 'CUSTOMS BROKER REGISTRATION ID',
              details: this.summary.customs_broker_registration_no,
            },
            {
              applicationSummary: 'ADVANCED AUTHORIZATION NO',
              details: this.summary.advanced_authorization_no,
            },
            {
              applicationSummary: 'ADVANCED AUTHORIZATION DATE',
              details: this.pipe.transform(this.summary.advanced_authorization_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'BOND AMOUNT IN INR',
              details: this.summary.bond_amount_inr,
            },
            {
              applicationSummary: 'FILE NO',
              details: this.summary.file_no,
            },
        
            {
              applicationSummary: 'FILE ISSUE DATE',
              details: this.pipe.transform(this.summary.file_issue_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'BOND NO',
              details: this.summary.bond_no,
            },
            {
              applicationSummary: 'FILE STORAGE LOCATION AT BBG CELL',
              details: this.summary.bbg_cell_location,
            },
          ]
        } else {
          this.data = [
            {
              applicationSummary: 'COMPANY NAME',
              details: this.summary.name,
            },
            {
              applicationSummary: 'IEC NO',
              details: this.summary.iec_code,
            },
            {
              applicationSummary: 'BLOCK ID',
              details: this.blockId?this.blockId:'',
            },
            {
              applicationSummary: 'CUSTOMS BROKER NAME',
              details: this.summary.customs_broker_name,
            },
            {
              applicationSummary: 'CUSTOMS BROKER REGISTRATION ID',
              details: this.summary.customs_broker_registration_no,
            },
            {
              applicationSummary: 'EPCG NO',
              details: this.summary.advanced_authorization_no,
            },
            {
              applicationSummary: 'EPCG DATE',
              details: this.pipe.transform(this.summary.advanced_authorization_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'BOND AMOUNT IN INR',
              details: this.summary.bond_amount_inr,
            },
            {
              applicationSummary: 'FILE NO',
              details: this.summary.file_no,
            },
         
            {
              applicationSummary: 'FILE ISSUE DATE',
              details: this.pipe.transform(this.summary.file_issue_date,'dd-MM-yyyy'),
            },
            {
              applicationSummary: 'BOND NO',
              details: this.summary.bond_no,
            },
            {
              applicationSummary: 'FILE STORAGE LOCATION AT BBG CELL',
              details: this.summary.bbg_cell_location,
            },
          ]
        }

        if (this.caseDetailsData.application_type == 'AA') {
          this.documentData = [
            {
              document: 'IEC COPY',
              pdf: this.summary.iec_copy_path,
              remarks:this.iec_remarks
             
            },
            {
              document: 'ADVANCED AUTHORIZATION',
              pdf: this.summary.advanced_authorization_path,
              remarks:this.aa_remarks
            },
            {
              document: 'BOND',
              pdf: this.summary.bond_path,
              remarks:this.bond_remarks
            },
            {
              document: 'STAR EXPORT HOUSE COPY',
              pdf: this.summary.start_export_house_copy_path,
              remarks:this.star_remarks
            },
            {
              document: 'BANK GUARANTEE',
              pdf: this.summary.bank_guarantee_path,
              remarks:this.bg_remarks
            },
            {
              document: 'GST REGISTRATION COPY',
              pdf: this.summary.gst_registration_path,
              remarks:this.gst_remarks
            },
            {
              document: 'LICENCE SCRIPT',
              pdf: this.summary.license_script_path,
              remarks:this.ls_remarks
            },
            {
              document: 'POWER OF ATTORNEY',
              pdf: this.summary.power_of_attorney_path,
              remarks:this.poa_remarks
            },
            {
              document: 'AFFADAVIT',
              pdf: this.summary.affadavit_path,
              remarks:this.aff_remarks
            },
            {
              document: 'RCMC',
              pdf: this.summary.rcmc_path,
              remarks:this.rcmc_remarks
            },
            {
              document: 'TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)',
              pdf: this.summary.turnover_certificate_path,
              remarks:this.tc_remarks
            },
            {
              document: 'IDENTITY PROOF - POA',
              pdf: this.summary.identity_proof_path,
              remarks:this.ipod_remarks
            },
            {
              document: 'IEC HOLDERS AUTHORITY LETTER',
              pdf: this.summary.iec_authority_letter_path,
              remarks:this.iec_authority_remarks
            },
            {
              document: 'ANY OTHER DOCUMENT',
              pdf: this.summary.any_other_document,
              remarks:this.any_other_document_remarks
            },
            {
              document: "BG EXEMPTION SUPPORTING DOCUMENT",
              pdf: this.summary.no_bg_doc,
              remarks: this.noBg_doc,
            }

          ]
        } else {
          this.documentData = [
            {
              document: 'IEC COPY',
              pdf: this.summary.iec_copy_path,
              remarks:this.iec_remarks
            },

            {
              document: 'EXPORT PROMOTION CAPITAL GOODS SCHEME',
              pdf: this.summary.advanced_authorization_path,
              remarks:this.epcg_remarks
            },
            {
              document: 'BOND',
              pdf: this.summary.bond_path,
              remarks:this.bond_remarks
            },
            {
              document: 'STAR EXPORT HOUSE COPY',
              pdf: this.summary.start_export_house_copy_path,
              remarks:this.star_remarks
            },
            {
              document: 'BANK GUARANTEE',
              pdf: this.summary.bank_guarantee_path,
              remarks:this.bg_remarks
            },
            {
              document: 'GST REGISTRATION COPY',
              pdf: this.summary.gst_registration_path,
              remarks:this.gst_remarks
            },
            {
              document: 'LICENCE SCRIPT',
              pdf: this.summary.license_script_path,
              remarks:this.ls_remarks
            },
            {
              document: 'POWER OF ATTORNEY',
              pdf: this.summary.power_of_attorney_path,
              remarks:this.poa_remarks
            },
            {
              document: 'AFFADAVIT',
              pdf: this.summary.affadavit_path,
              remarks:this.aff_remarks
            },
            {
              document: 'RCMC',
              pdf: this.summary.rcmc_path,
              remarks:this.rcmc_remarks
            },
            {
              document: 'TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)',
              pdf: this.summary.turnover_certificate_path,
              remarks:this.tc_remarks
            },
            {
              document: 'IDENTITY PROOF - POA',
              pdf: this.summary.identity_proof_path,
              remarks:this.ipod_remarks
            },
            {
              document: 'IEC HOLDERS AUTHORITY LETTER',
              pdf: this.summary.iec_authority_letter_path,
              remarks:this.iec_authority_remarks
            },

            {
              document: 'ANY OTHER DOCUMENT',
              pdf: this.summary.any_other_document,
              remarks:this.any_other_document_remarks
            },
            {
              document: "BG EXEMPTION SUPPORTING DOCUMENT",
              pdf: this.summary.no_bg_doc,
              remarks: this.noBg_doc,
            }

          ]
   
        }

        if(this.caseDetailsData.application_type == 'AA'){
          this.intimationData = [
            // {
            //   document: 'INSTALLATION CERTIFICATE',
            //   pdf: this.summary.installation_certificate_path,
            // },
            // {
            //   document: 'MID TERM REVIEW CERTIFICATE',
            //   pdf: this.summary.mid_term_certificate_path,
            // },
            {
              document: 'EODC APPLICATION DOCUMENT',
              pdf: this.summary.eodc_document_path,
            },
            {
              document: 'EODC REDEMPTION LETTER BY DGFT',
              pdf: this.summary.eodc_redemption_path,
            },
            {
              document: 'EODC REJECTION LETTER BY DGFT',
              pdf: this.summary.eodc_rejection_path,
            },
            {
              document: 'OTHER DOCUMENTS',
              pdf: this.summary.others_letters_path,
            },
            {
              document: 'EODC RECEIPT UPDATED BY CUSTOMS',
              pdf: this.summary.eodc_redemption_notice_date,
            },

          ]
        }else{
          this.intimationData = [
            {
              document: 'INSTALLATION CERTIFICATE',
              pdf: this.summary.installation_certificate_path,
            },
            {
              document: 'MID TERM REVIEW CERTIFICATE',
              pdf: this.summary.mid_term_certificate_path,
            },
            {
              document: 'EODC APPLICATION DOCUMENT',
              pdf: this.summary.eodc_document_path,
            },
            {
              document: 'EODC REDEMPTION LETTER BY DGFT',
              pdf: this.summary.eodc_redemption_path,
            },
            {
              document: 'EODC REJECTION LETTER BY DGFT',
              pdf: this.summary.eodc_rejection_path,
            },
            {
              document: 'OTHER DOCUMENTS',
              pdf: this.summary.others_letters_path,
            },
            {
              document: 'EODC RECEIPT UPDATED BY CUSTOMS',
              pdf: this.summary.eodc_redemption_notice_date,
            },

          ]
        }
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }



  remarksData() {

    if (this.process == 'registration') {
      let application = {
        application_id: this.caseDetailsData.application_id
      }
      this.blockchainService.getRegistrationDocumentByApplicationId(application).subscribe(
        (resp) => {
          if (resp.body.obj == null) {
            this.disRemarks = true;
          } else {
            this.remarksByLetter = resp.body.obj;
            this.remarksByLetter.forEach(element => {
              if(element.doc_name=="IEC COPY"){
                if(element.remarks!=null){
                  this.iec_remarks=true
                }
                
              }else if(element.doc_name=="IEC AUTHORIZATION LETTER"){
                if(element.remarks!=null){
                  this.iec_authorityRemarks=true
                }
                
              }
              else if(element.doc_name=="ADVANCED AUTHORIZATION"){
                if(element.remarks!=null){
                  this.aa_remarks=true
                }
                
              }
              else if(element.doc_name=="BOND"){
                if(element.remarks!=null){
                  this.bond_remarks=true
                }
              }
              else if(element.doc_name=="STAR EXPORT HOUSE COPY"){
                if(element.remarks!=null){
                  this.star_remarks=true
                }
              }
              else if(element.doc_name=="BANK GUARANTEE"){
                if(element.remarks!=null){
                  this.bg_remarks=true
                }
              }
              else if(element.doc_name=="GST REGISTRATION COPY"){
                if(element.remarks!=null){
                  this.gst_remarks=true
                }
              }
              else if(element.doc_name=="LICENCE SCRIPT"){
                if(element.remarks!=null){
                  this.ls_remarks=true
                }
              }
              else if(element.doc_name=="POWER OF ATTORNEY"){
                if(element.remarks!=null){
                  this.poa_remarks=true
                }
              }
               else if(element.doc_name=="AFFADAVIT"){
                if(element.remarks!=null){
                  this.aff_remarks=true
                }
              }
              else if(element.doc_name=="RCMC"){
                if(element.remarks!=null){
                  this.rcmc_remarks=true
                }
              }
              else if(element.doc_name=="TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)"){
                if(element.remarks!=null){
                  this.tc_remarks=true
                }
              }
              else if(element.doc_name=="IDENTITY PROOF - POA"){
                if(element.remarks!=null){
                  this.ipod_remarks=true
                }
              }else if(element.doc_name=="EXPORT PROMOTION CAPITAL GOODS SCHEME"){
                if(element.remarks!=null){
                  this.epcg_remarks=true
                }
              }
              else if(element.doc_name == "IEC HOLDERS AUTHORITY LETTER"){
                if(element.remarks!=null){
                  this.iec_authority_remarks=true
                }
              }
              else if(element.doc_name == "ANY OTHER DOCUMENT"){
                if(element.remarks!=null){
                  this.any_other_document_remarks=true
                }
              }
              else if(element.doc_name == "BG EXEMPTION SUPPORTING DOCUMENT"){
                if(element.remarks!=null){
                  this.noBg_doc=true
                }
              }
            });
          }


        })
    } else {
      let application = {
        cancellation_id: this.caseDetailsData.cancellation_id
      }
      this.blockchainService.getRegistrationDocumentByCancellationId(application).subscribe(
        (resp) => {
          // console.log(resp.body.obj)
          if (resp.body.obj == null) {
            this.disRemarks = true;
          } else {
            this.remarksByLetter = resp.body.obj;
            this.remarksByLetter.forEach(element=>{
              if(element.doc_name=="APPLICATION LETTER"){
                if(element.remarks!=null){
                  this.aL_remarks=true
                }
              }
              // else if(element.doc_name=="IEC LETTER"){
              //   if(element.remarks!=null){
              //     this.iec_letter=true
              //   }
              // }
              else if(element.doc_name=="IEC AUTHORIZATION LETTER"){
                if(element.remarks!=null){
                  this.iec_cancelRemarks=true
                }
              }else if(element.doc_name=="EXPORT OBLIGATION DISCHARGE CERTIFICATE"){
                if(element.remarks!=null){
                  this.eodc_remarks=true
                }
              }else if(element.doc_name=="SURRENDER LETTER"){
                if(element.remarks!=null){
                  this.sL_remarks=true
                }
              }else if(element.doc_name=="REGULARIZATION LETTER"){
                if(element.remarks!=null){
                  this.rg_remarks=true
                }
              }else if(element.doc_name=="LIST OF OTHER DOCUMENTS BEING UPLOADED "){
                if(element.remarks!=null){
                  this.od_remarks=true
                }
              }
              else if(element.doc_name=="OTHER DOCUMENT 1"){
                if(element.remarks!=null){
                  this.od1_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 2"){
                if(element.remarks!=null){
                  this.od2_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 3"){
                if(element.remarks!=null){
                  this.od3_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 4"){
                if(element.remarks!=null){
                  this.od4_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 5"){
                if(element.remarks!=null){
                  this.od5_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 6"){
                if(element.remarks!=null){
                  this.od6_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 7"){
                if(element.remarks!=null){
                  this.od7_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 8"){
                if(element.remarks!=null){
                  this.od8_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 9"){
                if(element.remarks!=null){
                  this.od9_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 10"){
                if(element.remarks!=null){
                  this.od10_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 1"){
                if(element.remarks!=null){
                  this.es1_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 2"){
                if(element.remarks!=null){
                  this.es2_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 3"){
                if(element.remarks!=null){
                  this.es3_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 4"){
                if(element.remarks!=null){
                  this.es4_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 5"){
                if(element.remarks!=null){
                  this.es5_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 6"){
                if(element.remarks!=null){
                  this.es6_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 7"){
                if(element.remarks!=null){
                  this.es7_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 8"){
                if(element.remarks!=null){
                  this.es8_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 9"){
                if(element.remarks!=null){
                  this.es9_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 10"){
                if(element.remarks!=null){
                  this.es10_remarks=true
                }
              }
              if(element.doc_name=="IEC COPY"){
                if(element.remarks!=null){
                  this.iec_letter=true
                }
                
              }else if(element.doc_name=="IEC AUTHORIZATION LETTER"){
                if(element.remarks!=null){
                  this.iec_authorityRemarks=true
                }
                
              }
              else if(element.doc_name=="ADVANCED AUTHORIZATION"){
                if(element.remarks!=null){
                  this.aa_remarks=true
                }
                
              }
              else if(element.doc_name=="BOND"){
                if(element.remarks!=null){
                  this.bond_remarks=true
                }
              }
              else if(element.doc_name=="STAR EXPORT HOUSE COPY"){
                if(element.remarks!=null){
                  this.star_remarks=true
                }
              }
              else if(element.doc_name=="BANK GUARANTEE"){
                if(element.remarks!=null){
                  this.bg_remarks=true
                }
              }
              else if(element.doc_name=="GST REGISTRATION COPY"){
                if(element.remarks!=null){
                  this.gst_remarks=true
                }
              }
              else if(element.doc_name=="LICENCE SCRIPT"){
                if(element.remarks!=null){
                  this.ls_remarks=true
                }
              }
              else if(element.doc_name=="POWER OF ATTORNEY"){
                if(element.remarks!=null){
                  this.poa_remarks=true
                }
              }
               else if(element.doc_name=="AFFADAVIT"){
                if(element.remarks!=null){
                  this.aff_remarks=true
                }
              }
              else if(element.doc_name=="RCMC"){
                if(element.remarks!=null){
                  this.rcmc_remarks=true
                }
              }
              else if(element.doc_name=="TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)"){
                if(element.remarks!=null){
                  this.tc_remarks=true
                }
              }
              else if(element.doc_name=="IDENTITY PROOF - POA"){
                if(element.remarks!=null){
                  this.ipod_remarks=true
                }
              }else if(element.doc_name=="EXPORT PROMOTION CAPITAL GOODS SCHEME"){
                if(element.remarks!=null){
                  this.epcg_remarks=true
                }
              }
              else if(element.doc_name == "IEC HOLDERS AUTHORITY LETTER"){
                if(element.remarks!=null){
                  this.iec_authority_remarks=true
                }
              }
              else if(element.doc_name == "ANY OTHER DOCUMENT"){
                if(element.remarks!=null){
                  this.any_other_document_remarks=true
                }
              }
              else if(element.doc_name == "BG EXEMPTION SUPPORTING DOCUMENT"){
                if(element.remarks!=null){
                  this.noBg_doc=true
                }
              }
            })
          }

        })
    }
  }

  remarksDataBasedOnApplicationId() {
    console.log(this.caseDetailsData);
    if (this.process == 'registration') {
      let application = {
        application_id: this.caseDetailsData
      }
      this.blockchainService.getRegistrationDocumentByApplicationId(application).subscribe(
        (resp) => {
          if (resp.body.obj == null) {
            this.disRemarks = true;
          } else {
            this.remarksByLetter = resp.body.obj;
            this.remarksByLetter.forEach(element => {
              if(element.doc_name=="IEC COPY"){
                if(element.remarks!=null){
                  this.iec_remarks=true
                }
                
              }else if(element.doc_name=="IEC AUTHORIZATION LETTER"){
                if(element.remarks!=null){
                  this.iec_authorityRemarks=true
                }
                
              }
              else if(element.doc_name=="ADVANCED AUTHORIZATION"){
                if(element.remarks!=null){
                  this.aa_remarks=true
                }
                
              }
              else if(element.doc_name=="BOND"){
                if(element.remarks!=null){
                  this.bond_remarks=true
                }
              }
              else if(element.doc_name=="STAR EXPORT HOUSE COPY"){
                if(element.remarks!=null){
                  this.star_remarks=true
                }
              }
              else if(element.doc_name=="BANK GUARANTEE"){
                if(element.remarks!=null){
                  this.bg_remarks=true
                }
              }
              else if(element.doc_name=="GST REGISTRATION COPY"){
                if(element.remarks!=null){
                  this.gst_remarks=true
                }
              }
              else if(element.doc_name=="LICENCE SCRIPT"){
                if(element.remarks!=null){
                  this.ls_remarks=true
                }
              }
              else if(element.doc_name=="POWER OF ATTORNEY"){
                if(element.remarks!=null){
                  this.poa_remarks=true
                }
              }
               else if(element.doc_name=="AFFADAVIT"){
                if(element.remarks!=null){
                  this.aff_remarks=true
                }
              }
              else if(element.doc_name=="RCMC"){
                if(element.remarks!=null){
                  this.rcmc_remarks=true
                }
              }
              else if(element.doc_name=="TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)"){
                if(element.remarks!=null){
                  this.tc_remarks=true
                }
              }
              else if(element.doc_name=="IDENTITY PROOF - POA"){
                if(element.remarks!=null){
                  this.ipod_remarks=true
                }
              }else if(element.doc_name=="EXPORT PROMOTION CAPITAL GOODS SCHEME"){
                if(element.remarks!=null){
                  this.epcg_remarks=true
                }
              }
              else if(element.doc_name == "IEC HOLDERS AUTHORITY LETTER"){
                if(element.remarks!=null){
                  this.iec_authority_remarks=true
                }
              }
              else if(element.doc_name == "ANY OTHER DOCUMENT"){
                if(element.remarks!=null){
                  this.any_other_document_remarks=true
                }
              }
              else if(element.doc_name == "BG EXEMPTION SUPPORTING DOCUMENT"){
                if(element.remarks!=null){
                  this.noBg_doc=true
                }
              }
            });
          }


        })
    } else {
      let application = {
        cancellation_id: this.caseDetailsData
      }
      this.blockchainService.getRegistrationDocumentByCancellationId(application).subscribe(
        (resp) => {
          // console.log(resp.body.obj)
          if (resp.body.obj == null) {
            this.disRemarks = true;
          } else {
            this.remarksByLetter = resp.body.obj;
            this.remarksByLetter.forEach(element=>{
              if(element.doc_name=="APPLICATION LETTER"){
                if(element.remarks!=null){
                  this.aL_remarks=true
                }
              }
              // else if(element.doc_name=="IEC LETTER"){
              //   if(element.remarks!=null){
              //     this.iec_letter=true
              //   }
              // }
              else if(element.doc_name=="IEC AUTHORIZATION LETTER"){
                if(element.remarks!=null){
                  this.iec_cancelRemarks=true
                }
              }else if(element.doc_name=="EXPORT OBLIGATION DISCHARGE CERTIFICATE"){
                if(element.remarks!=null){
                  this.eodc_remarks=true
                }
              }else if(element.doc_name=="SURRENDER LETTER"){
                if(element.remarks!=null){
                  this.sL_remarks=true
                }
              }else if(element.doc_name=="REGULARIZATION LETTER"){
                if(element.remarks!=null){
                  this.rg_remarks=true
                }
              }else if(element.doc_name=="LIST OF OTHER DOCUMENTS BEING UPLOADED "){
                if(element.remarks!=null){
                  this.od_remarks=true
                }
              }
              else if(element.doc_name=="OTHER DOCUMENT 1"){
                if(element.remarks!=null){
                  this.od1_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 2"){
                if(element.remarks!=null){
                  this.od2_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 3"){
                if(element.remarks!=null){
                  this.od3_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 4"){
                if(element.remarks!=null){
                  this.od4_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 5"){
                if(element.remarks!=null){
                  this.od5_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 6"){
                if(element.remarks!=null){
                  this.od6_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 7"){
                if(element.remarks!=null){
                  this.od7_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 8"){
                if(element.remarks!=null){
                  this.od8_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 9"){
                if(element.remarks!=null){
                  this.od9_remarks=true
                }
              }else if(element.doc_name=="OTHER DOCUMENT 10"){
                if(element.remarks!=null){
                  this.od10_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 1"){
                if(element.remarks!=null){
                  this.es1_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 2"){
                if(element.remarks!=null){
                  this.es2_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 3"){
                if(element.remarks!=null){
                  this.es3_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 4"){
                if(element.remarks!=null){
                  this.es4_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 5"){
                if(element.remarks!=null){
                  this.es5_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 6"){
                if(element.remarks!=null){
                  this.es6_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 7"){
                if(element.remarks!=null){
                  this.es7_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 8"){
                if(element.remarks!=null){
                  this.es8_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 9"){
                if(element.remarks!=null){
                  this.es9_remarks=true
                }
              }else if(element.doc_name=="EXCEL SHEET 10"){
                if(element.remarks!=null){
                  this.es10_remarks=true
                }
              }
              if(element.doc_name=="IEC COPY"){
                if(element.remarks!=null){
                  this.iec_letter=true
                }
                
              }else if(element.doc_name=="IEC AUTHORIZATION LETTER"){
                if(element.remarks!=null){
                  this.iec_authorityRemarks=true
                }
                
              }
              else if(element.doc_name=="ADVANCED AUTHORIZATION"){
                if(element.remarks!=null){
                  this.aa_remarks=true
                }
                
              }
              else if(element.doc_name=="BOND"){
                if(element.remarks!=null){
                  this.bond_remarks=true
                }
              }
              else if(element.doc_name=="STAR EXPORT HOUSE COPY"){
                if(element.remarks!=null){
                  this.star_remarks=true
                }
              }
              else if(element.doc_name=="BANK GUARANTEE"){
                if(element.remarks!=null){
                  this.bg_remarks=true
                }
              }
              else if(element.doc_name=="GST REGISTRATION COPY"){
                if(element.remarks!=null){
                  this.gst_remarks=true
                }
              }
              else if(element.doc_name=="LICENCE SCRIPT"){
                if(element.remarks!=null){
                  this.ls_remarks=true
                }
              }
              else if(element.doc_name=="POWER OF ATTORNEY"){
                if(element.remarks!=null){
                  this.poa_remarks=true
                }
              }
               else if(element.doc_name=="AFFADAVIT"){
                if(element.remarks!=null){
                  this.aff_remarks=true
                }
              }
              else if(element.doc_name=="RCMC"){
                if(element.remarks!=null){
                  this.rcmc_remarks=true
                }
              }
              else if(element.doc_name=="TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)"){
                if(element.remarks!=null){
                  this.tc_remarks=true
                }
              }
              else if(element.doc_name=="IDENTITY PROOF - POA"){
                if(element.remarks!=null){
                  this.ipod_remarks=true
                }
              }else if(element.doc_name=="EXPORT PROMOTION CAPITAL GOODS SCHEME"){
                if(element.remarks!=null){
                  this.epcg_remarks=true
                }
              }
              else if(element.doc_name == "IEC HOLDERS AUTHORITY LETTER"){
                if(element.remarks!=null){
                  this.iec_authority_remarks=true
                }
              }
              else if(element.doc_name == "ANY OTHER DOCUMENT"){
                if(element.remarks!=null){
                  this.any_other_document_remarks=true
                }
              }
              else if(element.doc_name == "BG EXEMPTION SUPPORTING DOCUMENT"){
                if(element.remarks!=null){
                  this.noBg_doc=true
                }
              }
            })
          }

        })
    }
  }

  openRemarks(data) {
    console.log("open",data);
    if (this.remarksByLetter != null) {
      this.remarksByLetter.forEach(remrk => {
        if (remrk.doc_name == data.document) {
          if (remrk.remarks != '') {
            this.openDialog(
              "paymentSum",
              remrk.remarks,
              ""
            );
          } else {
            
          }
        }

      })
    }


  }

  openPdf(data) {
    console.log(data);
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result.event === "paymentSum" && result.isUpdated === true) {
        this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
