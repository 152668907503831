import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  UntypedFormGroup,
} from "@angular/forms";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService, SummaryDetailsQuery1, SectionDetailsQuery } from "app/blockchainImpExp/blockchain.service";
import { MatTableDataSource } from "@angular/material/table";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}
export class MobileModel {
  mobile:string;
}
export interface ManageSection {
  no: number;
  zone_name: string;
  commissionerate: string;
  section:string;
  emails:string;
  mobile:string;
  addMobileNo:string;
}
// Interface
export interface MyInterface {
  attribute: string;
  name: string;
}

@Component({
  selector: 'app-manage-section',
  templateUrl: './manage-section.component.html',
  styleUrls: ['./manage-section.component.css']
})
export class ManageSectionComponent implements OnInit {

  selectedStatus = 'New';
  
  allStatus: Status[] = [
    {value: 'Incoming Date', viewValue: 'Incoming Date'},
    {value: 'Application Type', viewValue: 'Application Type'},
    {value: 'Application Stage', viewValue: 'Application Stage'},
    {value: 'File No', viewValue: 'File No'},
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  currentUser: any;
  role: any;
  dataSource:ManageSection[] = [];
  myForm: UntypedFormGroup;
  submitted = false;

  // Variables for Data Table
  public columns = [];
  public dataSourceAddMobile: MatTableDataSource<MobileModel> = null;
  public index: number;
  public id: any;

  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  displayedColumns = [
  'no',
  'zone_name',
  'commissionerate',
  'section',
  'emails',
  'mobile',
  'addMobileNo',
  'action'
  ];
  public displayedAddMobileColumns: MyInterface[] = [
    { attribute: "mobile", name: "Mobile" }
  ];
  autoFillForm = this.formBuilder.group({
    username: [""],
    companyname: [""],
    iecCode: [""],
  });
  getCustomsSection: any;
  sectionDetails: any;
  sectionAddMobileDetails: any;
  mobile: string;
  mob: any;
  summaryData: PaginatedDataSource<ManageSection, SectionDetailsQuery>;
  initialSort: Sort<ManageSection> = { property: "no", order: "desc" };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = this.currentUser.obj.user_type_id;
    this.getAllSectionManagement();
    this.columns = this.displayedAddMobileColumns.map(c => c.attribute);

    if (this.dataSourceAddMobile === null) {
      this.dataSourceAddMobile = new MatTableDataSource([]);
    }

    // myForm
    this.myForm = this.formBuilder.group({
      rows: this.formBuilder.array([this.initItemRows()])
    });

    this.dataSourceAddMobile.data = this.myForm.get("rows").value;
    //this.useFormRows();
     
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.myForm.controls[controlName].hasError(errorName);
  };

  initItemRows() {
    return this.formBuilder.group({
      mobile: ["",[Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });
  }
  get formArr() {
    return this.myForm.get("rows") as UntypedFormArray;
  }
  // To add new row
  addRow() {
    const newItem = this.initItemRows();
    this.formArr.push(newItem);
    this.dataSourceAddMobile.data = this.dataSourceAddMobile.data.concat(newItem.value);
  }
  removeRow(i) {
    if (i !== -1) {
      this.dataSourceAddMobile.data = this.dataSourceAddMobile.data.splice(1);
    }        
  }

  saveMoreMobileNos(){
   
    for(let i=0;i<this.myForm.controls["rows"].value.length;i++){
      if(i==0){
      this.mobile = this.myForm.controls["rows"].value[0].mobile;
      }
      if(i!=0){
      this.mobile = this.mobile.concat(",",this.myForm.controls["rows"].value[i].mobile);
      console.log(this.myForm.controls["rows"].value[i].mobile.length)
      }
    }
    this.mobile = this.mobile.concat(",",this.sectionAddMobileDetails.mobile);
    this.sectionAddMobileDetails.mobile = this.mobile;
    
    this.blockchainService.updateSectionManagement(this.sectionAddMobileDetails).subscribe(
      (data) => {
        if (data.status == 200) {
          // console.log(data.body);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Mobile No Successfully Added",
            "announcement"
          );
          setTimeout(() => {
            this.router.navigate(["/manageSections"]);
          }, 3000)
        }

      },
      (error) => {
        // console.log("fail", error);
        this.showNotification(
          "bottom",
          "right",
          "warning",
          "Error..Please try again",
          "announcement"
        );
      }
    );
  
  }

  addSection(){
    let action = "Add";
    // this.blockchainService.adminAction = action;
    localStorage.setItem('adminAction', JSON.stringify(action));
    this.router.navigate(["/addSection"]);
    }

    editSection(user){
    localStorage.setItem('adminUser', JSON.stringify(user));
    let action = "Edit";
    // this.blockchainService.adminAction = action;
    localStorage.setItem('adminAction', JSON.stringify(action));
    this.router.navigate(["/addSection"]);
    }

  deleteSection(){
   let sectionData = this.sectionDetails;
    let input = {
      section_management_communication_id: sectionData.section_management_communication_id,
    };
    this.blockchainService.deleteSectionManagement(input).subscribe(
     (resp) => {
       this.getCustomsSection = resp.body.obj;
       if(resp.status == 200){
        this.getAllSectionManagement();
       this.showNotification(
             "bottom",
             "right",
             "success",
             resp.body.message,
             "announcement"
           );
           setTimeout(() => {
           location.reload();
          }, 3000)
     }else{
       this.showNotification(
         "bottom",
         "right",
         "warning",
         "Failed To Delete User",
         "announcement"
       );
     }
   });
 }

 openModal(section) {
  this.sectionDetails = section;
  const buttonModal = document.getElementById("openModalButton");
  buttonModal.click();
} 

openModalAddMobile(section) {
  this.sectionAddMobileDetails = section;
  const buttonModal = document.getElementById("openModalButton");
  // buttonModal.click();
} 

  caseDetailsDialogue(data){
    localStorage.setItem('casedetailsData',JSON.stringify(data));
  }

  navigateToRegister(){
    this.router.navigate(['/registerTask']);
  }

  getAllSectionManagement(){
    this.blockchainService.getAllSectionManagement().subscribe(
      (resp) => {
        this.getCustomsSection = resp.obj;
        this.dataSource = this.getCustomsSection;
        if (this.dataSource !== null) {
          var summaryList = resp.obj;
          this.summaryData = new PaginatedDataSource<ManageSection, SectionDetailsQuery>(
            (request, query) =>
              this.blockchainService.pageManageSection(request, query, summaryList),
            this.initialSort,
            { search: "", data: undefined },
            10
          );
        }
        // console.log(this.getCustomsSection)
      });
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
