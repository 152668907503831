import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface PeriodicElement {
  no: number;
  document: string;
  viewPdf: string;
  inititate: string;
  notesheet: string;
  endorse: string;
  validate: string;
  remarks: string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {
    no: 1,
    document: "IEC COPY",
    viewPdf:
      "content/customs/6ec6b3c2-16cc-4803-b3f3-b33e02051bcc/new_request/Holiday List 2021.pdf",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },
  {
    no: 2,
    document: "GST REGISTRATION COPY",
    viewPdf: "",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },
  {
    no: 3,
    document: "LICENSE SCRIPT",
    viewPdf: "",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },
  {
    no: 4,
    document: "STAR EXPORT HOUSE COPY",
    viewPdf: "",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },
  {
    no: 5,
    document: "POWER OF ATTORNEY ",
    viewPdf: "",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },
  {
    no: 6,
    document: "BANK GUARANTEE",
    viewPdf: "",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },
  {
    no: 7,
    document: "BOND",
    viewPdf: "",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },

  {
    no: 8,
    document: "AFFADAVIT",
    viewPdf: "",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },
  {
    no: 9,
    document: "RCMC",
    viewPdf: "",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },
  {
    no: 10,
    document: "TURNOVER CERTIFICATE",
    viewPdf: "",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },
  {
    no: 11,
    document: "IDENTITY PROOF - POA ",
    viewPdf: "",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },
  {
    no: 12,
    document: "IDENTITY PROOF - BOND WITNESS",
    viewPdf: "",
    inititate: "YES",
    notesheet: "",
    endorse: "",
    validate: "",
    remarks: "",
  },
];
@Component({
  selector: 'app-register-initiate',
  templateUrl: './register-initiate.component.html',
  styleUrls: ['./register-initiate.component.css']
})



export class RegisterInitiateComponent implements OnInit {
  selectedStatus = "";
  dataSource = ELEMENT_DATA;
  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  displayedColumns = [
    "no",
    "document",
    "viewPdf",
    "initiate",
    "endorse",
    "validate",
    "remarks",
  ];
  alertStatus:string;
  autoFillForm = this.formBuilder.group({
    name: [""],
    section: [""],
    designation: [""],
  });
  caseDetailsFillForm = this.formBuilder.group({
    impexpname: [""],
    iecCode: [""],
    applicationDate: [""],
    fileIssueDate:[""],
    fileNo: [""],
    alert:[""],
  });

  initiateNotesheetForm = this.formBuilder.group({
    notesheetDetails: ["", Validators.required],
  });

  currentUser: any;
  caseDetailsData: any;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));
   
    this.caseDetailsFillForm.setValue({
      impexpname: this.caseDetailsData.impexpName,
      iecCode: this.caseDetailsData.iecCode,
      applicationDate: this.caseDetailsData.inititionDate,
      fileNo: this.caseDetailsData.fileNo,
      alert:this.caseDetailsData.status,
      fileIssueDate:this.caseDetailsData.notesheetDate,
    });

    this.autoFillForm.setValue({
      name: this.currentUser.obj.first_name + this.currentUser.obj.last_name,
      section:null,
      designation: this.currentUser.obj.designation,
    });
  }

  backReceipt() {
    this.router.navigate(["/registerTask"]);
  }

  navigateProvideBond() {
    this.router.navigate(["/provideBond"]);
  }
  navigateOnValidation() {
    this.router.navigate(["/validation"]);
  }
  back() {
    this.router.navigate(["/registerLicenseTa"]);
  }
}
