import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit,ViewChild,ElementRef } from "@angular/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  
} from "@angular/forms";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { PaymentsumComponent } from "../paymentsum/paymentsum.component";
import { DateAdapter } from "@angular/material/core";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}
export interface PeriodicElement {
  no: number;
  documentType: string;
  documentNo: string;
  documentDate: string;
  amount: string;
  remarks:string;
  action:any
}

export interface input {
  document_type: string;
  document_no: string;
  document_date: string;
  amount_in_inr: string;
  remarks:string;
  payment_id:string;
}
const ELEMENT_DATA: PeriodicElement[] = [
  {
    no: 1,
    documentType: "",
    documentNo: "",
    documentDate: "",
    amount: "",
    remarks:"",
    action:""
  },
];


@Component({
  selector: 'app-supporting-doc',
  templateUrl: './supporting-doc.component.html',
  styleUrls: ['./supporting-doc.component.css']
})
export class SupportingDocComponent implements OnInit {
  @ViewChild("inputFile") inputFile: ElementRef;
  isExcelFile: boolean;
  selectedFile: File;
   loader: boolean;
  displayedColumns = [
    "no",
    "documentType",
    "documentNo",
    "documentDate",
    "amount",
    "remarks",
    "action"

    ];
    //dataSource = ELEMENT_DATA;
    dataSource = new BehaviorSubject<AbstractControl[]>([]);
  
    pipe = new DatePipe("en-US");
  
    selectedStatus = "";
  
    allStatus: Status[] = [
      { value: "YES", viewValue: "YES" },
      { value: "NO", viewValue: "NO" },
    ];
    payment_id:string

    @BlockUI() blockUI: NgBlockUI;
    blockTemplate: BlockTemplateComponent;
    role: any;
  allDocType: any;
  allDocType1: any;
  active: boolean = false;
  url: string | ArrayBuffer;

  today: number = Date.now();
  now = Date.now();
  todayDate = this.pipe.transform(this.now, "yyyy-MM-dd", "en-US");

  doc_master: UntypedFormArray = this.formBuilder.array([]);
  doc: UntypedFormGroup = this.formBuilder.group({ doc_master: this.doc_master });
  data: PeriodicElement[] = [
    {
      no: 1,
      documentType: "",
      documentNo: "",
      documentDate: "",
      amount: "",
      remarks:"",
      action:""
    },
  ];
  

  input:input[]=[];
  removeRowNo: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  
  
  ngOnInit(): void {
    this.getDocumentType();
    this.data.forEach((d: PeriodicElement) => this.addRowDoc(d, false));
    this.updateView();
    this.payment_id = localStorage.getItem("payment_id");
   
    
  }

 
  // uploadXLS(){
  //   this.openDialog(
  //     "paymentSum",
  //     "This feature will be coming soon",
  //     "" 
  //        );
  // }

  onFileSelected(event) {
    console.log("clicked");
    console.log("clicked1",event);
    this.selectedFile = <File>event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); // read file as data url

    reader.onload = (event) => {
      // called once readAsDataURL is completed
      this.url = event.target.result;
    };
    this.onUpload();
  }

  onUpload() {
    const fd = new FormData();
    fd.append("file", this.selectedFile, this.selectedFile.name);
    this.uploadSupportingDocumentXls();
  }

  uploadSupportingDocumentXls(){
    const fd = new FormData();
    let file = this.selectedFile;

    let payment_id = JSON.parse(localStorage.getItem("payment_id"));
    console.log(payment_id,"payment_id=------");

    //let added_by = currentUser.obj.user_id;
    this.loader=true;
    this.blockUI.start("Please wait this may take few seconds ...");

    this.blockchainService.uploadSupportingDocXls(file,payment_id).subscribe({next:(resp:any)=>{
      this.allDocType1=resp.obj;
      if(resp.status==200){
        this.loader=false;
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.message,
          "announcement"
        );
        // this.router.navigate(["/account"])
      }else{
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.loader=false;
        this.openDialog(
          "supportingdoc",
          resp.message,
          ""
        );
      }
    }})
    
  }

back(){

}

  addRowDoc(d?: PeriodicElement, noUpdate?: boolean) {
    const newRow = this.formBuilder.group({
      no: Date.now(),
      documentType: [d && d.documentType ? d.documentType : null],
      documentNo: [d && d.documentNo ? d.documentNo : null],
      documentDate: [d && d.documentDate ? d.documentDate : null],
      amount: [d && d.amount ? d.amount : null],
      remarks:[d && d.remarks ? d.remarks : null],
      action: [null],
    });
    console.log(newRow,'dd');
    this.doc_master.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateView();
    }
  }

  updateView() {
    this.dataSource.next(this.doc_master.controls);
  }

  activeFn(){
    this.active = true;
  }
  getDocumentType(){
    this.blockchainService.getDocumentType().subscribe(resp=>{
      this.allDocType = resp.obj
    })
  }

  next(){
   // console.log(this.doc);
  
   for (let i = 0; i < this.doc.value.doc_master.length; i++) {
    if(this.doc.value.doc_master[i].amount===undefined || this.doc.value.doc_master[i].amount===null){
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Amount No Cannot be Empty",
        "announcement"
      );
      break;
   }else if(this.doc.value.doc_master[i].documentType===null){
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Document Type Cannot be Empty",
        "announcement"
      );
      break;
   }else if(this.doc.value.doc_master[i].documentNo===undefined){
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Document No Cannot be Empty",
        "announcement"
      );
      break;
   }else if(this.doc.value.doc_master[i].documentDate===undefined){
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Document Date Cannot be Empty",
        "announcement"
      );
      break;
   }else{
    this.input.push({
      document_type: this.doc.value.doc_master[i].documentType,
      document_no:  this.doc.value.doc_master[i].documentNo,
      document_date:  this.doc.value.doc_master[i].documentDate,
      amount_in_inr:  JSON.stringify(this.doc.value.doc_master[i].amount),
      remarks:this.doc.value.doc_master[i].remarks,
      payment_id:this.payment_id ,
     })
   }
   }
   if(this.input.length>0){
    this.saveSupportingDocument()
   }
  
   
   
   
//this.router.navigate(["/account"])
  }

  saveSupportingDocument() {
    let input={
      "sdm":this.input
    }
    this.blockUI.start("Please wait this may take few seconds ...");

    this.blockchainService
      .saveSupportingDocument(input)
      .subscribe((resp) => {
        console.log(resp.body);
        if(resp.body.status==200){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1000);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.router.navigate(["/account"])
        }else{
          setTimeout(() => {
            this.blockUI.stop();
          }, 1000);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }

      });
  }

  openModal(no) {
    this.removeRowNo = no;
    const buttonModal = document.getElementById("openModalButton");
    buttonModal.click();
  }
   
    removeRow() {
      let no = this.removeRowNo
      console.log(no,"Row Number");
      console.log( this.doc.value.doc_master,'before')
      const roomArr: any[] = this.dataSource.getValue();
      roomArr.forEach((item, index) => {
        if (item === no) { roomArr.splice(index, 1);
          this.doc.value.doc_master.splice(index, 1);
        }
      });
      console.log( this.doc.value.doc_master,'afterrrrrr')

      this.dataSource.next(roomArr);
      console.log(this.input,'this.input');
    }

  
    openDialog(action: string, obj: any, element: any) {
      const dialogConfig = new MatDialogConfig();
      // obj.action = action;
      // obj.object_id = element;
      // dialogConfig.backdropClass = "bdrop";
      dialogConfig.panelClass = "dialog-responsive";
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = obj;
      // dialogConfig.height = "500px";
      // dialogConfig.width = "850px";
  
      let dialogRef;
  
      if (action == "supportingdoc") {
        // dialogConfig.height = '500px';
        //   dialogConfig.width = '750px';
        dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
      }
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result.event === "supportingdoc" && result.isUpdated === true) {
          //this.showNotification("bottom", "right", "success", "", "announcement");
        }
      });
    }
    

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
