import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaymentsumComponent } from "../../payment/paymentsum/paymentsum.component";
import { AlertService } from "app/_services";

declare var $: any;

@Component({
  selector: "app-cancellation-details",
  templateUrl: "./cancellation-details.component.html",
  styleUrls: ["./cancellation-details.component.css"],
})
export class CancellationDetailsComponent implements OnInit {
  cancelAuth:boolean=false
  saveBtn:boolean=false;
  allApplicationTypes: any;
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  auth_number: any;
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  now = Date.now();
  date: any;
  modifiedCurrentDate: any;
  currentUser: any;
  cancellation_id: any;
  cancellationForm = this.formBuilder.group({
    iec_number: [
      "",
      [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern("^[a-zA-Z0-9]{10}$"),
      ],
    ],
    role: ["", Validators.required],
    name_of_firm: ["", Validators.required],
    auth_type: ["", Validators.required],
    auth_number: ["", Validators.required],
    auth_issue_date: ["", Validators.required],
    dgft_surrender_letter_no: [""],
    dgft_surrender_letter_date: [""],
    eodc_letter_no: [""],
    eodc_letter_date: [""],
    dgft_regularization_letter_no: [""],
    dgft_regularization_letter_date: [""],
    scn_pending: [""],
    
  });
  getScnDetailsByAuth: any;
  role: any;
  cancelId:any;
  applicationType: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale("en-GB"); //dd/MM/yyyy
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.cancelId=localStorage.getItem('cancellation_id');
    this.applicationType = localStorage.getItem('applicationType');
    
    if (this.currentUser.obj.role == "3") {
      this.role = "IEC";
    } else if (this.currentUser.obj.role == "1") {
      this.role = "Customs Broker";
    } else {
    }
    // this.getApplicationType();
    // this.getApplicationById();

    this.cancellationForm.patchValue({
      role: this.role,
    });
    // this.cancellation_id =  JSON.parse(localStorage.getItem("cancellation_id"));
    // this.getCancellationById(this.cancellation_id);
    this.getApplicationType();
    if(this.cancelId){
      this.cancelAuth = true
      this.getCancelDetails(this.cancelId)
    }
  }

  get f() {
    return this.cancellationForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.cancellationForm.controls[controlName].hasError(errorName);
  };

  authorize(e, auth_no) {
    console.log(e, "event that is being generated");
    if (e.value == "YES") {
      // this.isIecAuthorityLetter = true;
      this.openDialog(
        "paymentSum",
        "There is a Show Cause Notice pending against this application. Your application will be processed after the pending Show Cause Notice is resolved at your end  by summitting required documents or making necessary payments",
        ""
      );
    } else {
      this.getScnDetailsByAuthNumber(this.auth_number);
    }
  }

  checkAuthNumber(e) {
    if(this.cancelId){
      this.cancelAuth = true
    }else{
      this.cancelAuth = false
      console.log(e.target.value, "checking iec no");
      let input = {
        auth_number: e.target.value,
      };
      this.blockchainService.checkAuthNoCancellation(input).subscribe(
        (resp) => {
          if (resp.body.status == 200) {
            this.cancelAuth = true
            // this.iecNo = e.target.value
            // localStorage.setItem('iec_value',e.target.value)
          } else {
            this.cancelAuth = false
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );
          }
        },
        (error) => {
          console.log(error);
          this.alertService.error(error);
          if (error.status == 401) {
            location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Session Expired, Please Login Again",
              "announcement"
            );
            this.router.navigate(["/login"]);
          } else if (error.status == 500) {
            location.reload();
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Try Again",
              "announcement"
            );
          } else {
          }
        }
      );
    }

  }

  getCancelDetails(id:any){
    let input = {
      cancellation_id: id,
    };
    this.blockchainService.getCancellationById(input).subscribe( (resp) => {
      var cancelDetails = resp.obj;
 console.log(cancelDetails)
 this.cancellationForm.patchValue({
  iec_number: cancelDetails.iec_number,
  role: cancelDetails.role,
  name_of_firm: cancelDetails.name_of_firm,
  auth_type: cancelDetails.auth_type,
  auth_number: cancelDetails.auth_number,
  auth_issue_date: cancelDetails.auth_issue_date == null?'':new Date(cancelDetails.auth_issue_date),
  dgft_surrender_letter_no: cancelDetails.dgft_surrender_letter_no,
  dgft_surrender_letter_date: cancelDetails.dgft_surrender_letter_date == null?'':new Date(cancelDetails.dgft_surrender_letter_date),
  eodc_letter_no: cancelDetails.eodc_letter_no,
  eodc_letter_date: cancelDetails.eodc_letter_date == null?'':new Date(cancelDetails.eodc_letter_date),
  dgft_regularization_letter_no: cancelDetails.dgft_regularization_letter_no,
  dgft_regularization_letter_date: cancelDetails.dgft_regularization_letter_date == null?'':new Date(cancelDetails.dgft_regularization_letter_date),
  scn_pending: cancelDetails.scn_pending,

 })
 this.cancellation_id = cancelDetails.cancellation_id

  // localStorage.removeItem('cancellation_id');
    },
    (error) => {
      console.log(error);
      this.alertService.error(error);
      if (error.status == 401) {
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      } else if (error.status == 500) {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      } else {
      }
    })
  }

  checkIecNo(e) {
    console.log(e.target.value, "checking iec no");
    let input = {
      iec_no: e.target.value,
    };
    this.blockchainService.checkIecNo(input).subscribe(
      (resp) => {
        if (resp.body.status == 200) {
          // this.iecNo = e.target.value
          // localStorage.setItem('iec_value',e.target.value)
        } else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        if (error.status == 401) {
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        } else if (error.status == 500) {
          location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        } else {
        }
      }
    );
  }

  getScnDetailsByAuthNumber(authNo) {
    let input = {
      auth_number: authNo,
      applicationType: this.applicationType ? this.applicationType : 'AA'
    };
    this.blockchainService.getScnDetailsByAuthNumber(input).subscribe(
      (resp) => {
        this.getScnDetailsByAuth = resp.body.obj;
        if (this.getScnDetailsByAuth != null) {
          this.openDialog(
            "paymentSum",
            "There is a Show Cause Notice pending against this application. Your application will be processed after the pending Show Cause Notice is resolved at your end  by summitting required documents or making necessary payments",
            ""
          );
        }
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        if (error.status == 401) {
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        } else if (error.status == 500) {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        } else {
        }
      }
    );
  }

  getApplicationType() {
    this.blockchainService.getApplicationType().subscribe(
      (resp) => {
        this.allApplicationTypes = resp.obj;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        if (error.status == 401) {
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        } else if (error.status == 500) {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        } else {
        }
      }
    );
  }

  getCancellationById(input) {
    let user = {
      cancellation_id: input,
    };
    this.blockchainService.getCancellationById(user).subscribe(
      (resp) => {
        console.log(resp.obj.auth_type);
        this.cancellationForm.patchValue({
          auth_type: resp.obj.auth_type,
        });
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        if (error.status == 401) {
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        } else if (error.status == 500) {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        } else {
        }
      }
    );
  }

  back() {
    this.router.navigate(["/cancelDocUpload"]);
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  updateCancellationByPage() {
    console.log(this.cancellationForm.value);
    let cancellation_id = JSON.parse(localStorage.getItem("cancellation_id"));
    console.log(cancellation_id);
    if (cancellation_id == null) {
      this.blockUI.start("Please wait this may take few seconds ...");
      let input = {
        created_by: this.currentUser.obj.user_id,
        cancellation_id: cancellation_id,
        iec_number: this.cancellationForm.value.iec_number,
        role: this.cancellationForm.value.role,
        name_of_firm: this.cancellationForm.value.name_of_firm,
        auth_type: this.cancellationForm.value.auth_type,
        auth_number: this.cancellationForm.value.auth_number.trim(),
        auth_issue_date: this.pipe.transform(
          this.cancellationForm.value.auth_issue_date,
          "yyyy-MM-dd HH:mm:ss"
        ),
        dgft_surrender_letter_no:
          this.cancellationForm.value.dgft_surrender_letter_no.trim(),
        dgft_surrender_letter_date: this.pipe.transform(
          this.cancellationForm.value.dgft_surrender_letter_date,
          "yyyy-MM-dd HH:mm:ss"
        ),
        eodc_letter_no: this.cancellationForm.value.eodc_letter_no.trim(),
        eodc_letter_date: this.pipe.transform(
          this.cancellationForm.value.eodc_letter_date,
          "yyyy-MM-dd HH:mm:ss"
        ),
        dgft_regularization_letter_no:
          this.cancellationForm.value.dgft_regularization_letter_no.trim(),
        dgft_regularization_letter_date: this.pipe.transform(
          this.cancellationForm.value.dgft_regularization_letter_date,
          "yyyy-MM-dd HH:mm:ss"
        ),
        scn_pending: this.cancellationForm.value.scn_pending,
      };

      console.log(input, "input");
      this.blockchainService.saveApplicationV1(input).subscribe(
        (resp) => {
          if (resp.status == 200) {
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            localStorage.setItem(
              "cancellation_id",
              JSON.stringify(resp.obj)
            );
            localStorage.setItem(
              "iec_code",
              JSON.stringify(this.cancellationForm.value.iec_number)
            );
            if(!this.saveBtn){
              setTimeout(() => {
                this.router.navigate(["/cancelDetails"]);
                // this.router.navigate(["/autherizationRequest"]);
              }, 3000);
            }
         
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.message,
              "announcement"
            );
          } else {
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.message,
              "announcement"
            );
          }
        },
        (error) => {
          console.log(error);
          this.alertService.error(error);
          if (error.status == 401) {
            // location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Session Expired, Please Login Again",
              "announcement"
            );
            this.router.navigate(["/login"]);
          } else if (error.status == 500) {
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            // location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Try Again",
              "announcement"
            );
          } else {
          }
        }
      );
    } else {
      this.save();
      this.saveBtn = false
    }
  }

  // updateCancellationByPage(){
  //   this.router.navigate(["/cancelDetails"]);
  // }

  save() {
    
    let cancel_id = JSON.parse(localStorage.getItem("cancellation_id"));
    if (cancel_id == null) {
      this.updateCancellationByPage();
      this.saveBtn = true
    } else {
      this.blockUI.start("Please wait this may take few seconds ...");
      console.log(this.cancellationForm.value);
      let input = {
        cancellation_id: cancel_id,
        created_by: this.currentUser.obj.user_id,
        iec_number: this.cancellationForm.value.iec_number,
        name_of_firm: this.cancellationForm.value.name_of_firm,
        auth_type: this.cancellationForm.value.auth_type,
        role: this.cancellationForm.value.role,
        auth_number: this.cancellationForm.value.auth_number.trim(),
        auth_issue_date: this.pipe.transform(
          this.cancellationForm.value.auth_issue_date,
          "yyyy-MM-dd HH:mm:ss"
        ),
        dgft_surrender_letter_no:
          this.cancellationForm.value.dgft_surrender_letter_no.trim(),
        dgft_surrender_letter_date: this.pipe.transform(
          this.cancellationForm.value.dgft_surrender_letter_date,
          "yyyy-MM-dd HH:mm:ss"
        ),
        eodc_letter_no: this.cancellationForm.value.eodc_letter_no.trim(),
        eodc_letter_date: this.pipe.transform(
          this.cancellationForm.value.eodc_letter_date,
          "yyyy-MM-dd HH:mm:ss"
        ),
        dgft_regularization_letter_no:
          this.cancellationForm.value.dgft_regularization_letter_no.trim(),
        dgft_regularization_letter_date: this.pipe.transform(
          this.cancellationForm.value.dgft_regularization_letter_date,
          "yyyy-MM-dd HH:mm:ss"
        ),
        scn_pending: this.cancellationForm.value.scn_pending,
      };
      this.blockchainService.updateApplicationV1(input).subscribe(
        (resp) => {
          if (resp.status == 200) {
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            localStorage.setItem(
              "cancellation_id",
              JSON.stringify(resp.obj)
            );
            localStorage.setItem(
              "iec_code",
              JSON.stringify(this.cancellationForm.value.iec_number)
            );
            if(!this.saveBtn){
              setTimeout(() => {
                this.router.navigate(["/cancelDetails"]);
                // this.router.navigate(["/autherizationRequest"]);
              }, 3000);
            }
            // this.router.navigate(["/enterProofOfPayment"]);
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );

            // setTimeout(() => {
            //   this.router.navigate(["/autherizationRequest"]);
            // }, 3000);
          } else {
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );
          }
        },
        (error) => {
          console.log(error);
          this.alertService.error(error);
          if (error.status == 401) {
            // location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Session Expired, Please Login Again",
              "announcement"
            );
            this.router.navigate(["/login"]);
          } else if (error.status == 500) {
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            // location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Try Again",
              "announcement"
            );
          } else {
          }
        }
      );
    }
  }
  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "280px";
    dialogConfig.width = "475px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(CustomScreeningMessageComponent, dialogConfig);
    }
    if (action == "paymentSum") {
      dialogConfig.height = "280px";
      dialogConfig.width = "475px";
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }
  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
