import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService, ImportAAQuery, SCNQuery } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";

declare var $: any;


export interface Year {
  value: string;
  viewValue: string;
}

export interface SCN {
  iec_no: String,
  aa_no: String,
  scn_no: String,
  scn_date: String,
  amount: String,
}

@Component({
  selector: 'app-month-recovery',
  templateUrl: './month-recovery.component.html',
  styleUrls: ['./month-recovery.component.css']
})
export class MonthRecoveryComponent implements OnInit {
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  date: any;
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  initialSort: Sort<SCN> = { property: "scn_date", order: "asc" };

  selectedYear = ['2021-2022', '2021-2022'];
  
  allYear: Year[] = [
    {value: '2021-2022', viewValue: '2021-2022'},
    {value: '2020-2021', viewValue: '2020-2021'},
    {value: '2019-2020', viewValue: '2019-2020'},
  ];

  displayedColumns = [
    "month",
    "amount",
  ];
  recoveryResp: any;
  year1: any;
  year2: any;
  monthRecoveryResp: any;
 
  startYear = new Date().getFullYear();
  range = [];
  totalResult: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { }
loader:boolean;
  ngOnInit(): void {
    // this.loader=true;
    this.date = this.todayDate;
    for (let i = 0; i < 5; i++) {
      this.range.push((this.startYear - i)+ '-' + (this.startYear - i + 1));
    }
    this.getMonthWiseRecoveryByYear();

  }

  selectYearFn(v){
    console.log(v,'selected year')
    let years  = v.split("-");
    this.year1 = years[0]
    this.year2 = years[1]
    this.loader=true;
    this.getMonthWiseRecoveryByYear();
    // this.loader=tr;
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
  }

  getMonthWiseRecoveryByYear(){
    let input = {
      year1 : this.year1,
      year2: this.year2
    }
    this.blockUI.start();
    this.loader=true;
    this.blockchainService.getMonthWiseRecoveryByYear(input).subscribe(resp => {
      if (resp.body.status == 200) {
        this.loader=false;
        this.monthRecoveryResp = resp.body.obj;
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
       var sum = parseFloat(this.monthRecoveryResp.april)+parseFloat(this.monthRecoveryResp.may)+parseFloat(this.monthRecoveryResp.june)+parseFloat(this.monthRecoveryResp.julyl)+parseFloat(this.monthRecoveryResp.aug)+parseFloat(this.monthRecoveryResp.sep)+parseFloat(this.monthRecoveryResp.oct)+parseFloat(this.monthRecoveryResp.nov)+parseFloat(this.monthRecoveryResp.dec)+ parseFloat(this.monthRecoveryResp.jan)+ parseFloat(this.monthRecoveryResp.feb)+parseFloat(this.monthRecoveryResp.mar);
       
       this.totalResult= sum.toFixed(1)
      
       if (this.totalResult >= 10000000){
        this.totalResult = this.totalResult + ' ' + 'Crore'
       }  if(this.totalResult >= 100000){
        this.totalResult = this.totalResult + ' ' + 'Lakh'
       }
      
        
      
       console.log(this.totalResult,'this.totalResult')
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        
      } else {
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    })
  }
  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
