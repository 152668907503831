import { Component, OnInit } from "@angular/core";
import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
// import { FlexAlignDirective } from "@angular/flex-layout";
import { AuthenticationService } from "app/_services/authentication.service";
import { DateAdapter } from "@angular/material/core";
import { AlertService } from "app/_services";

declare var $: any;

export interface CategoryType {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-transfer-long-leave",
  templateUrl: "./transfer-long-leave.component.html",
  styleUrls: ["./transfer-long-leave.component.css"],
})
export class TransferLongLeaveComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  allLevels: CategoryType[] = [];
  allCategory: CategoryType[] = [];
  pipe = new DatePipe("en-US");
  msg = "";
  role: any;
  currentUser: any;
  iec_flag = false;
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  now = Date.now();

  selectedStatus: any;
  notiColor: any = "#06ffff";
  transferForm = this.formBuilder.group({
    section: ["", Validators.required],
    level: ["", Validators.required],
    name: ["", Validators.required],
    category: ["", Validators.required],
    start_date: ["",],
    end_date:[""],
  });
  zoneList: any;
  commissionrateList: any;
  allSectionByCommissionrate: any;
  adminAction: string;
  zoneId: any;
  userTypeList: any;
  designationList: any;
  sectionList: any;
  start_date: string;
  officerNameList: any;
  officerDetails: any;
  isTransfer: boolean = false;
  isLongLeave: boolean = false;
  end_date: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale("en-GB"); //dd/MM/yyyy
  }

  ngOnInit(): void {
    this.officerDetails = JSON.parse(localStorage.getItem("officerDetails"));
    var cuser = JSON.parse(localStorage.getItem("currentUser"));

    this.transferForm.patchValue({
      section: this.officerDetails.section,
      level: this.officerDetails.level,
      name: this.officerDetails.name,
    });
    this.role = cuser.obj.role;
    // console.log(this.role);
    if (this.role == 0) {
      this.iec_flag = false;
    } else {
      this.iec_flag = true;
    }

    this.getLevels();
    this.getAllSections();
    this.getCategory();

  }

  selectCategory(name, e) {
    console.log(this.transferForm.value);
    console.log(name, "name of level");
    if (e.isUserInput) {
      if(name == 'Transfer'){
        this.isTransfer = true;
        this.isLongLeave = false;
        this.transferForm.patchValue({
          start_date : ''
        })  
      } else{
        this.isTransfer = false;
        this.isLongLeave = true;
        this.transferForm.patchValue({
          start_date : '',
          end_date: ''
        }) 
        
      }
    }
  }

  getCustomUserBySectionAndLevel(input) {
    this.blockchainService
      .getCustomUserBySectionAndLevel(input)
      .subscribe((resp) => {
        this.officerNameList = resp.body.obj;
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      });
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.start_date = this.pipe.transform(event.value, "yyyy-MM-dd");
    // this.modifiedCurrentDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAllSections() {
    let input = {};
    this.blockchainService.getAllSections(input).subscribe((resp) => {
      this.sectionList = resp.body.obj;
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    });
  }

  getLevels() {
    this.allLevels = [
      { value: "L1", viewValue: "L1" },
      { value: "L2", viewValue: "L2" },
      { value: "L3", viewValue: "L3" },
    ];
  }

  getCategory() {
    this.allCategory = [
      { value: "Transfer", viewValue: "Transfer" },
      { value: "Long Leave", viewValue: "Long Leave" },
    ];
  }

  selectCommissionrate(id, event) {
    if (event.isUserInput) {
      this.getSectionByCommissionrateId(id);
    }
  }

  submit(){
    let user_id,on_leave,start_date,end_date ;
    user_id = this.officerDetails.customs_user_id
  
    if(this.officerDetails != null){
      if(this.isTransfer == true){
        on_leave = 2
        start_date = this.pipe.transform(this.transferForm.value.start_date , "yyyy-MM-dd") ?  this.pipe.transform(this.transferForm.value.start_date , "yyyy-MM-dd"): this.pipe.transform(this.start_date , "yyyy-MM-dd")
        end_date = null
      } else{
        on_leave = 1
        end_date =  this.pipe.transform(this.transferForm.value.end_date , "yyyy-MM-dd") ?  this.pipe.transform(this.transferForm.value.end_date , "yyyy-MM-dd") :  this.pipe.transform(this.end_date , "yyyy-MM-dd")
        start_date =  this.pipe.transform(this.transferForm.value.start_date , "yyyy-MM-dd") ?  this.pipe.transform(this.transferForm.value.start_date , "yyyy-MM-dd") :  this.pipe.transform(this.start_date , "yyyy-MM-dd")
      }
      let input = {
        user_id : user_id,
        on_leave : on_leave,
        start_date: start_date,
        end_date:end_date
   
      }
      this.assignOfficerLeaveOrTransfer(input);
        
    } else{
      //officer details null
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please Fill All Officer Details",
        "announcement"
      );
      this.router.navigate(['/officerDetailsTransfer'])
    }
  }

  assignOfficerLeaveOrTransfer(input){
    this.blockchainService.assignOfficerLeaveOrTransfer(input).subscribe(resp => {
      if(resp.body.status == 200){
        let message
        if(input.on_leave == 1){
          //long leave
         message = "No New Files will be allocated to" + " " + this.officerDetails.name + " " + "between" + " " + input.start_date + " " + "and" + " " + input.end_date + "."
        } else{
          //transferer
          message = " All Files have been transfered from Officer : "+ " " + this.officerDetails.name + " " + "to"+ this.officerDetails.level + " " + "Officer Level. "  
        }
        this.showNotification(
          "bottom",
          "right",
          "success",
          message,
          "announcement"
        );
      }
      this.showNotification(
        "bottom",
        "right",
        "success",
        resp.body.message,
        "announcement"
      );
      this.router.navigate(['/officerDetailsTransfer'])  
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{
      }
    });
  }

  getSectionByCommissionrateId(id) {
    let input = {
      id: id,
    };
    this.blockchainService
      .getSectionByCommissionrateId(input)
      .subscribe((resp) => {
        this.allSectionByCommissionrate = resp.body.obj;
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      });
  }

  get f() {
    return this.transferForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.transferForm.controls[controlName].hasError(errorName);
  };


  back() {
    this.router.navigate(["/officerDetailsTransfer"]);
  }

 

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", this.notiColor, "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 5000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4' +
          ' alert alert-{0} alert-with-icon" role="alert"><button mat-button  type="butto' +
          'n" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i clas' +
          's="material-icons">close</i></button><i class="material-icons" data-notify="ic' +
          'on">notifications</i> <span data-notify="title">{1}</span> <span data-notify="' +
          'message">{2}</span><div class="progress" data-notify="progressbar"><div class=' +
          '"progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valu' +
          'emin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" targ' +
          'et="{4}" data-notify="url"></a></div>',
      }
    );
  }
}
