import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

@Component({
  selector: 'app-cancel-doc-collect',
  templateUrl: './cancel-doc-collect.component.html',
  styleUrls: ['./cancel-doc-collect.component.css']
})
export class CancelDocCollectComponent implements OnInit {
  idProof_url:any;
  authorization_url:any;
  sectionViewLetterDetails:any;
  autoFillForm = this.formBuilder.group({
    name: [""],
    section:[""],
    designation: [""],
  });

  caseDetailsFillForm = this.formBuilder.group({
    name_of_person_collecting_document: [""],
    mobile_no_person_collecting_document: ["",[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    authorization_letter_to_collect_document: [""],
    identity_card_person_collecting_document:[""],
    cancellation_id:[""]
  });
  currentUser: any;
  caseDetailsData: any;
  process: any;
  selectedFile: File;
  selectedFile1: File;
  url: string | ArrayBuffer;
  constructor(   
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));
    this.setFormValue();
    this.process = JSON.parse(localStorage.getItem("process"));
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.caseDetailsFillForm.controls[controlName].hasError(errorName);
  };

  setFormValue(){
    this.autoFillForm.setValue({
      name: this.caseDetailsData.officer_name,
      designation: null,
      section:null,
    });
  }

  saveCollectPersonDetails(){
    if(
      this.caseDetailsFillForm.value.name_of_person_collecting_document != '' &&
      this.caseDetailsFillForm.value.mobile_no_person_collecting_document  != "" &&
      this.selectedFile != undefined &&
      this.selectedFile1 != undefined
    ){

      let input ={
        cancellation_id:this.caseDetailsData.cancellation_id,
        name_of_person_collecting_document:this.caseDetailsFillForm.value.name_of_person_collecting_document,
        mobile_no_person_collecting_document:this.caseDetailsFillForm.value.mobile_no_person_collecting_document,
        authorization_letter_to_collect_document:this.selectedFile,
        identity_card_person_collecting_document:this.selectedFile1
       }
      
       this.blockchainService.saveCancellationReturnDocumentPerson(input).subscribe(
        (resp) => {
          console.log(resp.body.obj);
          if(resp.body.status==200){
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );	
            this.router.navigate(["/cancellation1"]);
          }else{
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );	
          }
        })

    } else{
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please fill all the mandatory details",
        "announcement"
      );	
    }

  }

  openModalViewLetter(section) {
    // console.log(section.letter);
    this.sectionViewLetterDetails = section;
    // console.log(this.sectionViewLetterDetails)
    const buttonModal = document.getElementById("openModalButton");
    // buttonModal.click();
  }


  onFileSelected(event,action) {
    if(action == 'authorization'){
      let $authorization: any = document.querySelector("#authorization");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
      this.selectedFile = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.authorization_url = event.target.result;
      };
      this.onUpload();
    } else {
      $authorization.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else{
      let $idProof: any = document.querySelector("#idProof");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
      this.selectedFile1 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.idProof_url = event.target.result;
      };
      this.onUpload1();
    } else {
      $idProof.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    }
  }

  onUpload() {
    const fd = new FormData();
    fd.append("file", this.selectedFile, this.selectedFile.name);
  }
  onUpload1() {
    const fd = new FormData();
    fd.append("file", this.selectedFile1, this.selectedFile1.name);
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
