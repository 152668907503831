import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
  BlockchainService,
  L2AuthorizationBeforeValidityActionQuery,
} from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { id } from "date-fns/locale";
import { AnyNsRecord } from "dns";
// import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { DateAdapter } from "@angular/material/core";

declare var $: any;

export interface L2AuthorizationBeforeValidityAction {
  bg_amount: number;
  bond_amount: number;
  no_of_auth: number;
  no_of_iec: number;
}

@Component({
  selector: "app-om",
  templateUrl: "./om.component.html",
  styleUrls: ["./om.component.css"],
})
export class OmComponent implements OnInit {
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  date: any;
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  // beforeValidityResp: PaginatedDataSource<L2AuthorizationBeforeValidityAction, L2AuthorizationBeforeValidityActionQuery>;
  initialSort: Sort<L2AuthorizationBeforeValidityAction> = { property: "bg_amount", order: "asc" };
  beforeValidityResp:L2AuthorizationBeforeValidityAction;

  displayedColumns2 = ["bondAmt", "bgAmt", "no_of_authorization", "no_of_iecs"];
  cardDetailsResp: any;
  authAfterValidityResp: any;
  newAuthAfterValidityResp: string[] = [];
  bondsActionSummaryResp: any;
  newBondsActionSummaryResp: string[] = [];
  authAfterValidityPeriodResp: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    // this.date = this.todayDate;
    this.date = localStorage.getItem("ObMDate");
    this.getL2AuthorizationAfterValidity();

    this.getL2SummaryDetailsByDate();
    this.getL2AuthorizationAfterValidityActionSummary();
    this.getL2AuthorizationBeforeValidityAction();
    this.getL2AuthorizationAfterExportValidityPeriodOver();
  }

  getL2SummaryDetailsByDate() {
    this.blockUI.start("Please wait this may take few seconds ...");

    let input = {
      start_date: this.date,
    };
    this.blockchainService
      .getL2SummaryDetailsByDate(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.cardDetailsResp = resp.body.obj;
          
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        } else {
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        }
      });
  }

  getL2AuthorizationAfterValidity() {
    this.blockUI.start("Please wait this may take few seconds ...");
   
    let input = {
      start_date: this.date,
    };
    this.blockchainService
      .getL2AuthorizationAfterValidity(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.authAfterValidityResp = resp.body.obj;

        } else {
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  getL2AuthorizationAfterValidityActionSummary() {
    this.blockUI.start("Please wait this may take few seconds ...");
    let input = {
      start_date: this.date,
    };
    this.blockchainService
      .getL2AuthorizationAfterValidityActionSummary(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.bondsActionSummaryResp = resp.body.obj;
         
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        } else {
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        }
      });
  }

  getL2AuthorizationBeforeValidityAction() {
    this.blockUI.start("Please wait this may take few seconds ...");

    let input = {
      start_date: this.date,
    };
    this.blockchainService
      .getL2AuthorizationBeforeValidityAction(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.beforeValidityResp = resp.body.obj;
         
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        } else {
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        }
      });
  }

  getL2AuthorizationAfterExportValidityPeriodOver(){
    this.blockUI.start("Please wait this may take few seconds ...");

    let input = {
      start_date: this.date,
    };
    this.blockchainService
      .getL2AuthorizationAfterExportValidityPeriodOver(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.authAfterValidityPeriodResp = resp.body.obj;
         
          console.log(this.authAfterValidityPeriodResp,'validity period')
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        } else {
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        }
      });
  }


  authAfterExport(action) {
    if (action == "1") {
      localStorage.setItem("authAfterExp", "1");
      this.router.navigate(["/authAfterExport"]);
    } else if (action == "2") {
      localStorage.setItem("authAfterExp", "2");
      this.router.navigate(["/authAfterExport"]);
    } else if (action == "3") {
      localStorage.setItem("authAfterExp", "3");
      this.router.navigate(["/authAfterExport"]);
    } else if (action == "4") {
      localStorage.setItem("authAfterExp", "4");
      this.router.navigate(["/authAfterExport"]);
    } else if (action == "5") {
      localStorage.setItem("authAfterExp", "5");
      this.router.navigate(["/authAfterExport"]);
    } else {
    }
  }

  viewDetails(e){
    console.log(e);
    if(e == 'EODC APPLICATION NOT FILED'){
      localStorage.setItem('eodcApplicationStatus','notfiled');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e == 'EODC APPLIED BUT NOT YET GRANTED'){
      localStorage.setItem('eodcApplicationStatus','notgranted');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e == 'EODC APPLIED AND REJECTED BY DGFT'){
      localStorage.setItem('eodcApplicationStatus','rejected');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e == 'EODC APPLIED AND  GRANTED BY DGFT  BUT BOND CANCELLATION NOT FILED'){
      localStorage.setItem('eodcApplicationStatus','granted');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e == 'EODC GRANTED BY DGFT AND BOND CANCELLATION FILED'){
      localStorage.setItem('eodcApplicationStatus','bondfiled');
     this.router.navigate(['/detailAuthSummaryValidity']);

    }
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
  }

  process(){
    this.router.navigate(["/scnModulePS"]);
  }

  navigateToLiveBond(e) {
    console.log(e);
    if (e == "LIVE") {
      this.router.navigate(["/liveBond"]);
    } else {
      this.router.navigate(["/cancelledBond"]);
    }
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
