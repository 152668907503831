import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appOmitSpecialChar]'
})
export class OmitSpecialCharDirective {
  //ALPHANUMERIC with /-_\ .
  regexStr = '^[ A-Za-z0-9\\\\./_-]*$'
  // regexStr = '^[ A-Za-z0-9._@-]*$';
  @Input() isAlphaNumeric: boolean;

  constructor(private el: ElementRef,private control : NgControl) { }


  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      // this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^ A-Za-z0-9._@-]/g, '').replace(/\s/g, '');
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^ A-Za-z0-9\\\\./_-]/g, '').replace(/\s/g, '');
      this.control.control.setValue(this.el.nativeElement.value);
      event.preventDefault();

    }, 100)
  }

}
