import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update',
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.css']
})
export class UpdateComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  save(){
  //   localStorage.setItem('saveFile',JSON.stringify(this.selectedFile));
  //   let input = {
  //     // aa_file:this.selectedFile,
  //     // iec_file:this.selectedFile1,
  //     // iec_no:'abcd-efgh-ijkl-mnop',
  //     // application_type_id:'1',
  //     // application_type_name:'AA',
  //     // user_roles:'3',
  //   }
  //   console.log(input,'input')
  //   this.blockchainService.saveApplication(input).subscribe(resp => {

  //   })
    
   }

  back(){
    this.router.navigate(["/appSummary"])
  }

  next(){
    this.router.navigate(["/autherizationRequest"])
  }


}
