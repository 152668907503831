import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService, MailboxDetailsQuery, SummaryDetailsQuery1 } from "app/blockchainImpExp/blockchain.service";
import { PaymentsumComponent } from "app/blockchainImpExp/imp_exp/payment/paymentsum/paymentsum.component";
import { DomSanitizer } from "@angular/platform-browser";
import { AlertService } from "app/_services";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { element } from "protractor";


declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}
export interface Mailbox {
  letter_date: string;
  letter_no: string;
  authorization_type: string;
  authorization_no: string;
  letter_title: string;
  action: string;
}


@Component({
  selector: 'app-mailbox',
  templateUrl: './mailbox.component.html',
  styleUrls: ['./mailbox.component.css']
})
export class MailboxComponent implements OnInit {

  selectedStatus = 'New';
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  allStatus: Status[] = [
    { value: 'Incoming Date', viewValue: 'Incoming Date' },
    { value: 'Application Type', viewValue: 'Application Type' },
    { value: 'Application Stage', viewValue: 'Application Stage' },
    { value: 'File No', viewValue: 'File No' },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  currentUser: any;
  role: any;
  // dataSource: Mailbox[] = [];


  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  displayedColumns = [
    'letter_date',
    'letter_no',
    'authorization_type',
    'authorization_no',
    'letter_title',
    'view',
    'action'
  ];

  autoFillForm = this.formBuilder.group({
    username: [""],
    companyname: [""],
    iecCode: [""],
  });

  userDetails: any;
  summaryData: PaginatedDataSource<Mailbox, MailboxDetailsQuery>;
  dataSource:  MatTableDataSource<any> = new MatTableDataSource([]);
  initialSort: Sort<Mailbox> = { property: "letter_no", order: "desc" };
  getCustomsMailbox: any;
  sectionViewLetterDetails: any;
  loader: boolean;
  uploadedDocument: any;
  scnLetterStatus: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private domSanitizer: DomSanitizer,
    private alertService: AlertService
  ) { 
    // this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // this.role = this.currentUser.obj.user_type_id;
    // localStorage.removeItem('letter_type');
    // this.getAllCustomsMailBox();
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }
  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = this.currentUser.obj.user_type_id;
    localStorage.removeItem('letter_type');
    localStorage.removeItem('letterType');
    this.getAllCustomsMailBox();
  }


  caseDetailsDialogue(data) {
    localStorage.setItem('casedetailsData', JSON.stringify(data));
  }

  getAllCustomsMailBox() {
    let input={
      created_by:this.currentUser.obj.user_id
    }
    this.blockUI.start();
    this.loader=true;
    this.blockchainService.getMyMailboxDetails(input).subscribe(
      (resp) => {
         console.log(resp)
        this.getCustomsMailbox = resp.body.obj;
        this.dataSource.data = this.getCustomsMailbox;
        this.loader=false;
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        // this.dataSource = this.getCustomsMailbox;
        if (this.getCustomsMailbox !== null) {
          var summaryList = resp.body.obj;
        
          // this.summaryData = new PaginatedDataSource<Mailbox, MailboxDetailsQuery>(
          //   (request, query) =>
          //     this.blockchainService.MailPage(request, query, summaryList),
          //   this.initialSort,
          //   { search: "", data: undefined },
          //   10
          // );
          console.log(this.summaryData);
          console.log(this.dataSource);
        }
        console.log(this.dataSource);
        console.log(this.dataSource.data.length)
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      }
      );
  }
  convertToPlain(html) {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || "";
  }

  openModalViewLetter(section) {
    console.log(section);
    this.sectionViewLetterDetails = section.letter;
    this.uploadedDocument= section.attachedDocumentPath;
    this.scnLetterStatus=section.scn;
     console.log(this.uploadedDocument)
    const buttonModal = document.getElementById("openModalButton");
    // buttonModal.click();
  } 

  viewLetter(element) {
    let val = this.convertToPlain(element.letter);
    if (element.letter != null) {
      this.openDialog(
        "paymentSum",
        val,
        ""
      );
    } else {
      this.openDialog(
        "paymentSum",
        "No Remarks",
        ""
      );
    }
  }

  replyToCustoms(element){
    console.log(element);
    localStorage.setItem('authorization_no', JSON.stringify(element.authorization_no));
    if(element.scn==1){
      localStorage.setItem('application_id', JSON.stringify(element.reminderLetterId));
      localStorage.setItem('letterType', JSON.stringify(element.scn));
      }
    else if(element.letter_title=="Cancellation Query"){
    localStorage.setItem('application_id', JSON.stringify(element.cancellation_id));
    localStorage.setItem('letter_type', JSON.stringify(element.letter_title));
    localStorage.setItem('letterType', JSON.stringify(element.scn));

    }
    else{
      localStorage.setItem('application_id', JSON.stringify(element.application_id));
      localStorage.setItem('letter_type', JSON.stringify(element.letter_title));
      localStorage.setItem('letterType', JSON.stringify(element.scn));
    }
    this.router.navigate(["/replyToCustoms"]);
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result.event === "paymentSum" && result.isUpdated === true) {
        this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }


  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }


}
