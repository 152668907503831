import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";


@Component({
  selector: 'app-license-issue2',
  templateUrl: './license-issue2.component.html',
  styleUrls: ['./license-issue2.component.css']
})
export class LicenseIssue2Component implements OnInit {

  autoFillForm = this.formBuilder.group({
    name: [""],
    designation: [""],
  });
  caseDetailsFillForm = this.formBuilder.group({
    impexpname: [""],
    iecCode: [""],
    applicationDate: [""],
    fileNo: [""],
    jobNo:[""],
    newBondNo:[""]
  });

  alertStatusForm = this.formBuilder.group({
    alertStatus: ["", Validators.required],
  });

  currentUser: any;
  caseDetailsData: any;
  alertStatus:any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));

    this.caseDetailsFillForm.setValue({
      impexpname: this.caseDetailsData.impexpName,
      iecCode: this.caseDetailsData.iecCode,
      applicationDate: this.caseDetailsData.bbgReceiptDate,
      fileNo: this.caseDetailsData.fileNo,
      jobNo:this.caseDetailsData.jobNo,
      newBondNo:this.caseDetailsData.newBondNo
    });

    this.autoFillForm.setValue({
      name: this.currentUser.obj.first_name + this.currentUser.obj.last_name,
      designation: this.currentUser.obj.designation,
    });
  }


  submit(){
    this.router.navigate(["/licenseIssue"]);

  }
 
  back() {
    this.router.navigate(["/licenseIssue"]);
  }

}
