import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService, PreviewDetailsQuery, } from "app/blockchainImpExp/blockchain.service";

var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface PreviewPayment {
  no: number;
  payment_category: string;
  payment_subcategory: string;
  commissionerate:string;
  nature_of_payment:string;
  section:string;
  order_number:string;
  amount_in_inr:string;
  document_details_date:string;
  nature_of_document_no:string;


}


@Component({
  selector: 'app-payment-preview',
  templateUrl: './payment-preview.component.html',
  styleUrls: ['./payment-preview.component.css']
})
export class PaymentPreviewComponent implements OnInit {


  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  currentUser: any;
  role: any;
  dataSource:PreviewPayment[] = [];
 

  displayedColumns = [
    'no',
    'payment_category',
    'payment_subcategory',
    'commissionerate',
    'nature_of_payment',
    'section',
    'order_number',
    'amount_in_inr',
    'document_details_date',
    'nature_of_document_no',
    
  ];


  summaryData: PaginatedDataSource<PreviewPayment, PreviewDetailsQuery>;
  summaryData1:PaginatedDataSource<PreviewPayment, PreviewDetailsQuery>;
  initialSort: Sort<PreviewPayment> = { property: "no", order: "desc" };
  user: any;
  process: any;
  urlR: string;
  disablebtn: boolean;
  payment_id: string;
  getVoluntaryOfPayment: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { }

  ngOnInit(): void {
    // console.log(this.router.url,"Current URL");
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.payment_id = localStorage.getItem("payment_id");
    // this.payment_id = "40";
    this.getSupportingDocumentsByPaymentIdV3(this.payment_id);
  }

  getSupportingDocumentsByPaymentIdV3(paymentId) {
    let input = {
      payment_id: paymentId
    }
    this.blockchainService.getSupportingDocumentsByPaymentIdV3(input).subscribe((resp) => {
      this.getVoluntaryOfPayment = resp.body.obj;
      console.log(this.getVoluntaryOfPayment);
      this.dataSource=this.getVoluntaryOfPayment ;
      var summaryList = this.dataSource;
      this.summaryData = new PaginatedDataSource<PreviewPayment, PreviewDetailsQuery>(
        (request, query) =>
          this.blockchainService.pagePreview(request, query, summaryList),
        this.initialSort,
        { search: "", data: undefined },
        10
      );
    })

  }

  paymentMode(){
    this.router.navigate(["/paymentMode"]);
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }


}
