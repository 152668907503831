import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { AlertService } from "app/_services";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

@Component({
  selector: "app-upload-doc",
  templateUrl: "./upload-doc.component.html",
  styleUrls: ["./upload-doc.component.css"],
})
export class UploadDocComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI  | any;
  blockTemplate: BlockTemplateComponent  | any;
  role: any;

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean  | any;
  selectedFile: File  | any;
  url: string | ArrayBuffer | any;
  bondResp: any;
  selectedFileOther: any;
  selectedFileTurnOver: any;
  selectedFileRMC: any;
  selectedFileGST: any;
  pdfBaseUrl: string | any;
  bond_id: string | any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    private alertService: AlertService,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.bond_id = localStorage.getItem('bond_id');
    let localstorageCurrentUser:any = localStorage.getItem("currentUser")
    this.currentUser = JSON.parse(localstorageCurrentUser);
    this.getBondById();
    // this.pdfBaseUrl = "https://oms.accmumbai.gov.in/customs1.0/api/v1/downloadDocument/"
    this.pdfBaseUrl = this.blockchainService.docDownload;
  }

  getBondById() {
    let input = {
      application_id: localStorage.getItem("application_id"),
    };
    this.blockchainService.getBondById(input).subscribe((resp) => {
      this.bondResp = resp.body.obj;
      if (this.bondResp != null) {
        this.selectedFileGST = this.bondResp.gst_registration_path;
        this.selectedFileRMC = this.bondResp.rcmc_path;
        this.selectedFileTurnOver = this.bondResp.turnover_certificate_path;
        this.selectedFileOther = this.bondResp.any_other_documet_path
      }
    },(error) => {
      console.log(error)
      this.alertService.error(error);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Session Expired, Please Login Again",
        "announcement"
      );
      this.router.navigate(["/login"]);
    });
  }

  back() {
    this.router.navigate(["/bondBg"]);
  }

  next() {
    this.updateBond();
    this.router.navigate(["/submission"]);
  }

  save() {
    this.updateBondByPage();
  }

  updateBondByPage(){
    this.blockUI.start("Authorization Request Sent...");
    let localstorageBondForm : any = localStorage.getItem("bondForm")
    let bondForm = JSON.parse(localstorageBondForm);
      let input = {
        bond_id:this.bond_id ? this.bond_id :  this.bondResp.bond_id,
        application_id: localStorage.getItem("application_id"),
        gst_registration_file :  this.selectedFileGST ,
        rcmc_file : this.selectedFileRMC ,
        turnover_certificate_file : this.selectedFileTurnOver ,
        any_other_document_file :   this.selectedFileOther,
        remarks:bondForm.remarks ? bondForm.remarks : ''
      };
      this.blockchainService.updateBondByPage(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          localStorage.setItem('success','true')
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.getBondById();
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.router.navigate(["/submission"]);
          // this.flag=true;
        } else {
          localStorage.setItem('success','false')
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      });
    
  }

  //old update bond function, currently not in use
  updateBond() {
    this.blockUI.start("Authorization Request Sent...");
    let localstorageBondForm: any = localStorage.getItem("bondForm")
    let bondForm = JSON.parse(localstorageBondForm);
    //check for existing data present
    if ( 
      (this.bond_id != null && this.bond_id != undefined ) ||
      (this.bondResp != null &&
      this.bondResp.bond_id != null &&
      this.bondResp.bond_id != "")
    ) {
      let input = {
        bond_id:this.bond_id ? this.bond_id :  this.bondResp.bond_id,
        bond_path: this.bondResp.bond_path,
        bond_amount_inr:bondForm.bondAmount,
        address_of_bank: bondForm.bankAddress,
        amount_of_bank_guarantee: bondForm.bgAmount,
        auto_renewal: bondForm.autoRenewal == "YES" ? 0 : 1,
        bank_guarantee_number: bondForm.bgNo,
        bank_guarantee_file: bondForm.bgDoc,
        bank_gaurantee_issue_date :bondForm.bgIssueDate,
        bank_gaurantee_last_validity_date : bondForm.bgLastDate,
        ifsc_of_branch: bondForm.ifsc,
        name_of_bank: bondForm.bankName,
        name_of_branch: bondForm.branchName,
        start_exporter_house_copy_file: bondForm.starExp,
        application_id: localStorage.getItem("application_id"),
        start_exporter: bondForm.isStarExp == "YES" ? 0 : 1,
        gst_registration_file :  this.selectedFileGST ,
        rcmc_path : this.selectedFileRMC ,
        turnover_certificate_file : this.selectedFileTurnOver ,
        any_other_documet_file :   this.selectedFileOther,
        remarks:bondForm.remarks ? bondForm.remarks : ''
      };
      console.log(input,'update input')
      this.blockchainService.updateBond(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          localStorage.setItem('success','true')
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.getBondById();
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          // this.flag=true;
        } else {
          localStorage.setItem('success','false')
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      });
    } 
    else {
      let input = {
        bond_path: bondForm.bondUpload,
        bond_amount_inr:bondForm.bondAmount,
        address_of_bank: bondForm.bankAddress,
        amount_of_bank_guarantee: bondForm.bgAmount,
        auto_renewal: bondForm.autoRenewal == "YES" ? 0 : 1,
        bank_guarantee_number: bondForm.bgNo,
        bank_guarantee_file: bondForm.bgDoc,
        bank_gaurantee_issue_date :bondForm.bgIssueDate,
        bank_gaurantee_last_validity_date : bondForm.bgLastDate,
        ifsc_of_branch: bondForm.ifsc,
        name_of_bank: bondForm.bankName,
        name_of_branch: bondForm.branchName,
        start_exporter_house_copy_file: bondForm.starExp,
        application_id: localStorage.getItem("application_id"),
        start_exporter: bondForm.isStarExp == "YES" ? 0 : 1,
        gst_registration_file :  this.selectedFileGST ,
        rcmc_path : this.selectedFileRMC ,
        turnover_certificate_file : this.selectedFileTurnOver ,
        any_other_document_file :   this.selectedFileOther,
        remarks:bondForm.remarks ? bondForm.remarks : ''

      };
      this.blockchainService.saveBond(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          localStorage.setItem('bond_id',resp.body.obj)
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.getBondById();
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          // this.flag=true;
        } else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      });
    }
  }

  onFileSelected(event:  any, doc: any) {
    if (doc == "GST") {
      this.selectedFileGST = <File><unknown>event.target.files[0];
      if(this.selectedFileGST.type == 'application/pdf'){
        var reader = new FileReader();
        let eventTargetFiles : any = event.target.files[0]
      reader.readAsDataURL(eventTargetFiles); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        let eventTarget3:any = event?.target
        this.url = eventTarget3.result;
      };
      this.onUpload();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
      
    }
    if (doc == "RCMC") {
      this.selectedFileRMC = <File><unknown>event.target.files[0];
      if(this.selectedFileRMC.type == 'application/pdf'){
        var reader = new FileReader();
        let eventTargetFile : any = event.target.files[0]
        reader.readAsDataURL(eventTargetFile);
        // reader.readAsDataURL(event.target.files[0]); // read file as data url
  
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          let eventTarget2:any = event?.target
        this.url = eventTarget2.result;
        };
        this.onUpload1();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    }
    if (doc == "TURNOVER") {
      this.selectedFileTurnOver = <File><unknown>event.target.files[0];
      if(this.selectedFileTurnOver.type == 'application/pdf'){
        var reader = new FileReader();
        let eventTargetFile : any = event.target.files[0]
        reader.readAsDataURL(eventTargetFile);
        // reader.readAsDataURL(event.target.files[0]); // read file as data url
  
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          let eventTarget1:any = event?.target
          this.url = eventTarget1.result;
        };
        this.onUpload2();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    }
    if (doc == "OTHER") {
      this.selectedFileOther = <File><unknown>event.target.files[0];
      if(this.selectedFileOther.type == 'application/pdf'){
        var reader = new FileReader();
        let eventTargetFile : any = event.target.files[0]
      reader.readAsDataURL(eventTargetFile); // read file as data url

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        let eventTarget:any = event?.target
        this.url = eventTarget.result;
      };
      this.onUpload3();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
      
    }
  }

  onUpload() {
    const fd = new FormData();
    console.log(this.selectedFileGST,'gst file')
    fd.append("file", this.selectedFileGST, this.selectedFileGST.name);
  }

  onUpload1() {
    const fd = new FormData();
    console.log(this.selectedFileRMC,'rmc file')
    fd.append("file", this.selectedFileRMC, this.selectedFileRMC.name);
  }

  onUpload2() {
    const fd = new FormData();
    fd.append(
      "file",
      this.selectedFileTurnOver,
      this.selectedFileTurnOver.name
    );
  }

  onUpload3() {
    const fd = new FormData();
    fd.append("file", this.selectedFileOther, this.selectedFileOther.name);
  }
  statusFn(e: string, status: any) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef:any;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result: { event: string; isUpdated: boolean; }) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from: string, align: string, color: any, stringMessage: string, icons: string) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
