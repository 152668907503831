import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

export interface PeriodicElement {
  lable: string;
  data: string;
  status: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {status: 1 , lable:"RECEIPT" , data: '3-6-2022'},
  {status: 1 , lable:"ALERT CHECK" , data: '3-6-2022'},
  {status: 1 , lable:"INITIATE VALIDATION" , data: '3-6-2022'},
  {status: 1 , lable:"CREATE NOTE SHEET" , data: '3-6-2022'},
  {status: 1 , lable:"ENDORSE" , data: '3-6-2022'},
  {status: 0 , lable:"VALIDATE" , data: ''},
  {status: 0 , lable:"ADD JOB-BOND NO" , data: ''},
  {status: 0 , lable:"BBG RECEIPT" , data: ''},
  {status: 0 , lable:"LISENCE ISSUE" , data: ''}
 
];

@Component({
  selector: 'app-search-case',
  templateUrl: './search-case.component.html',
  styleUrls: ['./search-case.component.css']
})
export class SearchCaseComponent implements OnInit {

  displayedColumns: string[] = ['status','lable', 'data'];
  dataSource = ELEMENT_DATA;

  pipe = new DatePipe("en-US");
  now = Date.now();
  // todayDate = this.pipe.transform(this.now, "yyyy-MM-dd", "en-US");

  selectedStatus = "";
   color = 'primary';
   backgroundColor = '#64c153'
  
 
   @BlockUI() blockUI: NgBlockUI |any;
   blockTemplate: BlockTemplateComponent |any;
   role: any;
   selectedDate: any;
   aaDate: any;
   receiptDate: any;
   alert_checkDate: any;
   initiative_validDate: any;
   noteSheetDate: any;
   endorseDate: any;
  
   searchCaseForm = this.formBuilder.group({
    iecNo: [""],
    aaNo:[""],
    aaDate:[""]
   
   });

   caseStatusForm = this.formBuilder.group({
    impname: [""],
    fileNo:[""],
    adate:[""],
    jobNo:[""],
    bondNo:[""],
    licDate:[""]
   
   });
 
 
   currentUser: any;
   caseDetailsData: any;
   isButtonDisabled: boolean | any;
   selectedFile: File | any;
   url: string | ArrayBuffer | any;
   allApplicationTypes: any;
   applicationType: any;
   selectedFile1: File | any;
   date: any;
   modifiedCurrentDate: any;
   table = false;
  
 
   constructor(
     private formBuilder: UntypedFormBuilder,
     private route: ActivatedRoute,
     private router: Router,
     private overlay: Overlay,
     public dialog: MatDialog,
     public blockchainService: BlockchainService
   ) {}
 
   ngOnInit(): void {
    let localstorageCurrentUser:any = localStorage.getItem("currentUser")
     this.currentUser = JSON.parse(localstorageCurrentUser);
     this.getApplicationType();
   }
 
     getApplicationType(){
     this.blockchainService.getApplicationType().subscribe((resp) => {
       this.allApplicationTypes = resp.obj;
     });
   }
 
   selectedApplicationType(event: { isUserInput: any; }, applicationType: any){
     if (event.isUserInput) {
       console.log(event);
       this.applicationType = applicationType;
       // this.selectedCityName = "";
     }
   }
 
   save(){
     localStorage.setItem('saveFile',JSON.stringify(this.selectedFile));
     let input = {
      //  aa_file:this.selectedFile,
      //  iec_file:this.selectedFile1,
      //  iec_no:'abcd-efgh-ijkl-mnop',
      //  application_type_id:'1',
      //  application_type_name:'AA',
      //  user_roles:'3',
     }
     console.log(input,'input')
     this.blockchainService.saveApplication(input).subscribe({next:(resp: any) => {
 
     }})
     
   }

   
  back(){
    // this.router.navigate(["/typeRole"])
  }

 
   statusFn(e: string, status: any) {
     if (e == "NO") {
       this.isButtonDisabled = true;
     } else {
       this.isButtonDisabled = false;
     }
   }
 
  
   onFileSelected(event: any,action: string) {
     if(action == 'cust'){
       this.selectedFile = <File>event.target.files[0];
       var reader = new FileReader();
       reader.readAsDataURL(event.target.files[0]); // read file as data url
       reader.onload = (event) => {
         // called once readAsDataURL is completed
         let eventTarget1 : any = event?.target
         this.url = eventTarget1.result;
       };
       this.onUpload();
     } else{
       this.selectedFile1 = <File>event.target.files[0];
       var reader = new FileReader();
       reader.readAsDataURL(event.target.files[0]); // read file as data url
       reader.onload = (event) => {
         // called once readAsDataURL is completed
         let eventTarget:any = event?.target
         this.url = eventTarget.result;
       };
       this.onUpload1();
     }
    
   }
 
   //for iec  
   onUpload() {
     const fd = new FormData();
     fd.append("file", this.selectedFile, this.selectedFile.name);
   }
 
   //for aa  
   onUpload1() {
     const fd = new FormData();
     fd.append("file", this.selectedFile1, this.selectedFile1.name);
   }
   
   dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
    this.table = true;
  }

  formatDate(date: string | number | Date) {
    let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    year = d.getFullYear(),
    day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
 
 
   openDialog(action: string, obj: any, element: any) {
     const dialogConfig = new MatDialogConfig();
     // obj.action = action;
     // obj.object_id = element;
     // dialogConfig.backdropClass = "bdrop";
     dialogConfig.panelClass = "dialog-responsive";
     dialogConfig.disableClose = true;
     dialogConfig.autoFocus = true;
     dialogConfig.data = obj;
     dialogConfig.height = "500px";
     dialogConfig.width = "850px";
 
     let dialogRef:any;
 
     if (action == "received") {
       // dialogConfig.height = '500px';
       //   dialogConfig.width = '750px';
       // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
     }
 
     dialogRef.afterClosed().subscribe((result: { event: string; isUpdated: boolean; }) => {
       if (result.event === "create_request" && result.isUpdated === true) {
         //this.showNotification("bottom", "right", "success", "", "announcement");
       }
     });
   }
 
   
   showNotification(from: any, align: any, color: any |string | number, stringMessage: any, icons: any) {
     const type = ["", "info", "success", "warning", "danger"];
 
     $.notify(
       {
         icon: icons,
         message: stringMessage,
       },
       {
         type: type[color],
         timer: 4000,
         placement: {
           from: from,
           align: align,
         },
         template:
           '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
           '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
           '<i class="material-icons" data-notify="icon">notifications</i> ' +
           '<span data-notify="title">{1}</span> ' +
           '<span data-notify="message">{2}</span>' +
           '<div class="progress" data-notify="progressbar">' +
           '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
           "</div>" +
           '<a href="{3}" target="{4}" data-notify="url"></a>' +
           "</div>",
       }
     );
   }
 
 }
 