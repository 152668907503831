import { Component, OnInit } from "@angular/core";
import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
// import { FlexAlignDirective } from "@angular/flex-layout";
import { AuthenticationService } from "app/_services/authentication.service";
import { DateAdapter } from "@angular/material/core";
import { AlertService } from "app/_services";

declare var $: any;

export interface CategoryType {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-officer-details-transfer",
  templateUrl: "./officer-details-transfer.component.html",
  styleUrls: ["./officer-details-transfer.component.css"],
})
export class OfficerDetailsTransferComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  allLevels: CategoryType[] = [];
  allCategory: CategoryType[] = [];
  pipe = new DatePipe("en-US");
  msg = "";
  role: any;
  currentUser: any;
  iec_flag = false;

  selectedStatus: any;
  notiColor: any = "#06ffff";
  officerDetailsForm = this.formBuilder.group({
    section: ["", Validators.required],
    level: ["", Validators.required],
    name: ["", Validators.required],
  });
  zoneList: any;
  commissionrateList: any;
  allSectionByCommissionrate: any;
  adminAction: string;
  zoneId: any;
  userTypeList: any;
  designationList: any;
  sectionList: any;
  start_date: string;
  officerNameList: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale("en-GB"); //dd/MM/yyyy
  }

  ngOnInit(): void {
    // this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    var cuser = JSON.parse(localStorage.getItem("currentUser"));
    localStorage.removeItem("officerDetails");
    this.role = cuser.obj.role;
    // console.log(this.role);
    if (this.role == 0) {
      this.iec_flag = false;
    } else {
      this.iec_flag = true;
    }
    this.getLevels();
    this.getAllSections();
    this.getCategory();
  }

  submit() {
    let officerDetails = this.officerDetailsForm.value
    this.officerNameList.forEach(element => {
      if(this.officerDetailsForm.value.name == element.name){
        officerDetails.customs_user_id = element.customs_user_id
      }
    });
    
    localStorage.setItem(
      "officerDetails",
      JSON.stringify(officerDetails)
    );
    this.router.navigate(["/transferLongLeave"]);
  }

  selectLevel(name, e) {
    console.log(this.officerDetailsForm.value);
    console.log(name, "name of level");
    if (e.isUserInput) {
      if (
        this.officerDetailsForm.value.section != null &&
        this.officerDetailsForm.value.section != "" &&
        this.officerDetailsForm.value.section != undefined
      ) {
        let input = {
          section: this.officerDetailsForm.value.section,
          level: name,
        };
        this.getCustomUserBySectionAndLevel(input);
      } else {
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Select Section",
          "announcement"
        );
      }
    }
  }

  getCustomUserBySectionAndLevel(input) {
    this.blockchainService
      .getCustomUserBySectionAndLevel(input)
      .subscribe((resp) => {
        this.officerNameList = resp.body.obj;
        if(resp.body.obj.length == 0){
          this.showNotification(
            "bottom",
            "right",
            "success",
           resp.body.message,
            "announcement"
          );
        }
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      });
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.start_date = this.pipe.transform(event.value, "yyyy-MM-dd");
    // this.modifiedCurrentDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAllSections() {
    let input = {};
    this.blockchainService.getAllSections(input).subscribe((resp) => {
      this.sectionList = resp.body.obj;
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    });
  }

  getLevels() {
    this.allLevels = [
      { value: "L1", viewValue: "L1" },
      { value: "L2", viewValue: "L2" },
      { value: "L3", viewValue: "L3" },
    ];
  }

  getCategory() {
    this.allCategory = [
      { value: "Transfer", viewValue: "Transfer" },
      { value: "Long Leave", viewValue: "Long Leave" },
    ];
  }

  selectSection(name,e){
    if(e.isUserInput){
      this.officerDetailsForm.patchValue({
        level : ''
      })
    }
  }

  selectCommissionrate(id, event) {
    if (event.isUserInput) {
      this.getSectionByCommissionrateId(id);
    }
  }

  getSectionByCommissionrateId(id) {
    let input = {
      id: id,
    };
    this.blockchainService
      .getSectionByCommissionrateId(input)
      .subscribe((resp) => {
        this.allSectionByCommissionrate = resp.body.obj;
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      });
  }

  get f() {
    return this.officerDetailsForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.officerDetailsForm.controls[controlName].hasError(errorName);
  };

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", this.notiColor, "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 5000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4' +
          ' alert alert-{0} alert-with-icon" role="alert"><button mat-button  type="butto' +
          'n" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i clas' +
          's="material-icons">close</i></button><i class="material-icons" data-notify="ic' +
          'on">notifications</i> <span data-notify="title">{1}</span> <span data-notify="' +
          'message">{2}</span><div class="progress" data-notify="progressbar"><div class=' +
          '"progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valu' +
          'emin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" targ' +
          'et="{4}" data-notify="url"></a></div>',
      }
    );
  }
}
