import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService, SupportingProjAAQuery } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Page, PageRequest } from "app/paging/page";
import { Sort } from "app/paging/page";
import { DateAdapter } from "@angular/material/core";
import { AlertService } from "app/_services";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}
export interface PeriodicElement {
  srNo: string;
  name: string;
  address: string;
  gstn: string;
  industrialRegistration: string;
  exciseAddress: string;
  type: string;
  edit: string;
}


const ELEMENT_DATA: PeriodicElement[] = [
  {
    srNo: "",
    name: "",
    address: "",
    gstn: "",
    industrialRegistration: "",
    exciseAddress: "",
    type: "",
    edit: "",
  },
];

export interface SupportingProjAA {
  serial_no: number;
  name:string;
  address:string;
  gst_registration:string;
  industrial_registration:string;
  address_jurisdictional_central_execise_authority:string;
  type:string;
}

@Component({
  selector: "app-supporting-project-site",
  templateUrl: "./supporting-project-site.component.html",
  styleUrls: ["./supporting-project-site.component.css"],
})
export class SupportingProjectSiteComponent implements OnInit {
  supportingProjResp: PaginatedDataSource<SupportingProjAA, SupportingProjAAQuery> ;
  initialSort: Sort<SupportingProjAA> = { property: "name", order: "asc" };

  displayedColumns = [
    "srNo",
    "name",
    "address",
    "gstn",
    "industrialRegistration",
    "exciseAddress",
    "type",
    "edit",
  ];
  dataSource = ELEMENT_DATA;

  pipe = new DatePipe("en-US");


  @BlockUI() blockUI: NgBlockUI |any;
  blockTemplate: BlockTemplateComponent |any;
  role: any;
  authorizationDate: any;
  impDate: any;
  expDate: any;

  

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean |any;
  date: string |any;
  modifiedCurrentDate: string |any;
  lastDate: any;
  isaaForm2Enable: boolean = false;
  applicationType: string |any;
  isShowMessage: boolean |any;
  showMessage: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    private alertService: AlertService,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    let localstorageCurrentUser : any = localStorage.getItem("currentUser")
    this.currentUser = JSON.parse(localstorageCurrentUser);
    this.applicationType = localStorage.getItem('applicationType')
    this.getManufacturerById();
  }

  getManufacturerById(){
    let application_id = localStorage.getItem("application_id");

    let input = {
      application_id: application_id,
    };
    this.blockchainService.getManufacturerById(input).subscribe((resp) => {
      this.supportingProjResp = resp.body.obj;
    
      if (this.supportingProjResp !== null) {
        this.isShowMessage = false;
        var impItemList = resp.body.obj;
        this.supportingProjResp = new PaginatedDataSource<SupportingProjAA, SupportingProjAAQuery>(
          (request, query) =>
            this.blockchainService.pageSupportingProjAA(request, query, impItemList),
          this.initialSort,
          { search: "", data: [] },
          10
        );
      }
      else{
        this.isShowMessage = true;
        this.showMessage = resp.body.message;
      }
    },(error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        location.reload();
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        this.blockUI.stop();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
        location.reload();
      }
      else{}
    });
  }

  addSupportingProjAA(data: { update: boolean; }) {
    data.update = true;
    this.router.navigate(["/addProjectAA", data]);
  }



  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date: string | number | Date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  next() {
    this.router.navigate(["/bondBg"]);
  }

  back() {
    this.router.navigate(["/aaDetails"]);
  }

  showNotification(from: string, align: string, color: any, stringMessage: string, icons: string) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
