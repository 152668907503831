import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit,ViewChild } from "@angular/core";
import { FormControl, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthSummaryQuery, BlockchainService, ImportAAQuery } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Month } from "app/blockchainImpExp/customs_interface/custom-payment/payment-daily-summary/month";

declare var $: any;

export interface AuthSummary {
  application_id: number,
  auth_number: string,
  evd: string,
  bond_amount_in_inr: string,
  iec_number: string,
  bg_amount: string,
  date_of_last_letter: string,
  number_of_letter_sent: string,
  scn_no: string,
  scn_date: string,
  no_of_days_overdue: string
}
export interface Status {
  value: string;
  viewValue: string;
}
export interface calendarView {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-detail-auth-summary',
  templateUrl: './detail-auth-summary.component.html',
  styleUrls: ['./detail-auth-summary.component.css']
})
export class DetailAuthSummaryComponent implements OnInit {
  pipe = new DatePipe("en-US");
  date:any;
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  initialSort: Sort<AuthSummary> = { property: "no_of_days_overdue", order: "desc" };

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  @ViewChild(MatPaginator, { static: false }) paginator1: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) paginator2: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) paginator3: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) paginator4: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) paginator5: MatPaginator;
  filterData: any;
  filterByDataOneToTwoMonth: Status[] = [
    { value: 'IEC No', viewValue: 'IEC No' },
    { value: 'IEC Holder Name', viewValue: 'IEC Holder Name' },
    { value: 'AA No', viewValue: 'AA No' },
    { value: 'Export Validity Date', viewValue: 'Export Validity Date' },
    { value: 'No Of Days OverDue', viewValue: 'No Of Days OverDue' },
    { value: 'Bond Amount', viewValue: 'Bond Amount' },
    { value: 'Date Of Issue ShowCause Notice', viewValue: 'Date Of Issue ShowCause Notice' },
    // { value: 'Process', viewValue: 'Process' },
  ]
  selectedView: any;
  allCalendarView: calendarView[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Weekly", viewValue: "Weekly" },
    { value: "Monthly", viewValue: "Monthly" },
    { value: "Date Range", viewValue: "Date Range" },
  ];
  modifiedCurrentDate: any;
  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  isButtonVisible = false;
  first_day_of_month: any;
  last_day_of_month: any;
  currentDate = new Date();
  firstDay: Date;
  lastDay: Date;
  selectedFilter: string;
  events: string[] = [];
  events1: string[] = [];
  datepick = true;
  monthselectedValue: any;
  public selectedDate = new FormControl(new Date());
  public selectedDateStart = new FormControl(new Date());
  public selectedDateEnd = new FormControl(new Date());
  public months: Month[];
  public selectedMonth: Month;
  isMonthly: boolean;
  isDay: boolean;
  isWeekly: boolean;
  isRange: boolean;
  isNoOfDays:boolean;
  showTable: boolean;
  sendInput: any;
  dateData: any;
  startDate: any;
  endDate: any;
  maxDate = new Date(2050, 12, 31);
  noOfDaysValue: any;
  offsetData: any;
  pageSet: number;
  pageSizeChange: number;
  currentUser: any;
  role: any;
  totalEntries:any;
  dataSource: AuthSummary[] = [];
  loggedUserLevel: any;
  Process: any;
  user: any;
  displayedColumns = [
    "sr_no",
    "iec_number",
    "iec_holder_name",
    "auth_number",
    "evd",
    "no_of_days_overdue",
    "bond_amount_in_inr",
    "date_of_last_letter",
    "process",
  ];
  displayedColumns1 = [
    "sr_no",
    "iec_number",
    "iec_holder_name",
    "auth_number",
    "evd",
    "no_of_days_overdue",
    "bond_amount_in_inr",
    "date_of_last_letter",
    "process",
  ];
  displayedColumns2 = [
    "sr_no",
    "iec_number",
    "iec_holder_name",
    "auth_number",
    "evd",
    "no_of_days_overdue",
    "bond_amount_in_inr",
    "date_of_last_letter",
    "process",
  ];
  displayedColumns3 = [
    "sr_no",
    "iec_number",
    "iec_holder_name",
    "auth_number",
    "evd",
    "no_of_days_overdue",
    "bond_amount_in_inr",
    "date_of_last_letter",
    "process",
  ];
  displayedColumns4 = [
    "sr_no",
    "iec_number",
    "iec_holder_name",
    "auth_number",
    "evd",
    "no_of_days_overdue",
    "bond_amount_in_inr",
    "date_of_last_letter",
    "process",
  ];
  allNoOfDaysView: calendarView[] = [
    { value: "greater", viewValue: "Greater" },
    { value: "smaller", viewValue: "Smaller" },
  ];
  noOfAuth: string;
  eodcApplicationStatus: string;
  // eodcNotFiledResp: PaginatedDataSource<AuthSummary, AuthSummaryQuery>;
  eodcNotFiledResp:any;
  eodcNotGrantedResp: any;
  eodcRejectedResp: any;
  noCancellationResp: any;
  cancellationResp: any;
  eodcRejectedPaginatedResp:any;
  eodcNotFiledPaginatedResp: [];
  eodcNotGrantedPaginatedResp: [];
  cancellationPaginatedResp: [];
  noCancellationPaginatedResp: [];
  applicationType: string;
  downloadeodcNotFiledResp: any;
  downloadeodcNotFiledRespFilteredData: any;
  downloadeodcNotGrantedRespFilteredData: any;
  downloadeodcNotGrantedResp: any;
  downloadeodcRejectedResp: any;
  downloadeodcRejectedRespFilteredData: any;
  downloadnoCancellationResp: any;
  downloadnoCancellationRespFilteredData: any;
  downloadcancellationResp: any;
  downloadcancellationRespFilteredData: any;
  loader1:boolean;
  loader2:boolean;
  loader3:boolean;
  loader4:boolean;
  loader5:boolean;
  hideColumn:boolean=false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { 
    this.offsetData = JSON.parse(localStorage.getItem("offset"));
    this.pageSet = this.offsetData - 1;
    this.pageSizeChange = JSON.parse(localStorage.getItem("pageSize"));
  }

  ngOnInit(): void {
    // this.date = this.todayDate;
    localStorage.removeItem('redirectedpage');
    this.date = localStorage.getItem("ObMDate");
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.loggedUserLevel=this.currentUser.obj.level;
    if(this.loggedUserLevel=='L3'){
      this.hideColumn=true;
    }
    else{
      this.hideColumn=false;
    }
    console.log("hiddenColumn",this.hideColumn)
    console.log(this.currentUser.obj.level);
    this.role = this.currentUser.obj.user_type_id;
    this.user = this.currentUser.obj.user_id;
    this.loader1=true;
    this.loader2=true;
    this.loader3=true;
    this.loader4=true;
    this.loader5=true;
    this.pageSet=0;
    localStorage.setItem("pageSize","10");
    this.applicationType = localStorage.getItem('applicationType');
    this.eodcApplicationStatus = localStorage.getItem('eodcApplicationStatus')
    localStorage.removeItem('filter');
  if(this.eodcApplicationStatus == 'notfiled'){
    // this.getAuthorizationEodcNotFilled();
    this.processType(this.process, this.offsetData);
  } else if(this.eodcApplicationStatus == 'notgranted'){
    // this.getAuthorizationEodcFilledNotGranted();
    this.processType(this.process, this.offsetData);
  }
  else if(this.eodcApplicationStatus == 'rejected'){
    // this.getAuthorizationEodcFilledRejectedByDgft();
    this.processType(this.process, this.offsetData);
  }
  else if(this.eodcApplicationStatus == 'granted'){
    // this.getAuthorizationEodcFilledApprovedByDgftNoCancellation();
    this.processType(this.process, this.offsetData);
  }
  else if(this.eodcApplicationStatus == 'bondfiled'){
    // this.getAuthorizationEodcFilledApprovedByDgftCancellation();
    this.processType(this.process, this.offsetData);
  }
  else{}
  console.log(this.pageSet,"pageSet");
  }

  processType(type, offsetData) {
    if(this.pageSizeChange == null ){
      this.pageSizeChange = 10
    }
   
    // this.loadMonths();
    // this.getBondCancellationAppFiled(this.user, offsetData,this.pageSizeChange);
    if(this.eodcApplicationStatus == 'notfiled'){
       this.getAuthorizationEodcNotFilled(this.user, offsetData,this.pageSizeChange);
    } else if(this.eodcApplicationStatus == 'notgranted'){
       this.getAuthorizationEodcFilledNotGranted(this.user, offsetData,this.pageSizeChange);
    }
    else if(this.eodcApplicationStatus == 'rejected'){
       this.getAuthorizationEodcFilledRejectedByDgft(this.user, offsetData,this.pageSizeChange);
    }
    else if(this.eodcApplicationStatus == 'granted'){
       this.getAuthorizationEodcFilledApprovedByDgftNoCancellation(this.user, offsetData,this.pageSizeChange);

    }
    else if(this.eodcApplicationStatus == 'bondfiled'){
       this.getAuthorizationEodcFilledApprovedByDgftCancellation(this.user, offsetData,this.pageSizeChange);

    }
    else{}
  }
  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate,
      this.endDate = this.date ? this.date : this.todayDate
  }
  dateStartSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate;
    // console.log(this.startDate)
  }
  dateEndSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.endDate = this.date ? this.date : this.todayDate;
    // console.log(this.endDate)
  }
  onFilterSelected(event, status) {
    this.filterData = '';
    this.selectedDate=null;
    this.selectedDateStart=null;
    this.selectedDateEnd=null;
    this.selectedMonth=null;
    this.isMonthly = false;
    this.isNoOfDays=false;
    this.isDay = false;
    this.isWeekly = false;
    this.isRange = false;
    if (event.isUserInput) {
      this.selectedFilter = status;
    }
    if(this.selectedFilter=='Export Validity Date'){
      this.loadExportValidityMonths();
    }
    else{
      this.loadMonths();
    }
  }

  selectNoOfDaysView(event, value) {
    console.log(value);
    console.log(event);
    if (event.isUserInput==true) {
      this.noOfDaysValue=value;
      if (value == "greater" ) {
        this.isNoOfDays = true;
      }
      else if (value == "smaller" ) {
        this.isNoOfDays = true;
      }
      else {
        this.isNoOfDays = false;
      }
    }
  }

  resetFilters() {

    this.selectedFilter = '';
    this.selectedFilter = null;
    this.filterData = '';
    this.isMonthly = false;
    this.isDay = false;
    this.isWeekly = false;
    this.isRange = false;
    this.isNoOfDays=false;
    // this.dataSource = [];
    this.pageSet = 0;
    this.offsetData = 1;
    this.pageSizeChange=10;
    //this.pageSizeChange = 10;
    localStorage.setItem("offset", JSON.stringify(this.offsetData));
    localStorage.removeItem('filter');
    this.ngOnInit();
  }
  getFilterData(data, filter) {
    this.blockUI.start();
    this.loggedUserLevel=this.currentUser.obj.level;
    console.log(data);
    this.dataSource=[];
    this.pageSet = 0;
    this.offsetData = 1
    localStorage.setItem("offset", JSON.stringify(this.offsetData));
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    if (filter == 'AA No') {
      this.sendInput = {
        start_date:this.todayDate,
        auth_number: data,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
    }
    else if (filter == 'IEC No') {
      this.sendInput = {
        iec_number: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
      console.log(this.sendInput);
    }
    else if (filter == 'IEC Holder Name') {
      this.sendInput = {
        iec_holder_name: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
      console.log(this.sendInput);
    }
    else if (filter == 'Bond Amount') {
      this.sendInput = {
        bond_amount_in_inr: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
    } else if (filter == 'BG Amount') {
      this.sendInput = {
        bg_amount: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
    }
    else if (filter == 'No Of Days OverDue') {
      this.sendInput = {
        no_of_days_overdue: data,
        time_span:this.noOfDaysValue,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
    }
    else if (filter == 'Date Of Issue ShowCause Notice') {
        this.sendInput = {
          //user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          //pending_app:this.pendingApp
          scn_date:this.startDate+" 00:00:00",
          start_date:this.todayDate,
          custom_start_date:this.startDate,
          custom_end_date:this.endDate,
          // end_date:this.endDate,
        }
    } 
    else if (filter == 'Export Validity Date') {
      this.sendInput = {
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
        evd:this.startDate,
        start_date:this.todayDate,
        custom_start_date:this.startDate,
        custom_end_date:this.endDate,
      }
  
  } 

else if (filter == 'Process') {
  this.sendInput = {
    officer_name: data,
    //user_id: this.currentUser.obj.user_id,
    offset: this.offsetData,
    entries_per_page:this.pageSizeChange,
    //pending_app:this.pendingApp
  }

}

    // else if (filter == 'Incomming Date') {
    //   this.sendInput = {
    //     start_date: this.startDate,
    //     end_date: this.endDate,
    //     incoming_date: this.startDate,
    //     //user_id: this.currentUser.obj.user_id,
    //     offset: this.offsetData,
    //     entries_per_page:this.pageSizeChange,
    //     evd:"",
    //     custom_start_date:"",
    //     custom_end_date:"",
    //     //pending_app:this.pendingApp
    //   }
    // }
    setTimeout(() => {
      this.blockUI.stop();
    }, 500);
      if(this.eodcApplicationStatus == 'notfiled'){
        localStorage.setItem('filter', JSON.stringify(this.sendInput));
         this.getAuthorizationEodcNotFilledByFilter(this.sendInput);
        
      } else if(this.eodcApplicationStatus == 'notgranted'){
        localStorage.setItem('filter', JSON.stringify(this.sendInput));
         this.getAuthorizationEodcFilledNotGrantedFilter(this.sendInput);
        
      }
      else if(this.eodcApplicationStatus == 'rejected'){
        localStorage.setItem('filter', JSON.stringify(this.sendInput));
         this.getAuthorizationEodcFilledRejectedByDgftByFilter(this.sendInput);
        
      }
      else if(this.eodcApplicationStatus == 'granted'){
        localStorage.setItem('filter', JSON.stringify(this.sendInput));
         this.getAuthorizationEodcFilledApprovedByDgftNoCancellationByFilter(this.sendInput);
        
      }
      else if(this.eodcApplicationStatus == 'bondfiled'){
        localStorage.setItem('filter', JSON.stringify(this.sendInput));
         this.getAuthorizationEodcFilledApprovedByDgftCancellationFilter(this.sendInput);
      }
      //  this.getBondCancellationAppFiledFilter(this.sendInput);
  }
  getNextOneTwoMonth(event: PageEvent) {
    let offset = event.pageIndex;
    this.pageSizeChange = event.pageSize;
   
    if ((this.selectedFilter != null || this.selectedFilter != undefined) && this.filterData != null) {
      console.log(this.pageSizeChange,"1")
      this.pageSet = this.offsetData - 1;
      let filter = JSON.parse(localStorage.getItem("filter"));
      if (event.previousPageIndex == 0) {
        // localStorage.setItem("offset", JSON.stringify(offset + 1));
        filter.offset = offset + 1;
        filter.entries_per_page = this.pageSizeChange;
        if(this.eodcApplicationStatus == 'notfiled'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
           this.getAuthorizationEodcNotFilledByFilter(filter);
          
        } else if(this.eodcApplicationStatus == 'notgranted'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
           this.getAuthorizationEodcFilledNotGrantedFilter(filter);
          
        }
        else if(this.eodcApplicationStatus == 'rejected'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
           this.getAuthorizationEodcFilledRejectedByDgftByFilter(filter);
          
        }
        else if(this.eodcApplicationStatus == 'granted'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
           this.getAuthorizationEodcFilledApprovedByDgftNoCancellationByFilter(filter);
          
        }
        else if(this.eodcApplicationStatus == 'bondfiled'){
          localStorage.setItem("offset", JSON.stringify(offset + 1)); 
          this.getAuthorizationEodcFilledApprovedByDgftCancellationFilter(filter);
        }
        // this.getBondCancellationAppFiledFilter(filter);
      } else {
        // localStorage.setItem("offset", JSON.stringify(offset + 1));
        filter.offset = offset + 1;
        filter.entries_per_page = this.pageSizeChange;
        if(this.eodcApplicationStatus == 'notfiled'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcNotFilledByFilter(filter);
         
       } else if(this.eodcApplicationStatus == 'notgranted'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcFilledNotGrantedFilter(filter);
         
       }
       else if(this.eodcApplicationStatus == 'rejected'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcFilledRejectedByDgftByFilter(filter);
         
       }
       else if(this.eodcApplicationStatus == 'granted'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcFilledApprovedByDgftNoCancellationByFilter(filter);
         
       }
       else if(this.eodcApplicationStatus == 'bondfiled'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcFilledApprovedByDgftCancellationFilter(filter);
       }
        // this.getBondCancellationAppFiledFilter(filter);
      }
      
      
      
    } else {
      if (event.previousPageIndex == 0) {
        // localStorage.setItem("offset", JSON.stringify(offset + 1));
        if(this.eodcApplicationStatus == 'notfiled'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcNotFilled(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange);
         
       } else if(this.eodcApplicationStatus == 'notgranted'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcFilledNotGranted(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange);
         
       }
       else if(this.eodcApplicationStatus == 'rejected'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcFilledRejectedByDgft(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange);
         
       }
       else if(this.eodcApplicationStatus == 'granted'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcFilledApprovedByDgftNoCancellation(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange);
         
       }
       else if(this.eodcApplicationStatus == 'bondfiled'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));  
        this.getAuthorizationEodcFilledApprovedByDgftCancellation(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange);
       }
          // this.getBondCancellationAppFiled(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)

      } else {
        // localStorage.setItem("offset", JSON.stringify(offset + 1));
        if(this.eodcApplicationStatus == 'notfiled'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcNotFilled(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange);
         
       } else if(this.eodcApplicationStatus == 'notgranted'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcFilledNotGranted(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange);
         
       }
       else if(this.eodcApplicationStatus == 'rejected'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcFilledRejectedByDgft(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange);
         
       }
       else if(this.eodcApplicationStatus == 'granted'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationEodcFilledApprovedByDgftNoCancellation(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange);
         
       }
       else if(this.eodcApplicationStatus == 'bondfiled'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));  
        this.getAuthorizationEodcFilledApprovedByDgftCancellation(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange);
       }
        // this.getBondCancellationAppFiled(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)

      }
    }

  }
  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  loadMonths() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months = [];
    for (let i = 0; i < 12; i++) {

      // console.log("MOnth : ", month);
      if (month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      let name = this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = { "name": name, "value": month + 1, "start": start + " 00:00:00", "end": end + " 23:59:59" };
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
  }

  loadExportValidityMonths() {
    let date = new Date("2026-12-31");
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months = [];
    for (let i = 0; i < 156; i++) {

      // console.log("MOnth : ", month);
      if (month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      let name = this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = { "name": name, "value": month + 1, "start": start + " 00:00:00", "end": end + " 23:59:59" };
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
  }
  monthly(value, event) {
    if (event.isUserInput) {
      this.showTable = true;
      this.selectedMonth = value ? value : this.selectedMonth;
      this.startDate = this.pipe.transform(this.selectedMonth.start, "yyyy-MM-dd", "en-US");
      this.endDate = this.pipe.transform(this.selectedMonth.end, "yyyy-MM-dd", "en-US");
    }
  }

  selectCalendarView(event, value) {
    if (event.isUserInput==true) {
      if (value == "Monthly") {
        this.isMonthly = true;
        this.isDay = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Weekly") {
        this.isWeekly = true;
        this.isDay = false;
        this.isMonthly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Day") {
        this.isDay = true;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else {
        this.isDay = false;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = true;
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  compareMonths(i1, i2) {
    // return i2 && i1 && i1.value === i2.value;
    return i1 && i2 && i1.value === i2.value;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    if (this.isMonthly) {
      if (type === "change") {
        this.showTable = true;

        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        //const start =
        let date = new Date(this.today);
        //date = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        this.first_day_of_month = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        let currentMonth = date.getMonth();
        this.last_day_of_month = new Date(
          date.getFullYear(),
          currentMonth,
          this.daysInMonth(currentMonth + 1, date.getFullYear())
        );
        // let input = {
        //   start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        this.startDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        this.endDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);
        // this.dateData = input;
      }
    } else {
      if (type === "change") {
        this.showTable = true;
        this.today = event.value.getTime();

        const now = this.today;

        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        const start = new Date(this.today + 6 * 24 * 60 * 60 * 1000).getTime();
        const myStartDate = this.pipe.transform(start, "yyyy-MM-dd", "en-US");
        this.last6 = start;
        // let input = {
        //   end_date : this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   start_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        // this.dateData = input;
        this.startDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        this.endDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);

        if (this.today == this.now) {
          this.isButtonVisible = false;
        } else {

          this.isButtonVisible = true;
        }
      }
    }
  }
  getAuthorizationEodcNotFilled(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader1=true;
    console.log(offSetData);
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      // user:user,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getAuthorizationEodcNotFilled(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.eodcNotFiledResp = resp.body.obj.sd;
          this.eodcNotFiledPaginatedResp = resp.body.obj;
          this.totalEntries = resp.body.obj.total_entries;
          if (this.eodcNotFiledResp !== null) {
            this.loader1=false;
            var eodcNotFiledRespList = resp.body.obj.sd;
            this.eodcNotFiledResp = new PaginatedDataSource<AuthSummary, AuthSummaryQuery>(
              (request, query) =>
                this.blockchainService.pageAuthSummary(request, query, eodcNotFiledRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.eodcNotFiledResp.pageSize=this.pageSizeChange;
            if(this.paginator1){
            if(input.offset==1){
              this.paginator1.pageIndex=0;
              }
            }
            else{
              this.pageSizeChange=10;
              localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
            }
          }

          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader1=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  downloadAuthorizationEodcNotFilled(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    console.log(offSetData);
    var displayedCol = []
    displayedCol.push('IEC No');
    displayedCol.push('IEC Holder Name');
    displayedCol.push('AA No');
    displayedCol.push('Export Validity Date');
    displayedCol.push('No. Of Days OverDue');
    displayedCol.push('Bond Amount');
    displayedCol.push('Date Of Issue ShowCause Notice');
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      applicationType: this.applicationType ? this.applicationType : 'AA'
      // user:user,
    };
    console.log(input);
    this.blockchainService
      .getAuthorizationEodcNotFilled(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.oneTwoRespLength = resp.body.obj;
          // this.oneTwoResp = resp.body.obj.sd;
          // console.log(resp.body.obj);
          this.downloadeodcNotFiledResp = resp.body.obj.sd;
           
           console.log(this.downloadeodcNotFiledResp)
           var tableData:any=this.downloadeodcNotFiledResp;
           var tableObj =  tableData;
           console.log(tableObj,'tableobj')
       
           tableObj.forEach((element,index) => {
            let DateOfLastLetter=element.date_of_cancellation;
            let scnNo=element.scn_no;
            if(element.iec_number===null){
              element.iec_number="";
            }
            if(element.scn_date===null){
            element.scn_date='';
      
            }
             console.log(element);
             element['IEC No'] = element.iec_number;
             element['IEC Holder Name'] = element.iec_holder_name;
             element['AA No'] = element.auth_number;
             element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
             element['No. Of Days OverDue']=element.no_of_days_overdue;
             element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');;
             element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
       
             for (let i = 0; i < tableObj.length; i++) {
              for (let property in tableObj[i]) {
                // console.log(property);
                if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                  tableObj[i][property] = '';
                }
              }
            }
       // console.log(this.selectedTableDataforDownload[index].join(','));
           });
        const sortedElementsList = tableObj.sort((a, b) => a.application_id);
this.downloadFile(
  sortedElementsList,
  'EODC APPLICATION NOT FILED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
  displayedCol
);
for (let i = 0; i < tableObj.length; i++) {
  for (let property in tableObj[i]) {
    // console.log(property);
    if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
      tableObj[i][property] = '';
    }
  }
}
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  getAuthorizationEodcNotFilledByFilter(inputData:any) {
    this.loader1=true;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    //this.blockUI.start("Please wait this may take few seconds ...");
    // console.log(offSetData);
    // let input = {
    //   start_date: this.date,
    //   entries_per_page:pageSizeSet,
    //   offset:offSetData,
    //   // user:user,
    // };
    inputData.applicationType = this.applicationType ? this.applicationType : 'AA' 
    this.blockchainService
      .getAuthorizationEodcNotFilledFilter(inputData)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.eodcNotFiledResp = resp.body.obj.sd;
          this.eodcNotFiledPaginatedResp = resp.body.obj;
          if(inputData.offset==1){
          this.paginator1.pageIndex=0;
          }
          this.totalEntries = resp.body.obj.total_entries;
          if (this.eodcNotFiledResp !== null) {
            this.loader1=false;
            var eodcNotFiledRespList = resp.body.obj.sd;
            this.eodcNotFiledResp = new PaginatedDataSource<AuthSummary, AuthSummaryQuery>(
              (request, query) =>
                this.blockchainService.pageAuthSummary(request, query, eodcNotFiledRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.eodcNotFiledResp.pageSize=this.pageSizeChange;
          }

          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader1=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  downloadAuthorizationEodcNotFilledByFilter(input) {
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // let inputData = {
    //   user_id: input,
    //   offset: offSetData,
    //   entries_per_page:pageSizeSet,
    // }
 
    input.applicationType = this.applicationType ? this.applicationType : 'AA';
    input.entries_per_page=1147483647;
    this.blockchainService
    .getAuthorizationEodcNotFilledFilter(input)
    .subscribe((resp) => {
      if (resp.body.status == 200) {
        var displayedCol = []
        displayedCol.push('IEC No');
        displayedCol.push('IEC Holder Name');
        displayedCol.push('AA No');
        displayedCol.push('Export Validity Date');
        displayedCol.push('No. Of Days OverDue');
        displayedCol.push('Bond Amount');
        displayedCol.push('Date Of Issue ShowCause Notice');
          this.downloadeodcNotFiledRespFilteredData=resp.body.obj.sd;
          console.log(this.downloadeodcNotFiledRespFilteredData)
          var tableData:any=this.downloadeodcNotFiledRespFilteredData;
          var tableObj =  tableData;
          console.log(tableObj,'tableobj')
      
          tableObj.forEach((element,index) => {
       let DateOfLastLetter=element.date_of_cancellation;
      let scnNo=element.scn_no;
      if(element.iec_no===null){
        element.iec_no="";
      }
      if(element.date_of_cancellation===null){
      element.date_of_cancellation='';
      }
            console.log(element);
            element['IEC No'] = element.iec_number;
            element['IEC Holder Name'] = element.iec_holder_name;
            element['AA No'] = element.auth_number;
            element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
            element['No. Of Days OverDue']=element.no_of_days_overdue;
            element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');;
            element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
            for (let i = 0; i < tableObj.length; i++) {
             for (let property in tableObj[i]) {
               // console.log(property);
               if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                 tableObj[i][property] = '';
               }
             }
           }
      // console.log(this.selectedTableDataforDownload[index].join(','));
          });
       const sortedElementsList = tableObj.sort((a, b) => a.application_id);
        this.downloadFile(
        sortedElementsList,
        'EODC APPLICATION NOT FILED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
        displayedCol);
        // }

        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
       else {
        this.totalEntries=0;
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  getAuthorizationEodcFilledNotGranted(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader2=true;
    console.log(offSetData);
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      // user:user,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getAuthorizationEodcFilledNotGranted(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.eodcNotGrantedResp = resp.body.obj.sd;
          this.eodcNotGrantedPaginatedResp = resp.body.obj;
          this.totalEntries = resp.body.obj.total_entries;
          if (this.eodcNotGrantedResp !== null) {
            this.loader2=false;
            var eodcNotGrantedRespList = resp.body.obj.sd;
            this.eodcNotGrantedResp = new PaginatedDataSource<AuthSummary, AuthSummaryQuery>(
              (request, query) =>
                this.blockchainService.pageAuthSummary(request, query, eodcNotGrantedRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.eodcNotGrantedResp.pageSize=this.pageSizeChange;
            if(this.paginator2){
            if(input.offset==1){
              this.paginator2.pageIndex=0;
              }
            }
            else{
              this.pageSizeChange=10;
              localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));

            }
          }
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader2=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  downloadAuthorizationEodcFilledNotGranted(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    console.log(offSetData);
    var displayedCol = []
    displayedCol.push('IEC No');
    displayedCol.push('IEC Holder Name');
    displayedCol.push('AA No');
    displayedCol.push('Export Validity Date');
    displayedCol.push('No. Of Days OverDue');
    displayedCol.push('Bond Amount');
    displayedCol.push('Date Of Issue ShowCause Notice');
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      applicationType: this.applicationType ? this.applicationType : 'AA'
      // user:user,
    };
    console.log(input);
    this.blockchainService
      .getAuthorizationEodcFilledNotGranted(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.oneTwoRespLength = resp.body.obj;
          // this.oneTwoResp = resp.body.obj.sd;
          // console.log(resp.body.obj);
          this.downloadeodcNotGrantedResp = resp.body.obj.sd;
           
           console.log(this.downloadeodcNotGrantedResp)
           var tableData:any=this.downloadeodcNotGrantedResp;
           var tableObj =  tableData;
           console.log(tableObj,'tableobj')
       
           tableObj.forEach((element,index) => {
            let DateOfLastLetter=element.date_of_cancellation;
            let scnNo=element.scn_no;
            if(element.iec_number===null){
              element.iec_number="";
            }
            if(element.scn_date===null){
            element.scn_date='';
      
            }
             console.log(element);
             element['IEC No'] = element.iec_number;
             element['IEC Holder Name'] = element.iec_holder_name;
             element['AA No'] = element.auth_number;
             element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
             element['No. Of Days OverDue']=element.no_of_days_overdue;
             element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');;
             element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
       
             for (let i = 0; i < tableObj.length; i++) {
              for (let property in tableObj[i]) {
                // console.log(property);
                if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                  tableObj[i][property] = '';
                }
              }
            }
       // console.log(this.selectedTableDataforDownload[index].join(','));
           });
        const sortedElementsList = tableObj.sort((a, b) => a.application_id);
this.downloadFile(
  sortedElementsList,
  'EODC APPLIED BUT NOT YET GRANTED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
  displayedCol
);
for (let i = 0; i < tableObj.length; i++) {
  for (let property in tableObj[i]) {
    // console.log(property);
    if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
      tableObj[i][property] = '';
    }
  }
}
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  getAuthorizationEodcFilledNotGrantedFilter(inputData:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    // console.log(offSetData);
    // let input = {
    //   start_date: this.date,
    //   entries_per_page:pageSizeSet,
    //   offset:offSetData,
    //   // user:user,
    // };
    this.loader2=true;
    inputData.applicationType = this.applicationType ? this.applicationType : 'AA' 
    this.blockchainService
      .getAuthorizationEodcFilledNotGrantedFilter(inputData)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.eodcNotGrantedResp = resp.body.obj.sd;
          this.eodcNotGrantedPaginatedResp = resp.body.obj;
          this.totalEntries = resp.body.obj.total_entries;
          if(inputData.offset==1){
          this.paginator2.pageIndex=0;
          }
          if (this.eodcNotGrantedResp !== null) {
            this.loader2=false;
            var eodcNotGrantedRespList = resp.body.obj.sd;
            this.eodcNotGrantedResp = new PaginatedDataSource<AuthSummary, AuthSummaryQuery>(
              (request, query) =>
                this.blockchainService.pageAuthSummary(request, query, eodcNotGrantedRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.eodcNotGrantedResp.pageSize=this.pageSizeChange;

          }
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader2=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  downloadAuthorizationEodcFilledNotGrantedFilter(input) {
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // let inputData = {
    //   user_id: input,
    //   offset: offSetData,
    //   entries_per_page:pageSizeSet,
    // }
 
    input.applicationType = this.applicationType ? this.applicationType : 'AA';
    input.entries_per_page=1147483647;
    this.blockchainService
    .getAuthorizationEodcFilledNotGrantedFilter(input)
    .subscribe((resp) => {
      if (resp.body.status == 200) {
        var displayedCol = []
        displayedCol.push('IEC No');
        displayedCol.push('IEC Holder Name');
        displayedCol.push('AA No');
        displayedCol.push('Export Validity Date');
        displayedCol.push('No. Of Days OverDue');
        displayedCol.push('Bond Amount');
        displayedCol.push('Date Of Issue ShowCause Notice');
          this.downloadeodcNotGrantedRespFilteredData=resp.body.obj.sd;
          console.log(this.downloadeodcNotGrantedRespFilteredData)
          var tableData:any=this.downloadeodcNotGrantedRespFilteredData;
          var tableObj =  tableData;
          console.log(tableObj,'tableobj')
      
          tableObj.forEach((element,index) => {
       let DateOfLastLetter=element.date_of_cancellation;
      let scnNo=element.scn_no;
      if(element.iec_no===null){
        element.iec_no="";
      }
      if(element.date_of_cancellation===null){
      element.date_of_cancellation='';
      }
            console.log(element);
            element['IEC No'] = element.iec_number;
            element['IEC Holder Name'] = element.iec_holder_name;
            element['AA No'] = element.auth_number;
            element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
            element['No. Of Days OverDue']=element.no_of_days_overdue;
            element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');;
            element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
            for (let i = 0; i < tableObj.length; i++) {
             for (let property in tableObj[i]) {
               // console.log(property);
               if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                 tableObj[i][property] = '';
               }
             }
           }
      // console.log(this.selectedTableDataforDownload[index].join(','));
          });
       const sortedElementsList = tableObj.sort((a, b) => a.application_id);
        this.downloadFile(
        sortedElementsList,
        'EODC APPLIED BUT NOT YET GRANTED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
        displayedCol);
        // }

        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
       else {
        this.totalEntries=0;
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  getAuthorizationEodcFilledRejectedByDgft(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader3=true;
    console.log(offSetData);
    
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      // user:user,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getAuthorizationEodcFilledRejectedByDgft(input)
      .subscribe((resp) => {
        this.eodcRejectedPaginatedResp=resp.body.obj;
        console.log(this.eodcRejectedPaginatedResp);
        if (resp.body.status == 200) {
          this.loader3=false;
          this.eodcRejectedResp = resp.body.obj.sd;
          // this.eodcRejectedPaginatedResp=resp.body.obj;
          this.totalEntries = resp.body.obj.total_entries;
          
          if (this.eodcRejectedResp !== null) {
            var eodcRejectedRespList = resp.body.obj.sd;
            this.eodcRejectedResp = new PaginatedDataSource<AuthSummary, AuthSummaryQuery>(
              (request, query) =>
                this.blockchainService.pageAuthSummary(request, query, eodcRejectedRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.eodcRejectedResp.pageSize=this.pageSizeChange;
            if(this.paginator3){
            if(input.offset==1){
              this.paginator3.pageIndex=0;
              }
            }
            else{
              this.pageSizeChange=10;
              localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));

            }
          }
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader3=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  downloadAuthorizationEodcFilledRejectedByDgft(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    console.log(offSetData);
    var displayedCol = []
    displayedCol.push('IEC No');
    displayedCol.push('IEC Holder Name');
    displayedCol.push('AA No');
    displayedCol.push('Export Validity Date');
    displayedCol.push('No. Of Days OverDue');
    displayedCol.push('Bond Amount');
    displayedCol.push('Date Of Issue ShowCause Notice');
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      applicationType: this.applicationType ? this.applicationType : 'AA'
      // user:user,
    };
    console.log(input);
    this.blockchainService
      .getAuthorizationEodcFilledRejectedByDgft(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.oneTwoRespLength = resp.body.obj;
          // this.oneTwoResp = resp.body.obj.sd;
          // console.log(resp.body.obj);
          this.downloadeodcRejectedResp = resp.body.obj.sd;
           
           console.log(this.downloadeodcRejectedResp)
           var tableData:any=this.downloadeodcRejectedResp;
           var tableObj =  tableData;
           console.log(tableObj,'tableobj')
       
           tableObj.forEach((element,index) => {
            let DateOfLastLetter=element.date_of_cancellation;
            let scnNo=element.scn_no;
            if(element.iec_number===null){
              element.iec_number="";
            }
            if(element.scn_date===null){
            element.scn_date='';
      
            }
             console.log(element);
             element['IEC No'] = element.iec_number;
             element['IEC Holder Name'] = element.iec_holder_name;
             element['AA No'] = element.auth_number;
             element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
             element['No. Of Days OverDue']=element.no_of_days_overdue;
             element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');;
             element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
       
             for (let i = 0; i < tableObj.length; i++) {
              for (let property in tableObj[i]) {
                // console.log(property);
                if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                  tableObj[i][property] = '';
                }
              }
            }
       // console.log(this.selectedTableDataforDownload[index].join(','));
           });
        const sortedElementsList = tableObj.sort((a, b) => a.application_id);
this.downloadFile(
  sortedElementsList,
  'EODC APPLIED AND REJECTED BY DGFT' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
  displayedCol
);
for (let i = 0; i < tableObj.length; i++) {
  for (let property in tableObj[i]) {
    // console.log(property);
    if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
      tableObj[i][property] = '';
    }
  }
}
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  getAuthorizationEodcFilledRejectedByDgftByFilter(inputData:any) {
    this.loader3=true;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    //this.blockUI.start("Please wait this may take few seconds ...");
    // console.log(offSetData);
    // let input = {
    //   start_date: this.date,
    //   entries_per_page:pageSizeSet,
    //   offset:offSetData,
    //   // user:user,
    // };
    inputData.applicationType = this.applicationType ? this.applicationType : 'AA' 
    this.blockchainService
      .getAuthorizationEodcFilledRejectedByDgftFilter(inputData)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.eodcRejectedResp = resp.body.obj.sd;
          this.eodcRejectedPaginatedResp=resp.body.obj;
          this.totalEntries = resp.body.obj.total_entries;
          if(inputData.offset==1){
          this.paginator3.pageIndex=0;
          }
          if (this.eodcRejectedResp !== null) {
            this.loader3=false;
            var eodcRejectedRespList = resp.body.obj.sd;
            this.eodcRejectedResp = new PaginatedDataSource<AuthSummary, AuthSummaryQuery>(
              (request, query) =>
                this.blockchainService.pageAuthSummary(request, query, eodcRejectedRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.eodcRejectedResp.pageSize=this.pageSizeChange;

          }
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader3=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  downloadAuthorizationEodcFilledRejectedByDgftByFilter(input) {
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // let inputData = {
    //   user_id: input,
    //   offset: offSetData,
    //   entries_per_page:pageSizeSet,
    // }
 
    input.applicationType = this.applicationType ? this.applicationType : 'AA';
    input.entries_per_page=1147483647;
    this.blockchainService
    .getAuthorizationEodcFilledRejectedByDgftFilter(input)
    .subscribe((resp) => {
      if (resp.body.status == 200) {
        var displayedCol = []
        displayedCol.push('IEC No');
        displayedCol.push('IEC Holder Name');
        displayedCol.push('AA No');
        displayedCol.push('Export Validity Date');
        displayedCol.push('No. Of Days OverDue');
        displayedCol.push('Bond Amount');
        displayedCol.push('Date Of Issue ShowCause Notice');
          this.downloadeodcRejectedRespFilteredData=resp.body.obj.sd;
          console.log(this.downloadeodcRejectedRespFilteredData)
          var tableData:any=this.downloadeodcRejectedRespFilteredData;
          var tableObj =  tableData;
          console.log(tableObj,'tableobj')
      
          tableObj.forEach((element,index) => {
       let DateOfLastLetter=element.date_of_cancellation;
      let scnNo=element.scn_no;
      if(element.iec_no===null){
        element.iec_no="";
      }
      if(element.date_of_cancellation===null){
      element.date_of_cancellation='';
      }
            console.log(element);
            element['IEC No'] = element.iec_number;
            element['IEC Holder Name'] = element.iec_holder_name;
            element['AA No'] = element.auth_number;
            element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
            element['No. Of Days OverDue']=element.no_of_days_overdue;
            element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');;
            element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
            for (let i = 0; i < tableObj.length; i++) {
             for (let property in tableObj[i]) {
               // console.log(property);
               if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                 tableObj[i][property] = '';
               }
             }
           }
      // console.log(this.selectedTableDataforDownload[index].join(','));
          });
       const sortedElementsList = tableObj.sort((a, b) => a.application_id);
        this.downloadFile(
        sortedElementsList,
        'EODC APPLIED AND REJECTED BY DGFT' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
        displayedCol);
        // }

        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
       else {
        this.totalEntries=0;
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    });
  }
  
  getAuthorizationEodcFilledApprovedByDgftNoCancellation(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader4=true;
    console.log(offSetData);
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      // user:user,
    };
    this.blockchainService
      .getAuthorizationEodcFilledApprovedByDgftNoCancellation(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.noCancellationResp = resp.body.obj.sd;
          this.noCancellationPaginatedResp = resp.body.obj;
          this.totalEntries = resp.body.obj.total_entries;
          if (this.noCancellationResp !== null) {
            this.loader4=false;
            var noCancellationRespList = resp.body.obj.sd;
            this.noCancellationResp = new PaginatedDataSource<AuthSummary, AuthSummaryQuery>(
              (request, query) =>
                this.blockchainService.pageAuthSummary(request, query, noCancellationRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.noCancellationResp.pageSize=this.pageSizeChange;
            if(this.paginator4){
            if(input.offset==1){
              this.paginator4.pageIndex=0;
            }
          }
          else{
            this.pageSizeChange=10;
            localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));

          }

          }
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader4=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  downloadAuthorizationEodcFilledApprovedByDgftNoCancellation(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    console.log(offSetData);
    var displayedCol = []
    displayedCol.push('IEC No');
    displayedCol.push('IEC Holder Name');
    displayedCol.push('AA No');
    displayedCol.push('Export Validity Date');
    displayedCol.push('No. Of Days OverDue');
    displayedCol.push('Bond Amount');
    displayedCol.push('Date Of Issue ShowCause Notice');
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      // applicationType: this.applicationType ? this.applicationType : 'AA'
      // user:user,
    };
    console.log(input);
    this.blockchainService
      .getAuthorizationEodcFilledApprovedByDgftNoCancellation(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.oneTwoRespLength = resp.body.obj;
          // this.oneTwoResp = resp.body.obj.sd;
          // console.log(resp.body.obj);
          this.downloadnoCancellationResp = resp.body.obj.sd;
           
           console.log(this.downloadnoCancellationResp)
           var tableData:any=this.downloadnoCancellationResp;
           var tableObj =  tableData;
           console.log(tableObj,'tableobj')
       
           tableObj.forEach((element,index) => {
            let DateOfLastLetter=element.date_of_cancellation;
            let scnNo=element.scn_no;
            if(element.iec_number===null){
              element.iec_number="";
            }
            if(element.scn_date===null){
            element.scn_date='';
      
            }
             console.log(element);
             element['IEC No'] = element.iec_number;
             element['IEC Holder Name'] = element.iec_holder_name;
             element['AA No'] = element.auth_number;
             element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
             element['No. Of Days OverDue']=element.no_of_days_overdue;
             element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');;
             element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
       
             for (let i = 0; i < tableObj.length; i++) {
              for (let property in tableObj[i]) {
                // console.log(property);
                if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                  tableObj[i][property] = '';
                }
              }
            }
       // console.log(this.selectedTableDataforDownload[index].join(','));
           });
        const sortedElementsList = tableObj.sort((a, b) => a.application_id);
this.downloadFile(
  sortedElementsList,
  'EODC APPLIED & GRANTED BY DGFT BUT BOND CANCELLATION NOT FILED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
  displayedCol
);
for (let i = 0; i < tableObj.length; i++) {
  for (let property in tableObj[i]) {
    // console.log(property);
    if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
      tableObj[i][property] = '';
    }
  }
}
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  getAuthorizationEodcFilledApprovedByDgftNoCancellationByFilter(inputData:any) {
    this.loader4=true;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    //this.blockUI.start("Please wait this may take few seconds ...");
    // console.log(offSetData);
    // let input = {
    //   start_date: this.date,
    //   entries_per_page:pageSizeSet,
    //   offset:offSetData,
    //   // user:user,
    // };
    this.blockchainService
      .getAuthorizationEodcFilledApprovedByDgftNoCancellationFilter(inputData)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.noCancellationResp = resp.body.obj.sd;
          this.noCancellationPaginatedResp = resp.body.obj;
          this.totalEntries = resp.body.obj.total_entries;
          if(inputData.offset==1){
          this.paginator4.pageIndex=0;
          }
          if (this.noCancellationResp !== null) {
            this.loader4=false;
            var noCancellationRespList = resp.body.obj.sd;
            this.noCancellationResp = new PaginatedDataSource<AuthSummary, AuthSummaryQuery>(
              (request, query) =>
                this.blockchainService.pageAuthSummary(request, query, noCancellationRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.noCancellationResp.pageSize=this.pageSizeChange;

          }
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader4=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  downloadAuthorizationEodcFilledApprovedByDgftNoCancellationByFilter(input) {
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // let inputData = {
    //   user_id: input,
    //   offset: offSetData,
    //   entries_per_page:pageSizeSet,
    // }
 
    input.applicationType = this.applicationType ? this.applicationType : 'AA';
    input.entries_per_page=1147483647;
    this.blockchainService
    .getAuthorizationEodcFilledApprovedByDgftNoCancellationFilter(input)
    .subscribe((resp) => {
      if (resp.body.status == 200) {
        var displayedCol = []
        displayedCol.push('IEC No');
        displayedCol.push('IEC Holder Name');
        displayedCol.push('AA No');
        displayedCol.push('Export Validity Date');
        displayedCol.push('No. Of Days OverDue');
        displayedCol.push('Bond Amount');
        displayedCol.push('Date Of Issue ShowCause Notice');
          this.downloadnoCancellationRespFilteredData=resp.body.obj.sd;
          console.log(this.downloadnoCancellationRespFilteredData)
          var tableData:any=this.downloadnoCancellationRespFilteredData;
          var tableObj =  tableData;
          console.log(tableObj,'tableobj')
      
          tableObj.forEach((element,index) => {
       let DateOfLastLetter=element.date_of_cancellation;
      let scnNo=element.scn_no;
      if(element.iec_no===null){
        element.iec_no="";
      }
      if(element.date_of_cancellation===null){
      element.date_of_cancellation='';
      }
            console.log(element);
            element['IEC No'] = element.iec_number;
            element['IEC Holder Name'] = element.iec_holder_name;
            element['AA No'] = element.auth_number;
            element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
            element['No. Of Days OverDue']=element.no_of_days_overdue;
            element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');;
            element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
            for (let i = 0; i < tableObj.length; i++) {
             for (let property in tableObj[i]) {
               // console.log(property);
               if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                 tableObj[i][property] = '';
               }
             }
           }
      // console.log(this.selectedTableDataforDownload[index].join(','));
          });
       const sortedElementsList = tableObj.sort((a, b) => a.application_id);
        this.downloadFile(
        sortedElementsList,
        'EODC APPLIED & GRANTED BY DGFT BUT BOND CANCELLATION NOT FILED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
        displayedCol);
        // }

        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
       else {
        this.totalEntries=0;
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    });
  }
  getAuthorizationEodcFilledApprovedByDgftCancellation(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader5=true;
    console.log(offSetData);
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      // user:user,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getAuthorizationEodcFilledApprovedByDgftCancellation(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.cancellationResp = resp.body.obj.sd;
          this.cancellationPaginatedResp = resp.body.obj;
          this.totalEntries = resp.body.obj.total_entries;

          if (this.cancellationResp !== null) {
            this.loader5=false;
            var cancellationRespList = resp.body.obj.sd;
            this.cancellationResp = new PaginatedDataSource<AuthSummary, AuthSummaryQuery>(
              (request, query) =>
                this.blockchainService.pageAuthSummary(request, query, cancellationRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            if(this.paginator5){
            if(input.offset==1){
              this.paginator5.pageIndex=0;
              this.cancellationResp.pageSize=this.pageSizeChange;
              }
            }
            else{
              this.pageSizeChange=10;
              localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));

            }
          }
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader5=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  downloadAuthorizationEodcFilledApprovedByDgftCancellation(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    console.log(offSetData);
    var displayedCol = []
    displayedCol.push('IEC No');
    displayedCol.push('IEC Holder Name');
    displayedCol.push('AA No');
    displayedCol.push('Export Validity Date');
    displayedCol.push('No. Of Days OverDue');
    displayedCol.push('Bond Amount');
    displayedCol.push('Date Of Issue ShowCause Notice');
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      applicationType: this.applicationType ? this.applicationType : 'AA'
      // user:user,
    };
    console.log(input);
    this.blockchainService
      .getAuthorizationEodcFilledApprovedByDgftCancellation(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.oneTwoRespLength = resp.body.obj;
          // this.oneTwoResp = resp.body.obj.sd;
          // console.log(resp.body.obj);
          this.downloadcancellationResp = resp.body.obj.sd;
           
           console.log(this.downloadcancellationResp)
           var tableData:any=this.downloadcancellationResp;
           var tableObj =  tableData;
           console.log(tableObj,'tableobj')
       
           tableObj.forEach((element,index) => {
            let DateOfLastLetter=element.date_of_cancellation;
            let scnNo=element.scn_no;
            if(element.iec_number===null){
              element.iec_number="";
            }
            if(element.scn_date===null){
            element.scn_date='';
      
            }
             console.log(element);
             element['IEC No'] = element.iec_number;
             element['IEC Holder Name'] = element.iec_holder_name;
             element['AA No'] = element.auth_number;
             element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
             element['No. Of Days OverDue']=element.no_of_days_overdue;
             element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');;
             element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
       
             for (let i = 0; i < tableObj.length; i++) {
              for (let property in tableObj[i]) {
                // console.log(property);
                if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                  tableObj[i][property] = '';
                }
              }
            }
       // console.log(this.selectedTableDataforDownload[index].join(','));
           });
        const sortedElementsList = tableObj.sort((a, b) => a.application_id);
this.downloadFile(
  sortedElementsList,
  'EODC GRANTED BY DGFT AND BOND CANCELLATION FILED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
  displayedCol
);
for (let i = 0; i < tableObj.length; i++) {
  for (let property in tableObj[i]) {
    // console.log(property);
    if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
      tableObj[i][property] = '';
    }
  }
}
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  getAuthorizationEodcFilledApprovedByDgftCancellationFilter(inputData:any) {
    this.loader5=true;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    //this.blockUI.start("Please wait this may take few seconds ...");
    // console.log(offSetData);
    // let input = {
    //   start_date: this.date,
    //   entries_per_page:pageSizeSet,
    //   offset:offSetData,
    //   // user:user,
    // };
    inputData.applicationType = this.applicationType ? this.applicationType : 'AA' 
    this.blockchainService
      .getAuthorizationEodcFilledApprovedByDgftCancellationFilter(inputData)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.cancellationResp = resp.body.obj.sd;
          this.cancellationPaginatedResp = resp.body.obj;
          this.totalEntries = resp.body.obj.total_entries;
          if(inputData.offset==1){
          this.paginator5.pageIndex=0;
          }
  
          if (this.cancellationResp !== null) {
            this.loader5=false;
            var cancellationRespList = resp.body.obj.sd;
            this.cancellationResp = new PaginatedDataSource<AuthSummary, AuthSummaryQuery>(
              (request, query) =>
                this.blockchainService.pageAuthSummary(request, query, cancellationRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.cancellationResp.pageSize=this.pageSizeChange;

          }
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader5=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  downloadAuthorizationEodcFilledApprovedByDgftCancellationFilter(input) {
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // let inputData = {
    //   user_id: input,
    //   offset: offSetData,
    //   entries_per_page:pageSizeSet,
    // }
 
    input.applicationType = this.applicationType ? this.applicationType : 'AA';
    input.entries_per_page=1147483647;
    this.blockchainService
    .getAuthorizationEodcFilledApprovedByDgftCancellationFilter(input)
    .subscribe((resp) => {
      if (resp.body.status == 200) {
        var displayedCol = []
        displayedCol.push('IEC No');
        displayedCol.push('IEC Holder Name');
        displayedCol.push('AA No');
        displayedCol.push('Export Validity Date');
        displayedCol.push('No. Of Days OverDue');
        displayedCol.push('Bond Amount');
        displayedCol.push('Date Of Issue ShowCause Notice');
          this.downloadcancellationRespFilteredData=resp.body.obj.sd;
          console.log(this.downloadcancellationRespFilteredData)
          var tableData:any=this.downloadcancellationRespFilteredData;
          var tableObj =  tableData;
          console.log(tableObj,'tableobj')
      
          tableObj.forEach((element,index) => {
       let DateOfLastLetter=element.date_of_cancellation;
      let scnNo=element.scn_no;
      if(element.iec_no===null){
        element.iec_no="";
      }
      if(element.date_of_cancellation===null){
      element.date_of_cancellation='';
      }
            console.log(element);
            element['IEC No'] = element.iec_number;
            element['IEC Holder Name'] = element.iec_holder_name;
            element['AA No'] = element.auth_number;
            element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
            element['No. Of Days OverDue']=element.no_of_days_overdue;
            element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');;
            element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
            for (let i = 0; i < tableObj.length; i++) {
             for (let property in tableObj[i]) {
               // console.log(property);
               if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                 tableObj[i][property] = '';
               }
             }
           }
      // console.log(this.selectedTableDataforDownload[index].join(','));
          });
       const sortedElementsList = tableObj.sort((a, b) => a.application_id);
        this.downloadFile(
        sortedElementsList,
        'EODC GRANTED BY DGFT AND BOND CANCELLATION FILED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
        displayedCol);
        // }

        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
       else {
        this.totalEntries=0;
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    });
  }


  process(authno){    
    let url4="detailAuthSummaryValidity";
    localStorage.setItem('redirectedpage',url4);
    localStorage.setItem('authno',authno);
    this.router.navigate(['/scnModulePS']);
  }

  back(){
    this.router.navigate(['/processorView']); 
  }

reportGeneration() { 
//     var displayedCol = []
//     displayedCol.push('IEC No');
//     displayedCol.push('IEC Holder Name');
//     displayedCol.push('AA No');
//     displayedCol.push('Export Validity Date');
//     displayedCol.push('No. Of Days OverDue');
//     displayedCol.push('Bond Amount');
//     displayedCol.push('Date Of Issue ShowCause Notice');
//     var tableData:any=this.eodcNotFiledPaginatedResp;
//     if(tableData.sd){
//       var tableObj =  tableData.sd;
//       }
//       else{
//         var tableObj =  tableData;
//       }
//     console.log(tableObj,'tableobj')
//   // tableObj.forEach(elementNatureOfPayment => {
//   //   console.log(elementNatureOfPayment);
//   //   this.selectedTableDataforDownload.push(elementNatureOfPayment.nature_of_payment);
//   // });
//   // console.log(this.selectedTableDataforDownload);
  
//     tableObj.forEach((element,index) => {
//       let DateOfLastLetter=element.date_of_cancellation;
//       let scnNo=element.scn_no;
//       if(element.iec_number===null){
//         element.iec_number="";
//       }
//       if(element.scn_date===null){
//       element.scn_date='';

//       }

//       console.log(element);
//       element['IEC No'] = element.iec_number;
//       element['IEC Holder Name'] = element.iec_holder_name;
//       element['AA No'] = element.auth_number;
//       element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
//       element['No. Of Days OverDue']=element.no_of_days_overdue;
//       element['Bond Amount']=element.bond_amount_in_inr;
//       element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");

// // console.log(this.selectedTableDataforDownload[index].join(','));
//     });
//     for (let i = 0; i < tableObj.length; i++) {
//       for (let property in tableObj[i]) {
//         console.log(property);
//         if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
//           tableObj[i][property] = '';
//         }
//       }
//     }
//     console.log(tableObj);
//     const sortedElementsList = tableObj.sort((a, b) => a.application_id);
//     this.downloadFile(
//       sortedElementsList,
//       'EODC APPLICATION NOT FILED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
//       displayedCol
//     );
let tableFilterData = JSON.parse(localStorage.getItem('filter'));
console.log(tableFilterData);
if(tableFilterData){
   
this.downloadAuthorizationEodcNotFilledByFilter(tableFilterData);
}

else{
  
let offset = localStorage.getItem("offset");
this.downloadAuthorizationEodcNotFilled(this.currentUser.obj.user_id,1,1147483647)
// console.log(this.downloadOneTwoResp);
}  
  }
reportGeneration1() { 
//   var displayedCol = []
//   displayedCol.push('IEC No');
//   displayedCol.push('IEC Holder Name');
//   displayedCol.push('AA No');
//   displayedCol.push('Export Validity Date');
//   displayedCol.push('No. Of Days OverDue');
//   displayedCol.push('Bond Amount');
//   displayedCol.push('Date Of Issue ShowCause Notice');
//   var tableData:any=this.eodcNotGrantedPaginatedResp;
//   if(tableData.sd){
//     var tableObj =  tableData.sd;
//     }
//     else{
//       var tableObj =  tableData;
//     }
//   console.log(tableObj,'tableobj')
// // tableObj.forEach(elementNatureOfPayment => {
// //   console.log(elementNatureOfPayment);
// //   this.selectedTableDataforDownload.push(elementNatureOfPayment.nature_of_payment);
// // });
// // console.log(this.selectedTableDataforDownload);

//   tableObj.forEach((element,index) => {
//     let DateOfLastLetter=element.date_of_cancellation;
//     let scnNo=element.scn_no;
//     if(element.iec_number===null){
//       element.iec_number="";
//     }
//     if(element.scn_date===null){
//     element.scn_date='';

//     }

//     console.log(element);
//     element['IEC No'] = element.iec_number;
//     element['IEC Holder Name'] = element.iec_holder_name;
//     element['AA No'] = element.auth_number;
//     element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
//     element['No. Of Days OverDue']=element.no_of_days_overdue;
//     element['Bond Amount']=element.bond_amount_in_inr;
//     element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");

// // console.log(this.selectedTableDataforDownload[index].join(','));
//   });
//   for (let i = 0; i < tableObj.length; i++) {
//     for (let property in tableObj[i]) {
//       console.log(property);
//       if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
//         tableObj[i][property] = '';
//       }
//     }
//   }
//   console.log(tableObj);
//   const sortedElementsList = tableObj.sort((a, b) => a.application_id);
//   this.downloadFile(
//     sortedElementsList,
//     'EODC APPLIED BUT NOT YET GRANTED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
//     displayedCol
//   );
let tableFilterData = JSON.parse(localStorage.getItem('filter'));
console.log(tableFilterData);
if(tableFilterData){
   
this.downloadAuthorizationEodcFilledNotGrantedFilter(tableFilterData);
}

else{
  
let offset = localStorage.getItem("offset");
this.downloadAuthorizationEodcFilledNotGranted(this.currentUser.obj.user_id,1,1147483647)
// console.log(this.downloadOneTwoResp);
}  
}
reportGeneration2() { 
//   var displayedCol = []
//   displayedCol.push('IEC No');
//   displayedCol.push('IEC Holder Name');
//   displayedCol.push('AA No');
//   displayedCol.push('Export Validity Date');
//   displayedCol.push('No. Of Days OverDue');
//   displayedCol.push('Bond Amount');
//   displayedCol.push('Date Of Issue ShowCause Notice');
//   var tableData:any=this.eodcRejectedPaginatedResp;
//   if(tableData.sd){
//     var tableObj =  tableData.sd;
//     }
//     else{
//       var tableObj =  tableData;
//     }
//   console.log(tableObj,'tableobj')
// // tableObj.forEach(elementNatureOfPayment => {
// //   console.log(elementNatureOfPayment);
// //   this.selectedTableDataforDownload.push(elementNatureOfPayment.nature_of_payment);
// // });
// // console.log(this.selectedTableDataforDownload);

//   tableObj.forEach((element,index) => {
//     let DateOfLastLetter=element.date_of_cancellation;
//     let scnNo=element.scn_no;
//     if(element.iec_number===null){
//       element.iec_number="";
//     }
//     if(element.scn_date===null){
//     element.scn_date='';

//     }

//     console.log(element);
//     element['IEC No'] = element.iec_number;
//     element['IEC Holder Name'] = element.iec_holder_name;
//     element['AA No'] = element.auth_number;
//     element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
//     element['No. Of Days OverDue']=element.no_of_days_overdue;
//     element['Bond Amount']=element.bond_amount_in_inr;
//     element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");

// // console.log(this.selectedTableDataforDownload[index].join(','));
//   });
//   for (let i = 0; i < tableObj.length; i++) {
//     for (let property in tableObj[i]) {
//       console.log(property);
//       if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
//         tableObj[i][property] = '';
//       }
//     }
//   }
//   console.log(tableObj);
//   const sortedElementsList = tableObj.sort((a, b) => a.application_id);
//   this.downloadFile(
//     sortedElementsList,
//     'EODC APPLIED AND REJECTED BY DGFT' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
//     displayedCol
//   );
let tableFilterData = JSON.parse(localStorage.getItem('filter'));
console.log(tableFilterData);
if(tableFilterData){
   
this.downloadAuthorizationEodcFilledRejectedByDgftByFilter(tableFilterData);
}

else{
  
let offset = localStorage.getItem("offset");
this.downloadAuthorizationEodcFilledRejectedByDgft(this.currentUser.obj.user_id,1,1147483647)
// console.log(this.downloadOneTwoResp);
}
}
reportGeneration3() { 
//   var displayedCol = []
//   displayedCol.push('IEC No');
//   displayedCol.push('IEC Holder Name');
//   displayedCol.push('AA No');
//   displayedCol.push('Export Validity Date');
//   displayedCol.push('No. Of Days OverDue');
//   displayedCol.push('Bond Amount');
//   displayedCol.push('Date Of Issue ShowCause Notice');
//   var tableData:any=this.noCancellationPaginatedResp;
//   if(tableData.sd){
//     var tableObj =  tableData.sd;
//     }
//     else{
//       var tableObj =  tableData;
//     }
//   console.log(tableObj,'tableobj')
// // tableObj.forEach(elementNatureOfPayment => {
// //   console.log(elementNatureOfPayment);
// //   this.selectedTableDataforDownload.push(elementNatureOfPayment.nature_of_payment);
// // });
// // console.log(this.selectedTableDataforDownload);

//   tableObj.forEach((element,index) => {
//     let DateOfLastLetter=element.date_of_cancellation;
//     let scnNo=element.scn_no;
//     if(element.iec_number===null){
//       element.iec_number="";
//     }
//     if(element.scn_date===null){
//     element.scn_date='';

//     }

//     console.log(element);
//     element['IEC No'] = element.iec_number;
//     element['IEC Holder Name'] = element.iec_holder_name;
//     element['AA No'] = element.auth_number;
//     element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
//     element['No. Of Days OverDue']=element.no_of_days_overdue;
//     element['Bond Amount']=element.bond_amount_in_inr;
//     element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");

// // console.log(this.selectedTableDataforDownload[index].join(','));
//   });
//   for (let i = 0; i < tableObj.length; i++) {
//     for (let property in tableObj[i]) {
//       console.log(property);
//       if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
//         tableObj[i][property] = '';
//       }
//     }
//   }
//   console.log(tableObj);
//   const sortedElementsList = tableObj.sort((a, b) => a.application_id);
//   this.downloadFile(
//     sortedElementsList,
//     'EODC APPLIED & GRANTED BY DGFT BUT BOND CANCELLATION NOT FILED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
//     displayedCol
//   );
let tableFilterData = JSON.parse(localStorage.getItem('filter'));
console.log(tableFilterData);
if(tableFilterData){
   
this.downloadAuthorizationEodcFilledApprovedByDgftNoCancellationByFilter(tableFilterData);
}

else{
  
let offset = localStorage.getItem("offset");
this.downloadAuthorizationEodcFilledApprovedByDgftNoCancellation(this.currentUser.obj.user_id,1,1147483647)
// console.log(this.downloadOneTwoResp);
}
}
reportGeneration4() { 
//   var displayedCol = []
//   displayedCol.push('IEC No');
//   displayedCol.push('IEC Holder Name');
//   displayedCol.push('AA No');
//   displayedCol.push('Export Validity Date');
//   displayedCol.push('No. Of Days OverDue');
//   displayedCol.push('Bond Amount');
//   displayedCol.push('Date Of Issue ShowCause Notice');
//   var tableData:any=this.cancellationPaginatedResp;
//   if(tableData.sd){
//     var tableObj =  tableData.sd;
//     }
//     else{
//       var tableObj =  tableData;
//     }
//   console.log(tableObj,'tableobj')
// // tableObj.forEach(elementNatureOfPayment => {
// //   console.log(elementNatureOfPayment);
// //   this.selectedTableDataforDownload.push(elementNatureOfPayment.nature_of_payment);
// // });
// // console.log(this.selectedTableDataforDownload);

//   tableObj.forEach((element,index) => {
//     let DateOfLastLetter=element.date_of_cancellation;
//     let scnNo=element.scn_no;
//     if(element.iec_number===null){
//       element.iec_number="";
//     }
//     if(element.scn_date===null){
//     element.scn_date='';

//     }

//     console.log(element);
//     element['IEC No'] = element.iec_number;
//     element['IEC Holder Name'] = element.iec_holder_name;
//     element['AA No'] = element.auth_number;
//     element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
//     element['No. Of Days OverDue']=element.no_of_days_overdue;
//     element['Bond Amount']=element.bond_amount_in_inr;
//     element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");

// // console.log(this.selectedTableDataforDownload[index].join(','));
//   });
//   for (let i = 0; i < tableObj.length; i++) {
//     for (let property in tableObj[i]) {
//       console.log(property);
//       if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
//         tableObj[i][property] = '';
//       }
//     }
//   }
//   console.log(tableObj);
//   const sortedElementsList = tableObj.sort((a, b) => a.application_id);
//   this.downloadFile(
//     sortedElementsList,
//     'EODC GRANTED BY DGFT AND BOND CANCELLATION FILED' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
//     displayedCol
//   );
let tableFilterData = JSON.parse(localStorage.getItem('filter'));
console.log(tableFilterData);
if(tableFilterData){
   
this.downloadAuthorizationEodcFilledApprovedByDgftCancellationFilter(tableFilterData);
}

else{
  
let offset = localStorage.getItem("offset");
this.downloadAuthorizationEodcFilledApprovedByDgftCancellation(this.currentUser.obj.user_id,1,1147483647)
// console.log(this.downloadOneTwoResp);
}
}
downloadFile(data, filename = "data", headers) {
  let csvData = this.ConvertToCSV(data, headers);
  console.log(csvData);
  let blob = new Blob(["\ufeff" + csvData], {
    type: "text/csv;charset=utf-8;",
  });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1;
  if (isSafariBrowser) {
    //if Safari open in new window to save file with random filename.
    dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray, headerList) {
  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  let row = "Sr.No,";

  for (let index in headerList) {
    row += headerList[index] + ",";
  }
  row = row.slice(0, -1);
  str += row + "\r\n";
  for (let i = 0; i < array.length; i++) {
    let line = i + 1 + "";
    for (let index in headerList) {
      let head = headerList[index];

      line += "," + array[i][head];
    }
    str += line + "\r\n";
  }
  return str;
}


  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
