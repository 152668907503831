import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "app/_services";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-editprofile",
  templateUrl: "./editprofile.component.html",
  styleUrls: ["./editprofile.component.css"],
})
export class EditprofileComponent implements OnInit {
  selectedStatus = "";

  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;

  companyDetailsForm = this.formBuilder.group({
    company_name: ["", Validators.required],
    username: ["", Validators.required],
    password: ["", Validators.required],
    company_city: ["", Validators.required],
    company_state: ["", Validators.required],
    company_address: ["", Validators.required],
    company_phone: [
      "",
      [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
    ],
    company_email: [
      "",
      [
        Validators.required
      ],
    ],
    iec_code: ["", [Validators.required]],
    gstin_no: ["", [Validators.required,
   ]],
  });

  applicantDetailsForm = this.formBuilder.group({
    applicant_name: ["", Validators.required],
    applicant_designation: ["", Validators.required],
    applicant_phone: [
      "",
      [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
    ],
    applicant_email: [
      "",
      [
        Validators.required
      ],
    ],
  });

  chaDetailsForm = this.formBuilder.group({
    cbName: ["", Validators.required],
    cbRegistrationNo: ["", Validators.required],
    cbCity: [""],
    cbState: [""],
    cbAddress1: [""],
    cbAddress2: [""],
    cbMobile: [
      "",
      [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
    ],
    cbEmail: [
      "",
      [
        Validators.required
      ],
    ],
    username: [""],

  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  isApplicantDetails: boolean = false;
  getIecByIdDetails: any;
  getCbchaByIdDetails: any;
  selectedCityName: any;
  allCities: any;
  selectedStateName: any;
  allStates: any;
  updateIECDetailsObj: any;
  password: any;
  cbCity:any;
  cbState:any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>,
    private alertService: AlertService
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.getStates();
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if(this.currentUser.obj.role == '3'){
      this.getIecById();
    } else{
      this.getCbchaById();
    }
  }

  getStates() {
    this.blockchainService.getStates().subscribe((resp) => {
      this.allStates = resp.obj;
      // this.getCitesByState()
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  selectedState(event, state) {
    // if (event.isUserInput) {
      console.log(event);
      this.selectedStateName = state;
      this.getCitesByState(this.selectedStateName);
      this.selectedCityName = "";
    // }
  }

  selectedCity(event, city) {
    if (event.isUserInput) {
      this.selectedCityName = city;
    }
  }

  getCitesByState(state) {
    let input = {
      company_state: state,
    };
    this.blockchainService.getCitesByState(input).subscribe((resp) => {
      this.allCities = resp.body.obj;
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  navigateToCreate() {
    this.router.navigate(["/createProfile"]);
  }

  get f() {
    return this.companyDetailsForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.companyDetailsForm.controls[controlName].hasError(errorName);
  };

  get f1() {
    return this.applicantDetailsForm.controls;
  }
  public hasError1 = (controlName: string, errorName: string) => {
    return this.applicantDetailsForm.controls[controlName].hasError(errorName);
  };
  get f3() {
    return this.chaDetailsForm.controls;
  }
  public hasError3 = (controlName: string, errorName: string) => {
    return this.chaDetailsForm.controls[controlName].hasError(errorName);
  };
  submit1() {
    this.isApplicantDetails = true;
    // this.saveIECDetails()
  }

  back1() {
    this.router.navigate(["/welcomeUser"]);
  }

  back2() {
    this.isApplicantDetails = false;
  }

  submit2() {
    this.updateIECDetails();
    this.router.navigate(["/typeRole"]);
  }

  getIecById() {

    let input = {
      iec_holder_id: this.currentUser.obj.user_id,
    };
    this.blockchainService.getIecById(input).subscribe((resp) => {
      this.getIecByIdDetails = resp.obj;
      this.selectedCityName = this.getIecByIdDetails.company_city;
      this.selectedStateName = this.getIecByIdDetails.company_state;
      this.companyDetailsForm.patchValue({
        company_name: this.getIecByIdDetails.company_name,
        username: this.getIecByIdDetails.username,
        password: this.getIecByIdDetails.password,
        company_city: this.getIecByIdDetails.company_city,
        company_state: this.getIecByIdDetails.company_state,
        company_address: this.getIecByIdDetails.company_address,
        company_phone: this.getIecByIdDetails.company_phone,
        company_email: this.getIecByIdDetails.company_email,
        iec_code: this.getIecByIdDetails.iec_code,
        gstin_no: this.getIecByIdDetails.gstin_no,
      })
      // this.companyDetailsForm.setValue({
      //   company_name: this.getIecByIdDetails.company_name,
      //   username: this.getIecByIdDetails.username,
      //   password: this.getIecByIdDetails.password,
      //   company_city: this.getIecByIdDetails.company_city,
      //   company_state: this.getIecByIdDetails.company_state,
      //   company_address: this.getIecByIdDetails.company_address,
      //   company_phone: this.getIecByIdDetails.company_phone,
      //   company_email: this.getIecByIdDetails.company_email,
      //   iec_code: this.getIecByIdDetails.iec_code,
      //   gstin_no: this.getIecByIdDetails.gstin_no,
      // });
      this.applicantDetailsForm.setValue({
        applicant_name: this.getIecByIdDetails.applicant_name,
        applicant_designation: this.getIecByIdDetails.applicant_designation,
        applicant_phone: this.getIecByIdDetails.applicant_phone,
        applicant_email: this.getIecByIdDetails.applicant_email,
      });
      if (resp.status == 200) {
       
       
      } else {
        
        this.showNotification(
          "bottom",
          "right",
          "warning",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  updateIECDetails() {
    this.blockUI.start("Please wait a moment");

    let input = {
      iec_holder_id:this.currentUser.obj.user_id,
      company_name: this.companyDetailsForm.value.company_name,
      username: this.companyDetailsForm.value.username
        ? this.companyDetailsForm.value.username
        : "",
      password: this.companyDetailsForm.value.password
        ? this.companyDetailsForm.value.password
        : "",
      company_city: this.companyDetailsForm.value.company_city
        ? this.companyDetailsForm.value.company_city
        : "",
      company_state: this.companyDetailsForm.value.company_state
        ? this.companyDetailsForm.value.company_state
        : "",
      company_address: this.companyDetailsForm.value.company_address
        ? this.companyDetailsForm.value.company_address 
        : "",
      company_email: this.companyDetailsForm.value.company_email
        ? this.companyDetailsForm.value.company_email
        : "",
      company_phone: this.companyDetailsForm.value.company_phone
        ? this.companyDetailsForm.value.company_phone
        : "",
      iec_code: this.companyDetailsForm.value.iec_code
        ? this.companyDetailsForm.value.iec_code
        : "",
      gstin_no: this.companyDetailsForm.value.gstin_no
        ? this.companyDetailsForm.value.gstin_no
        : "",

      applicant_name: this.applicantDetailsForm.value.applicant_name
        ? this.applicantDetailsForm.value.applicant_name
        : "",
      applicant_designation: this.applicantDetailsForm.value
        .applicant_designation
        ? this.applicantDetailsForm.value.applicant_designation
        : "",
      applicant_phone: this.applicantDetailsForm.value.applicant_phone
        ? this.applicantDetailsForm.value.applicant_phone
        : "",
      applicant_email: this.applicantDetailsForm.value.applicant_email
        ? this.applicantDetailsForm.value.applicant_email
        : "",
      aa_epcg_no: "",
      aa_epcg_date: "",
      cb_cha_id: "",
      cb_cha_mobile: "",
      cb_cha_name: "",
      cb_cha_email: "",
      zone_id: "1",
    };

    this.blockchainService.updateIECDetails(input).subscribe((resp) => {
      this.updateIECDetailsObj = resp.body.obj;
      if (resp.body.status == 200) {
        this.blockUI.stop();
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        this.router.navigate(["/login"]);
      } else {
        this.blockUI.stop();
        this.showNotification(
          "bottom",
          "right",
          "warning",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    });
  }

  getCbchaById() {
    this.blockUI.start("Please wait a moment");

    let input = {
      cb_cha_id: this.currentUser.obj.user_id,
    };
    this.blockchainService.getCbchaById(input).subscribe((resp) => {
      this.getCbchaByIdDetails = resp.body.obj;
      var array = this.getCbchaByIdDetails.cb_cha_address.split('@');
      this.selectedCityName = this.getCbchaByIdDetails.cb_cha_city;
      this.selectedStateName = this.getCbchaByIdDetails.cb_cha_state;
      this.chaDetailsForm.patchValue({
        cbName: this.getCbchaByIdDetails.cb_cha_name,
        cbRegistrationNo:this.getCbchaByIdDetails.cb_cha_reg_no,
        username: this.getCbchaByIdDetails.cb_cha_username,
        cbCity: this.getCbchaByIdDetails.cb_cha_city,
        cbState: this.getCbchaByIdDetails.cb_cha_state,
        cbAddress1: array[0],
        cbAddress2: array[1],
        cbMobile: this.getCbchaByIdDetails.cb_cha_mobile,
        cbEmail: this.getCbchaByIdDetails.cb_cha_email,
       
      })
      if (resp.status == 200) {
        this.blockUI.stop();
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      } else {
        this.blockUI.stop();
        this.showNotification(
          "bottom",
          "right",
          "warning",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    )
  }

  updateCbcha(){
    this.blockUI.start("Please wait a moment");

    let input = {
      cb_cha_company_name: "",
      cb_cha_username: this.chaDetailsForm.value.username ? this.chaDetailsForm.value.username : '',
      cb_cha_password: this.getCbchaByIdDetails.cb_cha_password,
      cb_cha_address: this.chaDetailsForm.value.cbAddress1? this.chaDetailsForm.value.cbAddress1+"@"+this.chaDetailsForm.value.cbAddress2  : '',
      cb_cha_city: this.chaDetailsForm.value.cbCity ? this.chaDetailsForm.value.cbCity : '',
      cb_cha_state: this.chaDetailsForm.value.cbState ? this.chaDetailsForm.value.cbState: '',
      cb_cha_reg_no: this.chaDetailsForm.value.cbRegistrationNo ? this.chaDetailsForm.value.cbRegistrationNo : '',
      cb_cha_name: this.chaDetailsForm.value.cbName ? this.chaDetailsForm.value.cbName : '',
      cb_cha_mobile: this.chaDetailsForm.value.cbMobile ? this.chaDetailsForm.value.cbMobile :'',
      cb_cha_email: this.chaDetailsForm.value.cbEmail ? this.chaDetailsForm.value.cbEmail:'', 
      cb_cha_id:this.getCbchaByIdDetails.cb_cha_id,
      zone_id: "1",
    };
    console.log(this.getCbchaByIdDetails.password)
    this.blockchainService.updateCbcha(input).subscribe((resp) => {
      this.updateIECDetailsObj = resp.body.obj;
      if (resp.body.status == 200) {
        this.blockUI.stop();
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        // this.router.navigate(["/login"]);
        // location.reload();
      } else {
        this.blockUI.stop();
        this.showNotification(
          "bottom",
          "right",
          "warning",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
