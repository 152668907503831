import { Component, OnInit } from '@angular/core';
import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockTemplateComponent } from 'app/widgets/block-template/block-template.component';
// import { FlexAlignDirective } from '@angular/flex-layout';
import { AuthenticationService } from 'app/_services/authentication.service';
import { DateAdapter } from '@angular/material/core';
declare var $: any;

export interface IdentificationType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.css']
})
export class AddSectionComponent implements OnInit {
  allTypes: IdentificationType[] = [
    { value: 'IEC No', viewValue: 'IEC No' },
    { value: 'Custom Broker No.', viewValue: 'Custom Broker No.' },
    { value: 'PAN No', viewValue: 'PAN No' },
    { value: 'Aadhar No', viewValue: 'Aadhar No' },
    { value: 'Passport No', viewValue: 'Passport No' },
    { value: 'Other', viewValue: 'Other' },

  ];

  pipe = new DatePipe("en-US");
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  currentUser: any;
  iec_flag = false;

  selectedStatus: any;
  notiColor: any = "#06ffff";
  sectionForm = this.formBuilder.group({
    zone_name: [""],
    zone_id: [""],
    commissionerate: [""],
    section: [""],
    mobile: ["", [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    emails: ["", [Validators.required]],
  });
  zoneList: any;
  commissionrateList: any;
  zoneId: any;
  adminAction: any;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private authenticationService: AuthenticationService,   
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }
  ngOnInit(): void {
    // this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    var cuser = JSON.parse(localStorage.getItem("currentUser"));

    this.role = cuser.obj.role;
    // console.log(this.role);
    if (this.role == 0) {
      this.iec_flag = false;
    } else {
      this.iec_flag = true;
    }

    this.getZoneList();
    this.getCommissionrate();
    var adminUser = JSON.parse(localStorage.getItem("adminUser"));
    var adminAction = JSON.parse(localStorage.getItem("adminAction"));
    this.adminAction = adminAction;
    //  console.log(adminAction);

    if (adminAction == "Edit") {
      var adminUser = JSON.parse(localStorage.getItem("adminUser"));
      this.getSectionManagementById(adminUser.section_management_communication_id);
    } else {
      adminAction = "Add";
    }
  }
  getZoneList() {
    this.blockchainService.getAllZones().subscribe(
      (resp) => {
        this.zoneList = resp.obj;
      });
  }
  getCommissionrate() {
    this.blockchainService.getCommissionrate().subscribe(
      (resp) => {
        this.commissionrateList = resp.obj;
      });
  }
  get f() {
    return this.sectionForm.controls;
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.sectionForm.controls[controlName].hasError(errorName);
  };

  getSectionManagementById(section_management_communication_id) {
    this.blockUI.start("Please wait this may take few seconds ...");
    // let custumsAdmin = this.blockchainService.adminUser;
    let input = {
      section_management_communication_id: section_management_communication_id,
    };
    this.blockchainService.getSectionManagementById(input).subscribe((resp) => {
      //  console.log(resp.body.obj)
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      var editCustomSection = resp.body.obj;
      // var encodedString = btoa(editCustomUser.password);
      // console.log(encodedString);
      this.sectionForm = this.formBuilder.group({
        section_management_communication_id: [editCustomSection.section_management_communication_id],
        zone_name: [editCustomSection.zone_name],
        zone_id: [editCustomSection.zone_id],
        commissionerate: [editCustomSection.commissionerate],
        mobile: [editCustomSection.mobile],
        emails: [editCustomSection.emails, [Validators.required]],
        section: [editCustomSection.section],
      });
    });

  }
  saveSection() {
    // console.log(this.sectionForm.value);
    // this.blockchainService.adminAction = "Add";
    this.blockUI.start("Please wait this may take few seconds ...");
    if (this.sectionForm.value.zone_name == '') {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Zone Name Cannot be Empty",
        "announcement"
      );

    }
    else if (this.sectionForm.value.commissionerate == '') {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Commissionerate Cannot be Empty",
        "announcement"
      );
    }
    else if (this.sectionForm.value.section == '') {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Section Cannot be Empty",
        "announcement"
      );
    }

   

    else if (this.sectionForm.value.mobile == '') {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Mobile No Cannot be Empty",
        "announcement"
      );
    }
    else if (this.sectionForm.value.emails == '') {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Email Id Cannot be Empty",
        "announcement"
      );
    }

    else {
      if (this.sectionForm.valid) {
        this.blockUI.start("Please wait this may take few seconds ...");
        this.zoneList.forEach(e1 => {
          let zoneName = this.sectionForm.controls['zone_name'].value;
          if (e1.zone_name == zoneName) {
            this.zoneId = e1.zone_id;
            this.sectionForm.controls['zone_id'].setValue(this.zoneId);
          }
        });
        var adminAction = JSON.parse(localStorage.getItem("adminAction"));
        if (adminAction == "Add") {
          this.blockchainService.saveSectionManagement(this.sectionForm.value).subscribe(
            (data) => {
              if (data.status == 200) {
                setTimeout(() => {
                  this.blockUI.stop();
                }, 1500);
                // console.log(data.body);
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  data.body.message,
                  "announcement"
                );
                setTimeout(() => {
                  this.router.navigate(["/manageSections"]);

                }, 3000)
              }

            },
            (error) => {
              // console.log("fail", error);
              this.showNotification(
                "bottom",
                "right",
                "warning",
                error.message,
                "announcement"
              );
            }
          );
        }
        else if (adminAction == "Edit") {
          this.blockchainService.updateSectionManagement(this.sectionForm.value).subscribe(
            (data) => {
              if (data.status == 200) {
                // console.log(data.body);
                setTimeout(() => {
                  this.blockUI.stop();
                }, 1500);
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  data.body.message,
                  "announcement"
                );
                setTimeout(() => {
                  this.router.navigate(["/manageSections"]);

                }, 3000)

              }

            },
            (error) => {
              // console.log("fail", error);
              this.showNotification(
                "bottom",
                "right",
                "warning",
                error.message,
                "announcement"
              );
            }
          );
        }
        else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Enter Valid Details",
            "announcement"
          );
        }

      }
    }
  }

  back(){
    this.router.navigate(["/manageSections"]);
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", this.notiColor, "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 5000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4' +
          ' alert alert-{0} alert-with-icon" role="alert"><button mat-button  type="butto' +
          'n" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i clas' +
          's="material-icons">close</i></button><i class="material-icons" data-notify="ic' +
          'on">notifications</i> <span data-notify="title">{1}</span> <span data-notify="' +
          'message">{2}</span><div class="progress" data-notify="progressbar"><div class=' +
          '"progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valu' +
          'emin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" targ' +
          'et="{4}" data-notify="url"></a></div>',
      }
    );
  }

}
