import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "app/_services";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
declare var $: any;
export interface Status {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-amendment',
  templateUrl: './amendment.component.html',
  styleUrls: ['./amendment.component.css']
})
export class AmendmentComponent implements OnInit {

  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  now = Date.now();
  // todayDate = this.pipe.transform(this.now, "yyyy-MM-dd", "en-US");

  selectedStatus = "";
   color = 'primary';
   backgroundColor = '#64c153'
  
 
   @BlockUI() blockUI: NgBlockUI;
   blockTemplate: BlockTemplateComponent;
   role: any;
   selectedDate: any;
   ivDate: any;
   evDate:any;
   aiDate:any;
   aeDate:any;
  
   amendmentForm = this.formBuilder.group({
    iec_no: [""],
    license_no:[""],
    import_validity_date:[""],
    export_validity_date:[""],
    amended_import_date:[""],
    amended_export_date:[""],

   });

   amendmentAAForm = this.formBuilder.group({
    names: [""],
    sections: [""],
    designations: [""]
  
  });

  caseForm = this.formBuilder.group({
    iecName: [""],
    iecCode:[""],
    aaIsueDate:[""],
    aaNumber:[""],
    fileNo:[""],
    jobNo:[""],
    newBondNo:[""],
    bbgLocation:[""]
   
   });

  amendmentCaseForm = this.formBuilder.group({

    importDate: [""],
    exportDate:[""],

  });
 
   currentUser: any;
   caseDetailsData: any;
   isButtonDisabled: boolean;
   selectedFile: File;
   url: string | ArrayBuffer;
   allApplicationTypes: any;
   applicationType: any;
   selectedFile1: File;
   date: any;
   modifiedCurrentDate: any;
   showForm = false;
   allStatus: Status[] = [
    { value: "LICENSE12_09_4567_678", viewValue: "LICENSE12_09_4567_678" },
    { value: "LICENSE12_09_4567_6777", viewValue: "LICENSE12_09_4567_6777" },
  ];
  getCustomsAmendment: any;
  amendment_request_id: any;
  upload_path: any;
  getCustomsAmendmentExp: any;
  getCustomsAmendmentImp: any;
  disBtnImp: boolean;
  disBtnExp: boolean;
  amendment_type: any;
  offsetData: any;

 
   constructor(
     private formBuilder: UntypedFormBuilder,
     private route: ActivatedRoute,
     private router: Router,
     private overlay: Overlay,
     public dialog: MatDialog,
     public blockchainService: BlockchainService,
     private alertService: AlertService,
     private dateAdapter: DateAdapter<Date>
     ) {
       this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
       this.offsetData = JSON.parse(localStorage.getItem("offset"));
     }
 
   ngOnInit(): void {
     this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
     this.amendment_request_id = JSON.parse(localStorage.getItem("amendment_request_id"));
     this.amendment_type = JSON.parse(localStorage.getItem("amendment_type"));
    //  this.getApplicationType();
    this.getCustomsAmendmentById(this.amendment_request_id);
    this.disBtnImp = false;
    this.disBtnExp = false;
   }

   getCustomsAmendmentById(amendment_request_id){
    let input = {
      customs_intimation_amendment_id:amendment_request_id
    }
    this.blockchainService.getCustomsAmendmentById(input).subscribe(
      (resp) => {
        this.getCustomsAmendment = resp.body.obj;
        console.log(this.getCustomsAmendment);
        if(this.getCustomsAmendment.upload_letter_path != ''){
        this.upload_path = this.getCustomsAmendment.upload_letter_path
        }else{
          this.upload_path = null;
        }
        this.amendmentForm.setValue({
          iec_no: this.getCustomsAmendment.iec_no,
          license_no: this.getCustomsAmendment.license_no,
          import_validity_date: this.pipe.transform(this.getCustomsAmendment.import_validity_date, "yyyy-MM-dd"),
          export_validity_date: this.pipe.transform(this.getCustomsAmendment.export_validity_date, "yyyy-MM-dd"),
          amended_import_date:this.pipe.transform(this.getCustomsAmendment.amended_import_date, "yyyy-MM-dd"),
          amended_export_date:this.pipe.transform(this.getCustomsAmendment.amended_export_date, "yyyy-MM-dd"),
        })
        
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }
  validateImport(){
    
    let input = {
      customs_intimation_amendment_id:this.getCustomsAmendment.customs_intimation_amendment_id
    }
    this.blockchainService.updateAmendmentImpById(input).subscribe(
      (resp) => {
        this.getCustomsAmendmentImp = resp.body.obj;
        console.log(this.getCustomsAmendmentImp);
        if(resp.status == 200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.disBtnImp = true;
          // setTimeout(() => {
          //  location.reload();
          // }, 3000)
        }else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
         
        }
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }
  validateExport(){
    
    let input = {
      customs_intimation_amendment_id:this.getCustomsAmendment.customs_intimation_amendment_id
    }
    console.log(input)
    this.blockchainService.updateAmendmentExpById(input).subscribe(
      (resp) => {
        this.getCustomsAmendmentExp = resp.body.obj;
        console.log(this.getCustomsAmendmentExp);
        if(resp.status == 200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
         this.disBtnExp = true;
          // setTimeout(() => {
          //   location.reload();
          //  }, 3000)
        }else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          // setTimeout(() => {
          //   location.reload();
          //  }, 3000)
        }
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }
  approve(){
    let input = {
      customs_intimation_amendment_id:this.getCustomsAmendment.customs_intimation_amendment_id
    }
    this.blockchainService.updateApproveAmendmentById(input).subscribe(
      (resp) => {
        this.getCustomsAmendment = resp.body.obj;
        console.log(this.getCustomsAmendment);
        if(resp.status == 200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.router.navigate(["/amendmentList"]);
          setTimeout(() => {
            location.reload();
           }, 3000)
        }else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout(() => {
            location.reload();
           }, 3000)
        }
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }

  //    getApplicationType(){
  //    this.blockchainService.getApplicationType().subscribe((resp) => {
  //      this.allApplicationTypes = resp.obj;
  //    });
  //  }

 
  onFileSelected(event,action) {
    if(action == 'cust'){
      this.selectedFile = <File>event.target.files[0];
      if(this.selectedFile.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload();
      }
    
    } else{
      if(this.selectedFile1.type == 'application/pdf'){
      this.selectedFile1 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload();
    }
  }
  }

  //for iec  
   onUpload() {
    const fd = new FormData();
    fd.append("file", this.selectedFile, this.selectedFile.name);
  }

   update(){
    let input = {
      created_by: this.currentUser.obj.user_id,
      iec_no:this.amendmentForm.value.iec_no,
      license_no: this.amendmentForm.value.license_no,
      import_validity_date:this.pipe.transform(this.amendmentForm.value.import_validity_date, "yyyy-MM-dd HH:mm:ss") ,
      export_validity_date: this.pipe.transform(this.amendmentForm.value.export_validity_date, "yyyy-MM-dd HH:mm:ss") ,
      amended_import_date: this.pipe.transform(this.amendmentForm.value.amended_import_date, "yyyy-MM-dd HH:mm:ss"),
      amended_export_date: this.pipe.transform(this.amendmentForm.value.amended_export_date, "yyyy-MM-dd HH:mm:ss"),
      upload_letter:this.selectedFile,
      customs_intimation_amendment_id:this.getCustomsAmendment.customs_intimation_amendment_id
    };
    // console.log("input",input);
 
    this.blockchainService.updateCustomsAmendment(input).subscribe(
      (resp) => {
        console.log(resp.body.obj);
        if(resp.status == 200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Amendment Added Successfully",
            "announcement"
          );
        }
        //  this.addApprovalForm.reset();
        //  location.reload();
         setTimeout(() => {
          location.reload()
         }, 1500);
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          // location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          // location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
     
   }

   
  back(){
    // this.router.navigate(["/amendmentList"])
    this.router.navigate(['/amendmentList'], { queryParams: { page: this.offsetData } });
  }

  close(){
    // this.router.navigate(["/typeRole"])
  }

 
   statusFn(e, status) {
     if (e == "NO") {
       this.isButtonDisabled = true;
     } else {
       this.isButtonDisabled = false;
     }
   }
 

   dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

   showNotification(from, align, color, stringMessage, icons) {
     const type = ["", "info", "success", "warning", "danger"];
 
     $.notify(
       {
         icon: icons,
         message: stringMessage,
       },
       {
         type: type[color],
         timer: 4000,
         placement: {
           from: from,
           align: align,
         },
         template:
           '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
           '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
           '<i class="material-icons" data-notify="icon">notifications</i> ' +
           '<span data-notify="title">{1}</span> ' +
           '<span data-notify="message">{2}</span>' +
           '<div class="progress" data-notify="progressbar">' +
           '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
           "</div>" +
           '<a href="{3}" target="{4}" data-notify="url"></a>' +
           "</div>",
       }
     );
   }
 
 }
 