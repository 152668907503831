import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { element } from "protractor";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { PaymentsumComponent } from "../paymentsum/paymentsum.component";

declare var $: any;
export interface Status {
  value: string;
  viewValue: string;
}
export interface CommissionerElement {
  no: number;
  // duties: string;
  commissionerate: string;
  section: string;
  natureOfPayment: string;
  amount: string;
  appealNo: string;
  appealDate: string;
  orderNo: string;
  action: any;
  remarks: any;
  payment_id: string;
}

const Audit_DATA: CommissionerElement[] = [
  {
    no: 1,
    commissionerate: "",
    section: "",
    natureOfPayment: "",
    amount: "",
    appealNo: "",
    appealDate: "",
    orderNo: "",
    action: "",
    remarks: "",
    payment_id: "",
  },
];

export interface CestatElement {
  no: number;
  commissionerate: string;
  section: string;
  natureOfPayment: string;
  amount: string;
  appealNo: string;
  appealDate: string;
  orderNo: string;
  action: any;
  remarks: any;
  payment_id: string;
}

const Invest_DATA: CestatElement[] = [
  {
    no: 1,
    commissionerate: "",
    section: "",
    natureOfPayment: "",
    amount: "",
    appealNo: "",
    appealDate: "",
    orderNo: "",
    action: "",
    remarks: "",
    payment_id: "",
  },
];

export interface EpcgElement {
  no: number;
  commissionerate: string;
  section: string;
  natureOfPayment: string;
  amount: string;
  appealNo: string;
  appealDate: string;
  orderNo: string;
  action: any;
  remarks: any;
  payment_id: string;
}

export interface AaElement {
  no: number;
  commissionerate: string;
  section: string;
  natureOfPayment: string;
  amount: string;
  appealNo: string;
  appealDate: string;
  orderNo: string;
  action: any;
  remarks: any;
  payment_id: string;
}

export interface ShippingElement {
  no: number;
  commissionerate: string;
  section: string;
  natureOfPayment: string;
  amount: string;
  appealNo: string;
  appealDate: string;
  orderNo: string;
  action: any;
  remarks: any;
  payment_id: string;
}

const epcg_DATA: EpcgElement[] = [
  {
    no: 1,
    commissionerate: "",
    section: "",
    natureOfPayment: "",
    amount: "",
    appealNo: "",
    appealDate: "",
    orderNo: "",
    action: "",
    remarks: "",
    payment_id: "",
  },
  // {
  //   no: 2,
  //   otherHead: "OVERTIME",
  //   details: "",
  //   amount: "",
  // },
  // {
  //   no: 3,
  //   otherHead: "CUSTOMS REVENUE STAMP",
  //   details: "",
  //   amount: "",
  // },
];

export interface heads_request {
  payment_subcategory_index: string;
  payment_category: string;
  payment_subcategory: string;
  commissionerate: string;
  section: string;
  nature_of_payment: string;
  nature_of_document_name: string;
  nature_of_document_no: string;
  document_details_name: string;
  document_details_date: string;
  order_number: string;
  amount_in_inr: string;
  remarks: string;
  payment_id: string;
}

@Component({
  selector: 'app-appellate',
  templateUrl: './appellate.component.html',
  styleUrls: ['./appellate.component.css']
})
export class AppellateComponent implements OnInit {
  displayedColumns = [
    "no",
    "commissionerate",
    "section",
    "natureOfPayment",
    "amount",
    "appealNo",
    "appealDate",
    "orderNo",
    "remarks",
    "action",
  ];
  displayedColumns0 = [
    "no",
    "commissionerate",
    "section",
    "natureOfPayment",
    "amount",
    "appealNo",
    "appealDate",
    "orderNo",
    "remarks",
    "action",
  ];
  displayedColumns1 = [
    "no",
    "commissionerate",
    "section",
    "natureOfPayment",
    "amount",
    "appealNo",
    "appealDate",
    "orderNo",
    "remarks",
    "action",
  ];
  displayedColumns2 = [
    "no",
    "commissionerate",
    "section",
    "natureOfPayment",
    "amount",
    "appealNo",
    "appealDate",
    "orderNo",
    "remarks",
    "action",
  ];
  displayedColumns3 = [
    "no",
    "commissionerate",
    "section",
    "natureOfPayment",
    "amount",
    "appealNo",
    "appealDate",
    "orderNo",
    "remarks",
    "action",
  ];

  dataSource5 = new BehaviorSubject<AbstractControl[]>([]);
  dataSource6 = new BehaviorSubject<AbstractControl[]>([]);
  dataSource1 = new BehaviorSubject<AbstractControl[]>([]);
  dataSource2 = new BehaviorSubject<AbstractControl[]>([]);
  dataSource3 = new BehaviorSubject<AbstractControl[]>([]);

  finalList: heads_request[] = [];
  // otherFormArr: otherFormInterface[] = [];

  data: CommissionerElement[] = [
    {
      no: 1,
      commissionerate: "",
      section: "",
      natureOfPayment: "",
      amount: "",
      appealNo: "",
      appealDate: "",
      orderNo: "",
      action: "",
      remarks: "",
      payment_id: "1",
    },
  ];

  data1: CestatElement[] = [
    {
      no: 1,
      commissionerate: "",
      section: "",
      natureOfPayment: "",
      amount: "",
      appealNo: "",
      appealDate: "",
      orderNo: "",
      action: "",
      remarks: "",
      payment_id: "1",
    },
  ];

  data2: EpcgElement[] = [
    {
      no: 1,
      commissionerate: "",
      section: "",
      natureOfPayment: "",
      amount: "",
      appealNo: "",
      appealDate: "",
      orderNo: "",
      action: "",
      remarks: "",
      payment_id: "1",
    },
  ];

  data3: AaElement[] = [
    {
      no: 1,
      commissionerate: "",
      section: "",
      natureOfPayment: "",
      amount: "",
      appealNo: "",
      appealDate: "",
      orderNo: "",
      action: "",
      remarks: "",
      payment_id: "1",
    },
  ];

  data4: ShippingElement[] = [
    {
      no: 1,
      commissionerate: "",
      section: "",
      natureOfPayment: "",
      amount: "",
      appealNo: "",
      appealDate: "",
      orderNo: "",
      action: "",
      remarks: "",
      payment_id: "1",
    },
  ];

  commissioner_master: UntypedFormArray = this.formBuilder.array([]);
  commissionerForm: UntypedFormGroup = this.formBuilder.group({
    commissioner_master: this.commissioner_master,
  });

  cestat_master: UntypedFormArray = this.formBuilder.array([]);
  cestatForm: UntypedFormGroup = this.formBuilder.group({
    cestat_master: this.cestat_master,
  });

  highcourt_master: UntypedFormArray = this.formBuilder.array([]);
  highCourtForm: UntypedFormGroup = this.formBuilder.group({
    highcourt_master: this.highcourt_master,
  });

  supremecourt_master: UntypedFormArray = this.formBuilder.array([]);
  supremeCourtForm: UntypedFormGroup = this.formBuilder.group({
    supremecourt_master: this.supremecourt_master,
  });

  other_master: UntypedFormArray = this.formBuilder.array([]);
  otherForm: UntypedFormGroup = this.formBuilder.group({
    other_master: this.other_master,
  });

  pipe = new DatePipe("en-US");

  selectedStatus = "";

  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  date: any;
  blockTemplate: BlockTemplateComponent;
  role: any;
  allDocType: any;
  active: boolean = false;
  allDuties: any;
  allMiscellaneous: any;
  allSectionByCommissionrate: any = [];
  allOvertime: any;
  allCommissionrate: any;
  selectedDuties: any;
  seletedCommissionerateId: any;
  seletedCommissionerate: any;
  selectedSection: any;
  selectedMiscellaneous: any;
  selectedOvertime: any;
  removeRowNo: any;
  removeRowNo1: any;
  selectedSectionMisc: any;
  payableAmt: any = 0;

  payment_id: string;

  today: number = Date.now();
  allSectionByCommissionrate1: any = [];
  removeRowNo2: any;
  removeRowNo4: any;
  removeRowNo3: any;
  isNextEnable: boolean;
  commissionerate: any;
  getAllNatureOfPament: any;
  commissionerate_type: any;
  selectedDate: any;
  getVoluntaryOfPayment: any;
  grandTotal: string;
  no: any;
  remarks4: any
  selectedForm: string;
  wordCount: any;
  @ViewChild("text") text: ElementRef;
  words: any=0;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    private dateAdapter: DateAdapter<Date>,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { }

  ngOnInit(): void {
    this.commissionerate_type = "";
    this.getCommissionrate();
    this.getAllNatureOfPayments();
    this.payment_id = localStorage.getItem("payment_id");
    // this.payment_id = "40";
    this.getPaymentById(this.payment_id);
    // this.emptyRowAdd()
    this.getSupportingDocumentsByPaymentIdV3(this.payment_id);
    this.grandCalculate();
  }
  emptyRowAdd(){
    if(this.commissioner_master.value.length == 0){
    this.data.forEach((d: CommissionerElement) => this.addRowCommission(d, false));
    this.updateViewCommissioner();
    }
    if(this.cestat_master.value.length == 0){
    this.data1.forEach((d: CestatElement) => this.addRowCestat(d, false));
    this.updateViewCestat();
    }

    if(this.highcourt_master.value.length == 0){
    this.data2.forEach((d: EpcgElement) => this.addRowEpcg(d, false));
    this.updateViewEpcg();
    }

    if(this.supremecourt_master.value.length == 0){
    this.data3.forEach((d: AaElement) => this.addRowAA(d, false));
    this.updateViewAA();
    }

    if(this.other_master.value.length == 0){
    this.data4.forEach((d: ShippingElement) => this.addRowShip(d, false));
    this.updateViewShip();
    }
  }
  getSupportingDocumentsByPaymentIdV3(paymentId) {
    let input = {
      payment_id: paymentId
    }
    this.blockchainService.getSupportingDocumentsByPaymentIdV3(input).subscribe((resp) => {
      this.getVoluntaryOfPayment = resp.body.obj;
      if (this.getVoluntaryOfPayment.length != 0) {
      //  this.commissioner_master = this.formBuilder.array([]);
        this.getVoluntaryOfPayment.forEach((e1) => {
          if (e1.payment_category == 'APPELLATE PROCEEDINGS') {
            if (e1.payment_subcategory == 'Commissioner Appeal') {
              const newRow = this.formBuilder.group({
                payment_subcategory_index: e1.payment_subcategory_index,
                payment_category: "APPELLATE PROCEEDINGS",
                payment_subcategory: "Commissioner Appeal",
                commissionerate: this.commissionerate,
                section: e1.section,
                natureOfPayment: e1.nature_of_payment,
                nature_of_document_name: "Appeal No",
                appealNo: e1.nature_of_document_no,
                document_details_name: "Appeal Date",
                appealDate: e1.document_details_date,
                orderNo: e1.order_number,
                amount: e1.amount_in_inr,
                payment_id: this.payment_id,
                remarks: e1.remarks,
              })
              if (e1.payment_subcategory == 'Commissioner Appeal') {
                
                this.commissioner_master.push(newRow);
                this.updateViewCommissioner();
           
              }
               }
          
            if (e1.payment_subcategory == 'CESTAT') {
              const newRow = this.formBuilder.group({
                payment_subcategory_index: e1.payment_subcategory_index,
                payment_category: "APPELLATE PROCEEDINGS",
                payment_subcategory: "CESTAT",
                commissionerate: this.commissionerate,
                section: e1.section,
                natureOfPayment: e1.nature_of_payment,
                nature_of_document_name: "Appeal No",
                appealNo: e1.nature_of_document_no,
                document_details_name: "Appeal Date",
                appealDate: e1.document_details_date,
                orderNo: e1.order_number,
                amount: e1.amount_in_inr,
                payment_id: this.payment_id,
                remarks: e1.remarks,
              })
              if (e1.payment_subcategory == 'CESTAT') {
                this.cestat_master.push(newRow);
                this.updateViewCestat();
                
              }
            }

            if (e1.payment_subcategory == 'High Court') {
              const newRow = this.formBuilder.group({
                payment_subcategory_index: e1.payment_subcategory_index,
                payment_category: "APPELLATE PROCEEDINGS",
                payment_subcategory: "High Court",
                commissionerate: this.commissionerate,
                section: e1.section,
                natureOfPayment: e1.nature_of_payment,
                nature_of_document_name: "Appeal No",
                appealNo: e1.nature_of_document_no,
                document_details_name: "Appeal Date",
                appealDate: e1.document_details_date,
                orderNo: e1.order_number,
                amount: e1.amount_in_inr,
                payment_id: this.payment_id,
                remarks: e1.remarks,
              })
              if (e1.payment_subcategory == 'High Court') {
                this.highcourt_master.push(newRow);
                this.updateViewEpcg();
               
              }
            }

            if (e1.payment_subcategory == 'Supreme Court') {
              const newRow = this.formBuilder.group({
                payment_subcategory_index: e1.payment_subcategory_index,
                payment_category: "APPELLATE PROCEEDINGS",
                payment_subcategory: "Supreme Court",
                commissionerate: this.commissionerate,
                section: e1.section,
                natureOfPayment: e1.nature_of_payment,
                nature_of_document_name: "Appeal No",
                appealNo: e1.nature_of_document_no,
                document_details_name: "Appeal Date",
                appealDate: e1.document_details_date,
                orderNo: e1.order_number,
                amount: e1.amount_in_inr,
                payment_id: this.payment_id,
                remarks: e1.remarks,
              })
              if (e1.payment_subcategory == 'Supreme Court') {
                this.supremecourt_master.push(newRow);
                this.updateViewAA();
               
              }
            }

            if (e1.payment_subcategory == 'Other') {
              const newRow = this.formBuilder.group({
                payment_subcategory_index: e1.payment_subcategory_index,
                payment_category: "APPELLATE PROCEEDINGS",
                payment_subcategory: "Other",
                commissionerate: this.commissionerate,
                section: e1.section,
                natureOfPayment: e1.nature_of_payment,
                nature_of_document_name: "Appeal No",
                appealNo: e1.nature_of_document_no,
                document_details_name: "Appeal Date",
                appealDate: e1.document_details_date,
                orderNo: e1.order_number,
                amount: e1.amount_in_inr,
                payment_id: this.payment_id,
                remarks: e1.remarks,
              })
              if (e1.payment_subcategory == 'Other') {
                this.other_master.push(newRow);
                this.updateViewShip();
              
              }
            }
          } 
        })
      } 
       this.emptyRowAdd();
    });
  }
  omit_special_char(event) {
    var k;
    k = event.charCode; 
    //         k = event.keyCode;  (Both can be used)
    return (event.keyCode >= 48 && event.keyCode <= 57 || (event.keyCode == 47 || event.keyCode == 92 || event.keyCode == 45 || event.keyCode == 95));
  }
  getAllNatureOfPayments() {
    let input = {}
    this.blockchainService.getAllNatureOfPayments(input).subscribe((resp) => {
      this.getAllNatureOfPament = resp.body.obj;
    });
  }
  grandCalculate(){
    let input = {
      payment_id:this.payment_id
    }
    this.blockchainService.getGrandPaymentTotalByPaymentId(input).subscribe((resp) => {
     
      if(resp.obj==''){
        this.grandTotal = '0'
      }else{
        this.grandTotal = resp.obj;
      }
    });
  }
  getPaymentById(payment_id) {
    let input = {
      payment_master_id: payment_id
    }
    this.blockchainService.getPaymentById(input).subscribe((resp) => {
      this.commissionerate_type = resp.body.obj.commissionerate_type;
      localStorage.setItem('commissionerate_type', this.commissionerate_type);

      // this.commissionerForm.value.commissioner_master[0].controls['commissionerate'].setValue(this.commissionerate_type);
    });
  }
  payableAmtCalculate() {
    let newList: heads_request[] = [];

    this.commissionerForm.value.commissioner_master.forEach((element, index) => {
      newList.push({
        payment_subcategory_index: index,
        payment_category: "APPELLATE PROCEEDINGS",
        payment_subcategory: "Commissioner Appeal",
        commissionerate: this.commissionerate,
        section: element.section,
        nature_of_payment: element.natureOfPayment,
        nature_of_document_name: "Appeal No",
        nature_of_document_no: element.appealNo,
        document_details_name: "Appeal Date",
        document_details_date: element.appealDate,
        order_number: element.orderNo,
        amount_in_inr: element.amount ? element.amount : "0",
        payment_id: this.payment_id,
        remarks: element.remarks,
      });
    });
    this.cestatForm.value.cestat_master.forEach((element, index) => {
      newList.push({
        payment_subcategory_index: index,
        payment_category: "APPELLATE PROCEEDINGS",
        payment_subcategory: "CESTAT",
        commissionerate: this.commissionerate,
        section: element.section,
        nature_of_payment: element.natureOfPayment,
        nature_of_document_name: "Appeal No",
        nature_of_document_no: element.appealNo,
        document_details_name: "Appeal Date",
        document_details_date: element.appealDate,
        order_number: element.orderNo,
        amount_in_inr: element.amount ? element.amount : "0",
        payment_id: this.payment_id,
        remarks: element.remarks,
      });
    });

    this.highCourtForm.value.highcourt_master.forEach((element, index) => {
      newList.push({
        payment_subcategory_index: index,
        payment_category: "APPELLATE PROCEEDINGS",
        payment_subcategory: "High Court",
        commissionerate: this.commissionerate,
        section: element.section,
        nature_of_payment: element.natureOfPayment,
        nature_of_document_name: "Appeal No",
        nature_of_document_no: element.appealNo,
        document_details_name: "Appeal Date",
        document_details_date: element.appealDate,
        order_number: element.orderNo,
        amount_in_inr: element.amount ? element.amount : "0",
        payment_id: this.payment_id,
        remarks: element.remarks,
      });
    });

    this.supremeCourtForm.value.supremecourt_master.forEach((element, index) => {
      newList.push({
        payment_subcategory_index: index,
        payment_category: "APPELLATE PROCEEDINGS",
        payment_subcategory: "Supreme Court",
        commissionerate: this.commissionerate,
        section: element.section,
        nature_of_payment: element.natureOfPayment,
        nature_of_document_name: "Appeal No",
        nature_of_document_no: element.appealNo,
        document_details_name: "Appeal Date",
        document_details_date: element.appealDate,
        order_number: element.orderNo,
        amount_in_inr: element.amount ? element.amount : "0",
        payment_id: this.payment_id,
        remarks: element.remarks,
      });
    });

    this.otherForm.value.other_master.forEach((element, index) => {
      newList.push({
        payment_subcategory_index: index,
        payment_category: "APPELLATE PROCEEDINGS",
        payment_subcategory: "Other",
        commissionerate: this.commissionerate,
        section: element.section,
        nature_of_payment: element.natureOfPayment,
        nature_of_document_name: "Appeal No",
        nature_of_document_no: element.appealNo,
        document_details_name: "Appeal Date",
        document_details_date: element.appealDate,
        order_number: element.orderNo,
        amount_in_inr: element.amount ? element.amount : "0",
        payment_id: this.payment_id,
        remarks: element.remarks,
      });
    });

    this.payableAmt = 0;
    newList.forEach((element) => {
      // this.payableAmt = this.payableAmt + parseInt(element.amount_in_inr);
      this.payableAmt = this.payableAmt +  parseFloat(element.amount_in_inr.replace(/,/g, '')) 

    });
    localStorage.setItem("payableAmount", this.payableAmt);

    console.log(this.payableAmt, "payableAmt");
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd HH:mm:ss");
    this.selectedDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  addRowCommission(d?: CommissionerElement, noUpdate?: boolean) {
    this.commissionerate_type = localStorage.getItem('commissionerate_type');
    this.commissionerate = this.commissionerate_type;

    const newRow = this.formBuilder.group({
      no: Date.now(),
      // commissionerate: this.commissionerate,
      commissionerate: [{value:this.commissionerate, disabled: true}],
      section: [d && d.section ? d.section : ""],
      natureOfPayment: [d && d.natureOfPayment ? d.natureOfPayment : ""],

      amount: [d && d.amount ? d.amount : ""],
      appealNo: [d && d.appealNo ? d.appealNo : ""],
      appealDate: [d && d.appealDate ? d.appealDate : ""],
      orderNo: [d && d.orderNo ? d.orderNo : ""],
      remarks: [d && d.remarks ? d.remarks : ""],
      action: [null],
    });
    // console.log(newRow, "dd");
    // this.commissioner_master.value.forEach(e1=>{
    //   if(e1.amount!=""){
    //     this.commissioner_master.push(newRow);
    //   }
    // })
    
    this.commissioner_master.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateViewCommissioner();
    }
  }

 
  updateViewCommissioner() {
    this.dataSource5.next(this.commissioner_master.controls);
  }

  addRowCestat(d?: CestatElement, noUpdate?: boolean) {
    this.commissionerate_type = localStorage.getItem('commissionerate_type');
    this.commissionerate = this.commissionerate_type;
    const newRow = this.formBuilder.group({
      no: Date.now(),
      // commissionerate: this.commissionerate,
      commissionerate: [{value:this.commissionerate, disabled: true}],
      section: [d && d.section ? d.section : ""],
      natureOfPayment: [d && d.natureOfPayment ? d.natureOfPayment : ""],

      amount: [d && d.amount ? d.amount : ""],
      appealNo: [d && d.appealNo ? d.appealNo : ""],
      appealDate: [d && d.appealDate ? d.appealDate : ""],
      orderNo: [d && d.orderNo ? d.orderNo : ""],
      remarks: [d && d.remarks ? d.remarks : ""],
      action: [null],
    });
    // console.log(newRow, "miscellaneous");
     this.cestat_master.push(newRow);
    // this.cestat_master.value.forEach(e1=>{
    //   if(e1?.amount!=""){
    //     this.cestat_master.push(newRow);
    //   }
    // })
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateViewCestat();
    }
  }

  updateViewCestat() {
    this.dataSource6.next(this.cestat_master.controls);
  }

  addRowEpcg(d?: EpcgElement, noUpdate?: boolean) {
    this.commissionerate_type = localStorage.getItem('commissionerate_type');
    this.commissionerate = this.commissionerate_type;
    const newRow = this.formBuilder.group({
      no: Date.now(),
      // commissionerate: this.commissionerate,
      commissionerate: [{value:this.commissionerate, disabled: true}],
      section: [d && d.section ? d.section : ""],
      natureOfPayment: [d && d.natureOfPayment ? d.natureOfPayment : ""],

      amount: [d && d.amount ? d.amount : ""],
      appealNo: [d && d.appealNo ? d.appealNo : ""],
      appealDate: [d && d.appealDate ? d.appealDate : ""],
      orderNo: [d && d.orderNo ? d.orderNo : ""],
      remarks: [d && d.remarks ? d.remarks : ""],
      action: [null],
    });
    // console.log(newRow, "other");
    this.highcourt_master.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateViewEpcg();
    }
  }

  updateViewEpcg() {
    this.dataSource1.next(this.highcourt_master.controls);
  }

  addRowAA(d?: AaElement, noUpdate?: boolean) {
    this.commissionerate_type = localStorage.getItem('commissionerate_type');
    this.commissionerate = this.commissionerate_type;
    const newRow = this.formBuilder.group({
      no: Date.now(),
      // commissionerate: this.commissionerate,
      commissionerate: [{value:this.commissionerate, disabled: true}],
      section: [d && d.section ? d.section : ""],
      natureOfPayment: [d && d.natureOfPayment ? d.natureOfPayment : ""],

      amount: [d && d.amount ? d.amount : ""],
      appealNo: [d && d.appealNo ? d.appealNo : ""],
      appealDate: [d && d.appealDate ? d.appealDate : ""],
      orderNo: [d && d.orderNo ? d.orderNo : ""],
      remarks: [d && d.remarks ? d.remarks : ""],
      action: [null],
    });
    // console.log(newRow, "other");
    this.supremecourt_master.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateViewAA();
    }
  }

  updateViewAA() {
    this.dataSource2.next(this.supremecourt_master.controls);
  }

  addRowShip(d?: ShippingElement, noUpdate?: boolean) {
    this.commissionerate_type = localStorage.getItem('commissionerate_type');
    this.commissionerate = this.commissionerate_type;
    const newRow = this.formBuilder.group({
      no: Date.now(),
      // commissionerate: this.commissionerate,
      commissionerate: [{value:this.commissionerate, disabled: true}],
      section: [d && d.section ? d.section : ""],
      natureOfPayment: [d && d.natureOfPayment ? d.natureOfPayment : ""],

      amount: [d && d.amount ? d.amount : ""],
      appealNo: [d && d.appealNo ? d.appealNo : ""],
      appealDate: [d && d.appealDate ? d.appealDate : ""],
      orderNo: [d && d.orderNo ? d.orderNo : ""],
      remarks: [d && d.remarks ? d.remarks : ""],
      action: [null],
    });
    // console.log(newRow, "other");
    this.other_master.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateViewShip();
    }
  }

  updateViewShip() {
    this.dataSource3.next(this.other_master.controls);
  }

  removeRow() {
    let no = this.removeRowNo;
    const roomArr: any[] = this.dataSource5.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if (index == 0) {
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );
        }
        else {
          roomArr.splice(index, 1);
          this.commissionerForm.value.commissioner_master.splice(index, 1);
        }
      }
    });
    this.dataSource5.next(roomArr);
    //this.updateViewDuties();

    // let no = this.removeRowNo;
    // this.dataSource = this.dataSource.filter((u) => u.no !== no);
  }

  removeRow1() {
    let no = this.removeRowNo1;

    const roomArr: any[] = this.dataSource6.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if (index == 0) {
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );
        }
        else {
          roomArr.splice(index, 1);
          this.cestatForm.value.cestat_master.splice(index, 1);
        }
      }
    });
    this.dataSource6.next(roomArr);
  }

  removeRow2() {
    let no = this.removeRowNo2;
    const roomArr: any[] = this.dataSource1.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if (index == 0) {
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );
        }
        else {
          roomArr.splice(index, 1);
          this.highCourtForm.value.highcourt_master.splice(index, 1);
        }
      }
    });
    this.dataSource1.next(roomArr);
    // this.dataSource6 = this.dataSource6.filter((u) => u.no !== no);
  }

  removeRow3() {
    let no = this.removeRowNo3;
    const roomArr: any[] = this.dataSource2.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if (index == 0) {
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );
        }
        else {
          roomArr.splice(index, 1);
          this.supremeCourtForm.value.supremecourt_master.splice(index, 1);
        }
      }
    });
    this.dataSource2.next(roomArr);
    // this.dataSource6 = this.dataSource6.filter((u) => u.no !== no);
  }

  removeRow4() {
    let no = this.removeRowNo4;
    const roomArr: any[] = this.dataSource3.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if (index == 0) {
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );
        }
        else {
          roomArr.splice(index, 1);
          this.otherForm.value.other_master.splice(index, 1);
        }
      }
    });
    this.dataSource3.next(roomArr);
    // this.dataSource6 = this.dataSource6.filter((u) => u.no !== no);
  }

  activeFn() {
    this.active = true;
  }
  getDuties() {
    this.blockchainService.getDuties().subscribe((resp) => {
      this.allDuties = resp.obj;
    });
  }
  selectDuties(name, event) {
    if (event.isUserInput) {
      this.selectedDuties = name;
    }
  }

  getCommissionrate() {
    this.blockchainService.getCommissionrate().subscribe((resp) => {
      this.allCommissionrate = resp.obj;

      //  this.commissionerForm.value.commissioner_master.controls['commissionerate'].setValue(this.commissionerate_type);
      // this.commissionerForm.value.commissioner_master
      //  this.commissionerForm.value.commissioner_master[0].controls['commissionerate'].setValue(this.commissionerate_type);
    });
  }

  selectCommissionrate(name, id, action, i, event) {
    // if (event.isUserInput) {
      if (action == "duties") {
        console.log("duties", id);
        this.getSectionByCommissionrateId(id, i);
      } else {
        this.getSectionByCommissionrateId1(id, i);
      }
    // }
  }

  getSectionByCommissionrateId(id, index) {
    let input = {
      id: id,
    };
    this.blockchainService
      .getSectionByCommissionrateId(input)
      .subscribe((resp) => {
        this.allSectionByCommissionrate[index] = resp.body.obj;
        // console.log(
        //   this.allSectionByCommissionrate,
        //   "allSectionByCommissionrate"
        // );
      });
  }

  getSectionByCommissionrateId1(id, index) {
    let input = {
      id: id,
    };
    this.blockchainService
      .getSectionByCommissionrateId(input)
      .subscribe((resp) => {
        // this.allSectionByCommissionrate.splice(index, 0, resp.body.obj)
        // this.allSectionByCommissionrate.push(resp.body.obj);
        this.allSectionByCommissionrate1[index] = resp.body.obj;
        // console.log(
        //   this.allSectionByCommissionrate1,
        //   "allSectionByCommissionrate"
        // );
      });
  }

  selectSectionByCommissionrate(name, action, event) {
    if (event.isUserInput) {
      if (action == "duties") {
        this.selectedSection = name;
      } else {
        this.selectedSectionMisc = name;
      }
    }
  }

  getMiscellaneous() {
    this.blockchainService.getMiscellaneous().subscribe((resp) => {
      this.allMiscellaneous = resp.obj;
    });
  }

  selectMiscellaneous(name, event) {
    if (event.isUserInput) {
      this.selectedMiscellaneous = name;
    }
  }

  getOvertime() {
    this.blockchainService.getOvertime().subscribe((resp) => {
      this.allOvertime = resp.obj;
    });
  }

  selectOvertime(name, event) {
    if (event.isUserInput) {
      this.selectedOvertime = name;
    }
  }

  next() {
    // this.router.navigate(["/paymentMode"]);
    // appellate
    this.router.navigate(["/appellate"]);
  }

  openModal(no) {
    this.removeRowNo = no;
    const buttonModal = document.getElementById("openModalButton");
    buttonModal.click();
  }

  openModal1(no) {
    this.removeRowNo1 = no;
    const buttonModal = document.getElementById("openModalButton1");
    buttonModal.click();
  }

  openModal2(no) {
    this.removeRowNo2 = no;
    const buttonModal = document.getElementById("openModalButton2");
    buttonModal.click();
  }

  openModal3(no) {
    this.removeRowNo3 = no;
    const buttonModal = document.getElementById("openModalButton3");
    buttonModal.click();
  }

  openModal4(no) {
    this.removeRowNo4 = no;
    const buttonModal = document.getElementById("openModalButton4");
    buttonModal.click();
  }
  onMouseEnter(event: any, i, element, formName): void {
    if(element.value.remarks){
      this.remarks4 = element.value.remarks;
    }else{
      this.remarks4 = '';
    }
    event.target.click();
    this.no = i;
    this.selectedForm = formName;
  }

  submitRemarks(rowNo, selectedForm) {
    if (selectedForm == 'Pre-Deposite for Appellate Proceedings - Commissioner (Appeal)') {
      this.commissioner_master.controls[rowNo].patchValue({ "remarks": this.remarks4 });
    }
    if (selectedForm == 'Pre-Deposite for Appellate Proceedings - CESTAT') {
      this.cestat_master.controls[rowNo].patchValue({ "remarks": this.remarks4 });
    }
    if (selectedForm == 'Pre-Deposite for Appellate Proceedings - High Court') {
      this.highcourt_master.controls[rowNo].patchValue({ "remarks": this.remarks4 });
    }
    if (selectedForm == 'Pre-Deposite for Appellate Proceedings - Supreme Court') {
      this.supremecourt_master.controls[rowNo].patchValue({ "remarks": this.remarks4 });
    }
    if(selectedForm == 'Pre-Deposite for Appellate Proceedings - Other'){
      this.other_master.controls[rowNo].patchValue({ "remarks": this.remarks4 });
    }
  }
  wordCounter() {
    //alert(this.text.nativeElement.value)
    this.wordCount = this.text ? this.text.nativeElement.value.split(/\s+/) : 0;
    this.words = this.wordCount ? this.wordCount.length : 0;
  }

  eraseWordCount(){
    this.wordCount = 0;
    this.words = 0
  }
  
  saveHeads() {
    //this.grandCalculate(this.payment_id);
      let commMaster = [];
      this.commissionerForm.value.commissioner_master.forEach((element, index) => {
        if (
          (element.amount != "" &&
        element.amount != null &&
        element.amount != undefined) || element.section != "" || (element.natureOfPayment != "")
        ) {
          if (
             element.section == "" || element.natureOfPayment == "" || (element.amount == "")
          ) {
            localStorage.setItem('appllate','true');
            commMaster.push({message:"Please fill Section, Nature of Payment & Amount of Pre-Deposite for Appellate Proceedings - Commissioner (Appeal) for Row "+(++index)})

          }else{
           
              this.finalList.push({
                payment_subcategory_index: element.payment_subcategory_index ? element.payment_subcategory_index : index,
                payment_category: "APPELLATE PROCEEDINGS",
                payment_subcategory: "Commissioner Appeal",
                commissionerate: this.commissionerate,
                section: element.section,
                nature_of_payment: element.natureOfPayment,
                nature_of_document_name: "Appeal No",
                nature_of_document_no: element.appealNo,
                document_details_name: "Appeal Date",
                document_details_date: element.appealDate,
                order_number: element.orderNo,
                amount_in_inr: element.amount,
                payment_id: this.payment_id,
                remarks: element.remarks,
    
              });
            
          }
        }
       
      });

      let cestatMaster = [];
      this.cestatForm.value.cestat_master.forEach((element, index) => {
        if (
          (element.amount != "" &&
        element.amount != null &&
        element.amount != undefined) || element.section != "" || (element.natureOfPayment != "")
        ) {
          if (
            element.section == "" || element.natureOfPayment == "" || element.amount == ""
          ) {
            localStorage.setItem('appllate','true');
            cestatMaster.push({message:"Please fill Section, Nature of Payment & Amount of Pre-Deposite for Appellate Proceedings - CESTAT for Row "+(++index)})
          }else{
            this.finalList.push({
              payment_subcategory_index: element.payment_subcategory_index ? element.payment_subcategory_index : index,
              payment_category: "APPELLATE PROCEEDINGS",
              payment_subcategory: "CESTAT",
              commissionerate: this.commissionerate,
              section: element.section,
              nature_of_payment: element.natureOfPayment,
              nature_of_document_name: "Appeal No",
              nature_of_document_no: element.appealNo,
              document_details_name: "Appeal Date",
              document_details_date: element.appealDate,
              order_number: element.orderNo,
              amount_in_inr: element.amount,
              payment_id: this.payment_id,
              remarks: element.remarks,
            });
          }
        }
      });
     
      let highCourtMaster = [];
      this.highCourtForm.value.highcourt_master.forEach((element, index) => {
        if (
          (element.amount != "" &&
        element.amount != null &&
        element.amount != undefined) || element.section != "" || (element.natureOfPayment != "")
        ) {
          if (
            element.section == "" || element.natureOfPayment=="" || element.amount == "" ) {
              localStorage.setItem('appllate','true');
              highCourtMaster.push({message:"Please fill Section, Nature of Payment & Amount of Pre-Deposite for Appellate Proceedings - High Court for Row "+(++index)})
          }else{
            this.finalList.push({
              payment_subcategory_index: element.payment_subcategory_index ? element.payment_subcategory_index : index,
              payment_category: "APPELLATE PROCEEDINGS",
              payment_subcategory: "High Court",
              commissionerate: this.commissionerate,
              section: element.section,
              nature_of_payment: element.natureOfPayment,
              nature_of_document_name: "Appeal No",
              nature_of_document_no: element.appealNo,
              document_details_name: "Appeal Date",
              document_details_date: element.appealDate,
              order_number: element.orderNo,
              amount_in_inr: element.amount,
              payment_id: this.payment_id,
              remarks: element.remarks,
            });
          }
        }
    
      });
      
      let supremeCourtMaster = [];
      this.supremeCourtForm.value.supremecourt_master.forEach((element, index) => {
        if (
          (element.amount != "" &&
        element.amount != null &&
        element.amount != undefined) || element.section != "" || (element.natureOfPayment != "")
        ) {
          if (
            element.section == "" || element.natureOfPayment=="" || (element.amount == "")
           
          ) {
            localStorage.setItem('appllate','true');
            supremeCourtMaster.push({message:"Please fill Section, Nature of Payment & Amount of Pre-Deposite for Appellate Proceedings - Supreme Court for Row "+(++index)})
          }else{
            this.finalList.push({
              payment_subcategory_index: element.payment_subcategory_index ? element.payment_subcategory_index : index,
              payment_category: "APPELLATE PROCEEDINGS",
              payment_subcategory: "Supreme Court",
              commissionerate: this.commissionerate,
              section: element.section,
              nature_of_payment: element.natureOfPayment,
              nature_of_document_name: "Appeal No",
              nature_of_document_no: element.appealNo,
              document_details_name: "Appeal Date",
              document_details_date: element.appealDate,
              order_number: element.orderNo,
              amount_in_inr: element.amount,
              payment_id: this.payment_id,
              remarks: element.remarks,
            });
          }
        }
   
      });
     
      let otherMaster = [];
      this.otherForm.value.other_master.forEach((element, index) => {
        if (
          (element.amount != "" &&
        element.amount != null &&
        element.amount != undefined) || element.section != "" || (element.natureOfPayment != "")
        ) {
          if (
            element.section == "" || element.natureOfPayment == "" || element.amount == ""
            
          ) {
            localStorage.setItem('appllate','true');
            otherMaster.push({message:"Please fill Section, Nature of Payment & Amount of Pre-Deposite for Appellate Proceedings - Supreme Court for Row "+(++index)})
          }else{
            this.finalList.push({
              payment_subcategory_index: element.payment_subcategory_index ? element.payment_subcategory_index : index,
              payment_category: "APPELLATE PROCEEDINGS",
              payment_subcategory: "Other",
              commissionerate: this.commissionerate,
              section: element.section,
              nature_of_payment: element.natureOfPayment,
              nature_of_document_name: "Appeal No",
              nature_of_document_no: element.appealNo,
              document_details_name: "Appeal Date",
              document_details_date: element.appealDate,
              order_number: element.orderNo,
              amount_in_inr: element.amount,
              payment_id: this.payment_id,
              remarks: element.remarks,
            });
          }
        }
      
      });
 
      if(commMaster.length!=0){
        localStorage.setItem('appllate','true');
        commMaster.forEach(msg=>{
          this.showNotification(
            "bottom",
            "right",
            "success",
             msg.message,
            "announcement"
          );
        })
      }else if(cestatMaster.length!=0){
        localStorage.setItem('appllate','true');
        cestatMaster.forEach(msg=>{
          this.showNotification(
            "bottom",
            "right",
            "success",
             msg.message,
            "announcement"
          );
        })
      }else if(highCourtMaster.length!=0){
        localStorage.setItem('appllate','true');
        highCourtMaster.forEach(msg=>{
          this.showNotification(
            "bottom",
            "right",
            "success",
             msg.message,
            "announcement"
          );
        })
      }else if(supremeCourtMaster.length!=0){
        localStorage.setItem('appllate','true');
        supremeCourtMaster.forEach(msg=>{
          this.showNotification(
            "bottom",
            "right",
            "success",
             msg.message,
            "announcement"
          );
        })
      }else if(otherMaster.length!=0){
        localStorage.setItem('appllate','true');
        otherMaster.forEach(msg=>{
          this.showNotification(
            "bottom",
            "right",
            "success",
             msg.message,
            "announcement"
          );
     
        })
      }else{

     

      let input = {
        sd: this.finalList,
      };
      this.blockUI.start("Please wait this may take few seconds ...");

      this.blockchainService.updateSupportingDocumentsV3(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          localStorage.setItem('appllate','false');
          setTimeout(() => {
            this.blockUI.stop();
          }, 1000);
          // this.showNotification(
          //   "bottom",
          //   "right",
          //   "success",
          //   resp.body.message,
          //   "announcement"
          // );
          this.grandCalculate();
          // this.router.navigate(["/paymentApplication"]);
        } else {
          localStorage.setItem('appllate','true');
          setTimeout(() => {
            this.blockUI.stop();
          }, 1000);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message+" For Appellate Proceedings",
            "announcement"
          );
        }
      });
    }
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "paymentSum" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
