import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripTag'
})
export class StripTagPipe implements PipeTransform {
  step;
  transform(value: string): string {
    this.step = value.replace(/<br>/gm, '');
    return this.step;
  }
}
