import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit,ViewChild } from "@angular/core";
import { FormControl, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService, CancelledBondQuery, ImportAAQuery } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Month } from "app/blockchainImpExp/customs_interface/custom-payment/payment-daily-summary/month";

declare var $: any;


export interface CancelledBond {
  iec_no: string,
  iec_holder_name:string,
  aa_no: string,
  application_id: number,
  auth_number: string,
  export_validity_date: string,
  bond_amount_in_inr: string,
  bg_amount: string,
  date_of_last_letter: string,
  scn_no: string,
  scn_date: string,
  no_of_days_overdue: string,
  date_of_cancellation: string
}
export interface Status {
  value: string;
  viewValue: string;
}
export interface calendarView {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-live-bond',
  templateUrl: './live-bond.component.html',
  styleUrls: ['./live-bond.component.css']
})
export class LiveBondComponent implements OnInit {
  initialSort: Sort<CancelledBond> = { property: "application_id", order: "asc" };
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  @ViewChild(MatPaginator, { static: false }) paginator1: MatPaginator;
  filterData: any;
  filterByDataOneToTwoMonth: Status[] = [
    { value: 'IEC No', viewValue: 'IEC No' },
    { value: 'IEC Holder Name', viewValue: 'IEC Holder Name' },
    { value: 'AA No', viewValue: 'AA No' },
    { value: 'Export Validity Date', viewValue: 'Export Validity Date' },
    { value: 'No Of Days OverDue', viewValue: 'No Of Days OverDue' },
    { value: 'Bond Amount', viewValue: 'Bond Amount' },
    { value: 'Date Of Issue ShowCause Notice', viewValue: 'Date Of Issue ShowCause Notice' },
    // { value: 'Process', viewValue: 'Process' },
  ]
  allNoOfDaysView: calendarView[] = [
    { value: "greater", viewValue: "Greater" },
    { value: "smaller", viewValue: "Smaller" },
  ];
  displayedColumns = [
    "sr_no",
    "iec_no",
    "iec_holder_name",
    "auth_number" ,
    "export_validity_date",
    "no_of_days_overdue",
    "bond_amount_in_inr",
    "scn_date",
    "process"
  ];

  noOfAuth: string;
  liveCancellationBondResp: any;
  liveCancellationBondPaginatedResp:[];
  pipe = new DatePipe("en-US");
  maxDate = new Date(2050, 12, 31);
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  date: any;
  isNoOfDays:boolean;
  selectedView: any;
  allCalendarView: calendarView[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Weekly", viewValue: "Weekly" },
    { value: "Monthly", viewValue: "Monthly" },
    { value: "Date Range", viewValue: "Date Range" },
  ];
  modifiedCurrentDate: any;
  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  isButtonVisible = false;
  first_day_of_month: any;
  last_day_of_month: any;
  currentDate = new Date();
  firstDay: Date;
  noOfDaysValue: any;
  lastDay: Date;
  selectedFilter: string;
  events: string[] = [];
  events1: string[] = [];
  datepick = true;
  monthselectedValue: any;
  public selectedDate = new FormControl(new Date());
  public selectedDateStart = new FormControl(new Date());
  public selectedDateEnd = new FormControl(new Date());
  public months: Month[];
  public selectedMonth: Month;
  isMonthly: boolean;
  isDay: boolean;
  isWeekly: boolean;
  isRange: boolean;
  showTable: boolean;
  sendInput: any;
  dateData: any;
  startDate: any;
  endDate: any;
  offsetData: any;
  pageSet: number;
  pageSizeChange: number;
  currentUser: any;
  role: any;
  totalEntries;
  dataSource: CancelledBond[] = [];
  loggedUserLevel: any;
  Process: any;
  user: any;
  applicationType: string;
  downloadLiveBondResp: any;
  downloadLiveBondRespFilteredData: any;
  loader1:boolean;
  hideColumn:boolean=false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { 
    this.offsetData = JSON.parse(localStorage.getItem("offset"));
    this.pageSet = this.offsetData - 1;
    this.pageSizeChange = JSON.parse(localStorage.getItem("pageSize"));
  }

  ngOnInit(): void {
    localStorage.removeItem('redirectedpage');
    this.date = localStorage.getItem("ObMDate");
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.loggedUserLevel=this.currentUser.obj.level;
    if(this.loggedUserLevel=='L3'){
      this.hideColumn=true;
    }
    else{
      this.hideColumn=false;
    }
    console.log("hiddenColumn",this.hideColumn)
    console.log(this.currentUser.obj.level);
    this.role = this.currentUser.obj.user_type_id;
    this.user = this.currentUser.obj.user_id;
    this.date = localStorage.getItem("ObMDate");
    this.applicationType = localStorage.getItem('applicationType');
    localStorage.removeItem('filter');
    this.loader1=true;
    this.pageSet=0;
    localStorage.setItem("pageSize","10");
    // localStorage.setItem("offset", JSON.stringify(offset + 1));
    // this.getCancellationBondSummary(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange);
    this.processType(this.process, this.offsetData);
    // this.getBondCancellationAppFiled(this.date);
  }
  getNextOneTwoMonth(event: PageEvent) {
    let offset = event.pageIndex;
    this.pageSizeChange = event.pageSize;
   
    if ((this.selectedFilter != null || this.selectedFilter != undefined) && this.filterData != null) {
      console.log(this.pageSizeChange,"1")
      this.pageSet = this.offsetData - 1;
      let filter = JSON.parse(localStorage.getItem("filter"));
      if (event.previousPageIndex == 0) {
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        filter.offset = offset + 1;
        filter.entries_per_page = this.pageSizeChange;
        this.getBondCancellationAppFiledFilter(filter);
      } else {
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        filter.offset = offset + 1;
        filter.entries_per_page = this.pageSizeChange;
        this.getBondCancellationAppFiledFilter(filter);
      }
      
      
      
    } else {
      if (event.previousPageIndex == 0) {
        localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getBondCancellationAppFiled(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)

      } else {
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        this.getBondCancellationAppFiled(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)

      }
    }

  }
  processType(type, offsetData) {
    if(this.pageSizeChange == null ){
      this.pageSizeChange = 10
    }
   
    // this.loadMonths();
    this.getBondCancellationAppFiled(this.user, offsetData,this.pageSizeChange);

  }

  process(authno){
    let url3="liveBond";
    localStorage.setItem('redirectedpage',url3);
    localStorage.setItem('authno',authno);
    this.router.navigate(['/scnModulePS']);
  }
  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate,
      this.endDate = this.date ? this.date : this.todayDate
  }
  dateStartSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate;
    // console.log(this.startDate)
  }
  dateEndSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.endDate = this.date ? this.date : this.todayDate;
    // console.log(this.endDate)
  }
  onFilterSelected(event, status) {
    this.filterData = '';
    this.selectedDate=null;
    this.selectedDateStart=null;
    this.selectedDateEnd=null;
    this.selectedMonth=null;
    this.isMonthly = false;
    this.isDay = false;
    this.isNoOfDays=false;
    this.isWeekly = false;
    this.isRange = false;
    if (event.isUserInput) {
      this.selectedFilter = status;
    }
    if(this.selectedFilter=='Export Validity Date'){
      this.loadExportValidityMonths();
    }
    else{
      this.loadMonths();
    }
  }

  selectNoOfDaysView(event, value) {
    console.log(value);
    console.log(event);
    if (event.isUserInput==true) {
      this.noOfDaysValue=value;
      if (value == "greater" ) {
        this.isNoOfDays = true;
      }
      else if (value == "smaller" ) {
        this.isNoOfDays = true;
      }
      else {
        this.isNoOfDays = false;
      }
    }
  }

  resetFilters() {

    this.selectedFilter = '';
    this.selectedFilter = null;
    this.filterData = '';
    this.isMonthly = false;
    this.isDay = false;
    this.isWeekly = false;
    this.isNoOfDays=false;
    this.isRange = false;
    // this.dataSource = [];
    this.pageSet = 0;
    this.offsetData = 1;
    this.pageSizeChange = 10;
    this.paginator1.pageIndex=0;
    localStorage.setItem("offset", JSON.stringify(this.offsetData));
    localStorage.removeItem('filter');
    this.ngOnInit();
  }
  getFilterData(data, filter) {
    this.blockUI.start();
    this.loggedUserLevel=this.currentUser.obj.level;
    console.log(data);
    this.dataSource=[];
    this.pageSet = 0;
    this.offsetData = 1
    localStorage.setItem("offset", JSON.stringify(this.offsetData));
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    if (filter == 'AA No') {
      this.sendInput = {
        start_date:this.todayDate,
        auth_number: data,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
    }
    else if (filter == 'IEC No') {
      this.sendInput = {
        iec_no: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
      console.log(this.sendInput);
    }
    else if (filter == 'IEC Holder Name') {
      this.sendInput = {
        iec_holder_name: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
      console.log(this.sendInput);
    }
    else if (filter == 'Bond Amount') {
      this.sendInput = {
        bond_amount_in_inr: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
    } else if (filter == 'BG Amount') {
      this.sendInput = {
        bg_amount: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
    }
    else if (filter == 'No Of Days OverDue') {
      this.sendInput = {
        no_of_days_overdue: data,
        start_date:this.todayDate,
        time_span:this.noOfDaysValue,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
    }
    else if (filter == 'Date Of Issue ShowCause Notice') {
        this.sendInput = {
          //user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          //pending_app:this.pendingApp
          scn_date:this.startDate+" 00:00:00",
          start_date:this.todayDate,
          custom_start_date:this.startDate,
          custom_end_date:this.endDate,
          // end_date:this.endDate,
        }
    
    } 
    else if (filter == 'Export Validity Date') {
      this.sendInput = {
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
        export_validity_date:this.startDate,
        start_date:this.todayDate,
        custom_start_date:this.startDate,
        custom_end_date:this.endDate,
      }
  
  } 

else if (filter == 'Process') {
  this.sendInput = {
    Process: data,
    //user_id: this.currentUser.obj.user_id,
    offset: this.offsetData,
    entries_per_page:this.pageSizeChange,
    //pending_app:this.pendingApp
  }

}

    // else if (filter == 'Incomming Date') {
    //   this.sendInput = {
    //     start_date: this.startDate,
    //     end_date: this.endDate,
    //     incoming_date: this.startDate,
    //     //user_id: this.currentUser.obj.user_id,
    //     offset: this.offsetData,
    //     entries_per_page:this.pageSizeChange,
    //     evd:"",
    //     custom_start_date:"",
    //     custom_end_date:"",
    //     //pending_app:this.pendingApp
    //   }
    // }
    setTimeout(() => {
      this.blockUI.stop();
    }, 500);
      localStorage.setItem('filter', JSON.stringify(this.sendInput));
      this.paginator1.pageIndex=0;
      this.getBondCancellationAppFiledFilter(this.sendInput);
  }
  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }


  
  loadExportValidityMonths() {
    let date = new Date("2026-12-31");
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months = [];
    for (let i = 0; i < 156; i++) {

      // console.log("MOnth : ", month);
      if (month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      let name = this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = { "name": name, "value": month + 1, "start": start + " 00:00:00", "end": end + " 23:59:59" };
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
  }

  loadMonths() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months = [];
    for (let i = 0; i < 12; i++) {

      // console.log("MOnth : ", month);
      if (month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      let name = this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = { "name": name, "value": month + 1, "start": start + " 00:00:00", "end": end + " 23:59:59" };
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
  }

  monthly(value, event) {
    if (event.isUserInput) {
      this.showTable = true;
      this.selectedMonth = value ? value : this.selectedMonth;
      this.startDate = this.pipe.transform(this.selectedMonth.start, "yyyy-MM-dd", "en-US");
      this.endDate = this.pipe.transform(this.selectedMonth.end, "yyyy-MM-dd", "en-US");
    }
  }

  selectCalendarView(event, value) {
    if (event.isUserInput==true) {
      if (value == "Monthly") {
        this.isMonthly = true;
        this.isDay = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Weekly") {
        this.isWeekly = true;
        this.isDay = false;
        this.isMonthly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Day") {
        this.isDay = true;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else {
        this.isDay = false;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = true;
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  compareMonths(i1, i2) {
    // return i2 && i1 && i1.value === i2.value;
    return i1 && i2 && i1.value === i2.value;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    if (this.isMonthly) {
      if (type === "change") {
        this.showTable = true;

        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        //const start =
        let date = new Date(this.today);
        //date = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        this.first_day_of_month = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        let currentMonth = date.getMonth();
        this.last_day_of_month = new Date(
          date.getFullYear(),
          currentMonth,
          this.daysInMonth(currentMonth + 1, date.getFullYear())
        );
        // let input = {
        //   start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        this.startDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        this.endDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);
        // this.dateData = input;
      }
    } else {
      if (type === "change") {
        this.showTable = true;
        this.today = event.value.getTime();

        const now = this.today;

        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        const start = new Date(this.today + 6 * 24 * 60 * 60 * 1000).getTime();
        const myStartDate = this.pipe.transform(start, "yyyy-MM-dd", "en-US");
        this.last6 = start;
        // let input = {
        //   end_date : this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   start_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        // this.dateData = input;
        this.startDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        this.endDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);

        if (this.today == this.now) {
          this.isButtonVisible = false;
        } else {

          this.isButtonVisible = true;
        }
      }
    }
  }
  getBondCancellationAppFiledFilter(inputData:any) {
    this.loader1=true;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    //this.blockUI.start("Please wait this may take few seconds ...");
    // console.log(offSetData);
    // let input = {
    //   start_date: this.date,
    //   entries_per_page:pageSizeSet,
    //   offset:offSetData,
    //   // user:user,
    // };
    inputData.applicationType = this.applicationType ? this.applicationType : 'AA' 
      this.blockchainService.getBondCancellationAppFiledFilter(inputData).subscribe(resp => {
        if (resp.body.status == 200) {
          this.liveCancellationBondResp = resp.body.obj.cbsr;
          this.liveCancellationBondPaginatedResp=resp.body.obj;
          this.totalEntries = resp.body.obj.total_entries;
          if(inputData.offset==1){
          this.paginator1.pageIndex=0;
          }
          if (this.liveCancellationBondResp !== null) {
            this.loader1=false;
            var liveCancellationBondRespList = resp.body.obj.cbsr;
            this.liveCancellationBondResp = new PaginatedDataSource<CancelledBond, CancelledBondQuery>(
              (request, query) =>
                this.blockchainService.pageCancelledBond(request, query, liveCancellationBondRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.liveCancellationBondResp.pageSize=this.pageSizeChange;
          }
  
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader1=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      })
    }
  getBondCancellationAppFiled(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader1=true;
    console.log(offSetData);
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      // user:user,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
      this.blockchainService.getBondCancellationAppFiled(input).subscribe(resp => {
        if (resp.body.status == 200) {
          this.liveCancellationBondResp = resp.body.obj.cbsr;
          this.liveCancellationBondPaginatedResp=resp.body.obj;
          this.totalEntries = resp.body.obj.total_entries;
          if (this.liveCancellationBondResp !== null) {
            this.loader1=false;
            var liveCancellationBondRespList = resp.body.obj.cbsr;
            this.liveCancellationBondResp = new PaginatedDataSource<CancelledBond, CancelledBondQuery>(
              (request, query) =>
                this.blockchainService.pageCancelledBond(request, query, liveCancellationBondRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.liveCancellationBondResp.pageSize=this.pageSizeChange;
            if(this.paginator1){
            if(input.offset==1){
              this.paginator1.pageIndex=0;
              }
            }
            else{
              this.pageSizeChange=10;
              localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
            }
          }
  
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader1=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      })
    }
    downloadBondCancellationAppFiledByFilter(input) {
      localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
      // let inputData = {
      //   user_id: input,
      //   offset: offSetData,
      //   entries_per_page:pageSizeSet,
      // }
   
      input.applicationType = this.applicationType ? this.applicationType : 'AA';
      input.entries_per_page=1147483647;
      this.blockchainService
      .getBondCancellationAppFiledFilter(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          var displayedCol = []
      displayedCol.push('IEC No');
      displayedCol.push('IEC Holder Name');
      displayedCol.push('AA No');
      displayedCol.push('Export Validity Date');
      displayedCol.push('No. Of Days OverDue');
      displayedCol.push('Bond Amount');
      displayedCol.push('Date Of Issue ShowCause Notice');
            this.downloadLiveBondRespFilteredData=resp.body.obj.cbsr;
                       
            console.log(this.downloadLiveBondRespFilteredData)
            var tableData:any=this.downloadLiveBondRespFilteredData;
            var tableObj =  tableData;
            console.log(tableObj,'tableobj')
        
            tableObj.forEach((element,index) => {
         let DateOfLastLetter=element.date_of_cancellation;
        let scnNo=element.scn_no;
        if(element.iec_no===null){
          element.iec_no="";
        }
        if(element.date_of_cancellation===null){
        element.date_of_cancellation='';
        }
              console.log(element);
        element['IEC No'] = element.iec_no;
        element['IEC Holder Name'] = element.iec_holder_name;
        element['AA No'] = element.auth_number;
        element['Export Validity Date'] = this.pipe.transform(element.export_validity_date, "dd-MM-yyyy");
        element['No. Of Days OverDue']=element.no_of_days_overdue;
        element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
        element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
              for (let i = 0; i < tableObj.length; i++) {
               for (let property in tableObj[i]) {
                 // console.log(property);
                 if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                   tableObj[i][property] = '';
                 }
               }
             }
        // console.log(this.selectedTableDataforDownload[index].join(','));
            });
         const sortedElementsList = tableObj.sort((a, b) => a.application_id);
          this.downloadFile(
          sortedElementsList,
          'LIVE BONDS SUMMARY' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
          displayedCol);
          // }
  
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
         else {
          this.totalEntries=0;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
    }
    downloadBondCancellationAppFiled(user:any, offSetData:any,pageSizeSet:any) {
      //this.blockUI.start("Please wait this may take few seconds ...");
      console.log(offSetData);
      var displayedCol = []
      displayedCol.push('IEC No');
      displayedCol.push('IEC Holder Name');
      displayedCol.push('AA No');
      displayedCol.push('Export Validity Date');
      displayedCol.push('No. Of Days OverDue');
      displayedCol.push('Bond Amount');
      displayedCol.push('Date Of Issue ShowCause Notice');
      let input = {
        start_date: this.date,
        entries_per_page:pageSizeSet,
        offset:offSetData,
        applicationType: this.applicationType ? this.applicationType : 'AA'
        // user:user,
      };
      console.log(input);
      this.blockchainService
        .getBondCancellationAppFiled(input)
        .subscribe((resp) => {
          if (resp.body.status == 200) {
            // this.oneTwoRespLength = resp.body.obj;
            // this.oneTwoResp = resp.body.obj.sd;
            // console.log(resp.body.obj);
            this.downloadLiveBondResp = resp.body.obj.cbsr;
             
             console.log(this.downloadLiveBondResp)
             var tableData:any=this.downloadLiveBondResp;
             var tableObj =  tableData;
             console.log(tableObj,'tableobj')
         
             tableObj.forEach((element,index) => {
              let DateOfLastLetter=element.date_of_cancellation;
              let scnNo=element.scn_no;
              if(element.iec_no===null){
                element.iec_no="";
              }
              if(element.date_of_cancellation===null){
              element.date_of_cancellation='';
              }
               console.log(element);
        element['IEC No'] = element.iec_no;
        element['IEC Holder Name'] = element.iec_holder_name;
        element['AA No'] = element.auth_number;
        element['Export Validity Date'] = this.pipe.transform(element.export_validity_date, "dd-MM-yyyy");
        element['No. Of Days OverDue']=element.no_of_days_overdue;
        element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
        element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
         
               for (let i = 0; i < tableObj.length; i++) {
                for (let property in tableObj[i]) {
                  // console.log(property);
                  if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                    tableObj[i][property] = '';
                  }
                }
              }
         // console.log(this.selectedTableDataforDownload[index].join(','));
             });
          const sortedElementsList = tableObj.sort((a, b) => a.application_id);
  this.downloadFile(
    sortedElementsList,
    'LIVE BONDS SUMMARY' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
    displayedCol
  );
  for (let i = 0; i < tableObj.length; i++) {
    for (let property in tableObj[i]) {
      // console.log(property);
      if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
        tableObj[i][property] = '';
      }
    }
  }
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );
          } else {
            this.totalEntries=0;
            this.showNotification(
              "bottom",
              "right",
              "danger",
              resp.body.message,
              "announcement"
            );
          }
        });
    }
  
    back(){
      this.router.navigate(['/processorView']);
    }
  
    reportGeneration() { 
  //     var displayedCol = []
  //     displayedCol.push('IEC No');
  //     displayedCol.push('IEC Holder Name');
  //     displayedCol.push('AA No');
  //     displayedCol.push('Export Validity Date');
  //     displayedCol.push('No. Of Days OverDue');
  //     displayedCol.push('Bond Amount');
  //     displayedCol.push('Date Of Issue ShowCause Notice');
  //     var tableData:any=this.liveCancellationBondPaginatedResp;
  //     if(tableData.cbsr){
  //       var tableObj =  tableData.cbsr;
  //       }
  //       else{
  //         var tableObj =  tableData;
  //       }
  //     console.log(tableObj,'tableobj')
  //   // tableObj.forEach(elementNatureOfPayment => {
  //   //   console.log(elementNatureOfPayment);
  //   //   this.selectedTableDataforDownload.push(elementNatureOfPayment.nature_of_payment);
  //   // });
  //   // console.log(this.selectedTableDataforDownload);
    
  //     tableObj.forEach((element,index) => {
  //       let DateOfLastLetter=element.date_of_cancellation;
  //       let scnNo=element.scn_no;
  //       if(element.iec_no===null){
  //         element.iec_no="";
  //       }
  //       if(element.date_of_cancellation===null){
  //       element.date_of_cancellation='';
  
  //       }
  
  //       console.log(element);
  //       element['IEC No'] = element.iec_no;
  //       element['IEC Holder Name'] = element.iec_holder_name;
  //       element['AA No'] = element.auth_number;
  //       element['Export Validity Date'] = this.pipe.transform(element.export_validity_date, "dd-MM-yyyy");
  //       element['No. Of Days OverDue']=element.no_of_days_overdue;
  //       element['Bond Amount']=element.bond_amount_in_inr;
  //       element['Date Of Issue ShowCause Notice'] = this.pipe.transform(element.scn_date, "dd-MM-yyyy");
  
  // // console.log(this.selectedTableDataforDownload[index].join(','));
  //     });
  //     for (let i = 0; i < tableObj.length; i++) {
  //       for (let property in tableObj[i]) {
  //         console.log(property);
  //         if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
  //           tableObj[i][property] = '';
  //         }
  //       }
  //     }
  //     console.log(tableObj);
  //     const sortedElementsList = tableObj.sort((a, b) => a.application_id);
  //     this.downloadFile(
  //       sortedElementsList,
  //       'LIVE BONDS SUMMARY' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
  //       displayedCol
  //     );
  let tableFilterData = JSON.parse(localStorage.getItem('filter'));
  console.log(tableFilterData);
  if(tableFilterData){
     
  this.downloadBondCancellationAppFiledByFilter(tableFilterData);
  }
  
  else{
    
  let offset = localStorage.getItem("offset");
  this.downloadBondCancellationAppFiled(this.currentUser.obj.user_id,1,1147483647)
  // console.log(this.downloadOneTwoResp);
  }  
}
  downloadFile(data, filename = "data", headers) {
    let csvData = this.ConvertToCSV(data, headers);
    console.log(csvData);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  
  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "Sr.No,";
  
    for (let index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];
  
        line += "," + array[i][head];
      }
      str += line + "\r\n";
    }
    return str;
  }
    showNotification(from, align, color, stringMessage, icons) {
      const type = ["", "info", "success", "warning", "danger"];
  
      $.notify(
        {
          icon: icons,
          message: stringMessage,
        },
        {
          type: type[color],
          timer: 4000,
          placement: {
            from: from,
            align: align,
          },
          template:
            '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            "</div>" +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            "</div>",
        }
      );
    }
}
