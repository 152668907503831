import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, ElementRef, Inject, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { PaymentsumComponent } from "app/blockchainImpExp/imp_exp/payment/paymentsum/paymentsum.component";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
declare var $: any;

export interface PeriodicElement {
  item:string,
  details:any,
}

export interface PeriodicElement2 {
  item:string,
  date:any,
  sentBy:any
}

export interface PeriodicElement1 {
  item:string,
  pdf:any
  remarks:boolean;
}

export interface PeriodicElement3 {
  item:string,
  details:any,
  inputBy:any
}

export interface PeriodicElement4 {
  item:string,
  pdf:any
  
}
@Component({
  selector: 'app-relevant-info',
  templateUrl: './relevant-info.component.html',
  styleUrls: ['./relevant-info.component.css']
})
export class RelevantInfoComponent implements OnInit {

  dataSource:PeriodicElement[]=[];
  displayedColumns = [
    'item',
    'details',
  ];
  
  
  displayedColumns1 = [
    'item',
    'pdf',
    'remarks'
  ];
  dataSource1:PeriodicElement1[]=[];
  displayedColumns2 = [
    'item',
    'type_of_letter',
    'date',
    'sentBy',
    'view',
    'response_from_trader'
  ];
  displayedColumns3 = [
    'item',
    'details',
    'inputBy'
  ];
  displayedColumns4 = [
    'item',
    'pdf'
  ];
  displayedColumns5 = [
    'item',
    'pdf',
    'remarks'
  ];
  dataSource5:PeriodicElement4[]=[];
  dataSource2:PeriodicElement1[]=[];
  dataSource3:PeriodicElement2[]=[];
  dataSource4:PeriodicElement3[]=[];
  
  getAllOmDetails: any;
  remarksByLetter: any;
  authNo: any;

  // registration remarks
  iec_remarks:boolean=false;
  epcg_remarks:boolean=false;
  aa_remarks:boolean=false;
  bond_remarks:boolean=false;
  star_remarks:boolean=false;
  bg_remarks:boolean=false;
  gst_remarks:boolean=false;
  ls_remarks:boolean=false;
  poa_remarks:boolean=false;
  aff_remarks:boolean=false;
  rcmc_remarks:boolean=false;
  tc_remarks:boolean=false;
  ipod_remarks:boolean=false;

  // cancellation remarks
aL_remarks:boolean=false;
eodc_remarks:boolean=false;
sL_remarks:boolean=false;
rg_remarks:boolean=false;
od1_remarks:boolean=false;
od2_remarks:boolean=false;
od3_remarks:boolean=false;
od4_remarks:boolean=false;
od5_remarks:boolean=false;
od6_remarks:boolean=false;
od7_remarks:boolean=false;
od8_remarks:boolean=false;
od9_remarks:boolean=false;
od10_remarks:boolean=false;
es1_remarks:boolean=false;
es2_remarks:boolean=false;
es3_remarks:boolean=false;
es4_remarks:boolean=false;
es5_remarks:boolean=false;
es6_remarks:boolean=false;
es7_remarks:boolean=false;
es8_remarks:boolean=false;
es9_remarks:boolean=false;
es10_remarks:boolean=false;
list_remarks:boolean=false;
dateLast6Months:Date;
dateLast18Months:Date;
islast6Months: boolean;
islast18Months: boolean;
diffInMonths:any;
  currentUser: any;
  pipe = new DatePipe("en-US");
  remarksInput: any;
  applicationType: string;
  reminderLetterDetails: any;
  reminderLetterViewDetails: any;
  remiderLetterType: any;
  @ViewChild('reminderLetterViewDetails') scrollableDiv: ElementRef;
  @ViewChildren('reminderLetterViewDetails') dynamicScrollDiv: QueryList<ElementRef>;  
  @ViewChild('reminderLetterViewDetails1') myDiv: ElementRef;
  @ViewChild('responseLetterViewDetails1') myDiv1: ElementRef;
  responseLetterHeading: any;
  responseLetterViewDetails: any=[];
  singleResponseLetter: any;
  responseLetterViewDetailsExcel: any=[];
  singleResponseLetterFile: any;

  constructor(
    public blockchainService: BlockchainService,
     public dialog: MatDialog,
     private renderer: Renderer2
     ) { }


  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.authNo = localStorage.getItem("authno");
    this.getOMInformationByAuthNo(this.authNo);
  }

  ngAfterViewInit() {
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
  
  monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

test(d1, d2) {
    var diff = this.monthDiff(d1, d2);
    this.diffInMonths = diff;
    return this.diffInMonths;
    console.log(
        d1.toISOString().substring(0, 10),
        "to",
        d2.toISOString().substring(0, 10),
        ":",
        diff
    );
}

getOMInformationByAuthNo(auth){
  this.applicationType = localStorage.getItem('applicationType');
  let input = {
    auth_number: auth,
    applicationType: this.applicationType ? this.applicationType : 'AA'
  }
  let reminderLetterInput = {
    authNumber: auth,
  }
  this.blockchainService.getOMInformationByAuthNo(input).subscribe((resp) => {
    this.remarksInput = resp.body.obj;
    this.remarksData(this.remarksInput.application_id,this.remarksInput.cancellation_id);
    this.getAllOMRelaventInformationByAuthNo(auth);
    this.getReminderLetterData(reminderLetterInput);
  });
  
}  
getReminderLetterData(inputData){
  console.log(inputData);
  this.applicationType = localStorage.getItem('applicationType');
    this.blockchainService.getReminderLetterByAuthNumber(inputData).subscribe(
      (resp) => { 
         this.reminderLetterDetails=resp.body.obj;
        //  this.singleResponseLetter=this.reminderLetterDetails.response_Letter_File;
        //  console.log(this.singleResponseLetter);
        //   this.reminderLetterDetails=[
        //   {
        //     reminderLetterName:'1 MONTH PRE-EXPIRY',
        //     sentDate:24-11-2024,
        //     sentByName:'System',
        //     typeOfLetter:'Summons',
        //     htmlcode:'ff',
        //     attachmentPath:"test",
        //   },
        //   {
        //     reminderLetterName:'0 TO 1 MONTH',
        //     sentDate:12-9-2024,
        //     sentByName:'System',
        //     typeOfLetter:'ShowCause Notice',
			  //     attachmentPath:"test1",
        //     htmlcode:'fwsf',
        //   },
        //   {
        //     reminderLetterName:'1 TO 2 MONTHS',
        //     sentDate:12-9-2024,
        //     sentByName:'L1 Name',
        //     typeOfLetter:'Summons',
			  //     attachmentPath:"",
        //     htmlcode:'',
        //   },
        //   {
        //     reminderLetterName:'2 TO 6 MONTHS',
        //     sentDate:12-9-2024,
        //     sentByName:'L1 Name',
        //     typeOfLetter:'Summons',
			  //     attachmentPath:"",
        //   },
        //   {
        //     reminderLetterName:'6 TO 24 MONTHS',
        //     sentDate:12-9-2024,
        //     sentByName:'L1 Name',
        //     typeOfLetter:'Summons',
        //     attachmentPath:"test1",
        //   },
        //   {
        //     reminderLetterName:'6 TO 24 MONTHS',
        //     sentDate:12-9-2024, 
        //     sentByName:'L1 Name',
        //     typeOfLetter:'Summons',
        //     attachmentPath:""
        //   },
        //   {
        //     reminderLetterName:'MORE THAN 2 YEARS',
        //     sentDate:12-9-2024,
        //     sentByName:'L1 Name',
        //     typeOfLetter:'Summons',
			  //     attachmentPath:"test",
        //   },
          
        // ];
         console.log(this.reminderLetterDetails)
        // this.dataSource3 =this.reminderLetterDetails;
      })
}
openModalViewLetter(section) {
  console.log(section);
  this.remiderLetterType=section.typeOfLettter;
this.reminderLetterViewDetails = section.htmlcode;
// this.scrollableDiv.nativeElement.scrollTop = 0
console.log(this.reminderLetterViewDetails)
console.log(this.remiderLetterType)

const buttonModal = document.getElementById("openModalButton");
this.renderer.removeStyle(this.myDiv.nativeElement, 'scroll-snap-align');
  // buttonModal.click();
}
openModalViewMultiplePdf(section) {
  console.log(section);
  this.responseLetterViewDetails=[];
  this.responseLetterViewDetails.length=0;
  this.responseLetterViewDetailsExcel=[];
  this.responseLetterViewDetailsExcel.length=0;
  this.scrollFunctionForMultiplePdf();
  this.singleResponseLetterFile=section.response_Letter_File;
  this.responseLetterHeading="Response Letter";
  let array1= section.listOfPDF;
  console.log(this.singleResponseLetterFile);
  // let array1=["pdf1","pdf2","pdf3","pdf4","pdf5","pdf6","pdf7","pdf8","pdf9","pdf10"];
  for(let i=0;i<array1.length;i++){
    this.responseLetterViewDetails.push(array1[i])
  }
  // let array2=["pdf1","pdf2","pdf3","pdf4","pdf5","pdf6","pdf7","pdf8","pdf9","pdf10"];
  let array2= section.listOfEXCEL;
  for(let i=0;i<array2.length;i++){
    this.responseLetterViewDetailsExcel.push(array2[i])
  }
for(let i=0;i<this.responseLetterViewDetails.length;i++){
console.log(this.responseLetterViewDetails[i]);
}
 console.log(this.reminderLetterViewDetails)
console.log(this.remiderLetterType)

const buttonModal = document.getElementById("openModalButton");
// this.renderer.removeStyle(this.myDiv1.nativeElement, 'scroll-snap-align');
  // buttonModal.click();
}  
scrollFunction(){
  const myDiv = document.getElementById('reminderLetterViewDetails');
  const targetDiv: HTMLDivElement = document.getElementById("targetDiv") as HTMLDivElement;
    this.renderer.setStyle(this.myDiv.nativeElement, 'scroll-snap-align', 'start');
  console.log(myDiv);
  // console.log(this.scrollableDiv);

}
scrollFunctionForMultiplePdf(){
  const myDiv1 = document.getElementById('reminderLetterViewDetails');
  const targetDiv: HTMLDivElement = document.getElementById("targetDiv") as HTMLDivElement;
    this.renderer.setStyle(this.myDiv1.nativeElement, 'scroll-snap-align', 'start');
    this.responseLetterViewDetails=[];
    this.responseLetterViewDetails.length=0;
    this.responseLetterViewDetailsExcel=[];
    this.responseLetterViewDetailsExcel.length=0;
    console.log(this.responseLetterViewDetails);
  // console.log(myDiv1);
  // console.log(this.scrollableDiv);

}

  getAllOMRelaventInformationByAuthNo(auth){
    this.applicationType = localStorage.getItem('applicationType');
    let input = {
      auth_number: auth,
      applicationType: this.applicationType ? this.applicationType : 'AA'
    }
    this.blockchainService.getAllOMRelaventInformationByAuthNo(input).subscribe(
      (resp) => {
        this.getAllOmDetails = resp.body.obj;
      if(resp.body.obj != null){ 
       if(this.remarksInput.application_type=='AA'){
        this.dataSource = [
          {
            item:'COMPANY NAME',
            details:this.getAllOmDetails.company_name,
          },
          {
            item:'IEC NO',
            details:this.getAllOmDetails.iec_no,
          },
          {
            item:'CUSTOMS BROKER NAME',
            details:this.getAllOmDetails.customs_broker_name,
          },
          {
            item:'CUSTOMS BROKER REGISTRATON ID',
            details:this.getAllOmDetails.customs_broker_registation_id,
          },
          {
            item:'ADVANCE AUTHORIZATION NO',
            details:this.getAllOmDetails.aa_no,
          },
          {
            item:'ADVANCE AUTHORIZATION DATE',
            details:this.pipe.transform(this.getAllOmDetails.aa_date, "dd-MM-yyyy"),
          },
          {
            item:'DGFT SURRENDER LETTER NO',
            details:this.getAllOmDetails.dgft_surrender_letter_no,
          },
          {
            item:'DGFT SURRENDER LETTER ISSUE DATE',
            details:this.pipe.transform(this.getAllOmDetails.dgft_surrender_letter_date, "dd-MM-yyyy"),
          },
          {
            item:'EODC LETTER NO',
            details:this.getAllOmDetails.eodc_letter_no,
          },
          {
            item:'EODC LETTER DATE',
            details:this.pipe.transform(this.getAllOmDetails.eodc_letter_date, "dd-MM-yyyy"),
          },
          {
            item:'DGFT REGULARIZATION LETTER NO',
            details:this.getAllOmDetails.dgft_regularization_letter_no,
          },
          {
            item:'DGFT REGULARIZATION LETTER DATE',
            details:this.pipe.transform(this.getAllOmDetails.dgft_regularization_letter_date, "dd-MM-yyyy"),
          },
          {
            item:'CANCELLATION APPLICATION DATE',
            details:this.pipe.transform(this.getAllOmDetails.cancellation_application_date, "dd-MM-yyyy"),
          },
          {
            item:'ORIGINAL FILE NO',
            details:this.getAllOmDetails.originl_file_no,
          },
     
        ];
      }    
      else if(this.remarksInput.application_type=='EPCG'){
        this.dataSource = [
          {
            item:'COMPANY NAME',
            details:this.getAllOmDetails.company_name,
          },
          {
            item:'IEC NO',
            details:this.getAllOmDetails.iec_no,
          },
          {
            item:'CUSTOMS BROKER NAME',
            details:this.getAllOmDetails.customs_broker_name,
          },
          {
            item:'CUSTOMS BROKER REGISTRATON ID',
            details:this.getAllOmDetails.customs_broker_registation_id,
          },
          {
            item:'EPCG NO',
            details:this.getAllOmDetails.aa_no,
          },
          {
            item:'EPCG DATE',
            details:this.pipe.transform(this.getAllOmDetails.aa_date, "dd-MM-yyyy"),
          },
          {
            item:'DGFT SURRENDER LETTER NO',
            details:this.getAllOmDetails.dgft_surrender_letter_no,
          },
          {
            item:'DGFT SURRENDER LETTER ISSUE DATE',
            details:this.pipe.transform(this.getAllOmDetails.dgft_surrender_letter_date, "dd-MM-yyyy"),
          },
          {
            item:'EODC LETTER NO',
            details:this.getAllOmDetails.eodc_letter_no,
          },
          {
            item:'EODC LETTER DATE',
            details:this.pipe.transform(this.getAllOmDetails.eodc_letter_date, "dd-MM-yyyy"),
          },
          {
            item:'DGFT REGULARIZATION LETTER NO',
            details:this.getAllOmDetails.dgft_regularization_letter_no,
          },
          {
            item:'DGFT REGULARIZATION LETTER DATE',
            details:this.pipe.transform(this.getAllOmDetails.dgft_regularization_letter_date, "dd-MM-yyyy"),
          },
          {
            item:'CANCELLATION APPLICATION DATE',
            details:this.pipe.transform(this.getAllOmDetails.cancellation_application_date, "dd-MM-yyyy"),
          },
          {
            item:'ORIGINAL FILE NO',
            details:this.getAllOmDetails.originl_file_no,
          },
     
        ];
      }
         var newDate:any = new Date();
         var date:any = new Date(this.getAllOmDetails.aa_date);
         var diffDays:any = Math.floor((newDate - date) / (1000 * 60 * 60 * 24));
        //  var diff = this.monthDiff(newDate, date);
         var diff = this.monthDiff(date, newDate);

         this.diffInMonths = diff;
         console.log(this.diffInMonths,'difference in months');

         if(this.getAllOmDetails.installation_certificate == null){
          if(this.diffInMonths>=6){
            this.islast6Months = true;
          }else{
            this.islast6Months = false;
          }

          if(this.diffInMonths>=18){
            this.islast18Months = true;
          }else{
            this.islast18Months = false;
          }
         }

       
       
        this.dataSource1 =[
          {
            item:'IEC COPY',
            pdf:this.getAllOmDetails.iec_letter_path,
            remarks:this.iec_remarks
            
          },
          {
            item:'ADVANCED AUTHORIZATION',
            pdf:this.getAllOmDetails.aa_path,
            remarks:this.aa_remarks
            
          },
          {
            item:'BOND',
            pdf:this.getAllOmDetails.bond_path,
            remarks:this.bond_remarks
            
          },
          {
            item:'STAR EXPORT HOUSE COPY',
            pdf:this.getAllOmDetails.start_export_house_copy_path,
            remarks:this.star_remarks
          },
          {
            item:'GST REGISTRATION COPY',
            pdf:this.getAllOmDetails.gst_registration_copy_path,
            remarks:this.gst_remarks
          },
          {
            item:'LICENCE SCRIPT',
            pdf:this.getAllOmDetails.license_script_path,
            remarks:this.ls_remarks
          },
          {
            item:'POWER OF ATTORNEY',
            pdf:this.getAllOmDetails.power_of_attorney_path,
            remarks:this.poa_remarks
          },
          {
            item:'AFFADAVIT',
            pdf:this.getAllOmDetails.affadavit_path,
            remarks:this.aff_remarks
          },
          {
            item:'RCMC',
            pdf:this.getAllOmDetails.rcmc_path,
            remarks:this.rcmc_remarks
          },
          {
            item:'TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)',
            pdf:this.getAllOmDetails.turnover_certificate_path,
            remarks:this.tc_remarks
          },
          {
            item:'IDENTITY PROOF - POA',
            pdf:this.getAllOmDetails.identity_proof_path,
            remarks:this.ipod_remarks
          },
          
        ];
        this.dataSource2 =[
          {
            item:'APPLICATION LETTER',
            pdf:this.getAllOmDetails.application_letter_path,
            remarks:this.aL_remarks
          },
          {
            item:'EXPORT OBLIGATION DISCHARGE CERTIFICATE',
            pdf:this.getAllOmDetails.dgft_eodc_certificate_path,
            remarks:this.eodc_remarks
          },
          {
            item:'SURRENDER LETTER',
            pdf:this.getAllOmDetails.surrender_letter_path,
            remarks:this.sL_remarks
          },
          {
            item:'REGULARIZATION LETTER',
            pdf:this.getAllOmDetails.regularization_letter_path,
           remarks:this.rg_remarks
          },
          {
            item:'LIST OF OTHER DOCUMENTS BEING UPLOADED ',
            pdf:this.getAllOmDetails.list_of_other_document_path,
            remarks:this.list_remarks
          },
          {
            item:'OTHER DOCUMENTS 1',
            pdf:this.getAllOmDetails.other_documents1_path,
            remarks:this.od1_remarks
          },
          {
            item:'OTHER DOCUMENTS 2',
            pdf:this.getAllOmDetails.other_documents2_path,
            remarks:this.od2_remarks
          },
          {
            item:'OTHER DOCUMENTS 3',
            pdf:this.getAllOmDetails.other_documents3_path,
            remarks:this.od3_remarks
          },
          {
            item:'OTHER DOCUMENTS 4',
            pdf:this.getAllOmDetails.other_documents4_path,
            remarks:this.od4_remarks
          },
          {
            item:'OTHER DOCUMENTS 5',
            pdf:this.getAllOmDetails.other_documents5_path,
            remarks:this.od5_remarks
          },
          {
            item:'OTHER DOCUMENTS 6',
            pdf:this.getAllOmDetails.other_documents6_path,
            remarks:this.od6_remarks
          },
          {
            item:'OTHER DOCUMENTS 7',
            pdf:this.getAllOmDetails.other_documents7_path,
            remarks:this.od7_remarks
          },
          {
            item:'OTHER DOCUMENTS 8',
            pdf:this.getAllOmDetails.other_documents8_path,
            remarks:this.od8_remarks
          },
          {
            item:'OTHER DOCUMENTS 9',
            pdf:this.getAllOmDetails.other_documents9_path,
            remarks:this.od9_remarks
          },
          {
            item:'OTHER DOCUMENTS 10',
            pdf:this.getAllOmDetails.other_documents10_path,
            remarks:this.od10_remarks
          },
          {
            item:'EXCEL SHEET 1',
            pdf:this.getAllOmDetails.excel_sheet1_path,
            remarks:this.es1_remarks
          },
          {
            item:'EXCEL SHEET 2',
            pdf:this.getAllOmDetails.excel_sheet2_path,
            remarks:this.es2_remarks
          },
          {
            item:'EXCEL SHEET 3',
            pdf:this.getAllOmDetails.excel_sheet3_path,
            remarks:this.es3_remarks
          },
          {
            item:'EXCEL SHEET 4',
            pdf:this.getAllOmDetails.excel_sheet4_path,
            remarks:this.es4_remarks
          },
          {
            item:'EXCEL SHEET 5',
            pdf:this.getAllOmDetails.excel_sheet5_path,
            remarks:this.es5_remarks
          },
          {
            item:'EXCEL SHEET 6',
            pdf:this.getAllOmDetails.excel_sheet6_path,
            remarks:this.es6_remarks
          },
          {
            item:'EXCEL SHEET 7',
            pdf:this.getAllOmDetails.excel_sheet7_path,
            remarks:this.es7_remarks
          },
          {
            item:'EXCEL SHEET 8',
            pdf:this.getAllOmDetails.excel_sheet8_path,
            remarks:this.es8_remarks
          },
          {
            item:'EXCEL SHEET 9',
            pdf:this.getAllOmDetails.excel_sheet9_path,
            remarks:this.es9_remarks
          },
          {
            item:'EXCEL SHEET 10',
            pdf:this.getAllOmDetails.excel_sheet10_path,
            remarks:this.es10_remarks
          },
          
        ];

        // this.dataSource3 =[
        //   {
        //     item:'1 MONTH PRE-EXPIRY',
        //     date:this.pipe.transform(this.getAllOmDetails.one_month_pre_expiry_date, "dd-MM-yyyy"),
        //     sentBy:'System'
        //   },
        //   {
        //     item:'0 TO 1 MONTH',
        //     date:this.pipe.transform(this.getAllOmDetails.zero_to_one_month_date, "dd-MM-yyyy"),
        //     sentBy:'System'
        //   },
        //   {
        //     item:'1 TO 2 MONTHS',
        //     date:this.pipe.transform(this.getAllOmDetails.one_to_two_months_date, "dd-MM-yyyy"),
        //     sentBy:'L1 Name'
        //   },
        //   {
        //     item:'2 TO 6 MONTHS',
        //     date:this.pipe.transform(this.getAllOmDetails.two_to_six_monthS_date, "dd-MM-yyyy"),
        //     sentBy:'L1 Name'
        //   },
        //   {
        //     item:'6 TO 24 MONTHS',
        //     date:this.pipe.transform(this.getAllOmDetails.six_to_24_months_date, "dd-MM-yyyy"),
        //     sentBy:'L1 Name'
        //   },
        //   {
        //     item:'MORE THAN 2 YEARS',
        //     date:this.pipe.transform(this.getAllOmDetails.more_than_2_years_date, "dd-MM-yyyy"),
        //     sentBy:'L1 Name'
        //   },
          
        // ];
        this.dataSource4 =[
          {
            item:'EXPORT VALIDITY DATE',
            details:this.pipe.transform(this.getAllOmDetails.export_validity_date, "dd-MM-yyyy"),
            inputBy:''
          },
          {
            item:'BOND AMOUNT INR',
            details:this.getAllOmDetails.bond_amount_by_inr,
            inputBy:''
          },
          {
            item:'SHOW CAUSE NOTICE NO',
            details:this.getAllOmDetails.show_cause_notice_no,
            inputBy:''
          },
          {
            item:'SHOW CAUSE NOTICE DATE',
            details:this.pipe.transform(this.getAllOmDetails.show_cause_notice_date, "dd-MM-yyyy"),
            inputBy:''
          },
          {
            item:'AMOUNT DEMANDED',
            details:this.getAllOmDetails.amount_demanded,
            inputBy:''
          },
          {
            item:'ADJUDICATION DATE',
            details:this.pipe.transform(this.getAllOmDetails.adj_date, "dd-MM-yyyy"),
            inputBy:''
          },
          {
            item:'ADJUDICATION ORDER NUMBER',
            details:this.getAllOmDetails.adj_order_no,
            inputBy:''
          },
          {
            item:'TOTAL AMOUNT DUE',
            details:this.getAllOmDetails.total_amount_due,
            inputBy:''
          },
        ];

        if(this.remarksInput.application_type=='AA'){
        this.dataSource5 = [
          // {
          //   item: 'INSTALLATION CERTIFICATE',
          //   pdf: this.getAllOmDetails.installation_certificate_path,
          // },
          // {
          //   item: 'MID TERM REVIEW CERTIFICATE',
          //   pdf: this.getAllOmDetails.mid_term_certificate_path,
          // },
          {
            item: 'EODC APPLICATION DOCUMENT',
            pdf: this.getAllOmDetails.eodc_document_path,
          },
          {
            item: 'EODC REDEMPTION LETTER BY DGFT',
            pdf: this.getAllOmDetails.eodc_redemption_path,
          },
          {
            item: 'EODC REJECTION LETTER BY DGFT',
            pdf: this.getAllOmDetails.eodc_rejection_path,
          },
          {
            item: 'OTHER DOCUMENTS',
            pdf: this.getAllOmDetails.others_letters_path,
          },
          {
            item: 'EODC RECEIPT UPDATED BY CUSTOMS',
            pdf: this.pipe.transform(this.getAllOmDetails.eodc_redemption_notice_date, "dd-MM-yyyy"),
          },
        ]

        }else if(this.remarksInput.application_type=='EPCG'){
          this.dataSource5 = [
            {
              item: 'INSTALLATION CERTIFICATE',
              pdf: this.getAllOmDetails.installation_certificate_path,
            },
            {
              item: 'MID TERM REVIEW CERTIFICATE',
              pdf: this.getAllOmDetails.mid_term_certificate_path,
            },
            {
              item: 'EODC APPLICATION DOCUMENT',
              pdf: this.getAllOmDetails.eodc_document_path,
            },
            {
              item: 'EODC REDEMPTION LETTER BY DGFT',
              pdf: this.getAllOmDetails.eodc_redemption_path,
            },
            {
              item: 'EODC REJECTION LETTER BY DGFT',
              pdf: this.getAllOmDetails.eodc_rejection_path,
            },
            {
              item: 'OTHER DOCUMENTS',
              pdf: this.getAllOmDetails.others_letters_path,
            },
            {
              item: 'EODC RECEIPT UPDATED BY CUSTOMS',
              pdf: this.pipe.transform(this.getAllOmDetails.eodc_redemption_notice_date, "dd-MM-yyyy"),
            },
          ]
        }
      }
      })
  }

  remarksData(appId,canId){
    this.applicationType = localStorage.getItem('applicationType');
      let input = {
        application_id: appId,
        cancellation_id:canId,
        applicationType: this.applicationType ? this.applicationType : 'AA'
      }
      this.blockchainService.getDocumentsRemarks(input).subscribe(
        (resp) => {
         
          this.remarksByLetter=resp.body.obj;
          // console.log(this.remarksByLetter)
          this.remarksByLetter.forEach(element => {
            // console.log(element)
            if(element.doc_name=='IEC COPY'){
              if(element.remarks!=null){
                
                this.iec_remarks=true;
               
              }
            }
            else if(element.doc_name=="ADVANCED AUTHORIZATION"){
              if(element.remarks!=null){
                this.aa_remarks=true
              }
            }
            
            else if(element.doc_name=="BOND"){
              if(element.remarks!=null){
                this.bond_remarks=true
              }
            }
            else if(element.doc_name=="STAR EXPORT HOUSE COPY"){
              if(element.remarks!=null){
                this.star_remarks=true
              }
            }
            else if(element.doc_name=="BANK GUARANTEE"){
              if(element.remarks!=null){
                this.bg_remarks=true
              }
            }
            else if(element.doc_name=="GST REGISTRATION COPY"){
              if(element.remarks!=null){
                this.gst_remarks=true
              }
            }
            else if(element.doc_name=="LICENCE SCRIPT"){
              if(element.remarks!=null){
                this.ls_remarks=true
              }
            }
            else if(element.doc_name=="POWER OF ATTORNEY"){
              if(element.remarks!=null){
                this.poa_remarks=true
              }
            }
             else if(element.doc_name=="AFFADAVIT"){
              if(element.remarks!=null){
                this.aff_remarks=true
              }
            }
            else if(element.doc_name=="RCMC"){
              if(element.remarks!=null){
                this.rcmc_remarks=true
              }
            }
            else if(element.doc_name=="TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)"){
              if(element.remarks!=null){
                this.tc_remarks=true
              }
            }
            else if(element.doc_name=="IDENTITY PROOF - POA"){
              if(element.remarks!=null){
                this.ipod_remarks=true
              }
            }else if(element.doc_name=="EXPORT PROMOTION CAPITAL GOODS SCHEME"){
              if(element.remarks!=null){
                this.epcg_remarks=true
              }
            }if(element.doc_name=="APPLICATION LETTER"){
              if(element.remarks!=null){
                this.aL_remarks=true
              }
            }else if(element.doc_name=="EXPORT OBLIGATION DISCHARGE CERTIFICATE"){
              if(element.remarks!=null){
                this.eodc_remarks=true
              }
            }else if(element.doc_name=="SURRENDER LETTER"){
              if(element.remarks!=null){
                this.sL_remarks=true
              }
            }else if(element.doc_name=="REGULARIZATION LETTER"){
              if(element.remarks!=null){
                this.rg_remarks=true
              }
            }else if(element.doc_name=="OTHER DOCUMENTS 1"){
              if(element.remarks!=null){
                this.od1_remarks=true
              }
            }else if(element.doc_name=="OTHER DOCUMENTS 2"){
              if(element.remarks!=null){
                this.od2_remarks=true
              }
            }else if(element.doc_name=="OTHER DOCUMENTS 3"){
              if(element.remarks!=null){
                this.od3_remarks=true
              }
            }else if(element.doc_name=="OTHER DOCUMENTS 4"){
              if(element.remarks!=null){
                this.od4_remarks=true
              }
            }else if(element.doc_name=="OTHER DOCUMENTS 5"){
              if(element.remarks!=null){
                this.od5_remarks=true
              }
            }else if(element.doc_name=="OTHER DOCUMENTS 6"){
              if(element.remarks!=null){
                this.od6_remarks=true
              }
            }else if(element.doc_name=="OTHER DOCUMENTS 7"){
              if(element.remarks!=null){
                this.od7_remarks=true
              }
            }else if(element.doc_name=="OTHER DOCUMENTS 8"){
              if(element.remarks!=null){
                this.od8_remarks=true
              }
            }else if(element.doc_name=="OTHER DOCUMENTS 9"){
              if(element.remarks!=null){
                this.od9_remarks=true
              }
            }else if(element.doc_name=="OTHER DOCUMENTS 10"){
              if(element.remarks!=null){
                this.od10_remarks=true
              }
            }else if(element.doc_name=="EXCEL SHEET 1"){
              if(element.remarks!=null){
                this.es1_remarks=true
              }
            }else if(element.doc_name=="EXCEL SHEET 2"){
              if(element.remarks!=null){
                this.es2_remarks=true
              }
            }else if(element.doc_name=="EXCEL SHEET 3"){
              if(element.remarks!=null){
                this.es3_remarks=true
              }
            }else if(element.doc_name=="EXCEL SHEET 4"){
              if(element.remarks!=null){
                this.es4_remarks=true
              }
            }else if(element.doc_name=="EXCEL SHEET 5"){
              if(element.remarks!=null){
                this.es5_remarks=true
              }
            }else if(element.doc_name=="EXCEL SHEET 6"){
              if(element.remarks!=null){
                this.es6_remarks=true
              }
            }else if(element.doc_name=="EXCEL SHEET 7"){
              if(element.remarks!=null){
                this.es7_remarks=true
              }
            }else if(element.doc_name=="EXCEL SHEET 8"){
              if(element.remarks!=null){
                this.es8_remarks=true
              }
            }else if(element.doc_name=="EXCEL SHEET 9"){
              if(element.remarks!=null){
                this.es9_remarks=true
              }
            }else if(element.doc_name=="EXCEL SHEET 10"){
              if(element.remarks!=null){
                this.es10_remarks=true
              }
            }else if(element.doc_name=="LIST OF OTHER DOCUMENTS BEING UPLOADED "){
              if(element.remarks!=null){
                this.list_remarks=true
              }
            }
          });
        })
  }

  openRemarks(data) {
  
     this.remarksByLetter.forEach(remrk=>{
    if(remrk.doc_name==data.item){
      if(remrk.remarks != ""){
      this.openDialog(
        "paymentSum",
        remrk.remarks,
        ""
      );
    }else{
      this.openDialog(
        "paymentSum",
        "No Remarks",
        ""
      );
    }
  }

  })



  }

  openPdf(data) {
    console.log(data);
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result.event === "paymentSum" && result.isUpdated === true) {
        this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }


  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
