import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from "@angular/common";
import { DateAdapter } from "@angular/material/core";
@Component({
  selector: 'app-casefile-block-data',
  templateUrl: './casefile-block-data.component.html',
  styleUrls: ['./casefile-block-data.component.css']
})
export class CasefileBlockDataComponent implements OnInit {
  searchCaseData: any;
  searchCaseFlag: any;
  caseDetailsDataMessage: any;
  showViewDetailsBtn: boolean;
  caseDetailsData: any;

  constructor(private router: Router,private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
   }

  ngOnInit(): void {
    localStorage.removeItem('casedetailsData');
    this.caseDetailsDataMessage = JSON.parse(localStorage.getItem('searchCaseDataMessage'));
    this.searchCaseFlag = localStorage.getItem("searchCase");
    this.caseDetailsData = localStorage.getItem("casedetailsData");
    if(this.caseDetailsDataMessage=="You do not have permissions to view this application details."){
      this.showViewDetailsBtn=false;
    }
    else{
      this.showViewDetailsBtn=true;
    }
    console.log(this.caseDetailsDataMessage);
    console.log(this.showViewDetailsBtn);
    if(this.searchCaseFlag=="DataId"){
      this.searchCaseData = JSON.parse(localStorage.getItem("searchCaseDataId"));
    }else{
      this.searchCaseData = JSON.parse(localStorage.getItem("searchCaseData"));
    }
    console.log(this.searchCaseData);
  
  }
  viewGreenNoteData(data){
    console.log(data);
    localStorage.setItem('casedetailsData',JSON.stringify(data));
    // this.router.navigate(['/greenSheet']);
    this.router.navigate(['searchCaseNotesheet']);
  }
  viewGreenNote(data){
    console.log(data);
    localStorage.setItem('casedetailsData',JSON.stringify(data));
    this.router.navigate(['/greenSheet']);
    // this.router.navigate(['searchCaseNotesheet']);
  }
  viewCancellationGreenNoteData(data){
    console.log(data);
    localStorage.setItem('casedetailsData',JSON.stringify(data));
    this.router.navigate(['searchCaseNotesheet']);
  }
  back(){
    this.router.navigate(['/searchCase']);
  }
}
