import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import {  FormBuilder,  FormGroup } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { is } from "date-fns/locale";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DateAdapter } from "@angular/material/core";
import { AlertService } from "app/_services";
declare var $: any;

@Component({
  selector: 'app-cancel-auth',
  templateUrl: './cancel-auth.component.html',
  styleUrls: ['./cancel-auth.component.css']
})
export class CancelAuthComponent implements OnInit {
  isLinear = true;
  firstFormGroup: UntypedFormGroup;  
  secondFormGroup: UntypedFormGroup;  
  tabIndex = 0 ;
  isGreen:boolean = false;
  isApprove:boolean = false;
  isReject:boolean = false;
  isDraft:boolean = false;
  qType="draft";
  addGreenForm = this.formBuilder.group({
    noteDate: [""],
    name:[""],
    remarks:["",Validators.required],
    designation:[""],
    cancellation_id:[""],
    application_id:[""],
    attached_document : [""],
    html_flag:[""],
  });
  saveApprovalForm = this.formBuilder.group({
    draft_letter_approval:["",Validators.required],
    cancellation_id:[""]
  });
  saveRejectForm = this.formBuilder.group({
    draft_letter_reject:["",Validators.required],
    cancellation_id:[""]
  });
  updateRegisterForm = this.formBuilder.group({
   cancellation_id:[""],
   register1_name:[""],
   register1_serial_no:[""],
   register2_name:[""],
   register2_serial_no:[""]
  });
  bondAndBbgForm = this.formBuilder.group({
    bond_no:[""],
    bbg_cell_location:[""],
    cancellation_id:[""]
  });
  acceptJobForm = this.formBuilder.group({
    job_no:[""],
    cancellation_id:[""]
  })
  saveDraftForm = this.formBuilder.group({
    draft_letter:["",Validators.required],
    cancellation_id:[""]
  })
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;

  currentUser: any;
  caseDetailsData: any;
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  date: any;
  noteDate: any;
  acceptJob: any;
  isDocument:boolean = false;
  selectedFile: File;
  selectedFile1: File;
  url: string | ArrayBuffer;
  htmlContent = '';
  draft_letter_approval='';
  draft_letter_reject='';
  getDraftData: any;
  todayDate1= this.pipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");
  attached_document: File;
  draft_letter_status: number;
  stages: string;
  currentStep:any;
  offsetData: any;
  greenSheetDraftId: any;
  attachDocPath: any;
  getDraftDataFor1: any;
  userName: any;

  constructor(private formBuilder: UntypedFormBuilder, public dialog: MatDialog,
    public blockchainService: BlockchainService,private router: Router, private alertService: AlertService,  
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
      this.offsetData = JSON.parse(localStorage.getItem("offset"));
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if(this.currentUser.obj.level !== null){
      if(this.currentUser.obj.level == "L1"){
        this.draft_letter_status = 0
      } else{
        this.draft_letter_status = 1
      }
    }
    this.stages=localStorage.getItem("stages");
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));
    this.addGreenForm.controls['noteDate'].setValue(this.todayDate);
    this.firstFormGroup = this.formBuilder.group({  
      firstCtrl: ['', Validators.required]  
    });  
    this.secondFormGroup = this.formBuilder.group({  
      secondCtrl: ['', Validators.required]  
    });  
    this.isGreen = false;
    this.isApprove = false;
    this.isReject = false;
    this.isDraft = false;
    this.isDocument = false;
    if(this.currentUser.obj.level=='L2'){
      this.getDraftLetterByCancellationId(this.caseDetailsData.cancellation_id)
     }
    if(this.stages=="Issue Cancellation"||this.stages=="Validate"||this.stages=="Initiate"){
      //this.stepper.selectedIndex=0
      this.isLinear = false;
      this.currentStep=0
      this.isLinear = true;
  
    }else if(this.stages=="Update Register"){
     // this.stepper.selectedIndex=1
     this.isLinear = false;
      this.currentStep=1 
      this.isLinear = true;
    }
    else if(this.stages=="Document Return"){
      this.isLinear = false;
     this.currentStep=2
      this.isLinear = true;
    }
     
  }  
  
  omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
}

  changeTab(event){
    this.tabIndex = event.index;
  }

  addGreenNote(){
    this.isGreen = true;
  }
  saveGreenNote(){
    this.addGreenForm.controls['designation'].setValue('');
    this.addGreenForm.controls['name'].setValue(this.userName);
    this.addGreenForm.controls['cancellation_id'].setValue(this.caseDetailsData.cancellation_id);
    this.addGreenForm.controls['application_id'].setValue("0");
    if(this.noteDate==undefined){
      this.addGreenForm.controls['noteDate'].setValue(this.todayDate1);
    }else{
      this.noteDate=this.pipe.transform(this.noteDate, "yyyy-MM-dd HH:mm:ss");
      this.addGreenForm.controls['noteDate'].setValue(this.noteDate);
    }
    this.addGreenForm.controls['attached_document'].setValue(null);
    this.addGreenForm.controls['html_flag'].setValue(0);

    this.blockchainService.saveGreenSheet(this.addGreenForm.value).subscribe(
      (resp) => {
        if(resp.status == 200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Green Note Added Successfully",
            "announcement"
          );
        }
         this.addGreenForm.reset();
         setTimeout(() => {
          this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
         }, 1500);
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          // location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          // location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }

  sectionViewLetterDetails:any;
  openModalViewLetter(section) {
    // console.log(section.letter);
    this.sectionViewLetterDetails = section;
    // console.log(this.sectionViewLetterDetails)
    const buttonModal = document.getElementById("openModalButton");
    // buttonModal.click();
  }

  back(){
    this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
  }
  addApproveRemarks(){
    this.isApprove = true;
  }

  saveApprovalRemarks(){
    let input = {
      cancellation_approval_rejection_remarks:this.saveApprovalForm.value.draft_letter_approval,
      cancellation_id:this.caseDetailsData.cancellation_id,
    }
    this.blockchainService.saveCancellationApprovestatus(input).subscribe(
      (resp) => {
        console.log(resp.body.obj);
        if(resp.status == 200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Approval Remarks Added Successfully",
            "announcement"
          );
        }
         this.saveApprovalForm.reset();
        //  location.reload();
         setTimeout(() => {
          this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
         }, 1500);
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          // location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          // location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });

      let input1 ={
        designation:'',
        name:this.userName,
        noteDate:this.todayDate1,
        remarks:'Approval_Remarks :'+this.saveApprovalForm.value.draft_letter_approval,
        cancellation_id:this.caseDetailsData.cancellation_id,
        application_id:"0",
        attached_document:null,
        html_flag:0
      }
      this.blockchainService.saveGreenSheet(input1).subscribe(
        (resp) => {
          console.log(resp);
        })
  }

  addRejectRemarks(){
    this.isReject = true;
  }

  saveRejectRemarks(){
    let input = {
      cancellation_approval_rejection_remarks:this.saveRejectForm.value.draft_letter_reject,
      cancellation_id:this.caseDetailsData.cancellation_id,
    }
    // this.addRejectForm.controls['application_id'].setValue(this.caseDetailsData.application_id);
    this.blockchainService.saveCancellationRejectstatus(input).subscribe(
      (resp) => {
        console.log(resp.body.obj);
        if(resp.status == 200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Reject Remarks Added Successfully",
            "announcement"
          );
        }
         this.saveRejectForm.reset();
         setTimeout(() => {
          this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
        }, 1500);
  
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          // location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          // location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
      
      let input1 ={
        designation:'',
        name:this.userName,
        noteDate:this.todayDate1,
        remarks:'Rejection_Remarks :'+this.saveRejectForm.value.draft_letter_reject,
        cancellation_id:this.caseDetailsData.cancellation_id,
        application_id:"0",
        attached_document:null,
        html_flag:0
      }
      this.blockchainService.saveGreenSheet(input1).subscribe(
        (resp) => {
          console.log(resp);
        })
  }
  addDraft(){
    this.isDraft = true;
    this.isDocument =  false;
  }

  addDocument(){
    this.isDocument = true;
    this.isDraft = false;
  }
  getDraftLetterByCancellationId(cancellation_id){
    let input = {
      cancellation_id:cancellation_id
    }
    this.blockchainService.getDraftLetterByCancellationId(input).subscribe(
      (resp) => {
       console.log(resp)
       this.getDraftData = resp.body.obj;
      //  this.getDraftDataFor1 = resp.body.obj;
         if (this.getDraftData) {
       if (this.getDraftData.length != 0) {
        this.getDraftDataFor1 = true
        this.getDraftData.forEach(e2 => {
          this.isDraft = true;
          this.saveDraftForm.controls['cancellation_id'].setValue(e2.cancellation_id);
          this.htmlContent = e2.draft_letter;
          this.attachDocPath = e2.attached_document_path;
          this.userName=e2.name;
        })
      }
      }
    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        location.reload();
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
     
    }); 

  }
  discardDraft(){
    let input = {
      cancellation_id:this.caseDetailsData.cancellation_id,
      draft_letter_status:"2"
    }
    this.blockchainService.updateDraftLetterDiscardStatus(input).subscribe(
      (resp) => {
       console.log(resp)
       if (this.getDraftData != null) {
       this.getDraftData = resp.body.obj;
       this.saveDraftForm.controls['cancellation_id'].setValue(this.getDraftData.cancellation_id);
       this.htmlContent =this.getDraftData.draft_letter;
       this.htmlContent = '';
       }
       else{
        this.htmlContent = '';
      }
    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        // location.reload();
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
     
    }); 

  }
  changeqTypeDraft(){
  this.qType="draft";
  console.log("draft1",this.qType);
  }
  changeqTypeQuery(){
    this.qType="query";
    console.log("query1",this.qType);
    }
  saveDraft(query_type :any){
    query_type=this.qType;
    console.log(query_type);
    if(this.userName==undefined){
      this.userName="";
    }
    console.log(this.userName);
    if (this.currentUser.obj.level == 'L2') {
      if(this.getDraftData){
        console.log(this.getDraftData);
      if (this.getDraftData.length != 0) {
        this.getDraftData.forEach(e1 => {
          if (e1.cancellation_id == this.caseDetailsData.cancellation_id) {
            this.greenSheetDraftId = e1.green_sheet_draft_letter_id;
            this.attachDocPath = e1.attached_document_path;
          }
        })
      }
    }
    }
    if (this.currentUser.obj.level == 'L1'){
   
      
    let input = {
      draft_letter:this.htmlContent,
      attached_document:this.selectedFile,
      cancellation_id:this.caseDetailsData.cancellation_id,
      application_id:0,
      draft_letter_status:this.draft_letter_status,
      is_approved: 0,
      query_type : query_type
    }
    console.log(input)
    this.blockchainService.saveDraftLetter(input).subscribe(
      (resp) => {
        console.log(resp.body.obj);
        if(resp.status == 200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            // "Draft Added Successfully",
            "announcement"
          );
        }
         this.saveDraftForm.reset();
         setTimeout(() => {
          this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
        }, 1500);
  
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          // location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          // location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
    }else if(this.currentUser.obj.level == 'L2' && !this.getDraftData){
    // }else if(this.currentUser.obj.level == 'L2' && this.getDraftData.length == 0){
      let input = {
        draft_letter: this.htmlContent,
        attached_document: this.selectedFile,
        application_id: 0,
        draft_letter_status: this.draft_letter_status,
        cancellation_id: this.caseDetailsData.cancellation_id,
        is_approved: 1,
        query_type : query_type
      }
      this.blockchainService.saveDraftLetter(input).subscribe(
        (resp) => {
          // console.log(resp.body.obj);
          if (resp.body.status == 200) {
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );
            this.saveDraftForm.reset();
            setTimeout(() => {
              this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
            }, 1500);
          } else {
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );
          }


        }, (error) => {
          console.log(error)
          this.alertService.error(error);
          if (error.status == 401) {
            setTimeout(() => {
              // location.reload();
              this.blockUI.stop();
            }, 500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Session Expired, Please Login Again",
              "announcement"
            );
            this.router.navigate(["/login"]);
          }
          else if (error.status == 500) {
            setTimeout(() => {
              // location.reload();
              this.blockUI.stop();
            }, 500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Try Again",
              "announcement"
            );
          }
          else { }

        });

    }else{
      if(this.selectedFile==undefined && this.attachDocPath!=null){
        let input = {
          draft_letter:this.htmlContent,
          application_id:0,
          cancellation_id:this.caseDetailsData.cancellation_id,
         
          attached_document_path:this.attachDocPath,
          green_sheet_draft_letter_id:this.greenSheetDraftId,
          is_approved:1,
          query_type : query_type
        }
        this.blockchainService.updateDraftLetter(input).subscribe(
          (resp) => {
            console.log(resp.body.obj);
            if(resp.status == 200){
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.body.message,
                // "Draft Added Successfully",
                "announcement"
              );
            }
             this.saveDraftForm.reset();
             setTimeout(() => {
              this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
            }, 1500);
      
          }, (error) => {
            console.log(error)
            this.alertService.error(error);
            if(error.status == 401){
              // location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Session Expired, Please Login Again",
                "announcement"
              );
              this.router.navigate(["/login"]);
            }
            else if(error.status == 500){
              // location.reload();
              setTimeout(() => {
                this.blockUI.stop();
              }, 500);
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Try Again",
                "announcement"
              );
            }
            else{}
           
          });
      }  else{
        let input = {
        draft_letter:this.htmlContent,
        attached_document:this.selectedFile,
        application_id:0,
        cancellation_id:this.caseDetailsData.cancellation_id,
        green_sheet_draft_letter_id:this.greenSheetDraftId,
        is_approved:1,
        query_type : query_type
      }
      this.blockchainService.updateDraftLetter(input).subscribe(
        (resp) => {
          console.log(resp.body.obj);
          if(resp.status == 200){
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              // "Draft Added Successfully",
              "announcement"
            );
          }
           this.saveDraftForm.reset();
           setTimeout(() => {
            this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
          }, 1500);
    
        }, (error) => {
          console.log(error)
          this.alertService.error(error);
          if(error.status == 401){
            // location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Session Expired, Please Login Again",
              "announcement"
            );
            this.router.navigate(["/login"]);
          }
          else if(error.status == 500){
            // location.reload();
            setTimeout(() => {
              this.blockUI.stop();
            }, 500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Try Again",
              "announcement"
            );
          }
          else{}
         
        });
    }
    }

    if(this.currentUser.obj.level=='L2'){
      let input1 ={
        designation:'',
        name:this.userName,
        noteDate:this.todayDate1,
        remarks:'Query_Remarks :'+this.htmlContent,
        application_id:"0",
        cancellation_id:this.caseDetailsData.cancellation_id,
        attached_document:this.selectedFile,
        // attached_document_path:this.getDraftData.attached_document_path,
        attached_document_path:this.attachDocPath,
        html_flag:1,
        query_type : query_type
      }
      this.blockchainService.saveGreenSheet(input1).subscribe(
        (resp) => {
          console.log(resp);
        })
      }
  }

  updateRegisterSave(){
    // console.log("Hii");
    let input = {
      register1_name:this.updateRegisterForm.value.register1_name,
      register1_serial_no:this.updateRegisterForm.value.register1_serial_no,
      register2_name:this.updateRegisterForm.value.register2_name,
      register2_serial_no:this.updateRegisterForm.value.register2_serial_no,
      cancellation_id:this.caseDetailsData.cancellation_id,
    }
    // this.createJobForm.controls['application_id'].setValue(this.caseDetailsData.application_id);
    this.blockchainService.updateCancellationRegisterStatus(input).subscribe(
      (resp) => {
        // console.log(resp.status);
        if(resp.status == 200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Updated Successfully",
            "announcement"
          );
        }
         this.updateRegisterForm.reset();
         setTimeout(() => {
          this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
         }, 1500);
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          // location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          // location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }

  saveBondBbg(){
    this.bondAndBbgForm.controls['application_id'].setValue(this.caseDetailsData.application_id);
    this.blockchainService.saveAARegistrationBondNoAndBbg(this.bondAndBbgForm.value).subscribe(
      (resp) => {
        // console.log(resp.status);
        if(resp.status == 200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Data Saved Successfully",
            "announcement"
          );
        }
         this.bondAndBbgForm.reset();
         setTimeout(() => {
          this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
         }, 1500);
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          // location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          // location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });

  }
  getAARegistrationJobNumberByApplicationId(input){

    let application = {
      application_id: input
   }
   this.blockchainService.getAARegistrationJobNumberByApplicationId(application).subscribe(
     (resp) => {
      //  console.log(resp.body.obj);
        this.acceptJob = resp.body.obj;
         console.log(this.acceptJob);
         this.acceptJobForm.controls['job_no'].setValue(this.acceptJob);
        }, (error) => {
          console.log(error)
          this.alertService.error(error);
          if(error.status == 401){
            location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Session Expired, Please Login Again",
              "announcement"
            );
            this.router.navigate(["/login"]);
          }
          else if(error.status == 500){
            location.reload();
            setTimeout(() => {
              this.blockUI.stop();
            }, 500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Try Again",
              "announcement"
            );
          }
          else{}
         
        }
   )
      }

      updateAcceptJob(){
        this.acceptJobForm.controls['application_id'].setValue(this.caseDetailsData.application_id);
        this.blockchainService.updateAARegistrationJobNumber(this.acceptJobForm.value).subscribe(
          (resp) => {
            // console.log(resp.status);
            if(resp.status == 200){
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Job No Updated Successfully",
                "announcement"
              );
            }
             this.acceptJobForm.reset();
             setTimeout(() => {
              this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
              // this.route.navigateByUrl('/SampleComponent', { skipLocationChange: true });
             }, 1500);
          }, (error) => {
            console.log(error)
            this.alertService.error(error);
            if(error.status == 401){
              // location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Session Expired, Please Login Again",
                "announcement"
              );
              this.router.navigate(["/login"]);
            }
            else if(error.status == 500){
              // location.reload();
              setTimeout(() => {
                this.blockUI.stop();
              }, 500);
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Try Again",
                "announcement"
              );
            }
            else{}
           
          });
      }

      issueLiscense(){
        let application = {
          application_id: this.caseDetailsData.application_id
       }
       this.blockchainService.updateIssueLicenseStatus(application).subscribe(
        (resp) => {
          // console.log(resp.status);
          if(resp.status == 200){
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Issue Lisence Updated Successfully",
              "announcement"
            );
          }
           this.acceptJobForm.reset();
           setTimeout(() => {
            this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
            // this.route.navigateByUrl('/SampleComponent', { skipLocationChange: true });
           }, 1500);
        }, (error) => {
          console.log(error)
          this.alertService.error(error);
          if(error.status == 401){
            // location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Session Expired, Please Login Again",
              "announcement"
            );
            this.router.navigate(["/login"]);
          }
          else if(error.status == 500){
            // location.reload();
            setTimeout(() => {
              this.blockUI.stop();
            }, 500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Try Again",
              "announcement"
            );
          }
          else{}
         
        });
      }

      onFileSelected(event,action) {
        if(action == 'cust'){
          let $img: any = document.querySelector('#file');
          this.selectedFile = <File>event.target.files[0];
          if(this.selectedFile.type == 'application/pdf'){
            console.log("a");
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => {
              // called once readAsDataURL is completed
              this.url = event.target.result;
            };
            this.onUpload();
          }
          else{
            event.target.files[0].value='';
            // $img.value=''
            this.showNotification(
              "bottom",
              "right",
              "success",
              "File Format Invalid",
              "announcement"
            );
          }
         
        } else{
          this.selectedFile1 = <File>event.target.files[0];
          if(this.selectedFile1.type == 'application/pdf'){
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => {
              // called once readAsDataURL is completed
              this.url = event.target.result;
            };
            this.onUpload();
          }
          else{
            event.target.files[0].value='';
            this.showNotification(
              "bottom",
              "right",
              "success",
              "File Format Invalid",
              "announcement"
            );
          }
         
        }
      }

      onUpload() {
        const fd = new FormData();
        fd.append("file", this.selectedFile, this.selectedFile.name);
      }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd HH:mm:ss");
    this.noteDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

}
