import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { is } from "date-fns/locale";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { DateAdapter } from "@angular/material/core";

declare var $: any;
export interface Status {
  value: string;
  viewValue: string;
}
export interface PeriodicElement {
  name: string,
  details: any,
}
const ELEMENT_DATA: PeriodicElement[] = [
  {
    name: 'NAME OF LICENSE HOLDER',
    details: '',
  },
  {
    name: 'AA NUMBER',
    details: '',
  },
  {
    name: 'IEC NUMBER',
    details: '',
  },

];
@Component({
  selector: 'app-scn-recovery-module',
  templateUrl: './scn-recovery-module.component.html',
  styleUrls: ['./scn-recovery-module.component.css']
})
export class ScnRecoveryModuleComponent implements OnInit {
  allStatus: Status[] = [
    { value: "Yes", viewValue: "Yes" },
    { value: "No", viewValue: "No" },
  ];
  isLinear = false;
  scnForm: UntypedFormGroup;
  formComm: UntypedFormGroup;
  formCestatRa: UntypedFormGroup;
  formHighCourt: UntypedFormGroup;
  formSupreamCt: UntypedFormGroup;
  formPayment: UntypedFormGroup;
  formNotice: UntypedFormGroup;

  tabIndex = 0;
  displayedColumns = [
    'name',
    'details',
  ];

  currentUser: any;
  caseDetailsData: any;
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  now = Date.now();
  date: any;
  modifiedCurrentDate: any;

  dataSource = ELEMENT_DATA;
  licenseHolderDetails: any;
  scn_master_id: any;
  getScnDetailsByAuth: any;
  authNo: any;
  applicationType: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private router: Router,
    private dateAdapter: DateAdapter<Date>
     ) 
   { 
    this.dateAdapter.setLocale('en-GB'); 
    this.createForm() 
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationType = localStorage.getItem('applicationType');
    // this.authNo = JSON.parse(localStorage.getItem("authno"));
    this.authNo = localStorage.getItem("authno");
    console.log(this.authNo)
    this.getOMRelaventInformationByAuthNo(this.authNo);
    this.getScnDetailsByAuthNumber();
  }
  getOMRelaventInformationByAuthNo(auth) {
    let input = {
      auth_number: auth,
      applicationType: this.applicationType ? this.applicationType : 'AA'
    }
    this.blockchainService.getOMRelaventInformationByAuthNo(input).subscribe(
      (resp) => {
        
        if(resp.body.status == 200){
          console.log(resp.body.obj)
          this.licenseHolderDetails = resp.body.obj;
        }
      })
  }
  getScnDetailsByAuthNumber() {
    let input = {
      auth_number: this.authNo,
      applicationType: this.applicationType ? this.applicationType : 'AA'
    }
    this.blockchainService.getScnDetailsByAuthNumber(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        this.getScnDetailsByAuth = resp.body.obj;
        // this.scn_master_id = this.getScnDetailsByAuth.scn_master_id;
        localStorage.setItem('scn_master_id', JSON.stringify(this.getScnDetailsByAuth.scn_master_id));
        this.scn_master_id = JSON.parse(localStorage.getItem("scn_master_id"));
        this.scnForm.setValue({
          scn_number: this.getScnDetailsByAuth.scn_number,
          scn_date: this.pipe.transform(this.getScnDetailsByAuth.scn_date, "yyyy-MM-dd"),
          amount_demanded: this.getScnDetailsByAuth.amount_demanded,
          adj_order_no: this.getScnDetailsByAuth.adj_order_no,
          adj_order_date: this.pipe.transform(this.getScnDetailsByAuth.adj_order_date, "yyyy-MM-dd"),
          // total_show_cause_notice_amount_due: this.getScnDetailsByAuth.total_show_cause_notice_amount_due
        })

        this.formComm.setValue({
          commissioner_appeal_no: this.getScnDetailsByAuth.commissioner_appeal_no,
          commissioner_appeal_date: this.pipe.transform(this.getScnDetailsByAuth.commissioner_appeal_date, "yyyy-MM-dd"),
          comm_a_order_no: this.getScnDetailsByAuth.comm_a_order_no,
          comm_a_order_date: this.pipe.transform(this.getScnDetailsByAuth.comm_a_order_date, "yyyy-MM-dd"),
          // total_commissioner_appeal_amount_due: this.getScnDetailsByAuth.total_commissioner_appeal_amount_due
        })

        this.formCestatRa.setValue({
          cestat_ra_appeal_no: this.getScnDetailsByAuth.cestat_ra_appeal_no,
          cestat_ra_appeal_date: this.pipe.transform(this.getScnDetailsByAuth.cestat_ra_appeal_date, "yyyy-MM-dd"),
          cestat_ra_order_no: this.getScnDetailsByAuth.cestat_ra_order_no,
          cestat_ra_order_date: this.pipe.transform(this.getScnDetailsByAuth.cestat_ra_order_date, "yyyy-MM-dd"),
          // total_cestat_ra_appeal_amount_due: this.getScnDetailsByAuth.total_cestat_ra_appeal_amount_due,
        })

        this.formHighCourt.setValue({
          high_court_appeal_no: this.getScnDetailsByAuth.high_court_appeal_no,
          high_court_appeal_date: this.pipe.transform(this.getScnDetailsByAuth.high_court_appeal_date, "yyyy-MM-dd"),
          high_court_order_no: this.getScnDetailsByAuth.high_court_order_no,
          high_court_order_date: this.pipe.transform(this.getScnDetailsByAuth.high_court_order_date, "yyyy-MM-dd"),
          // total_high_court_appeal_amount_due: this.getScnDetailsByAuth.total_high_court_appeal_amount_due
        })

        this.formSupreamCt.setValue({
          supreme_court_writ__appeal_no: this.getScnDetailsByAuth.supreme_court_writ__appeal_no,
          supreme_court_writ__appeal_date: this.pipe.transform(this.getScnDetailsByAuth.supreme_court_writ__appeal_date, "yyyy-MM-dd"),
          supreme_court_writ__order_no: this.getScnDetailsByAuth.supreme_court_writ__order_no,
          supreme_court_writ__order_date: this.pipe.transform(this.getScnDetailsByAuth.supreme_court_writ__order_date, "yyyy-MM-dd"),
          // total_supreme_court_writ__appeal_amount_due: this.getScnDetailsByAuth.total_supreme_court_writ__appeal_amount_due
        })

        this.formNotice.setValue({
          amount_due: this.getScnDetailsByAuth.amount_due,
          date_of_issue_of_notice_under_section_142: this.pipe.transform(this.getScnDetailsByAuth.date_of_issue_of_notice_under_section_142, "yyyy-MM-dd"),
        })

        this.formPayment.setValue({
          payment_receipt_no: this.getScnDetailsByAuth.payment_receipt_no,
          payment_receipt_date: this.pipe.transform(this.getScnDetailsByAuth.payment_receipt_date, "yyyy-MM-dd"),
          payment_amount: this.getScnDetailsByAuth.payment_amount,
          amount_due_after_payment: this.getScnDetailsByAuth.amount_due_after_payment,
          payment_due: this.getScnDetailsByAuth.payment_due
        })


      })
  }


  createForm() {
    this.scnForm = this.formBuilder.group({
      scn_number: [""],
      scn_date: [""],
      amount_demanded: [""],
      adj_order_no: [""],
      adj_order_date: [""],
    });

    this.formComm = this.formBuilder.group({
      commissioner_appeal_no: [""],
      commissioner_appeal_date: [""],
      comm_a_order_no: [""],
      comm_a_order_date: [""],
    });

    this.formCestatRa = this.formBuilder.group({
      cestat_ra_appeal_no: [""],
      cestat_ra_appeal_date: [""],
      cestat_ra_order_no: [""],
      cestat_ra_order_date: [""],
    });

    this.formHighCourt = this.formBuilder.group({
      high_court_appeal_no: [""],
      high_court_appeal_date: [""],
      high_court_order_no: [""],
      high_court_order_date: [""],
    });
    this.formSupreamCt = this.formBuilder.group({
      supreme_court_writ__appeal_no: [""],
      supreme_court_writ__appeal_date: [""],
      supreme_court_writ__order_no: [""],
      supreme_court_writ__order_date: [""],
    });
    this.formNotice = this.formBuilder.group({
      amount_due: [""],
      date_of_issue_of_notice_under_section_142: [""]
    });
    this.formPayment = this.formBuilder.group({
      payment_receipt_no: [""],
      payment_receipt_date: [""],
      payment_amount: [""],
      amount_due_after_payment: [""],
      payment_due: [""]
    });

  }
  changeTab(event) {
    console.log(event.index);
    this.tabIndex = event.index;
  }
  submitScnForm() {
    // this.scnForm.controls['auth_number'].setValue(this.licenseHolderDetails.aa_no); 
    console.log(this.scnForm.value);
  
    if(this.scn_master_id !=null || this.scn_master_id !=undefined){
      if(this.scnForm.value.adj_order_no == ''){
        this.scnForm.value.adj_order_no = null
      } else{
        this.scnForm.value.adj_order_no = this.scnForm.value.adj_order_no;
      }
      let input = {
        scn_number: this.scnForm.value.scn_number,
        scn_date: this.pipe.transform(this.scnForm.value.scn_date, "yyyy-MM-dd HH:mm:ss"),
        amount_demanded: this.scnForm.value.amount_demanded,
        adj_order_no: this.scnForm.value.adj_order_no ,
        adj_order_date: this.pipe.transform(this.scnForm.value.adj_order_date, "yyyy-MM-dd HH:mm:ss"),

        auth_number:this.authNo,
        created_by: this.currentUser.obj.user_id,
        scn_master_id:this.scn_master_id
      }
      this.blockchainService.updateScnDetails(input).subscribe(
        (resp) => {
          console.log(resp.body.obj)
          // localStorage.setItem('scn_master_id', JSON.stringify(resp.body.obj));
          // this.scn_master_id = JSON.parse(localStorage.getItem("scn_master_id"));
          if (resp.body.status == 200) {
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );
          } else {
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );
          }
        })
    }else{
      if(this.scnForm.value.adj_order_no == ''){
        this.scnForm.value.adj_order_no = null
      } else{
        this.scnForm.value.adj_order_no = this.scnForm.value.adj_order_no;
      }
      let input = {
        scn_number: this.scnForm.value.scn_number,
        scn_date: this.pipe.transform(this.scnForm.value.scn_date, "yyyy-MM-dd HH:mm:ss"),
        amount_demanded: this.scnForm.value.amount_demanded,
        adj_order_no: this.scnForm.value.adj_order_no,
        adj_order_date: this.pipe.transform(this.scnForm.value.adj_order_date, "yyyy-MM-dd HH:mm:ss"),
        // total_show_cause_notice_amount_due: this.scnForm.value.total_show_cause_notice_amount_due,
        auth_number:this.authNo,
        created_by: this.currentUser.obj.user_id,
        applicationType: this.applicationType ? this.applicationType : 'AA'
      }
    this.blockchainService.saveScnDetails(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        localStorage.setItem('scn_master_id', JSON.stringify(resp.body.obj));
        this.scn_master_id = JSON.parse(localStorage.getItem("scn_master_id"));
        if (resp.body.status == 200) {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      })
    }
  }

  submitCommForm() {
    console.log(this.formComm.value);
    if(this.scnForm.value.adj_order_no == ''){
      this.scnForm.value.adj_order_no = null
    } else{
      this.scnForm.value.adj_order_no = this.scnForm.value.adj_order_no;
    }
    let input = {
      commissioner_appeal_no: this.formComm.value.commissioner_appeal_no,
      commissioner_appeal_date: this.pipe.transform(this.formComm.value.commissioner_appeal_date, "yyyy-MM-dd HH:mm:ss"),
      comm_a_order_no: this.formComm.value.comm_a_order_no,
      comm_a_order_date: this.pipe.transform(this.formComm.value.comm_a_order_date, "yyyy-MM-dd HH:mm:ss"),
      // total_commissioner_appeal_amount_due: this.formComm.value.total_commissioner_appeal_amount_due,
      scn_master_id: JSON.parse(localStorage.getItem("scn_master_id")),
      applicationType: this.applicationType ? this.applicationType : 'AA'
    }
    this.blockchainService.saveComm(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        if (resp.body.status == 200) {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      })
  }

  submitCestatRa() {
    console.log(this.formCestatRa.value);
    if(this.scnForm.value.adj_order_no == ''){
      this.scnForm.value.adj_order_no = null
    } else{
      this.scnForm.value.adj_order_no = this.scnForm.value.adj_order_no;
    }
    let input = {
      cestat_ra_appeal_no: this.formCestatRa.value.cestat_ra_appeal_no,
      cestat_ra_appeal_date: this.pipe.transform(this.formCestatRa.value.cestat_ra_appeal_date, "yyyy-MM-dd HH:mm:ss"),
      cestat_ra_order_no: this.formCestatRa.value.cestat_ra_order_no,
      cestat_ra_order_date: this.pipe.transform(this.formCestatRa.value.cestat_ra_order_date, "yyyy-MM-dd HH:mm:ss"),
      // total_cestat_ra_appeal_amount_due: this.formCestatRa.value.total_cestat_ra_appeal_amount_due,
      scn_master_id: JSON.parse(localStorage.getItem("scn_master_id")),
      applicationType: this.applicationType ? this.applicationType : 'AA'
    }
    this.blockchainService.saveCestatRa(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        if (resp.body.status == 200) {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      })
  }

  submitHighCourt() {
    console.log(this.formHighCourt.value);
    if(this.scnForm.value.adj_order_no == ''){
      this.scnForm.value.adj_order_no = null
    } else{
      this.scnForm.value.adj_order_no = this.scnForm.value.adj_order_no;
    }
    let input = {
      high_court_appeal_no: this.formHighCourt.value.high_court_appeal_no,
      high_court_appeal_date: this.pipe.transform(this.formHighCourt.value.high_court_appeal_date, "yyyy-MM-dd HH:mm:ss"),
      high_court_order_no: this.formHighCourt.value.high_court_order_no,
      high_court_order_date: this.pipe.transform(this.formHighCourt.value.high_court_order_date, "yyyy-MM-dd HH:mm:ss"),
      // total_high_court_appeal_amount_due: this.formHighCourt.value.total_high_court_appeal_amount_due,
      scn_master_id: JSON.parse(localStorage.getItem("scn_master_id")),
      applicationType: this.applicationType ? this.applicationType : 'AA'
    }
    this.blockchainService.saveHighCourt(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        if (resp.body.status == 200) {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      })
  }

  submitSupreamCt() {
    console.log(this.formSupreamCt.value);
    if(this.scnForm.value.adj_order_no == ''){
      this.scnForm.value.adj_order_no = null
    } else{
      this.scnForm.value.adj_order_no = this.scnForm.value.adj_order_no;
    }
    let input = {
      supreme_court_writ__appeal_no: this.formSupreamCt.value.supreme_court_writ__appeal_no,
      supreme_court_writ__appeal_date: this.pipe.transform(this.formSupreamCt.value.supreme_court_writ__appeal_date, "yyyy-MM-dd HH:mm:ss"),
      supreme_court_writ__order_no: this.formSupreamCt.value.supreme_court_writ__order_no,
      supreme_court_writ__order_date: this.pipe.transform(this.formSupreamCt.value.supreme_court_writ__order_date, "yyyy-MM-dd HH:mm:ss"),
      // total_supreme_court_writ__appeal_amount_due: this.formSupreamCt.value.total_supreme_court_writ__appeal_amount_due,
      scn_master_id: JSON.parse(localStorage.getItem("scn_master_id")),
      applicationType: this.applicationType ? this.applicationType : 'AA'
    }
    this.blockchainService.saveSupreamCt(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        if (resp.body.status == 200) {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      })
  }

  submitNotice() {
    console.log(this.formNotice.value);
    if(this.scnForm.value.adj_order_no == ''){
      this.scnForm.value.adj_order_no = null
    } else{
      this.scnForm.value.adj_order_no = this.scnForm.value.adj_order_no;
    }
    let input = {
      amount_due: this.formNotice.value.amount_due,
      date_of_issue_of_notice_under_section_142: this.pipe.transform(this.formNotice.value.date_of_issue_of_notice_under_section_142, "yyyy-MM-dd HH:mm:ss"),
      scn_master_id: JSON.parse(localStorage.getItem("scn_master_id")),
      applicationType: this.applicationType ? this.applicationType : 'AA'
    }
    this.blockchainService.saveNotice(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        if (resp.body.status == 200) {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      })
  }

  submitPayment() {
    console.log(this.formPayment.value);
    if(this.scnForm.value.adj_order_no == ''){
      this.scnForm.value.adj_order_no = null
    } else{
      this.scnForm.value.adj_order_no = this.scnForm.value.adj_order_no;
    }
    let input = {
      payment_receipt_no: this.formPayment.value.payment_receipt_no,
      payment_receipt_date: this.pipe.transform(this.formPayment.value.payment_receipt_date, "yyyy-MM-dd HH:mm:ss"),
      payment_amount: this.formPayment.value.payment_amount,
      amount_due_after_payment: this.formPayment.value.amount_due_after_payment,
      payment_due: this.formPayment.value.payment_due,
      scn_master_id: JSON.parse(localStorage.getItem("scn_master_id")),
      applicationType: this.applicationType ? this.applicationType : 'AA'
    }
    this.blockchainService.savePaymentDetails(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        if (resp.body.status == 200) {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      })

  }

  back(){
    this.router.navigate(['/scnModulePS']);
  }


  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };

}
