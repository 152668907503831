import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {FormArray,UntypedFormBuilder,Validators, AbstractControl,UntypedFormGroup,FormControl} from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { MatTableDataSource } from '@angular/material/table';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe, LowerCasePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { AmountToWordPipe } from "app/blockchainImpExp/imp_exp/amount-to-word.pipe";
import { PaymentsumComponent } from "app/blockchainImpExp/imp_exp/payment/paymentsum/paymentsum.component";
import { Console } from "console";
declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface PeriodicElement {
  no: number;
  demand_draft_no:string;
  date:string;
  bank_name:string;
  amount:string;
  reEnterDDNo:string;
  reEnterDDAmount:string;
  acceptDD:string;

}

export interface Cash{
  cashIn:string;
  prefilledCash:string;
  enterNewCash:string;
  acceptCash:string;

}

export interface IPOElement {
  no: number;
  ipo_no: string;
  date: string;
  post_office_name: string;
  payment_in_figures: string;
  action: any;
  remarks:any;
  payment_id:string;
}

@Component({
  selector: 'app-accept-challan',
  templateUrl: './accept-challan.component.html',
  styleUrls: ['./accept-challan.component.css'],
  providers: [AmountToWordPipe],
})
export class AcceptChallanComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  selectedStatus = 'New';
  reEnterCash :any
  allStatus: Status[] = [
    {value: 'Incoming Date', viewValue: 'Incoming Date'},
    {value: 'IEC Code', viewValue: 'IEC Code'},
  ];


  allStatusReseceived: Status[] = [
    { value: "0", viewValue: "YES" },
    { value: "1", viewValue: "NO" },
  ];


  currentUser: any;
  role: any;
  dataSource:PeriodicElement[] = [];
  data:Cash[] = [];
  // data:cas=ELEMENT;
  paymentId:string;
  ddForm: UntypedFormGroup;
  cashForm:UntypedFormGroup;
  ipoForm:UntypedFormGroup;
  rejectForm:UntypedFormGroup;
  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  displayedColumns = [
    'no',
    'ddNo',
    'ddDate',
    'bankName',
    'amountInFigures',
    'reEnterDDNo',
    'reEnterDDAmount',
    'acceptDD',
  ];

  cashColumns = [
    'no',
    'cash_in_figures',
    'cash_in_words',
    'validate_cash_in_figures',
    'validate_cash_in_words',
    'acceptCash'
  ];

  IPOColumns = [
    "no",
    "ipo_no",
    "date",
    "post_office_name",
    "payment_in_figures",
    'reEnterIPONo',
    'reEnterIPOAmount',
    "acceptIpo",
  ];

  SBColumns = [
    "no",
    "date",
    "bank_name",
    "amount_in_figures",
    'amount_in_words',
    'acceptsb'
  ];
  payment_id: string;
  loader: boolean;
  ipodata: any;
  sbdata: any;

  action: string;
  challanDetails: any;
  paymentAcceptance: any;
  offsetData: any;
  msg: null;
  sbFormData:any;
  sbFacilityData: any;

  constructor( private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private amountToWordPipe: AmountToWordPipe
    
    ) { 
      this.offsetData = JSON.parse(localStorage.getItem("offset"));
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = this.currentUser.obj.user_type_id;
    this.ddForm=this.formBuilder.group({
      demand_draft_no: [''],
      date:[''],
      bank_name:[''],
      amount:[''],
      reEnterDDNo:[''],
      reEnterDDAmount:[''],
      dd_id:['']
    });
    this.cashForm = this.formBuilder.group({
      cash_in_figures:[''],
      cash_in_words:[''],
      validate_cash_in_figures:[''],
      validate_cash_in_words:[''],
      cash_id:['']

    });

    this.ipoForm=this.formBuilder.group({
      ipo_no: [''],
      date:[''],
      post_office_name:[''],
      payment_in_figures:[''],
      reEnterIPONo:[''],
      reEnterIPOAmount:[''],
      ipo_id:['']
    });

    this.rejectForm = this.formBuilder.group({
      rejectReason:[''],
      extraDoc:['']
    });

    this.paymentAcceptance = JSON.parse(localStorage.getItem('paymentAcceptance'));
    this.payment_id = this.paymentAcceptance.payment_master_id;
    this.action = localStorage.getItem('action');
    console.log("action",this.payment_id);
    this.getDDById(this.payment_id);
    this.getCashById(this.payment_id);
    this.getIpoByPaymentId(this.payment_id);
    this.getSBFacilityByPaymentId(this.payment_id);
  }

  convertToWord(){
    this.cashForm.value.validate_cash_in_words = this.amountToWordPipe.transform(this.cashForm.value.validate_cash_in_figures);
    console.log(this.cashForm.value.cash_in_words,'words')
  }

  caseDetailsDialogue(data){
   console.log(this.ddForm.value);
   console.log(data);
  }

  get f() {
    return this.ddForm.controls;
  }

  
  getDDById(payment_Id){
    let input = {
      payment_id : payment_Id
    }
    this.blockchainService.getDDById(input).subscribe((resp) => {
      console.log(resp);
      this.dataSource = resp.body.obj;
    });
  }

  getCashById(payment_Id){
    let input = {
      payment_id : payment_Id
    }
    this.blockchainService.getCashById(input).subscribe((resp) => {
      console.log(resp);
      this.data = resp.body.obj;
    });
  }

  getIpoByPaymentId(payment_id){
    let input = {
      payment_id : payment_id
    }
    this.blockchainService.getIpoByPaymentId(input).subscribe((resp) => {
      this.ipodata = resp.body.obj;
    });
  }

  getSBFacilityByPaymentId(payment_id){
    let input = {
      payment_id : payment_id
    }
    this.blockchainService.getSBFacilityByPaymentId(input).subscribe((resp) => {
      this.sbdata = resp.body.obj;
      console.log(this.sbdata);
    });
  }

  confirmDD(element){
   
    console.log(element);
    
    this.blockUI.start("Please wait this may take few seconds ...");
     
      let input = {
        demand_draft_no:element.demand_draft_no,
        date:element.date,
        dd_id:element.dd_id,
        bank_confirmation:this.ddForm.value.reEnterDDNo,
        bank_name:element.bank_name,
        bank_confirmation_date:this.ddForm.value.reEnterDDAmount
      }
       console.log(input)
      this.blockchainService.updateDDConfirmation(input).subscribe((resp) => {
        if(resp.body.status==200){
          setTimeout(() => {
            this.blockUI.stop();
            this.getDDById(this.payment_id);
            this.ddForm.reset();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout( () => {
            this.msg = null;
          }, 3000);
         return;
        //  location.reload()
          
          // this.router.navigate(["/payment_acceptance"])
        }else{
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout( () => {
            this.msg = null;
          }, 3000);
          return;
        }
      });
  }

  submitDD(element){
    
    let verifyDD = this.ddForm.get('reEnterDDNo').value;
    let verifyAmount = this.ddForm.get('reEnterDDAmount').value;
    // console.log(verifyamount)
    this.blockUI.start("Please wait this may take few seconds ...");
    if(verifyDD == ''){
      //  alert("plseas enter valid DD no");
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
       this.showNotification(
        "bottom",
        "right",
        "success",
        "Please re-enter valid DD No.",
        "announcement"
      );
      setTimeout( () => {
				this.msg = null;
			}, 3000);
			return;
    }
    else if(verifyAmount==''){
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please re-enter valid amount",
        "announcement"
      );
      setTimeout( () => {
				this.msg = null;
			}, 3000);
			return;
    }
    else if((element.demand_draft_no.toLowerCase()) !== verifyDD.toLowerCase()){
      // alert("plseas enter valid DD no");
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please re-enter valid DD No.",
        "announcement"
      );
      setTimeout( () => {
				this.msg = null;
			}, 3000);
			return;
    }else if(element.amount !== verifyAmount){
      // alert("plseas enter valid amount");
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please re-enter valid amount",
        "announcement"
      );
      setTimeout( () => {
				this.msg = null;
			}, 3000);
			return;
    }
    else{
    this.ddForm.patchValue({
      demand_draft_no:element.demand_draft_no,
      date:element.date,
      dd_id:element.dd_id,
      amount:element.amount,
      bank_name:element.bank_name
    });
    // console.log(this.ddForm.value);
    this.blockchainService.updateDDStatus(this.ddForm.value).subscribe((resp) => {
      if(resp.body.status==200){
        setTimeout(() => {
          this.blockUI.stop();
          this.getDDById(this.payment_id);
          this.ddForm.reset();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        setTimeout( () => {
          this.msg = null;
        }, 3000);
         return;
        //  location.reload()
        // this.getDDById(this.payment_id);
        // this.router.navigate(["/payment_acceptance"])
      }else{
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        setTimeout( () => {
          this.msg = null;
        }, 3000);
        return;
      }
    });
   }
  }

  cashSubmit(element){
    let AmtFigure = this.cashForm.get('validate_cash_in_figures').value;
    let AmtWords = this.cashForm.get('validate_cash_in_words').value;
    this.blockUI.start("Please wait this may take few seconds ...");
    // console.log(verifyamount)
    if(AmtFigure ==''){
      this.blockUI.stop();
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please re-enter valid cash in figure",
        "announcement"
      ); 
      setTimeout( () => {
				this.msg = null;
			}, 3000);
			return;
    }
    
    else if(element.cash_in_figures != AmtFigure){
      // alert("plseas enter valid Amount in figure");
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please re-enter valid cash in figure",
        "announcement"
      );
      setTimeout( () => {
				this.msg = null;
			}, 3000);
			return;
    }else{
      this.cashForm.patchValue({
        cash_in_figures:element.cash_in_figures,
        cash_in_words:element.cash_in_words,
        cash_id:element.cash_id
      });
      console.log(this.cashForm.value);

      if(this.action=='Accept'){
      this.blockchainService.updateCashStatus(this.cashForm.value).subscribe((resp) => {
        console.log(resp);
        if(resp.body.status==200){
          setTimeout(() => {
            this.blockUI.stop();
            this.getCashById(this.payment_id);
            this.cashForm.reset();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
          setTimeout( () => {
            this.msg = null;
          }, 3000);
         return;
          // this.getCashById(this.payment_id);
        //  location.reload()
          // this.router.navigate(["/payment_acceptance"])
        }else{
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout( () => {
            this.msg = null;
          }, 3000);
          return;
        }
      });
    }

    if(this.action=='Confirm'){
      this.blockchainService.updateCashConfirmation(this.cashForm.value).subscribe((resp) => {
        console.log(resp);
        if(resp.body.status==200){
          setTimeout(() => {
            this.blockUI.stop();
            this.getCashById(this.payment_id);
            this.cashForm.reset();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
          setTimeout( () => {
            this.msg = null;
          }, 3000);
         return;
          // this.getCashById(this.payment_id);
          // location.reload()
          // this.router.navigate(["/payment_acceptance"])
        }else{
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout( () => {
            this.msg = null;
          }, 3000);
          return;
        }
      });
    }
    }
  }

  sbSubmit(element){
    // let AmtFigure = this.cashForm.get('validate_cash_in_figures').value;
    // let AmtWords = this.cashForm.get('validate_cash_in_words').value;
    this.blockUI.start("Please wait this may take few seconds ...");
    // console.log(verifyamount)
    if(this.sbdata ==''){
      this.blockUI.stop();
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please enter valid amount in figure",
        "announcement"
      ); 
      setTimeout( () => {
				this.msg = null;
			}, 3000);
			return;
    }
    
    else{
      // this.cashForm.patchValue({
      //   cash_in_figures:element.cash_in_figures,
      //   cash_in_words:element.cash_in_words,
      //   cash_id:element.cash_id
      // });
      // console.log(this.cashForm.value);
      this.sbFacilityData=this.sbdata[0];
      this.sbFormData={
        // bank_name:"ABU DHABI COMMERCIAL BANK",
        // date:"2024-11-11",
        // amount_in_figures:10,
        // amount_in_words:"Ten Rupees Only",
        // sb_id:10,
        bank_name:element.bank_name,
        date:element.created_date,
        amount_in_figures:element.amount_in_figures,
        amount_in_words:element.amount_in_words,
        sb_id:element.sb_id,
        user_id:this.currentUser.obj.user_id
      };
      console.log(this.sbdata);
      console.log(this.sbFormData);

      if(this.action=='Accept'){
      this.blockchainService.updateSbStatus(this.sbFormData).subscribe((resp) => {
        console.log(resp);
        if(resp.body.status==200){
          setTimeout(() => {
            this.blockUI.stop();
            this.getSBFacilityByPaymentId(this.payment_id);
            // this.cashForm.reset();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
          setTimeout( () => {
            this.msg = null;
          }, 3000);
         return;
          // this.getCashById(this.payment_id);
        //  location.reload()
          // this.router.navigate(["/payment_acceptance"])
        }else{
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout( () => {
            this.msg = null;
          }, 3000);
          return;
        }
      });
    }

    if(this.action=='Confirm'){
      this.blockchainService.updateSbConfirmation(this.sbFormData).subscribe((resp) => {
        console.log(resp);
        if(resp.body.status==200){
          setTimeout(() => {
            this.blockUI.stop();
            this.getSBFacilityByPaymentId(this.payment_id);
            // this.cashForm.reset();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
          setTimeout( () => {
            this.msg = null;
          }, 3000);
         return;
          // this.getCashById(this.payment_id);
          // location.reload()
          // this.router.navigate(["/payment_acceptance"])
        }else{
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout( () => {
            this.msg = null;
          }, 3000);
          return;
        }
      });
    }
    }
  }

  updateIpoStatus(element){
    console.log(element,'ele')
    let ipoNo = this.ipoForm.get('reEnterIPONo').value;
    let ipoAmount = this.ipoForm.get('reEnterIPOAmount').value;
    this.blockUI.start("Please wait this may take few seconds ...");
    console.log(ipoAmount,'ip')
    if(ipoNo == ''){
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
       this.showNotification(
        "bottom",
        "right",
        "success",
        "Please re-enter valid IPO No.",
        "announcement"
      );
      setTimeout( () => {
				this.msg = null;
			}, 3000);
			return;
    }
    else if(ipoAmount==''){
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please re-enter valid amount",
        "announcement"
      );
      setTimeout( () => {
				this.msg = null;
			}, 3000);
			return;
    }
    else if((element.ipo_no.toLowerCase()) !== ipoNo.toLowerCase()){
      // alert("plseas enter valid DD no");
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please re-enter valid IPO No.",
        "announcement"
      );
      setTimeout( () => {
				this.msg = null;
			}, 3000);
			return;
    }else if(element.payment_in_figures !== ipoAmount){
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please re-enter valid amount",
        "announcement"
      );
      setTimeout( () => {
				this.msg = null;
			}, 3000);
			return;
    }
    else{
    this.ipoForm.patchValue({
      ipo_no:element.ipo_no,
      date:element.date,
      ipo_id:element.ipo_id,
      payment_in_figures:element.payment_in_figures,
      post_office_name:element.post_office_name
    });
    console.log(this.ipoForm.value);

    if(this.action == 'Accept'){
    this.blockchainService.updateIpoStatus(this.ipoForm.value).subscribe((resp) => {
      if(resp.body.status==200){
        setTimeout(() => {
          this.blockUI.stop();
          this.getIpoByPaymentId(this.payment_id);
          this.ipoForm.reset();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        setTimeout( () => {
          this.msg = null;
        }, 3000);
       return;
        // location.reload()
        // this.getIpoByPaymentId(this.payment_id);
        // this.router.navigate(["/payment_acceptance"])
      }else{
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        setTimeout( () => {
          this.msg = null;
        }, 3000);
        return;
      }
    });
   }

   if(this.action == 'Confirm'){
    this.blockchainService.updateIpoConfirmation(this.ipoForm.value).subscribe((resp) => {
      if(resp.body.status==200){
        setTimeout(() => {
          this.blockUI.stop();
          this.getIpoByPaymentId(this.payment_id);
          this.ipoForm.reset();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        setTimeout( () => {
          this.msg = null;
        }, 3000);
         return;
        // location.reload()
        // this.getIpoByPaymentId(this.payment_id);
        // this.router.navigate(["/payment_acceptance"])
      }else{
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        setTimeout( () => {
          this.msg = null;
        }, 3000);
        return;
      }
    });
   }
   }
  }
back(){
  this.router.navigate(['/payment_acceptance'], { queryParams: { page: this.offsetData } });
}
  reject(patmt){
    // this.payment_id = localStorage.getItem('payment_id');
    this.payment_id = this.paymentAcceptance.payment_master_id;
    let input={
      payment_master_id:this.payment_id,
      reason_for_rejection:this.rejectForm.value.rejectReason,
      extra_documents_needed:this.rejectForm.value.extraDoc
    }
    if(this.action == 'Accept'){
      this.blockchainService.rejectChallan(input).subscribe((resp) => {
        if(resp.body.status==200){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout( () => {
            this.msg = null;
          
          }, 3000);
          // return;
          // this.router.navigate(["/payment_acceptance"]);
          this.router.navigate(['/payment_acceptance'], { queryParams: { page: this.offsetData } });
        
        }else{
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout( () => {
            this.msg = null;
          }, 3000);
          return;
        }
      });
    }
    if(this.action=='Confirm'){
      this.blockchainService.rejectChallanConfirmation(input).subscribe((resp) => {
        if(resp.body.status==200){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout( () => {
            this.msg = null;
           
          }, 3000);
          // return;
          this.router.navigate(['/payment_acceptance'], { queryParams: { page: this.offsetData } });
        }else{
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout( () => {
            this.msg = null;
          }, 3000);
          return;
        }
      });
    }
  }

  generateReciept(){
    this.blockUI.start("Please wait this may take few seconds ...");
    let input = {
      payment_master_id : this.payment_id 
    }
    this.blockchainService.updatePaymentStatus(input).subscribe(resp => {
      if(resp.body.status == 200){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.loader = false
        this.openDialog(
          "paymentSum",
          "EChallan Accepted Receipt Generated Successfully",
          "" 
             );
             setTimeout(() => {
              let url = resp.body.obj;
              url = url+'/'+ this.currentUser.obj.access_token
              window.open(url, '_blank');
              url = url+'/'+ this.currentUser.obj.access_token
              this.router.navigate(['/payment_acceptance'], { queryParams: { page: this.offsetData } });
            }, 3000);

            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );
      }else{
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.loader = false
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    })
   
  }

  generateConfirmationReciept(){
    this.blockUI.start("Please wait this may take few seconds ...");
    let input = {
      payment_master_id : this.payment_id 
    }
    this.blockchainService.updatePaymentConfirmationStatus(input).subscribe(resp => {
      if(resp.body.status == 200){
        console.log(resp.body.status);
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.loader = false
        this.openDialog(
          "paymentSum",
          "EChallan Confirmation Receipt Generated Successfully",
          "" 
             );
             setTimeout(() => {
              let url = resp.body.obj;
              url = url+'/'+ this.currentUser.obj.access_token
              window.open(url, '_blank')
              this.router.navigate(['/payment_acceptance'], { queryParams: { page: this.offsetData } });
            }, 3000);

            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );
      }else{
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.loader = false
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    })
   
  }


  openModalReject(){
  
      // console.log(section.letter);
      // this.paymentId = localStorage.getItem('payment_id')
      this.payment_id = this.paymentAcceptance.payment_master_id;
      // console.log(localStorage.getItem('payment_id'));
      this.challanDetails = this.payment_id;
      // console.log(this.challanDetails)
      const buttonModal = document.getElementById("openModalReject");
      buttonModal.click();
    
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "paymentSum" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }
  
  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
