import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { DateAdapter } from '@angular/material/core';
import { AlertService } from "app/_services";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-aa-header",
  templateUrl: "./aa-header.component.html",
  styleUrls: ["./aa-header.component.css"],
})
export class AaHeaderComponent implements OnInit {
  pipe = new DatePipe("en-US");

  selectedStatus = "";

  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  auth_date: any;
  import_validity_date: any;
  export_validity_date: any;

  aaForm = this.formBuilder.group({
    auth_number: [""],
    auth_date: ["",Validators.required],
    import_validity_date: ["",Validators.required],
    export_validity_date: ["",Validators.required],
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  date: string;
  modifiedCurrentDate: string;
  lastDate;
  aaResp: any;
  applicationType: string;
  auth_number: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    private alertService: AlertService,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationType = localStorage.getItem('applicationType')
    this.getAaApplicationById();

    this.aaForm.patchValue({
        auth_number: localStorage.getItem('auth_number')
    });
    this.auth_number = localStorage.getItem('auth_number')
  }

  checkAuthNo(e){
    console.log(e.target.value,'checking checkAuthNo')
    let input = {
      auth_number : e.target.value
    }
    this.blockchainService.checkAuthNo(input).subscribe(resp => {
      if(resp.body.status == 200){
        this.isButtonDisabled = false
      } 
     
      else{
        this.isButtonDisabled = true;
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    })
  }

  getAaApplicationById() {
    let application_id = localStorage.getItem("application_id");
    let input = {
      application_id: application_id,
    };
    this.blockchainService.getAaApplicationById(input).subscribe((resp) => {
      this.aaResp = resp.body.obj;
    
      console.log(this.aaResp);
      this.aaForm.patchValue({
        auth_number:
          this.aaResp.auth_number != null ? this.aaResp.auth_number : "",
        auth_date: this.aaResp.auth_date,
        import_validity_date: this.aaResp.import_validity_date,
        export_validity_date: this.aaResp.export_validity_date,
      });
    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    });
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  next() {
    this.aaForm.value.auth_date = this.pipe.transform(
      this.aaForm.value.auth_date,
      "yyyy-MM-dd"
    );
    this.aaForm.value.import_validity_date = this.pipe.transform(
      this.aaForm.value.import_validity_date,
      "yyyy-MM-dd"
    );
    this.aaForm.value.export_validity_date = this.pipe.transform(
      this.aaForm.value.export_validity_date,
      "yyyy-MM-dd"
    );

    if(this.aaForm.value.auth_date != null && this.aaForm.value.auth_date != undefined && this.aaForm.value.auth_date != ''){
      if(this.aaForm.value.import_validity_date != null && this.aaForm.value.import_validity_date != undefined && this.aaForm.value.import_validity_date != ''){
        if(this.aaForm.value.export_validity_date != null && this.aaForm.value.export_validity_date != undefined && this.aaForm.value.export_validity_date != ''){
          localStorage.setItem("aaheader", JSON.stringify(this.aaForm.value));
          this.router.navigate(["/aaDetails"]);
        } else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            'Please Select Export Validity Date',
            "announcement"
          );
        }
      } else{
        this.showNotification(
          "bottom",
          "right",
          "success",
          'Please Select Import Validity Date',
          "announcement"
        );
      }
      
    }
     else{
      this.showNotification(
        "bottom",
        "right",
        "success",
        'Please Select Authorization Date',
        "announcement"
      );
    }
  }

  back() {
    this.router.navigate(["/iecDetails"]);
  }

  save() {
    this.aaForm.value.auth_date = this.pipe.transform(
      this.aaForm.value.auth_date,
      "yyyy-MM-dd"
    );
    this.aaForm.value.import_validity_date = this.pipe.transform(
      this.aaForm.value.import_validity_date,
      "yyyy-MM-dd"
    );
    this.aaForm.value.export_validity_date = this.pipe.transform(
      this.aaForm.value.export_validity_date,
      "yyyy-MM-dd"
    );
      if( this.aaForm.value.auth_date != null && this.aaForm.value.auth_date != undefined && this.aaForm.value.auth_date != ''){
          if(this.aaForm.value.import_validity_date != null && this.aaForm.value.import_validity_date != undefined && this.aaForm.value.import_validity_date != ''){
            if( this.aaForm.value.export_validity_date != null && this.aaForm.value.export_validity_date != undefined && this.aaForm.value.export_validity_date != ''){
              localStorage.setItem("aaheader", JSON.stringify(this.aaForm.value));
            } else{
              this.showNotification(
                "bottom",
                "right",
                "success",
                'Please Select Export Validity Date',
                "announcement"
              );
            }
          }else{
            this.showNotification(
              "bottom",
              "right",
              "success",
              'Please Select Import Validity Date',
              "announcement"
            );
          }
        } else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            'Please Select Authorization Date',
            "announcement"
          );
        }
    
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
