import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface Summary {
  applicationSummary: string;
  details: string;
}

export interface CancelApplication {
  doc_name: string;
  viewPdf: string;
  received_status: string;
  remarks: string;
}


declare var $: any;

@Component({
  selector: 'app-reply-to-customs',
  templateUrl: './reply-to-customs.component.html',
  styleUrls: ['./reply-to-customs.component.css']
})
export class ReplyToCustomsComponent implements OnInit {
  selectedStatus = "";

  allStatus: Status[] = [
    { value: "0", viewValue: "YES" },
    { value: "1", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  regs: any;
  displayedColumns = [
    'applicationSummary',
    'details',
  ];
  autoFillForm = this.formBuilder.group({
    iec_number: [""],
    name_of_firm:[""],
    auth_type: [""],
    auth_no: [""],
    auth_date: [""],
    total_payment_made_against_auth: [""],
    date_of_payment_made_against_auth: [""],
  });
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");
  now = Date.now();
  date: any;
  modifiedCurrentDate: any;
  selectedFile1: File;
  selectedFile2: File;
  selectedFile3: File;
  selectedFile4: File;
  selectedFile5: File;
  selectedFile6: File;
  selectedFile7: File;
  selectedFile8: File;
  selectedFile9: File;
  selectedFile10: File;

  selectedFile11: File;
  selectedFile12: File;
  selectedFile13: File;
  selectedFile14: File;
  selectedFile15: File;
  selectedFile16: File;
  selectedFile17: File;
  selectedFile18: File;
  selectedFile19: File;
  selectedFile20: File;

  selectedFile21: File;

  dynamicArrayPdf = [];
  dynamicArrayExcel = [];
  rdm: UntypedFormArray = this.formBuilder.array([]);
  rdm1: UntypedFormArray = this.formBuilder.array([]);
  registerDocuments: UntypedFormGroup = this.formBuilder.group({ rdm_master: this.rdm });
  cancelDocuments: UntypedFormGroup = this.formBuilder.group({ rdm_master1: this.rdm1 });
  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;

  process: any;
  disable = false;
  viewPaymentsById: any;
  proof_of_payment_id: any;
  selectedFile: File;
  url: string | ArrayBuffer;
  showIECPdf: boolean = false;
  allApplicationTypes: any;
  applicationType: any;
  allApplicationDetails: any;
  summery:Summary[]=[];
  application_id: any;
  application: any;
  authorization_no: any;
  letter_type: any;
  companyName: any;
  letterTypeForApiCall: any;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); 
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));
    this.proof_of_payment_id = JSON.parse(localStorage.getItem("proof_of_payment_id"));
    this.authorization_no = JSON.parse(localStorage.getItem("authorization_no"));
    this.application = JSON.parse(localStorage.getItem("application_id"));
    this.letter_type = JSON.parse(localStorage.getItem("letter_type"));
    this.letterTypeForApiCall = JSON.parse(localStorage.getItem('letterType'));
    // this.setFormValue();
    this.getApplicationType();
    this.getMyMailboxApplicationDetailsByLetterNo(this.authorization_no);
  }

  getMyMailboxApplicationDetailsByLetterNo(authorization_no){
    let input={
      authorization_no:authorization_no    }
    this.blockchainService.getMyMailboxApplicationDetailsByLetterNo(input).subscribe((resp) => {
      this.allApplicationDetails = resp.body.obj;
      this.companyName=this.allApplicationDetails.company_name;
      console.log(this.allApplicationDetails);
      this.summery = [
        {
          applicationSummary: 'COMPANY NAME',
          details: this.allApplicationDetails.company_name,
        },
        {
          applicationSummary: 'IEC NO',
          details: this.allApplicationDetails.iec_no,
        },
        {
          applicationSummary: 'CUSTOMS BROKER NAME',
          details:this.allApplicationDetails.customs_broker_name,
        },
        {
          applicationSummary: 'CUSTOMS BROKER REGISTRATION ID',
          details: this.allApplicationDetails.customs_broker_registration_no,
        },
        {
          applicationSummary: 'ADVANCE AUTHORIZATION NO',
          details:this.allApplicationDetails.advanced_authorization_no,
        },
        {
          applicationSummary: 'ADVANCE AUTHORIZATION DATE',
          details:this.pipe.transform(this.allApplicationDetails.advanced_authorization_date, "dd-MM-yyyy"),
        },
        {
          applicationSummary: 'DGFT SURRENDER LETTER NO',
          details:this.allApplicationDetails.dgft_surrender_letter_no,
        },
        {
          applicationSummary: 'DGFT SURRENDER LETTER ISSUE DATE',
          // details: this.allApplicationDetails.dgft_surrender_letter_issue_date,
          details:this.pipe.transform(this.allApplicationDetails.dgft_surrender_letter_issue_date, "dd-MM-yyyy"),
        },
        {
          applicationSummary: 'EODC LETTER NO',
          details: this.allApplicationDetails.eodc_letter_no,
        },
        {
          applicationSummary: 'EODC LETTER DATE',
          // details: this.allApplicationDetails.eodc_letter_date,
          details:this.pipe.transform(this.allApplicationDetails.eodc_letter_date, "dd-MM-yyyy"),

        },
        {
          applicationSummary: 'DGFT REGULATION LETTER NO',
          details: this.allApplicationDetails.dgft_regularization_letter_no,
        },
        {
          applicationSummary: 'DGFT REGULATION LETTER DATE',
          // details: this.allApplicationDetails.dgft_regularization_letter_date,
          details:this.pipe.transform(this.allApplicationDetails.dgft_regularization_letter_date, "dd-MM-yyyy"),

        },
        {
          applicationSummary: 'CANCELLATION APPLICATION DATE',
          // details: this.allApplicationDetails.cancellation_application_date,
          details:this.pipe.transform(this.allApplicationDetails.cancellation_application_date, "dd-MM-yyyy"),

        },
        {
          applicationSummary: 'ORIGINAL FILE NO',
          details: this.allApplicationDetails.original_file_no,
        },
      ]
    });
  }
  addPdfRow() {
    this.dynamicArrayPdf.push({ otherPdf: '' });
    console.log('New row added successfully', 'New Row');
  }
  addExcelRow(){
    this.dynamicArrayExcel.push({ otherExcel: '' });
  }
  deletePdfRow(index) {
    this.dynamicArrayPdf.splice(index, 1);
  }
  deleteExcelRow(index) {
    this.dynamicArrayExcel.splice(index, 1);
  }

  getApplicationType() {
    this.blockchainService.getApplicationType().subscribe((resp) => {
      this.allApplicationTypes = resp.obj;
    });
  }
  selectedApplicationType(event, applicationType) {
    if (event.isUserInput) {
      console.log(event);
      this.applicationType = applicationType;
      this.autoFillForm.value.auth_type = applicationType;
      // this.selectedCityName = "";
      console.log(this.autoFillForm.value.auth_type)
    }
  }
  // selectedDocument:File;
  // remarksText:any
  submit() {
    
      if(this.selectedFile!=undefined || this.selectedFile != null){
        this.uploadFileInGreenSheet(this.selectedFile,"uploaded extra pdf 1st for reply to mailbox")
      } else{
        this.selectedFile = null;
        // this.uploadFileInGreenSheet(this.selectedFile,"uploaded extra pdf 1st for reply to mailbox")
      }
      if(this.selectedFile1!=undefined || this.selectedFile1 != null){
        this.uploadFileInGreenSheet(this.selectedFile1,"uploaded extra pdf 2nd for reply to mailbox")
      } else{
        this.selectedFile1 = null;
        // this.uploadFileInGreenSheet(this.selectedFile1,"uploaded extra pdf 2nd for reply to mailbox")
      }
      if(this.selectedFile2!=undefined || this.selectedFile2 != null){
        this.uploadFileInGreenSheet(this.selectedFile2,"uploaded extra pdf 3rd for reply to mailbox")
      } else{
        this.selectedFile2 = null;
        // this.uploadFileInGreenSheet(this.selectedFile2,"uploaded extra pdf 2nd for reply to mailbox")
      }
      if(this.selectedFile3!=undefined || this.selectedFile3 != null){
        this.uploadFileInGreenSheet(this.selectedFile3,"uploaded extra pdf 4th for reply to mailbox")
      }  else{
        this.selectedFile3 = null;
        // this.uploadFileInGreenSheet(this.selectedFile3,"uploaded extra pdf 4th for reply to mailbox")
      }
      if(this.selectedFile4!=undefined || this.selectedFile4 != null){
        this.uploadFileInGreenSheet(this.selectedFile4,"uploaded extra pdf 5th for reply to mailbox")
      }else{
        this.selectedFile4 = null;
        // this.uploadFileInGreenSheet(this.selectedFile4,"uploaded extra pdf 5th for reply to mailbox")
      }
      if(this.selectedFile5!=undefined || this.selectedFile5 != null){
        this.uploadFileInGreenSheet(this.selectedFile5,"uploaded extra pdf 6th for reply to mailbox")
      } else{
        this.selectedFile5 = null;
        // this.uploadFileInGreenSheet(this.selectedFile5,"uploaded extra pdf 6th for reply to mailbox")
      }
      if(this.selectedFile6!=undefined || this.selectedFile6 != null){
        this.uploadFileInGreenSheet(this.selectedFile6,"uploaded extra pdf 7th for reply to mailbox")
      } else{
        this.selectedFile6 = null;
        // this.uploadFileInGreenSheet(this.selectedFile6,"uploaded extra pdf 7th for reply to mailbox")
      }
      if(this.selectedFile7!=undefined || this.selectedFile7 != null){
        this.uploadFileInGreenSheet(this.selectedFile7,"uploaded extra pdf 8th for reply to mailbox")
      } else{
        this.selectedFile7 = null;
        // this.uploadFileInGreenSheet(this.selectedFile7,"uploaded extra pdf 8th for reply to mailbox")
      }
      if(this.selectedFile8!=undefined || this.selectedFile8 != null){
        this.uploadFileInGreenSheet(this.selectedFile8,"uploaded extra pdf 9th for reply to mailbox")
      } else{
        this.selectedFile8 = null;
        // this.uploadFileInGreenSheet(this.selectedFile8,"uploaded extra pdf 9th for reply to mailbox")
      }
      if(this.selectedFile9!=undefined || this.selectedFile9 != null){
        this.uploadFileInGreenSheet(this.selectedFile9,"uploaded extra pdf 10th for reply to mailbox")
      } else{
        this.selectedFile9 = null;
        // this.uploadFileInGreenSheet(this.selectedFile9,"uploaded extra pdf 10th for reply to mailbox")
      }
      if(this.selectedFile10!=undefined || this.selectedFile10 != null){
        this.uploadFileInGreenSheet(this.selectedFile10,"uploaded extra excel 1st for reply to mailbox")
      } else{
        this.selectedFile10 = null;
        // this.uploadFileInGreenSheet(this.selectedFile10,"uploaded extra excel 1st for reply to mailbox")
      }
      if(this.selectedFile11!=undefined || this.selectedFile11 != null){
        this.uploadFileInGreenSheet(this.selectedFile11,"uploaded extra excel 2nd for reply to mailbox")
      } else{
        this.selectedFile11 = null;
        // this.uploadFileInGreenSheet(this.selectedFile11,"uploaded extra excel 2nd for reply to mailbox")
      }
      if(this.selectedFile12!=undefined || this.selectedFile12 != null){
        this.uploadFileInGreenSheet(this.selectedFile12,"uploaded extra excel 3rd for reply to mailbox")
      } else{
        this.selectedFile12 = null;
        // this.uploadFileInGreenSheet(this.selectedFile12,"uploaded extra excel 3rd for reply to mailbox")
      }
      if(this.selectedFile13!=undefined || this.selectedFile13 != null){
        this.uploadFileInGreenSheet(this.selectedFile13,"uploaded extra excel 4th for reply to mailbox")
      } else{
        this.selectedFile13 = null;
        // this.uploadFileInGreenSheet(this.selectedFile13,"uploaded extra excel 4th for reply to mailbox")
      }
      if(this.selectedFile14!=undefined || this.selectedFile14 != null){
        this.uploadFileInGreenSheet(this.selectedFile14,"uploaded extra excel 5th for reply to mailbox")
      } else{
        this.selectedFile14 = null;
        // this.uploadFileInGreenSheet(this.selectedFile14,"uploaded extra excel 5th for reply to mailbox")
      }
      if(this.selectedFile15!=undefined || this.selectedFile15 != null){
        this.uploadFileInGreenSheet(this.selectedFile15,"uploaded extra excel 6th for reply to mailbox")
      } else{
        this.selectedFile15 = null;
        // this.uploadFileInGreenSheet(this.selectedFile15,"uploaded extra excel 6th for reply to mailbox")
      }
      if(this.selectedFile16!=undefined || this.selectedFile16 != null){
        this.uploadFileInGreenSheet(this.selectedFile16,"uploaded extra excel 7th for reply to mailbox")
      } else{
        this.selectedFile16 = null;
        // this.uploadFileInGreenSheet(this.selectedFile16,"uploaded extra excel 7th for reply to mailbox")
      }
      if(this.selectedFile17!=undefined || this.selectedFile17 != null){
        this.uploadFileInGreenSheet(this.selectedFile17,"uploaded extra excel 8th for reply to mailbox")
      } else{
        this.selectedFile17 = null;
        // this.uploadFileInGreenSheet(this.selectedFile17,"uploaded extra excel 8th for reply to mailbox")
      }
      if(this.selectedFile18!=undefined || this.selectedFile18 != null){
        this.uploadFileInGreenSheet(this.selectedFile18,"uploaded extra excel 9th for reply to mailbox")
      } else{
        this.selectedFile18 = null;
        // this.uploadFileInGreenSheet(this.selectedFile18,"uploaded extra excel 9th for reply to mailbox")
      }
      if(this.selectedFile19!=undefined || this.selectedFile19 != null){
        this.uploadFileInGreenSheet(this.selectedFile19,"uploaded extra excel 10th for reply to mailbox")
      } else{
        this.selectedFile19 = null;
        // this.uploadFileInGreenSheet(this.selectedFile19,"uploaded extra excel 10th for reply to mailbox")
      }
      if(this.selectedFile20!=undefined || this.selectedFile20 != null){
        this.uploadFileInGreenSheet(this.selectedFile20,"uploaded response letter pdf for reply to mailbox")
      } else{
        this.selectedFile20 = null;
        // this.uploadFileInGreenSheet(this.selectedFile20,"uploaded response letter pdf for reply to mailbox")
      }
      
  }

  checkSubmitedFile() {
    if(this.selectedFile==undefined || this.selectedFile == null){
      this.selectedFile = null;
    } 
    if(this.selectedFile1==undefined || this.selectedFile1 == null){
      this.selectedFile1 = null;
    } 
    if(this.selectedFile2==undefined || this.selectedFile2 == null){
      this.selectedFile2 = null;
    } 
    if(this.selectedFile3==undefined || this.selectedFile3 == null){
      this.selectedFile3 = null;
    } 
    if(this.selectedFile4==undefined || this.selectedFile4 == null){
      this.selectedFile4 = null;
    } 
    if(this.selectedFile5==undefined || this.selectedFile5 == null){
      this.selectedFile5 = null;
    } 
    if(this.selectedFile6==undefined || this.selectedFile6 == null){
      this.selectedFile6 = null;
    } 
    if(this.selectedFile7==undefined || this.selectedFile7 == null){
      this.selectedFile7 = null;
    } 
    if(this.selectedFile8==undefined || this.selectedFile8 == null){
      this.selectedFile8 = null;
    } 
    if(this.selectedFile9==undefined || this.selectedFile9 == null){
      this.selectedFile9 = null;
    } 
    if(this.selectedFile10==undefined || this.selectedFile10 == null){
      this.selectedFile10 = null;
    } 
    if(this.selectedFile11==undefined || this.selectedFile11 == null){
      this.selectedFile11 = null;
    } 
    if(this.selectedFile12==undefined || this.selectedFile12 == null){
      this.selectedFile12 = null;
    } 
    if(this.selectedFile13==undefined || this.selectedFile13 == null){
      this.selectedFile13 = null;
    } 
    if(this.selectedFile14==undefined || this.selectedFile14 == null){
      this.selectedFile14 = null;
    } 
    if(this.selectedFile15==undefined || this.selectedFile15 == null){
      this.selectedFile15 = null;
    } 
    if(this.selectedFile16==undefined || this.selectedFile16 == null){
      this.selectedFile16 = null;
    } 
    if(this.selectedFile17==undefined || this.selectedFile17 == null){
      this.selectedFile17 = null;
    } 
    if(this.selectedFile18==undefined || this.selectedFile18 == null){
      this.selectedFile18 = null;
    } 
    if(this.selectedFile19==undefined || this.selectedFile19 == null){
      this.selectedFile19 = null;
    } 
    if(this.selectedFile20==undefined || this.selectedFile20 == null){
      this.selectedFile20 = null;
    } 
    console.log(this.selectedFile, "check file");

}

submitReminderLetterDetails(){
  this.checkSubmitedFile();
  // if(this.letter_type=="Cancellation Query"){
    let input={
      reminderLetterId:this.application,
      response_Letter_File:this.selectedFile20,
      PDF1: this.selectedFile,
      PDF2: this.selectedFile1,
      PDF3: this.selectedFile2,
      PDF4: this.selectedFile3,
      PDF5: this.selectedFile4,
      PDF6: this.selectedFile5,
      PDF7: this.selectedFile6,
      PDF8: this.selectedFile7,
      PDF9: this.selectedFile8,
      PDF10: this.selectedFile9,
      EXCEL1: this.selectedFile10,
      EXCEL2: this.selectedFile11,
      EXCEL3: this.selectedFile12,
      EXCEL4: this.selectedFile13,
      EXCEL5: this.selectedFile14,
      EXCEL6: this.selectedFile15,
      EXCEL7: this.selectedFile16,
      EXCEL8: this.selectedFile17,
      EXCEL9: this.selectedFile18,
      EXCEL10: this.selectedFile19,
    };
  this.blockchainService.saveSCNGreenSheet(input).subscribe(
    (resp) => {
      console.log(input);
      console.log(resp.body.obj)
      if(resp.body.status==200){
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );	
         this.router.navigate(["/mailbox"]);
      }else{
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );	
      }
    })
// }
// else{
//   let input={
//     file1: this.selectedFile,
//     file2: this.selectedFile1,
//     file3: this.selectedFile2,
//     file4: this.selectedFile3,
//     file5: this.selectedFile4,
//     file6: this.selectedFile5,
//     file7: this.selectedFile6,
//     file8: this.selectedFile7,
//     file9: this.selectedFile8,
//     file10: this.selectedFile9,
//     file11: this.selectedFile10,
//     file12: this.selectedFile11,
//     file13: this.selectedFile12,
//     file14: this.selectedFile13,
//     file15: this.selectedFile14,
//     file16: this.selectedFile15,
//     file17: this.selectedFile16,
//     file18: this.selectedFile17,
//     file19: this.selectedFile18,
//     file20: this.selectedFile19,

//   };
//   this.blockchainService.saveGreenSheet(input).subscribe(
//     (resp) => {
//       console.log(input);
//       console.log(resp.body.obj)
//       if(resp.body.status==200){
//         this.showNotification(
//           "bottom",
//           "right",
//           "success",
//           resp.body.message,
//           "announcement"
//         );	
//         this.router.navigate(["/mailbox"]);
//       }else{
//         this.showNotification(
//           "bottom",
//           "right",
//           "success",
//           resp.body.message,
//           "announcement"
//         );	
//       }
//     })
// }
}
  
  back(){
    this.router.navigate(["/mailbox"]);
  }

  uploadFileInGreenSheet(file,fileName){
    console.log(file);
    if(this.letter_type=="Cancellation Query"){
    let input={
      cancellation_id:this.application,
      application_id:"0",
      // name:this.currentUser.obj.username,
      name:this.companyName,
      noteDate:this.todayDate,
      attached_document:file,
      remarks:fileName,
      html_flag:0,
      query_type:'query'
    }
    this.blockchainService.saveGreenSheet(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        if(resp.body.status==200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
           this.router.navigate(["/mailbox"]);
        }else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
        }
      })
  }
  else{
    let input={
      cancellation_id:"0",
      application_id:this.application,
      name:this.companyName,
      noteDate:this.todayDate,
      attached_document:file,
      remarks:fileName,
      html_flag:0,
      query_type:'query'
    }
    this.blockchainService.saveGreenSheet(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        if(resp.body.status==200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
          this.router.navigate(["/mailbox"]);
        }else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
        }
      })
  }
  }
  
  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd HH:mm:ss");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  sectionViewLetterDetails:any;
  openModalViewLetter(section) {
    this.sectionViewLetterDetails = section;
    console.log(this.sectionViewLetterDetails)
    const buttonModal = document.getElementById("openModalButton");
    // buttonModal.click();
  }

  onFileSelected(event, action) {
    if (action == 'pdf') {
      this.selectedFile = <File>event.target.files[0];
      if(this.selectedFile.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    } else if (action == 'pdf0') {
      this.selectedFile1 = <File>event.target.files[0];
      if(this.selectedFile1.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload1();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }

    } else if (action == 'pdf1') {
      this.selectedFile2 = <File>event.target.files[0];
      if(this.selectedFile2.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload2();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    } else if (action == 'pdf2') {
      this.selectedFile3 = <File>event.target.files[0];
      if(this.selectedFile3.type == 'application/pdf'){
        var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload3();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
      
    } else if (action == 'pdf3') {
      this.selectedFile4 = <File>event.target.files[0];
      if(this.selectedFile4.type == 'application/pdf'){
        var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload4();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
      
    } else if (action == 'pdf4') {
      this.selectedFile5 = <File>event.target.files[0];
      if(this.selectedFile5.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload5();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    } else if (action == 'pdf5') {
      this.selectedFile6 = <File>event.target.files[0];
      if(this.selectedFile6.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload6();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    }
    else if (action == 'pdf6') {
      this.selectedFile7 = <File>event.target.files[0];
      if(this.selectedFile7.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload7();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    }
    else if (action == 'pdf7') {
      this.selectedFile8 = <File>event.target.files[0];
      if(this.selectedFile8.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload8();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    }
    else if (action == 'pdf9') {
      this.selectedFile9 = <File>event.target.files[0];
      if(this.selectedFile9.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload9();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    }
    else if (action == 'excel') {
     
      this.selectedFile10 = <File>event.target.files[0];
      if(this.selectedFile10.type == '.csv' || this.selectedFile10.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.selectedFile10.type == 'application/vnd.ms-excel'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload10();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    }
    else if (action == 'excel0') {
      this.selectedFile11 = <File>event.target.files[0];
      if(this.selectedFile11.type == '.csv' || this.selectedFile11.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.selectedFile11.type == 'application/vnd.ms-excel'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload11();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    }  
    else if (action == 'excel1') {
      this.selectedFile12 = <File>event.target.files[0];
      if(this.selectedFile12.type == '.csv' || this.selectedFile12.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.selectedFile12.type == 'application/vnd.ms-excel'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload12();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    }
    else if (action == 'excel2') {
      this.selectedFile13 = <File>event.target.files[0];
      if(this.selectedFile13.type == '.csv' || this.selectedFile13.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.selectedFile13.type == 'application/vnd.ms-excel'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload13();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    }
    else if (action == 'excel3') {
      this.selectedFile14 = <File>event.target.files[0];
      if(this.selectedFile14.type == '.csv' || this.selectedFile14.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.selectedFile14.type == 'application/vnd.ms-excel'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload14();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    }
    else if (action == 'excel4') {
      this.selectedFile15 = <File>event.target.files[0];
      if(this.selectedFile15.type == '.csv' || this.selectedFile15.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.selectedFile15.type == 'application/vnd.ms-excel'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload15();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    
    }
    else if (action == 'excel5') {
      this.selectedFile16 = <File>event.target.files[0];
      if(this.selectedFile16.type == '.csv' || this.selectedFile16.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.selectedFile16.type == 'application/vnd.ms-excel'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload16();
      }
     
    }   else if (action == 'excel6') {
      this.selectedFile17 = <File>event.target.files[0];
      if(this.selectedFile17.type == '.csv' || this.selectedFile17.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.selectedFile17.type == 'application/vnd.ms-excel'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload17();
      }
     
    }   else if (action == 'excel7') {
      this.selectedFile18 = <File>event.target.files[0];
      if(this.selectedFile18.type == '.csv' || this.selectedFile18.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.selectedFile18.type == 'application/vnd.ms-excel'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload18();
      }
     
    }   else if (action == 'excel8') {
      this.selectedFile19 = <File>event.target.files[0];
      if(this.selectedFile19.type == '.csv' || this.selectedFile19.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      this.selectedFile19.type == 'application/vnd.ms-excel'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload19();
      }
     
    }   else if (action == 'res') {
      this.selectedFile20 = <File>event.target.files[0];
      if(this.selectedFile20.type == 'application/pdf'){
        var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
        console.log(this.url);
      };
      this.onUpload20();
      }
      
    }

  }

  //for iec  
  onUpload() {
    const fd = new FormData();
    fd.append("file", this.selectedFile, this.selectedFile.name);
  }

  onUpload1() {
    const fd = new FormData();
    fd.append("file", this.selectedFile1, this.selectedFile1.name);
  }

  onUpload2() {
    const fd = new FormData();
    fd.append("file", this.selectedFile2, this.selectedFile2.name);
  }

  onUpload3() {
    const fd = new FormData();
    fd.append("file", this.selectedFile3, this.selectedFile3.name);
  }

  onUpload4() {
    const fd = new FormData();
    fd.append("file", this.selectedFile4, this.selectedFile4.name);
  }

  onUpload5() {
    const fd = new FormData();
    fd.append("file", this.selectedFile5, this.selectedFile5.name);
  }

  onUpload6() {
    const fd = new FormData();
    fd.append("file", this.selectedFile6, this.selectedFile6.name);
  }

  onUpload7() {
    const fd = new FormData();
    fd.append("file", this.selectedFile7, this.selectedFile7.name);
  }

  onUpload8() {
    const fd = new FormData();
    fd.append("file", this.selectedFile8, this.selectedFile8.name);
  }
  onUpload9() {
    const fd = new FormData();
    fd.append("file", this.selectedFile9, this.selectedFile9.name);
  }
  onUpload10() {
    const fd = new FormData();
    fd.append("file", this.selectedFile10, this.selectedFile10.name);
  }
  onUpload11() {
    const fd = new FormData();
    fd.append("file", this.selectedFile11, this.selectedFile11.name);
  }
  onUpload12() {
    const fd = new FormData();
    fd.append("file", this.selectedFile12, this.selectedFile12.name);
  }
  onUpload13() {
    const fd = new FormData();
    fd.append("file", this.selectedFile13, this.selectedFile13.name);
  }
  onUpload14() {
    const fd = new FormData();
    fd.append("file", this.selectedFile14, this.selectedFile14.name);
  }
  onUpload15() {
    const fd = new FormData();
    fd.append("file", this.selectedFile15, this.selectedFile15.name);
  }
  onUpload16() {
    const fd = new FormData();
    fd.append("file", this.selectedFile16, this.selectedFile16.name);
  }
  onUpload17() {
    const fd = new FormData();
    fd.append("file", this.selectedFile17, this.selectedFile17.name);
  }
  onUpload18() {
    const fd = new FormData();
    fd.append("file", this.selectedFile18, this.selectedFile18.name);
  }
  onUpload19() {
    const fd = new FormData();
    fd.append("file", this.selectedFile19, this.selectedFile19.name);
  }
  onUpload20() {
    const fd = new FormData();
    console.log(this.selectedFile20);
    fd.append("file", this.selectedFile20, this.selectedFile20.name);
  }



  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: '10',
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
