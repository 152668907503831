import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService, ImportAAQuery, SCNQuery } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { isThursday } from "date-fns";
import { el } from "date-fns/locale";

declare var $: any;

export interface SCN {
  iec_no: String,
  aa_no: String,
  scn_no: String,
  scn_date: String,
  amount: String,
}


@Component({
  selector: 'app-revenue-stake',
  templateUrl: './revenue-stake.component.html',
  styleUrls: ['./revenue-stake.component.css']
})
export class RevenueStakeComponent implements OnInit {
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  date: any;
  @BlockUI() blockUI: NgBlockUI;

  blockTemplate: BlockTemplateComponent;
  initialSort: Sort<SCN> = { property: "scn_date", order: "asc" };

  displayedColumns = [
    "sr_no",
    "iec_no",
    "aa_no",
    "scn_no",
    "scn_date",
    "amount",
  ];
  recoveryResp: any;
  yearWiseRecoveryResp: any;
  year2019Resp: any=[];
  year2020Resp: any=[];
  year2021Resp: any=[];
  year2022Resp:any=[];
  year2023Resp:any=[];
 

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { }
  loader:boolean;
  ngOnInit(): void {
    this.date = this.todayDate;
    this.loader=true;
    this.getYearWiseRecoveryByYear();
    // this.loader=true;
  }

  getYearWiseRecoveryByYear(){
    let input = {
      // start_date : this.date
    }
    this.blockUI.start();
    this.loader=true;
    this.blockchainService.getYearWiseRecoveryByYear(input).subscribe(resp => {
      if (resp.body.status == 200) {
        this.yearWiseRecoveryResp = resp.body.obj;
        this.loader=false;
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        this.yearWiseRecoveryResp.forEach(element => {
          if(element.year1 == '2019'){
            this.year2019Resp.push(element) 
          } 
          if(element.year1 == '2020'){
            this.year2020Resp.push(element)
          }
          if(element.year1 == '2021'){
            this.year2021Resp.push(element)
          }
          if(element.year1 == '2022'){
            this.year2022Resp.push(element)
          }
          if(element.year1 == '2023'){
            this.year2023Resp.push(element)
          }
        });
      
        console.log(this.year2019Resp,'this.year2019Resp');
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
       
      } else {
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    })
  }
  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
