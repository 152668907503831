import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

@Component({
  selector: 'app-welcomeuser',
  templateUrl: './welcomeuser.component.html',
  styleUrls: ['./welcomeuser.component.css']
})
export class WelcomeuserComponent implements OnInit {
  currentUser: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { }

  ngOnInit(): void {
    localStorage.removeItem("application_id");
    localStorage.removeItem("applicationType");
    
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }
  payments(){
    this.router.navigate(["/paymentsummary"]);
  }

  profile(){
    this.router.navigate(["/editProfile"]);
  }

  registration(){
    localStorage.removeItem('application_id');
    this.router.navigate(["/typeRole"]);
  }

  history(){
    this.router.navigate(["/history"]);
  }

  cancellation(){
    // localStorage.removeItem('cancellation_id');
    this.router.navigate(["/cancelDocUpload"]);
  }
}
