import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { UntypedFormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService, RegistrationDetailsQuery, RegistrationQuery } from "app/blockchainImpExp/blockchain.service";
import { Month } from "../../custom-payment/payment-daily-summary/month";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { AlertService } from "app/_services";

var $: any;
export interface Status {
  value: string;
  viewValue: string;
}

export interface MonthType {
  value: string;
  viewValue: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


export interface calendarView {
  value: string;
  viewValue: string;
}

export interface Registration {
  systemId: number;
  imp_exp_name: string;
  iec_code: string;
  file_no: string;
  application_type: string;
  authorization_type: string;
  authorization_no: string;
  stage: string;
  pending_level: string;
  officer_name: string;
  incoming_date: string;
  viewCaseDetails: string;
  application_status: string;
  query_by_customs:string;
  completion_date: string;

}

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.css']
})
export class Step1Component implements OnInit {
  @ViewChild(MatPaginator, { static: false }) paginator2: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) paginator1: MatPaginator;
  selectedLevel = "PENDING";
  pendingApp: number;
  selectedStatus = 'New';

  allStatus: Status[] = [
    { value: 'incommingDate', viewValue: 'Incoming Date' },
    { value: 'applicationType', viewValue: 'Application Type' },
    { value: 'stage', viewValue: 'Application Stage' },
    { value: 'status', viewValue: 'Application Status' },
    { value: 'fileNo', viewValue: 'File No' },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  currentUser: any;
  role: any;
  totalEntries;
  dataSource: Registration[] = [];
  
  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  displayedColumns = [
    'systemId',
    'imp_exp_name',
    'iec_code',
    'file_no',
    'application_type',
    'license_no',
    'stage',
    'pending_level',
    'officer_name',
    'incoming_date',
    'viewCaseDetails',
    'application_status',
    'query_by_customs',
    'completion_date',
    'actionAwaited_L1',
    'actionAwaited_L2'
  ];

  displayedColumns1 = [
    'systemId',
    'imp_exp_name',
    'iec_code',
    'file_no',
    'authorization_type',
    'authorization_no',
    'scn_pending',
    'stage',
    'pending_level',
    'officer_name',
    'incoming_date',
    'viewCaseDetails',
    'application_status',
    'query_by_customs',
    'completion_date',
  ];

  autoFillForm = this.formBuilder.group({
    username: [""],
    companyname: [""],
    iecCode: [""],
  });
  summaryData:any;
  summaryData1:any;
  // summaryData: PaginatedDataSource<Registration, RegistrationDetailsQuery>;
  // summaryData1: PaginatedDataSource<Registration, RegistrationDetailsQuery>;
  initialSort: Sort<Registration> = { property: "systemId", order: "desc" };
  user: any;
  process: any;
  urlR: string;
  disablebtn: boolean;
  selectedFilter: string;
  filterData: any;
  filterByDataReg: Status[] = [
    { value: 'IMP EXP Name', viewValue: 'IMP EXP Name' },
    { value: 'IEC Code', viewValue: 'IEC Code' },
    { value: 'Authorization No', viewValue: 'Authorization No' },
    { value: 'Pending Level', viewValue: 'Pending Level' },
    { value: 'Stages', viewValue: 'Stages' },
    { value: 'Officer Name', viewValue: 'Officer Name' },
    { value: 'Application Status', viewValue: 'Application Status' },
    { value: 'Query By Customs', viewValue: 'Query By Customs' },
    { value: 'Incomming Date', viewValue: 'Incomming Date' },
    // { value: 'Application Type', viewValue: 'Application Type' },
  ]

  filterByDataCan: Status[] = [
    { value: 'IMP EXP Name', viewValue: 'IMP EXP Name' },
    { value: 'IEC Code', viewValue: 'IEC Code' },
    { value: 'SCN Pending', viewValue: 'SCN Pending' },
    { value: 'Authorisation No', viewValue: 'Authorisation No' },
    { value: 'Pending Level', viewValue: 'Pending Level' },
    { value: 'Stages', viewValue: 'Stages' },
    { value: 'Officer Name', viewValue: 'Officer Name' },
    { value: 'Application Status', viewValue: 'Application Status' },
    { value: 'Query By Customs', viewValue: 'Query By Customs' },
    { value: 'Incomming Date', viewValue: 'Incomming Date' },
    // { value: 'Authorisation Type', viewValue: 'Authorisation Type' }, 
  ]
  filterQueryStatusReg: Status[] = [
    { value: "Query Generated By L1", viewValue: "Query Generated By L1" },
    { value: "Query Sent", viewValue: "Query Sent" },
    { value: "Query Response Received", viewValue: "Query Response Received" },
    { value: "N/A", viewValue: "N/A" },
  ];
  filterQueryStatusCan: Status[] = [
    { value: "Query Generated By L1", viewValue: "Query Generated By L1" },
    { value: "Query Sent", viewValue: "Query Sent" },
    { value: "Query Response Received", viewValue: "Query Response Received" },
    { value: "N/A", viewValue: "N/A" },

  ];
  filterStagesReg: Status[] = [
    { value: 'Initiate', viewValue: 'Initiate' },
    { value: 'Validate', viewValue: 'Validate' },
    { value: 'Create Job', viewValue: 'Create Job' },
    { value: 'Accept Job', viewValue: 'Accept Job' },
    { value: 'Bond and Bbg', viewValue: 'Bond and Bbg' },
    { value: 'Completed', viewValue: 'Completed' },
    { value: 'Rejected', viewValue: 'Rejected' },
  ]

  filterStagesCan: Status[] = [
    { value: 'Initiate', viewValue: 'Initiate' },
    { value: 'Validate', viewValue: 'Validate' },
    { value: 'Update Register', viewValue: 'Update Register' },
    { value: 'Document Return', viewValue: 'Document Return' },
    { value: 'Rejected', viewValue: 'Rejected' },
  ]

  confirmationStatus: Status[] = [
    { value: "L1", viewValue: "L1" },
    { value: "L2", viewValue: "L2" },
    { value: "L1-L2", viewValue: "L1-L2" },
    { value: "N/A", viewValue: "Not Pending" },
  ]
  applicationStatus: Status[] = [
    { value: "0", viewValue: "Received" },
    { value: "1", viewValue: "Approved" },
    { value: "2", viewValue: "Rejected" },
  ]
  licenseStatus: Status[] = [
    { value: "AA", viewValue: "AA" },
    { value: "EPCG", viewValue: "EPCG" },
  ];
  selectedView: any;

  allCalendarView: calendarView[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Weekly", viewValue: "Weekly" },
    { value: "Monthly", viewValue: "Monthly" },
    { value: "Date Range", viewValue: "Date Range" },
  ];
  date: any;
  modifiedCurrentDate: any;
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  isButtonVisible = false;
  first_day_of_month: any;
  last_day_of_month: any;
  currentDate = new Date();
  firstDay: Date;
  lastDay: Date;

  events: string[] = [];
  events1: string[] = [];
  datepick = true;
  monthselectedValue: any;
  public selectedDate = new UntypedFormControl(new Date());
  public selectedDateStart = new UntypedFormControl(new Date());
  public selectedDateEnd = new UntypedFormControl(new Date());
  public months: Month[];
  public selectedMonth: Month;
  isMonthly: boolean;
  isDay: boolean;
  isWeekly: boolean;
  isRange: boolean;
  showTable: boolean;
  sendInput: any;
  dateData: any;
  startDate: any;
  endDate: any;
  offsetData: any;
  pageSet: number;
  pageSizeChange: number;
  dataSource1: MatTableDataSource<any>;
  loggedUserLevel: any;
  loader: boolean;
  loaderCancellation: boolean;
  offset: number;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('en-GB');
    this.offsetData = JSON.parse(localStorage.getItem("offset"));
    this.pageSet = this.offsetData - 1;
    this.pageSizeChange = JSON.parse(localStorage.getItem("pageSize"));
  }

  ngOnInit(): void {
    // console.log(this.router.url,"Current URL");
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.loggedUserLevel=this.currentUser.obj.level;
    console.log(this.currentUser.obj.level);
    this.role = this.currentUser.obj.user_type_id;
    this.user = this.currentUser.obj.user_id;
    this.urlR = this.router.url;
    this.loader = false;
    this.loaderCancellation=false;
    this.pageSizeChange=10;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    localStorage.setItem('stages', "");
    if (this.urlR == '/registration1' || this.urlR == '/registration_1') {
      delete localStorage["process"];
      localStorage.setItem('process', JSON.stringify('registration'));
    }
    else if (this.urlR == '/cancellation1') {
      delete localStorage["process"];
      localStorage.setItem('process', JSON.stringify('cancellation'));
    }

    this.process = JSON.parse(localStorage.getItem("process"));

    // console.log(this.offsetData);

    if (this.offsetData == null) {
      this.offsetData = 1
    }
    if(this.selectedLevel=='PENDING'){
      this.pendingApp = 1;
    }else{
      this.pendingApp = 0;
    }
    this.pageSet = 0;
    //this.pageSizeChange = 10;
    localStorage.setItem('offset', JSON.stringify(this.offsetData));
    this.processType(this.process, this.offsetData);
    
  }


  processType(type, offsetData) {
    if(this.pageSizeChange == null ){
      this.pageSizeChange = 10
    }
   
    this.loadMonths();
    if (type == 'registration') {
      // this.getApplicationListByUserId(this.user);
      this.getApplicationListByUserIdPagination(this.user, offsetData,this.pageSizeChange,this.pendingApp);
    }
    else {
      this.getCancellationListByUserIdPagination(this.user, offsetData,this.pageSizeChange,this.pendingApp);
      // this.getCancellationListByUserId(this.user);
    }
  }

  getApplicationListByUserIdPagination(input, offSetData,pageSizeSet,pending) {

    let user = {
      user_id: input,
      offset: offSetData,
      entries_per_page:pageSizeSet,
      pending_app:pending
    }
    this.loader=true;
    this.blockUI.start();
    this.blockchainService.getApplicationListByUserIdPagination(user).subscribe(
      (resp) => {
        this.loader=false;
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        this.dataSource = resp.body.obj.asr;
        this.summaryData = resp.body.obj.asr;
        this.totalEntries = resp.body.obj.total_entries;
        this.summaryData.paginator=this.paginator2;
        // this.dataSource.forEach(st => {
        //   if (st.application_status == '0') {
        //     st.application_status = 'Received';
        //   } else if (st.application_status == '1') {
        //     st.application_status = 'Approved';
        //   } else {
        //     st.application_status = 'Rejected';
        //   }
        // })
        this.dataSource.forEach(st => {
          // if (st.application_status == '0') {
          //   st.application_status = 'Received';
          // } else if (st.application_status=='1' && st.stage=='completed'){ 
          //   st.application_status = 'Approved';
          // } else if (st.application_status=='1' && st.stage!='completed'){ 
          //   st.application_status = 'Received';
          // }
          //  else {
          //   st.application_status = 'Rejected';
          // }
          if (st.application_status=='1' && st.stage=='Completed'){ 
            st.application_status = 'Approved';
          }
          else if ( st.stage=='Rejected'){
            st.application_status = 'Rejected';
          }
          else{
            st.application_status = 'Received';
          } 
        })
        if (this.summaryData != null) {
          var summaryList = resp.body.obj.asr;
          this.summaryData = new PaginatedDataSource<Registration, RegistrationDetailsQuery>(
            (request, query) =>
              this.blockchainService.pageRegistration(request, query, summaryList),
            this.initialSort,
            { search: "", data: undefined },
            
          );
          this.summaryData.pageSize=this.pageSizeChange;

        }
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }

  getCancellationListByUserIdPagination(input, offSetData,pageSizeSet,pending) {
    let user = {
      user_id: input,
      offset: offSetData,
      entries_per_page:pageSizeSet,
      pending_app:pending
    }
    // this.loader=true;
    this.loaderCancellation=true;
    this.blockUI.start();
    this.blockchainService.getCancellationListByUserIdPagination(user).subscribe(
      (resp) => {
        // this.loader = false;
        this.loaderCancellation=false;
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        this.dataSource = resp.body.obj.clsr;
        this.summaryData1 = resp.body.obj.clsr;
        this.totalEntries = resp.body.obj.total_entries;
        this.summaryData1.paginator=this.paginator1;
        // this.dataSource.forEach(st => {
        //   if (st.application_status == '0') {
        //     st.application_status = 'Received';
        //   } else if (st.application_status == '1') {
        //     st.application_status = 'Approved';
        //   } else {
        //     st.application_status = 'Rejected';
        //   }
        // })
        this.dataSource.forEach(st => {
          if (st.application_status=='1' && st.stage=='Completed'){ 
            st.application_status = 'Approved';
          }
          else if ( st.stage=='Rejected'){
            st.application_status = 'Rejected';
          }
          else{
            st.application_status = 'Received';
          } 
        })
        if (this.dataSource != null) {

          var summaryList1 = resp.body.obj.clsr;
          this.summaryData1 = new PaginatedDataSource<Registration, RegistrationDetailsQuery>(
            (request, query) =>
              this.blockchainService.pageCancellation(request, query, summaryList1),
            this.initialSort,
            { search: "", data: undefined },
            
          );
          this.summaryData1.pageSize=this.pageSizeChange;
        }
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            location.reload();
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }

  getNextReg(event: PageEvent) {
    console.log(event);
    this.offset = event.pageIndex;
    this.pageSizeChange = event.pageSize;
    this.pageSet=this.offset;
  //  this.paginator1.pageSize=event.pageSize;
  //  console.log(this.offset);
  //  console.log(this.pageSet);
  //  console.log(this.pageSizeChange);
  //  console.log(this.paginator2);
    if ((this.selectedFilter != null || this.selectedFilter != undefined) && this.filterData != null) {

      // this.pageSet = this.offsetData - 1;
      // this.pageSet = this.offset;

      let filter = JSON.parse(localStorage.getItem("filter"));
      console.log(event);
      console.log(this.offset);
      if (event.previousPageIndex == 0) {
        console.log(event);
        filter.offset = this.offset+1;
        filter.entries_per_page = this.pageSizeChange;
        this.pageSet = this.offset;
        localStorage.setItem('offset', JSON.stringify(this.offset + 1));
        // localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
        this.getApplicationFilterListByUserId(filter);
      } else {
        filter.offset = this.offset;
        filter.entries_per_page = this.pageSizeChange;
        this.pageSet = this.offset;
        localStorage.setItem('offset', JSON.stringify(this.offset + 1));
        // localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
        this.getApplicationFilterListByUserId(filter);
      }
      
      
    } else {
    //  console.log(this.pageSizeChange);
      if (event.previousPageIndex == 0) {
        localStorage.setItem('offset', JSON.stringify(this.offset + 1));
        localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));

        this.getApplicationListByUserIdPagination(this.currentUser.obj.user_id, this.offset + 1,this.pageSizeChange,this.pendingApp)
      } else {
  

        localStorage.setItem('offset', JSON.stringify(this.offset + 1));
        localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
        this.getApplicationListByUserIdPagination(this.currentUser.obj.user_id, this.offset + 1,this.pageSizeChange,this.pendingApp)
      }
    }

  }
  // getNextCan(event: PageEvent) {
  //   let offset = event.pageIndex;
  //   this.pageSizeChange = event.pageSize;
  //   if ((this.selectedFilter != null || this.selectedFilter != undefined) && this.filterData != null) {
  //     this.pageSet = this.offsetData - 1;
  //     let filter = JSON.parse(localStorage.getItem("filter"));
  //     if (event.previousPageIndex == 0) {
  //       localStorage.setItem('offset', JSON.stringify(offset + 1));
  //       filter.offset = offset + 1;
  //       filter.entries_per_page = this.pageSizeChange;
  //       this.getCancellationFilterListByUserId(filter);
  //     } else {
  //       localStorage.setItem('offset', JSON.stringify(offset + 1));
  //       filter.offset = offset + 1;
  //       filter.entries_per_page = this.pageSizeChange;
  //       this.getCancellationFilterListByUserId(filter);
  //     }
      
  //   } else {
  //     if (event.previousPageIndex == 0) {
  //       localStorage.setItem('offset', JSON.stringify(offset + 1));
  //       this.getCancellationListByUserIdPagination(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange,this.pendingApp)
  //     } else {
  //       localStorage.setItem('offset', JSON.stringify(offset + 1));
  //       this.getCancellationListByUserIdPagination(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange,this.pendingApp)
  //     }
  //   }

  // }
  getNextCan(event: PageEvent) {
    console.log(event);
    this.offset = event.pageIndex;
    this.pageSizeChange = event.pageSize;
    this.pageSet=this.offset;
    if ((this.selectedFilter != null || this.selectedFilter != undefined) && this.filterData != null) {

      // this.pageSet = this.offsetData - 1;
      // this.pageSet = this.offset;

      let filter = JSON.parse(localStorage.getItem("filter"));
      console.log(event);
      console.log(this.offset);
      if (event.previousPageIndex == 0) {
        console.log(event);
        filter.offset = this.offset+1;
        filter.entries_per_page = this.pageSizeChange;
        this.pageSet = this.offset;
        localStorage.setItem('offset', JSON.stringify(this.offset + 1));
        // localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
        this.getCancellationFilterListByUserId(filter);
      } else {
        filter.offset = this.offset;
        filter.entries_per_page = this.pageSizeChange;
        this.pageSet = this.offset;
        localStorage.setItem('offset', JSON.stringify(this.offset + 1));
        // localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
        this.getCancellationFilterListByUserId(filter);
      }
      
      
    } else {
    //  console.log(this.pageSizeChange);
      if (event.previousPageIndex == 0) {
        localStorage.setItem('offset', JSON.stringify(this.offset + 1));
        localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));

        this.getCancellationListByUserIdPagination(this.currentUser.obj.user_id, this.offset + 1,this.pageSizeChange,this.pendingApp)
      } else {
  

        localStorage.setItem('offset', JSON.stringify(this.offset + 1));
        localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
        this.getCancellationListByUserIdPagination(this.currentUser.obj.user_id, this.offset + 1,this.pageSizeChange,this.pendingApp)
      }
    }
  }
  getApplicationListByUserId(input) {
    let user = {
      user_id: input
    }
    this.blockchainService.getApplicationListByUserId(user).subscribe(
      (resp) => {
        this.dataSource = resp.body.obj;
        this.dataSource.forEach(st => {
          if (st.application_status == '0') {
            st.application_status = 'Received';
          } else if (st.application_status == '1') {
            st.application_status = 'Approved';
          } else {
            st.application_status = 'Rejected';
          }
        })
        if (this.dataSource != null) {

          var summaryList = this.dataSource;
          this.summaryData = new PaginatedDataSource<Registration, RegistrationDetailsQuery>(
            (request, query) =>
              this.blockchainService.pageRegistration(request, query, summaryList),
            this.initialSort,
            { search: "", data: undefined },
            
          );
        }
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }

  getCancellationListByUserId(input) {
    let user = {
      user_id: input
    }
    this.blockchainService.getCancellationListByUserId(user).subscribe(
      (resp) => {
        this.dataSource = resp.body.obj;
        // this.dataSource.forEach(st => {
        //   if (st.application_status == '0') {
        //     st.application_status = 'Received';
        //   } else if (st.application_status == '1') {
        //     st.application_status = 'Approved';
        //   } else {
        //     st.application_status = 'Rejected';
        //   }
        // })
        this.dataSource.forEach(st => {
          // if (st.application_status == '0') {
          //   st.application_status = 'Received';
          // } else if (st.application_status=='1' && st.stage=='Completed'){ 
          //   st.application_status = 'Approved';
          // } else if (st.application_status=='1' && st.stage!='Completed'){ 
          //   st.application_status = 'Received';
          // }
          //  else {
          //   st.application_status = 'Rejected';
          // }
          if (st.application_status=='1' && st.stage=='Completed'){ 
            st.application_status = 'Approved';
          }
          else if ( st.stage=='Rejected'){
            st.application_status = 'Rejected';
          }
          else{
            st.application_status = 'Received';
          } 
        })
        if (this.dataSource != null) {

          var summaryList1 = this.dataSource;
          this.summaryData1 = new PaginatedDataSource<Registration, RegistrationDetailsQuery>(
            (request, query) =>
              this.blockchainService.pageCancellation(request, query, summaryList1),
            this.initialSort,
            { search: "", data: undefined },
          
          );
        }
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }

  editRegistrationDetails(data) {
    localStorage.setItem('casedetailsData', JSON.stringify(data));
    localStorage.setItem('stages', data.stage);
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    if (this.currentUser.obj.level == 'L1') {
      localStorage.setItem('stages', data.stage);
      if (data.stage == 'Initiate') {
        this.router.navigate(['/registration2']);
      } else {
        this.router.navigate(['/registrationValidate']);
      }

    } else {
      this.router.navigate(['/registrationValidate']);
    }
  }
  editCancellationDetails(data) {
    localStorage.setItem('casedetailsData', JSON.stringify(data));
    localStorage.setItem('stages', data.stage);
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    if (this.currentUser.obj.level == 'L1') {
      localStorage.setItem('stages', data.stage);
      if (data.stage == 'Initiate') {
        this.router.navigate(['/cancellation2']);
      } else {
        this.router.navigate(['/cancelNotesheet']);
      }
    }
    else {
      this.router.navigate(['/cancelNotesheet']);
    }
  }

  onFilterSelected(event, status) {
    this.filterData = '';
    this.isMonthly = false;
    this.isDay = false;
    this.isWeekly = false;
    this.isRange = false;
    if (event.isUserInput) {
      this.selectedFilter = status;
    }
  }
  resetFilters() {

    this.selectedFilter = '';
    this.selectedFilter = null;
    this.filterData = '';
    this.isMonthly = false;
    this.isDay = false;
    this.isWeekly = false;
    this.isRange = false;
    this.dataSource = [];
    this.pageSet = 0;
    this.offsetData = 1;
    //this.pageSizeChange = 10;
    localStorage.setItem('offset', JSON.stringify(this.offsetData));
    this.ngOnInit();
  }
  changeConfirmationStatus(status) {
    this.filterData = status;
  }
  changeQueryStatus(status) {
    this.filterData = status;
  }

  getFilterData(data, filter) {
    this.blockUI.start();
    this.loggedUserLevel=this.currentUser.obj.level;
    console.log(this.loggedUserLevel);
    this.dataSource=[];
    this.pageSet = 0;
    this.offsetData = 1
    localStorage.setItem('offset', JSON.stringify(this.offsetData));
    if (filter == 'IMP EXP Name') {
      this.sendInput = {
        imp_exp_name: data,
        user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        pending_app:this.pendingApp
      }
    }
    else if (filter == 'IEC Code') {
      this.sendInput = {
        iec_code: data,
        user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        pending_app:this.pendingApp
      }
      console.log(this.sendInput);
    }
    else if (filter == 'Authorization No') {
      this.sendInput = {
        license_no: data,
        user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        pending_app:this.pendingApp
      }
    } else if (filter == 'Authorisation No') {
      this.sendInput = {
        authorization_no: data,
        user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        pending_app:this.pendingApp
      }
    }else if (filter == 'Officer Name') {
        this.sendInput = {
          officer_name: data,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
    } else if (this.selectedFilter == 'Application Type') {
      if (this.filterData == "AA") {
        this.sendInput = {
          application_type: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "EPCG") {
        this.sendInput = {
          application_type: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
    } else if (this.selectedFilter == 'Authorisation Type') {
      if (this.filterData == "AA") {
        this.sendInput = {
          authorization_type: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "EPCG") {
        this.sendInput = {
          authorization_type: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
    } else if (this.selectedFilter == 'Pending Level') {
      if (this.filterData == "L1") {
        this.sendInput = {
          pending_level: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "L2") {
        this.sendInput = {
          pending_level: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "L1-L2") {
        this.sendInput = {
          pending_level: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "N/A") {
        this.sendInput = {
          pending_level: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
    } else if (this.selectedFilter == 'Application Status') {
      if (this.filterData == "0") {
        this.sendInput = {
          application_status: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "1") {
        this.sendInput = {
          application_status: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "2") {
        this.sendInput = {
          application_status: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
    }else if (this.selectedFilter == 'Query By Customs') {
      if (this.filterData == "Query Sent") {
        this.sendInput = {
          query_status: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Query Response Received") {
        this.sendInput = {
          query_status: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Query Generated By L1") {
        this.sendInput = {
          query_status: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "N/A") {
        this.sendInput = {
          query_status: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }

    }else if (this.selectedFilter == 'Stages') {
      if(this.process=='registration'){
      if (this.filterData == "Initiate") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Validate") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Create Job") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Accept Job") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Bond and Bbg") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Completed") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Rejected") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
     }else{
      if (this.filterData == "Initiate") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Validate") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Update Register") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Document Return") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "Rejected") {
        this.sendInput = {
          stage: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
     }
    }
    else if (this.selectedFilter == 'SCN Pending') {
      if (this.filterData == "NO") {
        this.sendInput = {
          scn_pending: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "YES") {
        this.sendInput = {
          scn_pending: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
    }
    else if (filter == 'Incomming Date') {
      this.sendInput = {
        start_date: this.startDate,
        end_date: this.endDate,
        incoming_date: this.startDate,
        user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        pending_app:this.pendingApp
      }
    }
    setTimeout(() => {
      this.blockUI.stop();
    }, 500);
    if (this.process == 'registration') {
      localStorage.setItem('filter', JSON.stringify(this.sendInput));
      this.getApplicationFilterListByUserId(this.sendInput);
    } else {
      localStorage.setItem('filter', JSON.stringify(this.sendInput));
      this.getCancellationFilterListByUserId(this.sendInput);
    }

  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate,
      this.endDate = this.date ? this.date : this.todayDate
  }
  dateStartSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate;
    // console.log(this.startDate)
  }
  dateEndSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.endDate = this.date ? this.date : this.todayDate;
    // console.log(this.endDate)
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  loadMonths() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months = [];
    for (let i = 0; i < 12; i++) {

      // console.log("MOnth : ", month);
      if (month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      let name = this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = { "name": name, "value": month + 1, "start": start + " 00:00:00", "end": end + " 23:59:59" };
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
  }

  monthly(value, event) {
    if (event.isUserInput) {
      this.showTable = true;
      this.selectedMonth = value ? value : this.selectedMonth;
      this.startDate = this.pipe.transform(this.selectedMonth.start, "yyyy-MM-dd", "en-US");
      this.endDate = this.pipe.transform(this.selectedMonth.end, "yyyy-MM-dd", "en-US");
    }
  }

  selectCalendarView(event, value) {
    if (event.isUserInput==true) {
      if (value == "Monthly") {
        this.isMonthly = true;
        this.isDay = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Weekly") {
        this.isWeekly = true;
        this.isDay = false;
        this.isMonthly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Day") {
        this.isDay = true;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else {
        this.isDay = false;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = true;
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  compareMonths(i1, i2) {
    // return i2 && i1 && i1.value === i2.value;
    return i1 && i2 && i1.value === i2.value;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    if (this.isMonthly) {
      if (type === "change") {
        this.showTable = true;

        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        //const start =
        let date = new Date(this.today);
        //date = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        this.first_day_of_month = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        let currentMonth = date.getMonth();
        this.last_day_of_month = new Date(
          date.getFullYear(),
          currentMonth,
          this.daysInMonth(currentMonth + 1, date.getFullYear())
        );
        // let input = {
        //   start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        this.startDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        this.endDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);
        // this.dateData = input;
      }
    } else {
      if (type === "change") {
        this.showTable = true;
        this.today = event.value.getTime();

        const now = this.today;

        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        const start = new Date(this.today + 6 * 24 * 60 * 60 * 1000).getTime();
        const myStartDate = this.pipe.transform(start, "yyyy-MM-dd", "en-US");
        this.last6 = start;
        // let input = {
        //   end_date : this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   start_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        // this.dateData = input;
        this.startDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        this.endDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);

        if (this.today == this.now) {
          this.isButtonVisible = false;
        } else {

          this.isButtonVisible = true;
        }
      }
    }
  }

  getApplicationFilterListByUserId(input) {
  // input.offset=input.offset+1;  
  if(input.offset==0){
    input.offset=1;
  }
    this.loader=true;
    this.blockUI.start();
    // this.pageSet = 0;
    this.blockchainService.getApplicationFilterListByUserId(input).subscribe(
      (resp) => {
        // console.log(resp.body.obj);
        this.loader = false;
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
      
        this.dataSource = resp.body.obj.asr;
        this.summaryData=resp.body.obj.asr;
        this.totalEntries = resp.body.obj.total_entries;
        console.log(this.pageSet)
        // this.summaryData.paginator=this.paginator2;

        // this.pageSet = this.offsetData - 1;

        // this.summaryData.paginator=this.paginator2;
        this.pageSet = input.offset;
        if(input.offset==1){
          this.pageSet=0;
          // this.summaryData.paginator.pageIndex=0;
          // this.pageSet = this.offset;
        }
        else{
        this.pageSet=this.offset;
        }
        // if (input.offset == 1) {
        //   // this.offsetData = 1
        //   this.pageSet = 0;
          // this.paginator2.pageIndex=0;
        // }
        // else{

        // }
        // this.offset=


        // this.dataSource.forEach(st => {
        //   if (st.application_status == '0') {
        //     st.application_status = 'Received';
        //   } else if (st.application_status == '1') {
        //     st.application_status = 'Approved';
        //   } else {
        //     st.application_status = 'Rejected';
        //   }
        // })
        this.dataSource.forEach(st => {
          // if (st.application_status == '0') {
          //   st.application_status = 'Received';
          // } else if (st.application_status=='1' && st.stage=='Completed'){ 
          //   st.application_status = 'Approved';
          // } else if (st.application_status=='1' && st.stage!='Completed'){ 
          //   st.application_status = 'Received';
          // }
          //  else {
          //   st.application_status = 'Rejected';
          // }
          if (st.application_status=='1' && st.stage=='Completed'){ 
            st.application_status = 'Approved';
          }
          else if ( st.stage=='Rejected'){
            st.application_status = 'Rejected';
          }
          else{
            st.application_status = 'Received';
          } 
        })
        // console.log(input)
        var summaryList = resp.body.obj.asr;
        this.summaryData = new PaginatedDataSource<Registration, RegistrationDetailsQuery>(
          (request, query) =>
            this.blockchainService.pageRegistration(request, query, summaryList),
          this.initialSort,
          { search: "", data: undefined },
        
        );
        this.summaryData.pageSize=this.pageSizeChange;
        console.log(this.summaryData.paginator);

      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }
  onChangeLevel(event){
    
    if(event.value=='PENDING'){
      this.pendingApp = 1;
    }else{
      this.pendingApp = 0;
    }
  }
  getCancellationFilterListByUserId(input) {
    
    // this.pageSet = 0;
    if(input.offset==0){
      input.offset=1;
    }
    this.loaderCancellation=true;
    this.blockUI.start();
    this.blockchainService.getCancellationFilterListByUserId(input).subscribe(
      (resp) => {
        this.loaderCancellation=false;
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        this.dataSource = resp.body.obj.clsr;
        this.summaryData1 = resp.body.obj.clsr;
        console.log(this.dataSource);
        this.totalEntries = resp.body.obj.total_entries;
        this.pageSet = input.offset;
        if(input.offset==1){
          this.pageSet=0;
          // this.summaryData.paginator.pageIndex=0;
          // this.pageSet = this.offset;
        }
        else{
        this.pageSet=this.offset;
        }
        // this.dataSource.forEach(st => {
        //   console.log(this.dataSource);
        //   if (st.application_status == '0') {
        //     st.application_status = 'Received';
        //   } else if (st.application_status == '1') {
        //     st.application_status = 'Approved';
        //   } else {
        //     st.application_status = 'Rejected';
        //   }
        // })
        this.dataSource.forEach(st => {
          // if (st.application_status == '0') {
          //   st.application_status = 'Received';
          // } else if (st.application_status=='1' && st.stage=='Completed'){ 
          //   st.application_status = 'Approved';
          // } else if (st.application_status=='1' && st.stage!='Completed'){ 
          //   st.application_status = 'Received';
          // }
          //  else {
          //   st.application_status = 'Rejected';
          // }
          if (st.application_status=='1' && st.stage=='Completed'){ 
            st.application_status = 'Approved';
          }
          else if ( st.stage=='Rejected'){
            st.application_status = 'Rejected';
          }
          else{
            st.application_status = 'Received';
          } 
        })
        var summaryList1 = resp.body.obj.clsr;
        this.summaryData1 = new PaginatedDataSource<Registration, RegistrationDetailsQuery>(
          (request, query) =>
            this.blockchainService.pageCancellation(request, query, summaryList1),
          this.initialSort,
          { search: "", data: undefined },
        
        );
        console.log(summaryList1);
        this.summaryData1.pageSize=this.pageSizeChange;
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          location.reload();
          setTimeout(() => {
            this.blockUI.stop();
          }, 500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }



  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}