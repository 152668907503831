import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { AlertService } from "app/_services";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-aa-details",
  templateUrl: "./aa-details.component.html",
  styleUrls: ["./aa-details.component.css"],
})
export class AaDetailsComponent implements OnInit {
  pipe = new DatePipe("en-US");

  selectedStatus = "";

  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  authorizationDate: any;
  impDate: any;
  expDate: any;

  aaDetailsForm1 = this.formBuilder.group({
    officeName: ["", [Validators.required]],
    name: ["", [Validators.required]],
    address: ["", [Validators.required]],
    iecNo: ["", [Validators.required]],
    expType: ["", [Validators.required]],
    expStatus: ["", [Validators.required]],
    // actualUser: ["", [Validators.required]],
    // utilization: [""],
    fileNo: ["",[Validators.required]],
    // normType: [""],
    expForeignCurrency: [""],
    expRate: [""],
  });

  aaDetailsForm2 = this.formBuilder.group({
    impRate: [""],
    fobValue: [""],
    fobExpValue: [""],
    cifValue: [""],
    expCifValue: [""],
    portName: [""],
    portCode: ["",[Validators.required]],
    portType: [""],
    customeDateNo: [""],
    customeNo: [""],
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  date: string;
  modifiedCurrentDate: string;
  lastDate;
  isaaForm2Enable: boolean = false;
  aaDetailsResp: any;
  auth_id: string;
  iecNo: string;
  applicationType: string;
  currencyList: any;
  currencyType: any;
  isNext: boolean;
  customeDateNo:any;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.auth_id = localStorage.getItem('auth_id');
    this.iecNo = localStorage.getItem('iec_value');
    this.applicationType = localStorage.getItem('applicationType')
    
    this.aaDetailsForm1.patchValue({
      iecNo: this.iecNo ,
    });
    this.aaDetailsForm1.value.iecNo = this.iecNo
    this.getCurrencyList();
    this.getAaApplicationById();
  }
  get f1() {
    return this.aaDetailsForm2.controls;
  }
  public hasError1 = (controlName: string, errorName: string) => {
    return this.aaDetailsForm2.controls[controlName].hasError(errorName);
  };
  getAaApplicationById() {
    let application_id = localStorage.getItem("application_id");
    let input = {
      application_id: application_id,
    };
    this.blockchainService.getAaApplicationById(input).subscribe((resp) => {
      this.aaDetailsResp = resp.body.obj;
      this.aaDetailsForm1.patchValue({
        officeName: this.aaDetailsResp.name_of_firm,
        name: this.aaDetailsResp.name_of_issuing_authorization,
        address: this.aaDetailsResp.address_of_applicant,
        iecNo: this.aaDetailsResp.auth_iec_number,
        expType: this.aaDetailsResp.exporter_type,
        expStatus: this.aaDetailsResp.exporter_status,
        // actualUser: this.aaDetailsResp.transferable,
        // utilization: this.aaDetailsResp.utilization,
        fileNo: this.aaDetailsResp.aa_file_no,
        // normType: this.aaDetailsResp.type_of_norm,
        expForeignCurrency: this.aaDetailsResp.export_foreign_currency,
        expRate: this.aaDetailsResp.foreign_exchange_export_rate,
      });
      // this.currencyList.forEach(ele=>{
      //   if(ele.foreign_currency_id==this.aaDetailsResp.export_foreign_currency){
      //     this.currencyType = ele;
      //   }
      // })
      this.aaDetailsForm2.patchValue({
        impRate: this.aaDetailsResp.foreign_exchange_import_rate,
        fobValue: this.aaDetailsResp.fob_in_inr,
        fobExpValue: this.aaDetailsResp.fob_value_cxport_foreign_currency,
        cifValue: this.aaDetailsResp.cif_in_inr,
        expCifValue: this.aaDetailsResp.cif_value_cxport_foreign_currency,
        portName: this.aaDetailsResp.port_of_name,
        portCode: this.aaDetailsResp.port_code,
        portType: this.aaDetailsResp.port_type,
        customeDateNo: this.aaDetailsResp.custom_notification_date_number,
        customeNo: this.aaDetailsResp.custom_notification_number,
      });
    },(error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        location.reload();
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        this.blockUI.stop();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
        location.reload();
      }
      else{}
    });
  }

  getCurrencyList(){
    let input={}
    this.blockchainService.getCurrencyList(input).subscribe((resp) => {
      // console.log(resp);
      this.currencyList = resp.body.obj
    })
  }
  selectedCurrencyType(event, currencyType) {
    if (event.isUserInput) {
      // console.log(event);
      // console.log(currencyType)
      this.currencyType = currencyType;
    }
   
  }
  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  next() {
    localStorage.setItem(
      "aa_details1",
      JSON.stringify(this.aaDetailsForm1.value)
    );
    this.isaaForm2Enable = true;
  }

  save() {
    localStorage.setItem(
      "aa_details1",
      JSON.stringify(this.aaDetailsForm1.value)
    );
  }

  back() {
    this.router.navigate(["/aaHeader"]);
  }

  save1(event) {
    this.saveOrUpdate(event);
  }

  next1(event) {
    this.saveOrUpdate(event);
    // this.router.navigate(["/exportAA"]);
    this.isNext = true;
    // this.router.navigate(["/supportingProjectAA"]);
  }

  saveOrUpdate(event) {
    if(this.aaDetailsForm1.value.name != null && this.aaDetailsForm1.value.name != undefined && this.aaDetailsForm1.value.name != '') {
        if(this.aaDetailsForm1.value.officeName != null && this.aaDetailsForm1.value.officeName != undefined && this.aaDetailsForm1.value.officeName != ''){
          if(this.aaDetailsForm1.value.address != null && this.aaDetailsForm1.value.address != undefined && this.aaDetailsForm1.value.address != ''){
            if(this.aaDetailsForm1.value.iecNo != null && this.aaDetailsForm1.value.iecNo != undefined && this.aaDetailsForm1.value.iecNo != ''){
              if(this.aaDetailsForm1.value.expType != null && this.aaDetailsForm1.value.expType != undefined && this.aaDetailsForm1.value.expType != ''){
                if(this.aaDetailsForm1.value.expStatus != null && this.aaDetailsForm1.value.expStatus != undefined && this.aaDetailsForm1.value.expStatus != ''){
                  if(this.aaDetailsForm1.value.fileNo != null && this.aaDetailsForm1.value.fileNo != undefined && this.aaDetailsForm1.value.fileNo != ''){
                    this.blockUI.start("Please wait this may take few seconds ...");
                    var authheader = JSON.parse(localStorage.getItem("aaheader"));
                    console.log(authheader, "authheader");
                    if (
                      (this.aaDetailsResp == null ||
                      this.aaDetailsResp.auth_id == undefined ||
                      this.aaDetailsResp.auth_id == null ||
                      this.aaDetailsResp.auth_id == "0" )&& (this.auth_id == null || this.auth_id == undefined)
                    ) {
                      let input = {
                        auth_number: authheader.auth_number,
                        application_id: localStorage.getItem("application_id"),
                        auth_date: authheader.auth_date,
                        import_validity_date: authheader.import_validity_date,
                        export_validity_date: authheader.export_validity_date,
                        name_of_issuing_authorization: this.aaDetailsForm1.value.name,
                        name_of_firm: this.aaDetailsForm1.value.officeName,
                        address_of_applicant: this.aaDetailsForm1.value.address,
                        auth_iec_number: this.aaDetailsForm1.value.iecNo,
                        exporter_type: this.aaDetailsForm1.value.expType,
                        exporter_status: this.aaDetailsForm1.value.expStatus,
                        // transferable: this.aaDetailsForm1.value.actualUser,
                        // utilization: this.aaDetailsForm1.value.utilization,
                        aa_file_no: this.aaDetailsForm1.value.fileNo,
                        // type_of_norm: this.aaDetailsForm1.value.normType,
                        export_foreign_currency: this.aaDetailsForm1.value.expForeignCurrency,
                        foreign_exchange_export_rate: this.aaDetailsForm1.value.expRate,
                        foreign_exchange_import_rate: this.aaDetailsForm2.value.impRate,
                        fob_in_inr: this.aaDetailsForm2.value.fobValue,
                        fob_value_cxport_foreign_currency:
                          this.aaDetailsForm2.value.fobExpValue,
                        cif_in_inr: this.aaDetailsForm2.value.cifValue,
                        cif_value_cxport_foreign_currency:
                          this.aaDetailsForm2.value.expCifValue,
                        port_of_name: this.aaDetailsForm2.value.portName,
                        port_name: this.aaDetailsForm2.value.portName,
                        port_code: this.aaDetailsForm2.value.portCode,
                        port_type: this.aaDetailsForm2.value.portType,
                        custom_notification_date_number:this.aaDetailsForm2.value.customeDateNo,
                        custom_notification_number:this.aaDetailsForm2.value.customeNo,
                          
                      };
                      console.log(input)
                      this.blockchainService.saveAAMaster(input).subscribe((resp) => {
                        if (resp.body.status == 200) {
                          localStorage.setItem("auth_id", resp.body.obj);
                          setTimeout(() => {
                            this.blockUI.stop();
                          }, 1500);
                          // location.reload();
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            resp.body.message,
                            "announcement"
                          );
                          if(this.isNext = true){
                            this.router.navigate(["/supportingProjectAA"]);
                          } else {}
                        }
                        else {
                          setTimeout(() => {
                            this.blockUI.stop();
                          }, 1500);
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            resp.body.message,
                            "announcement"
                          );
                        }
                      },(error) => {
                        console.log(error)
                        this.alertService.error(error);
                        if(error.status == 401){
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            "Session Expired, Please Login Again",
                            "announcement"
                          );
                          // location.reload();
                          this.router.navigate(["/login"]);
                        }
                        else if(error.status == 500){
                          setTimeout(() => {
                            this.blockUI.stop();
                          }, 1500);
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            "Please Try Again",
                            "announcement"
                          );
                          // location.reload();
                        }
                        else{}
                      });
                    } else {
                      let input = {
                        auth_id: this.auth_id ? this.auth_id : this.aaDetailsResp.auth_id,
                        auth_number: authheader.auth_number,
                        application_id: localStorage.getItem("application_id"),
                        auth_date: authheader.auth_date,
                        import_validity_date: authheader.import_validity_date,
                        export_validity_date: authheader.export_validity_date,
                        name_of_issuing_authorization: this.aaDetailsForm1.value.name,
                        name_of_firm: this.aaDetailsForm1.value.officeName,
                        address_of_applicant: this.aaDetailsForm1.value.address,
                        auth_iec_number: this.aaDetailsForm1.value.iecNo,
                        exporter_type: this.aaDetailsForm1.value.expType,
                        exporter_status: this.aaDetailsForm1.value.expStatus,
                        // transferable: this.aaDetailsForm1.value.actualUser,
                        // utilization: this.aaDetailsForm1.value.utilization,
                        aa_file_no: this.aaDetailsForm1.value.fileNo,
                        // type_of_norm: this.aaDetailsForm1.value.normType,
                        export_foreign_currency: this.aaDetailsForm1.value.expForeignCurrency,
                        foreign_exchange_export_rate: this.aaDetailsForm1.value.expRate,
                        foreign_exchange_import_rate: this.aaDetailsForm1.value.impRate,
                        fob_in_inr: this.aaDetailsForm2.value.fobValue,
                        fob_value_cxport_foreign_currency:
                          this.aaDetailsForm2.value.fobExpValue,
                        cif_in_inr: this.aaDetailsForm2.value.cifValue,
                        cif_value_cxport_foreign_currency:
                          this.aaDetailsForm2.value.expCifValue,
                        port_of_name: this.aaDetailsForm2.value.portName,
                        port_name: this.aaDetailsForm2.value.portName,
                        port_code: this.aaDetailsForm2.value.portCode,
                        port_type: this.aaDetailsForm2.value.portType,
                        custom_notification_date_number:
                          this.aaDetailsForm2.value.customeDateNo,
                
                        custom_notification_number:this.aaDetailsForm2.value.customeNo,
                      };
                      this.blockchainService.updateAAMaster(input).subscribe((resp) => {
                        if (resp.body.status == 200) {
                          setTimeout(() => {
                            this.blockUI.stop();
                          }, 1500);
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            resp.body.message,
                            "announcement"
                          );
                          if(this.isNext = true){
                            this.router.navigate(["/supportingProjectAA"]);
                          } else {}
                        } else {
                          setTimeout(() => {
                            this.blockUI.stop();
                          }, 1500);
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            resp.body.message,
                            "announcement"
                          );
                        }
                      },(error) => {
                        console.log(error)
                        this.alertService.error(error);
                        if(error.status == 401){
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            "Session Expired, Please Login Again",
                            "announcement"
                          );
                          // location.reload();
                          this.router.navigate(["/login"]);
                        }
                        else if(error.status == 500){
                          setTimeout(() => {
                            this.blockUI.stop();
                          }, 1500);
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            "Please Try Again",
                            "announcement"
                          );
                          // location.reload();
                        }
                        else{}
                      });
                    }
                  } else{
                    this.showNotification(
                      "bottom",
                      "right",
                      "success",
                      'Please Enter File No',
                      "announcement"
                    );
                  }
                } else{
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    'Please Enter Exporter Status',
                    "announcement"
                  );
                }
              } else{
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  'Please Enter Exporter Type',
                  "announcement"
                );
              }
            } else{
              this.showNotification(
                "bottom",
                "right",
                "success",
                'Please Enter IEC',
                "announcement"
              );
            }
          } else{
            this.showNotification(
              "bottom",
              "right",
              "success",
              'Please Enter Address Of Applicant',
              "announcement"
            );
          }
        }else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            'Please Enter Name of Office Issuing Authorization',
            "announcement"
          );
        }
      
      }
      else{
        this.showNotification(
          "bottom",
          "right",
          "success",
          'Please Enter Name ',
          "announcement"
        );
      }
   
  }

  back1() {
    this.isaaForm2Enable = false;
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
