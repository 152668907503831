import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "app/_services";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

@Component({
  selector: "app-add-export-ou",
  templateUrl: "./add-export-ou.component.html",
  styleUrls: ["./add-export-ou.component.css"],
})
export class AddExportOUComponent implements OnInit {

  pipe = new DatePipe("en-US");


  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  shippingDate: any;

  bosForm = this.formBuilder.group({
    auth_no:["",Validators.required],
    bosNo: ["",Validators.required],
    bosDate: ["",Validators.required],
    productQty: ["",Validators.required],
    fobValue: ["",Validators.required],
    productDescr: ["",Validators.required],
    itchsCode: ["",Validators.required],
    portImport: ["",Validators.required],
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  modifiedCurrentDate: any;
  date: any;
  selectedFile: File;
  url: string | ArrayBuffer;
  isAdd: boolean;
  isUpdate: boolean;
  selectedAuthNo: any;
  updateData: any;
  allAuth: any;
  applicationType: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationType = localStorage.getItem('applicationType')
    
    if (this.route.snapshot.params["update"] == "true") {
      this.isUpdate = true;
      this.updateData = this.route.snapshot.params;
      console.log(this.updateData);
      
      // this.bosDoc = this.updateData.boe_document_path,
      this.selectedAuthNo = this.updateData.auth_no,
    
      this.bosForm.patchValue({
    auth_no:this.updateData.auth_no,
    bosNo: this.updateData.bos_no,
    bosDate:  this.pipe.transform(this.updateData.bos_date, "yyyy-MM-dd"),
    productQty: this.updateData.product_qty,
    productDescr: this.updateData.product_description,
    fobValue: this.updateData.fob_value_in_inr,
    portImport: this.updateData.port_of_import_code,
    itchsCode : this.updateData.itchs_code
      });
    }

    console.log(this.bosForm.value,'llll')
    this.getAllAuthNumber();
  }

  get f() {
    return this.bosForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.bosForm.controls[controlName].hasError(errorName);
  };

  authFn(e) {
    console.log(e);
    this.selectedAuthNo = e;
  }

  addorUpdate() {
    let input = {
      application_id: 0,
      auth_no: this.selectedAuthNo,
      // bill_of_shipping_export_id: ""this.pipe.transform(this.bosForm.value.bosDate, "yyyy-MM-dd"),
      bos_date: this.pipe.transform(this.bosForm.value.bosDate,"yyyy-MM-dd")+" "+ "00:00:00",
      product_description: this.bosForm.value.productDescr,
      bos_no: this.bosForm.value.bosNo,
      fob_value_in_inr: this.bosForm.value.fobValue,
      port_of_import_code: this.bosForm.value.portImport,
      itchs_code:this.bosForm.value.itchsCode,
      product_qty: this.bosForm.value.productQty,
    };
    console.log(input,'save func')
    if(!this.isUpdate){
     
      this.saveBillOfShippingExport(input);
    }
    else{
      let input = {
        application_id: 0,
        auth_no: this.selectedAuthNo,
        bill_of_shipping_export_id: this.updateData.bill_of_shipping_export_id,
        bos_date: this.pipe.transform(this.bosForm.value.bosDate,"yyyy-MM-dd")+" "+ "00:00:00",
        product_description: this.bosForm.value.productDescr,
        bos_no: this.bosForm.value.bosNo,
        fob_value_in_inr: this.bosForm.value.fobValue,
        port_of_import_code: this.bosForm.value.portImport,
        itchs_code:this.bosForm.value.itchsCode,
        product_qty: this.bosForm.value.productQty,
      };
      this.updateBillOfShippingExport(input);
    }
    
  }

  getAllAuthNumber() {
    this.blockchainService.getAllAuthNumber().subscribe((resp) => {
      this.allAuth = resp.body.obj;
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  saveBillOfShippingExport(input) {
    this.blockchainService.saveBillOfShippingExport(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.router.navigate(["/exportOU"]);

        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      } else {
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    } 
    );
  }

  updateBillOfShippingExport(input) {
    this.blockchainService.updateBillOfShippingExport(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.router.navigate(["/exportOU"]);

        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      } else {
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }


  
  navigateExport() {
    this.router.navigate(["/exportOU"]);
  }


  onFileSelected(event) {
    console.log("clicked");
    console.log("clicked1", event);
    this.selectedFile = <File>event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); // read file as data url

    reader.onload = (event) => {
      // called once readAsDataURL is completed
      this.url = event.target.result;
    };
    this.onUpload();
  }

  onUpload() {
    const fd = new FormData();
    console.log(this.selectedFile, "selectedFile");
    fd.append("file", this.selectedFile, this.selectedFile.name);
    // this.submitRequest()
  }

  cancel() {
    this.router.navigate(["/applnSummary"]);
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log("Event", event);
    // this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    console.log("First Date=" + this.date);
    this.modifiedCurrentDate = this.date;
    console.log("helllooo", this.modifiedCurrentDate);
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

 

  back() {
    this.router.navigate(["/exportOU"]);
  }
  statusFn(e, status) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
