import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "app/_services";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface RegisterApplication {
  doc_name: string;
  viewPdf: string;
  received_status: string;
  commentsFromAi: string;
  remarks: string;
  application_id: number;
  cancellation_id: number;
}

export interface CancelApplication {
  doc_name: string;
  viewPdf: string;
  received_status: string;
  remarks: string;
}


@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.css']
})
export class Step2Component implements OnInit {
  selectedStatus = "";

  allStatus: Status[] = [
    { value: "0", viewValue: "YES" },
    { value: "1", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  regs: any;
  isIecPath: any;
  isIceAuthority:any;
  isExport: any;
  isAdv: any;
  isBond: any;
  isStar: any;
  isBank: any;
  isGst: any;
  isLicence: any;
  isPower: any;
  isAffdavit: any;
  isNoBg:any;
  isTurnover: any;
  isRcmc: any;
  isIdentity; any;
  isIecHolders: any;
  isOther: any;
  isAppLetter: any;
  isIecLetter:any;
  isIECLetr:any;
  isEpcg: any;
  isSurrender: any;
  isRegularization: any;
  isListOther: any;
  isDoc1: any;
  isDoc2: any;
  isDoc3: any;
  isDoc4: any;
  isDoc5: any;
  isDoc6: any;
  isDoc7: any;
  isDoc8: any;
  isDoc9: any;
  isDoc10: any;
  isExcel1: any;
  isExcel2: any;
  isExcel3: any;
  isExcel4: any;
  isExcel5: any;
  isExcel6: any;
  isExcel7: any;
  isExcel8: any;
  isExcel9: any;
  isExcel10: any;


  displayedColumns = [
    "no",
    "doc_name",
    "viewPdf",
    "commentsFromAi",
    "received_status",
    "remarks",
  ];

  displayedColumns1 = [
    "no",
    "doc_name",
    "viewPdf",
    "received_status",
    "remarks",
  ];
  autoFillForm = this.formBuilder.group({
    name: [""],
    section: [""],
    designation: [""],
  });
  fillForm = this.formBuilder.group({
    fileNo: [""],
    alertStatus: [""]
  });
  caseDetailsFillForm = this.formBuilder.group({
    impexpname: [""],
    iecCode: [""],
    inititionDate: [""],
  });

  rdm: UntypedFormArray = this.formBuilder.array([]);
  rdm1: UntypedFormArray = this.formBuilder.array([]);
  registerDocuments: UntypedFormGroup = this.formBuilder.group({ rdm_master: this.rdm });
  cancelDocuments: UntypedFormGroup = this.formBuilder.group({ rdm_master1: this.rdm1 });
  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  register: RegisterApplication[] = [];
  reg1: RegisterApplication[] = [];
  cancel: RegisterApplication[] = [];
  process: any;
  isRegistrationEnable:boolean;
  isCancellationEnable:boolean;
  disable = false;
  offsetData: any;
  access_token: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    this.offsetData = JSON.parse(localStorage.getItem("offset"));
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.access_token = this.currentUser.obj.access_token
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));
    this.setFormValue();
    this.process = JSON.parse(localStorage.getItem("process"));
    console.log(this.fillForm.value.alertStatus)
    if (this.process == "registration") {
      this.getApplicationDetailsByApplicationId(this.caseDetailsData.application_id, this.currentUser.obj.user_id);
      this.isRegistrationEnable = false;
    } else if (this.process == "cancellation") {
      this.getCancellationDetailsByCancellationId(this.caseDetailsData.cancellation_id, this.currentUser.obj.user_id)
    }

  }

  setFormValue() {
    this.autoFillForm.setValue({
      name: this.caseDetailsData.officer_name,
      designation: null,
      section: null,
    });
    this.caseDetailsFillForm.setValue({
      impexpname: this.caseDetailsData.imp_exp_name,
      iecCode: this.caseDetailsData.iec_code,
      inititionDate: this.caseDetailsData.completion_date
    });
    this.fillForm.setValue({
      fileNo: this.caseDetailsData.file_no,
      alertStatus: "No"
    })
  }
  getCancellationDetailsByCancellationId(input1, input2) {
    let application = {
      cancellation_id: input1,
      user_id: input2
    }
    this.blockchainService.getCancellationDetailsByCancellationId(application).subscribe(
      (resp) => {
        console.log(resp.body.obj);
        this.regs = resp.body.obj;

        if (this.regs.application_letter_path == null) {
          this.isAppLetter = "1";
        } else {
          this.isAppLetter = "";
        }

        // if (this.regs.iec_letter_path == null) {
        //   this.isIECLetr = "1";
        // } else {
        //   this.isIECLetr = "";
        // }

        if (this.regs.cancellation_iec_auth == null) {
          this.isIecLetter = "1";
        } else {
          this.isIecLetter = "";
        }

        if (this.regs.export_obligation_discharge_certificate_path == null) {
          this.isEpcg = "1";
        } else {
          this.isEpcg = "";
        }

        if (this.regs.surrender_letter == null) {
          this.isSurrender = "1";
        } else {
          this.isSurrender = "";
        }

        if (this.regs.regularization_letter == null) {
          this.isRegularization = "1";
        } else {
          this.isRegularization = "";
        }

        if (this.regs.list_of_other_documents_being_uploaded_path == null) {
          this.isListOther = "1";
        } else {
          this.isListOther = "";
        }

        if (this.regs.other_documents1_path == null) {
          this.isDoc1 = "1";
        } else {
          this.isDoc1 = "";
        }

        if (this.regs.other_documents2_path == null) {
          this.isDoc2 = "1";
        } else {
          this.isDoc2 = "";
        }

        if (this.regs.other_documents3_path == null) {
          this.isDoc3 = "1";
        } else {
          this.isDoc3 = "";
        }

        if (this.regs.other_documents4_path == null) {
          this.isDoc4 = "1";
        } else {
          this.isDoc4 = "";
        }

        if (this.regs.other_documents5_path == null) {
          this.isDoc5 = "1";
        } else {
          this.isDoc5 = "";
        }


        if (this.regs.other_documents6_path == null) {
          this.isDoc6 = "1";
        } else {
          this.isDoc6 = "";
        }

        if (this.regs.other_documents7_path == null) {
          this.isDoc7 = "1";
        } else {
          this.isDoc7 = "";
        }

        if (this.regs.other_documents8_path == null) {
          this.isDoc8 = "1";
        } else {
          this.isDoc8 = "";
        }

        if (this.regs.other_documents9_path == null) {
          this.isDoc9 = "1";
        } else {
          this.isDoc9 = "";
        }

        if (this.regs.other_documents10_path == null) {
          this.isDoc10 = "1";
        } else {
          this.isDoc10 = "";
        }


        if (this.regs.excel_sheet1_path == null) {
          this.isExcel1 = "1";
        } else {
          this.isExcel1 = "";
        }

        if (this.regs.excel_sheet2_path == null) {
          this.isExcel2 = "1";
        } else {
          this.isExcel2 = "";
        }


        if (this.regs.excel_sheet3_path == null) {
          this.isExcel3 = "1";
        } else {
          this.isExcel3 = "";
        }

        if (this.regs.excel_sheet4_path == null) {
          this.isExcel4 = "1";
        } else {
          this.isExcel4 = "";
        }

        if (this.regs.excel_sheet5_path == null) {
          this.isExcel5 = "1";
        } else {
          this.isExcel5 = "";
        }

        if (this.regs.excel_sheet6_path == null) {
          this.isExcel6 = "1";
        } else {
          this.isExcel6 = "";
        }

        if (this.regs.excel_sheet7_path == null) {
          this.isExcel7 = "1";
        } else {
          this.isExcel7 = "";
        }


        if (this.regs.excel_sheet8_path == null) {
          this.isExcel8 = "1";
        } else {
          this.isExcel8 = "";
        }

        if (this.regs.excel_sheet9_path == null) {
          this.isExcel9 = "1";
        } else {
          this.isExcel9 = "";
        }

        if (this.regs.excel_sheet10_path == null) {
          this.isExcel10 = "1";
        } else {
          this.isExcel10 = "";
        }

        this.cancel = [
          {
            doc_name: "APPLICATION LETTER",
            viewPdf: this.regs.application_letter_path,
            received_status: this.isAppLetter,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)

          },
          // {
          //   doc_name: "IEC LETTER",
          //   viewPdf: this.regs.iec_letter_path,
          //   received_status: this.isIecPath,
          //   commentsFromAi: "",
          //   remarks: "",
          //   application_id: 0,
          //   cancellation_id: parseInt(this.caseDetailsData.cancellation_id)

          // },
          {
            doc_name: "IEC AUTHORIZATION LETTER",
            viewPdf: this.regs.cancellation_iec_auth,
            received_status: this.isIECLetr,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)

          },
          {
            doc_name: "EXPORT OBLIGATION DISCHARGE CERTIFICATE",
            viewPdf: this.regs.export_obligation_discharge_certificate_path,
            received_status: this.isEpcg,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          },
          {
            doc_name: "SURRENDER LETTER",
            viewPdf: this.regs.surrender_letter,
            received_status: this.isSurrender,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          },
          {

            doc_name: "REGULARIZATION LETTER",
            viewPdf: this.regs.regularization_letter,
            received_status: this.isRegularization,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          },
          {

            doc_name: "LIST OF OTHER DOCUMENTS BEING UPLOADED ",
            viewPdf: this.regs.list_of_other_documents_being_uploaded_path,
            received_status: this.isListOther,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          },
          {

            doc_name: "OTHER DOCUMENTS 1",
            viewPdf: this.regs.other_documents1_path,
            received_status: this.isDoc1,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "OTHER DOCUMENTS 2",
            viewPdf: this.regs.other_documents2_path,
            received_status: this.isDoc2,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "OTHER DOCUMENTS 3",
            viewPdf: this.regs.other_documents3_path,
            received_status: this.isDoc3,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "OTHER DOCUMENTS 4",
            viewPdf: this.regs.other_documents4_path,
            received_status: this.isDoc4,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "OTHER DOCUMENTS 5",
            viewPdf: this.regs.other_documents5_path,
            received_status: this.isDoc5,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "OTHER DOCUMENTS 6",
            viewPdf: this.regs.other_documents6_path,
            received_status: this.isDoc6,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "OTHER DOCUMENTS 7",
            viewPdf: this.regs.other_documents7_path,
            received_status: this.isDoc7,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "OTHER DOCUMENTS 8",
            viewPdf: this.regs.other_documents8_path,
            received_status: this.isDoc8,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "OTHER DOCUMENTS 9",
            viewPdf: this.regs.other_documents9_path,
            received_status: this.isDoc9,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "OTHER DOCUMENTS 10",
            viewPdf: this.regs.other_documents10_path,
            received_status: this.isDoc10,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          },
          {

            doc_name: "EXCEL SHEET 1",
            viewPdf: this.regs.excel_sheet1_path,
            received_status: this.isExcel1,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          },
          {

            doc_name: "EXCEL SHEET 2",
            viewPdf: this.regs.excel_sheet2_path,
            received_status: this.isExcel2,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "EXCEL SHEET 3",
            viewPdf: this.regs.excel_sheet3_path,
            received_status: this.isExcel3,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "EXCEL SHEET 4",
            viewPdf: this.regs.excel_sheet4_path,
            received_status: this.isExcel4,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "EXCEL SHEET 5",
            viewPdf: this.regs.excel_sheet5_path,
            received_status: this.isExcel5,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "EXCEL SHEET 6",
            viewPdf: this.regs.excel_sheet6_path,
            received_status: this.isExcel6,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "EXCEL SHEET 7",
            viewPdf: this.regs.excel_sheet7_path,
            received_status: this.isExcel7,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "EXCEL SHEET 8",
            viewPdf: this.regs.excel_sheet8_path,
            received_status: this.isExcel8,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "EXCEL SHEET 9",
            viewPdf: this.regs.excel_sheet9_path,
            received_status: this.isExcel9,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }, {

            doc_name: "EXCEL SHEET 10",
            viewPdf: this.regs.excel_sheet10_path,
            received_status: this.isExcel10,
            commentsFromAi: "",
            remarks: "",
            application_id: 0,
            cancellation_id: parseInt(this.caseDetailsData.cancellation_id)
          }
        ];

        this.cancelDocuments = this.formBuilder.group({
          rdm1: this.formBuilder.array([
            this.cancel.forEach(abc => {
              const newRow = this.formBuilder.group({
                doc_name: [abc.doc_name],
                received_status: [abc.received_status],
                remarks: [abc.remarks],
                application_id: [abc.application_id],
                cancellation_id: [abc.cancellation_id],
                return_status: 0
              })
              this.rdm1.push(newRow);
            })
          ])
        });
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }

  getApplicationDetailsByApplicationId(input1, input2) {
    let application = {
      application_id: input1,
      user_id: input2
    }
    this.blockchainService.getApplicationDetailsByApplicationId(application).subscribe(
      (resp) => {
        console.log(resp.body.obj);
        this.regs = resp.body.obj;
        if (this.regs.iec_copy_path == null) {
          this.isIecPath = '1';
        } else {
          this.isIecPath = "";
        }

        if (this.regs.advanced_authorization_path == null) {
          this.isExport = "1";
        } else {
          this.isExport = "";
        }

        if (this.regs.bond_path == null) {
          this.isBond = "1";
        } else {
          this.isBond = "";
        }

        if (this.regs.start_export_house_copy_path== null) {
          this.isStar = "1";
        } else {
          this.isStar = "";
        }


        if (this.regs.bank_guarantee_path == null) {
          this.isBank = "1";
        } else {
          this.isBank = "";
        }

        if (this.regs.gst_registration_path == null) {
          this.isGst = "1";
        } else {
          this.isGst = ""
        }

        if (this.regs.license_script_path == null) {
          this.isLicence = "1";
        } else {
          this.isLicence = ""
        }

        if (this.regs.power_of_attorney_path == null) {
          this.isPower = "1";
        } else {
          this.isPower = ""
        }

        if (this.regs.rcmc_path == null) {
          this.isRcmc = "1";
        } else {
          this.isRcmc = ""
        }

        if (this.regs.identity_proof_path == null) {
          this.isIdentity = "1";
        } else {
          this.isIdentity = ""
        }

        if (this.regs.turnover_certificate_path == null) {
          this.isTurnover = "1";
        } else {
          this.isTurnover = "";
        }

        if (this.regs.any_other_document == null) {
          this.isOther = "1";
        } else {
          this.isOther = "";
        }

        if (this.regs.iec_authority_letter_path == null) {
          this.isIecHolders = "1";
        } else {
          this.isIecHolders = "";
        }

        if (this.regs.affadavit_path == null) {
          this.isAffdavit = "1";
        } else {
          this.isAffdavit = "";
        }

        if (this.regs.no_bg_doc == null) {
          this.isNoBg = "1";
        } else {
          this.isNoBg = "";
        }

        if (this.caseDetailsData.application_type == 'AA') {
          this.register = [
            {
              doc_name: "IEC COPY",
              viewPdf: this.regs.iec_copy_path,
              received_status: this.isIecPath,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },

            {
              doc_name: "ADVANCED AUTHORIZATION",
              viewPdf: this.regs.advanced_authorization_path,
              received_status: this.isAdv,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0
            },
            {
              doc_name: "BOND",
              viewPdf: this.regs.bond_path,
              received_status: this.isBond,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "STAR EXPORT HOUSE COPY",
              viewPdf: this.regs.start_export_house_copy_path,
              received_status: this.isStar,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "BANK GUARANTEE",
              viewPdf: this.regs.bank_guarantee_path,
              received_status: this.isBank,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "GST REGISTRATION COPY",
              viewPdf: this.regs.gst_registration_path,
              received_status: this.isGst,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "LICENCE SCRIPT",
              viewPdf: this.regs.license_script_path,
              received_status: this.isLicence,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },

            {

              doc_name: "POWER OF ATTORNEY",
              viewPdf: this.regs.power_of_attorney_path,
              received_status: this.isPower,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "AFFADAVIT",
              viewPdf: this.regs.affadavit_path,
              received_status: this.isAffdavit,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "RCMC",
              viewPdf: this.regs.rcmc_path,
              received_status: this.isRcmc,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)",
              viewPdf: this.regs.turnover_certificate_path,
              received_status: this.isTurnover,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "IDENTITY PROOF - POA",
              viewPdf: this.regs.identity_proof_path,
              received_status: this.isIdentity,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "IEC HOLDERS AUTHORITY LETTER",
              viewPdf: this.regs.iec_authority_letter_path,
              received_status: this.isIecHolders,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "ANY OTHER DOCUMENT",
              viewPdf: this.regs.any_other_document,
              received_status: this.isOther,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "BG EXEMPTION SUPPORTING DOCUMENT",
              viewPdf: this.regs.no_bg_doc,
              received_status: this.isNoBg,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
          ];
        } else {
          this.register = [
            {
              doc_name: "IEC COPY",
              viewPdf: this.regs.iec_copy_path,
              received_status: this.isIecPath,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },

            {
              doc_name: "EXPORT PROMOTION CAPITAL GOODS SCHEME",
              viewPdf: this.regs.advanced_authorization_path,
              received_status: this.isExport,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0
            },
            {
              doc_name: "BOND",
              viewPdf: this.regs.bond_path,
              received_status: this.isBond,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "STAR EXPORT HOUSE COPY",
              viewPdf: this.regs.start_export_house_copy_path,
              received_status: this.isStar,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "BANK GUARANTEE",
              viewPdf: this.regs.bank_guarantee_path,
              received_status: this.isBank,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "GST REGISTRATION COPY",
              viewPdf: this.regs.gst_registration_path,
              received_status: this.isGst,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "LICENCE SCRIPT",
              viewPdf: this.regs.license_script_path,
              received_status: this.isLicence,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "POWER OF ATTORNEY",
              viewPdf: this.regs.power_of_attorney_path,
              received_status: this.isPower,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "AFFADAVIT",
              viewPdf: this.regs.affadavit_path,
              received_status: this.isAffdavit,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "RCMC",
              viewPdf: this.regs.rcmc_path,
              received_status: this.isRcmc,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "TURNOVER CERTIFICATE(Not Needed If RCMC Submitted)",
              viewPdf: this.regs.turnover_certificate_path,
              received_status: this.isTurnover,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "IDENTITY PROOF - POA",
              viewPdf: this.regs.identity_proof_path,
              received_status: this.isIdentity,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "IEC HOLDERS AUTHORITY LETTER",
              viewPdf: this.regs.iec_authority_letter_path,
              received_status: this.isIecHolders,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "ANY OTHER DOCUMENT",
              viewPdf: this.regs.any_other_document,
              received_status: this.isOther,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
            {

              doc_name: "BG EXEMPTION SUPPORTING DOCUMENT",
              viewPdf: this.regs.no_bg_doc,
              received_status: this.isNoBg,
              commentsFromAi: "",
              remarks: "",
              application_id: parseInt(this.caseDetailsData.application_id),
              cancellation_id: 0

            },
          ];
        }
        this.registerDocuments = this.formBuilder.group({
          rdm: this.formBuilder.array([
            this.register.forEach(abc => {
              const newRow = this.formBuilder.group({
                doc_name: [abc.doc_name],
                received_status: [abc.received_status],
                remarks: [abc.remarks],
                application_id: [abc.application_id],
                cancellation_id: [abc.cancellation_id],
                return_status: 0
              })
              this.rdm.push(newRow);
            })
          ])
        });

      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      });
  }

  saveCancelApplication(rdm1) {
    let input = {
      rdm: rdm1.value
    }
    this.blockchainService.saveRegistrationDocument(input).subscribe(
      (resp) => {
        console.log(resp);
        if (resp.body.status == 200) {
          this.isCancellationEnable = true;
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Remarks Submitted Successfully",
            "announcement"
          );
        }
        else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Remarks can not be submitted",
            "announcement"
          );
        }
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      })

    if (this.fillForm.value.alertStatus == 'YES') {
      let input1 = {
        cancellation_id: this.caseDetailsData.cancellation_id
      }
      this.blockchainService.updateCancellationAlertStatus(input1).subscribe(
        (resp) => {
          console.log(resp);
          if (resp.body.status == 200) {
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Alerts updated sucessfully",
              "announcement"
            );
          }
        }, (error) => {
          console.log(error)
          this.alertService.error(error);
          if(error.status == 401){
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Session Expired, Please Login Again",
              "announcement"
            );
            this.router.navigate(["/login"]);
          }
          else if(error.status == 500){
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Try Again",
              "announcement"
            );
          }
          else{}
         
        })
    }
  }
  registrationNotesheet(){
    this.router.navigate(["/registrationValidate"]);
  }
  cancelNotesheet(){
    this.router.navigate(["/cancelNotesheet"]);
  }
  saveRegisterApplication(rdm) {
    console.log(this.registerDocuments);
    console.log(rdm.value)
    let input = {
      rdm: rdm.value
    }
    this.blockchainService.saveRegistrationDocument(input).subscribe(
      (resp) => {
        if (resp.body.status == 200) {
          this.isRegistrationEnable = true;
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Remarks Submitted Successfully",
            "announcement"
          );
        } else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Remarks can not be submitted",
            "announcement"
          );
        }
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
       
      })

    if (this.fillForm.value.alertStatus == 'YES') {
      console.log(this.fillForm.value.alertStatus)
      let input1 = {
        application_id: this.caseDetailsData.application_id
      }
      this.blockchainService.updateApplicationAlertStatus(input1).subscribe(
        (resp) => {
          console.log(resp);
          if (resp.body.status == 200) {
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Alerts updated sucessfully",
              "announcement"
            );
          }
        })
    }
  }

  back() {
    if (this.process == "registration") {

      this.router.navigate(['/registration1'], { queryParams: { page: this.offsetData } });
      // this.router.navigate(["/registration1"]);
    } else {
      // this.router.navigate(["/cancellation1"]);
      this.router.navigate(['/cancellation1'], { queryParams: { page: this.offsetData } });
    }

  }

  navigateToRegister() {
    this.router.navigate(["/registerTask"]);
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  statusFn(e, status) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
