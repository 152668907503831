import { Component, OnInit } from "@angular/core";
import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
// import { FlexAlignDirective } from "@angular/flex-layout";
import { AuthenticationService } from "app/_services/authentication.service";
import { DateAdapter } from "@angular/material/core";
import { AlertService } from "app/_services";

declare var $: any;

export interface CategoryType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-reassign',
  templateUrl: './reassign.component.html',
  styleUrls: ['./reassign.component.css']
})
export class ReassignComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  allLevels: CategoryType[] = [];
  allCategory: CategoryType[] = [];
  pipe = new DatePipe("en-US");
  msg = "";
  role: any;
  currentUser: any;
  iec_flag = false;
  license_no;
  license_issue_date;
  
  selectedStatus: any;
  notiColor: any = "#06ffff";
  reassignmentForm = this.formBuilder.group({
    license_no: ["", Validators.required],
    license_type: ["", Validators.required],
    iec_no: ["", Validators.required],
    license_issue_date:["", Validators.required],
    current_pending_level:["", Validators.required],
    current_pending_officer:["", Validators.required],
    reassigned_level:["", Validators.required],
    reassigned_officer:["", Validators.required],
  });
  zoneList: any;
  commissionrateList: any;
  allSectionByCommissionrate: any;
  adminAction: string;
  zoneId: any;
  userTypeList: any;
  designationList: any;
  sectionList: any;
  start_date: string;
  officerNameList: any;
  iecNoDetails: any;
  officerDetails: any;
  reassignedOfficer: any;
  assigned_user_id: any;
  user_id_to_assign: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale("en-GB"); //dd/MM/yyyy
  }

  ngOnInit(): void {
    // this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    var cuser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = cuser.obj.role;
    // console.log(this.role);
  }



 

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.start_date = this.pipe.transform(event.value, "yyyy-MM-dd");
    // this.modifiedCurrentDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }



  getLevels() {
    this.allLevels = [
      { value: "L1", viewValue: "L1" },
      { value: "L2", viewValue: "L2" },
    ];
  }

  getCategory() {
    this.allCategory = [
      { value: "Transfer", viewValue: "Transfer" },
      { value: "Long Leave", viewValue: "Long Leave" },
    ];
  }

  
  submit(){
    this.updateFileAssignStatus();
  }

  selectOfficerName(data,e){
    if(e.isUserInput){
      this.user_id_to_assign = data.customs_user_id;
    }
  }

  selectReassignedLevel(level,e){
    let reassignedLevel ;
    if(e.isUserInput){
      if(level == 'L1'){
        reassignedLevel = level
        this.assigned_user_id = this.iecNoDetails.user_id_L1
      } else{
        reassignedLevel = level
        this.assigned_user_id = this.iecNoDetails.user_id_L2
      }
      let data  = {
        level:reassignedLevel,
        assgned_user_id:this.assigned_user_id,
        license_type:this.iecNoDetails.licence_type
      }
      
      this.getOfficerByApplicationIdExceptUserId(data)
    }
  }

  detailsByIecNo(e,license){
      let license_no = e.target.value ? e.target.value : license
      this.getIecDetailsByAuthNumber(license_no);
  }

  getIecDetailsByAuthNumber(license_no) {
    let input = {
      license_no: license_no,
    };
    this.blockchainService
      .getIecDetailsByAuthNumber(input)
      .subscribe((resp) => {
        this.iecNoDetails = resp.body.obj;
        if(resp.body.status == 200){
          this.getLevels();
          this.reassignmentForm.patchValue({
            license_type:   this.iecNoDetails.licence_type,
            iec_no:   this.iecNoDetails.iec_no,
            license_issue_date:this.pipe.transform(this.iecNoDetails.license_issue_date, "yyyy-MM-dd") ,
            current_pending_level:this.iecNoDetails.current_pending_level,
            current_pending_officer:this.iecNoDetails.current_pending_officer_name,
          })
        } else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            "No Data Found For This Authorization No.",
            "announcement"
          );
        }
      
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      });
  }

  getOfficerByApplicationIdExceptUserId(input) {
    
    this.blockchainService
      .getOfficerByApplicationIdExceptUserId(input)
      .subscribe((resp) => {
        this.officerDetails = resp.body.obj;
        this.officerDetails.forEach(element => {
          this.reassignedOfficer.push(element.name)
        });
       
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      });
  }

  updateFileAssignStatus(){
    let input = {
    user_id_to_assign: this.user_id_to_assign,
    assgned_user_id : this.assigned_user_id,
    application_id : this.iecNoDetails.application_id
    }
    this.blockchainService.updateFileAssignStatus(input).subscribe(resp => {
      if(resp.body.status == 200){
        this.showNotification(
          "bottom",
          "right",
          "success",
           resp.body.message,
          "announcement"
        );
        location.reload();
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    })
  }

  get f() {
    return this.reassignmentForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.reassignmentForm.controls[controlName].hasError(errorName);
  };

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", this.notiColor, "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 5000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4' +
          ' alert alert-{0} alert-with-icon" role="alert"><button mat-button  type="butto' +
          'n" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i clas' +
          's="material-icons">close</i></button><i class="material-icons" data-notify="ic' +
          'on">notifications</i> <span data-notify="title">{1}</span> <span data-notify="' +
          'message">{2}</span><div class="progress" data-notify="progressbar"><div class=' +
          '"progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valu' +
          'emin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" targ' +
          'et="{4}" data-notify="url"></a></div>',
      }
    );
  }
}
