import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface RegisterApplication {
  doc_name: string;
  viewPdf: string;
  received_status: string;
  commentsFromAi: string;
  remarks: string;
  application_id: number;
  cancellation_id: number;
}

export interface CancelApplication {
  doc_name: string;
  viewPdf: string;
  received_status: string;
  remarks: string;
}


@Component({
  selector: 'app-view-validate-payment',
  templateUrl: './view-validate-payment.component.html',
  styleUrls: ['./view-validate-payment.component.css']
})
export class ViewValidatePaymentComponent implements OnInit {
  selectedStatus = "";

  allStatus: Status[] = [
    { value: "0", viewValue: "YES" },
    { value: "1", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  regs: any;
  total_payment_made_against_auth_date:any;
  auth_date:any;
  auth_type:any;
  autoFillForm = this.formBuilder.group({
    iec_number: [""],
    auth_type: [""],
    auth_no: [""],
    auth_date: [""],
    total_payment_made_against_auth: [""],
    total_payment_made_against_auth_date: [""],
  });
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");
  now = Date.now();
  date: any;
  modifiedCurrentDate: any;
  selectedFile1: File;
  selectedFile2: File;
  selectedFile3: File;
  selectedFile4: File;
  selectedFile5: File;
  selectedFile6: File;
  selectedFile7: File;
  selectedFile8: File;
  selectedFile9: File;
  selectedFile10: File;
  dynamicArrayPdf = [];
  rdm: UntypedFormArray = this.formBuilder.array([]);
  rdm1: UntypedFormArray = this.formBuilder.array([]);
  registerDocuments: UntypedFormGroup = this.formBuilder.group({ rdm_master: this.rdm });
  cancelDocuments: UntypedFormGroup = this.formBuilder.group({ rdm_master1: this.rdm1 });
  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  register: RegisterApplication[] = [];
  cancel: RegisterApplication[] = [];
  process: any;
  disable = false;
  viewPaymentsById: any;
  proof_of_payment_id: any;
  selectedFile: File;
  url: string | ArrayBuffer;
  showIECPdf: boolean = false;
  allApplicationTypes: any;
  applicationType: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));
    this.proof_of_payment_id = JSON.parse(localStorage.getItem("proof_of_payment_id"));
    this.getApplicationType();
    this.setFormValue();

  }
  getApplicationType() {
    this.blockchainService.getApplicationType().subscribe((resp) => {
      this.allApplicationTypes = resp.obj;
    });
  }
  selectedApplicationType(event, applicationType) {
    if (event.isUserInput) {
      console.log(event);
      this.applicationType = applicationType;
      this.autoFillForm.value.auth_type = applicationType;
      // this.selectedCityName = "";
      console.log(this.autoFillForm.value.auth_type)
    }
  }
  addPdfRow() {
    
    this.dynamicArrayPdf.push({ otherPdf: '' });
    // console.log('New row added successfully',this.dynamicArrayPdf);
  }
  deletePdfRow(index) {
    this.dynamicArrayPdf.splice(index, 1);
  }

  setFormValue() {
    
    let input = {
      proof_of_payment_id: this.proof_of_payment_id
    }
    this.blockchainService.getProofOfPaymentsById(input).subscribe(
      (resp) => {
        this.viewPaymentsById = resp.body.obj;
        this.autoFillForm.setValue({
          iec_number: this.viewPaymentsById.iec_number,
          auth_type: this.viewPaymentsById.auth_type,
          auth_no: this.viewPaymentsById.auth_no,
          auth_date: this.pipe.transform(this.viewPaymentsById.auth_date, "yyyy-MM-dd"),
          total_payment_made_against_auth: this.viewPaymentsById.total_payment_made_against_auth,
          total_payment_made_against_auth_date: this.pipe.transform(this.viewPaymentsById.date_of_payment_made_against_auth, "yyyy-MM-dd"),
          // proof_of_payent_doc1_path:this.selectedFile,
          // proof_of_payent_doc2_path:this.selectedFile1,
          // proof_of_payent_doc3_path:this.selectedFile2,
          // proof_of_payent_doc4_path:this.selectedFile3,
          // proof_of_payent_doc5_path:this.selectedFile4,
          // proof_of_payent_doc6_path:this.selectedFile5,
          // proof_of_payent_doc7_path:this.selectedFile6,
          // proof_of_payent_doc8_path:this.selectedFile7,
          // proof_of_payent_doc9_path:this.selectedFile8,
          // proof_of_payent_doc10_path:this.selectedFile9
        });

      })

  }
  update() {
    console.log(this.autoFillForm.value);
      if(this.selectedFile==undefined){
        this.selectedFile = null;
      } if(this.selectedFile1==undefined)
      {
        this.selectedFile1 = null;
      }
       if(this.selectedFile2==undefined)
      {
        this.selectedFile2 = null;
      }
      if(this.selectedFile3==undefined)
      {
        this.selectedFile3 = null;
      }
       if(this.selectedFile4==undefined)
      {
        this.selectedFile4 = null;
      }
       if(this.selectedFile5==undefined)
      {
        this.selectedFile5 = null;
      }
       if(this.selectedFile6==undefined)
      {
        this.selectedFile6 = null;
      }
      if(this.selectedFile7==undefined)
      {
        this.selectedFile7 = null;
      }
      if(this.selectedFile8==undefined)
      {
        this.selectedFile8 = null;
      }
       if(this.selectedFile9==undefined)
      {
        this.selectedFile9 = null;
      }
      let input = {
        proof_of_payment_id:this.proof_of_payment_id,
        name_of_firm:this.viewPaymentsById.name_of_firm,
        iec_number: this.autoFillForm.value.iec_number,
        auth_type: this.autoFillForm.value.auth_type,
        auth_no: this.autoFillForm.value.auth_no,
        auth_date:this.pipe.transform(this.autoFillForm.value.auth_date, "yyyy-MM-dd HH:mm:ss"),
        total_payment_made_against_auth: this.autoFillForm.value.total_payment_made_against_auth,
        total_payment_made_against_auth_date: this.pipe.transform(this.autoFillForm.value.total_payment_made_against_auth_date, "yyyy-MM-dd HH:mm:ss"),
        proof_of_payent_doc1: this.selectedFile,
        proof_of_payent_doc2: this.selectedFile1,
        proof_of_payent_doc3: this.selectedFile2,
        proof_of_payent_doc4: this.selectedFile3,
        proof_of_payent_doc5: this.selectedFile4,
        proof_of_payent_doc6: this.selectedFile5,
        proof_of_payent_doc7: this.selectedFile6,
        proof_of_payent_doc8: this.selectedFile7,
        proof_of_payent_doc9: this.selectedFile8,
        proof_of_payent_doc10: this.selectedFile9
      }
      this.blockchainService.updateProofOfPayments(input).subscribe(
        (resp) => {
          console.log(resp.body.obj)
          if(resp.body.status==200){
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );
            setTimeout(() => {
              this.router.navigate(['/proofOfPayment']);
            }, 1500);	
          
          }else{
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );	
          }
        })

  }
  reject(){
    let input={
      proof_of_payment_id:this.proof_of_payment_id
    }
    this.blockchainService.updateProofOfPaymentsRejectStatus(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        if(resp.body.status==200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
          
            this.router.navigate(['/proofOfPayment']);
       
        }else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
        }
      })
  }
  back(){
    this.router.navigate(['/proofOfPayment']);
  }
  validate(){
    let input={
      proof_of_payment_id:this.proof_of_payment_id
    }
    this.blockchainService.updateProofOfPaymentsValidateStatus(input).subscribe(
      (resp) => {
        console.log(resp.body.obj)
        if(resp.body.status==200){
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
          
            this.router.navigate(['/proofOfPayment']);
       
        }else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );	
        }
      })
  }
  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd HH:mm:ss");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  // numberOnly(event): boolean {
  //   const charCode = event.which ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false;
  //   }
  //   return true;
  // }
  onFileSelected(event, action) {
    if (action == 'pdf') {
      this.selectedFile = <File>event.target.files[0];
      if(this.selectedFile.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    
    } else if (action == 'pdf0') {
      this.selectedFile1 = <File>event.target.files[0];
      if(this.selectedFile1.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload1();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    
    } else if (action == 'pdf1') {
      this.selectedFile2 = <File>event.target.files[0];
      if(this.selectedFile2.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload2();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    
    } else if (action == 'pdf2') {
      this.selectedFile3 = <File>event.target.files[0];
      if(this.selectedFile3.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload3();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    } else if (action == 'pdf3') {
      this.selectedFile4 = <File>event.target.files[0];
      if(this.selectedFile4.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload4();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    
    } else if (action == 'pdf4') {
      this.selectedFile5 = <File>event.target.files[0];
      if(this.selectedFile5.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload5();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
     
    } else if (action == 'pdf5') {
      this.selectedFile6 = <File>event.target.files[0];
      if(this.selectedFile6.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload6();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    
    }
    else if (action == 'pdf6') {
      this.selectedFile7 = <File>event.target.files[0];
      if(this.selectedFile7.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload7();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
   
    }
    else if (action == 'pdf7') {
      this.selectedFile8 = <File>event.target.files[0];
      if(this.selectedFile8.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload8();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    
    }
    else if (action == 'pdf9') {
      this.selectedFile9 = <File>event.target.files[0];
      if(this.selectedFile9.type == 'application/pdf'){
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.url = event.target.result;
        };
        this.onUpload9();
      }
      else{
        event.target.files[0].value='';
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
      
   
    }


  }

  //for iec  
  onUpload() {
    const fd = new FormData();
    fd.append("file", this.selectedFile, this.selectedFile.name);
  }

  onUpload1() {
    const fd = new FormData();
    fd.append("file", this.selectedFile1, this.selectedFile1.name);
  }

  onUpload2() {
    const fd = new FormData();
    fd.append("file", this.selectedFile2, this.selectedFile2.name);
  }

  onUpload3() {
    const fd = new FormData();
    fd.append("file", this.selectedFile3, this.selectedFile3.name);
  }

  onUpload4() {
    const fd = new FormData();
    fd.append("file", this.selectedFile4, this.selectedFile4.name);
  }

  onUpload5() {
    const fd = new FormData();
    fd.append("file", this.selectedFile5, this.selectedFile5.name);
  }

  onUpload6() {
    const fd = new FormData();
    fd.append("file", this.selectedFile6, this.selectedFile6.name);
  }

  onUpload7() {
    const fd = new FormData();
    fd.append("file", this.selectedFile7, this.selectedFile7.name);
  }

  onUpload8() {
    const fd = new FormData();
    fd.append("file", this.selectedFile8, this.selectedFile8.name);
  }
  onUpload9() {
    const fd = new FormData();
    fd.append("file", this.selectedFile9, this.selectedFile9.name);
  }
  onUpload10() {
    const fd = new FormData();
    fd.append("file", this.selectedFile10, this.selectedFile10.name);
  }



  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  
}
