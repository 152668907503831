import { Component, OnInit } from "@angular/core";
import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
// import { FlexAlignDirective } from "@angular/flex-layout";
import { AuthenticationService } from "app/_services/authentication.service";
import { DateAdapter } from "@angular/material/core";
import { AlertService } from "app/_services";

declare var $: any;

export interface IdentificationType {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.css"],
})
export class AddUserComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  allLevels: IdentificationType[] = [];
  allModules: IdentificationType[] = [];
  pipe = new DatePipe("en-US");
  msg = "";
  role: any;
  currentUser: any;
  iec_flag = false;

  selectedStatus: any;
  notiColor: any = "#06ffff";
  userForm = this.formBuilder.group({
    zone_name: ["", Validators.required],
    commissionerate: ["", Validators.required],
    section_list: ["", Validators.required],
    name: ["", Validators.required],
    mobile: [
      "",
      [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
    ],
    email: [
      "",
      [
        Validators.required,
      ],
    ],
    level: ["", Validators.required],
    section: ["", Validators.required],
    user_type: ["", Validators.required],
    designation: ["", Validators.required],
    // username: [""],
    // password: [""],
  });
  zoneList: any;
  commissionrateList: any;
  allSectionByCommissionrate: any;
  adminAction: string;
  zoneId: any;
  userTypeList: any;
  designationList: any;
  sectionList: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale("en-GB"); //dd/MM/yyyy
  }

  ngOnInit(): void {
    // this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    var cuser = JSON.parse(localStorage.getItem("currentUser"));

    this.role = cuser.obj.role;
    if (this.role == 0) {
      this.iec_flag = false;
    } else {
      this.iec_flag = true;
    }

    // for all zones
    this.getZoneList();
    this.getCommissionrate();
    this.getLevels();
    this.getModuleAccess();
    this.getAllSections();
    this.getAllDesignations();
    this.getAllUserTypes();
    var adminUser = JSON.parse(localStorage.getItem("adminUser"));
    var adminAction = JSON.parse(localStorage.getItem("adminAction"));
    this.adminAction = adminAction;
    console.log(adminAction);

    if (adminAction == "Edit") {
      var adminUser = JSON.parse(localStorage.getItem("adminUser"));
      this.getCustomsUsersById(adminUser.customs_user_id);
    } else {
      adminAction = "Add";
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getAllSections() {
    let input = {};
    this.blockchainService.getAllSections(input).subscribe((resp) => {
      this.sectionList = resp.body.obj;
    });
  }

  getAllDesignations() {
    let input = {};
    this.blockchainService.getAllDesignations(input).subscribe((resp) => {
      this.designationList = resp.body.obj;
    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    });
  }

  getAllUserTypes() {
    let input = {};
    this.blockchainService.getAllUserTypes(input).subscribe((resp) => {
      this.userTypeList = resp.body.obj;
    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    });
  }

  getZoneList() {
    this.blockchainService.getAllZones().subscribe((resp) => {
      this.zoneList = resp.obj;
    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    });
  }

  getCommissionrate() {
    this.blockchainService.getCommissionrate().subscribe((resp) => {
      this.commissionrateList = resp.obj;
    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    });
  }

  getLevels() {
    this.allLevels = [
      { value: "L1", viewValue: "L1" },
      { value: "L2", viewValue: "L2" },
      { value: "L3", viewValue: "L3" },
    ];
  }

  getModuleAccess() {
    this.allModules = [
      { value: "CASH", viewValue: "CASH" },
      { value: "AA", viewValue: "AA" },
      { value: "EPCG", viewValue: "EPCG" },
      { value: "IMPORT", viewValue: "IMPORT" },
      { value: "UNDER", viewValue: "UNDER" },
      { value: "BOND", viewValue: "BOND" },
    ];
  }

  selectCommissionrate(id, event) {
    if (event.isUserInput) {
      this.getSectionByCommissionrateId(id);
    }
  }

  getSectionByCommissionrateId(id) {
    let input = {
      id: id,
    };
    this.blockchainService
      .getSectionByCommissionrateId(input)
      .subscribe((resp) => {
        this.allSectionByCommissionrate = resp.body.obj;
      }, (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      });
  }

  get f() {
    return this.userForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.userForm.controls[controlName].hasError(errorName);
  };

  // next() {
  //   this.savePayments();
  //  }
  getCustomsUsersById(customs_user_id) {
    // let custumsAdmin = this.blockchainService.adminUser;
    let input = {
      customs_user_id: customs_user_id,
    };
    this.blockchainService.getCustomsUsersById(input).subscribe((resp) => {
      console.log(resp.body.obj);
      var editCustomUser = resp.body.obj;
      var encodedString = btoa(editCustomUser.password);
      console.log(encodedString);
      this.userForm = this.formBuilder.group({
        customs_user_id: [editCustomUser.customs_user_id],
        zone_name: [editCustomUser.zone_name],
        // zone_id:[editCustomUser.zone_id],
        commissionerate: [editCustomUser.commissionerate],
        section_list: [editCustomUser.section_list],
        name: [editCustomUser.name],
        mobile: [
          editCustomUser.mobile,
          [
            Validators.required,
            Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
          ],
        ],
        email: [
          editCustomUser.email,
          [
            Validators.required,

          ],
        ],
        level: [editCustomUser.level],
        section: [editCustomUser.section],
        user_type: [editCustomUser.user_type],
        designation: [editCustomUser.designation],
        // username: [editCustomUser.username],
        // password: [editCustomUser.password],
      });
    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    });
  }
  back() {
    this.router.navigate(["/manageUsers"]);
  }

  saveUser() {
    // console.log(this.userForm.value);
    // this.blockchainService.adminAction = "Add";
    this.blockUI.start("Please wait this may take few seconds ...");
    if (this.userForm.value.zone_name == "") {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Zone Name Cannot be Empty",
        "announcement"
      );
    } else if (this.userForm.value.commissionerate == "") {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Commissionerate Cannot be Empty",
        "announcement"
      );
    } else if (this.userForm.value.section == "") {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Section Cannot be Empty",
        "announcement"
      );
    } else if (this.userForm.value.name == "") {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Name Cannot be Empty",
        "announcement"
      );
    } else if (this.userForm.value.mobile == "") {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Mobile No Cannot be Empty",
        "announcement"
      );
    } else if (this.userForm.value.email == "") {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Email ID Cannot be Empty",
        "announcement"
      );
    } else if (this.userForm.value.level == "") {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Level Cannot be Empty",
        "announcement"
      );
    } else {
      if (this.userForm.valid) {
        this.blockUI.start("Please wait this may take few seconds ...");
        this.zoneList.forEach((e1) => {
          let zoneName = this.userForm.controls["zone_name"].value;
          if (e1.zone_name == zoneName) {
            this.zoneId = e1.zone_id;
            // this.userForm.controls['zone_id'].setValue(this.zoneId);
          }
        });
        var adminAction = JSON.parse(localStorage.getItem("adminAction"));
        if (adminAction == "Add") {
          this.blockchainService
            .saveCustomsUsers(this.userForm.value)
            .subscribe(
              (data) => {
                if (data.status == 200) {
                  console.log(data.body);
                  setTimeout(() => {
                    this.blockUI.stop();
                  }, 1500);

                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "User Added Successfully",
                    "announcement"
                  );
                  setTimeout(() => {
                    this.msg = null;
                    this.router.navigate(["/manageUsers"]);
                  }, 3000);
                }
              }, (error) => {
                console.log(error)
                this.alertService.error(error);
                if(error.status == 401){
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Session Expired, Please Login Again",
                    "announcement"
                  );
                  this.router.navigate(["/login"]);
                }
                else if(error.status == 500){
                  setTimeout(() => {
                    this.blockUI.stop();
                  }, 1500);
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Please Try Again",
                    "announcement"
                  );
                }
                else{}
              })
        } else if (adminAction == "Edit") {
          this.blockchainService
            .updateCustomsUsers(this.userForm.value)
            .subscribe(
              (data) => {
                if (data.status == 200) {
                  setTimeout(() => {
                    this.blockUI.stop();
                  }, 1500);

                  console.log(data.body);
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "User Updated Successfully",
                    "announcement"
                  );
                  setTimeout(() => {
                    this.msg = null;
                    this.router.navigate(["/manageUsers"]);
                  }, 3000);
                }
              },
              (error) => {
                console.log(error)
                this.alertService.error(error);
                if(error.status == 401){
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Session Expired, Please Login Again",
                    "announcement"
                  );
                  this.router.navigate(["/login"]);
                }
                else if(error.status == 500){
                  setTimeout(() => {
                    this.blockUI.stop();
                  }, 1500);
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Please Try Again",
                    "announcement"
                  );
                }
                else{}
              })
        } else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Enter Valid Details",
            "announcement"
          );
        }
      }
    }
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", this.notiColor, "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 5000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4' +
          ' alert alert-{0} alert-with-icon" role="alert"><button mat-button  type="butto' +
          'n" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i clas' +
          's="material-icons">close</i></button><i class="material-icons" data-notify="ic' +
          'on">notifications</i> <span data-notify="title">{1}</span> <span data-notify="' +
          'message">{2}</span><div class="progress" data-notify="progressbar"><div class=' +
          '"progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valu' +
          'emin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" targ' +
          'et="{4}" data-notify="url"></a></div>',
      }
    );
  }
}
