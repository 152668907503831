import { Overlay } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockchainService } from 'app/blockchainImpExp/blockchain.service';
declare var $:any;
@Component({
  selector: 'app-search-case-summary',
  templateUrl: './search-case-summary.component.html',
  styleUrls: ['./search-case-summary.component.css']
})
export class SearchCaseSummaryComponent implements OnInit {
  allApplicationTypes: any;
  selectedDate: any;
  ivDate: any;
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  date: any;
  searchForm = this.formBuilder.group({
    license_no:["",Validators.required],
   });
  currentUser: any;
  constructor( private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  
  }
  get f() {
    return this.searchForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.searchForm.controls[controlName].hasError(errorName);
  };
  getApplicationType() {
    this.blockchainService.getApplicationType().subscribe((resp) => {
      this.allApplicationTypes = resp.obj;
    
    });
  }
  approve(){
    
    let input = {
      auth_number:this.searchForm.value.license_no
    }
    this.blockchainService.getAllBlockDataForSearchCase(input).subscribe(
      (resp) => {
      
        if(resp.body.status == 200){
          localStorage.setItem('searchCase',"Data");
          localStorage.setItem('searchCaseData',JSON.stringify(resp.body.obj));
          localStorage.removeItem('searchCaseDataId');
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout(() => {
            this.router.navigate(["/blockData"]);
          }, 1000);
         
        }else{
          localStorage.setItem('searchCase',"DataId");
          localStorage.setItem('searchCaseDataId',JSON.stringify(resp.body.obj));
          localStorage.removeItem('searchCaseData');
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          setTimeout(() => {
            this.router.navigate(["/blockData"]);
          }, 1000);
         
        }
  })
}

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd HH:mm");
    
  }
  // getAppType(value){
  //   let app = []
  //   app =  this.allApplicationTypes.filter(s => s.name != 'AA');
  //   return app;
  // }
  // selectedApplicationType(event, applicationType) {
  //   if (event.isUserInput) {
  //     console.log(event);
  //     this.applicationType = applicationType;
  //     localStorage.setItem('liscenseType',applicationType);
  //   }
  // }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
