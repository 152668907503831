import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from "@angular/common";
import { DateAdapter } from "@angular/material/core";
@Component({
  selector: 'app-casefile-block-data',
  templateUrl: './casefile-block-data.component.html',
  styleUrls: ['./casefile-block-data.component.css']
})
export class CasefileBlockDataComponent implements OnInit {
  searchCaseData: any;
  searchCaseFlag: any;

  constructor(private router: Router,private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
   }

  ngOnInit(): void {
    this.searchCaseFlag = localStorage.getItem("searchCase");
    if(this.searchCaseFlag=="DataId"){
      this.searchCaseData = JSON.parse(localStorage.getItem("searchCaseDataId"));
    }else{
      this.searchCaseData = JSON.parse(localStorage.getItem("searchCaseData"));
    }
    
  
  }
  viewGreenNoteData(){
    this.router.navigate(['/greenSheet']);
  }
  back(){
    this.router.navigate(['/searchCase']);
  }
}
