import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService, ImportAAQuery, L2AuthorizationAfterValidityActionQuery } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { DateAdapter } from "@angular/material/core";

declare var $: any;

export interface L2AuthorizationAfterValidityAction {
  bg_amount: number
  bond_amount: number
  no_of_auth: number
  no_of_iec: number
}


@Component({
  selector: 'app-processor-view',
  templateUrl: './processor-view.component.html',
  styleUrls: ['./processor-view.component.css']
})
export class ProcessorViewComponent implements OnInit {
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  date: any;
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  // beforeValidityResp: PaginatedDataSource<L2AuthorizationAfterValidityAction, L2AuthorizationAfterValidityActionQuery>;
  initialSort: Sort<L2AuthorizationAfterValidityAction> = { property: "bg_amount", order: "asc" };
  beforeValidityResp:L2AuthorizationAfterValidityAction;
  displayedColumns = [
    "category",
    "pre_expiry_month",
    "month_0_1",
    "month_1_2",
    "month_2_6",
    "month_6_24",
    "more_2_years"
  ];


  displayedColumns1 = [
    "category",
    "amount",
    "total_no_application",
    "view_details",
  ];
  cardDetailsResp: any;
  authAfterValidityPeriodResp: any;
  authAfterValidityResp: any;
  bondsActionSummaryResp: any;
  authBlockValidityResp: any;
  statusClass =  'active';
  statusClass1 =  'not-active';
  applicationType: string = 'AA';
  isAA: boolean = true;
  loader:boolean;
  loader1:boolean;
  loader2:boolean;
  loader3:boolean;
  loader4:boolean;
  loader5:boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
      this.date = this.todayDate;
      this.getL2SummaryDetailsByDate();
    
  }

  ngOnInit(): void {
    localStorage.removeItem('applicationType')
    localStorage.removeItem('redirectedpage');
    this.date = this.todayDate;
    localStorage.setItem("ObMDate", this.date);
    // this.getL2SummaryDetailsByDate();
    this.loader=true;
    this.loader1=true;
    this.loader2=true;
    this.loader3=true;
    this.loader4=true;
    this.loader5=true;
    this.getL2AuthorizationAfterExportValidityActionPending();
   
    // this.getL2AuthorizationAfterValidityActionSummary();
    this.getL2AuthorizationBeforeValidityAction();
    this.getL2AuthorizationAfterExportValidityPeriodOver();
    this.getL2AuthorizationBeforeExportValidityPeriodByBlock();
    this.getL2AuthorizationAfterValidityActionSummary();
  }

  openTab(e,action){
    if(action == 'aa'){
      localStorage.setItem('applicationType','AA');
      this.applicationType = 'AA'
      this.isAA = true;
      this.statusClass = 'active';
      this.statusClass1 =  'not-active';
      //api call
      this.getL2SummaryDetailsByDate();
      this.getL2AuthorizationAfterExportValidityActionPending();
      this.getL2AuthorizationAfterValidityActionSummary();
      this.getL2AuthorizationBeforeValidityAction();
      this.getL2AuthorizationBeforeExportValidityPeriodByBlock();
      this.getL2AuthorizationAfterExportValidityPeriodOver();

    }  else{
      localStorage.setItem('applicationType','EPCG');
      this.applicationType = 'EPCG'
      this.isAA = false;
      this.statusClass1 = 'active';
      this.statusClass =  'not-active';
      //api call
      this.getL2SummaryDetailsByDate();
      this.getL2AuthorizationAfterExportValidityActionPending();
      this.getL2AuthorizationAfterValidityActionSummary();
      this.getL2AuthorizationBeforeValidityAction();
      this.getL2AuthorizationBeforeExportValidityPeriodByBlock();
      this.getL2AuthorizationAfterExportValidityPeriodOver();
    }
  }
  
  getL2AuthorizationAfterExportValidityActionPending(){
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader1=true;
      let input = {
        start_date: this.date,
        applicationType: this.applicationType ? this.applicationType : 'AA' 
      };
      this.blockchainService
        .getL2AuthorizationAfterExportValidityActionPending(input)
        .subscribe((resp) => {
          if (resp.body.status == 200) {
            this.loader1=false;
            this.authAfterValidityResp = resp.body.obj;
            setTimeout(() => {
              this.blockUI.stop();
            }, 2500);
          } else {
            this.loader1=false;
            this.showNotification(
              "bottom",
              "right",
              "danger",
              resp.body.message,
              "announcement"
            );
            setTimeout(() => {
              this.blockUI.stop();
            }, 2500);
          }
        });
  }
  getL2AuthorizationBeforeExportValidityPeriodByBlock(){
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader4=true;
      let input = {
        start_date: this.date,
        applicationType: this.applicationType ? this.applicationType : 'AA' 
      };
      this.blockchainService
        .getL2AuthorizationBeforeExportValidityPeriodByBlock(input)
        .subscribe((resp) => {
          if (resp.body.status == 200) {
            this.loader4=false;
            this.authBlockValidityResp = resp.body.obj;
            setTimeout(() => {
              this.blockUI.stop();
            }, 2500);
          } else {
            this.loader4=false;
            this.showNotification(
              "bottom",
              "right",
              "danger",
              resp.body.message,
              "announcement"
            );
            setTimeout(() => {
              this.blockUI.stop();
            }, 2500);
          }
        });
  }
  getL2SummaryDetailsByDate() {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader=true;
    let input = {
      start_date: this.date,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getL2SummaryDetailsByDate(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.loader=false;
          this.cardDetailsResp = resp.body.obj;
          
          setTimeout(() => {
            this.blockUI.stop();
          }, 2500);
        } else {
          this.loader=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
          setTimeout(() => {
            this.blockUI.stop();
          }, 2000);
        }
      });
  }

  // getL2AuthorizationAfterValidity() {
  //   //this.blockUI.start("Please wait this may take few seconds ...");
   
  //   let input = {
  //     start_date: this.date,
  //   };
  //   this.blockchainService
  //     .getL2AuthorizationAfterValidity(input)
  //     .subscribe((resp) => {
  //       if (resp.body.status == 200) {
  //         this.authAfterValidityResp = resp.body.obj;

  //       } else {
  //         this.showNotification(
  //           "bottom",
  //           "right",
  //           "danger",
  //           resp.body.message,
  //           "announcement"
  //         );
  //       }
  //     });
  // }

  getL2AuthorizationAfterValidityActionSummary() {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader5=true;
    console.log(this.loader5);
    let input = {
      start_date: this.date,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getL2AuthorizationAfterValidityActionSummary(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.loader5=false;
          this.bondsActionSummaryResp = resp.body.obj;
         
          setTimeout(() => {
            this.blockUI.stop();
          }, 2500);
        } else {
          this.loader5=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        }
      });
  }

  getL2AuthorizationBeforeValidityAction() {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader2=true;
    let input = {
      start_date: this.date,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getL2AuthorizationBeforeValidityAction(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.loader2=false;
          if(resp.body.obj != null || resp.body.obj!= ''){
            this.beforeValidityResp = resp.body.obj;
          }
         
         
          setTimeout(() => {
            this.blockUI.stop();
          }, 2500);
        } else {
          this.loader2=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        }
      });
  }

  getL2AuthorizationAfterExportValidityPeriodOver(){
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader3=true;
    let input = {
      start_date: this.date,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getL2AuthorizationAfterExportValidityPeriodOver(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.loader3=false;
          this.authAfterValidityPeriodResp = resp.body.obj;
         
          // console.log(this.authAfterValidityPeriodResp,'validity period')
          setTimeout(() => {
            this.blockUI.stop();
          }, 2500);
        } else {
          this.loader3=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        }
      });
  }


  authAfterExport(action) {
    if (action == "1") {
      localStorage.setItem("authAfterExp", "1");
      this.router.navigate(["/authAfterExport"]);
    } else if (action == "2") {
      localStorage.setItem("authAfterExp", "2");
      this.router.navigate(["/authAfterExport"]);
    } else if (action == "3") {
      localStorage.setItem("authAfterExp", "3");
      this.router.navigate(["/authAfterExport"]);
    } else if (action == "4") {
      localStorage.setItem("authAfterExp", "4");
      this.router.navigate(["/authAfterExport"]);
    } else if (action == "5") {
      localStorage.setItem("authAfterExp", "5");
      this.router.navigate(["/authAfterExport"]);
    } else {
    }
  }

  viewDetails(e){
    console.log(e);
    if(e == 'EODC APPLICATION NOT FILED'){
      localStorage.setItem('eodcApplicationStatus','notfiled');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e == 'EODC APPLIED BUT NOT YET GRANTED'){
      localStorage.setItem('eodcApplicationStatus','notgranted');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e == 'EODC APPLIED AND REJECTED BY DGFT'){
      localStorage.setItem('eodcApplicationStatus','rejected');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e == 'EODC APPLIED AND  GRANTED BY DGFT  BUT BOND CANCELLATION NOT FILED'){
      localStorage.setItem('eodcApplicationStatus','granted');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e == 'EODC GRANTED BY DGFT AND BOND CANCELLATION FILED'){
      localStorage.setItem('eodcApplicationStatus','bondfiled');
     this.router.navigate(['/detailAuthSummaryValidity']);

    }
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    localStorage.setItem("ObMDate", this.date);
    // this.todayDate = this.date;
    this.getL2AuthorizationAfterExportValidityActionPending();
   
    // this.getL2AuthorizationAfterValidityActionSummary();
    this.getL2AuthorizationBeforeValidityAction();
    this.getL2AuthorizationAfterExportValidityPeriodOver();
    this.getL2AuthorizationBeforeExportValidityPeriodByBlock();
    this.getL2AuthorizationAfterValidityActionSummary();
  }

  process(){
    this.router.navigate(["/scnModulePS"]);
  }

  navigateToLiveBond(e) {
    console.log(e);
    if (e == "LIVE") {
      this.router.navigate(["/liveBond"]);
    } else {
      this.router.navigate(["/cancelledBond"]);
    }
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

  

}
