import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { element } from "protractor";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { PaymentsumComponent } from "../paymentsum/paymentsum.component";

declare var $: any;
export interface Status {
  value: string;
  viewValue: string;
}
export interface DutiesElement {
  no: number;
  duties: string;
  commissionerate: string;
  section: string;
  amount: string;
  action: any;
  remarks: any;
  payment_id: string;
}

const Duties_DATA: DutiesElement[] = [
  {
    no: 1,
    duties: "",
    commissionerate: "",
    section: "",
    amount: "",
    action: "",
    remarks: "",
    payment_id: "",
  },
];

export interface MiscellaneousElement {
  no: number;
  miscellaneous: string;
  commissionerate: string;
  section: string;
  amount: string;
  action: any;
  remarks: any;
  payment_id: string;
}

const Miscellaneous_DATA: MiscellaneousElement[] = [
  {
    no: 1,
    miscellaneous: "",
    commissionerate: "",
    section: "",
    amount: "",
    action: "",
    remarks: "",
    payment_id: "1",
  },
];

export interface OtherElement1 {
  no: number;
  otherHead: string;
  details: string;
  amount: string;
  action: any;
  remarks: string;
}

export interface OtherElement2 {
  no: number;
  otherHead: string;
  details: string;
  amount: string;
  action: any;
  remarks: string;
}

export interface OtherElement3 {
  no: number;
  otherHead: string;
  details: string;
  amount: string;
  action: any;
  remarks: string;
}

const Other_DATA: OtherElement1[] = [
  {
    no: 1,
    otherHead: "PERSONAL ACCOUNT CREDIT DEPOSIT",
    details: "",
    amount: "",
    remarks: "",
    action: "",
  },
  // {
  //   no: 2,
  //   otherHead: "OVERTIME",
  //   details: "",
  //   amount: "",
  // },
  // {
  //   no: 3,
  //   otherHead: "CUSTOMS REVENUE STAMP",
  //   details: "",
  //   amount: "",
  // },
];

export interface heads_request {
  head_name: string;
  commissionerate: string;
  section: string;
  amount_in_inr: string;
  type_of_head: string;
  payment_id: string;
  remarks: string;
}

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.css"],
})
export class AccountComponent implements OnInit {
  displayedColumns = [
    "no",
    "duties",
    "commissionerate",
    "section",
    "amount",
    "remarks",
    "action",
  ];
  displayedColumns0 = [
    "no",
    "miscellaneous",
    "commissionerate",
    "section",
    "amount",
    "remarks",
    "action",
  ];
  displayedColumns1 = [
    "no",
    "otherHead",
    "details",
    "amount",
    "remarks",
    "action",
  ];
  displayedColumns2 = [
    "no",
    "otherHead",
    "details",
    "amount",
    "remarks",
    "action",
  ];
  displayedColumns3 = [
    "no",
    "otherHead",
    "details",
    "amount",
    "remarks",
    "action",
  ];

  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  dataSource0 = new BehaviorSubject<AbstractControl[]>([]);
  dataSource1 = new BehaviorSubject<AbstractControl[]>([]);
  dataSource2 = new BehaviorSubject<AbstractControl[]>([]);
  dataSource3 = new BehaviorSubject<AbstractControl[]>([]);

  finalList: heads_request[] = [];
  // otherFormArr: otherFormInterface[] = [];

  data: DutiesElement[] = [
    {
      no: 1,
      duties: "",
      commissionerate: "",
      section: "",
      amount: "",
      action: "",
      remarks: "",
      payment_id: "1",
    },
  ];

  data1: MiscellaneousElement[] = [
    {
      no: 1,
      miscellaneous: "",
      commissionerate: "",
      section: "",
      amount: "",
      action: "",
      remarks: "",
      payment_id: "1",
    },
  ];

  data2: OtherElement1[] = [
    {
      no: 1,
      otherHead: "",
      details: "",
      amount: "",
      remarks: "",
      action: "",
    },
  ];

  data3: OtherElement2[] = [
    {
      no: 1,
      otherHead: "",
      details: "",
      amount: "",
      remarks: "",
      action: "",
    },
  ];

  data4: OtherElement3[] = [
    {
      no: 1,
      otherHead: "",
      details: "",
      amount: "",
      remarks: "",
      action: "",
    },
  ];

  duties_master: UntypedFormArray = this.formBuilder.array([]);
  dutiesForm: UntypedFormGroup = this.formBuilder.group({
    duties_master: this.duties_master,
  });

  misc_master: UntypedFormArray = this.formBuilder.array([]);
  miscForm: UntypedFormGroup = this.formBuilder.group({
    misc_master: this.misc_master,
  });

  other_master: UntypedFormArray = this.formBuilder.array([]);
  otherForm: UntypedFormGroup = this.formBuilder.group({
    other_master: this.other_master,
  });

  other_master2: UntypedFormArray = this.formBuilder.array([]);
  otherForm2: UntypedFormGroup = this.formBuilder.group({
    other_master2: this.other_master2,
  });

  other_master3: UntypedFormArray = this.formBuilder.array([]);
  otherForm3: UntypedFormGroup = this.formBuilder.group({
    other_master3: this.other_master3,
  });

  pipe = new DatePipe("en-US");

  selectedStatus = "";

  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  allDocType: any;
  active: boolean = false;
  allDuties: any;
  allMiscellaneous: any;
  allSectionByCommissionrate: any = [];
  allOvertime: any;
  allCommissionrate: any;
  selectedDuties: any;
  seletedCommissionerateId: any;
  seletedCommissionerate: any;
  selectedSection: any;
  selectedMiscellaneous: any;
  selectedOvertime: any;
  removeRowNo: any;
  removeRowNo1: any;
  selectedSectionMisc: any;
  payableAmt: any = 0;

  payment_id: string;
  otherHead1 = "PERSONAL ACCOUNT CREDIT DEPOSIT";
  otherHead2 = "OVERTIME";
  otherHead3 = "CUSTOMS REVENUE STAMP";

  allSectionByCommissionrate1: any = [];
  removeRowNo2: any;
  removeRowNo4: any;
  removeRowNo3: any;
  isNextEnable: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) {}

  ngOnInit(): void {
    this.getDuties();
    this.getCommissionrate();
    this.getMiscellaneous();
    this.getOvertime();

    this.data.forEach((d: DutiesElement) => this.addRowDuties(d, false));
    this.updateViewDuties();

    this.data1.forEach((d: MiscellaneousElement) => this.addRowMisc(d, false));
    this.updateViewMisc();

    this.data2.forEach((d: OtherElement1) => this.addRowOther(d, false));
    this.updateViewOther();

    this.data3.forEach((d: OtherElement2) => this.addRowOther2(d, false));
    this.updateViewOther2();

    this.data4.forEach((d: OtherElement3) => this.addRowOther3(d, false));
    this.updateViewOther3();

    this.payment_id = localStorage.getItem("payment_id");
  }

  payableAmtCalculate() {
    let newList: heads_request[] = [];

    this.dutiesForm.value.duties_master.forEach((element) => {
      newList.push({
        head_name: element.duties,
        commissionerate: element.commissionerate,
        section: element.section,
        amount_in_inr: element.amount ? element.amount : "0",
        type_of_head: "1",
        payment_id: this.payment_id,
        remarks: element.remarks,
      });
    });
    this.miscForm.value.misc_master.forEach((element) => {
      newList.push({
        head_name: element.miscellaneous,
        commissionerate: element.commissionerate,
        section: element.section,
        // section:this.selectedSectionMisc,
        amount_in_inr: element.amount ? element.amount : "0",
        type_of_head: "2",
        payment_id: this.payment_id,
        remarks: element.remarks,
      });
    });

    this.otherForm.value.other_master.forEach((element) => {
      newList.push({
        head_name: "Others",
        commissionerate: element.otherHead,
        section: element.details,
        amount_in_inr: element.amount ? element.amount : "0",
        type_of_head: "3",
        payment_id: this.payment_id,
        remarks: element.remarks,
      });
    });

    this.otherForm2.value.other_master2.forEach((element) => {
      newList.push({
        head_name: "Others",
        commissionerate: element.otherHead,
        section: element.details,
        amount_in_inr: element.amount ? element.amount : "0",
        type_of_head: "3",
        payment_id: this.payment_id,
        remarks: element.remarks,
      });
    });

    this.otherForm3.value.other_master3.forEach((element) => {
      newList.push({
        head_name: "Others",
        commissionerate: element.otherHead,
        section: element.details,
        amount_in_inr: element.amount ? element.amount : "0",
        type_of_head: "3",
        payment_id: this.payment_id,
        remarks: element.remarks,
      });
    });

    this.payableAmt = 0;
    newList.forEach((element) => {
      this.payableAmt = this.payableAmt + parseInt(element.amount_in_inr);
    });
    localStorage.setItem("payableAmount", this.payableAmt);

    console.log(this.payableAmt, "payableAmt");
  }

  addRowDuties(d?: DutiesElement, noUpdate?: boolean) {
    const newRow = this.formBuilder.group({
      no: Date.now(),
      duties: [d && d.duties ? d.duties : ""],
      commissionerate: [d && d.commissionerate ? d.commissionerate : ""],
      section: [d && d.section ? d.section : ""],
      amount: [d && d.amount ? d.amount : ""],
      remarks: [d && d.remarks ? d.remarks : ""],
      action: [null],
    });
    console.log(newRow, "dd");
    this.duties_master.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateViewDuties();
    }
  }

  back() {}

  updateViewDuties() {
    this.dataSource.next(this.duties_master.controls);
  }

  addRowMisc(d?: MiscellaneousElement, noUpdate?: boolean) {
    const newRow = this.formBuilder.group({
      no: Date.now(),
      miscellaneous: [d && d.miscellaneous ? d.miscellaneous : ""],
      commissionerate: [d && d.commissionerate ? d.commissionerate : ""],
      section: [d && d.section ? d.section : ""],
      amount: [d && d.amount ? d.amount : ""],
      remarks: [d && d.remarks ? d.remarks : ""],
      action: [null],
    });
    console.log(newRow, "miscellaneous");
    this.misc_master.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateViewMisc();
    }
  }

  updateViewMisc() {
    this.dataSource0.next(this.misc_master.controls);
  }

  addRowOther(d?: OtherElement1, noUpdate?: boolean, others?: any) {
    const newRow = this.formBuilder.group({
      no: Date.now(),
      otherHead: [others],
      details: [d && d.details ? d.details : ""],
      amount: [d && d.amount ? d.amount : ""],
      remarks: [d && d.remarks ? d.remarks : ""],
    });
    console.log(newRow, "other");
    this.other_master.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateViewOther();
    }
  }

  updateViewOther() {
    this.dataSource1.next(this.other_master.controls);
  }

  addRowOther2(d?: OtherElement2, noUpdate?: boolean, others?: any) {
    const newRow = this.formBuilder.group({
      no: Date.now(),
      otherHead: [others],
      details: [d && d.details ? d.details : ""],
      amount: [d && d.amount ? d.amount : ""],
      remarks: [d && d.remarks ? d.remarks : ""],
    });
    console.log(newRow, "other");
    this.other_master2.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateViewOther2();
    }
  }

  updateViewOther2() {
    this.dataSource2.next(this.other_master2.controls);
  }

  addRowOther3(d?: OtherElement3, noUpdate?: boolean, others?: any) {
    const newRow = this.formBuilder.group({
      no: Date.now(),
      otherHead: [others],
      details: [d && d.details ? d.details : ""],
      amount: [d && d.amount ? d.amount : ""],
      remarks: [d && d.remarks ? d.remarks : ""],
    });
    console.log(newRow, "other");
    this.other_master3.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateViewOther3();
    }
  }

  updateViewOther3() {
    this.dataSource3.next(this.other_master3.controls);
  }

  removeRow() {
    let no = this.removeRowNo;
    const roomArr: any[] = this.dataSource.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if(index == 0){
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );
        }
          else{
        roomArr.splice(index, 1);
        this.dutiesForm.value.duties_master.splice(index, 1);
          }
      }
    });
    this.dataSource.next(roomArr);
    //this.updateViewDuties();

    // let no = this.removeRowNo;
    // this.dataSource = this.dataSource.filter((u) => u.no !== no);
  }

  removeRow1() {
    let no = this.removeRowNo1;
  
    const roomArr: any[] = this.dataSource0.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if(index == 0){
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );}
          else{
        roomArr.splice(index, 1);
        this.miscForm.value.misc_master.splice(index, 1);
          }
      }
    });
    this.dataSource0.next(roomArr);
  }

  removeRow2() {
    let no = this.removeRowNo1;
    const roomArr: any[] = this.dataSource1.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if(index == 0){
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );}
          else{
        roomArr.splice(index, 1);
        this.otherForm.value.other_master.splice(index, 1);
          }
      }
    });
    this.dataSource1.next(roomArr);
    // this.dataSource0 = this.dataSource0.filter((u) => u.no !== no);
  }

  removeRow3() {
    let no = this.removeRowNo3;
    const roomArr: any[] = this.dataSource2.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if(index == 0){
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );}
          else{
        roomArr.splice(index, 1);
        this.otherForm2.value.other_master2.splice(index, 1);
          }
      }
    });
    this.dataSource2.next(roomArr);
    // this.dataSource0 = this.dataSource0.filter((u) => u.no !== no);
  }

  removeRow4() {
    let no = this.removeRowNo4;
    const roomArr: any[] = this.dataSource3.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if(index == 0){
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );}
          else{
        roomArr.splice(index, 1);
        this.otherForm3.value.other_master3.splice(index, 1);
          }
      }
    });
    this.dataSource3.next(roomArr);
    // this.dataSource0 = this.dataSource0.filter((u) => u.no !== no);
  }

  activeFn() {
    this.active = true;
  }
  getDuties() {
    this.blockchainService.getDuties().subscribe((resp) => {
      this.allDuties = resp.obj;
    });
  }
  selectDuties(name, event) {
    if (event.isUserInput) {
      this.selectedDuties = name;
    }
  }

  getCommissionrate() {
    this.blockchainService.getCommissionrate().subscribe((resp) => {
      this.allCommissionrate = resp.obj;
    });
  }

  selectCommissionrate(name, id, action, i, event) {
    if (event.isUserInput) {
      if (action == "duties") {
        console.log("duties", id);
        this.getSectionByCommissionrateId(id, i);
      } else {
        this.getSectionByCommissionrateId1(id, i);
      }
    }
  }

  getSectionByCommissionrateId(id, index) {
    let input = {
      id: id,
    };
    this.blockchainService
      .getSectionByCommissionrateId(input)
      .subscribe((resp) => {
        this.allSectionByCommissionrate[index] = resp.body.obj;
        console.log(
          this.allSectionByCommissionrate,
          "allSectionByCommissionrate"
        );
      });
  }

  getSectionByCommissionrateId1(id, index) {
    let input = {
      id: id,
    };
    this.blockchainService
      .getSectionByCommissionrateId(input)
      .subscribe((resp) => {
        // this.allSectionByCommissionrate.splice(index, 0, resp.body.obj)
        // this.allSectionByCommissionrate.push(resp.body.obj);
        this.allSectionByCommissionrate1[index] = resp.body.obj;
        console.log(
          this.allSectionByCommissionrate1,
          "allSectionByCommissionrate"
        );
      });
  }

  selectSectionByCommissionrate(name, action, event) {
    if (event.isUserInput) {
      if (action == "duties") {
        this.selectedSection = name;
      } else {
        this.selectedSectionMisc = name;
      }
    }
  }

  getMiscellaneous() {
    this.blockchainService.getMiscellaneous().subscribe((resp) => {
      this.allMiscellaneous = resp.obj;
    });
  }

  selectMiscellaneous(name, event) {
    if (event.isUserInput) {
      this.selectedMiscellaneous = name;
    }
  }

  getOvertime() {
    this.blockchainService.getOvertime().subscribe((resp) => {
      this.allOvertime = resp.obj;
    });
  }

  selectOvertime(name, event) {
    if (event.isUserInput) {
      this.selectedOvertime = name;
    }
  }

  next() {
    this.router.navigate(["/paymentMode"]);
  }

  openModal(no) {
    this.removeRowNo = no;
    const buttonModal = document.getElementById("openModalButton");
    buttonModal.click();
  }

  openModal1(no) {
    this.removeRowNo1 = no;
    const buttonModal = document.getElementById("openModalButton1");
    buttonModal.click();
  }

  openModal2(no) {
    this.removeRowNo2 = no;
    const buttonModal = document.getElementById("openModalButton2");
    buttonModal.click();
  }

  openModal3(no) {
    this.removeRowNo3 = no;
    const buttonModal = document.getElementById("openModalButton3");
    buttonModal.click();
  }

  openModal4(no) {
    this.removeRowNo4 = no;
    const buttonModal = document.getElementById("openModalButton4");
    buttonModal.click();
  }

  saveHeads() {
    if (
      this.payableAmt == 0 ||
      this.payableAmt == null ||
      this.payableAmt == undefined
    ) {
      this.openDialog(
        "paymentSum",
        "Please click on Calculate to check the Payable Amount",
        ""
      );
      this.isNextEnable = true;
    } else {
      this.dutiesForm.value.duties_master.forEach((element) => {
        if (
          element.amount != "" &&
          element.amount != null &&
          element.amount != undefined
        ) {
          if (
            element.duties == "" ||
            element.duties == null ||
            element.duties == undefined ||
            element.commissionerate == "" || element.section == ""
          ) {
          
              this.openDialog(
                "paymentSum",
                "Please fill the details of the amount entered",
                ""
              );
            
          }
        }
        this.finalList.push({
          head_name: element.duties,
          commissionerate: element.commissionerate,
          section: element.section,
          amount_in_inr: JSON.stringify(element.amount)
            ? JSON.stringify(element.amount)
            : "0",
          type_of_head: "1",
          payment_id: this.payment_id,
          remarks: element.remarks,
        });
      });
      this.miscForm.value.misc_master.forEach((element) => {
        if (
          element.amount != "" &&
          element.amount != null &&
          element.amount != undefined
        ) {
          if (
            element.miscellaneous == "" ||
            element.miscellaneous == null ||
            element.miscellaneous == undefined ||
            element.commissionerate == "" || element.section == ""
          ) {
            
              this.openDialog(
                "paymentSum",
                "Please fill the details of the amount entered",
                ""
              );
            
          }
        }
        this.finalList.push({
          head_name: element.miscellaneous,
          commissionerate: element.commissionerate,
          section: element.section,
          // section:this.selectedSectionMisc,
          amount_in_inr: JSON.stringify(element.amount)
            ? JSON.stringify(element.amount)
            : "0",
          type_of_head: "2",
          payment_id: this.payment_id,
          remarks: element.remarks,
        });
      });

      this.otherForm.value.other_master.forEach((element) => {
        if (
          element.amount != "" &&
          element.amount != null &&
          element.amount != undefined
        ) {
          if (
            element.section == "" ||
            element.section == null ||
            element.section == undefined
          ) {
            this.openDialog(
              "paymentSum",
              "Please fill the details of the amount entered",
              ""
            );
          }
        }
        this.finalList.push({
          head_name: "Others",
          commissionerate: "PERSONAL ACCOUNT CREDIT DEPOSIT",
          section: element.details,
          amount_in_inr: JSON.stringify(element.amount)
            ? JSON.stringify(element.amount)
            : "0",
          type_of_head: "3",
          payment_id: this.payment_id,
          remarks: element.remarks,
        });
      });

      this.otherForm2.value.other_master2.forEach((element) => {
        if (
          element.amount != "" &&
          element.amount != null &&
          element.amount != undefined
        ) {
          if (
            element.section == "" ||
            element.section == null ||
            element.section == undefined
          ) {
            this.openDialog(
              "paymentSum",
              "Please fill the details of the amount entered",
              ""
            );
          }
        }
        this.finalList.push({
          head_name: "Others",
          commissionerate: "OVERTIME",
          section: element.details,
          amount_in_inr: JSON.stringify(element.amount)
            ? JSON.stringify(element.amount)
            : "0",
          type_of_head: "3",
          payment_id: this.payment_id,
          remarks: element.remarks,
        });
      });

      this.otherForm3.value.other_master3.forEach((element) => {
        if (
          element.amount != "" &&
          element.amount != null &&
          element.amount != undefined
        ) {
          if (
            element.section == "" ||
            element.section == null ||
            element.section == undefined
          ) {
            this.openDialog(
              "paymentSum",
              "Please fill the details of the amount entered",
              ""
            );
          }
        }
        this.finalList.push({
          head_name: "Others",
          commissionerate: "CUSTOMS REVENUE STAMP",
          section: element.details,
          amount_in_inr: JSON.stringify(element.amount)
            ? JSON.stringify(element.amount)
            : "0",
          type_of_head: "3",
          payment_id: this.payment_id,
          remarks: element.remarks,
        });
      });

      console.log(this.finalList, "final list");

      let input = {
        heads_request: this.finalList,
      };
      console.log(input, "inputttt");
      this.blockUI.start("Please wait this may take few seconds ...");

      this.blockchainService.saveHeads(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1000);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.router.navigate(["/paymentMode"]);
        } else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1000);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      });
    }
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "paymentSum" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
