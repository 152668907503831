import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import {
  BlockchainService,
  ExportAAQuery,
} from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { DateAdapter } from "@angular/material/core";

declare var $: any;


export interface PeriodicElement {
  expSrNo: string;
  expItems: string;
  itemCharacteristics: string;
  itchsCode: string;
  quantity: string;
  uom: string;
  fobInr: string;
  fobForeign: string;
  edit: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    expSrNo: "",
    expItems: "",
    itemCharacteristics: "",
    itchsCode: "",
    quantity: "",
    uom: "",
    fobInr: "",
    fobForeign: "",
    edit: "",
  },
];

export interface ExportAA {
  application_id: number;
  created_date: string;
  deleted_date: string;
  export_serial_number: string;
  fob_for_foreign: string;
  fob_for_inr: string;
  itchs_code: string;
  item_characteristics: string;
  item_id: number;
  items_names: string;
  quantity: string;
  uom: string;
}

@Component({
  selector: "app-export-aa",
  templateUrl: "./export-aa.component.html",
  styleUrls: ["./export-aa.component.css"],
})
export class ExportAAComponent implements OnInit {
  expItemResp: PaginatedDataSource<ExportAA, ExportAAQuery>;
  initialSort: Sort<ExportAA> = { property: "created_date", order: "asc" };

  displayedColumns = [
    "expSrNo",
    "expItems",
    "itemCharacteristics",
    "itchsCode",
    "quantity",
    "uom",
    "fobInr",
    "fobForeign",
    "edit",
  ];
  dataSource = ELEMENT_DATA;

  pipe = new DatePipe("en-US");


  @BlockUI() blockUI: NgBlockUI|any;
  blockTemplate: BlockTemplateComponent|any;
  role: any;
  authorizationDate: any;
  impDate: any;
  expDate: any;

  aaDetailsForm1 = this.formBuilder.group({
    officeName: [""],
    name: [""],
    address: [""],
    iecNo: [""],
    expType: [""],
    expStatus: [""],
    actualUser: [""],
    utilization: [""],
    fileNo: [""],
    normType: [""],
    expForeignCurrency: [""],
    expRate: [""],
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean|any;
  date: string|any;
  modifiedCurrentDate: string|any;
  lastDate: any;
  isaaForm2Enable: boolean = false;
  showMessage: any;
  isShowMessage: boolean = false;
  applicationType: string |any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    let localstorageCurrentUser : any = localStorage.getItem("currentUser")
    this.currentUser = JSON.parse(localstorageCurrentUser);
    this.applicationType = localStorage.getItem('applicationType')
    // localStorage.setItem("item_id", "0");
    this.getItemExportedById();
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date: string | number | Date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  next() {
    this.router.navigate(["/importAA"]);
  }

  back() {
    this.router.navigate(["/aaDetails"]);
  }

  getItemExportedById() {
    let application_id = localStorage.getItem("application_id");

    let input = {
      application_id: application_id,
    };
    this.blockchainService.getItemExportedById(input).subscribe((resp) => {
      this.expItemResp = resp.body.obj;
      if (this.expItemResp !== null) {
        this.isShowMessage = false;
        var expItemList = resp.body.obj;
        this.expItemResp = new PaginatedDataSource<ExportAA, ExportAAQuery>(
          (request, query) =>
            this.blockchainService.pageExportAA(request, query, expItemList),
          this.initialSort,
          { search: "", data: [] },
          10
        );
      } else{
        this.isShowMessage = true;
        this.showMessage = resp.body.message;
      }
    });
  }

  addExportAA(data: { update: boolean; }) {
    data.update = true;
    this.router.navigate(["/addExportAA", data]);
  }

  showNotification(from: any, align: any, color: any | number, stringMessage: any, icons: any) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
