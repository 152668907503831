import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
  selector: "app-remarks-license-ta",
  templateUrl: "./remarks-license-ta.component.html",
  styleUrls: ["./remarks-license-ta.component.css"],
})
export class RemarksLicenseTaComponent implements OnInit {
  autoFillForm = this.formBuilder.group({
    name: [""],
    designation: [""],
  });
  caseDetailsFillForm = this.formBuilder.group({
    impexpname: [""],
    iecCode: [""],
    applicationDate: [""],
    fileNo: [""],
  });

  alertStatusForm = this.formBuilder.group({
    alertStatus: ["", Validators.required],
  });

  currentUser: any;
  caseDetailsData: any;
  alertStatus:any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));

    this.caseDetailsFillForm.setValue({
      impexpname: this.caseDetailsData.impexpName,
      iecCode: this.caseDetailsData.iecCode,
      applicationDate: this.caseDetailsData.receivedDate,
      fileNo: this.caseDetailsData.fileNo,
    });

    this.autoFillForm.setValue({
      name: this.currentUser.obj.first_name + this.currentUser.obj.last_name,
      designation: this.currentUser.obj.designation,
    });
  }

  backReceipt() {
    this.router.navigate(["/registerTask"]);
  }

  navigateProvideBond() {
    this.router.navigate(["/provideBond"]);
  }
  navigateOnValidation() {
    this.router.navigate(["/validation"]);
  }
  back() {
    this.router.navigate(["/registerLicenseTa"]);
  }
}
