import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
// import { decimalDigest } from "@angular/compiler/src/i18n/digest";
// import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
  FormArray,
  FormGroup,
} from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { CustomScreenComponent } from "app/chp-dashboard/custom-screen/custom-screen.component";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { AmountToWordPipe } from "../../amount-to-word.pipe";
import { PaymentsumComponent } from "../paymentsum/paymentsum.component";

declare var $: any;
export interface Status {
  value: string;
  viewValue: string;
}
export interface TableData {
  from: Date;
  to: Date;
}

export interface DDElement {
  no: number;
  demand_draft_no: string;
  date: string;
  bank_name: string;
  branch_name: string;
  amount: string;
  action: any;
  remarks: any;
  payment_id: string;
}

const DD_DATA: DDElement[] = [
  {
    no: 1,
    demand_draft_no: "",
    date: "",
    bank_name: "",
    branch_name: "",
    amount: "",
    action: "",
    remarks: "",
    payment_id: localStorage.getItem("payment_id"),
  },
];

export interface CashElement {
  no: number;
  cash_in_figures: string;
  cash_in_words: string;
  remarks: string;
  payment_id: string;
}

export interface SBFacilityElement {
  no: number;
  bank_name:string;
  amount_in_figures: string;
  amount_in_words: string;
  remarks: string;
  action:any;
  payment_id: string;
}

const Cash_DATA: CashElement[] = [
  {
    no: 1,
    cash_in_figures: "",
    cash_in_words: "",
    remarks: "",
    payment_id: localStorage.getItem("payment_id"),
  },
];

const SB_FACILITY_DATA: SBFacilityElement[] = [
  {
    no: 1,
    bank_name:"",
    amount_in_figures: "",
    amount_in_words: "",
    remarks: "",
    action:"",
    payment_id: localStorage.getItem("payment_id"),
  },
];

export interface IPOElement {
  no: number;
  ipo_no: string;
  date: string;
  post_office_name: string;
  payment_in_figures: string;
  action: any;
  remarks: any;
  payment_id: string;
}


const IPO_DATA: IPOElement[] = [
  {
    no: 1,
    ipo_no: "",
    date: "",
    post_office_name: "",
    payment_in_figures: "",
    action: "",
    remarks: "",
    payment_id: localStorage.getItem("payment_id"),
  },
];

@Component({
  selector: "app-paymentmode",
  templateUrl: "./paymentmode.component.html",
  styleUrls: ["./paymentmode.component.css"],
  providers: [AmountToWordPipe],
})
export class PaymentmodeComponent implements OnInit {
  payment_id: string;
  loader: boolean;
  displayedColumns = [
    "no",
    "demand_draft_no",
    "date",
    "bank_name",
    "branch_name",
    "amount",
    "remarks",
    "action",
  ];
  displayedColumns0 = ["no", "cash_in_figures", "cash_in_words", "remarks"];
  displayedColumns1 = [
    "no",
    "ipo_no",
    "date",
    "post_office_name",
    "payment_in_figures",
    "remarks",
    "action",
  ];
  displayedColumns2 = [
    "no",
    "bank_name", 
    "amount_in_inr",
    "amount_in_words",
    "remarks",
    "action",
  ];

  dataSource0 = Cash_DATA;
  // dataSource2 = SB_FACILITY_DATA;

  dataSource = new BehaviorSubject<AbstractControl[]>([]);
  dataSource1 = new BehaviorSubject<AbstractControl[]>([]);
  dataSource2 = new BehaviorSubject<AbstractControl[]>([]);

  pipe = new DatePipe("en-US");

  selectedStatus = "";
  today: number = Date.now();
  now = Date.now();
  todayDate = this.pipe.transform(this.now, "yyyy-MM-dd", "en-US");

  allBanks: any;

  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  allDocType: any;
  active: boolean = false;

  data: DDElement[] = [
    {
      no: 1,
      demand_draft_no: "",
      date: "",
      bank_name: "",
      branch_name: "",
      amount: "",
      action: "",
      remarks: "",
      payment_id: localStorage.getItem("payment_id"),
    },
  ];

  // data0: CashElement[] = [
  //   {
  //     no: 1,
  //     cash_in_figures: "",
  //     cash_in_words: "",
  //     payment_id:"1"
  //   },
  // ];

  data1: IPOElement[] = [
    {
      no: 1,
      ipo_no: "",
      date: "",
      post_office_name: "",
      payment_in_figures: "",
      remarks: "",
      payment_id: localStorage.getItem("payment_id"),
      action: "",
    },
  ];
  data2: SBFacilityElement[] = [
    {
      no: 1,
      // date: "",
      bank_name: "",
      amount_in_figures: "",
      amount_in_words: "",
      remarks: "",
      payment_id: localStorage.getItem("payment_id"),
      action: "",
    },
  ];
  // rows: FormArray = this.formBuilder.array([]);
  // form: FormGroup = this.formBuilder.group({ 'dates': this.rows });

  dd_master: UntypedFormArray = this.formBuilder.array([]);
  ddForm: UntypedFormGroup = this.formBuilder.group({ dd_master: this.dd_master });

  // cash_master: FormArray = this.formBuilder.array([]);
  // cashForm: FormGroup = this.formBuilder.group({
  //   cash_master: this.cash_master,
  // });

  ipo_master: UntypedFormArray = this.formBuilder.array([
  ]);

  ipoForm: UntypedFormGroup = this.formBuilder.group({ 
    ipo_master: this.ipo_master 
  });

  sb_master: UntypedFormArray = this.formBuilder.array([
  ]);

  sbCollectFacilityForm: UntypedFormGroup = this.formBuilder.group({ 
    sb_master: this.sb_master 
  });
  // ddForm = this.formBuilder.group({
  //   demand_draft_no:[""],
  //   date:[""],
  //   bank_name:[""],
  //   amount:[""]
  //  })
  bankName:any;
  cashForm = this.formBuilder.group({
    cash_in_figures: ["", [Validators.pattern(/^-?(0|[1-9]\d*){1,2}?$/)]],
    cash_in_words: [""],
    remarks: [""],
  });
  // sbCollectFacilityForm = this.formBuilder.group({
  //   bank_name:[""],
  //   amount_in_figures: ["", [Validators.pattern(/^-?(0|[1-9]\d*){1,2}?$/)]],
  //   amount_in_words: [""],
  //   remarks: [""],
  // });
  removeRowNo: any;
  removeRowNo1: any;
  removeRowNo2: any;
  paymentAmt: any = 0;
  typeSelected: string;
  isLoading: boolean;
  
  public sucessMessage;
  ga: number;
  grandTotal: string;
  currentUser: any;
  amount:any;
  amount1: any;
  amountInWordsControl: AbstractControl<any, any>;
  
  // ipoForm = this.formBuilder.group({
  //   ipo_no: [""],
  //   date: [""],
  //   post_office_name: [""],
  //   amount: [""],
  // });
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private amountToWordPipe: AmountToWordPipe
  ) {
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.getBankNames();
    this.getDocumentType();
    this.payment_id = localStorage.getItem("payment_id");
    // this.data.forEach((d: TableData) => this.addRow(d, false));
    this.data.forEach((d: DDElement) => this.addRowDD(d, false));
    this.updateView();
    // this.data0.forEach((d: CashElement) => this.addRowCash(d, false));
    // this.updateViewCash();

    this.data1.forEach((d: IPOElement) => this.addRowIpo(d, false));
    console.log(this.data2);
    this.data2.forEach((d: SBFacilityElement) => this.addRowSb(d, false));
    this.updateViewIpo();
    this.updateViewSB();

    this.grandCalculate();
  }

  get f() {
    return this.cashForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.cashForm.controls[controlName].hasError(errorName);
  };

  // get f2() {
  //   return this.sbCollectFacilityForm.controls;
  // }
  // public hasError2 = (controlName: string, errorName: string) => {
  //   return this.sbCollectFacilityForm.controls[controlName].hasError(errorName);
  // };

  allowOnlyNumbersAndDecimal(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    // Allow: backspace, tab, enter, escape, and delete
    const allowedKeys = ['Backspace', 'Tab', 'Enter', 'Escape', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
    
    if (allowedKeys.indexOf(event.key) !== -1) {
      return; // Allow the key
    }

    // Check if the key is a number or a decimal point
    const currentValue = input.value;
    const newValue = currentValue + event.key;

    // Check for valid input (only numbers and one decimal point)
    if (!/^\d*\.?\d*$/.test(newValue)) {
      event.preventDefault(); // Prevent the input if it's not valid
    }
  }
  
  get f2() {
    return this.sbCollectFacilityForm.value.sb_master.controls;
  }
  public hasError2 = (controlName: string, errorName: string) => {
    return this.sbCollectFacilityForm.value.sb_master.controls[controlName].hasError(errorName);
  };

  get f1() {
    return this.ipo_master.controls;
  }
  public hasError1 = (controlName: string, errorName: string) => {
    return this.ipo_master.controls[controlName].hasError(errorName);
  };

  convertToWord() {
    this.cashForm.value.cash_in_words = this.amountToWordPipe.transform(
      this.cashForm.value.cash_in_figures
    );
    console.log(this.cashForm.value.cash_in_words, "words");
  }
  // convertToWordForSB() {
  //   console.log("sbCollectFacilityForm",this.sbCollectFacilityForm);
  //   console.log(this.sb_master);
  //   this.sbCollectFacilityForm.value.amount_in_words = this.amountToWordPipe.transform(
  //     this.sbCollectFacilityForm.value.amount_in_inr
  //   );
  //   console.log(this.sbCollectFacilityForm.value.amount_in_words, "words");
  // }

  convertToWordForSB(index: number) {
    const sbMasterArray = this.sbCollectFacilityForm.get('sb_master') as FormArray;
  
    // Check if the index is valid
    if (index < 0 || index >= sbMasterArray.length) {
      console.error('Invalid index:', index);
      return; // Exit if the index is invalid
    }
  
    const control = sbMasterArray.at(index) as FormGroup;
  
    // Access the controls
    const amountControl = control.get('amount_in_figures');
    const amountInWordsControl = control.get('amount_in_words');
  
    if (amountControl && amountInWordsControl) {
      const amountValue = amountControl.value;
  
      if (amountValue) {
        const words = this.amountToWordPipe.transform(amountValue);
        amountInWordsControl.setValue(words);
      } else {
        console.warn('amount_in_figures is empty for index:', index);
      }
    } else {
      console.error('Controls not found for index:', index);
    }
  
    console.log(this.sbCollectFacilityForm.value);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  addRowDD(d?: DDElement, noUpdate?: boolean) {
    const newRow = this.formBuilder.group({
      no: Date.now(),
      demand_draft_no: [d && d.demand_draft_no ? d.demand_draft_no : ""],
      date: [d && d.date ? d.date : ''],
      bank_name: [d && d.bank_name ? d.bank_name : ""],
      branch_name: [d && d.branch_name ? d.branch_name : ""],
      amount: [d && d.amount ? d.amount : ""],
      remarks: [""],
      action: [""],
    });
    console.log(newRow, "dd");
    this.dd_master.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateView();
    }
  }

  updateView() {
    this.dataSource.next(this.dd_master.controls);
  }

  addRowIpo(d?: IPOElement, noUpdate?: boolean) {
    const newRow = this.formBuilder.group({
      no: Date.now(),
      ipo_no: [d && d.ipo_no ? d.ipo_no : ""],
      date: [d && d.date ? d.date : ""],
      post_office_name: [d && d.post_office_name ? d.post_office_name : ""],
      payment_in_figures: [
        d && d.payment_in_figures ? d.payment_in_figures : "",
      ],
      remarks: [""],
      action: [null],
    });
    console.log(newRow, "ipo");
    this.ipo_master.push(newRow);
    // this.dataSource = [this.ddForm,newRow];
    if (!noUpdate) {
      this.updateViewIpo();
    }
  }
  addRowSb(d?: SBFacilityElement, noUpdate?: boolean) {
    console.log(d);
    const newRow = this.formBuilder.group({
      no: Date.now(),
      // date: [d && d.date ? d.date : ""],
      bank_name: [d && d.bank_name ? d.bank_name : ""],
      amount_in_figures: [
        d && d.amount_in_figures ? d.amount_in_figures : "",
      ],
      amount_in_words: [
        d && d.amount_in_words ? d.amount_in_words : "",
      ],
      payment_id:this.payment_id,
      remarks: [""],
      action: [null],
    });
    console.log(newRow, "sb");
    this.sb_master.push(newRow);
    if (!noUpdate) {
      this.updateViewSB();
    }
  }

  updateViewIpo() {
    this.dataSource1.next(this.ipo_master.controls);
  }
  updateViewSB() {
    this.dataSource2.next(this.sb_master.controls);
  }

  activeFn() {
    this.active = true;
  }
  getDocumentType() {
    this.blockchainService.getDocumentType().subscribe((resp) => {
      this.allDocType = resp.obj;
    });
  }
  getBankNames() {
    this.blockchainService.getBankNames().subscribe((resp) => {
      this.allBanks = resp.obj;
    });
  }

  openModal(no) {
    this.removeRowNo = no;
    const buttonModal = document.getElementById("openModalButton");
    buttonModal.click();
  }

  openModal1(no) {
    this.removeRowNo1 = no;
    const buttonModal = document.getElementById("openModalButton1");
    buttonModal.click();
  }

  openModal2(no) {
    this.removeRowNo2 = no;
    const buttonModal = document.getElementById("openModalButton2");
    buttonModal.click();
  }

  removeRow() {
    let no = this.removeRowNo;
    const roomArr: any[] = this.dataSource.getValue();
    roomArr.forEach((item, index) => {
      if(index == 0){
        this.openDialog(
          "paymentSum",
          "Cannot delete the first row, Kindly update the first row if required",
          ""
        );
        // console.log(item,'item')
        // item.value.demand_draft_no = '';
        // item.value.date = '';
        // item.value.bank_name= '';
        // item.value.branch_name = '';
        // item.value.amount = '';
        // this.dataSource.next(item.value)
        // console.log(item.value)
      }
      else{
        if (item === no) {
          roomArr.splice(index, 1);
          this.ddForm.value.dd_master.splice(index, 1);
        }
      }
     
    });
    this.dataSource.next(roomArr);
  }
  removeRow1() {
    let no = this.removeRowNo1;
    const roomArr: any[] = this.dataSource1.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if(index == 0){
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );
        }
          else{
            roomArr.splice(index, 1);
            this.ipoForm.value.ipo_master.splice(index, 1);
          }
        
      }
    });
    this.dataSource1.next(roomArr);
  }
  removeRow2() {
    let no = this.removeRowNo2;
    const roomArr: any[] = this.dataSource2.getValue();
    roomArr.forEach((item, index) => {
      if (item === no) {
        if(index == 0){
          this.openDialog(
            "paymentSum",
            "Cannot delete the first row, Kindly update the first row if required",
            ""
          );
        }
          else{
            roomArr.splice(index, 1);
            this.sbCollectFacilityForm.value.sb_master.splice(index, 1);
          }
        
      }
    });
    this.dataSource2.next(roomArr);
  }
  preview(){
    this.router.navigate(["/paymentPreview"]);
  }
  grandCalculate(){
    let input = {
      payment_id:this.payment_id
    }
    this.blockchainService.getGrandPaymentTotalByPaymentId(input).subscribe((resp) => {
     console.log(resp)
      if(resp.obj==''){
        this.grandTotal = '0'
      }else{
        this.grandTotal = resp.obj;
      }
    });
  }

  paymentAmountSum() {
    this.blockUI.start("Please wait a moment");

    setTimeout(() => {
      this.blockUI.stop();
    }, 1000);
    this.grandCalculate();
    let newList = [];

    this.ddForm.value.dd_master.forEach((element) => {
      newList.push({
        demand_draft_no: element.demand_draft_no,
        date: element.date,
        bank_name: element.bank_name,
        branch_name: element.branch_name,
        amount_in_inr: element.amount ? element.amount : "0",
        payment_id: this.payment_id,
      });
    });
    this.ipoForm.value.ipo_master.forEach((element) => {
      newList.push({
        ipo_no: element.ipo_no,
        date: element.date,
        post_office_name: element.post_office_name,
        // section:this.selectedSectionMisc,
        amount_in_inr: element.payment_in_figures
          ? element.payment_in_figures
          : "0",
        payment_id: this.payment_id,
      });
    });

    let cashFormArr = [];
    console.log(this.cashForm.value);
    cashFormArr.push(this.cashForm.value);
    cashFormArr.forEach((element) => {
      newList.push({
        amount_in_inr: element.cash_in_figures ? element.cash_in_figures : "0",
        cash_in_words: element.cash_in_words,
        payment_id: this.payment_id,
      });
    });

    console.log(this.sbCollectFacilityForm);
    // let sbFacilityFormArr = [];
    // sbFacilityFormArr.push(this.sbCollectFacilityForm.value);
    // sbFacilityFormArr.forEach((element) => {
    //   console.log("element",element);
    //   newList.push({
    //     bank_name:element.bank_name,
    //     amount_in_inr: element.amount_in_figures ? element.amount_in_figures : "0",
    //     amount_in_words: element.amount_in_words,
    //     remarks:element.remarks,
    //     payment_id: this.payment_id,
    //   });
    // });
    this.sbCollectFacilityForm.value.sb_master.forEach((element) => {
      newList.push({
        bank_name:element.bank_name,
        amount_in_figures: element.amount_in_figures ? element.amount_in_figures : "0",
        amount_in_words: element.amount_in_words,
        remarks:element.remarks?element.remarks:"",
        payment_id: this.payment_id,
      });
    });
    console.log(newList, "newlist");
    console.log( this.paymentAmt);
    this.paymentAmt = 0;
    newList.forEach((element) => {
      console.log(element.amount_in_inr);
      // this.paymentAmt = this.paymentAmt + parseInt(element.amount_in_inr);
      this.paymentAmt = this.paymentAmt + parseFloat(element.amount_in_inr?element.amount_in_inr.replace(/,/g, ''):element.amount_in_figures.replace(/,/g, ''));
    });
 

    //  let payableAmount = localStorage.getItem('payableAmount');
    //  if(this.paymentAmt < payableAmount){
    //   this.openDialog(
    //     "paymentSum",
    //     "",
    //     ""
    //        );
    //  }
    console.log(this.paymentAmt, "paymentAmt");
  }

  save() {
    this.paymentAmountSum();
    this.grandCalculate();
    // this.cashForm.value.cash_in_words=this.cash_in_words;
    // console.log(this.cashForm.value);
    let payableAmount = localStorage.getItem("payableAmount");
    if(this.paymentAmt != null && this.paymentAmt != undefined
      && this.grandTotal != null && this.grandTotal != undefined){
        if (this.paymentAmt < this.grandTotal) {
          this.openDialog(
            "paymentSum",
            "Your payment amount is less than due. Please check and resubmit",
            ""
          );
        } else {
          console.log(this.paymentAmt)
          this.loader = true;
          this.ga = this.paymentAmt - parseInt(payableAmount)
          console.log(this.ga,'ga');
          this.convertToWord();
          this.saveDemandDraft();
        }
      } else{

      }
 
  }

  saveDemandDraft() {

    let ddFormValid = true ;
    this.ddForm.value.dd_master.forEach((element) => {
      element.payment_id = this.payment_id;
      // element.amount = JSON.stringify(element.amount)
     
      if((typeof(element.amount)!== 'number')){
        element.amount = element.amount;
       
      }else{
        element.amount = JSON.stringify(element.amount)
      }

      if (element.date == null || element.date == undefined) {
        element.date = "";
      }
      if (element.demand_draft_no == null || element.demand_draft_no == undefined) {
        element.demand_draft_no = "";
      } 
      if (element.amount == null || element.amount == undefined) {
        element.amount = "";
      }

      if(element.amount !== null && element.amount !== "" && element.amount !== undefined){
        if(element.demand_draft_no == "" || element.date == "" || element.bank_name == "" ||
        element.branch_name == ""){
          ddFormValid = false
          this.openDialog(
            "paymentSum",
            "Please fill the details of the amount entered",
            ""
          );
        } 
      }else {
        ddFormValid = true
      }
      
    });
    if(ddFormValid){
      this.blockUI.start("Please wait this may take few seconds ...");

      console.log(this.ddForm.value);
      this.blockchainService
        .saveDemandDraft(this.ddForm.value)
        .subscribe((resp) => {
          if (resp.body.status == 200) {
            setTimeout(() => {
              this.blockUI.stop();
            }, 1000);
            this.saveCash();
          } else {
            this.loader = false;
            setTimeout(() => {
              this.blockUI.stop();
            }, 1000);
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.body.message,
              "announcement"
            );
          }
        });
    }else{
      this.loader = false
      // this.openDialog(
      //   "paymentSum",
      //   "Please Enter Valid Demand Draft Details",
      //   ""
      // );
    }
   
  }

 
  saveCash() {
  
    this.cashForm.value.payment_id = this.payment_id;
    if(this.cashForm.value.cash_in_figures == undefined){
      this.cashForm.value.cash_in_figures=""
    }
    if(this.cashForm.value.cash_in_words == undefined){
      this.cashForm.value.cash_in_words=""
    }
    let cash_master = [];
    cash_master.push(this.cashForm.value);
    let input = {
      cash_master: cash_master,
    };
    console.log(input, "cashform");
    this.blockUI.start("Please wait this may take few seconds ...");

    this.blockchainService.saveCash(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.saveIpo();
      } else {
        this.loader = false;
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  saveSbCollectFacility() {
  console.log(this.sbCollectFacilityForm);
    this.sbCollectFacilityForm.value.payment_id = this.payment_id;
    if(this.sbCollectFacilityForm.value.amount_in_figures == undefined){
      this.sbCollectFacilityForm.value.amount_in_figures=""
    }
    if(this.sbCollectFacilityForm.value.amount_in_words == undefined){
      this.sbCollectFacilityForm.value.amount_in_words=""
    }
    if(this.sbCollectFacilityForm.value.bank_name == undefined){
      this.sbCollectFacilityForm.value.bank_name=""
    }
    if(this.sbCollectFacilityForm.value.remarks == undefined){
      this.sbCollectFacilityForm.value.remarks=""
    }
    console.log(this.sbCollectFacilityForm.value);
    let sb_master = [];
    sb_master=this.sbCollectFacilityForm.value.sb_master;
    console.log(this.sbCollectFacilityForm.value);
    console.log(sb_master);

    let input = {
      sb_master
    };
    console.log(input, "sbfacilityform");
    this.blockUI.start("Please wait this may take few seconds ...");

    this.blockchainService.saveSbCollectFacility(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
         this.updatePayments();
      } else {
        this.loader = false;
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  saveIpo() {
    let ipoValid = true;
    this.ipoForm.value.ipo_master.forEach((element) => {
      element.payment_id = this.payment_id;
      element.amount = JSON.stringify(element.amount)

      if (element.date == null || element.date == undefined) {
        element.date = "";
      }
      if (element.ipo_no == null || element.ipo_no == undefined) {
        element.ipo_no = "";
      }
      if (element.post_office_name == null || element.post_office_name == undefined) {
        element.post_office_name = "";
      }
      if(element.amount !== null && element.amount !== "" && element.amount !== undefined){
        if(element.ipo_no == "" || element.date == "" || element.post_office_name == "" ){
          ipoValid = false;
          this.openDialog(
            "paymentSum",
            "Please fill the details of the amount entered",
            ""
          );
        } else{
          ipoValid = true;
        }
      }
    });
    console.log(this.ipoForm.value);
    if(ipoValid){
      this.blockUI.start("Please wait this may take few seconds ...");

      this.blockchainService.saveIpo(this.ipoForm.value).subscribe((resp) => {
        if (resp.body.status == 200) {
          this.saveSbCollectFacility();
          // this.updatePayments();
          setTimeout(() => {
            this.blockUI.stop();
          }, 1000);
        } else {
          this.loader = false;
          setTimeout(() => {
            this.blockUI.stop();
          }, 1000);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      });
    }
  
  }


  updatePayments() {
    let isGuestUser = localStorage.getItem('paymentGuest')
    let input = {
      payment_master_id: this.payment_id,
      ga : this.ga
    };
    this.blockUI.start("Please wait this may take few seconds ...");

    this.blockchainService.updatePayments(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.loader = false;
      if(isGuestUser == 'true'){
        this.openDialog("paymentSum", "sucessChallan", "");

      }
      else {
      
      this.openDialog("paymentSum", "EChallan Genereated Successfully", "");

      }
      setTimeout(() => {
        this.blockUI.stop();
      }, 1000);
        setTimeout(() => {
          let url = resp.body.obj;
          // url = url+'/'+ this.currentUser.obj.access_token
          url = url
          window.open(url, "_blank");

          this.router.navigate(["/welcomeUser"]);
        }, 1000);


        // this.openDialog(
        //   "paymentSum",
        //   "",
        //   ""
        //      );
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      } else {
        this.loader = false;
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "paymentSum" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  openMessageDialog(action: string, title: string, message: string) {
    const dialogConfig = new MatDialogConfig();
    let obj: any = {};
    obj.action = action;
    obj.title = title;
    obj.message = message;
    dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    let dialogRef;

    if (action == "message_dialog") {
      dialogRef = this.dialog.open(CustomScreenComponent, dialogConfig);
    }
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "message_dialog" && result.isUpdated === true) {
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
