import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockchainService } from 'app/blockchainImpExp/blockchain.service';
import { AlertService } from "app/_services";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { is } from "date-fns/locale";
import { BlockUI, NgBlockUI } from "ng-block-ui";
declare var $: any;

export interface GreenNote {
 
   noteDate: string;
   name:string;
   designation:string;
   remarks:string;
 }
// const ELEMENT_DATA2: Registration2[] = [
//   {
//     date: '07.07.22',
//     name:'Test',
//     designation:'Inspector',
//     remarks:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters'
//   },  
//   {
//     date: '08.07.22',
//     name:'Test2',
//     designation:'Sub Inspector',
//     remarks:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters'
//   },
  
// ];
@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.css']
})
export class ValidateComponent implements OnInit {
  greenNote:GreenNote[] = [];
  currentUser: any;
  caseDetailsData: any;
  process: any;
  @BlockUI() blockUI: NgBlockUI;
  // @ViewChild("draftText") draftText: ElementRef;
  blockTemplate: BlockTemplateComponent;
  constructor( 
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,private alertService: AlertService) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));
    this.process = JSON.parse(localStorage.getItem("process"));
    if(this.process=='registration'){
      this.getGreenSheetByApplicationId(this.caseDetailsData.application_id);
    }else{
      this.getGreenSheetByCancellationId(this.caseDetailsData.cancellation_id);
      
    }
   
  }
  getGreenSheetByCancellationId(input){
    let application = {
      cancellation_id: input
   }
   this.blockchainService.getGreenSheetByCancellationId(application).subscribe(
     (resp) => {
      //  console.log(resp.body.obj);
        this.greenNote = resp.body.obj;
      console.log("GreenShet",this.greenNote);
        // console.log(this.greenNote)
     }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        location.reload();
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
     
    });
  }

  
  getGreenSheetByApplicationId(input){
    let application = {
      application_id: input
   }
   this.blockUI.start();
   this.blockchainService.getGreenSheetByApplicationId(application).subscribe(
     (resp) => {
      //  console.log(resp.body.obj);
      setTimeout(() => {
        this.blockUI.stop();
      }, 500);
        this.greenNote = resp.body.obj;
     }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        location.reload();
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
     
    });
  }


  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
