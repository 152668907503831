import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { UntypedFormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService, SummaryReceivedQuery } from "app/blockchainImpExp/blockchain.service";
import { Month } from "../payment-daily-summary/month";

var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface SummaryReceived {
  no:string;
  challanNo:string;
  challanReceipt:string;
 licenseType:string;
  licenseNo:string;
  commissionerate:string;
  dd:string;
  cash:string;
  ipo:string;
}

export interface GroupBy {
  totalPayable: string;
  isGroupBy: boolean;
  totalAmt:string;
}

export interface MonthType {
  value: string;
  viewValue: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



export interface calendarView {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-payment-received-summary',
  templateUrl: './payment-received-summary.component.html',
  styleUrls: ['./payment-received-summary.component.css']
})
export class PaymentReceivedSummaryComponent implements OnInit {
  selectedStatus = 'New';
  pipe = new DatePipe("en-US");
  allStatus: Status[] = [
    {value: 'Incoming Date', viewValue: 'Incoming Date'},
    {value: 'IEC Code', viewValue: 'IEC Code'},
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  currentUser: any;
  role: any;
  dataSource:SummaryReceived[];

  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  displayedColumns = [
    'no',
    'challanNo',
    'depositor_name',
    'challanReceipt',
    'licenseType',
    'licenseNo',
    'commissionerate',
    'dd',
    'cash',
    'ipo',
    'sb'
  ];
  
  allPaymentSummary: any;
  

  selectedView:any;

  allCalendarView: calendarView[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Weekly", viewValue: "Weekly" },
    { value: "Monthly", viewValue: "Monthly" },
  ];
  date: any;
  modifiedCurrentDate: any;
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  isButtonVisible = false;
  first_day_of_month: any;
  last_day_of_month: any;
  currentDate = new Date();
  firstDay: Date;
  lastDay: Date;

  events: string[] = [];
  events1: string[] = [];
  datepick = true;
  monthselectedValue: any;
  public selectedDate =  new  UntypedFormControl(new  Date());
  public months : Month[];
  public selectedMonth :Month;
  isMonthly: boolean;
  isDay: boolean;
  isWeekly: boolean;
  showTable: boolean;
  summaryData: PaginatedDataSource<SummaryReceived, SummaryReceivedQuery>;
  initialSort: Sort<SummaryReceived> = { property: "challanNo", order: "asc" };
  constructor( private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = this.currentUser.obj.user_type_id;
    this.loadMonths();
  }

  getPaymentDailySummaryMis(input){
    this.blockchainService.getPaymentRecivedSummary(input).subscribe(resp => {
      this.allPaymentSummary = resp.body.obj;
      console.log(this.allPaymentSummary);
      this.summaryData = this.allPaymentSummary;
      if (this.summaryData !== null) {
        var summaryList =  resp.body.obj;
        this.summaryData = new PaginatedDataSource<SummaryReceived, SummaryReceivedQuery>(
          (request, query) =>
            this.blockchainService.pageSummaryReceived(request, query, summaryList),
          this.initialSort,
          { search: "", data: undefined },
          10
        );
      }
    })
  }
  
  caseDetailsDialogue(data){
    localStorage.setItem('casedetailsData',JSON.stringify(data));
  }
  
  isGroup(index, item): boolean{
    return item.isGroupBy;
  }
  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    let input = {
      start_date : this.date ? this.date : this.todayDate,
      end_date : this.date ? this.date : this.todayDate
    }
    this.showTable = true;
    this.getPaymentDailySummaryMis(input);
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  loadMonths() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months=[];
    for(let i=0; i<12; i++) {

      console.log("MOnth : ",month);
      if(month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year,month,1);
      let lastday = new Date(year,month+1,0);
      let name=this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = {"name" : name, "value": month + 1, "start": start + " 00:00:00", "end" : end+" 23:59:59"};
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
    
  }

  monthly(value,event){
    if(event.isUserInput){
      this.showTable = true;
      this.selectedMonth = value ? value : this.selectedMonth;
      let input = {
        start_date: this.pipe.transform(this.selectedMonth.start, "yyyy-MM-dd", "en-US"),
        end_date: this.pipe.transform(this.selectedMonth.end, "yyyy-MM-dd", "en-US"),
      }
      this.getPaymentDailySummaryMis(input);

    }
  }

  selectCalendarView(event, value) {
    if (event.isUserInput) {
      if (value == "Monthly") {
        this.isMonthly = true;
        this.isDay = false;
        this.isWeekly = false;
        this.showTable = false;
      } else if (value == "Weekly") {
        this.isWeekly = true;
        this.isDay = false;
        this.isMonthly = false;
        this.showTable = false;
      } else {
        this.isDay = true;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  compareMonths(i1,i2) {
    // return i2 && i1 && i1.value === i2.value;
    return i1 && i2 && i1.value === i2.value;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    if (this.isMonthly) {
      if (type === "change") {
        this.showTable = true;

        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        //const start =
        let date = new Date(this.today);
        //date = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        this.first_day_of_month = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        let currentMonth = date.getMonth();
        this.last_day_of_month = new Date(
          date.getFullYear(),
          currentMonth,
          this.daysInMonth(currentMonth + 1, date.getFullYear())
        );
        let input = {
          start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
          end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        }
        this.getPaymentDailySummaryMis(input);

      }
    } else {
      if (type === "change") {
        this.showTable = true;
        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        const start = new Date(this.today + 6 * 24 * 60 * 60 * 1000).getTime();
        const myStartDate = this.pipe.transform(start, "yyyy-MM-dd", "en-US");
        this.last6 = start;
        let input = {
          end_date : this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
          start_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        }
        
        this.getPaymentDailySummaryMis(input);

        if (this.today == this.now) {
          this.isButtonVisible = false;
        } else {
      
          this.isButtonVisible = true;
        }
      }
    }
  }

  reportGeneration() { 
    var displayedCol = []
    displayedCol.push('Challan No');
    displayedCol.push('Depositor Name');
    displayedCol.push('Challan Receipt Date');
    displayedCol.push('License Type');
    displayedCol.push('License No');
    displayedCol.push('Commissionerate');
    displayedCol.push('DD Total');
    displayedCol.push('CASH Total');
    displayedCol.push('IPO Total');
    displayedCol.push('SB Collect Facility Total');

  var tableObj = this.allPaymentSummary;
  console.log(tableObj,'tableobj')
    tableObj.forEach(element => {
      element['Challan No'] = element.challan_no;
      element['Depositor Name'] = element.depositor_name;
      element['Challan Receipt Date'] = element.challan_receipt_date;
      element['License Type'] = element.license_type;
      element['License No'] = element.license_no;
      element['Commissionerate'] = element.commissionerate;
      element['DD Total'] = element.dd?element.dd:'';
      element['CASH Total'] = element.cash?element.cash:'';
      element['IPO Total'] = element.ipo?element.ipo:'';
      element['SB Collect Facility Total'] = element.sb_collect_facility?element.sb_collect_facility:'';


    });
    this.downloadFile(
      tableObj,
      'Payment Received Summary Report' + ' ' + this.todayDate,
      displayedCol
    );
}

downloadFile(data, filename = "data", headers) {
  let csvData = this.ConvertToCSV(data, headers);
  console.log(csvData);
  let blob = new Blob(["\ufeff" + csvData], {
    type: "text/csv;charset=utf-8;",
  });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1;
  if (isSafariBrowser) {
    //if Safari open in new window to save file with random filename.
    dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray, headerList) {
  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  let row = "Sr.No,";

  for (let index in headerList) {
    row += headerList[index] + ",";
  }
  row = row.slice(0, -1);
  str += row + "\r\n";
  for (let i = 0; i < array.length; i++) {
    let line = i + 1 + "";
    for (let index in headerList) {
      let head = headerList[index];

      line += "," + array[i][head];
    }
    str += line + "\r\n";
  }
  return str;
}

}
