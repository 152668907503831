import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
// import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "app/_services";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

export interface roleType {
  id: string;
  name: string;
}

@Component({
  selector: "app-customes-intimation",
  templateUrl: "./customes-intimation.component.html",
  styleUrls: ["./customes-intimation.component.css"],
})
export class CustomesIntimationComponent implements OnInit {
  pipe = new DatePipe("en-US");
  sectionViewLetterDetails:any;
  selectedStatus = "";
  color = "primary";
  backgroundColor = "#64c153";
  allRoleType: roleType[] = [
    { id: "IEC", name: "IEC" },
    { id: "Customs Broker", name: "Customs Broker" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  selectedDate: any;
  liscenseselectedDate: any;
  applicationDate: any;
  docDate: any;
  redemptionDate: any;
  rejectionDate: any;
  otherDate: any;

  customsIntimationForm = this.formBuilder.group({
    license_type: ["",Validators.required],
    license_no: ["",Validators.required],
    liscenseselectedDate: ["",Validators.required],
    installation_certificate_date:["",Validators.required],
    // applicationDate: ["",Validators.required],
    docDate: [""],
    redemptionDate: [""],
    rejectionDate: [""],
    otherDate: [""],
    mid_term_certificate_date:[""]
  });
  customsIntimationMidForm = this.formBuilder.group({
    license_type: ["",Validators.required],
    license_no: ["",Validators.required],
    liscenseselectedDate: ["",Validators.required],
    installation_certificate_date:[""],
    // applicationDate: ["",Validators.required],
    docDate: [""],
    redemptionDate: [""],
    rejectionDate: [""],
    otherDate: [""],
    mid_term_certificate_date:["",Validators.required]
  });
  customsIntimationEODCForm = this.formBuilder.group({
    license_type: ["",Validators.required],
    license_no: ["",Validators.required],
    liscenseselectedDate: ["",Validators.required],
    installation_certificate_date:[""],
    // applicationDate: ["",Validators.required],
    docDate: ["",Validators.required],
    redemptionDate: ["",Validators.required],
    rejectionDate: ["",Validators.required],
    otherDate: ["",Validators.required],
    mid_term_certificate_date:[""]
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  url: string | ArrayBuffer;
  other_url: string | ArrayBuffer;
  rejection_url: string | ArrayBuffer;
  redemption_url: string | ArrayBuffer;
  document_url: string | ArrayBuffer;
  allApplicationTypes: any;
  applicationType: any;
  date: string;
  modifiedCurrentDate: string;
  documentFile: File;
  redemptionFile: File;
  otherFile: File;
  rejectionFile: File;
  liscenseType: string;
  installation_certificate: File;
  isInstallationCertificate: boolean;
  isMidTermReview: boolean;
  isEodcVisible: boolean;
  midterm_certificate_date
  statusClass =  'not-active';
  statusClass1 =  'not-active';
  statusClass2 =  'not-active';
  tabAction: string;
  mid_term_certificate: File;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.liscenseType = localStorage.getItem('liscenseType');
    this.applicationType = this.liscenseType;
    this.tabAction = 'installation_certificate';
    this.isInstallationCertificate = true
      this.isMidTermReview = false
      this.isEodcVisible = false;
      this.statusClass = 'active';
      this.statusClass1 =  'not-active';
      this.statusClass2 =  'not-active';
    this.getApplicationType();
  }
  getAppType(value){
    let app = []
    app =  this.allApplicationTypes.filter(s => s.name != 'AA');
    return app;
  }
  openTab(e,action){
    if(action == 'installation_certificate'){
      this.tabAction = 'installation_certificate'
      this.isInstallationCertificate = true
      this.isMidTermReview = false
      this.isEodcVisible = false;
      this.statusClass = 'active';
      this.statusClass1 =  'not-active';
      this.statusClass2 =  'not-active';
      console.log(this.tabAction);
    } else if(action == 'mid_term_review'){
      this.tabAction = 'mid_term_review'
      this.isMidTermReview = true
      this.isInstallationCertificate = false
      this.isEodcVisible = false;
      this.statusClass1 = 'active';
      this.statusClass =  'not-active';
      this.statusClass2 =  'not-active';
    } else{
      this.tabAction = 'eodc_application'
      this.isEodcVisible = true;
      this.isInstallationCertificate = false
      this.isMidTermReview = false
      this.statusClass2 = 'active';
      this.statusClass =  'not-active';
      this.statusClass1 =  'not-active';
    }
    console.log(this.tabAction);

  }

  get f() {
    return this.customsIntimationForm.controls;
  }
  get f1() {
    return this.customsIntimationMidForm.controls;
  }
  get f2() {
    return this.customsIntimationEODCForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.customsIntimationForm.controls[controlName].hasError(errorName);
  };
  public MidFormhasError = (controlName: string, errorName: string) => {
    return this.customsIntimationMidForm.controls[controlName].hasError(errorName);
  };
  public EODCFormhasError = (controlName: string, errorName: string) => {
    return this.customsIntimationEODCForm.controls[controlName].hasError(errorName);
  };

  getApplicationType() {
    this.blockchainService.getApplicationType().subscribe((resp) => {
      this.allApplicationTypes = resp.obj;
  
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  selectedApplicationType(event, applicationType) {
    if (event.isUserInput) {
      console.log(event);
      this.applicationType = applicationType;
      localStorage.setItem('liscenseType',applicationType);
    }
  }

  checkingDocs(){
    console.log("in1");
    if(this.tabAction === 'installation_certificate'){
      console.log("in");
      if(this.customsIntimationForm.value.installation_certificate_date != null){
        if(this.installation_certificate != undefined){
          let input = {
            created_by: this.currentUser.obj.user_id,
            licence_type: this.customsIntimationForm.value.license_type,
            license_no: this.customsIntimationForm.value.license_no,
            license_date: this.pipe.transform(this.customsIntimationForm.value.liscenseselectedDate, "yyyy-MM-dd") ,
            installation_certificate_date:this.pipe.transform(this.customsIntimationForm.value.installation_certificate_date, "yyyy-MM-dd"),
            installation_certificate : this.installation_certificate,
            eodc_application_date:this.customsIntimationForm.value.applicationDate?this.pipe.transform(this.customsIntimationForm.value.applicationDate, "yyyy-MM-dd"):null ,
            eodc_document_date: this.pipe.transform(this.customsIntimationForm.value.docDate, "yyyy-MM-dd") ,
            eodc_document_path: this.documentFile?this.documentFile:null,
            eodc_redemption_date: this.pipe.transform(this.customsIntimationForm.value.redemptionDate, "yyyy-MM-dd"),
            eodc_redemption_path: this.redemptionFile?this.redemptionFile:null,
            eodc_rejection_date: this.pipe.transform(this.customsIntimationForm.value.rejectionDate, "yyyy-MM-dd"),
            eodc_rejection_path: this.rejectionFile?this.rejectionFile:null,
            others_letters_date:this.pipe.transform(this.customsIntimationForm.value.otherDate, "yyyy-MM-dd") ,
            others_letters_path: this.otherFile?this.otherFile:null,
            mid_term_certificate : null,
            mid_term_certificate_date: null
          };
          this.submit(input)
        } else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            'Please upload Installation Certificate Document',
            "announcement"
          );
        }
      }else{
        this.showNotification(
          "bottom",
          "right",
          "success",
         'Please select installation certificate date',
          "announcement"
        );
      }
     
    }

    if(this.tabAction === 'mid_term_review'){
      if(this.customsIntimationMidForm.value.mid_term_certificate_date != null){
        if(this.mid_term_certificate != undefined){
          let input = {
            created_by: this.currentUser.obj.user_id,
            licence_type: this.customsIntimationMidForm.value.license_type,
            license_no: this.customsIntimationMidForm.value.license_no,
            license_date: this.pipe.transform(this.customsIntimationMidForm.value.liscenseselectedDate, "yyyy-MM-dd") ,
            installation_certificate_date: this.customsIntimationMidForm.value.installation_certificate_date ? this.pipe.transform(this.customsIntimationForm.value.installation_certificate_date, "yyyy-MM-dd") : null,
            installation_certificate : this.installation_certificate ?  this.installation_certificate : null,
            eodc_application_date:this.customsIntimationMidForm.value.applicationDate?this.pipe.transform(this.customsIntimationMidForm.value.applicationDate, "yyyy-MM-dd"):null ,
            eodc_document_date: this.pipe.transform(this.customsIntimationMidForm.value.docDate, "yyyy-MM-dd") ,
            eodc_document_path: this.documentFile?this.documentFile:null,
            eodc_redemption_date: this.pipe.transform(this.customsIntimationMidForm.value.redemptionDate, "yyyy-MM-dd"),
            eodc_redemption_path: this.redemptionFile?this.redemptionFile:null,
            eodc_rejection_date: this.pipe.transform(this.customsIntimationMidForm.value.rejectionDate, "yyyy-MM-dd"),
            eodc_rejection_path: this.rejectionFile?this.rejectionFile:null,
            others_letters_date:this.pipe.transform(this.customsIntimationMidForm.value.otherDate, "yyyy-MM-dd") ,
            others_letters_path: this.otherFile?this.otherFile:null,
            mid_term_certificate : this.mid_term_certificate,
            mid_term_certificate_date:this.pipe.transform(this.customsIntimationMidForm.value.mid_term_certificate_date, "yyyy-MM-dd") 
          };
          this.submit(input)
        } else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            'Please upload Mid Term Certificate Document',
            "announcement"
          );
        }
      }else{
        this.showNotification(
          "bottom",
          "right",
          "success",
         'Please select Mid Term Certificate date',
          "announcement"
        );
      }
    }
    
    if(this.tabAction === 'eodc_application'){
          // let input = {
          //   created_by: this.currentUser.obj.user_id,
          //   licence_type: this.customsIntimationEODCForm.value.license_type,
          //   license_no: this.customsIntimationEODCForm.value.license_no,
          //   license_date: this.pipe.transform(this.customsIntimationEODCForm.value.liscenseselectedDate, "yyyy-MM-dd") ,
          //   installation_certificate_date: this.customsIntimationEODCForm.value.installation_certificate_date ? this.pipe.transform(this.customsIntimationForm.value.installation_certificate_date, "yyyy-MM-dd") : null,
          //   installation_certificate : this.installation_certificate ?  this.installation_certificate : null,
          //   eodc_application_date:this.pipe.transform(this.customsIntimationEODCForm.value.applicationDate, "yyyy-MM-dd") ,
          //   eodc_document_date: this.pipe.transform(this.customsIntimationEODCForm.value.docDate, "yyyy-MM-dd") ,
          //   eodc_document_path: this.documentFile,
          //   eodc_redemption_date: this.pipe.transform(this.customsIntimationEODCForm.value.redemptionDate, "yyyy-MM-dd"),
          //   eodc_redemption_path: this.redemptionFile,
          //   eodc_rejection_date: this.pipe.transform(this.customsIntimationEODCForm.value.rejectionDate, "yyyy-MM-dd"),
          //   eodc_rejection_path: this.rejectionFile,
          //   others_letters_date:this.pipe.transform(this.customsIntimationEODCForm.value.otherDate, "yyyy-MM-dd") ,
          //   others_letters_path: this.otherFile,
          //   mid_term_certificate : this.mid_term_certificate ? this.mid_term_certificate : null,
          //   mid_term_certificate_date:this.customsIntimationEODCForm.value.mid_term_certificate_date ? this.pipe.transform(this.customsIntimationForm.value.mid_term_certificate_date, "yyyy-MM-dd")  : null
          // };
          // this.submit(input)
          if(this.customsIntimationEODCForm.value.docDate != null){
            if(this.documentFile != undefined){
          let input = {
            created_by: this.currentUser.obj.user_id,
            licence_type: this.customsIntimationEODCForm.value.license_type,
            license_no: this.customsIntimationEODCForm.value.license_no,
            license_date: this.pipe.transform(this.customsIntimationEODCForm.value.liscenseselectedDate, "yyyy-MM-dd") ,
            installation_certificate_date: this.customsIntimationEODCForm.value.installation_certificate_date ? this.pipe.transform(this.customsIntimationForm.value.installation_certificate_date, "yyyy-MM-dd") : null,
            installation_certificate : this.installation_certificate ?  this.installation_certificate : null,
            eodc_application_date:this.customsIntimationEODCForm.value.applicationDate?this.pipe.transform(this.customsIntimationEODCForm.value.applicationDate, "yyyy-MM-dd"):null ,
            eodc_document_date: this.pipe.transform(this.customsIntimationEODCForm.value.docDate, "yyyy-MM-dd") ,
            eodc_document_path: this.documentFile,
            eodc_redemption_date: this.pipe.transform(this.customsIntimationEODCForm.value.redemptionDate, "yyyy-MM-dd"),
            eodc_redemption_path: this.redemptionFile,
            eodc_rejection_date: this.pipe.transform(this.customsIntimationEODCForm.value.rejectionDate, "yyyy-MM-dd"),
            eodc_rejection_path: this.rejectionFile,
            others_letters_date:this.pipe.transform(this.customsIntimationEODCForm.value.otherDate, "yyyy-MM-dd") ,
            others_letters_path: this.otherFile,
            mid_term_certificate : this.mid_term_certificate ? this.mid_term_certificate : null,
            mid_term_certificate_date:this.customsIntimationEODCForm.value.mid_term_certificate_date ? this.pipe.transform(this.customsIntimationForm.value.mid_term_certificate_date, "yyyy-MM-dd")  : null
          };
          this.submit(input)
            } else{
              this.showNotification(
                "bottom",
                "right",
                "success",
                'Please upload EODC Application Document',
                "announcement"
              );
            }
          // if(this.redemptionFile != undefined){
          //   let input = {
          //     created_by: this.currentUser.obj.user_id,
          //     licence_type: this.customsIntimationEODCForm.value.license_type,
          //     license_no: this.customsIntimationEODCForm.value.license_no,
          //     license_date: this.pipe.transform(this.customsIntimationEODCForm.value.liscenseselectedDate, "yyyy-MM-dd") ,
          //     installation_certificate_date: this.customsIntimationEODCForm.value.installation_certificate_date ? this.pipe.transform(this.customsIntimationForm.value.installation_certificate_date, "yyyy-MM-dd") : null,
          //     installation_certificate : this.installation_certificate ?  this.installation_certificate : null,
          //     eodc_application_date:this.pipe.transform(this.customsIntimationEODCForm.value.applicationDate, "yyyy-MM-dd") ,
          //     eodc_document_date: this.pipe.transform(this.customsIntimationEODCForm.value.docDate, "yyyy-MM-dd") ,
          //     eodc_document_path: this.documentFile,
          //     eodc_redemption_date: this.pipe.transform(this.customsIntimationEODCForm.value.redemptionDate, "yyyy-MM-dd"),
          //     eodc_redemption_path: this.redemptionFile,
          //     eodc_rejection_date: this.pipe.transform(this.customsIntimationEODCForm.value.rejectionDate, "yyyy-MM-dd"),
          //     eodc_rejection_path: this.rejectionFile,
          //     others_letters_date:this.pipe.transform(this.customsIntimationEODCForm.value.otherDate, "yyyy-MM-dd") ,
          //     others_letters_path: this.otherFile,
          //     mid_term_certificate : this.mid_term_certificate ? this.mid_term_certificate : null,
          //     mid_term_certificate_date:this.customsIntimationEODCForm.value.mid_term_certificate_date ? this.pipe.transform(this.customsIntimationForm.value.mid_term_certificate_date, "yyyy-MM-dd")  : null
          //   };
          //   this.submit(input)
          //   }
          //   else{
          //     this.showNotification(
          //       "bottom",
          //       "right",
          //       "success",
          //       'Please upload EODC Redemption letter  by DGFT',
          //       "announcement"
          //     );
          //   }
          //   if(this.rejectionFile != undefined){
          //     let input = {
          //       created_by: this.currentUser.obj.user_id,
          //       licence_type: this.customsIntimationEODCForm.value.license_type,
          //       license_no: this.customsIntimationEODCForm.value.license_no,
          //       license_date: this.pipe.transform(this.customsIntimationEODCForm.value.liscenseselectedDate, "yyyy-MM-dd") ,
          //       installation_certificate_date: this.customsIntimationEODCForm.value.installation_certificate_date ? this.pipe.transform(this.customsIntimationForm.value.installation_certificate_date, "yyyy-MM-dd") : null,
          //       installation_certificate : this.installation_certificate ?  this.installation_certificate : null,
          //       eodc_application_date:this.pipe.transform(this.customsIntimationEODCForm.value.applicationDate, "yyyy-MM-dd") ,
          //       eodc_document_date: this.pipe.transform(this.customsIntimationEODCForm.value.docDate, "yyyy-MM-dd") ,
          //       eodc_document_path: this.documentFile,
          //       eodc_redemption_date: this.pipe.transform(this.customsIntimationEODCForm.value.redemptionDate, "yyyy-MM-dd"),
          //       eodc_redemption_path: this.redemptionFile,
          //       eodc_rejection_date: this.pipe.transform(this.customsIntimationEODCForm.value.rejectionDate, "yyyy-MM-dd"),
          //       eodc_rejection_path: this.rejectionFile,
          //       others_letters_date:this.pipe.transform(this.customsIntimationEODCForm.value.otherDate, "yyyy-MM-dd") ,
          //       others_letters_path: this.otherFile,
          //       mid_term_certificate : this.mid_term_certificate ? this.mid_term_certificate : null,
          //       mid_term_certificate_date:this.customsIntimationEODCForm.value.mid_term_certificate_date ? this.pipe.transform(this.customsIntimationForm.value.mid_term_certificate_date, "yyyy-MM-dd")  : null
          //     };
          //     this.submit(input)
          //     }
          //     else{
          //       this.showNotification(
          //         "bottom",
          //         "right",
          //         "success",
          //         'Please upload EODC Rejection letter by DGFT',
          //         "announcement"
          //       );
          //     }
          //     if(this.otherFile != undefined){
          //       let input = {
          //         created_by: this.currentUser.obj.user_id,
          //         licence_type: this.customsIntimationEODCForm.value.license_type,
          //         license_no: this.customsIntimationEODCForm.value.license_no,
          //         license_date: this.pipe.transform(this.customsIntimationEODCForm.value.liscenseselectedDate, "yyyy-MM-dd") ,
          //         installation_certificate_date: this.customsIntimationEODCForm.value.installation_certificate_date ? this.pipe.transform(this.customsIntimationForm.value.installation_certificate_date, "yyyy-MM-dd") : null,
          //         installation_certificate : this.installation_certificate ?  this.installation_certificate : null,
          //         eodc_application_date:this.pipe.transform(this.customsIntimationEODCForm.value.applicationDate, "yyyy-MM-dd") ,
          //         eodc_document_date: this.pipe.transform(this.customsIntimationEODCForm.value.docDate, "yyyy-MM-dd") ,
          //         eodc_document_path: this.documentFile,
          //         eodc_redemption_date: this.pipe.transform(this.customsIntimationEODCForm.value.redemptionDate, "yyyy-MM-dd"),
          //         eodc_redemption_path: this.redemptionFile,
          //         eodc_rejection_date: this.pipe.transform(this.customsIntimationEODCForm.value.rejectionDate, "yyyy-MM-dd"),
          //         eodc_rejection_path: this.rejectionFile,
          //         others_letters_date:this.pipe.transform(this.customsIntimationEODCForm.value.otherDate, "yyyy-MM-dd") ,
          //         others_letters_path: this.otherFile,
          //         mid_term_certificate : this.mid_term_certificate ? this.mid_term_certificate : null,
          //         mid_term_certificate_date:this.customsIntimationEODCForm.value.mid_term_certificate_date ? this.pipe.transform(this.customsIntimationForm.value.mid_term_certificate_date, "yyyy-MM-dd")  : null
          //       };
          //       this.submit(input)
          //       }
          //       else{
          //         this.showNotification(
          //           "bottom",
          //           "right",
          //           "success",
          //           'Please upload Other documents',
          //           "announcement"
          //         );
          //       }    

          }else{
            this.showNotification(
              "bottom",
              "right",
              "success",
             'Please select EODC Application date',
              "announcement"
            );
          }
    }
  }

  submit(input) {
   
   

   if(this.isEodcVisible){
    console.log(input, "input");
    if(this.redemptionFile == undefined || this.redemptionFile == null){
      this.showNotification(
        "bottom",
        "right",
        "success",
       'Please upload EODC Redemption Letter By DGFT',
        "announcement"
      );
    }
    else if(this.rejectionFile == undefined || this.rejectionFile == null){
      this.showNotification(
        "bottom",
        "right",
        "success",
       'Please upload EODC Rejection Letter By DGFT',
        "announcement"
      );
    }
    else if(this.otherFile == undefined || this.otherFile == null){
      this.showNotification(
        "bottom",
        "right",
        "success",
       'Please upload Other Documents',
        "announcement"
      );
    }
    else{
      this.blockUI.start();
    this.blockchainService.saveCustomsIntimation(input).subscribe(resp => 
      {
      if (resp.body.status == 200) {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
     
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        this.router.navigate(['/welcomeUser'])
      } else {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    )
    }

  }
  else{
    console.log(input, "input1");
    this.blockUI.start();
    this.blockchainService.saveCustomsIntimation(input).subscribe(resp => 
      {
      if (resp.body.status == 200) {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
     
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        this.router.navigate(['/welcomeUser'])
      } else {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    )
    }
  }


  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  onFileSelected(event, action) {
    if (action == "document") {
      let $document: any = document.querySelector('#document');
      let fileType = <File>event.target.files[0]
      if(fileType.type == 'application/pdf'){
      this.documentFile = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.document_url = event.target.result;
      };
      this.onUpload();
    }else{
      $document.value=''
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "redemption") {
      let $redemption: any = document.querySelector('#redemption');
      let fileType = <File>event.target.files[0]
      if(fileType.type == 'application/pdf'){
      this.redemptionFile = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.redemption_url = event.target.result;
      };
      this.onUpload1();
    }else{
      $redemption.value=''
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "rejection") {
      let $rejection: any = document.querySelector('#rejection');
      let fileType = <File>event.target.files[0]
      if(fileType.type == 'application/pdf'){
      this.rejectionFile = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.rejection_url = event.target.result;
      };
      this.onUpload2();
    }else{
      $rejection.value=''
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "other") {
      let $other: any = document.querySelector('#other');
      let fileType = <File>event.target.files[0]
      if(fileType.type == 'application/pdf'){
      this.otherFile = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.other_url = event.target.result;
      };
      this.onUpload3();
    }else{
      $other.value=''
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
      
    }
    else if(action == "installation_certificate"){
      let $installation_certificate: any = document.querySelector('#installation_certificate');
      let fileType = <File>event.target.files[0]
      if(fileType.type == 'application/pdf'){
      this.installation_certificate = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload4();
    }else{
      $installation_certificate.value=''
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    }
    else if(action == "mid_term_certificate"){
      let $mid_term_certificate: any = document.querySelector('#mid_term_certificate');
      let fileType = <File>event.target.files[0]
      if(fileType.type == 'application/pdf'){
      this.mid_term_certificate = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload5();
    }else{
      $mid_term_certificate.value=''
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    }
  }

  //for document
  onUpload() {
    const fd = new FormData();
    fd.append("file", this.documentFile, this.documentFile.name);
  }

  //for redemptionFile
  onUpload1() {
    const fd = new FormData();
    fd.append("file", this.redemptionFile, this.redemptionFile.name);
  }

  //for rejectionFile
  onUpload2() {
    const fd = new FormData();
    fd.append("file", this.rejectionFile, this.rejectionFile.name);
  }

  // for otherFile
  onUpload3() {
    const fd = new FormData();
    fd.append("file", this.otherFile, this.otherFile.name);
  }
  onUpload4() {
    const fd = new FormData();
    fd.append("file", this.installation_certificate, this.installation_certificate.name);
  }
  onUpload5() {
    const fd = new FormData();
    fd.append("file", this.mid_term_certificate, this.mid_term_certificate.name);
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
  openModalViewLetter(section) {
    // console.log(section.letter);
    this.sectionViewLetterDetails = section;
    // console.log(this.sectionViewLetterDetails)
    const buttonModal = document.getElementById("openModalButton");
    // buttonModal.click();
  } 
}
