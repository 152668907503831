import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface PeriodicElement {
  systemId: number;
  impexpName: string;
  iecCode: string;
  fileNo: string;
  validationDate: string;
  viewCaseDetails: string;
  status: string;
  incomingTaskDate: string;
  actionCompletedDate: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    systemId: 1,
    impexpName: "Import Export",
    iecCode: "75256fe2-eed7-4427-b886-dc6879a1ca6",
    fileNo: "File256fe2-eed7-4427-b886-dc6879a1ca6",
    validationDate: "01-02-2022",
    viewCaseDetails: "",
    status: "New",
    incomingTaskDate: "30-02-2022",
    actionCompletedDate: "",
  },
];
@Component({
  selector: "app-createjob",
  templateUrl: "./createjob.component.html",
  styleUrls: ["./createjob.component.css"],
})
export class CreatejobComponent implements OnInit {
  selectedStatus = "";

  allStatus: Status[] = [
    { value: "New", viewValue: "New" },
    { value: "Job No. Created", viewValue: "Job No. Created" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  dataSource = ELEMENT_DATA;

  displayedColumns = [
    "systemId",
    "impexpName",
    "iecCode",
    "fileNo",
    "validationDate",
    "viewCaseDetails",
    "status",
    "incomingTaskDate",
    "actionCompletedDate",
  ];
  autoFillForm = this.formBuilder.group({
    name: [""],
    designation: [""],
  });

  caseDetailsFillForm = this.formBuilder.group({
    impexpname: [""],
    iecCode: [""],
    inititionDate: [""],
    fileNo: [""],
    noAlert: [""],
  });

  currentUser: any;
  caseDetailsData: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));

    this.autoFillForm.setValue({
      name: this.currentUser.obj.first_name + this.currentUser.obj.last_name,
      designation: this.currentUser.obj.designation,
    });
    // this.caseDetailsFillForm.setValue({
    //   impexpname: this.caseDetailsData.impexpName,
    //   iecCode: this.caseDetailsData.iecCode,
    //   inititionDate: this.caseDetailsData.inititionDate,
    //   fileNo: this.caseDetailsData.fileNo,
    //   noAlert: "",
    // });
  }

  caseDetailsDialogue(data) {
    localStorage.setItem("casedetailsData", JSON.stringify(data));
  }

  back() {
    this.router.navigate(["/validation"]);
  }

  navigateToRegister() {
    this.router.navigate(["/registerTask"]);
  }
  navigateValidation() {
    this.router.navigate(["/validation"]);
  }

  navigateCreateJob() {
    this.router.navigate(["/createJob"]);
  }

  navigateProvideBond() {
    this.router.navigate(["/provideBond"]);
  }
  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
