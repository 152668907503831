import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "app/_services";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
declare var $: any;

export interface roleType {
  id: string;
  name: string;
}

@Component({
  selector: "app-upload-doc-cancel",
  templateUrl: "./upload-doc-cancel.component.html",
  styleUrls: ["./upload-doc-cancel.component.css"],
})
export class UploadDocCancelComponent implements OnInit {
  sectionViewLetterDetails: any;
  selectedStatus = "";
  color = "primary";
  backgroundColor = "#64c153";
  allRoleType: roleType[] = [
    { id: "IEC", name: "IEC" },
    { id: "Customs Broker", name: "Customs Broker" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;

  cancellationForm = this.formBuilder.group({
    applicationType: ["", Validators.required],
    role: [""],
  }); 
  authApplication: any;
  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  selectedFile: File;
  url: string | ArrayBuffer;
  selectedFile25url: string | ArrayBuffer;
  application_url: string | ArrayBuffer;
  exportObligation_url: string | ArrayBuffer;
  surrender_url: string | ArrayBuffer;
  regularization_url: string | ArrayBuffer;
  list_url: string | ArrayBuffer;
  pdf_url: string | ArrayBuffer;
  selectedFile18_url: string | ArrayBuffer;
  selectedFile19_url: string | ArrayBuffer;
  selectedFile20_url: string | ArrayBuffer;
  selectedFile21_url: string | ArrayBuffer;
  selectedFile22_url: string | ArrayBuffer;
  selectedFile23_url: string | ArrayBuffer;
  selectedFile24_url: string | ArrayBuffer;
  selectedFile25_url: string | ArrayBuffer;
  selectedFile26_url: string | ArrayBuffer;
  selectedFile27_url: string | ArrayBuffer;
  selectedFile28_url: string | ArrayBuffer;
  allApplicationTypes: any;
  applicationType: any;
  selectedFile1: File;
  selectedFile2: File;
  selectedFile3: File;
  selectedFile4: File;
  selectedFile5: File;
  selectedFile6: File;
  selectedFile7: File;
  selectedFile8: File;
  selectedFile9: File;
  selectedFile10: File;
  selectedFile11: File;
  selectedFile12: File;
  selectedFile13: File;
  selectedFile14: File;
  selectedFile15: File;
  selectedFile16: File;
  selectedFile17: File;
  selectedFile18: File;
  selectedFile19: File;
  selectedFile20: File;
  selectedFile21: File;
  selectedFile22: File;
  selectedFile23: File;
  selectedFile24: File;
  selectedFile25: File;
  selectedFile26: File;
  selectedFile27: File;

  applicationIdResp: any;
  showPdf: boolean = false;
  aaPath: any;
  iecPath: any;
  showIECPdf: boolean = false;
  dynamicArrayPdf = [];
  dynamicArrayExcel = [];
  isIecAuthorityLetter: boolean;
  selectedFile28: File;
  showSubmitBtn: boolean=false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale("en-GB"); //dd/MM/yyyy
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    console.log(this.currentUser.obj.user_id);
    if (this.currentUser.obj.role == "3") {
      this.role = "IEC";
    } else if (this.currentUser.obj.role == "1") {
      this.role = "Customs Broker";
    } else {
    }
    this.getApplicationType();
    // this.getApplicationById();

    this.cancellationForm.patchValue({
      role: this.role,
    });
  }

  get f() {
    return this.cancellationForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.cancellationForm.controls[controlName].hasError(errorName);
  };

  authorize(e) {
    console.log(e, "event that is being generated");
    if (e.value == "YES") {
      this.showSubmitBtn=false;
      this.isIecAuthorityLetter = true;
    } else {
      this.showSubmitBtn=true;
      this.isIecAuthorityLetter = false;
    }
  }

  addPdfRow() {
    this.dynamicArrayPdf.push({ otherPdf: "" });
    console.log("New row added successfully", "New Row");
  }
  addExcelRow() {
    this.dynamicArrayExcel.push({ otherExcel: "" });
  }
  deletePdfRow(event,action,index) {
    this.dynamicArrayPdf.splice(index, 1);
    if(action=='pdf'){
      this.selectedFile5=null;
    }
    if(action=='pdf0'){
      this.selectedFile18=null;
    }
    if(action=='pdf1'){
      this.selectedFile19=null;
    }
    if(action=='pdf2'){
      this.selectedFile20=null;
    }
    if(action=='pdf3'){
      this.selectedFile21=null;
    }
    if(action=='pdf4'){
      this.selectedFile22=null;
    }
    if(action=='pdf5'){
      this.selectedFile23=null;
    }
    if(action=='pdf6'){
      this.selectedFile24=null;
    }
    if(action=='pdf7'){
      this.selectedFile25=null;
    }
    if(action=='pdf8'){
      this.selectedFile26=null;
    }
    if(action=='pdf9'){
      this.selectedFile27=null;
    }

  }
  deleteExcelRow(event,action,index) {
    this.dynamicArrayExcel.splice(index,1);

    if(action=='excel'){
      this.selectedFile6=null;
    }
    if(action=='excel0'){
      this.selectedFile8=null;
    }
    if(action=='excel1'){
      this.selectedFile9=null;
    }
    if(action=='excel2'){
      this.selectedFile10=null;
    }
    if(action=='excel3'){
      this.selectedFile11=null;
    }
    if(action=='excel4'){
      this.selectedFile12=null;
    }
    if(action=='excel5'){
      this.selectedFile13=null;
    }
    if(action=='excel6'){
      this.selectedFile14=null;
    }
    if(action=='excel7'){
      this.selectedFile9=null;
    }
    if(action=='excel8'){
      this.selectedFile15=null;
    }
    if(action=='excel9'){
      this.selectedFile16=null;
    }

  }
  getValues() {
    console.log(this.dynamicArrayPdf);
  }
  getApplicationType() {
    this.blockchainService.getApplicationType().subscribe(
      (resp) => {
        this.allApplicationTypes = resp.obj;
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        if (error.status == 401) {
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        } else if (error.status == 500) {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        } else {
        }
      }
    );
  }

  // getApplicationById() {
  //   let input = {
  //     application_id: localStorage.getItem('application_id')
  //   }
  //   this.blockchainService.getApplicationById(input).subscribe(resp => {
  //     this.applicationIdResp = resp.body.obj;
  //     localStorage.setItem('application_id',this.applicationIdResp.application_id);
  //     if (this.applicationIdResp.aa_path != '' && this.applicationIdResp.aa_path != null) {
  //       this.showPdf = true;
  //     } else {
  //       this.showPdf = false
  //     }
  //     if (this.applicationIdResp.iec_certificate_path != '' && this.applicationIdResp.iec_certificate_path != null) {
  //       this.showIECPdf = true;
  //     } else {
  //       this.showIECPdf = false
  //     }
  //     this.aaPath = this.applicationIdResp.aa_path;
  //     this.selectedFile = this.aaPath;

  //     this.iecPath = this.applicationIdResp.iec_certificate_path;
  //     this.selectedFile1 = this.iecPath;

  //     this.role = this.currentUser.obj.role_name

  //     this.cancellationForm.patchValue({
  //       aa_file: this.applicationIdResp.aa_path,
  //       iec_file: this.applicationIdResp.iec_certificate_path,
  //       applicationType: this.applicationIdResp.application_type_name,
  //       role: this.role,

  //     });

  //   })
  // }

  selectedApplicationType(event, applicationType) {
    if (event.isUserInput) {
      console.log(event);
      this.applicationType = applicationType;
      this.cancellationForm.value.applicationType = applicationType;
    }
  }

  selectedRole(event, role) {
    if (event.isUserInput) {
      console.log(event);
      this.role = role;
      this.cancellationForm.value.role = role;
    }
  }

  next() {
    this.save();
    // this.router.navigate(['/cancelDetails'])
  }
  back(){
    this.router.navigate(['/cancelDocUpload'])
  }

  save() {
    console.log("cantype", this.cancellationForm.value.applicationType);
    // this.blockUI.start("Please wait this may take few seconds ...");

    // this.saveOrUpdate();
    var user_roles = this.currentUser.obj.role;
    // if (localStorage.getItem("application_id") != undefined || localStorage.getItem("application_id") != null) {
    if (this.selectedFile == undefined) {
      this.selectedFile = null;
    }
    if (this.selectedFile1 == undefined) {
      this.selectedFile1 = null;
    }
    if (this.selectedFile2 == undefined) {
      this.selectedFile2 = null;
    }
    if (this.selectedFile3 == undefined) {
      this.selectedFile3 = null;
    }
    if (this.selectedFile4 == undefined) {
      this.selectedFile4 = null;
    }
    if (this.selectedFile5 == undefined) {
      this.selectedFile5 = null;
    }
    if (this.selectedFile6 == undefined) {
      this.selectedFile6 = null;
    }
    if (this.selectedFile8 == undefined) {
      this.selectedFile8 = null;
    }
    if (this.selectedFile9 == undefined) {
      this.selectedFile9 = null;
    }
    if (this.selectedFile10 == undefined) {
      this.selectedFile10 = null;
    }
    if (this.selectedFile11 == undefined) {
      this.selectedFile11 = null;
    }
    if (this.selectedFile12 == undefined) {
      this.selectedFile12 = null;
    }
    if (this.selectedFile13 == undefined) {
      this.selectedFile13 = null;
    }
    if (this.selectedFile14 == undefined) {
      this.selectedFile14 = null;
    }
    if (this.selectedFile15 == undefined) {
      this.selectedFile15 = null;
    }
    if (this.selectedFile16 == undefined) {
      this.selectedFile16 = null;
    }
    if (this.selectedFile17 == undefined) {
      this.selectedFile17 = null;
    }
    if (this.selectedFile18 == undefined) {
      this.selectedFile18 = null;
    }
    if (this.selectedFile19 == undefined) {
      this.selectedFile19 = null;
    }
    if (this.selectedFile20 == undefined) {
      this.selectedFile20 = null;
    }
    if (this.selectedFile21 == undefined) {
      this.selectedFile21 = null;
    }
    if (this.selectedFile22 == undefined) {
      this.selectedFile22 = null;
    }
    if (this.selectedFile23 == undefined) {
      this.selectedFile23 = null;
    }
    if (this.selectedFile24 == undefined) {
      this.selectedFile24 = null;
    }
    if (this.selectedFile25 == undefined) {
      this.selectedFile25 = null;
    }
    if (this.selectedFile26 == undefined) {
      this.selectedFile26 = null;
    }
    if (this.selectedFile27 == undefined) {
      this.selectedFile27 = null;
    }
    if (this.selectedFile28 == undefined) {
      this.selectedFile28 = null;
    }
    let cancellation_id = JSON.parse(localStorage.getItem("cancellation_id"));
    console.log(cancellation_id);
    let input = {
      auth_type: this.cancellationForm.value.applicationType,
      role: this.currentUser.obj.role,
      cancellation_id: cancellation_id,
      upload_application_letter: this.selectedFile,
      upload_export_obligation_discharge_certificate: this.selectedFile1,
      upload_surrender_letter: this.selectedFile2,
      upload_regularization_letter: this.selectedFile3,
      upload_list_ofOther_documents_Being_submitted: this.selectedFile4,

      upload_pdf_of_other_documents1: this.selectedFile5,
      upload_pdf_of_other_documents2: this.selectedFile18,
      upload_pdf_of_other_documents3: this.selectedFile19,
      upload_pdf_of_other_documents4: this.selectedFile20,
      upload_pdf_of_other_documents5: this.selectedFile21,
      upload_pdf_of_other_documents6: this.selectedFile22,
      upload_pdf_of_other_documents7: this.selectedFile23,
      upload_pdf_of_other_documents8: this.selectedFile24,
      upload_pdf_of_other_documents9: this.selectedFile25,
      upload_pdf_of_other_documents10: this.selectedFile26,
      upload_excel_sheet1: this.selectedFile6,
      upload_excel_sheet2: this.selectedFile8,
      upload_excel_sheet3: this.selectedFile9,
      upload_excel_sheet4: this.selectedFile10,
      upload_excel_sheet5: this.selectedFile11,
      upload_excel_sheet6: this.selectedFile12,
      upload_excel_sheet7: this.selectedFile13,
      upload_excel_sheet8: this.selectedFile14,
      upload_excel_sheet9: this.selectedFile15,
      upload_excel_sheet10: this.selectedFile16,

      iec_authority_letter: this.selectedFile28,
      created_by: this.currentUser.obj.user_id,
    };
    console.log(input);
    this.blockchainService.uploadCancellationDocumentsV1(input).subscribe(
      (resp) => {
        if (resp.body.status == 200) {
          // setTimeout(() => {
          //   this.blockUI.stop();
          // }, 1500);
          // localStorage.setItem(
          //   "cancellation_id",
          //   JSON.stringify(resp.body.obj)
          // );
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );

          setTimeout(() => {
            this.router.navigate(["/autherizationRequest"]);
          }, 2000);
        } else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      },
      (error) => {
        console.log(error);
        this.alertService.error(error);
        if (error.status == 401) {
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        } else if (error.status == 500) {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          // location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        } else {
        }
      }
    );
    // }
  }

  // saveOrUpdate() {
  //   var user_roles = this.currentUser.obj.role
  //   if (localStorage.getItem("application_id") == undefined || localStorage.getItem("application_id") == null) {
  //     let input = {
  //       aa_file: this.selectedFile,
  //       iec_file: this.selectedFile1,
  //       application_type_id: '1',
  //       application_type_name: this.cancellationForm.value.applicationType,
  //       user_roles: this.currentUser.obj.role,
  //       created_by: this.currentUser.obj.user_id,
  //     }
  //     this.blockchainService.saveApplication(input).subscribe(resp => {
  //       if (resp.status == 200) {
  //         localStorage.setItem('application_id', resp.obj);
  //         this.showNotification(
  //           "bottom",
  //           "right",
  //           "success",
  //           resp.message,
  //           "announcement"
  //         );
  //       } else {
  //         this.showNotification(
  //           "bottom",
  //           "right",
  //           "success",
  //           resp.message,
  //           "announcement"
  //         );
  //       }
  //     })
  //   } else {
  //     let input = {
  //       aa_file: this.selectedFile,
  //       iec_file: this.selectedFile1,
  //       application_type_id: '1',
  //       application_type_name: this.cancellationForm.value.applicationType,
  //       user_roles: user_roles,
  //       application_id: localStorage.getItem("application_id"),
  //       created_by: this.currentUser.obj.user_id,
  //     }
  //     console.log(input, 'input 2')
  //     this.blockchainService.updateApplication(input).subscribe(resp => {
  //       localStorage.setItem('application_id', input.application_id);
  //       if (resp.status == 200) {
  //         this.showNotification(
  //           "bottom",
  //           "right",
  //           "success",
  //           resp.message,
  //           "announcement"
  //         );
  //       } else {
  //         this.showNotification(
  //           "bottom",
  //           "right",
  //           "success",
  //           resp.message,
  //           "announcement"
  //         );
  //       }
  //     })
  //   }
  // }

  statusFn(e, status) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  onFileSelected(event, action) {
    if (action == "application") {
      let $application: any = document.querySelector("#application");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.application_url = event.target.result;
        };
        this.onUpload();
      } else {
        $application.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "exportObligation") {
      let $exportObligation: any = document.querySelector("#exportObligation");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile1 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.exportObligation_url = event.target.result;
        };
        this.onUpload1();
      } else {
        $exportObligation.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "surrender") {
      let $surrender: any = document.querySelector("#surrender");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile2 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.surrender_url = event.target.result;
        };
        this.onUpload2();
      } else {
        $surrender.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "regularization") {
      let $regularization: any = document.querySelector("#regularization");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile3 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.regularization_url = event.target.result;
        };
        this.onUpload3();
      } else {
        $regularization.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "list") {
      let $list: any = document.querySelector("#list");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile4 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.list_url = event.target.result;
        };
        this.onUpload4();
      } else {
        $list.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "pdf") {
      let $pdf: any = document.querySelector("#pdf");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile5 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.pdf_url = event.target.result;
        };
        this.onUpload5();
      } else {
        $pdf.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "excel") {
      let $excel: any = document.querySelector("#excel");
      let fileType = <File>event.target.files[0];
      if (fileType.type == ".csv" || fileType.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType.type == "application/vnd.ms-excel") {
      this.selectedFile6 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFile6); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload6();
    } else {
      $excel.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "excel0") {
      let $excel0: any = document.querySelector("#excel0");
      let fileType = <File>event.target.files[0];
      if (fileType.type == ".csv" || fileType.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType.type == "application/vnd.ms-excel") {
      this.selectedFile8 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFile8); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload8();
    } else {
      $excel0.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "excel1") {
      let $excel1: any = document.querySelector("#excel1");
      let fileType = <File>event.target.files[0];
      if (fileType.type == ".csv" || fileType.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType.type == "application/vnd.ms-excel") {
      this.selectedFile9 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload9();
    } else {
      $excel1.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "excel2") {
      let $excel2: any = document.querySelector("#excel2");
      let fileType = <File>event.target.files[0];
      if (fileType.type == ".csv" || fileType.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType.type == "application/vnd.ms-excel") {
      this.selectedFile10 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload10();
    } else {
      $excel2.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "excel3") {
      let $excel3: any = document.querySelector("#excel3");
      let fileType = <File>event.target.files[0];
      if (fileType.type == ".csv" || fileType.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType.type == "application/vnd.ms-excel") {
      this.selectedFile11 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload11();
    } else {
      $excel3.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "excel4") {
      let $excel4: any = document.querySelector("#excel4");
      let fileType = <File>event.target.files[0];
      if (fileType.type == ".csv" || fileType.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType.type == "application/vnd.ms-excel") {
      this.selectedFile12 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload12();
    } else {
      $excel4.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "excel5") {
      let $excel5: any = document.querySelector("#excel5");
      let fileType = <File>event.target.files[0];
      if (fileType.type == ".csv" || fileType.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType.type == "application/vnd.ms-excel") {
      this.selectedFile13 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload13();
    } else {
      $excel5.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "excel6") {
      let $excel6: any = document.querySelector("#excel6");
      let fileType = <File>event.target.files[0];
      if (fileType.type == ".csv" || fileType.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType.type == "application/vnd.ms-excel") {
      this.selectedFile14 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload14();
    } else {
      $excel6.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "excel7") {
      let $excel7: any = document.querySelector("#excel7");
      let fileType = <File>event.target.files[0];
      if (fileType.type == ".csv" || fileType.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType.type == "application/vnd.ms-excel") {
      this.selectedFile15 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload15();
    } else {
      $excel7.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "excel8") {
      let $excel8: any = document.querySelector("#excel8");
      let fileType = <File>event.target.files[0];
      if (fileType.type == ".csv" || fileType.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType.type == "application/vnd.ms-excel") {
      this.selectedFile16 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload16();
    } else {
      $excel8.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "excel9") {
      let $excel9: any = document.querySelector("#excel9");
      let fileType = <File>event.target.files[0];
      if (fileType.type == ".csv" || fileType.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType.type == "application/vnd.ms-excel") {
      this.selectedFile17 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload17();
    } else {
      $excel9.value = "";
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else if (action == "pdf0") {
      let $pdf0: any = document.querySelector("#pdf0");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile18 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.selectedFile18_url = event.target.result;
        };
        this.onUpload18();
      } else {
        $pdf0.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "pdf1") {
      let $pdf1: any = document.querySelector("#pdf1");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile19 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.selectedFile19_url = event.target.result;
        };
        this.onUpload19();
      } else {
        $pdf1.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "pdf2") {
      let $pdf2: any = document.querySelector("#pdf2");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile20 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.selectedFile20_url = event.target.result;
        };
        this.onUpload20();
      } else {
        $pdf2.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "pdf3") {
      let $pdf3: any = document.querySelector("#pdf3");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile21 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.selectedFile21_url = event.target.result;
        };
        this.onUpload21();
      } else {
        $pdf3.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "pdf4") {
      let $pdf4: any = document.querySelector("#pdf4");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile22 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.selectedFile22_url = event.target.result;
        };
        this.onUpload22();
      } else {
        $pdf4.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "pdf5") {
      let $pdf5: any = document.querySelector("#pdf5");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile23 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.selectedFile23_url = event.target.result;
        };
        this.onUpload23();
      } else {
        $pdf5.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "pdf6") {
      let $pdf6: any = document.querySelector("#pdf6");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile24 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.selectedFile24_url = event.target.result;
        };
        this.onUpload24();
      } else {
        $pdf6.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "pdf7") {
      let $pdf7: any = document.querySelector("#pdf7");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile25 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.selectedFile25_url = event.target.result;
        };
        this.onUpload25();
      } else {
        $pdf7.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "pdf8") {
      let $pdf8: any = document.querySelector("#pdf8");
      let fileType = <File>event.target.files[0];
      if (fileType.type == "application/pdf") {
        this.selectedFile26 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.selectedFile26_url = event.target.result;
        };
        this.onUpload26();
      } else {
        $pdf8.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    } else if (action == "pdf9") {
      this.selectedFile27 = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.selectedFile27_url = event.target.result;
      };
      this.onUpload27();
    } else if (action == "iecAuth") {
      let $iecAuth: any = document.querySelector("#iecAuth");
      let fileType = <File>event.target.files[0];
      console.log(fileType);
      if (fileType){
      if (fileType.type == "application/pdf") {
        this.selectedFile28 = <File>event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = (event) => {
          // called once readAsDataURL is completed
          this.selectedFile28_url = event.target.result;
          this.showSubmitBtn=true; 
          console.log(this.selectedFile28_url,this.selectedFile28);
        };
        this.onUpload28();
      } else {
        console.log(this.selectedFile28_url,this.selectedFile28);
        this.showSubmitBtn=false; 
        $iecAuth.value = "";
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Format Invalid",
          "announcement"
        );
      }
    }
    else{
      console.log(fileType);
     this.showSubmitBtn=false; 
    }
    }
  }

  //for iec
  onUpload() {
    const fd = new FormData();
    fd.append("file", this.selectedFile, this.selectedFile.name);
  }

  onUpload1() {
    const fd = new FormData();
    fd.append("file", this.selectedFile1, this.selectedFile1.name);
  }

  onUpload2() {
    const fd = new FormData();
    fd.append("file", this.selectedFile2, this.selectedFile2.name);
  }

  onUpload3() {
    const fd = new FormData();
    fd.append("file", this.selectedFile3, this.selectedFile3.name);
  }

  onUpload4() {
    const fd = new FormData();
    fd.append("file", this.selectedFile4, this.selectedFile4.name);
  }

  onUpload5() {
    const fd = new FormData();
    fd.append("file", this.selectedFile5, this.selectedFile5.name);
  }

  onUpload6() {
    const fd = new FormData();
    fd.append("file", this.selectedFile6, this.selectedFile6.name);
  }

  onUpload7() {
    const fd = new FormData();
    fd.append("file", this.selectedFile7, this.selectedFile7.name);
  }

  onUpload8() {
    const fd = new FormData();
    fd.append("file", this.selectedFile8, this.selectedFile8.name);
  }
  onUpload9() {
    const fd = new FormData();
    fd.append("file", this.selectedFile9, this.selectedFile9.name);
  }
  onUpload10() {
    const fd = new FormData();
    fd.append("file", this.selectedFile10, this.selectedFile10.name);
  }
  onUpload11() {
    const fd = new FormData();
    fd.append("file", this.selectedFile11, this.selectedFile11.name);
  }
  onUpload12() {
    const fd = new FormData();
    fd.append("file", this.selectedFile12, this.selectedFile12.name);
  }
  onUpload13() {
    const fd = new FormData();
    fd.append("file", this.selectedFile13, this.selectedFile13.name);
  }
  onUpload14() {
    const fd = new FormData();
    fd.append("file", this.selectedFile14, this.selectedFile14.name);
  }
  onUpload15() {
    const fd = new FormData();
    fd.append("file", this.selectedFile15, this.selectedFile15.name);
  }
  onUpload16() {
    const fd = new FormData();
    fd.append("file", this.selectedFile16, this.selectedFile16.name);
  }
  onUpload17() {
    const fd = new FormData();
    fd.append("file", this.selectedFile17, this.selectedFile17.name);
  }
  onUpload18() {
    const fd = new FormData();
    fd.append("file", this.selectedFile18, this.selectedFile18.name);
  }
  onUpload19() {
    const fd = new FormData();
    fd.append("file", this.selectedFile19, this.selectedFile19.name);
  }
  onUpload20() {
    const fd = new FormData();
    fd.append("file", this.selectedFile20, this.selectedFile20.name);
  }
  onUpload21() {
    const fd = new FormData();
    fd.append("file", this.selectedFile21, this.selectedFile21.name);
  }
  onUpload22() {
    const fd = new FormData();
    fd.append("file", this.selectedFile22, this.selectedFile22.name);
  }
  onUpload23() {
    const fd = new FormData();
    fd.append("file", this.selectedFile23, this.selectedFile23.name);
  }
  onUpload24() {
    const fd = new FormData();
    fd.append("file", this.selectedFile24, this.selectedFile24.name);
  }
  onUpload25() {
    const fd = new FormData();
    fd.append("file", this.selectedFile25, this.selectedFile25.name);
  }
  onUpload26() {
    const fd = new FormData();
    fd.append("file", this.selectedFile26, this.selectedFile26.name);
  }
  onUpload27() {
    const fd = new FormData();
    fd.append("file", this.selectedFile27, this.selectedFile27.name);
  }

  onUpload28() {
    const fd = new FormData();
    fd.append("file", this.selectedFile28, this.selectedFile28.name);
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

  openModalViewLetter(section) {
    // console.log(section.letter);
    this.sectionViewLetterDetails = section;
    // console.log(this.sectionViewLetterDetails)
    const buttonModal = document.getElementById("openModalButton");
    // buttonModal.click();
  }
}
