import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// import { HttpClientModule  } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { CommonModule } from '@angular/common';  

import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";

import { AppComponent } from "./app.component";

// import { DashboardComponent } from './dashboard/dashboard.component';
// import { UserProfileComponent } from './user-profile/user-profile.component';
// import { TableListComponent } from './table-list/table-list.component';
// import { TypographyComponent } from './typography/typography.component';
// import { IconsComponent } from './icons/icons.component';
// import { MapsComponent } from './maps/maps.component';
// import { NotificationsComponent } from './notifications/notifications.component';
// import { UpgradeComponent } from './upgrade/upgrade.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { BrowserModule } from "@angular/platform-browser";
import { AppService } from "./app.service";
// import { ChartModule } from "angular-highcharts";
import { LoginComponent } from "./login/login.component";
import { AuthGuard } from "./_guards/index";
import {
  AlertService,
  AuthenticationService,
  UserService,
} from "./_services/index";
import { AlertComponent } from "./_directives/alert.component";
import { HomeComponent } from "./home/home.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
// import { RuleEngineUpdateDialogComponent } from './ruleengine/rule-engine-update-dialog/rule-engine-update-dialog.component';

import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { CdkTableModule } from "@angular/cdk/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatRippleModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSidenavModule } from "@angular/material/sidenav";
/* Angular Flex Layout */
// import { FlexLayoutModule } from "@angular/flex-layout";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatMenuModule } from "@angular/material/menu";
import {MatStepperModule} from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
//  import { PiechartComponent } from './device/piechart/piechart.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatRadioModule } from "@angular/material/radio";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CustomInputModule } from "./widgets/custom-input/custom-input.module";
// import { ChartsModule } from 'ng2-charts';
// import * as chart from "chart.js";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { RegistrationTaskComponent } from "./blockchainImpExp/customs_interface/aa_ta/registration-task/registration-task.component";
import { CaseDetailsComponent } from "./blockchainImpExp/customs_interface/aa_ta/registration-task/case-details/case-details.component";
import { RecievedFileComponent } from "./blockchainImpExp/customs_interface/aa_ta/registration-task/recieved-file/recieved-file.component";
import { ValidationComponent } from "./blockchainImpExp/customs_interface/aa_ta/validation/validation.component";
import { Validation2Component } from "./blockchainImpExp/customs_interface/aa_ta/validation2/validation2.component";
import { CreatejobComponent } from "./blockchainImpExp/customs_interface/aa_ta/createjob/createjob.component";
import { Createjob1Component } from "./blockchainImpExp/customs_interface/aa_ta/createjob/createjob1/createjob1.component";
import { ProvidebondComponent } from "./blockchainImpExp/customs_interface/aa_ta/providebond/providebond.component";
import { RegisterLicenseTaComponent } from "./blockchainImpExp/customs_interface/license_ta/register-license-ta/register-license-ta.component";
import { RemarksLicenseTaComponent } from "./blockchainImpExp/customs_interface/license_ta/remarks-license-ta/remarks-license-ta.component";
import { Notesheet1Component } from "./blockchainImpExp/customs_interface/aa_exm/notesheet1/notesheet1.component";
import { NoteSheet2Component } from "./blockchainImpExp/customs_interface/aa_exm/note-sheet2/note-sheet2.component";
import { NoteSheet3Component } from "./blockchainImpExp/customs_interface/aa_exm/note-sheet3/note-sheet3.component";
import { EndorseComponent } from "./blockchainImpExp/customs_interface/aa_ao/endorse/endorse.component";
import { Endorse2Component } from "./blockchainImpExp/customs_interface/aa_ao/endorse2/endorse2.component";
import { Endorse3Component } from "./blockchainImpExp/customs_interface/aa_ao/endorse3/endorse3.component";
import { ValidateDcComponent } from "./blockchainImpExp/customs_interface/aa_dc/validate-dc/validate-dc.component";
import { ValidateDc3Component } from "./blockchainImpExp/customs_interface/aa_dc/validate-dc3/validate-dc3.component";
import { ValidateDc2Component } from "./blockchainImpExp/customs_interface/aa_dc/validate-dc2/validate-dc2.component";
import { AcceptJobComponent } from "./blockchainImpExp/customs_interface/aa_dc/accept-job/accept-job.component";
import { AcceptJob2Component } from "./blockchainImpExp/customs_interface/aa_dc/accept-job2/accept-job2.component";
import { BbgRecieptComponent } from "./blockchainImpExp/customs_interface/bbg_ao/bbg-reciept/bbg-reciept.component";
import { BbgAcceptComponent } from "./blockchainImpExp/customs_interface/bbg_ao/bbg-accept/bbg-accept.component";
import { BbgSubmitComponent } from "./blockchainImpExp/customs_interface/bbg_ao/bbg-submit/bbg-submit.component";
import { LicenseIssueComponent } from "./blockchainImpExp/customs_interface/license_ao/license-issue/license-issue.component";
import { LicenseIssue2Component } from "./blockchainImpExp/customs_interface/license_ao/license-issue2/license-issue2.component";
import { CreateprofileComponent } from './blockchainImpExp/imp_exp/profile/createprofile/createprofile.component';
import { ApplnSummaryComponent } from './blockchainImpExp/imp_exp/registration/appln-summary/appln-summary.component';
import { AaSummaryComponent } from './blockchainImpExp/imp_exp/registration/aa-summary/aa-summary.component';
import { UploadDocComponent } from './blockchainImpExp/imp_exp/registration/upload-doc/upload-doc.component';
import { AppSummaryComponent } from './blockchainImpExp/imp_exp/cancellation/app-summary/app-summary.component';
import { EodcComponent } from './blockchainImpExp/imp_exp/cancellation/eodc/eodc.component';
import { ImportComponent } from './blockchainImpExp/imp_exp/cancellation/import/import.component';
import { ExportComponent } from './blockchainImpExp/imp_exp/cancellation/export/export.component';
import { SignUpComponent } from './blockchainImpExp/sign-up/sign-up.component';
import { ApplnTypeRoleComponent } from './blockchainImpExp/imp_exp/registration/appln-type-role/appln-type-role.component';
import { IecDetailsComponent } from './blockchainImpExp/imp_exp/registration/iec-details/iec-details.component';
import { UploadAAComponent } from './blockchainImpExp/imp_exp/registration/upload-aa/upload-aa.component';
import { BongBgDetailsComponent } from './blockchainImpExp/imp_exp/registration/bong-bg-details/bong-bg-details.component';
import { SubmissionComponent } from './blockchainImpExp/imp_exp/registration/submission/submission.component';
import { ImportOUComponent } from './blockchainImpExp/imp_exp/obligation_update/import-ou/import-ou.component';
import { AddImportOUComponent } from './blockchainImpExp/imp_exp/obligation_update/add-import-ou/add-import-ou.component';
import { ExportOUComponent } from './blockchainImpExp/imp_exp/obligation_update/export-ou/export-ou.component';
import { AddExportOUComponent } from './blockchainImpExp/imp_exp/obligation_update/add-export-ou/add-export-ou.component';
import { AddImportComponent } from './blockchainImpExp/imp_exp/cancellation/add-import/add-import.component';
import { AddExportComponent } from './blockchainImpExp/imp_exp/cancellation/add-export/add-export.component';
import { AaHeaderComponent } from './blockchainImpExp/imp_exp/registration/aa-header/aa-header.component';
import { AaDetailsComponent } from './blockchainImpExp/imp_exp/registration/aa-details/aa-details.component';
import { ExportAAComponent } from './blockchainImpExp/imp_exp/registration/export-aa/export-aa.component';
import { AddExportAAComponent } from './blockchainImpExp/imp_exp/registration/export-aa/add-export-aa/add-export-aa.component';
import { ImpAAComponent } from './blockchainImpExp/imp_exp/registration/imp-aa/imp-aa.component';
import { AddimpAAComponent } from './blockchainImpExp/imp_exp/registration/imp-aa/addimp-aa/addimp-aa.component';
import { SupportingProjectSiteComponent } from './blockchainImpExp/imp_exp/registration/supporting-project-site/supporting-project-site.component';
import { AddProjectSiteComponent } from './blockchainImpExp/imp_exp/registration/supporting-project-site/add-project-site/add-project-site.component';
import { Step1Component } from './blockchainImpExp/customs_interface/aa_L1/step1/step1.component';
import { Step2Component } from './blockchainImpExp/customs_interface/aa_L1/step2/step2.component';
import { EditprofileComponent } from './blockchainImpExp/imp_exp/profile/editprofile/editprofile.component';
import { RegisterInitiateComponent } from './blockchainImpExp/customs_interface/license_ta/register-initiate/register-initiate.component';
import { JobCreationComponent } from './blockchainImpExp/customs_interface/aa_L1/job-creation/job-creation.component';
import { WelcomescreenComponent } from './blockchainImpExp/imp_exp/welcomescreen/welcomescreen.component';
import { NewmoduleComponent } from './blockchainImpExp/imp_exp/payment/newmodule/newmodule.component';
import { SupportingDocComponent } from './blockchainImpExp/imp_exp/payment/supporting-doc/supporting-doc.component';
import { PaymentsummaryComponent } from './blockchainImpExp/imp_exp/payment/paymentsummary/paymentsummary.component';
import { AccountComponent } from './blockchainImpExp/imp_exp/payment/account/account.component';
import { PaymentmodeComponent } from './blockchainImpExp/imp_exp/payment/paymentmode/paymentmode.component';
import { CustomesIntimationComponent } from './blockchainImpExp/imp_exp/customes_intimation/customes-intimation/customes-intimation.component';
import { UpdateComponent } from './blockchainImpExp/imp_exp/cancellation/update/update/update.component';
import { AutherizationRequestComponent } from './blockchainImpExp/imp_exp/cancellation/autherization-request/autherization-request/autherization-request.component';
import { SearchCaseComponent } from './blockchainImpExp/imp_exp/search_case/search-case/search-case.component';
import { AmountToWordPipe } from './blockchainImpExp/imp_exp/amount-to-word.pipe';
import { PaymentAcceptanceComponent } from './blockchainImpExp/customs_interface/custom-payment/payment-acceptance/payment-acceptance.component';
import { CashChallanComponent } from './blockchainImpExp/customs_interface/custom-payment/cash-challan/cash-challan.component';
import { AcceptChallanComponent } from './blockchainImpExp/customs_interface/custom-payment/accept-challan/accept-challan.component';
import { PaymentDailyMISComponent } from "./blockchainImpExp/customs_interface/custom-payment/payment-daily-mis/payment-daily-mis.component";
import { PaymentDailySummaryComponent } from './blockchainImpExp/customs_interface/custom-payment/payment-daily-summary/payment-daily-summary.component';
import { AmendmentComponent } from './blockchainImpExp/customs_interface/amendment/amendment/amendment.component';
import { ImportCouComponent } from "./blockchainImpExp/customs_interface/custom_obligation/import-cou/import-cou/import-cou.component";
import { ExportCouComponent } from "./blockchainImpExp/customs_interface/custom_obligation/export-cou/export-cou/export-cou.component";
import { InterfaceCustomeIntimationComponent } from './blockchainImpExp/customs_interface/customes_intimation/interface-custome-intimation/interface-custome-intimation.component';
import { AmendmentAaComponent } from './blockchainImpExp/customs_interface/amendment/amendment/amendment-aa/amendment-aa/amendment-aa.component';
import { PaymentsumComponent } from './blockchainImpExp/imp_exp/payment/paymentsum/paymentsum.component';
import { BlockTemplateComponent } from "../app/widgets/block-template/block-template.component";
import { BlockUIModule } from "ng-block-ui";
import { ManageComponent } from './blockchainImpExp/admin/manage/manage.component';
import { ManageUserComponent } from './blockchainImpExp/admin/manage-user/manage-user.component';
import { ManageSectionComponent } from './blockchainImpExp/admin/manage-section/manage-section.component';
import { AddUserComponent } from './blockchainImpExp/admin/add-user/add-user.component';
import { AddSectionComponent } from './blockchainImpExp/admin/add-section/add-section.component';
import { DepositChallanComponent } from './blockchainImpExp/customs_interface/custom-payment/deposit-challan/deposit-challan.component';
import { CancellationSummaryComponent } from './blockchainImpExp/customs_interface/cancellation/cancellation-summary/cancellation-summary.component';
import { ValidateAuthComponent } from './blockchainImpExp/customs_interface/aa_ao/validate-auth/validate-auth.component';
import { HistoryComponent } from './blockchainImpExp/imp_exp/registration/history/history.component';
import { ValidateComponent } from './blockchainImpExp/customs_interface/aa_ao/validate/validate.component';
import { ApplicationSummaryComponent } from './blockchainImpExp/customs_interface/aa_ao/application-summary/application-summary.component';
import { WelcomeuserComponent } from './blockchainImpExp/imp_exp/login_welcome/welcomeuser/welcomeuser.component';
import { CamendmentComponent } from './blockchainImpExp/imp_exp/customs-amendment/camendment/camendment.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CancelAuthComponent } from './blockchainImpExp/customs_interface/aa_ao/cancel-auth/cancel-auth.component';
import { CancelDocReturnComponent } from './blockchainImpExp/customs_interface/aa_ao/cancel-doc-return/cancel-doc-return.component';
import { CancelDocCollectComponent } from './blockchainImpExp/customs_interface/aa_ao/cancel-doc-collect/cancel-doc-collect.component';
import { UploadDocCancelComponent } from "./blockchainImpExp/imp_exp/cancellation/upload-doc-cancel/upload-doc-cancel.component";
import { CancellationDetailsComponent } from './blockchainImpExp/imp_exp/cancellation/cancellation-details/cancellation-details.component';
import { OmComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/om/om.component';
import { AuthorizationAfterExportComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/om/authorization-after-export/authorization-after-export.component';
import { LiveBondComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/om/live-bond/live-bond.component';
import { CancelledBondComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/om/cancelled-bond/cancelled-bond.component';
import { AuthSummaryComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/authSummary/auth-summary/auth-summary.component';
import { DetailAuthSummaryComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/authSummary/detail-auth-summary/detail-auth-summary.component';
import { ProcessorViewComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/processor-view/processor-view.component';
import { ScnModuleComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/processor-view/scn-module/scn-module.component';
import { RelevantInfoComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/processor-view/relevant-info/relevant-info.component';
import { ShowCauseComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/show-cause/show-cause.component';
import { ProcessletterComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/processletter/processletter.component';
import { ScnAdj1Component } from './blockchainImpExp/customs_interface/obligation-mgmt/show-cause/scn-adj1/scn-adj1.component';
import { ScnRecovery1Component } from './blockchainImpExp/customs_interface/obligation-mgmt/show-cause/scn-recovery1/scn-recovery1.component';
import { MonthRecoveryComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/show-cause/month-recovery/month-recovery.component';
import { RevenueStakeComponent } from './blockchainImpExp/customs_interface/obligation-mgmt/show-cause/revenue-stake/revenue-stake.component';
import { ScnRecoveryModuleComponent } from "./blockchainImpExp/customs_interface/obligation-mgmt/processor-view/scn-recovery-module/scn-recovery-module.component";
import { ValidetePaymentComponent } from "./blockchainImpExp/customs_interface/aa_ao/validete-payment/validete-payment.component";
import { ViewValidatePaymentComponent } from "./blockchainImpExp/customs_interface/aa_ao/view-validate-payment/view-validate-payment.component";
import { ProofPaymentComponent } from "./blockchainImpExp/imp_exp/cancellation/proof-payment/proof-payment.component";
import { AmendmentListComponent } from './blockchainImpExp/customs_interface/amendment-list/amendment-list.component';
import { MailboxComponent } from "./blockchainImpExp/customs_interface/mailbox/mailbox.component";
import { ReplyToCustomsComponent } from './blockchainImpExp/customs_interface/reply-to-customs/reply-to-customs.component';
import { BondUploadComponent } from './blockchainImpExp/imp_exp/registration/bong-bg-details/bond-upload/bond-upload.component';
import { PaymentApplicationComponent } from './blockchainImpExp/imp_exp/payment/payment-application/payment-application.component';
import { VoluntaryPaymentComponent } from './blockchainImpExp/imp_exp/payment/voluntary-payment/voluntary-payment.component';
import { AppellateComponent } from './blockchainImpExp/imp_exp/payment/appellate/appellate.component';
import { BaggageComponent } from './blockchainImpExp/imp_exp/payment/baggage/baggage.component';
import { ManualChallanComponent } from './blockchainImpExp/imp_exp/payment/manual-challan/manual-challan.component';
import { DisposalComponent } from './blockchainImpExp/imp_exp/payment/disposal/disposal.component';
import { ForeignPostOfficeComponent } from './blockchainImpExp/imp_exp/payment/foreign-post-office/foreign-post-office.component';
import { OtherPaymentComponent } from './blockchainImpExp/imp_exp/payment/other-payment/other-payment.component';
import { PaymentPreviewComponent } from './blockchainImpExp/imp_exp/payment/payment-preview/payment-preview.component';
import { PaymentReceivedSummaryComponent } from './blockchainImpExp/customs_interface/custom-payment/payment-received-summary/payment-received-summary.component';
import { PaymentReceivedDetailSummaryComponent } from './blockchainImpExp/customs_interface/custom-payment/payment-received-detail-summary/payment-received-detail-summary.component';
import { NatureofpaymentSummaryComponent } from './blockchainImpExp/customs_interface/custom-payment/natureofpayment-summary/natureofpayment-summary.component';
import { PaymentByPaymentHeadsComponent } from './blockchainImpExp/customs_interface/custom-payment/payment-by-payment-heads/payment-by-payment-heads.component';
import { GrievanceModule1Component } from './blockchainImpExp/grievance-module1/grievance-module1.component';
import { SearchfilterComponent } from './blockchainImpExp/searchfilter/searchfilter.component';
import { MisDailySummaryComponent } from './blockchainImpExp/customs_interface/custom-payment/mis-daily-summary/mis-daily-summary.component';
import { PaymentStatusComponent } from './blockchainImpExp/customs_interface/custom-payment/payment-status/payment-status.component';
import { ForgetPasswordComponent } from './blockchainImpExp/forget-password/forget-password.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ChangeAdminComponent } from './blockchainImpExp/admin/change-admin/change-admin.component';
import { TransferLongLeaveComponent } from './blockchainImpExp/admin/transfer-long-leave/transfer-long-leave.component';
import { OfficerDetailsTransferComponent } from './blockchainImpExp/admin/officer-details-transfer/officer-details-transfer.component';
import { ReassignComponent } from './blockchainImpExp/admin/reassign/reassign.component';
import { SearchCaseSummaryComponent } from './blockchainImpExp/customs_interface/search_case/search-case-summary/search-case-summary.component';
import { CasefileBlockDataComponent } from './blockchainImpExp/customs_interface/search_case/casefile-block-data/casefile-block-data.component';
import { InterceptorInterceptor } from "./interceptor.interceptor";
import { MyProfileComponent } from './blockchainImpExp/admin/my-profile/my-profile.component';
// import { RecaptchaModule } from "ng-recaptcha";
// import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
// import { ReCaptchaV3Service } from 'ng-recaptcha';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { environment } from '../environments/environment';
import { SiteMaintainaceComponent } from './site-maintaince/site-maintainace/site-maintainace.component';
import { GreenSheetComponent } from './blockchainImpExp/customs_interface/search_case/green-sheet/green-sheet.component';
import { OnlyNumberInputDirective } from './_directives/only-number-input.directive';
import { OmitSpecialCharDirective } from "./_directives/omit-special-char.directive";
import { GreenNoteSpecialCharDirective } from './_directives/green-note-special-char.directive';
import { NumbersOnlyDirective } from './_directives/numbers-only.directive';
import { EmailSpecialCharDirectiveDirective } from './_directives/email-special-char-directive.directive';
import { MobileNoSpecialCharDirectiveDirective } from './_directives/mobile-no-special-char-directive.directive';
import { AlphaNumericOnlyDirectiveDirective } from './_directives/alpha-numeric-only-directive.directive';
import { NumberAndSpecialCharDirectiveDirective } from './_directives/number-and-special-char-directive.directive';
import { CustomScreenComponent } from "./chp-dashboard/custom-screen/custom-screen.component";

import { StripTagPipe } from './blockchainImpExp/imp_exp/strip-tag.pipe';
import { PaymentDailyRegisterComponent } from './blockchainImpExp/customs_interface/custom-payment/payment-daily-register/payment-daily-register.component';
import { PaymentAcceptedChallanComponent } from './blockchainImpExp/customs_interface/custom-payment/payment-accepted-challan/payment-accepted-challan.component';
import { TransferAllFileComponent } from "./blockchainImpExp/admin/transfer-all-file/transfer-all-file.component";
import { TransferAllFileFinalFormComponent } from "./blockchainImpExp/admin/transfer-all-file-final-form/transfer-all-file-final-form.component";
import { AlphanumericwithslashDirective } from "./_directives/alphanumericwithslash.directive";

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    // HttpModule,
    // ChartModule,
    ComponentsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatButtonModule,
    MatSidenavModule,
    RouterModule,
    NgxMaterialTimepickerModule,
    AppRoutingModule,
    MatTableModule,
    MatSortModule,
    CdkTableModule,
    MatPaginatorModule,
    MatStepperModule,
    MatIconModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatInputModule,
    MatRippleModule,
    // FlexLayoutModule,
    MatToolbarModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatRadioModule,
    MatCheckboxModule,
    MatTabsModule,
    CustomInputModule,
    HttpClientModule, 
    AngularEditorModule,
    MatSnackBarModule,
    PdfViewerModule,
    // ChartsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    BlockUIModule.forRoot({
      template: BlockTemplateComponent,
    }),
    
 
  ],
  exports: [
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatInputModule,
    MatFormFieldModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSnackBarModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    AlertComponent,
    HomeComponent,
    RegistrationTaskComponent,
    CaseDetailsComponent,
    RecievedFileComponent,
    ValidationComponent,
    Validation2Component,
    CreatejobComponent,
    Createjob1Component,
    ProvidebondComponent,
    RegisterLicenseTaComponent,
    RemarksLicenseTaComponent,
    Notesheet1Component,
    NoteSheet2Component,
    NoteSheet3Component,
    EndorseComponent,
    Endorse2Component,
    Endorse3Component,
    ValidateDcComponent,
    ValidateDc2Component,
    ValidateDc3Component,
    AcceptJobComponent,
    AcceptJob2Component,
    BbgRecieptComponent,
    BbgAcceptComponent,
    BbgSubmitComponent,
    LicenseIssueComponent,
    LicenseIssue2Component,
    CreateprofileComponent,
    ApplnSummaryComponent,
    AaSummaryComponent,
    UploadDocComponent,
    AppSummaryComponent,
    EodcComponent,
    ImportComponent,
    ExportComponent,
    SignUpComponent,
    ApplnTypeRoleComponent,
    IecDetailsComponent,
    UploadAAComponent,
    BongBgDetailsComponent,
    SubmissionComponent,
    ImportOUComponent,
    AddImportOUComponent,
    ExportOUComponent,
    AddExportOUComponent,
    AddImportComponent,
    AddExportComponent,
    AaHeaderComponent,
    AaDetailsComponent,
    ExportAAComponent,
    AddExportAAComponent,
    ImpAAComponent,
    AddimpAAComponent,
    SupportingProjectSiteComponent,
    AddProjectSiteComponent,
    Step1Component,
    Step2Component,
    EditprofileComponent,
    RegisterInitiateComponent,
    JobCreationComponent,
    // WelcomescreenComponent,
    NewmoduleComponent,
    SupportingDocComponent,
    PaymentsummaryComponent,
    AccountComponent,
    PaymentmodeComponent,
    CustomesIntimationComponent,
    AmountToWordPipe,
    PaymentAcceptanceComponent,
    CashChallanComponent,
    AcceptChallanComponent,
    PaymentDailyMISComponent,
    UpdateComponent,
    AutherizationRequestComponent,
    SearchCaseComponent,
    PaymentDailySummaryComponent,
    AmendmentComponent,
    ImportCouComponent,
    ExportCouComponent,
    InterfaceCustomeIntimationComponent,
    AmendmentAaComponent,
    PaymentsumComponent,
    PaymentsumComponent,
    ManageComponent,
    ManageUserComponent,
    ManageSectionComponent,
    AddUserComponent,
    AddSectionComponent,
    DepositChallanComponent,
    CancellationSummaryComponent,
    ValidateAuthComponent,
    // HistoryComponent,
    ValidateComponent,
    ApplicationSummaryComponent,
    WelcomeuserComponent,
    CamendmentComponent,
    CancelAuthComponent,
    CancelDocReturnComponent,
    CancelDocCollectComponent,
    UploadDocCancelComponent,
    CancellationDetailsComponent,
    
    OmComponent,
    AuthorizationAfterExportComponent,
    LiveBondComponent,
    // CancelledBondComponent,
    AuthSummaryComponent,
    DetailAuthSummaryComponent,
    ProcessorViewComponent,
    ScnModuleComponent,
    RelevantInfoComponent,
    // ShowCauseComponent,
    ProcessletterComponent,
    // ScnAdj1Component,
    ScnRecoveryModuleComponent,
    // ScnRecovery1Component,
    MonthRecoveryComponent,
    RevenueStakeComponent,
    // ValidetePaymentComponent,
    ViewValidatePaymentComponent,
    ProofPaymentComponent,
    AmendmentListComponent,
    MailboxComponent,
    ReplyToCustomsComponent,
    BondUploadComponent,
    PaymentApplicationComponent,
    VoluntaryPaymentComponent,
    AppellateComponent,
    BaggageComponent,
    ManualChallanComponent,
    DisposalComponent,
    ForeignPostOfficeComponent,
    OtherPaymentComponent,
    PaymentPreviewComponent,
    PaymentReceivedSummaryComponent,
    PaymentReceivedDetailSummaryComponent,
    NatureofpaymentSummaryComponent,
    PaymentByPaymentHeadsComponent,
    GrievanceModule1Component,
    SearchfilterComponent,
    MisDailySummaryComponent,
    PaymentStatusComponent,
    ForgetPasswordComponent,
    ChangeAdminComponent,
    TransferLongLeaveComponent,
    OfficerDetailsTransferComponent,
    TransferAllFileComponent,
    TransferAllFileFinalFormComponent,
    ReassignComponent,
    SearchCaseSummaryComponent,
    CasefileBlockDataComponent,
    MyProfileComponent,
    SiteMaintainaceComponent,
    GreenSheetComponent,
    OnlyNumberInputDirective,
    OmitSpecialCharDirective,
    GreenNoteSpecialCharDirective,
    NumbersOnlyDirective,
    EmailSpecialCharDirectiveDirective,
    MobileNoSpecialCharDirectiveDirective,
    AlphaNumericOnlyDirectiveDirective,
    NumberAndSpecialCharDirectiveDirective,
    CustomScreenComponent ,
    StripTagPipe,
    PaymentDailyRegisterComponent,
    PaymentAcceptedChallanComponent,
    AlphanumericwithslashDirective

  

  ],
  providers: [
    AppService,
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: "6LcdoksmAAAAAK3O2pgoa4yAhkQpAg1W6O2JV0Hs",
      } as RecaptchaSettings,
    },
    // ReCaptchaV3Service,
    // { provide: RECAPTCHA_V3_SITE_KEY, useValue: "6LcdoksmAAAAAK3O2pgoa4yAhkQpAg1W6O2JV0Hs" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
