import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService, ImitationDetailsQuery } from "app/blockchainImpExp/blockchain.service";
import { Sort } from "app/paging/page";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Month } from "../../custom-payment/payment-daily-summary/month";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { DateAdapter } from "@angular/material/core";
declare var $:any;
export interface Status {
  value: string;
  viewValue: string;
}

export interface MonthType {
  value: string;
  viewValue: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface calendarView {
  value: string;
  viewValue: string;
}

export interface Imitation {
  id: number;
  imp_exp_name: string;
  iec_code: string;
  file_no: string;
  application_type: string;
  application_no: string;
  license_issue_date: string;
  installation_certificate:string;
  midterm_certificate:string;
  dgft_application_date: string;
  dgft_application_doc_path: string;
  customs_intimation_date: string;
  created_date:string;
}


@Component({
  selector: 'app-interface-custome-intimation',
  templateUrl: './interface-custome-intimation.component.html',
  styleUrls: ['./interface-custome-intimation.component.css']
})
export class InterfaceCustomeIntimationComponent implements OnInit {

  selectedLevel = "PENDING";
  pipe = new DatePipe("en-US");
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
  allStatus: Status[] = [
    {value: 'incommingDate', viewValue: 'Incoming Date'},
    {value: 'applicationType', viewValue: 'Application Type'},
    {value: 'fileNo', viewValue: 'File No'},
  ];
  selectedFilter: string;
  filterData: any;
  filterByDataReg: Status[] = [
    { value: 'IMP EXP Name', viewValue: 'IMP EXP Name' },
    { value: 'IEC Code', viewValue: 'IEC Code' },
    { value: 'Application No', viewValue: 'Application No' },
    { value: 'License Issue Date', viewValue: 'License Issue Date' },
    { value: 'Customs Intimation Date', viewValue: 'Customs Intimation Date' },
    { value: 'Application Type', viewValue: 'Application Type' },
  
  
  ]
  selectedView: any;
  allCalendarView: calendarView[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Weekly", viewValue: "Weekly" },
    { value: "Monthly", viewValue: "Monthly" },
    { value: "Date Range", viewValue: "Date Range" },
  ];
  licenseStatus: Status[] = [
    { value: "AA", viewValue: "AA" },
    { value: "EPCG", viewValue: "EPCG" },
  ];
  date: any;
  modifiedCurrentDate: any;
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  isButtonVisible = false;
  first_day_of_month: any;
  last_day_of_month: any;
  currentDate = new Date();
  firstDay: Date;
  lastDay: Date;
  events: string[] = [];
  events1: string[] = [];
  datepick = true;
  monthselectedValue: any;
  public selectedDate = new UntypedFormControl(new Date());
  public selectedDateStart = new UntypedFormControl(new Date());
  public selectedDateEnd = new UntypedFormControl(new Date());
  public months: Month[];
  public selectedMonth: Month;
  isMonthly: boolean;
  isDay: boolean;
  isWeekly: boolean;
  isRange: boolean;
  showTable: boolean;
  sendInput: any;
  dateData: any;
  startDate: any;
  endDate: any;
  offsetData: any;
  pageSet: number;
  pageSizeChange: number;
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;

  updateForm = this.formBuilder.group({
    name: [""],
    section: [""],
    designation: [""]
  
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
 
  selectedFile: File;
  removeRowNo: any;
  url: string | ArrayBuffer;

   dataSource:Imitation[] = [];

  displayedColumns = [
    "id",
    "imp_exp_name",
    "iec_code",
    "file_no",
    "application_type",
    "application_no",
    "license_issue_date",
    "installation_certificate",
    "midterm_certificate",
    // "dgft_application_doc_path",
    "customs_intimation_date"
  ];
  summaryData: PaginatedDataSource<Imitation, ImitationDetailsQuery>;
  initialSort: Sort<Imitation> = { property: "created_date", order: "desc" };
  statusClass =  'active';
  statusClass1 =  'not-active';
  isViewCaseFiles:boolean=true;
  isUploadEodcInfo:boolean; 
  tabAction: string;
  pendingApp: number;
  totalEntries: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('en-GB');
    this.offsetData = JSON.parse(localStorage.getItem("offset"));
    this.pageSet = this.offsetData - 1;
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.loadMonths();
    if (this.offsetData == null) {
      this.offsetData = 1
    }
    this.pageSizeChange = 10;
    if(this.selectedLevel=='PENDING'){
      this.pendingApp = 1;
    }else{
      this.pendingApp = 0;
    }
    
    let input = {
      user_id: this.currentUser.obj.user_id,
      offset: this.offsetData,
      entries_per_page:this.pageSizeChange,
      pending_app:this.pendingApp
    }
    this.getAllCustomsIntimationEodcRequest(input);
  }

  openTab(e,action){
    if(action == 'viewCaseFiles'){
      this.tabAction = 'viewCaseFiles'
      this.isViewCaseFiles = true
      this.isUploadEodcInfo = false
      this.statusClass = 'active';
      this.statusClass1 =  'not-active';
    } else if(action == 'uploadEodcInfo'){
      this.tabAction = 'uploadEodcInfo'
      this.isUploadEodcInfo = true
      this.isViewCaseFiles = false
      this.statusClass1 = 'active';
      this.statusClass =  'not-active';
      
    }
  }
  getNext(event: PageEvent) {
    let offset = event.pageIndex;
    this.pageSizeChange = event.pageSize;
    console.log(event);
    if ((this.selectedFilter != null || this.selectedFilter != undefined) && this.filterData != null) {

      this.pageSet = this.offsetData - 1;
      let filter = JSON.parse(localStorage.getItem("filter"));
      if (event.previousPageIndex == 0) {
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        filter.offset = offset + 1;
        filter.entries_per_page = this.pageSizeChange;
        this.getAllCustomsIntimationEodcRequest(filter);
      } else {
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        filter.offset = offset + 1;
        filter.entries_per_page = this.pageSizeChange;
        this.getAllCustomsIntimationEodcRequest(filter);
      }
      
    } else {
      let filter = {
        user_id: this.currentUser.obj.user_id,
        offset: offset + 1,
        entries_per_page:this.pageSizeChange,
        pending_app:this.pendingApp
      }
      if (event.previousPageIndex == 0) {
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        this.getAllCustomsIntimationEodcRequest(filter);
      } else {
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        this.getAllCustomsIntimationEodcRequest(filter);
      }
    }

  }
  caseDetails(data){

  }
  onChangeLevel(event){
    console.log(event.value);

    if(event.value=='PENDING'){
      this.pendingApp = 1;
    }else{
      this.pendingApp = 0;
    }
  }
  getAllCustomsIntimationEodcRequest(input){
   this.blockchainService.getAllCustomsIntimationEodcRequest(input).subscribe(
     (resp) => {
       console.log(resp.body.obj);
        this.dataSource = resp.body.obj.ci;
        this.totalEntries = resp.body.obj.total_entries;
        if (this.dataSource != null) {
          var summaryList = this.dataSource;
          this.summaryData = new PaginatedDataSource<Imitation, ImitationDetailsQuery>(
            (request, query) =>
              this.blockchainService.pageImitation(request, query, summaryList),
            this.initialSort,
            { search: "", data: undefined },
           
          );
        }
     });
  }
  onFilterSelected(event, status) {
    this.filterData = '';
    this.isMonthly = false;
    this.isDay = false;
    this.isWeekly = false;
    this.isRange = false;
    if (event.isUserInput) {
      this.selectedFilter = status;
    }
  }
  resetFilters() {

    this.selectedFilter = '';
    this.selectedFilter = null;
    this.filterData = '';
    this.isMonthly = false;
    this.isDay = false;
    this.isWeekly = false;
    this.isRange = false;
    this.dataSource = [];
    this.pageSet = 0;
    this.offsetData = 1;
    this.pageSizeChange = 10;
    localStorage.setItem("offset", JSON.stringify(this.offsetData));
    this.ngOnInit();
  }
  changeConfirmationStatus(status) {
    this.filterData = status;
  }

  getFilterData(data, filter) {
    this.blockUI.start();
    this.dataSource = [];
    console.log(this.pageSizeChange);
    this.pageSet = 0;
    this.offsetData = 1
    localStorage.setItem("offset", JSON.stringify(this.offsetData));
    if (filter == 'IMP EXP Name') {
      this.sendInput = {
        imp_exp_name: data,
        user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        pending_app:this.pendingApp
      }
    }
    else if (filter == 'IEC Code') {
      this.sendInput = {
        iec_code: data,
        user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        pending_app:this.pendingApp
      }
    }
   else if (filter == 'Application No') {
      this.sendInput = {
        application_no: data,
        user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        pending_app:this.pendingApp
      }
    }else if (this.selectedFilter == 'Application Type') {
      if (this.filterData == "AA") {
        this.sendInput = {
          application_type: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
      else if (this.filterData == "EPCG") {
        this.sendInput = {
          application_type: this.filterData,
          user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          pending_app:this.pendingApp
        }
      }
    } 
    else if (filter == 'License Issue Date') {
      this.sendInput = {
        start_date: this.startDate,
        end_date: this.endDate,
        license_issue_date: this.startDate,
        user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        pending_app:this.pendingApp
      }
    }
    else if (filter == 'Customs Intimation Date') {
      this.sendInput = {
        start_date: this.startDate,
        end_date: this.endDate,
        customs_intimation_date: this.startDate,
        user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        pending_app:this.pendingApp
      }
    }
    setTimeout(() => {
      this.blockUI.stop();
    }, 500);
      localStorage.setItem('filter', JSON.stringify(this.sendInput));
      this.getAllCustomsIntimationEodcRequest(this.sendInput);
   
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate,
      this.endDate = this.date ? this.date : this.todayDate
  }
  dateStartSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate;
    console.log(this.startDate)
  }
  dateEndSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.endDate = this.date ? this.date : this.todayDate;
    console.log(this.endDate)
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  loadMonths() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months = [];
    for (let i = 0; i < 12; i++) {

      // console.log("MOnth : ", month);
      if (month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      let name = this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = { "name": name, "value": month + 1, "start": start + " 00:00:00", "end": end + " 23:59:59" };
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
  }

  monthly(value, event) {
    if (event.isUserInput) {
      this.showTable = true;
      this.selectedMonth = value ? value : this.selectedMonth;
      this.startDate = this.pipe.transform(this.selectedMonth.start, "yyyy-MM-dd", "en-US");
      this.endDate = this.pipe.transform(this.selectedMonth.end, "yyyy-MM-dd", "en-US");
    }
  }

  selectCalendarView(event, value) {
    if (event.isUserInput==true) {
      if (value == "Monthly") {
        this.isMonthly = true;
        this.isDay = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Weekly") {
        this.isWeekly = true;
        this.isDay = false;
        this.isMonthly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Day") {
        this.isDay = true;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else {
        this.isDay = false;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = true;
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  compareMonths(i1, i2) {
    // return i2 && i1 && i1.value === i2.value;
    return i1 && i2 && i1.value === i2.value;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    if (this.isMonthly) {
      if (type === "change") {
        this.showTable = true;

        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        //const start =
        let date = new Date(this.today);
        //date = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        this.first_day_of_month = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        let currentMonth = date.getMonth();
        this.last_day_of_month = new Date(
          date.getFullYear(),
          currentMonth,
          this.daysInMonth(currentMonth + 1, date.getFullYear())
        );
        // let input = {
        //   start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        this.startDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        this.endDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);
        // this.dateData = input;
      }
    } else {
      if (type === "change") {
        this.showTable = true;
        this.today = event.value.getTime();

        const now = this.today;

        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        const start = new Date(this.today + 6 * 24 * 60 * 60 * 1000).getTime();
        const myStartDate = this.pipe.transform(start, "yyyy-MM-dd", "en-US");
        this.last6 = start;
        // let input = {
        //   end_date : this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   start_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        // this.dateData = input;
        this.startDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        this.endDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);

        if (this.today == this.now) {
          this.isButtonVisible = false;
        } else {

          this.isButtonVisible = true;
        }
      }
    }
  }
  getXLSTemplate(){
    window.open(this.blockchainService.xlDownload, "_blank");
  }

 
  addImportOU(action){
    localStorage.setItem('action',action)
  
  }

  navigateExport() {
    this.router.navigate(["/export-ou"]);
  }


  removeRow() {
    let no = this.removeRowNo;
    this.dataSource = this.dataSource.filter((u) => u.id !== no);
  }

  openModal1(srNo) {
    console.log(srNo,'element no')
    this.removeRowNo = srNo;
    const buttonModal1 = document.getElementById("openModalButton1");
    console.log("buttonModal", buttonModal1);
    buttonModal1.click();
  }

  onFileSelected(event) {
    console.log("clicked");
    console.log("clicked1", event);
    this.selectedFile = <File>event.target.files[0];
    if(this.selectedFile.type=='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'||this.selectedFile.type=='application/vnd.ms-excel'){
      console.log(this.selectedFile.type);
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); // read file as data url

    reader.onload = (event) => {
      // called once readAsDataURL is completed
      this.url = event.target.result;
    };
    this.onUpload();
  }
  else{
    console.log(this.selectedFile.type);
    this.showNotification(
      "bottom",
      "right",
      "success",
      "File Format Invalid",
      "announcement"
    );
  }
  }

  onUpload() {
    const fd = new FormData();
    console.log(this.selectedFile, "selectedFile");
    fd.append("file", this.selectedFile, this.selectedFile.name);
    // this.submitRequest()
    this.save(this.selectedFile);
  }

  cancel() {
    this.router.navigate(["/applnSummary"]);
  }


  next() {
    this.router.navigate(["/amendmentList"]);
  }

  back(){
    this.router.navigate(["/export-ou"])
  }

  save(file){
    localStorage.setItem('saveFile',JSON.stringify(this.selectedFile));
    let input = {
      file:file,
    }
    console.log(input,'input')
    this.blockchainService.uploadCustomsIntimationRedemptionXls(input).subscribe({next:(resp:any) => {
      console.log(resp);
      console.log(resp.status);
      if(resp.status == 200){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "File Uploaded Successfully",
          "announcement"
        );
      }
      else {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.message,
          "announcement"
        );
      }
     }})
    
  }
  statusFn(e, status) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}

