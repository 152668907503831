import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
// import { decimalDigest } from "@angular/compiler/src/i18n/digest";
// import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { Component, ElementRef, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  UntypedFormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";


@Component({
  selector: 'app-paymentsum',
  templateUrl: './paymentsum.component.html',
  styleUrls: ['./paymentsum.component.css']
})
export class PaymentsumComponent implements OnInit {
  private element: any;
  customMessage: any;
  showMessage: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    private el: ElementRef
    ) {
      this.element = el.nativeElement;
      if(this.data == 'sucessChallan'){
        this.showMessage = true;
      } else{
        this.customMessage = data

      }
   }

  ngOnInit(): void {
    this.openModal()
  }

  openModal(){
    this.element.style.display = 'block';
  }
}
