import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { UntypedFormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { MatTableDataSource } from '@angular/material/table';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService, PaymentAcceptanceQuery } from "app/blockchainImpExp/blockchain.service";
import { Month } from "../customs_interface/custom-payment/payment-daily-summary/month";

var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface MonthType {
  value: string;
  viewValue: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



export interface calendarView {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-searchfilter',
  templateUrl: './searchfilter.component.html',
  styleUrls: ['./searchfilter.component.css']
})
export class SearchfilterComponent implements OnInit {
  selectedFilter:string = "";
  filterData:any;
  filterByData:Status[]=[
    {value: 'IEC Code', viewValue: 'IEC Code'},
    {value: 'Authorization No', viewValue: 'Authorization No'},
    {value: 'Mobile No', viewValue: 'Mobile No'},
    {value: 'Authorization Type', viewValue: 'Authorization Type'},
    {value: 'Challan Status', viewValue: 'Challan Status'},
    {value: 'Challan Application Date', viewValue: 'Challan Application Date'},
  ]
  confirmationStatus:Status[]=[
    { value: "ACCEPT", viewValue: "ACCEPT" },
    { value: "ACCEPTED", viewValue: "ACCEPTED" },
    { value: "CONFIRM", viewValue: "CONFIRM" },
    { value: "CONFIRMED", viewValue: "CONFIRMED" },
    { value: "ACCEPTED & CONFIRM", viewValue: "ACCEPTED & CONFIRMED" },
    { value: "ACCEPTED & CONFIRMED", viewValue: "ACCEPTED & CONFIRMED" },

  ];
  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  licenseStatus: Status[] = [
    { value: "AA", viewValue: "AA" },
    { value: "EPCG", viewValue: "EPCG" },
  ];
  selectedView:any;

  allCalendarView: calendarView[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Weekly", viewValue: "Weekly" },
    { value: "Monthly", viewValue: "Monthly" },
  ];
  date: any;
  modifiedCurrentDate: any;
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  isButtonVisible = false;
  first_day_of_month: any;
  last_day_of_month: any;
  currentDate = new Date();
  firstDay: Date | any;
  lastDay: Date | any;

  events: string[] = [];
  events1: string[] = [];
  datepick = true;
  monthselectedValue: any;
  public selectedDate =  new  UntypedFormControl(new  Date());
  public months : Month[] =[];
  public selectedMonth :Month | any;
  isMonthly: boolean = false;
  isDay: boolean = true;
  isWeekly: boolean = false;
  showTable: boolean= false;
  constructor() { }

  ngOnInit(): void {
  }
  onFilterSelected(event: { isUserInput: any; },status: string){
    if(event.isUserInput){
      this.selectedFilter = status;
      console.log(status)
      console.log(this.selectedFilter)
    }
 
  }
  getFilterData(data: any){
    console.log(data)
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    let input = {
      start_date : this.date ? this.date : this.todayDate,
      end_date : this.date ? this.date : this.todayDate
    }
    this.showTable = true;
    // this.getPaymentDailySummaryMis(input);
  }

  formatDate(date: string | number | Date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  loadMonths() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months=[];
    for(let i=0; i<12; i++) {

      console.log("MOnth : ",month);
      if(month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year,month,1);
      let lastday = new Date(year,month+1,0);
      let name=this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = {"name" : name, "value": month + 1, "start": start + " 00:00:00", "end" : end+" 23:59:59"};
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
    
  }

  monthly(value: Month,event: { isUserInput: any; }){
    if(event.isUserInput){
      this.showTable = true;
      this.selectedMonth = value ? value : this.selectedMonth;
      let input = {
        start_date: this.pipe.transform(this.selectedMonth.start, "yyyy-MM-dd", "en-US"),
        end_date: this.pipe.transform(this.selectedMonth.end, "yyyy-MM-dd", "en-US"),
      }
      // this.getPaymentDailySummaryMis(input);

    }
  }

  selectCalendarView(event: { isUserInput: any; }, value: string) {
    if (event.isUserInput) {
      if (value == "Monthly") {
        this.isMonthly = true;
        this.isDay = false;
        this.isWeekly = false;
        this.showTable = false;
      } else if (value == "Weekly") {
        this.isWeekly = true;
        this.isDay = false;
        this.isMonthly = false;
        this.showTable = false;
      } else {
        this.isDay = true;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
      }
    }
  }

  daysInMonth(month: number, year: number) {
    return new Date(year, month, 0).getDate();
  }

  compareMonths(i1: { value: any; },i2: { value: any; }) {
    // return i2 && i1 && i1.value === i2.value;
    return i1 && i2 && i1.value === i2.value;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    if (this.isMonthly) {
      if (type === "change") {
        this.showTable = true;
        let eventValue1 : any = event.value
        this.today = eventValue1.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        //const start =
        let date = new Date(this.today);
        //date = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        this.first_day_of_month = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        let currentMonth = date.getMonth();
        this.last_day_of_month = new Date(
          date.getFullYear(),
          currentMonth,
          this.daysInMonth(currentMonth + 1, date.getFullYear())
        );
        let input = {
          start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
          end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        }
        // this.getPaymentDailySummaryMis(input);

      }
    } else {
      if (type === "change") {
        this.showTable = true;
        let eventValue :any = event?.value 
        this.today = eventValue.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        const start = new Date(this.today + 6 * 24 * 60 * 60 * 1000).getTime();
        const myStartDate = this.pipe.transform(start, "yyyy-MM-dd", "en-US");
        this.last6 = start;
        let input = {
          end_date : this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
          start_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        }
        
        // this.getPaymentDailySummaryMis(input);

        if (this.today == this.now) {
          this.isButtonVisible = false;
        } else {
      
          this.isButtonVisible = true;
        }
      }
    }
  }

}
