import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit,ViewChild } from "@angular/core";
import { FormControl, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService, ImportAAQuery, OneTwoQuery } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { Month } from "app/blockchainImpExp/customs_interface/custom-payment/payment-daily-summary/month";
import { MatPaginator, PageEvent } from "@angular/material/paginator";

declare var $: any;


export interface OneTwo {
  application_id: number,
  auth_number: string,
  evd: string,
  bond_amount_in_inr: string,
  iec_number: string,
  iec_holder_name:string;
  bg_amount: string,
  date_of_last_letter: string,
  number_of_letter_sent: string,
  scn_no: string,
  scn_date: string,
  no_of_days_overdue: string
}
export interface Status {
  value: string;
  viewValue: string;
}
export interface calendarView {
  value: string;
  viewValue: string;
}
export interface Registration {
  systemId: number;
  imp_exp_name: string;
  iec_code: string;
  file_no: string;
  application_type: string;
  authorization_type: string;
  authorization_no: string;
  stage: string;
  pending_level: string;
  officer_name: string;
  incoming_date: string;
  viewCaseDetails: string;
  application_status: string;
  completion_date: string;

}
@Component({
  selector: 'app-authorization-after-export',
  templateUrl: './authorization-after-export.component.html',
  styleUrls: ['./authorization-after-export.component.css']
})

export class AuthorizationAfterExportComponent implements OnInit {
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  initialSort: Sort<OneTwo> = { property: "application_id", order: "asc" };

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  @ViewChild(MatPaginator, { static: false }) paginator1: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) paginator2: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) paginator3: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) paginator4: MatPaginator;
  @ViewChild(MatPaginator, { static: false }) paginator5: MatPaginator;
  filterData: any;
  filterByDataOneToTwoMonth: Status[] = [
    { value: 'IEC No', viewValue: 'IEC No' },
    { value: 'IEC Holder Name', viewValue: 'IEC Holder Name' },
    { value: 'AA No', viewValue: 'AA No' },
    { value: 'Export Validity Date', viewValue: 'Export Validity Date' },
    {value:'No Of Days OverDue',viewValue:'No Of Days OverDue'},
    { value: 'Bond Amount', viewValue: 'Bond Amount' },
    { value: 'BG Amount', viewValue: 'BG Amount' },
    { value: 'Date Of Last Letter', viewValue: 'Date Of Last Letter' },
    { value: 'No Of Last Letters Sent', viewValue: 'No Of Last Letters Sent' },
    { value: 'SCN No', viewValue: 'SCN No' },
    // { value: 'Process', viewValue: 'Process' },
  ]
  filterByDataBeforeExportValidityPeriod: Status[] = [
    { value: 'IEC No', viewValue: 'IEC No' },
    { value: 'IEC Holder Name', viewValue: 'IEC Holder Name' },
    { value: 'AA No', viewValue: 'AA No' },
    { value: 'Export Validity Date', viewValue: 'Export Validity Date' },
    // {value:'No Of Days OverDue',viewValue:'No Of Days OverDue'},
    { value: 'Bond Amount', viewValue: 'Bond Amount' },
    { value: 'BG Amount', viewValue: 'BG Amount' },
    { value: 'Date Of Last Letter', viewValue: 'Date Of Last Letter' },
    { value: 'No Of Last Letters Sent', viewValue: 'No Of Last Letters Sent' },
    { value: 'SCN No', viewValue: 'SCN No' },
    // { value: 'Process', viewValue: 'Process' },
  ]
  displayedColumns = [
    "sr_no",
    "iec_number",
    "iec_holder_name",
    "auth_number",
    "evd",
    "no_of_days_overdue",
    "bond_amount_in_inr",
    "bg_amount",
    "date_of_last_letter",
    "number_of_letter_sent",
    "scn_no",
    "process",
  ];

  displayedColumns1 = [
    "sr_no",
    "iec_number",
    "iec_holder_name",
    "auth_number",
    "evd",
    "no_of_days_overdue",
    "bond_amount_in_inr",
    "bg_amount",
    "date_of_last_letter",
    "number_of_letter_sent",
    "scn_no",
    "process",
  ];

  displayedColumns2 = [
    "sr_no",
    "iec_number",
    "iec_holder_name",
    "auth_number",
    "evd",
    "no_of_days_overdue",
    "bond_amount_in_inr",
    "bg_amount",
    "date_of_last_letter",
    "number_of_letter_sent",
    "scn_no",
    "process",
  ];

  displayedColumns3 = [
    "sr_no",
    "iec_number",
    "iec_holder_name",
    "auth_number",
    "evd",
    "no_of_days_overdue",
    "bond_amount_in_inr",
    "bg_amount",
    "date_of_last_letter",
    "number_of_letter_sent",
    "scn_no",
    "process",
  ];

  displayedColumns4 = [
    "sr_no",
    "iec_number",
    "iec_holder_name",
    "auth_number",
    "evd",
    // "no_of_days_overdue",
    "bond_amount_in_inr",
    "bg_amount",
    "date_of_last_letter",
    "number_of_letter_sent",
    "scn_no",
    "process",
  ];

  noOfAuth: string;
  oneTwoResp: any;
  dataOneTwoResp:[];
  selectedTableDataforDownload: any=[];
  date: string;
  twoSixResp: PaginatedDataSource<OneTwo, OneTwoQuery>;
  datatwoSixResp:[];
  pendingApp: number;
  six24Resp: any;
  datasix24Resp:[];
  more2Resp: PaginatedDataSource<OneTwo, OneTwoQuery>;
  datamore2Resp:[];
  beforeValidityResp: any;
  databeforeValidityResp: [];
  updatedMore2Resp: any=[];
  dataBeforeValidityResp:[];
  selectedView: any;

  allCalendarView: calendarView[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Weekly", viewValue: "Weekly" },
    { value: "Monthly", viewValue: "Monthly" },
    { value: "Date Range", viewValue: "Date Range" },
  ];

  allNoOfDaysView: calendarView[] = [
    { value: "greater", viewValue: "Greater" },
    { value: "smaller", viewValue: "Smaller" },
  ];

  modifiedCurrentDate: any;
  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  isButtonVisible = false;
  first_day_of_month: any;
  last_day_of_month: any;
  currentDate = new Date();
  firstDay: Date;
  lastDay: Date;
  selectedFilter: string;
  events: string[] = [];
  events1: string[] = [];
  datepick = true;
  monthselectedValue: any;
  public selectedDate = new FormControl(new Date());
  public selectedDateStart = new FormControl(new Date());
  public selectedDateEnd = new FormControl(new Date());
  public months: Month[];
  public selectedMonth: Month;
  isMonthly: boolean;
  isNoOfDays:boolean;
  isDay: boolean;
  isWeekly: boolean;
  isRange: boolean;
  showTable: boolean;
  sendInput: any;
  dateData: any;
  startDate: any;
  endDate: any;
  offsetData: any;
  pageSet: number;
  pageSizeChange: number;
  currentUser: any;
  role: any;
  totalEntries;
  dataSource: OneTwo[] = [];
  loggedUserLevel: any;
  Process: any;
  user: any;
  oneTwoRespLength: any;
  maxDate = new Date(2050, 12, 31);
  applicationType: string;
  noOfDaysValue: any;
  downloadOneToMonthFilterData: any;
  oneTwoRespFilteredData: any;
  downloadOneTwoResp: any;
  downloadOneTwoRespFilteredData: any;
  downloadTwoSixResp: any;
  downloadTwoSixRespFilteredData: any;
  downloadSix24RespFilteredData: any;
  downloadSix24Resp: any;
  download2YrRespFilteredData: any;
  download2YrResp: any;
  downloadBeforeValidityRespFilteredData: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { 
    this.offsetData = JSON.parse(localStorage.getItem("offset"));
    this.pageSet = this.offsetData - 1;
    this.pageSizeChange = JSON.parse(localStorage.getItem("pageSize"));
    
  }
  loader:boolean;
  loader1:boolean;
  loader2:boolean;
  loader3:boolean;
  loader4:boolean;
  loader5:boolean;
  hideColumn:boolean=false;
  ngOnInit(): void {
    // this.date = this.todayDate;
    // this.date = "2022-08-23"
    localStorage.removeItem('filter');
    localStorage.removeItem('redirectedpage');
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.loggedUserLevel=this.currentUser.obj.level;
    if(this.loggedUserLevel=='L3'){
      this.hideColumn=true;
    }
    else{
      this.hideColumn=false;
    }
    console.log("hiddenColumn",this.hideColumn)
    console.log(this.currentUser.obj.level);
    this.role = this.currentUser.obj.user_type_id;
    this.user = this.currentUser.obj.user_id;
    this.applicationType = localStorage.getItem('applicationType');
    this.date = localStorage.getItem("ObMDate");
    this.noOfAuth = localStorage.getItem('authAfterExp');
    this.pageSizeChange=10;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    if (this.offsetData == null) {
      this.offsetData = 1
    }
    if(this.noOfAuth == '1'){
      this.loader1=true;
      this.processType(this.process, this.offsetData);
      // this.getAuthorizationAfterValidityOneTwoMonth(this.user,this.offsetData,this.pageSizeChange);
    } 
    else if(this.noOfAuth == '2'){
      console.log(this.pageSet,"pageSet");
      this.loader2=true;
      this.processType(this.process, this.offsetData);
      // this.getAuthorizationAfterValidityTwoSixMonth();
    }
    else if(this.noOfAuth == '3'){
      this.loader3=true;
      this.processType(this.process, this.offsetData);
      // this.getAuthorizationAfterValiditySix24Month();
    }
    else if(this.noOfAuth == '4'){
      this.loader4=true;
      this.processType(this.process, this.offsetData);
      // this.getAuthorizationAfterValidityMoreThan2Years();
    } 
    else if(this.noOfAuth == '5'){
      this.loader5=true;
      this.processType(this.process, this.offsetData);
      // this.getAuthorizationBeforeValidity();
    }
    else{}
    this.pageSet = 0;

  }

  getNextOneTwoMonth(event: PageEvent) {
    let offset = event.pageIndex;
    this.pageSizeChange = event.pageSize;
   
    if ((this.selectedFilter != null || this.selectedFilter != undefined) && this.filterData != null) {
      console.log(this.pageSizeChange,"1")
      this.pageSet = this.offsetData - 1;
      console.log(this.pageSet,"pageSet");
      let filter = JSON.parse(localStorage.getItem("filter"));
      if (event.previousPageIndex == 0) {
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        filter.offset = offset + 1;
        filter.entries_per_page = this.pageSizeChange;
        if(this.noOfAuth == '1'){
          // filter[0].entries_per_page=1147483647;
        this.getListByOneToTwoMonthByFilter(filter);
        }
        else if(this.noOfAuth == '2'){
          console.log("21");
          this.getAuthorizationAfterValidityTwoSixMonthByFilter(filter);
        }
        else if(this.noOfAuth == '3'){
          console.log("31");
           this.getAuthorizationAfterValiditySix24MonthByFilter(filter);
        }
        else if(this.noOfAuth == '4'){
          console.log("31");
           this.getAuthorizationAfterValidityMoreThan2YearsByFilter(filter);
        }
        else if(this.noOfAuth == '5'){
          console.log("31");
          this.getAuthorizationBeforeValidityFilter(filter);
        }
      } else {
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        filter.offset = offset + 1;
        filter.entries_per_page = this.pageSizeChange;
        if(this.noOfAuth == '1'){
          // filter[0].entries_per_page=1147483647;
          this.getListByOneToTwoMonthByFilter(filter);
          }
          else if(this.noOfAuth == '2'){
            console.log("22");
            this.getAuthorizationAfterValidityTwoSixMonthByFilter(filter);          
          }
          else if(this.noOfAuth == '3'){
            console.log("32");
             this.getAuthorizationAfterValiditySix24MonthByFilter(filter);          
          }
          else if(this.noOfAuth == '4'){
            console.log("42");
              this.getAuthorizationAfterValidityMoreThan2YearsByFilter(filter);          
          }
          else if(this.noOfAuth == '5'){
            console.log("42");
             this.getAuthorizationBeforeValidityFilter(filter);          
          }

      }
      
      
      
    } else {
     console.log(this.pageSizeChange,"2");
      if (event.previousPageIndex == 0) {
        console.log("if");
        if(this.noOfAuth == '1'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        this.getAuthorizationAfterValidityOneTwoMonth(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)
        }
        else if(this.noOfAuth == '2'){
          console.log("23");
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
          this.getAuthorizationAfterValidityTwoSixMonth(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)
        }
        else if(this.noOfAuth == '3'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationAfterValiditySix24Month(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)
        }
        else if(this.noOfAuth == '4'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationAfterValidityMoreThan2Years(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)
        }
        else if(this.noOfAuth == '5'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationBeforeValidity(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)
        }
      } else {
        console.log("else");
        if(this.noOfAuth == '1'){
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        this.getAuthorizationAfterValidityOneTwoMonth(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)
        }
        else if(this.noOfAuth == '2'){
          console.log("24");
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
          this.getAuthorizationAfterValidityTwoSixMonth(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)
        }
        else if(this.noOfAuth == '3'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAuthorizationAfterValiditySix24Month(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)
        }
        else if(this.noOfAuth == '4'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
           this.getAuthorizationAfterValidityMoreThan2Years(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)
        }
        else if(this.noOfAuth == '5'){
          localStorage.setItem("offset", JSON.stringify(offset + 1));
            this.getAuthorizationBeforeValidity(this.currentUser.obj.user_id, offset + 1,this.pageSizeChange)
        }
      }
    }

  }
  onFilterSelected(event, status) {
    console.log(event);
    this.filterData = '';
    this.selectedDate=null;
    this.selectedDateStart=null;
    this.selectedDateEnd=null;
    this.selectedMonth=null;
    this.isMonthly = false;
    this.isDay = false;
    this.isWeekly = false;
    this.isNoOfDays=false;
    this.isRange = false; 
    if (event.isUserInput) {
      this.selectedFilter = status;
    }
    if(this.selectedFilter=='Export Validity Date'){

      this.loadExportValidityMonths();
    }
    else{

      this.loadMonths();
    }
  }
  resetFilters() {

    this.selectedFilter = '';
    this.selectedFilter = null;
    this.filterData = '';
    this.isMonthly = false;
    this.isDay = false;
    this.isWeekly = false;
    this.isRange = false;
    this.isNoOfDays=false;
     this.dataSource = [];
    this.pageSet = 0;
    this.offsetData = 1;
    //this.pageSizeChange = 10;
    localStorage.removeItem('filter');
    localStorage.setItem("offset", JSON.stringify(this.offsetData));
    this.ngOnInit();
  }

  reportGeneration() { 
    console.log(this.oneTwoResp);
    console.log(this.filterData);
    console.log(this.oneTwoRespFilteredData);
    let tableFilterData = JSON.parse(localStorage.getItem('filter'));
    console.log(tableFilterData);
    if(tableFilterData){
      // tableFilterData.entries_per_page=1147483647;
    this.downloadListByOneToTwoMonthByFilter(tableFilterData);
//     var tableData:any=this.oneTwoRespFilteredData;
//     var tableObj =  tableData;
//     console.log(tableObj,'tableobj')
     

//     tableObj.forEach((element,index) => {
//       let scnDate=element.scn_date;
//       let DateOfLastLetter=element.date_of_last_letter;
//       let scnNo=element.scn_no;
//       if(element.iec_number===null){
//         element.iec_number="";
//       }
//       if(element.scn_no===null){
//         scnNo="";
//       }
//       if(element.scn_date===null){
//         scnDate="";
//       }
//       if(element.date_of_last_letter===null){
//         console.log(element.date_of_last_letter);
//         DateOfLastLetter="";
//         console.log(element.date_of_last_letter);

//       }
//       if(element.number_of_letter_sent===null){
//         element.number_of_letter_sent="";
//       }
//       console.log(element);
//       element['IEC No'] = element.iec_number;
//       element['IEC Holder Name'] = element.iec_holder_name;
//       element['AA No'] = element.auth_number;
//       element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
//       element['No. Of Days OverDue'] = element.no_of_days_overdue;
//       element['Bond Amount']=element.bond_amount_in_inr;
//       element['BG Amount'] = element.bg_amount;
//       element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
//       element['No Of Last Letters Sent'] = element.number_of_letter_sent;
//       element['SCN No'] = scnNo;
//       element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");

// // console.log(this.selectedTableDataforDownload[index].join(','));
//     });
//     for (let i = 0; i < tableObj.length; i++) {
//       for (let property in tableObj[i]) {
//         console.log(property);
//         if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
//           tableObj[i][property] = '';
//         }
//       }
//     }
    
//     console.log(tableObj);
  
//     const sortedElementsList = tableObj.sort((a, b) => a.application_id);
//     this.downloadFile(
//       sortedElementsList,
//       'AUTH AFTER EXP VALIDITY PERIOD(1-2 MONTH)' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
//       displayedCol
//     );
  }

  else{
      
    let offset = localStorage.getItem("offset");
    this.downloadAuthorizationAfterValidityOneTwoMonth(this.currentUser.obj.user_id,1,1147483647)
    console.log(this.downloadOneTwoResp);
  }
  

}

reportGeneration1() { 

  let tableFilterData = JSON.parse(localStorage.getItem('filter'));
  console.log(tableFilterData);
  if(tableFilterData){
     
  this.downloadAuthorizationAfterValidityTwoSixMonthByFilter(tableFilterData);
}

else{
    
  let offset = localStorage.getItem("offset");
  this.downloadAuthorizationAfterValidityTwoSixMonth(this.currentUser.obj.user_id,1,1147483647)
  // console.log(this.downloadOneTwoResp);
}
}

reportGeneration2() { 
  // var displayedCol = []
  // displayedCol.push('IEC No');
  // displayedCol.push('IEC Holder Name');
  // displayedCol.push('AA No');
  // displayedCol.push('Export Validity Date');
  // displayedCol.push('No. Of Days OverDue');
  // displayedCol.push('Bond Amount');
  // displayedCol.push('BG Amount');
  // displayedCol.push('Date Of Last Letter');
  // displayedCol.push('No Of Last Letters Sent');
  // displayedCol.push('SCN No');
  // displayedCol.push('SCN Date');

  // console.log(this.oneTwoResp);
  // console.log(this.dataOneTwoResp);
  // var tableData:any=this.datasix24Resp;
  // if(tableData.sd){
  //   var tableObj =  tableData.sd;
  //   }
  //   else{
  //     var tableObj =  tableData;
  //   }  console.log(tableObj,'tableobj')
// tableObj.forEach(elementNatureOfPayment => {
//   console.log(elementNatureOfPayment);
//   this.selectedTableDataforDownload.push(elementNatureOfPayment.nature_of_payment);
// });
// console.log(this.selectedTableDataforDownload);

//   tableObj.forEach((element,index) => {
//     let scnDate=element.scn_date;
//     let DateOfLastLetter=element.date_of_last_letter;
//     let scnNo=element.scn_no;
//     if(element.iec_number===null){
//       element.iec_number="";
//     }
//     if(element.scn_no===null){
//       scnNo="";
//     }
//     if(element.scn_date===null){
//       scnDate="";
//     }
//     if(element.date_of_last_letter===null){
//       console.log(element.date_of_last_letter);
//       DateOfLastLetter="";
//       console.log(element.date_of_last_letter);

//     }
//     if(element.number_of_letter_sent===null){
//       element.number_of_letter_sent="";
//     }
//     console.log(element);
//     element['IEC No'] = element.iec_number;
//     element['IEC Holder Name'] = element.iec_holder_name;
//     element['AA No'] = element.auth_number;
//     element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
//     element['No. Of Days OverDue'] = element.no_of_days_overdue;
//     element['Bond Amount']=element.bond_amount_in_inr;
//     element['BG Amount'] = element.bg_amount;
//     element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
//     element['No Of Last Letters Sent'] = element.number_of_letter_sent;
//     element['SCN No'] = scnNo;
//     element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");

// // console.log(this.selectedTableDataforDownload[index].join(','));
//   });
//   for (let i = 0; i < tableObj.length; i++) {
//     for (let property in tableObj[i]) {
//       // console.log(property);
//       if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
//         tableObj[i][property] = '';
//       }
//     }
//   }
//   console.log(tableObj);
//   const sortedElementsList = tableObj.sort((a, b) => a.application_id);
//   this.downloadFile(
//     sortedElementsList,
//     'AUTH AFTER EXP VALIDITY PERIOD(6-24 MONTH)' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
//     displayedCol
//   );
let tableFilterData = JSON.parse(localStorage.getItem('filter'));
console.log(tableFilterData);
if(tableFilterData){
   
this.downloadAuthorizationAfterValiditySix24MonthByFilter(tableFilterData);
}

else{
  
let offset = localStorage.getItem("offset");
this.downloadAuthorizationAfterValiditySix24Month(this.currentUser.obj.user_id,1,1147483647)
}
}

reportGeneration3() { 

let tableFilterData = JSON.parse(localStorage.getItem('filter'));
console.log(tableFilterData);
if(tableFilterData){
   
this.downloadAuthorizationAfterValidityMoreThan2YearsByFilter(tableFilterData);
}

else{
  
let offset = localStorage.getItem("offset");
this.downloadAuthorizationAfterValidityMoreThan2Years(this.currentUser.obj.user_id,1,1147483647)
}
}

reportGeneration4() { 
let tableFilterData = JSON.parse(localStorage.getItem('filter'));
console.log(tableFilterData);
if(tableFilterData){
   
this.downloadAuthorizationBeforeValidityFilter(tableFilterData);
}

else{
  
let offset = localStorage.getItem("offset");
this.downloadAuthorizationBeforeValidity(this.currentUser.obj.user_id,1,1147483647)
}
}

downloadFile(data, filename = "data", headers) {
  let csvData = this.ConvertToCSV(data, headers);
  console.log(csvData);
  let blob = new Blob(["\ufeff" + csvData], {
    type: "text/csv;charset=utf-8;",
  });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1;
  if (isSafariBrowser) {
    //if Safari open in new window to save file with random filename.
    dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray, headerList) {
  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  let row = "Sr.No,";

  for (let index in headerList) {
    row += headerList[index] + ",";
  }
  row = row.slice(0, -1);
  str += row + "\r\n";
  for (let i = 0; i < array.length; i++) {
    let line = i + 1 + "";
    for (let index in headerList) {
      let head = headerList[index];

      line += "," + array[i][head];
    }
    str += line + "\r\n";
  }
  return str;
}

  getFilterData(data, filter) {
    this.blockUI.start();
    this.loggedUserLevel=this.currentUser.obj.level;
    console.log(data);
    this.dataSource=[];
    this.pageSet = 0;
    this.offsetData = 1
    localStorage.setItem("offset", JSON.stringify(this.offsetData));
    if (filter == 'AA No') {
      this.sendInput = {
        start_date:this.todayDate,
        auth_number: data,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
    }
    else if (filter == 'IEC No') {
      this.sendInput = {
        iec_number: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
      console.log(this.sendInput);
    }
    else if (filter == 'IEC Holder Name') {
      this.sendInput = {
        iec_holder_name: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
      console.log(this.sendInput);
    }
    else if (filter == 'Bond Amount') {
      this.sendInput = {
        bond_amount_in_inr: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
    } else if (filter == 'BG Amount') {
      this.sendInput = {
        bg_amount: data,
        start_date:this.todayDate,
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
      }
    }
    else if (filter == 'Date Of Last Letter') {
        this.sendInput = {
          //user_id: this.currentUser.obj.user_id,
          offset: this.offsetData,
          entries_per_page:this.pageSizeChange,
          //pending_app:this.pendingApp
          date_of_last_letter:this.startDate+" 00:00:00",
          start_date:this.todayDate,
          custom_start_date:this.startDate,
          custom_end_date:this.endDate,
          // end_date:this.endDate,
        }
    
    } 
    else if (filter == 'Export Validity Date') {
      this.sendInput = {
        //user_id: this.currentUser.obj.user_id,
        offset: this.offsetData,
        entries_per_page:this.pageSizeChange,
        //pending_app:this.pendingApp
        evd:this.startDate,
        start_date:this.todayDate,
        custom_start_date:this.startDate,
        custom_end_date:this.endDate,
      }
  
  } 
  else if (filter == 'No Of Days OverDue') {
    this.sendInput = {
      no_of_days_overdue: data,
      time_span:this.noOfDaysValue,
      offset: this.offsetData,
      entries_per_page:this.pageSizeChange,
      start_date:this.todayDate,
    }

} 
  else if (filter == 'No Of Last Letters Sent') {
    this.sendInput = {
      number_of_letter_sent: data,
      //user_id: this.currentUser.obj.user_id,
      offset: this.offsetData,
      entries_per_page:this.pageSizeChange,
      start_date:this.todayDate,
      //pending_app:this.pendingApp
    }

} 
else if (filter == 'SCN No') {
  this.sendInput = {
    scn_no: data,
    //user_id: this.currentUser.obj.user_id,
    offset: this.offsetData,
    entries_per_page:this.pageSizeChange,
    start_date:this.todayDate,
    //pending_app:this.pendingApp
  }
}
else if (filter == 'Process') {
  this.sendInput = {
    officer_name: data,
    //user_id: this.currentUser.obj.user_id,
    offset: this.offsetData,
    entries_per_page:this.pageSizeChange,
    //pending_app:this.pendingApp
  }

}

    // else if (filter == 'Incomming Date') {
    //   this.sendInput = {
    //     start_date: this.startDate,
    //     end_date: this.endDate,
    //     incoming_date: this.startDate,
    //     //user_id: this.currentUser.obj.user_id,
    //     offset: this.offsetData,
    //     entries_per_page:this.pageSizeChange,
    //     evd:"",
    //     custom_start_date:"",
    //     custom_end_date:"",
    //     //pending_app:this.pendingApp
    //   }
    // }
    setTimeout(() => {
      this.blockUI.stop();
    }, 500);
    if(this.noOfAuth == '1'){
      localStorage.setItem('filter', JSON.stringify(this.sendInput));
      // this.sendInput.entries_per_page=1147483647;
      this.paginator1.pageIndex=0;
       this.getListByOneToTwoMonthByFilter(this.sendInput);
    }
    else if(this.noOfAuth == '2'){
      localStorage.setItem('filter', JSON.stringify(this.sendInput));
      this.paginator2.pageIndex=0;
      this.getAuthorizationAfterValidityTwoSixMonthByFilter(this.sendInput);
    }
    else if(this.noOfAuth == '3'){
      localStorage.setItem('filter', JSON.stringify(this.sendInput));
      this.paginator3.pageIndex=0;
       this.getAuthorizationAfterValiditySix24MonthByFilter(this.sendInput);
    }
    else if(this.noOfAuth == '4'){
      localStorage.setItem('filter', JSON.stringify(this.sendInput));
      this.paginator4.pageIndex=0;
        this.getAuthorizationAfterValidityMoreThan2YearsByFilter(this.sendInput);
    }
    else if(this.noOfAuth == '5'){
      localStorage.setItem('filter', JSON.stringify(this.sendInput));
      this.paginator5.pageIndex=0;
       this.getAuthorizationBeforeValidityFilter(this.sendInput);
    }

  
  }
  // getExportValidity2MonthPeriodFilterListByUserId(input) {
  //   this.pageSet = 0;
  //   this.blockchainService.getExportValidity2MonthPeriodFilterListByUserId(input).subscribe(
  //     (resp) => {
  //       // console.log(resp.body.obj);
      
  //       this.dataSource = resp.body.obj.asr;
  //       this.totalEntries = resp.body.obj.total_entries;
  //       this.dataSource.forEach(st => {
  //         if (st.application_status == '0') {
  //           st.application_status = 'Received';
  //         } else if (st.application_status == '1') {
  //           st.application_status = 'Approved';
  //         } else {
  //           st.application_status = 'Rejected';
  //         }
  //       })
  //       // console.log(input)
  //       var summaryList = this.dataSource;
  //       this.summaryData = new PaginatedDataSource<Registration, RegistrationDetailsQuery>(
  //         (request, query) =>
  //           this.blockchainService.pageRegistration(request, query, summaryList),
  //         this.initialSort,
  //         { search: "", data: undefined },
        
  //       );
  //     }, (error) => {
  //       console.log(error)
  //       this.alertService.error(error);
  //       if(error.status == 401){
  //         location.reload();
  //         this.showNotification(
  //           "bottom",
  //           "right",
  //           "success",
  //           "Session Expired, Please Login Again",
  //           "announcement"
  //         );
  //         this.router.navigate(["/login"]);
  //       }
  //       else if(error.status == 500){
  //         location.reload();
  //         setTimeout(() => {
  //           this.blockUI.stop();
  //         }, 500);
  //         this.showNotification(
  //           "bottom",
  //           "right",
  //           "success",
  //           "Please Try Again",
  //           "announcement"
  //         );
  //       }
  //       else{}
       
  //     });
  // }
  changeConfirmationStatus(status) {
    this.filterData = status;
  }
  processType(type, offsetData) {
    if(this.pageSizeChange == null ){
      this.pageSizeChange = 10
    }
    // this.loadMonths();
    if(this.noOfAuth == '1'){
      this.getAuthorizationAfterValidityOneTwoMonth(this.user, offsetData,this.pageSizeChange);
    }
    else if(this.noOfAuth == '2'){
      localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
      this.getAuthorizationAfterValidityTwoSixMonth(this.user, offsetData,this.pageSizeChange);
    }
    else if(this.noOfAuth == '3'){
      localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
       this.getAuthorizationAfterValiditySix24Month(this.user, offsetData,this.pageSizeChange);
    }
    else if(this.noOfAuth == '4'){
      localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
       this.getAuthorizationAfterValidityMoreThan2Years(this.user, offsetData,this.pageSizeChange);
   }
   else if(this.noOfAuth == '5'){
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
     this.getAuthorizationBeforeValidity(this.user, offsetData,this.pageSizeChange);
}
  }
  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate,
      this.endDate = this.date ? this.date : this.todayDate
  }
  dateStartSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate;
    // console.log(this.startDate)
  }
  dateEndSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.endDate = this.date ? this.date : this.todayDate;
    // console.log(this.endDate)
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  loadExportValidityMonths() {
    let date = new Date("2026-12-31");
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months = [];
    for (let i = 0; i < 156; i++) {

      // console.log("MOnth : ", month);
      if (month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      let name = this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = { "name": name, "value": month + 1, "start": start + " 00:00:00", "end": end + " 23:59:59" };
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
  }

  loadMonths() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months = [];
    for (let i = 0; i < 12; i++) {

      // console.log("MOnth : ", month);
      if (month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      let name = this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = { "name": name, "value": month + 1, "start": start + " 00:00:00", "end": end + " 23:59:59" };
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }
  }
  monthly(value, event) {
    if (event.isUserInput) {
      this.showTable = true;
      this.selectedMonth = value ? value : this.selectedMonth;
      this.startDate = this.pipe.transform(this.selectedMonth.start, "yyyy-MM-dd", "en-US");
      this.endDate = this.pipe.transform(this.selectedMonth.end, "yyyy-MM-dd", "en-US");
    }
  }

  selectCalendarView(event, value) {
    if (event.isUserInput==true) {
      if (value == "Monthly") {
        this.isMonthly = true;
        this.isDay = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Weekly") {
        this.isWeekly = true;
        this.isDay = false;
        this.isMonthly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Day") {
        this.isDay = true;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else {
        this.isDay = false;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = true;
      }
    }
  }

  selectNoOfDaysView(event, value) {
    console.log(value);
    console.log(event);
    if (event.isUserInput==true) {
      this.noOfDaysValue=value;
      if (value == "greater" ) {
        this.isNoOfDays = true;
      }
      else if (value == "smaller" ) {
        this.isNoOfDays = true;
      }
      else {
        this.isNoOfDays = false;
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  compareMonths(i1, i2) {
    // return i2 && i1 && i1.value === i2.value;
    return i1 && i2 && i1.value === i2.value;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    if (this.isMonthly) {
      if (type === "change") {
        this.showTable = true;

        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        //const start =
        let date = new Date(this.today);
        //date = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        this.first_day_of_month = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        let currentMonth = date.getMonth();
        this.last_day_of_month = new Date(
          date.getFullYear(),
          currentMonth,
          this.daysInMonth(currentMonth + 1, date.getFullYear())
        );
        // let input = {
        //   start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        this.startDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        this.endDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);
        // this.dateData = input;
      }
    } else {
      if (type === "change") {
        this.showTable = true;
        this.today = event.value.getTime();

        const now = this.today;

        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        const start = new Date(this.today + 6 * 24 * 60 * 60 * 1000).getTime();
        const myStartDate = this.pipe.transform(start, "yyyy-MM-dd", "en-US");
        this.last6 = start;
        // let input = {
        //   end_date : this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   start_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        // this.dateData = input;
        this.startDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        this.endDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);

        if (this.today == this.now) {
          this.isButtonVisible = false;
        } else {

          this.isButtonVisible = true;
        }
      }
    }
  }

  // getDownloadDataOneToTwoMonthByFilter(input) {
  //   localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
  //   // let inputData = {
  //   //   user_id: input,
  //   //   offset: offSetData,
  //   //   entries_per_page:pageSizeSet,
  //   // }
 
  //   input.applicationType = this.applicationType ? this.applicationType : 'AA';
  //   this.blockchainService
  //   .getDownloadExportValidity2MonthPeriodFilterListByFilter(input)
  //   .subscribe((resp) => {
  //     if (resp.body.status == 200) {
  //       // this.oneTwoRespLength=resp.body.obj;
  //       // this.oneTwoResp = resp.body.obj.sd;
  //       this.downloadOneToMonthFilterData =  resp.body.obj.sd;
  //       // if(input.offset==1){
  //       // this.paginator1.pageIndex=0;
  //       // }
  //       // this.downloadOneToMonthFilterData = this.oneTwoResp;
  //       // localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
  //       // this.totalEntries=resp.body.obj.total_entries;
  //       // console.log(resp.body.obj);
  //       // console.log(this.oneTwoResp+"dvsfvawefae");
  //       // if (this.oneTwoResp !== null) {
  //       //   var oneTwoRespList = resp.body.obj.sd;
  //       //   console.log("ds",oneTwoRespList);
  //       //   this.oneTwoResp = new PaginatedDataSource<OneTwo, OneTwoQuery>(
  //       //     (request, query) =>
  //       //       this.blockchainService.pageOneTwo(request, query, oneTwoRespList),
  //       //     this.initialSort,
  //       //     { search: "", data: undefined },
  //       //     10
  //       //   );
  //       //   console.log(oneTwoRespList);
  //       //   console.log("ds",this.oneTwoResp);
  //       //   this.oneTwoResp.pageSize=this.pageSizeChange;
  //       // }

  //       setTimeout(() => {
  //         this.blockUI.stop();
  //       }, 1500);
  //       this.showNotification(
  //         "bottom",
  //         "right",
  //         "success",
  //         resp.body.message,
  //         "announcement"
  //       );
  //     }
  //      else {
  //       this.totalEntries=0;
  //       this.showNotification(
  //         "bottom",
  //         "right",
  //         "danger",
  //         resp.body.message,
  //         "announcement"
  //       );
  //     }
  //   });
  // }


  getListByOneToTwoMonthByFilter(input) {
    this.loader1=true;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // let inputData = {
    //   user_id: input,
    //   offset: offSetData,
    //   entries_per_page:pageSizeSet,
    // }
 
    input.applicationType = this.applicationType ? this.applicationType : 'AA';
    this.blockchainService
    .getExportValidity2MonthPeriodFilterListByFilter(input)
    .subscribe((resp) => {
      if (resp.body.status == 200) {
        this.oneTwoRespLength=resp.body.obj;
        this.oneTwoResp = resp.body.obj.sd;
        // if(input.offset==1){
        // this.paginator1.pageIndex=0;
        // }
        this.dataOneTwoResp = this.oneTwoResp;
        localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
        this.totalEntries=resp.body.obj.total_entries;
        console.log(resp.body.obj);
        console.log(this.oneTwoResp+"dvsfvawefae");
        if (this.oneTwoResp !== null) {
          this.loader1=false;
          var oneTwoRespList = resp.body.obj.sd;
          this.oneTwoRespFilteredData=resp.body.obj.sd;
          console.log("ds",oneTwoRespList);
          this.oneTwoResp = new PaginatedDataSource<OneTwo, OneTwoQuery>(
            (request, query) =>
              this.blockchainService.pageOneTwo(request, query, oneTwoRespList),
            this.initialSort,
            { search: "", data: undefined },
            10
          );
          console.log(oneTwoRespList);
          console.log("ds",this.oneTwoResp);
          this.oneTwoResp.pageSize=this.pageSizeChange;
          if(input.offset==1){
            this.paginator1.pageIndex=0;
            }
        }

        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
       else {
        this.totalEntries=0;
        this.loader1=false;
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    });
  }
  downloadListByOneToTwoMonthByFilter(input) {
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // let inputData = {
    //   user_id: input,
    //   offset: offSetData,
    //   entries_per_page:pageSizeSet,
    // }
 
    input.applicationType = this.applicationType ? this.applicationType : 'AA';
    input.entries_per_page=1147483647;
    this.blockchainService
    .getExportValidity2MonthPeriodFilterListByFilter(input)
    .subscribe((resp) => {
      if (resp.body.status == 200) {
        // this.oneTwoRespLength=resp.body.obj;
        // this.oneTwoResp = resp.body.obj.sd;
        // if(input.offset==1){
        // this.paginator1.pageIndex=0;
        // }
        // this.dataOneTwoResp = this.oneTwoResp;
        // localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
        // this.totalEntries=resp.body.obj.total_entries;
        // console.log(resp.body.obj);
        // console.log(this.oneTwoResp+"dvsfvawefae");
        // if (this.oneTwoResp !== null) {
          var displayedCol = []
          displayedCol.push('IEC No');
          displayedCol.push('IEC Holder Name');
          displayedCol.push('AA No');
          displayedCol.push('Export Validity Date');
          displayedCol.push('No. Of Days OverDue');
          displayedCol.push('Bond Amount');
          displayedCol.push('BG Amount');
          displayedCol.push('Date Of Last Letter');
          displayedCol.push('No Of Last Letters Sent');
          displayedCol.push('SCN No');
          displayedCol.push('SCN Date');
          this.downloadOneTwoRespFilteredData=resp.body.obj.sd;
                     
          console.log(this.downloadOneTwoRespFilteredData)
          var tableData:any=this.downloadOneTwoRespFilteredData;
          var tableObj =  tableData;
          console.log(tableObj,'tableobj')
      
          tableObj.forEach((element,index) => {
            let scnDate=element.scn_date;
            let DateOfLastLetter=element.date_of_last_letter;
            let scnNo=element.scn_no;
            if(element.iec_number===null){
              element.iec_number="";
            }
            if(element.scn_no===null){
              scnNo="";
            }
            if(element.scn_date===null){
              scnDate="";
            }
            if(element.date_of_last_letter===null){
              console.log(element.date_of_last_letter);
              DateOfLastLetter="";
              console.log(element.date_of_last_letter);
      
            }
            if(element.number_of_letter_sent===null){
              element.number_of_letter_sent="";
            }
            console.log(element);
            element['IEC No'] = element.iec_number;
            element['IEC Holder Name'] = element.iec_holder_name;
            element['AA No'] = element.auth_number;
            element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
            element['No. Of Days OverDue'] = element.no_of_days_overdue;
            element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
            element['BG Amount'] = element.bg_amount.replace(/,/g, '');
            element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
            element['No Of Last Letters Sent'] = element.number_of_letter_sent;
            element['SCN No'] = scnNo;
            element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");
      
            for (let i = 0; i < tableObj.length; i++) {
             for (let property in tableObj[i]) {
               // console.log(property);
               if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                 tableObj[i][property] = '';
               }
             }
           }
      // console.log(this.selectedTableDataforDownload[index].join(','));
          });
       const sortedElementsList = tableObj.sort((a, b) => a.application_id);
        this.downloadFile(
        sortedElementsList,
        'AUTH AFTER EXP VALIDITY PERIOD(1-2 MONTH)' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
        displayedCol);
        // }

        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
       else {
        this.totalEntries=0;
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  getAuthorizationAfterValidityOneTwoMonth(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader1=true;
    console.log(offSetData);
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      applicationType: this.applicationType ? this.applicationType : 'AA'
      // user:user,
    };
    console.log(input);
    this.blockchainService
      .getAuthorizationAfterValidityOneTwoMonthByPagination(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.loader1=false;
          this.oneTwoRespLength = resp.body.obj;
          this.oneTwoResp = resp.body.obj.sd;
          console.log(resp.body.obj);
          this.dataOneTwoResp = this.oneTwoResp;
           this.totalEntries = resp.body.obj.total_entries;
           
           console.log(this.dataOneTwoResp.length);
          // this.totalEntries = resp.body.obj.length;
          if (this.oneTwoResp !== null) {
            var oneTwoRespList = resp.body.obj.sd;
            console.log("ds",oneTwoRespList);
            this.oneTwoResp = new PaginatedDataSource<OneTwo, OneTwoQuery>(
              (request, query) =>
                this.blockchainService.pageOneTwo(request, query, oneTwoRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            console.log("ds",this.oneTwoResp);
            console.log("ds",oneTwoRespList);
            this.oneTwoResp.pageSize=this.pageSizeChange;
            if(this.paginator2){
            if(input.offset==1){
              this.paginator2.pageIndex=0;
              }
            }
          }

          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader1=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  downloadAuthorizationAfterValidityOneTwoMonth(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    console.log(offSetData);
    var displayedCol = []
    displayedCol.push('IEC No');
    displayedCol.push('IEC Holder Name');
    displayedCol.push('AA No');
    displayedCol.push('Export Validity Date');
    displayedCol.push('No. Of Days OverDue');
    displayedCol.push('Bond Amount');
    displayedCol.push('BG Amount');
    displayedCol.push('Date Of Last Letter');
    displayedCol.push('No Of Last Letters Sent');
    displayedCol.push('SCN No');
    displayedCol.push('SCN Date');
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      applicationType: this.applicationType ? this.applicationType : 'AA'
      // user:user,
    };
    console.log(input);
    this.blockchainService
      .getAuthorizationAfterValidityOneTwoMonthByPagination(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.oneTwoRespLength = resp.body.obj;
          // this.oneTwoResp = resp.body.obj.sd;
          // console.log(resp.body.obj);
          this.downloadOneTwoResp = resp.body.obj.sd;
           
           console.log(this.downloadOneTwoResp)
           var tableData:any=this.downloadOneTwoResp;
           var tableObj =  tableData;
           console.log(tableObj,'tableobj')
       
           tableObj.forEach((element,index) => {
             let scnDate=element.scn_date;
             let DateOfLastLetter=element.date_of_last_letter;
             let scnNo=element.scn_no;
             if(element.iec_number===null){
               element.iec_number="";
             }
             if(element.scn_no===null){
               scnNo="";
             }
             if(element.scn_date===null){
               scnDate="";
             }
             if(element.date_of_last_letter===null){
               console.log(element.date_of_last_letter);
               DateOfLastLetter="";
               console.log(element.date_of_last_letter);
       
             }
             if(element.number_of_letter_sent===null){
               element.number_of_letter_sent="";
             }
             console.log(element);
             element['IEC No'] = element.iec_number;
             element['IEC Holder Name'] = element.iec_holder_name;
             element['AA No'] = element.auth_number;
             element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
             element['No. Of Days OverDue'] = element.no_of_days_overdue;
             element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
             element['BG Amount'] = element.bg_amount.replace(/,/g, '');
             element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
             element['No Of Last Letters Sent'] = element.number_of_letter_sent;
             element['SCN No'] = scnNo;
             element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");
       
             for (let i = 0; i < tableObj.length; i++) {
              for (let property in tableObj[i]) {
                // console.log(property);
                if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                  tableObj[i][property] = '';
                }
              }
            }
       // console.log(this.selectedTableDataforDownload[index].join(','));
           });
        const sortedElementsList = tableObj.sort((a, b) => a.application_id);
this.downloadFile(
  sortedElementsList,
  'AUTH AFTER EXP VALIDITY PERIOD(1-2 MONTH)' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
  displayedCol
);
for (let i = 0; i < tableObj.length; i++) {
  for (let property in tableObj[i]) {
    // console.log(property);
    if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
      tableObj[i][property] = '';
    }
  }
}
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }


  getAuthorizationAfterValidityTwoSixMonth(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader2=true;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    console.log(offSetData);
    console.log(this.pageSizeChange);
    console.log(pageSizeSet);
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      // user:user,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getAuthorizationAfterValidityTwoSixMonth(input)
      
          // this.totalEntries = resp.body.obj.length;
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.twoSixResp = resp.body.obj.sd;
          this.datatwoSixResp =  resp.body.obj;
           this.totalEntries = resp.body.obj.total_entries;
          //  this.paginator2.pageSize = input.entries_per_page;
          //  this.paginator2.pageSize=input.entries_per_page;
          //  console.log(this.paginator2.pageIndex);
          // this.totalEntries = resp.body.obj.length;
          if (this.twoSixResp !== null) {
            this.loader2=false;
            var twoSixRespList = resp.body.obj.sd;
            this.twoSixResp = new PaginatedDataSource<OneTwo, OneTwoQuery>(
              (request, query) =>
                this.blockchainService.pageOneTwo(request, query, twoSixRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.twoSixResp.pageSize=this.pageSizeChange;
            if(this.paginator2){
            if(input.offset==1){
              this.paginator2.pageIndex=0;
              }
            }
          }

          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader2=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  downloadAuthorizationAfterValidityTwoSixMonth(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    console.log(offSetData);
    var displayedCol = []
    displayedCol.push('IEC No');
    displayedCol.push('IEC Holder Name');
    displayedCol.push('AA No');
    displayedCol.push('Export Validity Date');
    displayedCol.push('No. Of Days OverDue');
    displayedCol.push('Bond Amount');
    displayedCol.push('BG Amount');
    displayedCol.push('Date Of Last Letter');
    displayedCol.push('No Of Last Letters Sent');
    displayedCol.push('SCN No');
    displayedCol.push('SCN Date');
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      applicationType: this.applicationType ? this.applicationType : 'AA'
      // user:user,
    };
    console.log(input);
    this.blockchainService
      .getAuthorizationAfterValidityTwoSixMonth(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.oneTwoRespLength = resp.body.obj;
          // this.oneTwoResp = resp.body.obj.sd;
          // console.log(resp.body.obj);
          this.downloadTwoSixResp = resp.body.obj.sd;
           
           console.log(this.downloadTwoSixResp)
           var tableData:any=this.downloadTwoSixResp;
           var tableObj =  tableData;
           console.log(tableObj,'tableobj')
       
           tableObj.forEach((element,index) => {
             let scnDate=element.scn_date;
             let DateOfLastLetter=element.date_of_last_letter;
             let scnNo=element.scn_no;
             if(element.iec_number===null){
               element.iec_number="";
             }
             if(element.scn_no===null){
               scnNo="";
             }
             if(element.scn_date===null){
               scnDate="";
             }
             if(element.date_of_last_letter===null){
               console.log(element.date_of_last_letter);
               DateOfLastLetter="";
               console.log(element.date_of_last_letter);
       
             }
             if(element.number_of_letter_sent===null){
               element.number_of_letter_sent="";
             }
             console.log(element);
             element['IEC No'] = element.iec_number;
             element['IEC Holder Name'] = element.iec_holder_name;
             element['AA No'] = element.auth_number;
             element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
             element['No. Of Days OverDue'] = element.no_of_days_overdue;
             element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
             element['BG Amount'] = element.bg_amount.replace(/,/g, '');
             element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
             element['No Of Last Letters Sent'] = element.number_of_letter_sent;
             element['SCN No'] = scnNo;
             element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");
       
             for (let i = 0; i < tableObj.length; i++) {
              for (let property in tableObj[i]) {
                // console.log(property);
                if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                  tableObj[i][property] = '';
                }
              }
            }
       // console.log(this.selectedTableDataforDownload[index].join(','));
           });
        const sortedElementsList = tableObj.sort((a, b) => a.application_id);
this.downloadFile(
  sortedElementsList,
  'AUTH AFTER EXP VALIDITY PERIOD(2-6 MONTH)' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
  displayedCol
);
for (let i = 0; i < tableObj.length; i++) {
  for (let property in tableObj[i]) {
    // console.log(property);
    if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
      tableObj[i][property] = '';
    }
  }
}
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  getAuthorizationAfterValidityTwoSixMonthByFilter(inputData:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader2=true;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    if(inputData.offset==1){
    this.pageSet = 0;
    }
    // console.log(offSetData);
    // let input = {
    //   start_date: this.date,
    //   entries_per_page:pageSizeSet,
    //   offset:offSetData,
    //   // user:user,
    // };
   inputData.applicationType = this.applicationType ? this.applicationType : 'AA' 
    this.blockchainService
      .getAuthorizationAfterValidityTwoSixMonthByFilter(inputData)
      
          // this.totalEntries = resp.body.obj.length;
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.twoSixResp = resp.body.obj.sd;
          this.datatwoSixResp =  resp.body.obj.sd;
           this.totalEntries = resp.body.obj.total_entries;
           if(inputData.offset==1){
           this.paginator2.pageIndex=0;
           }
           console.log(this.pageSet,"pageSet");
           console.log(inputData,"inputFilterData");
           console.log(this.totalEntries,"entries");
           console.log(this.twoSixResp,"resp");
          //  this.totalEntries = resp.body.obj.length;
          if (this.twoSixResp !== null) {
            this.loader2=false;
            // this.getAuthorizationAfterValidityTwoSixMonth(this.user, offsetData,this.pageSizeChange);
            var twoSixRespList = resp.body.obj.sd;
            this.twoSixResp = new PaginatedDataSource<OneTwo, OneTwoQuery>(
              (request, query) =>
                this.blockchainService.pageOneTwo(request, query, twoSixRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.twoSixResp.pageSize=this.pageSizeChange;
            console.log(this.twoSixResp);
          }

          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader2=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  downloadAuthorizationAfterValidityTwoSixMonthByFilter(input) {
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // let inputData = {
    //   user_id: input,
    //   offset: offSetData,
    //   entries_per_page:pageSizeSet,
    // }
 
    input.applicationType = this.applicationType ? this.applicationType : 'AA';
    input.entries_per_page=1147483647;
    this.blockchainService
    .getAuthorizationAfterValidityTwoSixMonthByFilter(input)
    .subscribe((resp) => {
      if (resp.body.status == 200) {
          var displayedCol = []
          displayedCol.push('IEC No');
          displayedCol.push('IEC Holder Name');
          displayedCol.push('AA No');
          displayedCol.push('Export Validity Date');
          displayedCol.push('No. Of Days OverDue');
          displayedCol.push('Bond Amount');
          displayedCol.push('BG Amount');
          displayedCol.push('Date Of Last Letter');
          displayedCol.push('No Of Last Letters Sent');
          displayedCol.push('SCN No');
          displayedCol.push('SCN Date');
          this.downloadTwoSixRespFilteredData=resp.body.obj.sd;
                     
          console.log(this.downloadTwoSixRespFilteredData)
          var tableData:any=this.downloadTwoSixRespFilteredData;
          var tableObj =  tableData;
          console.log(tableObj,'tableobj')
      
          tableObj.forEach((element,index) => {
            let scnDate=element.scn_date;
            let DateOfLastLetter=element.date_of_last_letter;
            let scnNo=element.scn_no;
            if(element.iec_number===null){
              element.iec_number="";
            }
            if(element.scn_no===null){
              scnNo="";
            }
            if(element.scn_date===null){
              scnDate="";
            }
            if(element.date_of_last_letter===null){
              console.log(element.date_of_last_letter);
              DateOfLastLetter="";
              console.log(element.date_of_last_letter);
      
            }
            if(element.number_of_letter_sent===null){
              element.number_of_letter_sent="";
            }
            console.log(element);
            element['IEC No'] = element.iec_number;
            element['IEC Holder Name'] = element.iec_holder_name;
            element['AA No'] = element.auth_number;
            element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
            element['No. Of Days OverDue'] = element.no_of_days_overdue;
            element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
            element['BG Amount'] = element.bg_amount.replace(/,/g, '');
            element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
            element['No Of Last Letters Sent'] = element.number_of_letter_sent;
            element['SCN No'] = scnNo;
            element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");
      
            for (let i = 0; i < tableObj.length; i++) {
             for (let property in tableObj[i]) {
               // console.log(property);
               if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                 tableObj[i][property] = '';
               }
             }
           }
      // console.log(this.selectedTableDataforDownload[index].join(','));
          });
       const sortedElementsList = tableObj.sort((a, b) => a.application_id);
        this.downloadFile(
        sortedElementsList,
        'AUTH AFTER EXP VALIDITY PERIOD(2-6 MONTH)' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
        displayedCol);
        // }

        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
       else {
        this.totalEntries=0;
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  getAuthorizationAfterValiditySix24MonthByFilter(inputData:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader3=true;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    if(inputData.offset==1){
      this.pageSet = 0;
      }
      // console.log(this.paginator3.pageIndex);
    // console.log(offSetData);
    // let input = {
    //   start_date: this.date,
    //   entries_per_page:pageSizeSet,
    //   offset:offSetData,
    //   // user:user,
    // };
    inputData.applicationType = this.applicationType ? this.applicationType : 'AA' 
    this.blockchainService
      .getAuthorizationAfterValiditySix24MonthFilter(inputData)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.six24Resp = resp.body.obj.sd;
          this.datasix24Resp = resp.body.obj;
          // if(inputData.offset==1){
          // this.paginator3.pageIndex=0;
          // }
          this.totalEntries=resp.body.obj.total_entries;
          if (this.six24Resp !== null) {
            this.loader3=false;
            var six24RespList = resp.body.obj.sd;
            this.six24Resp = new PaginatedDataSource<OneTwo, OneTwoQuery>(
              (request, query) =>
                this.blockchainService.pageOneTwo(request, query, six24RespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.six24Resp.pageSize=this.pageSizeChange;
          }

          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader3=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  downloadAuthorizationAfterValiditySix24MonthByFilter(input) {
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // let inputData = {
    //   user_id: input,
    //   offset: offSetData,
    //   entries_per_page:pageSizeSet,
    // }
 
    input.applicationType = this.applicationType ? this.applicationType : 'AA';
    input.entries_per_page=1147483647;
    this.blockchainService
    .getAuthorizationAfterValiditySix24MonthFilter(input)
    .subscribe((resp) => {
      if (resp.body.status == 200) {
          var displayedCol = []
          displayedCol.push('IEC No');
          displayedCol.push('IEC Holder Name');
          displayedCol.push('AA No');
          displayedCol.push('Export Validity Date');
          displayedCol.push('No. Of Days OverDue');
          displayedCol.push('Bond Amount');
          displayedCol.push('BG Amount');
          displayedCol.push('Date Of Last Letter');
          displayedCol.push('No Of Last Letters Sent');
          displayedCol.push('SCN No');
          displayedCol.push('SCN Date');
          this.downloadSix24RespFilteredData=resp.body.obj.sd;
                     
          console.log(this.downloadSix24RespFilteredData)
          var tableData:any=this.downloadSix24RespFilteredData;
          var tableObj =  tableData;
          console.log(tableObj,'tableobj')
      
          tableObj.forEach((element,index) => {
            let scnDate=element.scn_date;
            let DateOfLastLetter=element.date_of_last_letter;
            let scnNo=element.scn_no;
            if(element.iec_number===null){
              element.iec_number="";
            }
            if(element.scn_no===null){
              scnNo="";
            }
            if(element.scn_date===null){
              scnDate="";
            }
            if(element.date_of_last_letter===null){
              console.log(element.date_of_last_letter);
              DateOfLastLetter="";
              console.log(element.date_of_last_letter);
      
            }
            if(element.number_of_letter_sent===null){
              element.number_of_letter_sent="";
            }
            console.log(element);
            element['IEC No'] = element.iec_number;
            element['IEC Holder Name'] = element.iec_holder_name;
            element['AA No'] = element.auth_number;
            element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
            element['No. Of Days OverDue'] = element.no_of_days_overdue;
            element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
            element['BG Amount'] = element.bg_amount.replace(/,/g, '');
            element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
            element['No Of Last Letters Sent'] = element.number_of_letter_sent;
            element['SCN No'] = scnNo;
            element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");
      
            for (let i = 0; i < tableObj.length; i++) {
             for (let property in tableObj[i]) {
               // console.log(property);
               if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                 tableObj[i][property] = '';
               }
             }
           }
      // console.log(this.selectedTableDataforDownload[index].join(','));
          });
       const sortedElementsList = tableObj.sort((a, b) => a.application_id);
        this.downloadFile(
        sortedElementsList,
        'AUTH AFTER EXP VALIDITY PERIOD(6-24 MONTH)' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
        displayedCol);
        // }

        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
       else {
        this.totalEntries=0;
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  getAuthorizationAfterValiditySix24Month(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader3=true;
    console.log(offSetData);
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      // user:user,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getAuthorizationAfterValiditySix24Month(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.six24Resp = resp.body.obj.sd;
          this.datasix24Resp = resp.body.obj;
          this.totalEntries=resp.body.obj.total_entries;
          if (this.six24Resp !== null) {
            this.loader3=false;
            var six24RespList = resp.body.obj.sd;
            this.six24Resp = new PaginatedDataSource<OneTwo, OneTwoQuery>(
              (request, query) =>
                this.blockchainService.pageOneTwo(request, query, six24RespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.six24Resp.pageSize=this.pageSizeChange;
            if(this.paginator3){
            if(input.offset==1){
              this.paginator3.pageIndex=0;
              }
            }
          }

          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader3=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  downloadAuthorizationAfterValiditySix24Month(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    console.log(offSetData);
    var displayedCol = []
    displayedCol.push('IEC No');
    displayedCol.push('IEC Holder Name');
    displayedCol.push('AA No');
    displayedCol.push('Export Validity Date');
    displayedCol.push('No. Of Days OverDue');
    displayedCol.push('Bond Amount');
    displayedCol.push('BG Amount');
    displayedCol.push('Date Of Last Letter');
    displayedCol.push('No Of Last Letters Sent');
    displayedCol.push('SCN No');
    displayedCol.push('SCN Date');
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      applicationType: this.applicationType ? this.applicationType : 'AA'
      // user:user,
    };
    console.log(input);
    this.blockchainService
      .getAuthorizationAfterValiditySix24Month(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.oneTwoRespLength = resp.body.obj;
          // this.oneTwoResp = resp.body.obj.sd;
          // console.log(resp.body.obj);
          this.downloadSix24Resp = resp.body.obj.sd;
           
           console.log(this.downloadSix24Resp)
           var tableData:any=this.downloadSix24Resp;
           var tableObj =  tableData;
           console.log(tableObj,'tableobj')
       
           tableObj.forEach((element,index) => {
             let scnDate=element.scn_date;
             let DateOfLastLetter=element.date_of_last_letter;
             let scnNo=element.scn_no;
             if(element.iec_number===null){
               element.iec_number="";
             }
             if(element.scn_no===null){
               scnNo="";
             }
             if(element.scn_date===null){
               scnDate="";
             }
             if(element.date_of_last_letter===null){
               console.log(element.date_of_last_letter);
               DateOfLastLetter="";
               console.log(element.date_of_last_letter);
       
             }
             if(element.number_of_letter_sent===null){
               element.number_of_letter_sent="";
             }
             console.log(element);
             element['IEC No'] = element.iec_number;
             element['IEC Holder Name'] = element.iec_holder_name;
             element['AA No'] = element.auth_number;
             element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
             element['No. Of Days OverDue'] = element.no_of_days_overdue;
             element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
             element['BG Amount'] = element.bg_amount.replace(/,/g, '');
             element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
             element['No Of Last Letters Sent'] = element.number_of_letter_sent;
             element['SCN No'] = scnNo;
             element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");
       
             for (let i = 0; i < tableObj.length; i++) {
              for (let property in tableObj[i]) {
                // console.log(property);
                if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                  tableObj[i][property] = '';
                }
              }
            }
       // console.log(this.selectedTableDataforDownload[index].join(','));
           });
        const sortedElementsList = tableObj.sort((a, b) => a.application_id);
this.downloadFile(
  sortedElementsList,
  'AUTH AFTER EXP VALIDITY PERIOD(6-24 MONTH)' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
  displayedCol
);
for (let i = 0; i < tableObj.length; i++) {
  for (let property in tableObj[i]) {
    // console.log(property);
    if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
      tableObj[i][property] = '';
    }
  }
}
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  getAuthorizationAfterValidityMoreThan2YearsByFilter(inputData:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader4=true;
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // console.log(offSetData);
    // let input = {
    //   start_date: this.date,
    //   entries_per_page:pageSizeSet,
    //   offset:offSetData,
    //   // user:user,
    // };
    if(inputData.offset==1){
      this.pageSet = 0;
      }
      inputData.applicationType = this.applicationType ? this.applicationType : 'AA' 
    this.blockchainService
      .getAuthorizationAfterValidityMoreThan2YearsFilter(inputData)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.more2Resp = resp.body.obj.sd;
          this.datamore2Resp = resp.body.obj;
          // if(inputData.offset==1){
          // this.paginator4.pageIndex=0;}
          this.totalEntries=resp.body.obj.total_entries;
          if (this.more2Resp !== null) {
            this.loader4=false;
            var more2RespList = resp.body.obj.sd;
            this.more2Resp = new PaginatedDataSource<OneTwo, OneTwoQuery>(
              (request, query) =>
                this.blockchainService.pageOneTwo(request, query, more2RespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.more2Resp.pageSize=this.pageSizeChange;
          }

          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader4=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  downloadAuthorizationAfterValidityMoreThan2YearsByFilter(input) {
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // let inputData = {
    //   user_id: input,
    //   offset: offSetData,
    //   entries_per_page:pageSizeSet,
    // }
 
    input.applicationType = this.applicationType ? this.applicationType : 'AA';
    input.entries_per_page=1147483647;
    this.blockchainService
    .getAuthorizationAfterValidityMoreThan2YearsFilter(input)
    .subscribe((resp) => {
      if (resp.body.status == 200) {
          var displayedCol = []
          displayedCol.push('IEC No');
          displayedCol.push('IEC Holder Name');
          displayedCol.push('AA No');
          displayedCol.push('Export Validity Date');
          displayedCol.push('No. Of Days OverDue');
          displayedCol.push('Bond Amount');
          displayedCol.push('BG Amount');
          displayedCol.push('Date Of Last Letter');
          displayedCol.push('No Of Last Letters Sent');
          displayedCol.push('SCN No');
          displayedCol.push('SCN Date');
          this.download2YrRespFilteredData=resp.body.obj.sd;
                     
          console.log(this.download2YrRespFilteredData)
          var tableData:any=this.download2YrRespFilteredData;
          var tableObj =  tableData;
          console.log(tableObj,'tableobj')
      
          tableObj.forEach((element,index) => {
            let scnDate=element.scn_date;
            let DateOfLastLetter=element.date_of_last_letter;
            let scnNo=element.scn_no;
            if(element.iec_number===null){
              element.iec_number="";
            }
            if(element.scn_no===null){
              scnNo="";
            }
            if(element.scn_date===null){
              scnDate="";
            }
            if(element.date_of_last_letter===null){
              console.log(element.date_of_last_letter);
              DateOfLastLetter="";
              console.log(element.date_of_last_letter);
      
            }
            if(element.number_of_letter_sent===null){
              element.number_of_letter_sent="";
            }
            console.log(element);
            element['IEC No'] = element.iec_number;
            element['IEC Holder Name'] = element.iec_holder_name;
            element['AA No'] = element.auth_number;
            element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
            element['No. Of Days OverDue'] = element.no_of_days_overdue;
            element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
            element['BG Amount'] = element.bg_amount.replace(/,/g, '');
            element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
            element['No Of Last Letters Sent'] = element.number_of_letter_sent;
            element['SCN No'] = scnNo;
            element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");
      
            for (let i = 0; i < tableObj.length; i++) {
             for (let property in tableObj[i]) {
               // console.log(property);
               if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                 tableObj[i][property] = '';
               }
             }
           }
      // console.log(this.selectedTableDataforDownload[index].join(','));
          });
       const sortedElementsList = tableObj.sort((a, b) => a.application_id);
        this.downloadFile(
        sortedElementsList,
        'AUTH AFTER EXP VALIDITY PERIOD(MORE THAN 2YR)' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
        displayedCol);
        // }

        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
       else {
        this.totalEntries=0;
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    });
  }
  
  getAuthorizationAfterValidityMoreThan2Years(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader4=true;
    console.log(offSetData);
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      // user:user,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getAuthorizationAfterValidityMoreThan2Years(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.more2Resp = resp.body.obj.sd;
          this.datamore2Resp = resp.body.obj;
          this.totalEntries=resp.body.obj.total_entries;

          if (this.more2Resp !== null) {
            this.loader4=false;
            var more2RespList = resp.body.obj.sd;
            this.more2Resp = new PaginatedDataSource<OneTwo, OneTwoQuery>(
              (request, query) =>
                this.blockchainService.pageOneTwo(request, query, more2RespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.more2Resp.pageSize=this.pageSizeChange;
            if(this.paginator4){
            if(input.offset==1){
              this.paginator4.pageIndex=0;
              }
            }
          }

          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader4=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  downloadAuthorizationAfterValidityMoreThan2Years(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    console.log(offSetData);
    var displayedCol = []
    displayedCol.push('IEC No');
    displayedCol.push('IEC Holder Name');
    displayedCol.push('AA No');
    displayedCol.push('Export Validity Date');
    displayedCol.push('No. Of Days OverDue');
    displayedCol.push('Bond Amount');
    displayedCol.push('BG Amount');
    displayedCol.push('Date Of Last Letter');
    displayedCol.push('No Of Last Letters Sent');
    displayedCol.push('SCN No');
    displayedCol.push('SCN Date');
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      applicationType: this.applicationType ? this.applicationType : 'AA'
      // user:user,
    };
    console.log(input);
    this.blockchainService
      .getAuthorizationAfterValidityMoreThan2Years(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.oneTwoRespLength = resp.body.obj;
          // this.oneTwoResp = resp.body.obj.sd;
          // console.log(resp.body.obj);
          this.download2YrResp = resp.body.obj.sd;
           
           console.log(this.download2YrResp)
           var tableData:any=this.download2YrResp;
           var tableObj =  tableData;
           console.log(tableObj,'tableobj')
       
           tableObj.forEach((element,index) => {
             let scnDate=element.scn_date;
             let DateOfLastLetter=element.date_of_last_letter;
             let scnNo=element.scn_no;
             if(element.iec_number===null){
               element.iec_number="";
             }
             if(element.scn_no===null){
               scnNo="";
             }
             if(element.scn_date===null){
               scnDate="";
             }
             if(element.date_of_last_letter===null){
               console.log(element.date_of_last_letter);
               DateOfLastLetter="";
               console.log(element.date_of_last_letter);
       
             }
             if(element.number_of_letter_sent===null){
               element.number_of_letter_sent="";
             }
             console.log(element);
             element['IEC No'] = element.iec_number;
             element['IEC Holder Name'] = element.iec_holder_name;
             element['AA No'] = element.auth_number;
             element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
             element['No. Of Days OverDue'] = element.no_of_days_overdue;
             element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
             element['BG Amount'] = element.bg_amount.replace(/,/g, '');
             element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
             element['No Of Last Letters Sent'] = element.number_of_letter_sent;
             element['SCN No'] = scnNo;
             element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");
       
             for (let i = 0; i < tableObj.length; i++) {
              for (let property in tableObj[i]) {
                // console.log(property);
                if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                  tableObj[i][property] = '';
                }
              }
            }
       // console.log(this.selectedTableDataforDownload[index].join(','));
           });
        const sortedElementsList = tableObj.sort((a, b) => a.application_id);
this.downloadFile(
  sortedElementsList,
  'AUTH AFTER EXP VALIDITY PERIOD(MORE THAN 2YR)' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
  displayedCol
);
for (let i = 0; i < tableObj.length; i++) {
  for (let property in tableObj[i]) {
    // console.log(property);
    if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
      tableObj[i][property] = '';
    }
  }
}
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  getAuthorizationBeforeValidityFilter(inputData:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader5=true;
    // console.log(offSetData);
    // let input = {
    //   start_date: this.date,
    //   entries_per_page:pageSizeSet,
    //   offset:offSetData,
    //   // user:user,
    // };
    inputData.applicationType = this.applicationType ? this.applicationType : 'AA' 
    this.blockchainService
      .getAuthorizationBeforeValidityFilter(inputData)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.beforeValidityResp = resp.body.obj.sd;
          this.dataBeforeValidityResp=resp.body.obj;
          // if(inputData.offset==1){
          // this.paginator5.pageIndex=0;
          // }
          this.totalEntries=resp.body.obj.total_entries;

          if (this.beforeValidityResp !== null) {
            this.loader5=false;
            var beforeValidityRespList = resp.body.obj.sd;
            console.log(beforeValidityRespList);
            this.beforeValidityResp   = new PaginatedDataSource<OneTwo, OneTwoQuery>(
              (request, query) =>
                this.blockchainService.pageOneTwo(request, query, beforeValidityRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.beforeValidityResp.pageSize=this.pageSizeChange;
            console.log("ds",this.dataBeforeValidityResp);
            console.log("ds",this.beforeValidityResp);
          }

          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader5=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  downloadAuthorizationBeforeValidityFilter(input) {
    localStorage.setItem("pageSize", JSON.stringify(this.pageSizeChange));
    // let inputData = {
    //   user_id: input,
    //   offset: offSetData,
    //   entries_per_page:pageSizeSet,
    // }
 
    input.applicationType = this.applicationType ? this.applicationType : 'AA';
    input.entries_per_page=1147483647;
    this.blockchainService
    .getAuthorizationBeforeValidityFilter(input)
    .subscribe((resp) => {
      if (resp.body.status == 200) {
          var displayedCol = []
          displayedCol.push('IEC No');
          displayedCol.push('IEC Holder Name');
          displayedCol.push('AA No');
          displayedCol.push('Export Validity Date');
          // displayedCol.push('No. Of Days OverDue');
          displayedCol.push('Bond Amount');
          displayedCol.push('BG Amount');
          displayedCol.push('Date Of Last Letter');
          displayedCol.push('No Of Last Letters Sent');
          displayedCol.push('SCN No');
          displayedCol.push('SCN Date');
          this.downloadBeforeValidityRespFilteredData=resp.body.obj.sd;
                     
          console.log(this.downloadBeforeValidityRespFilteredData)
          var tableData:any=this.downloadBeforeValidityRespFilteredData;
          var tableObj =  tableData;
          console.log(tableObj,'tableobj')
      
          tableObj.forEach((element,index) => {
            let scnDate=element.scn_date;
            let DateOfLastLetter=element.date_of_last_letter;
            let scnNo=element.scn_no;
            if(element.iec_number===null){
              element.iec_number="";
            }
            if(element.scn_no===null){
              scnNo="";
            }
            if(element.scn_date===null){
              scnDate="";
            }
            if(element.date_of_last_letter===null){
              console.log(element.date_of_last_letter);
              DateOfLastLetter="";
              console.log(element.date_of_last_letter);
      
            }
            if(element.number_of_letter_sent===null){
              element.number_of_letter_sent="";
            }
            console.log(element);
            element['IEC No'] = element.iec_number;
            element['IEC Holder Name'] = element.iec_holder_name;
            element['AA No'] = element.auth_number;
            element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
            // element['No. Of Days OverDue'] = element.no_of_days_overdue;
            element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
            element['BG Amount'] = element.bg_amount.replace(/,/g, '');
            element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
            element['No Of Last Letters Sent'] = element.number_of_letter_sent;
            element['SCN No'] = scnNo;
            element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");
      
            for (let i = 0; i < tableObj.length; i++) {
             for (let property in tableObj[i]) {
               // console.log(property);
               if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                 tableObj[i][property] = '';
               }
             }
           }
      // console.log(this.selectedTableDataforDownload[index].join(','));
          });
       const sortedElementsList = tableObj.sort((a, b) => a.application_id);
        this.downloadFile(
        sortedElementsList,
        'AUTH BEFORE EXP VALIDITY PERIOD' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
        displayedCol);
        // }

        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
       else {
        this.totalEntries=0;
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  getAuthorizationBeforeValidity(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    this.loader5=true;
    console.log(offSetData);
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      // user:user,
      applicationType: this.applicationType ? this.applicationType : 'AA' 
    };
    this.blockchainService
      .getAuthorizationBeforeValidity(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          this.beforeValidityResp = resp.body.obj.sd;
          this.dataBeforeValidityResp=resp.body.obj;
          this.totalEntries=resp.body.obj.total_entries;

          if (this.beforeValidityResp !== null) {
            this.loader5=false;
            var beforeValidityRespList = resp.body.obj.sd;
            console.log(beforeValidityRespList);
            this.beforeValidityResp   = new PaginatedDataSource<OneTwo, OneTwoQuery>(
              (request, query) =>
                this.blockchainService.pageOneTwo(request, query, beforeValidityRespList),
              this.initialSort,
              { search: "", data: undefined },
              10
            );
            this.beforeValidityResp.pageSize=this.pageSizeChange;
            if(this.paginator5){
            if(input.offset==1){
              this.paginator5.pageIndex=0;
              }
            }
            console.log("ds",this.dataBeforeValidityResp);
            console.log("ds",this.beforeValidityResp);
          }

          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.loader5=false;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }

  downloadAuthorizationBeforeValidity(user:any, offSetData:any,pageSizeSet:any) {
    //this.blockUI.start("Please wait this may take few seconds ...");
    console.log(offSetData);
    var displayedCol = []
    displayedCol.push('IEC No');
    displayedCol.push('IEC Holder Name');
    displayedCol.push('AA No');
    displayedCol.push('Export Validity Date');
    // displayedCol.push('No. Of Days OverDue');
    displayedCol.push('Bond Amount');
    displayedCol.push('BG Amount');
    displayedCol.push('Date Of Last Letter');
    displayedCol.push('No Of Last Letters Sent');
    displayedCol.push('SCN No');
    displayedCol.push('SCN Date');
    let input = {
      start_date: this.date,
      entries_per_page:pageSizeSet,
      offset:offSetData,
      applicationType: this.applicationType ? this.applicationType : 'AA'
      // user:user,
    };
    console.log(input);
    this.blockchainService
      .getAuthorizationBeforeValidity(input)
      .subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.oneTwoRespLength = resp.body.obj;
          // this.oneTwoResp = resp.body.obj.sd;
          // console.log(resp.body.obj);
          this.download2YrResp = resp.body.obj.sd;
           
           console.log(this.download2YrResp)
           var tableData:any=this.download2YrResp;
           var tableObj =  tableData;
           console.log(tableObj,'tableobj')
       
           tableObj.forEach((element,index) => {
             let scnDate=element.scn_date;
             let DateOfLastLetter=element.date_of_last_letter;
             let scnNo=element.scn_no;
             if(element.iec_number===null){
               element.iec_number="";
             }
             if(element.scn_no===null){
               scnNo="";
             }
             if(element.scn_date===null){
               scnDate="";
             }
             if(element.date_of_last_letter===null){
               console.log(element.date_of_last_letter);
               DateOfLastLetter="";
               console.log(element.date_of_last_letter);
       
             }
             if(element.number_of_letter_sent===null){
               element.number_of_letter_sent="";
             }
             console.log(element);
             element['IEC No'] = element.iec_number;
             element['IEC Holder Name'] = element.iec_holder_name;
             element['AA No'] = element.auth_number;
             element['Export Validity Date'] = this.pipe.transform(element.evd, "dd-MM-yyyy");
            //  element['No. Of Days OverDue'] = element.no_of_days_overdue;
             element['Bond Amount']=element.bond_amount_in_inr.replace(/,/g, '');
             element['BG Amount'] = element.bg_amount.replace(/,/g, '');
             element['Date Of Last Letter'] = this.pipe.transform(DateOfLastLetter, "dd-MM-yyyy");
             element['No Of Last Letters Sent'] = element.number_of_letter_sent;
             element['SCN No'] = scnNo;
             element['SCN Date'] = this.pipe.transform(scnDate, "dd-MM-yyyy");
       
             for (let i = 0; i < tableObj.length; i++) {
              for (let property in tableObj[i]) {
                // console.log(property);
                if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
                  tableObj[i][property] = '';
                }
              }
            }
       // console.log(this.selectedTableDataforDownload[index].join(','));
           });
        const sortedElementsList = tableObj.sort((a, b) => a.application_id);
this.downloadFile(
  sortedElementsList,
  'AUTH BEFORE EXP VALIDITY PERIOD' + ' ' + this.pipe.transform(this.todayDate, "dd-MM-yyyy"),
  displayedCol
);
for (let i = 0; i < tableObj.length; i++) {
  for (let property in tableObj[i]) {
    // console.log(property);
    if (tableObj[i][property] === null || tableObj[i][property] === undefined) {
      tableObj[i][property] = '';
    }
  }
}
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        } else {
          this.totalEntries=0;
          this.showNotification(
            "bottom",
            "right",
            "danger",
            resp.body.message,
            "announcement"
          );
        }
      });
  }
  
  process(authno){
    let url1="authAfterExport";
    localStorage.setItem('authno',authno);
    localStorage.setItem('redirectedpage',url1);
    this.router.navigate(['/scnModulePS']);

  }

  back(){
    this.router.navigate(['/processorView']);
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
