import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Page, PageRequest } from "app/paging/page";
import { delay } from "rxjs/operators";
import * as CryptoJS from "crypto-js";
import { NormalResponse } from "./NormalResponse";
import { PeriodicElement } from "./admin/manage-user/manage-user.component";
import { ManageSection } from "./admin/manage-section/manage-section.component";
import { PaymentAcceptance } from "./customs_interface/custom-payment/payment-acceptance/payment-acceptance.component";
import { Registration } from "./customs_interface/aa_L1/step1/step1.component";
import { Import } from "./customs_interface/custom_obligation/import-cou/import-cou/import-cou.component";
import { Imitation } from "./customs_interface/customes_intimation/interface-custome-intimation/interface-custome-intimation.component";
import { ExportAA } from "./imp_exp/registration/export-aa/export-aa.component";
import { ImportAA } from "./imp_exp/registration/imp-aa/imp-aa.component";
import { SupportingProjAA } from "./imp_exp/registration/supporting-project-site/supporting-project-site.component";
import { HistoryData, PaymentHistoryData } from "./imp_exp/registration/history/history.component";
import { ImportOU } from "./imp_exp/obligation_update/import-ou/import-ou.component";
import { ExportOU } from "./imp_exp/obligation_update/export-ou/export-ou.component";
import { L2AuthorizationBeforeValidityAction } from "./customs_interface/obligation-mgmt/om/om.component";
import { OneTwo } from "./customs_interface/obligation-mgmt/om/authorization-after-export/authorization-after-export.component";
import { AuthSummary } from "./customs_interface/obligation-mgmt/authSummary/detail-auth-summary/detail-auth-summary.component";
import { SCN } from "./customs_interface/obligation-mgmt/show-cause/show-cause.component";
import { Mailbox } from "./customs_interface/mailbox/mailbox.component";
import { CancelledBond } from "./customs_interface/obligation-mgmt/om/cancelled-bond/cancelled-bond.component";
import { AmendmentPeriodicElement } from "./customs_interface/amendment-list/amendment-list.component";
import { L2AuthorizationAfterValidityAction } from "./customs_interface/obligation-mgmt/processor-view/processor-view.component";
import { ProofPaymentResponse } from "./customs_interface/aa_ao/validete-payment/validete-payment.component";
import { PreviewPayment } from "./imp_exp/payment/payment-preview/payment-preview.component";
import { SummaryReceived } from "./customs_interface/custom-payment/payment-received-summary/payment-received-summary.component";
import { MISDailySummary, MISDailySummaryData } from "./customs_interface/custom-payment/mis-daily-summary/mis-daily-summary.component";
// import 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { SummaryRegister } from "./customs_interface/custom-payment/payment-daily-register/payment-daily-register.component";


const localstorageCurrentUser:any = localStorage.getItem("currentUser")
const currentUser = JSON.parse(localstorageCurrentUser);

// export interface SummaryDetailsQuery {
//   search: string;
//   data: DataSummary[] ;
// }
export interface CustomsIntimationQuery {
  search: string;
  data: ProofPaymentResponse[];
}

export interface SummaryDetailsQuery1 {
  search: string;
  data: PeriodicElement[];
}

export interface MailboxDetailsQuery {
  search: string;
  data: Mailbox[];
}
export interface SectionDetailsQuery {
  search: string;
  data: ManageSection[];
}
export interface RegistrationDetailsQuery {
  search: string;
  data: Registration[];
}
export interface PreviewDetailsQuery {
  search: string;
  data: PreviewPayment[];
}

export interface ImportDetailsQuery {
  search: string;
  data: Import[];
}
export interface ImitationDetailsQuery {
  search: string;
  data: Imitation[];
}
export interface PaymentAcceptanceQuery {
  search: string;
  data: PaymentAcceptance[];
}

export interface SummaryReceivedQuery {
  search: string;
  data: SummaryReceived[];
}

export interface RegistrationQuery {
  search: string;
  data: Registration[];
}
export interface ExportAAQuery {
  search: string;
  data: ExportAA[];
}
export interface ImportAAQuery {
  search: string;
  data: ImportAA[];
}

export interface ImportOUQuery {
  search: string;
  data: ImportOU[];
}

export interface ExportOUQuery {
  search: string;
  data: ExportOU[];
}

export interface HistoryDetailsQuery {
  search: string;
  data: HistoryData[];
}

export interface HistoryPaymentDetailsQuery {
  search: string;
  data: PaymentHistoryData[];
}

export interface SupportingProjAAQuery {
  search: string;
  data: SupportingProjAA[];
}
export interface L2AuthorizationBeforeValidityActionQuery {
  search: string;
  data: L2AuthorizationBeforeValidityAction;
}


export interface L2AuthorizationAfterValidityActionQuery {
  search: string;
  data: L2AuthorizationAfterValidityAction;
}

export interface OneTwoQuery {
  search: string;
  data: OneTwo;
}

export interface AuthSummaryQuery {
  search: string;
  data: AuthSummary;
}

export interface AmendmentListQuery {
  search: string;
  data: AmendmentPeriodicElement;
}
export interface SCNQuery {
  search: string;
  data: SCN;
}

export interface CancelledBondQuery {
  search: string;
  data: CancelledBond;
}

export interface MISDailyDataQuery {
  search: string;
  data: MISDailySummaryData[];
}

@Injectable({
  providedIn: "root",
})
export class BlockchainService {
  filteredRuleEngineList = [];
  public cUser = JSON.parse(localStorage.getItem("currentUser"));
  accessToken = this.cUser ? this.cUser.obj.access_token : null;
  authToken = "Bearer " + "" + this.accessToken;

  tokenParse = "1beae597-7234-4452-858a-79c2adee1323";

  httpHeaderswithAuthformData = new HttpHeaders({
    Authorization: this.authToken,
  });

  httpHeaders = new HttpHeaders({
    "Content-Type": "application/json",
  });

  httpHeaderswithAuth = new HttpHeaders({
  
    "Content-Type": "application/json",
  });


  // For Prod
 getBaseUrl = "https://oms.accmumbai.gov.in/customs1.1/api/v1/";
 base2url = "https://oms.accmumbai.gov.in/customs1.1/";
 docDownload = 'https://oms.accmumbai.gov.in/customs1.1/api/v1/downloadDocument/';
 xlDownload = 'http://13.234.77.238/customs/Customs%20Intimation%20Redemption%20Details.xlsx';


  //  For UAT

  // getBaseUrl = "https://omsgate.sensez9.tech/customs1.0UAT/api/v1/"; 
  // base2url = "https://omsgate.sensez9.tech/customs1.0UAT/"; 
  // docDownload = 'https://omsgate.sensez9.tech/customs1.0UAT/api/v1/downloadDocument/';
  // xlDownload = 'https://omsgate.sensez9.tech/customs/Customs%20Intimation%20Redemption%20Document.xlsx';

  //FOR UAT LOCAL

  // getBaseUrl = "https://omsgate.sensez9.tech/customs1.0UATLocal/api/v1/"; 
  // base2url = "https://omsgate.sensez9.tech/customs1.0UATLocal/"; 
  // docDownload = 'https://omsgate.sensez9.tech/customs1.0UATLocal/api/v1/downloadDocument/';
  // xlDownload = 'https://omsgate.sensez9.tech/customs/Customs%20Intimation%20Redemption%20Document.xlsx';

    //  For Local
    
    // getBaseUrl = "http://3.86.184.35:8080/customs1.0UATLocal/api/v1/"; 
    // base2url = "http://3.86.184.35:8080/customs1.0UATLocal/"; 
    // docDownload = 'http://3.86.184.35:8080/customs1.0UATLocal/api/v1/downloadDocument/';
    // xlDownload = 'http://3.86.184.35:8080/customs/Customs%20Intimation%20Redemption%20Document.xlsx';
  
   
  adminUser: any;
  adminAction: string;
  step:number=0;
  currentLoggedInUserData = JSON.parse(localStorage.getItem("currentUser"));
  constructor(private http: HttpClient) { 

  }


  getSummonsLetter(input){
    let data = {
      "auth_number":"AuthNumber55",
      "created_by":"38"
  }
  return this.http.post<any>(this.getBaseUrl + "getSummonsLetter",input, {
    headers: this.httpHeaders,
    observe: "response",
  });
  }

  getDemandNoticeLetter(input){
    let data = 
      {
        "auth_number":"AuthNumber55",
        "created_by":"38"
    
    }
  return this.http.post<any>(this.getBaseUrl + "getDemandNoticeLetter",input, {
    headers: this.httpHeaders,
    observe: "response",
  });
  }

  getNonSubmissionLetter(input){
    let data = 
      {
        "auth_number":"AuthNumber55",
        "created_by":"38"
    
    }
  return this.http.post<any>(this.getBaseUrl + "getNonSubmissionLetter",input, {
    headers: this.httpHeaders,
    observe: "response",
  });
  }

  getScnLetter(input){
    let data = 
      {
        "auth_number":"AuthNumber55",
        "created_by":"38"
    
    }
  return this.http.post<any>(this.getBaseUrl + "getScnLetter",input, {
    headers: this.httpHeaders,
    observe: "response",
  });
  }


  //payment module
  uploadSupportingDocXls(file, payment_id) {
    let formData: FormData = new FormData();
    formData.append("file", file);
    // formData.append('filename', file.name);
    formData.append("payment_id", payment_id);

    return this.http
      .post(this.base2url + "uploadSupportingDocumentsXls", formData).pipe(map(data=>{}))
      // .subscribe((response:any)=>{
      //   return Observable;
      // })
  }




  updateFileAssignStatus(input){
    return this.http.post<any>(this.base2url + "updateFileAssignStatus",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getOfficerByApplicationIdExceptUserId(input){
    return this.http.post<any>(this.base2url + "getOfficerByApplicationIdExceptUserId",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getIecDetailsByAuthNumber(input){
    return this.http.post<any>(this.base2url + "getIecDetailsByAuthNumber",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  assignOfficerLeaveOrTransfer(input){
    return this.http.post<any>(this.base2url + "assignOfficerLeaveOrTransfer",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  TransferAllFiles(input){
    return this.http.post<any>(this.base2url + "api/v1/updateAllFileAssignStatus",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  changeAdminRights(input){
    return this.http.post<any>(this.base2url + "changeAdminRights",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getCustomUserBySectionAndLevel(input){
    return this.http.post<any>(this.base2url + "getCustomUserBySectionAndLevel",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getAllSections(input){
    return this.http.post<any>(this.base2url + "getAllSections",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getAllUserTypes(input){
    return this.http.post<any>(this.base2url + "getAllUserTypes",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getAllDesignations(input){
    return this.http.post<any>(this.base2url + "getAllDesignations",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  
  getAllPaymentMisDailySummaryV1(input){
    return this.http.post<any>(this.base2url + "getAllPaymentMisDailySummaryV1",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  sendOtpResetPass(input){
    return this.http.post<any>(this.base2url + "sendOtpResetPass",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  verifyOtpResetPass(input){
    return this.http.post<any>(this.base2url + "verifyOtpResetPass",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  resetPassword(input){
    return this.http.post<any>(this.base2url + "resetPassword",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  getAllPaymentModuleStateSummary(input){
    return this.http.post<any>(this.base2url + "getAllPaymentModuleStateSummary",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  getAllPayments() {
    return this.http.post<any>(this.base2url + "getAllPayments", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  getAllPaymentsPagination(input){
    return this.http.post<any>(this.base2url + "getAllPaymentsV1",input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getDDById(input) {
    return this.http.post<any>(this.base2url + "getDDById", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getCashById(input) {
    return this.http.post<any>(this.base2url + "getCashById", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  getPaymentDailySummaryById(input) {
    return this.http.post<any>(
      this.base2url + "getPaymentDailySummaryById",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }
  saveRegistrationDocument(input) {
    return this.http.post<any>(this.getBaseUrl + "saveRegistrationDocument", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  saveDenomination(input) {
    return this.http.post<any>(this.base2url + "saveDenomination", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  saveCustomsUsers(input) {
    return this.http.post<any>(this.base2url + "saveCustomsUsers", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  updateCustomsUsers(input) {
    return this.http.post<any>(this.base2url + "updateCustomsUsers", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  deleteCustomsUsers(input) {
    return this.http.post<any>(this.base2url + "deleteCustomsUsers", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getCustomsUsersById(input) {
    return this.http.post<any>(this.base2url + "getCustomsUsersById", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getAllSectionManagement() {
    return this.http.post<any>(this.base2url + "getAllSectionManagement", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  deleteSectionManagement(input) {
    return this.http.post<any>(
      this.base2url + "deleteSectionManagement",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }

  getSectionManagementById(input) {
    return this.http.post<any>(
      this.base2url + "getSectionManagementById",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }
  saveSectionManagement(input) {
    return this.http.post<any>(this.base2url + "saveSectionManagement", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  updateSectionManagement(input) {
    return this.http.post<any>(
      this.base2url + "updateSectionManagement",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }
  // updateDDStatus
  // updateCashStatus

  getDocumentType() {
    return this.http.post<any>(this.base2url + "getDocumentType", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  getAllZones() {
    return this.http.post<any>(this.base2url + "getAllZones", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getAllCustomsUsers() {
    return this.http.post<any>(this.base2url + "getAllCustomsUsers", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  getAllAmendmentRequests(input) {
    return this.http.post<any>(this.getBaseUrl + 'getAllAmendmentRequestsV1', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateAmendmentImpById(input) {
    return this.http.post<any>(this.getBaseUrl + 'updateAmendmentImpById', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateAmendmentExpById(input) {
    return this.http.post<any>(this.getBaseUrl + 'updateAmendmentExpById', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateApproveAmendmentById(input) {
    return this.http.post<any>(this.getBaseUrl + 'updateApproveAmendmentById', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getCustomsAmendmentById(input) {
    return this.http.post<any>(this.base2url + 'getCustomsAmendmentById', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getBankNames() {
    return this.http.post<any>(this.base2url + "getBankNames", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getCashChallanPdfs(input) {
    return this.http.post<any>(this.base2url + "getCashChallanPdfs", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getSbiChallanPdfs(input) {
    return this.http.post<any>(this.base2url + "getSbiChallanPdfs", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getNonSbiChallanPdfs(input) {
    return this.http.post<any>(this.base2url + "getNonSbiChallanPdfs", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }



  getPaymentDailySummaryByDate(input) {
    return this.http.post<any>(
      this.base2url + "getPaymentDailySummaryHead",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }

  getNOPSummary(input) {
    return this.http.post<any>(
      this.base2url + "getNOPSummary",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }
  getPaymentRecivedSummary(input) {
    return this.http.post<any>(
      this.base2url + "getPaymentRecivedSummary",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }

  getDailyPaymentRegister(input) {
    return this.http.post<any>(
      this.base2url + "getDailyPaymentRegister",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }

  getAcceptedChallanDD(input) {
    return this.http.post<any>(
      this.base2url + "getAcceptedChallanDDConfPending",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }


  getPaymentDailySummaryMis(input) {
    return this.http.post<any>(
      this.base2url + "getPaymentDailySummaryMis",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }

  getIpoByPaymentId(input) {
    return this.http.post<any>(this.base2url + "getIpoByPaymentId", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getSBFacilityByPaymentId(input) {
    return this.http.post<any>(this.base2url + "getSBFacilityByPaymentId", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  updateIpoStatus(input) {
    return this.http.post<any>(this.base2url + "updateIpoStatus", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  updateIpoConfirmation(input) {
    return this.http.post<any>(this.base2url + "updateIpoConfirmation", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  rejectChallan(input) {
    return this.http.post<any>(this.base2url + "rejectChallan", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  rejectChallanConfirmation(input){
    return this.http.post<any>(this.base2url + "rejectChallanConfirmation", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  updateDDStatus(input) {
    return this.http.post<any>(this.base2url + "updateDDStatus", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  updateDDConfirmation(input) {
    return this.http.post<any>(this.base2url + "updateDDConfirmation", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  updateCashStatus(input) {
    return this.http.post<any>(this.base2url + "updateCashStatus", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  updateCashConfirmation(input) {
    return this.http.post<any>(this.base2url + "updateCashConfirmation", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  updateSbStatus(input) {
    return this.http.post<any>(this.base2url + "updateSBFacilityStatus", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  updateSbConfirmation(input) {
    return this.http.post<any>(this.base2url + "updateSbFacilityConfirmation", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  saveDemandDraft(input) {
    return this.http.post<any>(this.base2url + "saveDemandDraft", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  saveSupportingDocument(input) {
    return this.http.post<any>(
      this.base2url + "saveSupportingDocuments",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }

  saveCash(input) {
    return this.http.post<any>(this.base2url + "saveCash", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  saveIpo(input) {
    return this.http.post<any>(this.base2url + "saveIpo", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  saveSbCollectFacility(input) {
    return this.http.post<any>(this.base2url + "saveSbCollectFacility", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  // customs registration
  getApplicationListByUserId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getApplicationListByUserId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  logout(input){
    return this.http.post<any>(this.getBaseUrl + 'logOut', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getApplicationListByUserIdPagination(input){
    return this.http.post<any>(this.getBaseUrl + 'getApplicationListByUserIdPagination', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getCancellationListByUserIdPagination(input){
    return this.http.post<any>(this.getBaseUrl + 'getCancellationListByUserIdPagination', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getApplicationFilterListByUserId(input){
    return this.http.post<any>(this.getBaseUrl + 'getApplicationFilterListByUserId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getExportValidity2MonthPeriodFilterListByFilter(input){
    return this.http.post<any>(this.getBaseUrl + 'getAuthorizationAfterValidityOneTwoMonthFilter', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getDownloadExportValidity2MonthPeriodFilterListByFilter(input){
    return this.http.post<any>(this.getBaseUrl + 'getAuthorizationAfterValidityOneTwoMonthFilter', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getExportValidity6MonthPeriodFilterListByFilter(input){
    return this.http.post<any>(this.getBaseUrl + 'getAuthorizationAfterValidityTwoSixMonthFilter', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getCancellationFilterListByUserId(input){
    return this.http.post<any>(this.getBaseUrl + 'getCancellationFilterListByUserId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getRegistrationDocumentByApplicationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getRegistrationDocumentByApplicationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getRegistrationDocumentByCancellationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getRegistrationDocumentByCancellationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getApplicationDetailsByApplicationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getApplicationDetailsByApplicationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateCancellationAlertStatus(input) {
    return this.http.post<any>(this.getBaseUrl + 'updateCancellationAlertStatus', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  updateApplicationAlertStatus(input) {
    return this.http.post<any>(this.getBaseUrl + 'updateAARegistrationAlertStatus', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getApplicationSummaryByApplicationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getApplicationSummaryByApplicationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getApplicationSummaryByApplicationIdSearchCase(input){
    return this.http.post<any>(this.getBaseUrl + 'getApplicationSummaryByApplicationIdSearchCase', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getGreenSheetByApplicationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getGreenSheetByApplicationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getGreenSheetByCancellationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getGreenSheetByCancellationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  saveGreenSheet(input) {
    console.log(input);
    let formData: FormData = new FormData();
    formData.append("application_id", input.application_id);
    formData.append("noteDate", input.noteDate);
    formData.append("name", input.name);
    formData.append("remarks", input.remarks);
    formData.append("designation", input.designation);
    formData.append("attached_document", input.attached_document);
    formData.append("cancellation_id", input.cancellation_id);
    formData.append("attached_document_path", input.attached_document_path);
    formData.append("html_flag", input.html_flag);
    formData.append("query_type",input.query_type);
    return this.http.post<any>(this.getBaseUrl + 'saveGreenSheet', formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }
  saveSCNGreenSheet(input) {
    console.log(input);
    let formData: FormData = new FormData();
    formData.append("reminderLetterId", input.reminderLetterId);
    formData.append("response_Letter_File", input.response_Letter_File);
    formData.append("PDF1",input.PDF1);
    formData.append("PDF2",input.PDF2);
    formData.append("PDF3",input.PDF3);
    formData.append("PDF4",input.PDF4);
    formData.append("PDF5",input.PDF5);
    formData.append("PDF6",input.PDF6);
    formData.append("PDF7",input.PDF7);
    formData.append("PDF8",input.PDF8);
    formData.append("PDF9",input.PDF9);
    formData.append("PDF10",input.PDF10);
    formData.append("EXCEL1",input.EXCEL1);
    formData.append("EXCEL2",input.EXCEL2);
    formData.append("EXCEL3",input.EXCEL3);
    formData.append("EXCEL4",input.EXCEL4);
    formData.append("EXCEL5",input.EXCEL5);
    formData.append("EXCEL6",input.EXCEL6);
    formData.append("EXCEL7",input.EXCEL7);
    formData.append("EXCEL8",input.EXCEL8);
    formData.append("EXCEL9",input.EXCEL9);
    formData.append("EXCEL10",input.EXCEL10);


    return this.http.post<any>(this.getBaseUrl + 'SaveReplyFromIEC', formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }

  saveAARegistrationApprovestatus(input) {
    let formData: FormData = new FormData();
    formData.append("application_id", input.application_id);
    formData.append("remarks", input.remarks);
    return this.http.post<any>(this.getBaseUrl + 'saveAARegistrationApprovestatus', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  saveCancellationRejectstatus(input) {
    let formData: FormData = new FormData();
    formData.append("cancellation_id", input.cancellation_id);
    formData.append("cancellation_approval_rejection_remarks", input.cancellation_approval_rejection_remarks);
    return this.http.post<any>(this.getBaseUrl + 'saveCancellationRejectstatus', formData, {
      headers:this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }
  saveAARegistrationRejectstatus(input) {
    return this.http.post<any>(this.getBaseUrl + 'saveAARegistrationRejectstatus', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  saveAARegistrationJobNumber(input) {
    return this.http.post<any>(this.getBaseUrl + 'saveAARegistrationJobNumber', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }


  saveAARegistrationBondNoAndBbg(input) {
    return this.http.post<any>(this.getBaseUrl + 'saveAARegistrationBondNoAndBbg', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAARegistrationJobNumberByApplicationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getAARegistrationJobNumberByApplicationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getBondAndBbgNoByApplicationId(input){
    return this.http.post<any>(this.getBaseUrl + 'getBondAndBbgNoByApplicationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateAARegistrationJobNumber(input) {
    return this.http.post<any>(this.getBaseUrl + 'updateAARegistrationJobNumber', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  saveAARegistrationLevelJobNumberByApplicationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'saveAARegistrationLevelJobNumberByApplicationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAllCustomsIntimationEodcRequest(input) {
    return this.http.post<any>(this.getBaseUrl + "getAllCustomsIntimationEodcRequestV1", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  updateIssueLicenseStatus(input) {
    return this.http.post<any>(this.getBaseUrl + "updateIssueLicenseStatus", input, {
      headers: this.httpHeaderswithAuth,
    });
  }
  saveCancellationApprovestatus(input) {
    let formData: FormData = new FormData();

    formData.append("cancellation_id", input.cancellation_id);
    formData.append("cancellation_approval_rejection_remarks", input.cancellation_approval_rejection_remarks);

    return this.http.post<any>(this.getBaseUrl + "saveCancellationApprovestatus", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });

  }
  saveDraftLetter(input) {
    let formData: FormData = new FormData();
    formData.append("cancellation_id", input.cancellation_id);
    formData.append("application_id", input.application_id);
    formData.append("draft_letter", input.draft_letter);
    formData.append("attached_document", input.attached_document);
    formData.append("draft_letter_status", input.draft_letter_status);
    formData.append("is_approved", input.is_approved);
    formData.append("query_type", input.query_type);

    return this.http.post<any>(this.getBaseUrl + "saveDraftLetter", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });

  }
  updateDraftLetter(input) {
    let formData: FormData = new FormData();

    formData.append("cancellation_id", input.cancellation_id);
    formData.append("application_id", input.application_id);
    formData.append("draft_letter", input.draft_letter);
    formData.append("attached_document", input.attached_document);
    formData.append("green_sheet_draft_letter_id", input.green_sheet_draft_letter_id);
    formData.append("is_approved", input.is_approved);
    formData.append("attached_document_path", input.attached_document_path);
    formData.append("query_type",input.query_type);

    return this.http.post<any>(this.getBaseUrl + "updateDraftLetter", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });

  }
  saveCancellationReturnDocumentPerson(input) {
    let formData: FormData = new FormData();
    formData.append("cancellation_id", input.cancellation_id);
    formData.append("name_of_person_collecting_document", input.name_of_person_collecting_document);
    formData.append("mobile_no_person_collecting_document", input.mobile_no_person_collecting_document);
    formData.append("authorization_letter_to_collect_document", input.authorization_letter_to_collect_document);
    formData.append("identity_card_person_collecting_document", input.identity_card_person_collecting_document);
    return this.http.post<any>(this.getBaseUrl + "saveCancellationReturnDocumentPerson", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }
  updateCancellationRegisterStatus(input) {

    return this.http.post<any>(this.getBaseUrl + "updateCancellationRegisterStatus", input, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }
  getAllProofOfPayments() {
    let input = {}
    return this.http.post<any>(this.getBaseUrl + 'getAllProofOfPayments', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getAllNatureOfPayments(input) {
    return this.http.post<any>(this.base2url + 'getAllNatureOfPayments', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAllFilterPayments(input){
    return this.http.post<any>(this.base2url + 'getAllPaymentsV1', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getPaymentAgainstCategoryByNOP(input) {
    return this.http.post<any>(this.base2url + 'getPaymentAgainstCategoryByNOP', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getAllPaymentAgainstCategory(input) {
    return this.http.post<any>(this.base2url + 'getAllPaymentAgainstCategory', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getSupportingDocumentsByPaymentIdV3(input) {
    return this.http.post<any>(this.base2url + 'getSupportingDocumentsByPaymentIdV3', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getProofOfPaymentsById(input) {
    return this.http.post<any>(this.getBaseUrl + 'getProofOfPaymentsById', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateProofOfPaymentsValidateStatus(input) {
    return this.http.post<any>(this.getBaseUrl + 'updateProofOfPaymentsValidateStatus', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  updateProofOfPaymentsRejectStatus(input) {
    return this.http.post<any>(this.getBaseUrl + 'updateProofOfPaymentsRejectStatus', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateProofOfPayments(input) {
    let formData: FormData = new FormData();
    formData.append("proof_of_payment_id", input.proof_of_payment_id);
    formData.append("iec_number", input.iec_number);
    formData.append("name_of_firm", input.name_of_firm);
    formData.append("auth_type", input.auth_type);
    formData.append("auth_no", input.auth_no);
    formData.append("auth_date", input.auth_date);
    formData.append("total_payment_made_against_auth", input.total_payment_made_against_auth);
    formData.append("date_of_payment_made_against_auth", input.total_payment_made_against_auth_date);


    formData.append("proof_of_payent_doc1", input.proof_of_payent_doc1);
    formData.append("proof_of_payent_doc2", input.proof_of_payent_doc2);
    formData.append("proof_of_payent_doc3", input.proof_of_payent_doc3);
    formData.append("proof_of_payent_doc4", input.proof_of_payent_doc4);
    formData.append("proof_of_payent_doc5", input.proof_of_payent_doc5);
    formData.append("proof_of_payent_doc6", input.proof_of_payent_doc6);
    formData.append("proof_of_payent_doc7", input.proof_of_payent_doc7);
    formData.append("proof_of_payent_doc8", input.proof_of_payent_doc8);
    formData.append("proof_of_payent_doc9", input.proof_of_payent_doc9);
    formData.append("proof_of_payent_doc10", input.proof_of_payent_doc10);

    return this.http.post<any>(this.getBaseUrl + "updateProofOfPayments", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }

  deleteProofOfPayments(input) {
    return this.http.post<any>(this.getBaseUrl + 'deleteProofOfPayments', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  saveProofOfPayments(input) {
    let formData: FormData = new FormData();

    formData.append("iec_number", input.iec_number);
    formData.append("name_of_firm", input.name_of_firm);
    formData.append("auth_type", input.auth_type);
    formData.append("auth_no", input.auth_no);
    formData.append("auth_date", input.auth_date);
    formData.append("total_payment_made_against_auth", input.total_payment_made_against_auth);
    formData.append("date_of_payment_made_against_auth", input.date_of_payment_made_against_auth);


    formData.append("proof_of_payent_doc1", input.proof_of_payent_doc1);
    formData.append("proof_of_payent_doc2", input.proof_of_payent_doc2);
    formData.append("proof_of_payent_doc3", input.proof_of_payent_doc3);
    formData.append("proof_of_payent_doc4", input.proof_of_payent_doc4);
    formData.append("proof_of_payent_doc5", input.proof_of_payent_doc5);
    formData.append("proof_of_payent_doc6", input.proof_of_payent_doc6);
    formData.append("proof_of_payent_doc7", input.proof_of_payent_doc7);
    formData.append("proof_of_payent_doc8", input.proof_of_payent_doc8);
    formData.append("proof_of_payent_doc9", input.proof_of_payent_doc9);
    formData.append("proof_of_payent_doc10", input.proof_of_payent_doc10);

    return this.http.post<any>(this.getBaseUrl + "saveProofOfPayments", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });


  }
  // trade cancellation
  saveCancellation(input) {
    let formData: FormData = new FormData();
    formData.append("auth_type", input.auth_type);
    formData.append("role", input.role);
    formData.append("upload_application_letter", input.upload_application_letter);
    formData.append("upload_export_obligation_discharge_certificate", input.upload_export_obligation_discharge_certificate);
    formData.append("upload_surrender_letter", input.upload_surrender_letter);
    formData.append("upload_regularization_letter", input.upload_regularization_letter);
    formData.append("upload_list_ofOther_documents_Being_submitted", input.upload_list_ofOther_documents_Being_submitted);
    formData.append("upload_pdf_of_other_documents", input.upload_pdf_of_other_documents);

    formData.append("upload_pdf_of_other_documents1", input.upload_pdf_of_other_documents1);
    formData.append("upload_pdf_of_other_documents2", input.upload_pdf_of_other_documents2);
    formData.append("upload_pdf_of_other_documents3", input.upload_pdf_of_other_documents3);
    formData.append("upload_pdf_of_other_documents4", input.upload_pdf_of_other_documents4);
    formData.append("upload_pdf_of_other_documents5", input.upload_pdf_of_other_documents5);
    formData.append("upload_pdf_of_other_documents6", input.upload_pdf_of_other_documents6);
    formData.append("upload_pdf_of_other_documents7", input.upload_pdf_of_other_documents7);
    formData.append("upload_pdf_of_other_documents8", input.upload_pdf_of_other_documents8);
    formData.append("upload_pdf_of_other_documents9", input.upload_pdf_of_other_documents9);
    formData.append("upload_pdf_of_other_documents10", input.upload_pdf_of_other_documents10);
    formData.append("upload_excel_sheet", input.upload_excel_sheet);
    formData.append("upload_excel_sheet1", input.upload_excel_sheet1);
    formData.append("upload_excel_sheet2", input.upload_excel_sheet2);
    formData.append("upload_excel_sheet3", input.upload_excel_sheet3);
    formData.append("upload_excel_sheet4", input.upload_excel_sheet4);
    formData.append("upload_excel_sheet5", input.upload_excel_sheet5);
    formData.append("upload_excel_sheet6", input.upload_excel_sheet6);
    formData.append("upload_excel_sheet7", input.upload_excel_sheet7);
    formData.append("upload_excel_sheet8", input.upload_excel_sheet8);
    formData.append("upload_excel_sheet9", input.upload_excel_sheet9);
    formData.append("upload_excel_sheet10", input.upload_excel_sheet10);
    formData.append("created_by", input.created_by);

    return this.http.post<any>(this.getBaseUrl + "saveCancellation", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }


  uploadCancellationDocumentsV1(input){
    let formData: FormData = new FormData();
    // formData.append("auth_type", input.auth_type);
    // formData.append("role", input.role);
    formData.append("cancellation_id", input.cancellation_id);
    formData.append("upload_application_letter", input.upload_application_letter);
    formData.append("upload_export_obligation_discharge_certificate", input.upload_export_obligation_discharge_certificate);
    formData.append("upload_surrender_letter", input.upload_surrender_letter);
    formData.append("upload_regularization_letter", input.upload_regularization_letter);
    formData.append("upload_list_ofOther_documents_Being_submitted", input.upload_list_ofOther_documents_Being_submitted);
    formData.append("upload_pdf_of_other_documents", input.upload_pdf_of_other_documents);

    formData.append("upload_pdf_of_other_documents1", input.upload_pdf_of_other_documents1);
    formData.append("upload_pdf_of_other_documents2", input.upload_pdf_of_other_documents2);
    formData.append("upload_pdf_of_other_documents3", input.upload_pdf_of_other_documents3);
    formData.append("upload_pdf_of_other_documents4", input.upload_pdf_of_other_documents4);
    formData.append("upload_pdf_of_other_documents5", input.upload_pdf_of_other_documents5);
    formData.append("upload_pdf_of_other_documents6", input.upload_pdf_of_other_documents6);
    formData.append("upload_pdf_of_other_documents7", input.upload_pdf_of_other_documents7);
    formData.append("upload_pdf_of_other_documents8", input.upload_pdf_of_other_documents8);
    formData.append("upload_pdf_of_other_documents9", input.upload_pdf_of_other_documents9);
    formData.append("upload_pdf_of_other_documents10", input.upload_pdf_of_other_documents10);
    formData.append("upload_excel_sheet", input.upload_excel_sheet);
    formData.append("upload_excel_sheet1", input.upload_excel_sheet1);
    formData.append("upload_excel_sheet2", input.upload_excel_sheet2);
    formData.append("upload_excel_sheet3", input.upload_excel_sheet3);
    formData.append("upload_excel_sheet4", input.upload_excel_sheet4);
    formData.append("upload_excel_sheet5", input.upload_excel_sheet5);
    formData.append("upload_excel_sheet6", input.upload_excel_sheet6);
    formData.append("upload_excel_sheet7", input.upload_excel_sheet7);
    formData.append("upload_excel_sheet8", input.upload_excel_sheet8);
    formData.append("upload_excel_sheet9", input.upload_excel_sheet9);
    formData.append("upload_excel_sheet10", input.upload_excel_sheet10);
    formData.append("iec_authority_letter", input.iec_authority_letter);

    formData.append("created_by", input.created_by);

    return this.http.post<any>(this.getBaseUrl + "uploadCancellationDocumentsV1", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }

  updateCancellationByPage(input) {
    return this.http.post<any>(this.getBaseUrl + "updateCancellationByPage", input, {
      headers: this.httpHeaderswithAuth,
    });
  }

  saveApplicationV1(input) {
    return this.http.post<any>(this.getBaseUrl + "saveCancellationV1", input, {
      headers: this.httpHeaderswithAuth,
    });
  }

  updateApplicationV1(input) {
    return this.http.post<any>(this.getBaseUrl + "updateCancellationV1", input, {
      headers: this.httpHeaderswithAuth,
    });
  }

  updateCancellation(input) {
    let formData: FormData = new FormData();
    let cancel_id = JSON.parse(localStorage.getItem("cancellation_id"));
    formData.append("cancellation_id", cancel_id);
    formData.append("auth_type", input.auth_type);
    formData.append("iec_number", input.iec_number);
    formData.append("name_of_firm", input.name_of_firm);
    formData.append("auth_number", input.auth_number);
    formData.append("auth_issue_date", input.auth_issue_date);
    formData.append("dgft_surrender_letter_no", input.dgft_surrender_letter_no);
    formData.append("dgft_surrender_letter_date", input.dgft_surrender_letter_date);
    formData.append("eodc_letter_no", input.eodc_letter_no);
    formData.append("eodc_letter_date", input.eodc_letter_date);
    formData.append("dgft_regularization_letter_no", input.dgft_regularization_letter_no);
    formData.append("dgft_regularization_letter_date", input.dgft_regularization_letter_date);

    return this.http.post<any>(this.getBaseUrl + "updateCancellation", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }
  getCancellationPdfByCancellationId(input) {
    return this.http.post<any>(this.getBaseUrl + "getCancellationPdfByCancellationId", input, {
      headers: this.httpHeaderswithAuth,
    });
  }


  getIecHolderByIecCode(input) {
    return this.http.post<any>(this.getBaseUrl + "getIecHolderByIecCode", input, {
      headers: this.httpHeaderswithAuth,
    });
  }

  getCancellationById(input) {
    return this.http.post<any>(this.getBaseUrl + "getCancellationById", input, {
      headers: this.httpHeaderswithAuth,
    });
  }
  getCancellationListByUserId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getCancellationListByUserId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getCancellationDetailsByCancellationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getCancellationDetailsByCancellationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getMyMailboxDetails(input) {

    return this.http.post<any>(this.getBaseUrl + 'getMyMailboxDetailsV2', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getMyMailboxApplicationDetailsByLetterNo(input) {

    return this.http.post<any>(this.getBaseUrl + 'getMyMailboxApplicationDetailsByLetterNo', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }



  getCancellationSummaryByCancellationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getCancellationSummaryByCancellationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getCancellationReturnDetailsByCancellationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getCancellationReturnDetailsByCancellationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getDraftLetterByApplicationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getDraftLetterByApplicationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getDraftLetterByCancellationId(input) {
    return this.http.post<any>(this.getBaseUrl + 'getDraftLetterByCancellationId', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateDraftLetterDiscardStatus(input) {
    return this.http.post<any>(this.getBaseUrl + 'updateDraftLetterDiscardStatus', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  //trade side
  updatePayments(input) {
    return this.http.post<any>(this.base2url + "updatePayments", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  //customs
  updatePaymentStatus(input) {
    return this.http.post<any>(this.base2url + "updatePaymentStatus", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  updatePaymentConfirmationStatus(input){
    return this.http.post<any>(this.base2url + "updatePaymentConfirmationStatus", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  saveHeads(input) {
    return this.http.post<any>(this.base2url + "saveHeads", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  updateSupportingDocumentsV3(input) {
    return this.http.post<any>(this.base2url + "updateSupportingDocumentsV3", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getDuties() {
    return this.http.post<any>(this.base2url + "getDuties", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getCommissionrate() {
    return this.http.post<any>(this.base2url + "getCommissionrate", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getSectionByCommissionrateId(input) {
    return this.http.post<any>(
      this.base2url + "getSectionByCommissionrateId",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }

  getPaymentById(input) {
    return this.http.post<any>(
      this.base2url + "getPaymentById",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }
  getMiscellaneous() {
    return this.http.post<any>(this.base2url + "getMiscellaneous", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getOvertime() {
    return this.http.post<any>(this.base2url + "getOvertime", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getGrandPaymentTotalByPaymentId(input){
    return this.http.post<any>(this.base2url+ "getGrandPaymentTotalByPaymentId", input, {
      headers: this.httpHeaderswithAuth,
    });
  }
  sendOtpIec(input) {
    return this.http.post<any>(this.base2url + "sendOtpIec", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  verifyOtpIec(input) {
    return this.http.post<any>(this.base2url + "verifyOtpIec", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  sendOtpCbcha(input) {
    return this.http.post<any>(this.base2url + "sendOtpCbcha", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }
  verifyOtpCbcha(input) {
    return this.http.post<any>(this.base2url + "verifyOtpCbcha", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  saveApplication(input) {
    console.log('auth token', this.httpHeaderswithAuthformData)
    let formData: FormData = new FormData();
    formData.append("aa_file", input.aa_file);
    formData.append("iec_file", input.iec_file);
    formData.append("application_type_id", input.application_type_id);
    formData.append("application_type_name", input.application_type_name);
    formData.append("user_roles", input.user_roles);
    formData.append("created_by", input.created_by);
    //this.httpHeaders.append('Authorization', `Bearer ${this.tokenParse}`);

    return this.http
      .post(this.getBaseUrl + "saveApplication", formData, {
        headers: this.httpHeaderswithAuthformData,
      })
      .pipe(map(data=>{
        return data
        // console.log(data,'save appln data')
      }))
      // .subscribe((response:any)=>{
      //   return Observable;
      // })
  }

  uploadCustomsIntimationRedemptionXls(input) {
   
    let formData: FormData = new FormData();
    formData.append("file", input.file);
    //this.httpHeaders.append('Authorization', `Bearer ${this.tokenParse}`);
    console.log(formData);
    return this.http
      .post(this.getBaseUrl + "uploadCustomsIntimationRedemptionXls", formData, {
        headers: this.httpHeaderswithAuthformData,
      })
      .pipe(map(data=>{}))
      // .subscribe((response:any)=>{
      //   return Observable;
      // })
  }


  getMyApplicationHistoryByUserId(input) {
    return this.http.post<any>(this.getBaseUrl + "getMyApplicationHistoryByUserId", input, {
      headers: this.httpHeaderswithAuth,
    });
  }

  updateApplicationIecApprovalStatus(input) {
    return this.http.post<any>(this.getBaseUrl + "updateApplicationIecApprovalStatus", input, {
      headers: this.httpHeaderswithAuth,
    });
  }



  getMyPaymentHistoryByUserId(input) {
    return this.http.post<any>(this.getBaseUrl + "getMyPaymentHistoryByUserId", input, {
      headers: this.httpHeaderswithAuth,
    });
  }

  updateApplication(input) {
    let formData: FormData = new FormData();
    formData.append("aa_file", input.aa_file);
    formData.append("iec_file", input.iec_file);
    formData.append("application_type_id", input.application_type_id);
    formData.append("application_type_name", input.application_type_name);
    formData.append("user_roles", input.user_roles);
    formData.append("created_by", input.created_by);
    formData.append("application_id", input.application_id);

    // this.httpHeaders.append('Authorization', `Bearer ${this.tokenParse}`);

    return this.http
      .post(this.getBaseUrl + "updateApplication", formData, {
        headers: this.httpHeaderswithAuthformData,
      })
      .pipe(map(data=>{
        return data
      }));
     
  }

  getApplicationType() {
    return this.http.post<any>(this.base2url + "getApplicationType", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getStates() {
    return this.http.post<any>(this.base2url + "getStates", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getCitesByState(input) {
    return this.http.post<any>(this.base2url + "getCitesByState", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getSBIPaymentsByDate(input) {
    return this.http.post<any>(this.base2url + "getSBIPaymentsByDate", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getNonSBIPaymentsByDate(input) {
    return this.http.post<any>(
      this.base2url + "getNonSBIPaymentsByDate",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }

  saveCbCha(input) {
    return this.http.post<any>(this.base2url + "saveCbCha", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  checkIecNo(input) {
    return this.http.post<any>(this.getBaseUrl + "checkIecNo", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  checkAuthNoCancellation(input){
    return this.http.post<any>(this.getBaseUrl + "checkAuthNoCancellation", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getIecHolderByIecNoNoauth(input) {
    return this.http.post<any>(this.base2url + "getIecHolderByIecNoNoauth", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  checkAuthNo(input) {
    return this.http.post<any>(this.getBaseUrl + "checkAuthNo", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  saveIECDetails(input) {
    return this.http.post<any>(this.base2url + "saveIec", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  updateIECDetails(input) {
    return this.http.post<any>(this.getBaseUrl + "updateIec", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  updateCbcha(input) {
    return this.http.post<any>(this.getBaseUrl + "updateCbcha", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }


  getAllIec() {
    return this.http.post<any>(this.getBaseUrl + "getAllIec", {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  getIecById(input) {
    return this.http.post<any>(this.getBaseUrl + "getIecById", input, {
      headers: this.httpHeaderswithAuth,
    });
  }

  getIecApplicationByApplicationId(input) {
    return this.http.post<any>(this.getBaseUrl + "getIecApplicationByApplicationId", input, {
      headers: this.httpHeaderswithAuth,
    });
  }

  getCbchaById(input) {
    return this.http.post<any>(this.getBaseUrl + "getCbchaById", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getSummaryDetails(input) {
    return this.http.post<any>(
      this.getBaseUrl + "getRequestByUserRole",
      input,
      {
        headers: this.httpHeaders,
        observe: "response",
      }
    );
  }

  createRequest(input, file) {
    let formData: FormData = new FormData();
    formData.append("data", JSON.stringify(input));
    formData.append("file", file);
    return this.http
      .post(this.getBaseUrl + "createRequest", formData).pipe(map(data=>{
        return data
      }))
      // .subscribe((response:any)=>{
      //   return Observable;
      // })
     
  }

  updateAARequest(input, file) {
    let formData: FormData = new FormData();
    formData.append("data", JSON.stringify(input));
    formData.append("file", file);
    return this.http
      .post(this.getBaseUrl + "updateAARequest", formData)
      .pipe(map(data=>{
        return data
      }))
      // .subscribe((response:any)=>{
      //   return Observable;
      // })
  }

  updateBGRequest(input, file) {
    let formData: FormData = new FormData();
    formData.append("data", JSON.stringify(input));
    formData.append("file", file);
    return this.http
      .post(this.getBaseUrl + "updateBGRequest", formData)
      .pipe(map(data=>{
        return data
      }))
      // .subscribe((response:any)=>{
      //   return Observable;
      // })
  }

  updateLegalRequest(input, file) {
    let formData: FormData = new FormData();
    formData.append("data", JSON.stringify(input));
    formData.append("file", file);
    return this.http
      .post(this.getBaseUrl + "updateLegalRequest", formData)
      .pipe(map(data=>{
        return data
      }))
      // .subscribe((response:any)=>{
      //   return Observable;
      // })
  }

  verifyRequest(input, file) {
    return this.http.post<any>(this.getBaseUrl + "verifyRequest", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  approveRequest(input, file) {
    return this.http.post<any>(this.getBaseUrl + "approveRequest", input, {
      headers: this.httpHeaders,
      observe: "response",
    });
  }

  saveIecApplicationV1(input){
    let formData: FormData = new FormData();
    formData.append("iec_no", input.iec_no);
    formData.append("pan_no", input.pan_no);
    formData.append("firm_name", input.firm_name);
    formData.append("nature_of_firm", input.nature_of_firm);
    formData.append("iec_issue_date", input.iec_issue_date);
    formData.append("registered_address", input.registered_address);
    formData.append("name_of_authorized_signatory", input.name_of_authorized_signatory);
    formData.append("application_id", input.application_id);
    formData.append("created_by", input.created_by);
    formData.append("iec_authority_letter", input.iec_authority_letter);
    console.log(formData,'formdata')
    return this.http.post<any>(this.getBaseUrl + "saveIecApplicationV1", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }

  updateIecApplicationV1(input){
    let formData: FormData = new FormData();
    formData.append("iec_id", input.iec_id);
    formData.append("iec_no", input.iec_no);
    formData.append("pan_no", input.pan_no);
    formData.append("firm_name", input.firm_name);
    formData.append("nature_of_firm", input.nature_of_firm);
    formData.append("iec_issue_date", input.iec_issue_date);
    formData.append("registered_address", input.registered_address);
    formData.append("name_of_authorized_signatory", input.name_of_authorized_signatory);
    formData.append("application_id", input.application_id);
    formData.append("created_by", input.created_by);
    formData.append("iec_authority_letter", input.iec_authority_letter);
    console.log(formData,'formdata')
    return this.http.post<any>(this.getBaseUrl + "updateIecApplicationV1", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }

  saveIecApplication(input) {
    return this.http.post<any>(this.getBaseUrl + "saveIecApplication", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateIecApplication(input) {
    return this.http.post<any>(
      this.getBaseUrl + "updateIecApplication",
      input,
      {
        headers: this.httpHeaderswithAuth,
        observe: "response",
      }
    );
  }
  getIecApplicationById(input) {
    return this.http.post<any>(
      this.getBaseUrl + "getIecApplicationById",
      input,
      {
        headers: this.httpHeaderswithAuth,
        observe: "response",
      }
    );
  }
  saveAAMaster(input) {
    return this.http.post<any>(this.getBaseUrl + "saveAaApplication", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateAAMaster(input) {
    return this.http.post<any>(this.getBaseUrl + "updateAaApplication", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAaApplicationById(input) {
    return this.http.post<any>(
      this.getBaseUrl + "getAaApplicationById",
      input,
      {
        headers: this.httpHeaderswithAuth,
        observe: "response",
      }
    );
  }

  getCurrencyList(input) {
    return this.http.post<any>(
      this.getBaseUrl + "getCurrencyList",
      input,
      {
        headers: this.httpHeaderswithAuth,
        observe: "response",
      }
    );
  }


  getAAMasterById(input) {
    return this.http.post<any>(
      this.getBaseUrl + "getAaApplicationById",
      input,
      {
        headers: this.httpHeaderswithAuth,
        observe: "response",
      }
    );
  }

  saveItemExported(input) {
    return this.http.post<any>(this.getBaseUrl + "saveItemExported", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateItemExported(input) {
    return this.http.post<any>(this.getBaseUrl + "updateItemExported", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAllBlockDataForSearchCase(input){
    return this.http.post<any>(this.getBaseUrl + "getAllBlockDataForSearchCase", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getItemExportedById(input) {
    return this.http.post<any>(this.getBaseUrl + "getItemExportedById", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  saveItemImported(input) {
    return this.http.post<any>(this.getBaseUrl + "saveItemImported", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateItemImported(input) {
    return this.http.post<any>(this.getBaseUrl + "updateItemImported", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getItemImportedById(input) {
    return this.http.post<any>(this.getBaseUrl + "getItemImportedById", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getApplicationById(input):Observable<any> {
    return this.http.post<any>(this.getBaseUrl + "getApplicationById", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getManufacturerById(input) {
    return this.http.post<any>(this.getBaseUrl + "getManufacturerById", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  saveManufacturer(input) {
    return this.http.post<any>(this.getBaseUrl + "saveManufacturer", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  updateManufacturer(input) {
    return this.http.post<any>(this.getBaseUrl + "updateManufacturer", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getBillOfEntryByAuthNumber(input) {
    return this.http.post<any>(
      this.getBaseUrl + "getBillOfEntryByAuthNumber", input,
      {
        headers: this.httpHeaderswithAuth,
        observe: "response",
      }
    );
  }

  updateBillOfEntryImport(input) {
    let formData: FormData = new FormData();
    formData.append("application_id", input.application_id);
    formData.append("auth_no", input.auth_no);
    formData.append("boe_date", input.boe_date);
    formData.append("boe_document_file", input.boe_document_file);
    formData.append("boe_no", input.boe_no);
    formData.append("cif_value_in_inr", input.cif_value_in_inr);
    formData.append("port_of_import_code", input.port_of_import_code);
    formData.append("product_qty", input.product_qty);
    formData.append("bill_of_entry_import_id", input.bill_of_entry_import_id);

    return this.http.post<any>(this.getBaseUrl + "updateBillOfEntryImport", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });

  }

  saveBillOfEntryImport(input) {
    let formData: FormData = new FormData();
    formData.append("application_id", input.application_id);
    formData.append("auth_no", input.auth_no);
    formData.append("boe_date", input.boe_date);
    formData.append("boe_document_file", input.boe_document_file);
    formData.append("boe_no", input.boe_no);
    formData.append("cif_value_in_inr", input.cif_value_in_inr);
    formData.append("port_of_import_code", input.port_of_import_code);
    formData.append("product_qty", input.product_qty);

    return this.http.post<any>(this.getBaseUrl + "saveBillOfEntryImport", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });


  }

  deleteBillOfEntryImport(input) {
    return this.http.post<any>(
      this.getBaseUrl + "deleteBillOfEntryImport",
      input,
      {
        headers: this.httpHeaderswithAuth,
        observe: "response",
      }
    );
  }

  getBillOfShippingByAuthNumber(input) {
    return this.http.post<any>(
      this.getBaseUrl + "getBillOfShippingByAuthNumber", input,
      {
        headers: this.httpHeaderswithAuth,
        observe: "response",
      }
    );
  }

  updateBillOfShippingExport(input) {
    return this.http.post<any>(
      this.getBaseUrl + "updateBillOfShippingExport",
      input,
      {
        headers: this.httpHeaderswithAuth,
        observe: "response",
      }
    );
  }

  saveBillOfShippingExport(input) {
    return this.http.post<any>(
      this.getBaseUrl + "saveBillOfShippingExport",
      input,
      {
        headers: this.httpHeaderswithAuth,
        observe: "response",
      }
    );
  }

  deleteBillOfShippingExport(input) {
    return this.http.post<any>(
      this.getBaseUrl + "deleteBillOfShippingExport",
      input,
      {
        headers: this.httpHeaderswithAuth,
        observe: "response",
      }
    );
  }

  getAllAuthNumber() {
    let input = {}
    return this.http.post<any>(this.getBaseUrl + 'getAllAuthNumber', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }



  saveCustomsIntimation(input) {
    let formData: FormData = new FormData();
    formData.append("created_by", input.created_by);
    formData.append("licence_type", input.licence_type);
    formData.append("license_no", input.license_no);
    formData.append("license_date", input.license_date);
    formData.append("eodc_application_date", input.eodc_application_date);
    formData.append("eodc_document_date", input.eodc_document_date);
    formData.append("eodc_document", input.eodc_document_path);
    formData.append("eodc_redemption_date", input.eodc_redemption_date);
    formData.append("eodc_redemption_letter", input.eodc_redemption_path);
    formData.append("eodc_rejection_date", input.eodc_rejection_date);
    formData.append("eodc_rejection_letter", input.eodc_rejection_path);
    formData.append("others_letters_date", input.others_letters_date);
    formData.append("others_letters", input.others_letters_path);
    formData.append("installation_certificate_date", input.installation_certificate_date);
    formData.append("installation_certificate", input.installation_certificate);
    formData.append("mid_term_certificate",input.mid_term_certificate);
    formData.append("mid_term_certificate_date",input.mid_term_certificate_date);

    return this.http.post<any>(this.getBaseUrl + "saveCustomsIntimation", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }
  updateCustomsAmendment(input) {
    let formData: FormData = new FormData();
    formData.append("iec_no", input.iec_no);
    formData.append("license_no", input.license_no);
    formData.append("import_validity_date", input.import_validity_date);
    formData.append("export_validity_date", input.export_validity_date);
    formData.append("amended_import_date", input.amended_import_date);
    formData.append("amended_export_date", input.amended_export_date);
    formData.append("upload_letter", input.upload_letter);
    formData.append("created_by", input.created_by);

    return this.http.post<any>(this.base2url + "updateCustomsAmendment", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }
  saveCustomsAmendment(input) {
    let formData: FormData = new FormData();
    formData.append("iec_no", input.iec_no);
    formData.append("license_no", input.license_no);
    formData.append("import_validity_date", input.import_validity_date);
    formData.append("export_validity_date", input.export_validity_date);
    formData.append("amended_import_date", input.amended_import_date);
    formData.append("amended_export_date", input.amended_export_date);
    formData.append("upload_letter", input.upload_letter_path);
    formData.append("created_by", input.created_by);

    return this.http.post<any>(this.getBaseUrl + "saveCustomsAmendment", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }

  checkIssuedIecNo(input) {
    return this.http.post<any>(this.getBaseUrl + "checkIssuedIecNo", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  savePayments(input): Observable<HttpResponse<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    }) 
    // https://oms.accmumbai.gov.in/customs1.1/savePayments
    return this.http.post<any>( this.base2url+"savePayments", input, {
      headers: httpHeaders,
      observe: 'response',
    })
  }
  getBondById(input) {
    return this.http.post<any>(this.getBaseUrl + "getBondById", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getApplicationDetailsForPdf(input) {
    return this.http.post<any>(this.getBaseUrl + "getApplicationDetailsForPdf", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getRegistrationPdfByApplicationId(input) {
    return this.http.post<any>(this.getBaseUrl + "getRegistrationPdfByApplicationId", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });

  }



  saveBond(input) {
    let formData: FormData = new FormData();
    formData.append("bond_file", input.bond_path);
    formData.append("bond_amount_inr", input.bond_amount_inr);
    formData.append("address_of_bank", input.address_of_bank);
    formData.append("amount_of_bank_guarantee", input.amount_of_bank_guarantee);
    formData.append("auto_renewal", input.auto_renewal);
    formData.append("bank_guarantee_number", input.bank_guarantee_number);
    formData.append("bank_guarantee_file", input.bank_guarantee_file);
    formData.append(
      "bank_gaurantee_issue_date",
      input.bank_gaurantee_issue_date
    );
    formData.append(
      "bank_gaurantee_last_validity_date",
      input.bank_gaurantee_last_validity_date
    );
    formData.append("ifsc_of_branch", input.ifsc_of_branch);
    formData.append("name_of_bank", input.name_of_bank);
    formData.append("name_of_branch", input.name_of_branch);
    formData.append(
      "start_exporter_house_copy_file",
      input.start_exporter_house_copy_path
    );
    formData.append("application_id", input.application_id);
    formData.append("start_exporter", input.start_exporter);
    formData.append("gst_registration_file", input.gst_registration_file);
    formData.append("rcmc_path", input.rcmc_path);
    formData.append(
      "turnover_certificate_file",
      input.turnover_certificate_file
    );
    formData.append("any_other_documet_file", input.any_other_documet_file);
    formData.append("remarks", input.remarks);
    formData.append("yes_bg_exemption", input.yes_bg_exemption);
    formData.append("no_bg_exemption", input.no_bg_exemption);
    formData.append("no_bg_doc", input.no_bg_doc);
  
    formData.append("created_by", input.created_by);
    

    return this.http.post<any>(this.getBaseUrl + "saveBond", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }

  updateBondByPage(input) {
    let formData: FormData = new FormData();
    formData.append("bond_id", input.bond_id);
    formData.append("application_id", input.application_id);
    formData.append("gst_registration_file", input.gst_registration_file);
    formData.append("rcmc_file", input.rcmc_file);
    formData.append(
      "turnover_certificate_file",
      input.turnover_certificate_file
    );
    formData.append("any_other_document_file", input.any_other_document_file);
    formData.append("star_export_house_copy", input.star_export_house_copy);
    formData.append("power_of_attorney", input.power_of_attorney);
    formData.append(
      "licence_script",
      input.licence_script
    );
    formData.append("affadavit", input.affadavit);
    formData.append("identity_proof", input.identity_proof);

    return this.http.post<any>(this.getBaseUrl + "updateBondByPage", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }

  updateBondByPage2(input){
    let formData: FormData = new FormData();
    formData.append("bond_id", input.bond_id);
    formData.append("application_id", input.application_id);
    formData.append("star_export_house_copy", input.star_export_house_copy);
    formData.append("power_of_attorney", input.power_of_attorney);
    formData.append(
      "licence_script",
      input.licence_script
    );
    formData.append("affadavit", input.affadavit);
    formData.append("identity_proof", input.identity_proof);

    return this.http.post<any>(this.getBaseUrl + "updateBondByPage2", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }

  updateBond(input) {
    let formData: FormData = new FormData();
    formData.append("bond_id", input.bond_id);
    formData.append("bond_file", input.bond_path);
    formData.append("bond_amount_inr", input.bond_amount_inr);
    formData.append("address_of_bank", input.address_of_bank);
    formData.append("amount_of_bank_guarantee", input.amount_of_bank_guarantee);
    formData.append("auto_renewal", input.auto_renewal);
    formData.append("bank_guarantee_number", input.bank_guarantee_number);
    formData.append("bank_guarantee_file", input.bank_guarantee_file);
    formData.append(
      "bank_gaurantee_issue_date",
      input.bank_gaurantee_issue_date
    );
    formData.append(
      "bank_gaurantee_last_validity_date",
      input.bank_gaurantee_last_validity_date
    );
    formData.append("ifsc_of_branch", input.ifsc_of_branch);
    formData.append("name_of_bank", input.name_of_bank);
    formData.append("name_of_branch", input.name_of_branch);
    formData.append(
      "start_exporter_house_copy_file",
      input.start_exporter_house_copy_path
    );
    formData.append("application_id", input.application_id);
    formData.append("start_exporter", input.start_exporter);
    formData.append("gst_registration_file", input.gst_registration_file);
    formData.append("rcmc_path", input.rcmc_path);
    formData.append(
      "turnover_certificate_file",
      input.turnover_certificate_file
    );
    formData.append("any_other_documet_file", input.any_other_documet_file);
    formData.append("remarks", input.remarks);
    formData.append("created_by", input.created_by);
    formData.append("yes_bg_exemption", input.yes_bg_exemption);
    formData.append("no_bg_exemption", input.no_bg_exemption);
    formData.append("no_bg_doc", input.no_bg_doc);
    return this.http.post<any>(this.getBaseUrl + "updateBond", formData, {
      headers: this.httpHeaderswithAuthformData,
      observe: "response",
    });
  }

  //Obligation Management
  getL2SummaryDetailsByDate(input) {
    return this.http.post<any>(this.getBaseUrl + "getL2SummaryDetailsByDate", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getL2AuthorizationAfterValidity(input) {
    return this.http.post<any>(this.getBaseUrl + "getL2AuthorizationAfterValidity", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getL2AuthorizationAfterValidityActionSummary(input) {
    return this.http.post<any>(this.getBaseUrl + "getL2AuthorizationAfterValidityActionSummary", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getL2AuthorizationBeforeValidityAction(input) {
    return this.http.post<any>(this.getBaseUrl + "getL2AuthorizationBeforeValidityAction", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationAfterValidityOneTwoMonthByPagination(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationAfterValidityOneTwoMonth", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationAfterValidityTwoSixMonth(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationAfterValidityTwoSixMonth", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationAfterValidityTwoSixMonthByFilter(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationAfterValidityTwoSixMonthFilter", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getAuthorizationAfterValiditySix24Month(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationAfterValiditySix24Month", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getAuthorizationAfterValiditySix24MonthFilter(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationAfterValiditySix24MonthFilter", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationAfterValidityMoreThan2Years(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationAfterValidityMoreThan2Years", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getAuthorizationAfterValidityMoreThan2YearsFilter(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationAfterValidityMoreThan2YearsFilter", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationBeforeValidity(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationBeforeValidity", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationBeforeValidityFilter(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationBeforeValidityFilter", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }


  getL2AuthorizationAfterExportValidityPeriodOver(input) {
    return this.http.post<any>(this.getBaseUrl + "getL2AuthorizationAfterExportValidityPeriodOver", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationEodcNotFilled(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationEodcNotFilled", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationEodcNotFilledFilter(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationEodcNotFilledFilter", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationEodcFilledNotGranted(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationEodcFilledNotGranted", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getAuthorizationEodcFilledNotGrantedFilter(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationEodcFilledNotGrantedFilter", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationEodcFilledRejectedByDgft(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationEodcFilledRejectedByDgft", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getAuthorizationEodcFilledRejectedByDgftFilter(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationEodcFilledRejectedByDgftFilter", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationEodcFilledApprovedByDgftNoCancellation(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationEodcFilledApprovedByDgftNoCancellation", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getAuthorizationEodcFilledApprovedByDgftNoCancellationFilter(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationEodcFilledApprovedByDgftNoCancellationFilter", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getAuthorizationEodcFilledApprovedByDgftCancellation(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationEodcFilledApprovedByDgftCancellation", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getAuthorizationEodcFilledApprovedByDgftCancellationFilter(input) {
    return this.http.post<any>(this.getBaseUrl + "getAuthorizationEodcFilledApprovedByDgftCancellationFilter", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getCompletePendencyAmountDueYes(input) {
    return this.http.post<any>(this.getBaseUrl + "getCompletePendencyAmountDueYes", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getCompletePendencySCNIssuedADJPending(input) {
    return this.http.post<any>(this.getBaseUrl + "getCompletePendencySCNIssuedADJPending", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getCompletePendencySCNIssuedADJAndAmountDueYes(input) {
    return this.http.post<any>(this.getBaseUrl + "getCompletePendencySCNIssuedADJAndAmountDueYes", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getCancellationBondSummary(input) {
    return this.http.post<any>(this.getBaseUrl + "getCancellationBondSummary", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getCancellationBondSummaryFilter(input) {
    return this.http.post<any>(this.getBaseUrl + "getCancellationBondSummaryFilter", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getBondCancellationAppFiled(input) {
    return this.http.post<any>(this.getBaseUrl + "getBondCancellationAppFiled", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getBondCancellationAppFiledFilter(input) {
    return this.http.post<any>(this.getBaseUrl + "getBondCancellationAppFiledFilter", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getMonthWiseRecoveryByYear(input) {
    return this.http.post<any>(this.getBaseUrl + "getMonthWiseRecoveryByYear", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getYearWiseRecoveryByYear(input) {
    return this.http.post<any>(this.getBaseUrl + "getYearWiseRecoveryByYear", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getL2AuthorizationAfterExportValidityActionPending(input) {
    return this.http.post<any>(this.getBaseUrl + "getL2AuthorizationAfterExportValidityActionPending", input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getL2AuthorizationBeforeExportValidityPeriodByBlock(input){
    return this.http.post<any>(this.getBaseUrl + "getL2AuthorizationBeforeExportValidityPeriodByBlock",input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  page1(
    request: PageRequest<ProofPaymentResponse>,
    query: CustomsIntimationQuery,
    ruleEngineList: ProofPaymentResponse[]
  ): Observable<Page<ProofPaymentResponse>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter((s) =>
     
      // s.auth_date = s.auth_date ? s.auth_date :''
        s.name_of_firm.toLowerCase().includes(search) ||
        s.iec_number.toLowerCase().includes(search) ||
        s.auth_type.toLowerCase().includes(search) ||
        s.auth_no.toLowerCase().includes(search) ||
        // s.auth_date !=null ? s.auth_date.includes(search) : false
        // s.created_date.toLowerCase().includes(search) ||
        // s.date_of_payment_made_against_auth.toLowerCase().includes(search)  ||
        s.total_payment_made_against_auth.includes(search)
        // s.validate_status.toLowerCase().includes(search) ||
        // s.validation_date.includes(search) 
      );
    }

    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageUser(request: PageRequest<PeriodicElement>,
    query: SummaryDetailsQuery1,
    ruleEngineList: PeriodicElement[]
  ): Observable<Page<PeriodicElement>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter((s) =>
        s.created_date.toLowerCase().includes(search)
      );
    }

    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  filteredRule(request, query, ruleEngineList) {

    this.filteredRuleEngineList = [...this.filteredRuleEngineList].sort((a, b) => {
      const propA = a[request.sort.property];
      const propB = b[request.sort.property];
      let result;
      if (typeof propA === "string") {
        result = propA
          .toLowerCase()
          .localeCompare(propB.toString().toLowerCase());
      } else {
        result = (propA as any) - (propB as any);
      }
      const factor = request.sort.order === "desc" ? 1 : -1;
      return result * factor;
    });

  }

  pageManageSection(
    request: PageRequest<ManageSection>,
    query: SectionDetailsQuery,
    ruleEngineList: ManageSection[]
  ): Observable<Page<ManageSection>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter((s) =>
        s.zone_name.toLowerCase().includes(search)
      );
    }

    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  // page(
  //   request: PageRequest<DataSummary>,
  //   query: SummaryDetailsQuery,
  //   ruleEngineList: DataSummary[]
  // ): Observable<Page<DataSummary>> {
  //   this.filteredRuleEngineList = ruleEngineList;
  //   let { search, data } = query;
  //   if (search) {
  //     search = search.toLowerCase();

  //     this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
  //       (s) =>
  //         s.iec_code.toLowerCase().includes(search) ||
  //         s.request_id.toLowerCase().includes(search) ||
  //         s.status_name.toLowerCase().includes(search)
  //     );
  //   }
  //   this.filteredRule(request, query, ruleEngineList);

  //   const start = request.page * request.size;
  //   const end = start + request.size;
  //   const pageUsers = this.filteredRuleEngineList.slice(start, end);
  //   const page = {
  //     content: pageUsers,
  //     number: request.page,
  //     size: pageUsers.length,
  //     totalElements: this.filteredRuleEngineList.length,
  //   };
  //   return of(page).pipe(delay(500));
  // }

  pageImitation(
    request: PageRequest<Imitation>,
    query: ImitationDetailsQuery,
    ruleEngineList: Imitation[]
  ): Observable<Page<Imitation>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) =>
          s.imp_exp_name.toLowerCase().includes(search) ||
          s.iec_code.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pagePaymentAcceptance(
    request: PageRequest<PaymentAcceptance>,
    query: PaymentAcceptanceQuery,
    ruleEngineList: PaymentAcceptance[]
  ): Observable<Page<PaymentAcceptance>> {
    let filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      filteredRuleEngineList = filteredRuleEngineList.filter(
        (s) =>
          s.iec_no.toLowerCase().includes(search) ||
          s.mobileNo.toLowerCase().includes(search) ||
          s.challan_date.toLowerCase().includes(search) ||
          s.challanReceiptDate.toLowerCase().includes(search) ||
          s.challan_number.toLowerCase().includes(search)
      );
    }

    filteredRuleEngineList = [...filteredRuleEngineList].sort((a, b) => {
      const propA = a[request.sort.property];
      const propB = b[request.sort.property];
      let result;
      result = (propA as any) - (propB as any);
      const factor = request.sort.order === "asc" ? 1 : -1;
      return result * factor;
    });

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageSummaryReceived(
    request: PageRequest<SummaryReceived>,
    query: SummaryReceivedQuery,
    ruleEngineList: SummaryReceived[]
  ): Observable<Page<SummaryReceived>> {
    let filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      filteredRuleEngineList = filteredRuleEngineList.filter(
        (s) =>
          s.challanNo.toLowerCase().includes(search) ||
          s.licenseType.toLowerCase().includes(search) ||
          s.licenseNo.toLowerCase().includes(search) ||
          s.commissionerate.toLowerCase().includes(search) 
         
      );
    }

    filteredRuleEngineList = [...filteredRuleEngineList].sort((a, b) => {
      const propA = a[request.sort.property];
      const propB = b[request.sort.property];
      let result;
      result = (propA as any) - (propB as any);
      const factor = request.sort.order === "asc" ? 1 : -1;
      return result * factor;
    });

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageSummaryRegistered(
    request: PageRequest<SummaryRegister>,
    query: SummaryReceivedQuery,
    ruleEngineList: SummaryRegister[]
  ): Observable<Page<SummaryRegister>> {
    let filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      filteredRuleEngineList = filteredRuleEngineList.filter(
        (s) =>
          s.challanNo.toLowerCase().includes(search) ||
          s.natureOfPayment.toLowerCase().includes(search) ||
          s.chaDetails.toLowerCase().includes(search) ||
          s.commissionerate.toLowerCase().includes(search) 
         
      );
    }

    filteredRuleEngineList = [...filteredRuleEngineList].sort((a, b) => {
      const propA = a[request.sort.property];
      const propB = b[request.sort.property];
      let result;
      result = (propA as any) - (propB as any);
      const factor = request.sort.order === "asc" ? 1 : -1;
      return result * factor;
    });

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageImport(
    request: PageRequest<Import>,
    query: ImportDetailsQuery,
    ruleEngineList: Import[]
  ): Observable<Page<Import>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter((s) =>
        s.boeNo.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageRegistration(
    request: PageRequest<Registration>,
    query: RegistrationDetailsQuery,
    ruleEngineList: Registration[]
  ): Observable<Page<Registration>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        s =>
          s.file_no.toLowerCase().includes(search) ||
          s.incoming_date.toLowerCase().includes(search) ||
          s.application_status.toLowerCase().includes(search) ||
          s.stage.toLowerCase().includes(search) ||
          s.imp_exp_name.toLowerCase().includes(search) ||
          s.license_no.toLowerCase().includes(search) ||
          s.iec_code.toLowerCase().includes(search) 
      )
    }

    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageCancellation(
    request: PageRequest<Registration>,
    query: RegistrationDetailsQuery,
    ruleEngineList: Registration[]
  ): Observable<Page<Registration>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        s =>
          s.file_no.toLowerCase().includes(search) ||
          s.incoming_date.toLowerCase().includes(search) ||
          s.application_status.toLowerCase().includes(search) ||
          s.stage.toLowerCase().includes(search) ||
          s.imp_exp_name.toLowerCase().includes(search) ||
          s.authorization_no.toLowerCase().includes(search)||
          s.iec_code.toLowerCase().includes(search) 
         
      )
    }

    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pagePreview(
    request: PageRequest<PreviewPayment>,
    query: PreviewDetailsQuery,
    ruleEngineList: PreviewPayment[]
  ): Observable<Page<PreviewPayment>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        s =>
          s.payment_category.toLowerCase().includes(search) ||
          s.payment_subcategory.toLowerCase().includes(search) ||
          s.commissionerate.toLowerCase().includes(search) ||
          s.nature_of_payment.toLowerCase().includes(search) ||
          s.section.toLowerCase().includes(search)

      )
    }

    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }
  pageExportAA(
    request: PageRequest<ExportAA>,
    query: ExportAAQuery,
    ruleEngineList: ExportAA[]
  ): Observable<Page<ExportAA>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) => s.items_names.toLowerCase().includes(search)
        // s.incommingDate.toLowerCase().includes(search) ||
        // s.applicationType.toLowerCase().includes(search)||
        // s.stage.toLowerCase().includes(search)||
        // s.applicationStatus.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageImportAA(
    request: PageRequest<ImportAA>,
    query: ImportAAQuery,
    ruleEngineList: ImportAA[]
  ): Observable<Page<ImportAA>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) => s.items_names.toLowerCase().includes(search)
        // s.incommingDate.toLowerCase().includes(search) ||
        // s.applicationType.toLowerCase().includes(search)||
        // s.stage.toLowerCase().includes(search)||
        // s.applicationStatus.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageAmendment(
    request: PageRequest<AmendmentPeriodicElement>,
    query: AmendmentListQuery,
    ruleEngineList: AmendmentPeriodicElement[]
  ): Observable<Page<AmendmentPeriodicElement>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) => s.items_names.toLowerCase().includes(search)
        // s.incommingDate.toLowerCase().includes(search) ||
        // s.applicationType.toLowerCase().includes(search)||
        // s.stage.toLowerCase().includes(search)||
        // s.applicationStatus.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageSupportingProjAA(
    request: PageRequest<SupportingProjAA>,
    query: SupportingProjAAQuery,
    ruleEngineList: SupportingProjAA[]
  ): Observable<Page<SupportingProjAA>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page:Page<SupportingProjAA> = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageHistory(
    request: PageRequest<HistoryData>,
    query: HistoryDetailsQuery,
    ruleEngineList: HistoryData[]
  ): Observable<Page<HistoryData>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
        search = search.toLowerCase();
      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) => s.authorization_no.toLowerCase().includes(search)||
        s.application_approval_status.toLowerCase().includes(search) ||
        s.status.toLowerCase().includes(search) ||
        s.authorization_type.toLowerCase().includes(search)||
        s.application_type.toLowerCase().includes(search)||
        s.iec_no.toLowerCase().includes(search)||
        s.customer_broker_id.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pagePaymentHistory(
    request: PageRequest<PaymentHistoryData>,
    query: HistoryPaymentDetailsQuery,
    ruleEngineList: PaymentHistoryData[]
  ): Observable<Page<PaymentHistoryData>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
        search = search.toLowerCase();
      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) => s.authorization_no.toLowerCase().includes(search)||
      s.application_approval_status.toLowerCase().includes(search) ||
      s.status.toLowerCase().includes(search) ||
       s.authorization_type.toLowerCase().includes(search)||
       s.application_type.toLowerCase().includes(search)||
       s.iec_no.toLowerCase().includes(search)||
       s.customer_broker_id.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageImportOU(
    request: PageRequest<ImportOU>,
    query: ImportOUQuery,
    ruleEngineList: ImportOU[]
  ): Observable<Page<ImportOU>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) => s.items_names.toLowerCase().includes(search)
        // s.incommingDate.toLowerCase().includes(search) ||
        // s.applicationType.toLowerCase().includes(search)||
        // s.stage.toLowerCase().includes(search)||
        // s.applicationStatus.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }
  MailPage(request: PageRequest<Mailbox>,
    query: MailboxDetailsQuery,
    ruleEngineList: Mailbox[]
  ): Observable<Page<Mailbox>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) => s.boe_no.toLowerCase().includes(search)
        // s.incommingDate.toLowerCase().includes(search) ||
        // s.applicationType.toLowerCase().includes(search)||
        // s.stage.toLowerCase().includes(search)||
        // s.applicationStatus.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }
  pageExportOU(
    request: PageRequest<ExportOU>,
    query: ExportOUQuery,
    ruleEngineList: ExportOU[]
  ): Observable<Page<ExportOU>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) => s.boe_no.toLowerCase().includes(search)
        // s.incommingDate.toLowerCase().includes(search) ||
        // s.applicationType.toLowerCase().includes(search)||
        // s.stage.toLowerCase().includes(search)||
        // s.applicationStatus.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageL2AuthorizationBeforeValidityAction(
    request: PageRequest<L2AuthorizationBeforeValidityAction>,
    query: L2AuthorizationBeforeValidityActionQuery,
    ruleEngineList: L2AuthorizationBeforeValidityAction[]
  ): Observable<Page<L2AuthorizationBeforeValidityAction>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) =>
          s.category.toLowerCase().includes(search)
        // s.boe_no.toLowerCase().includes(search)
        // s.incommingDate.toLowerCase().includes(search) ||
        // s.applicationType.toLowerCase().includes(search)||
        // s.stage.toLowerCase().includes(search)||
        // s.applicationStatus.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageOneTwo(
    request: PageRequest<OneTwo>,
    query: OneTwoQuery,
    ruleEngineList: OneTwo[]
  ): Observable<Page<OneTwo>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) =>
          s.application_id.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageAuthSummary(
    request: PageRequest<AuthSummary>,
    query: AuthSummaryQuery,
    ruleEngineList: AuthSummary[]
  ): Observable<Page<AuthSummary>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) =>
          s.application_id.toLowerCase().includes(search)
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }


  pageSCN(
    request: PageRequest<SCN>,
    query: SCNQuery,
    ruleEngineList: SCN[]
  ): Observable<Page<SCN>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();
      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) =>
        s.iec_no.toLowerCase().includes(search) ||
          s.scn_date.toLowerCase().includes(search) ||
          s.aa_no.toLowerCase().includes(search)||
          s.scn_no.toLowerCase().includes(search)

      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }

  pageCancelledBond(
    request: PageRequest<CancelledBond>,
    query: CancelledBondQuery,
    ruleEngineList: CancelledBond[]
  ): Observable<Page<CancelledBond>> {
    this.filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      this.filteredRuleEngineList = this.filteredRuleEngineList.filter(
        (s) =>
          s.iec_no.toLowerCase().includes(search) ||
          s.auth_number.toLowerCase().includes(search) 
        
      );
    }
    this.filteredRule(request, query, ruleEngineList);

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = this.filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: this.filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }


  //Obligation Management SCN Recovery module

  getOMRelaventInformationByAuthNo(input) {

    return this.http.post<any>(this.getBaseUrl + 'getOMRelaventInformationByAuthNo', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getScnDraftLetterByAuthNumber(input) {
    return this.http.post<any>(this.getBaseUrl + 'getScnDraftLetterByAuthNumber', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  getOMInformationByAuthNo(input){
    return this.http.post<any>(this.getBaseUrl + 'getOMInformationByAuthNo', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  

  getAllOMRelaventInformationByAuthNo(input) {
    return this.http.post<any>(this.getBaseUrl + 'getAllOMRelaventInformationByAuthNo', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getDocumentsRemarks(input) {
    return this.http.post<any>(this.getBaseUrl + 'getDocumentsRemarks', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getScnDetailsByAuthNumber(input) {
    return this.http.post<any>(this.getBaseUrl + 'getScnDetailsByAuthNumber', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  getReminderLetterByAuthNumber(input) {
    return this.http.post<any>(this.getBaseUrl + 'getAllReminderLetterDetails', input, {
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  saveScnDraftLetter(input) {
    return this.http.post<any>(this.getBaseUrl + "saveScnDraftLetter", input, {
      // headers: this.httpHeaderswithAuthformData,
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  updateScnDraftLetter(input) {
    return this.http.post<any>(this.getBaseUrl + "updateScnDraftLetter", input, {
      // headers: this.httpHeaderswithAuthformData,
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  saveScnDetails(input) {
    return this.http.post<any>(this.getBaseUrl + "saveScnDetails", input, {
      // headers: this.httpHeaderswithAuthformData,
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  updateScnDetails(input) {
    return this.http.post<any>(this.getBaseUrl + "updateScnDetails", input, {
      // headers: this.httpHeaderswithAuthformData,
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }
  saveComm(input) {
    return this.http.post<any>(this.getBaseUrl + "saveComm", input, {
      // headers: this.httpHeaderswithAuthformData,
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  saveCestatRa(input) {
    return this.http.post<any>(this.getBaseUrl + "saveCestatRa", input, {
      // headers: this.httpHeaderswithAuthformData,
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  saveHighCourt(input) {
    return this.http.post<any>(this.getBaseUrl + "saveHighCourt", input, {
      // headers: this.httpHeaderswithAuthformData,
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  saveSupreamCt(input) {
    return this.http.post<any>(this.getBaseUrl + "saveSupreamCt", input, {
      // headers: this.httpHeaderswithAuthformData,
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  saveNotice(input) {
    return this.http.post<any>(this.getBaseUrl + "saveNotice", input, {
      // headers: this.httpHeaderswithAuthformData,
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  savePaymentDetails(input) {
    return this.http.post<any>(this.getBaseUrl + "savePaymentDetails", input, {
      // headers: this.httpHeaderswithAuthformData,
      headers: this.httpHeaderswithAuth,
      observe: "response",
    });
  }

  pageMISDailySummaryRecord(
    request: PageRequest<MISDailySummaryData>,
    query: MISDailyDataQuery,
    ruleEngineList: MISDailySummaryData[]
  ): Observable<Page<MISDailySummaryData>> {
    let filteredRuleEngineList = ruleEngineList;
    let { search, data } = query;
    if (search) {
      search = search.toLowerCase();

      filteredRuleEngineList = filteredRuleEngineList.filter(
        (s) =>
          s.final_challan_no.toLowerCase().includes(search) ||
          s.depositor_name.toLowerCase().includes(search)
      );
    }

    filteredRuleEngineList = [...filteredRuleEngineList].sort((a, b) => {
      const propA = a[request.sort.property];
      const propB = b[request.sort.property];
      let result;
      // if (typeof propA === "string") {
      //   result = propA
      //     .toLowerCase()
      //     .localeCompare(propB.toString().toLowerCase());
      // } else {
      //   result = (propA as any) - (propB as any);
      // }
      result = (propA as any) - (propB as any);
      const factor = request.sort.order === "asc" ? 1 : -1;
      return result * factor;
    });

    const start = request.page * request.size;
    const end = start + request.size;
    const pageUsers = filteredRuleEngineList.slice(start, end);
    const page = {
      content: pageUsers,
      number: request.page,
      size: pageUsers.length,
      totalElements: filteredRuleEngineList.length,
    };
    return of(page).pipe(delay(500));
  }
}
