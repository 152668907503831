import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { is } from "date-fns/locale";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatStepper } from '@angular/material/stepper';

import { DateAdapter } from "@angular/material/core";
import { AppellateComponent } from "../appellate/appellate.component";
import { VoluntaryPaymentComponent } from "../voluntary-payment/voluntary-payment.component";
import { BaggageComponent } from "../baggage/baggage.component";
import { DisposalComponent } from "../disposal/disposal.component";
import { ForeignPostOfficeComponent } from "../foreign-post-office/foreign-post-office.component";
import { ManualChallanComponent } from "../manual-challan/manual-challan.component";
import { OtherPaymentComponent } from "../other-payment/other-payment.component";
import { PaymentsumComponent } from "../paymentsum/paymentsum.component";
declare var $: any;
@Component({
  selector: 'app-payment-application',
  templateUrl: './payment-application.component.html',
  styleUrls: ['./payment-application.component.css']
})
export class PaymentApplicationComponent implements OnInit {
  tabIndex = 0;
  isLinear = false;
  firstFormGroup = UntypedFormGroup;
  displayStyle = "none";
  secondFormGroup = UntypedFormGroup;
  @ViewChild(VoluntaryPaymentComponent) voluntarty: VoluntaryPaymentComponent;
  @ViewChild(AppellateComponent) applet: AppellateComponent;
  @ViewChild(BaggageComponent) baggage: BaggageComponent;
  @ViewChild(ManualChallanComponent) manual: ManualChallanComponent;
  @ViewChild(DisposalComponent) disposal: DisposalComponent;
  @ViewChild(ForeignPostOfficeComponent) foreign: ForeignPostOfficeComponent;
  @ViewChild(OtherPaymentComponent) other: OtherPaymentComponent;
  stay: boolean;
  @ViewChild('stepper', { static: false }) stepper: MatStepper;
  volFlag: string;
  aptFlag: string;
  bagFlag: string;
  disFlag: string;
  manFlag: string;
  forFlag: string;
  othFlag: string;
  constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute,
    private router: Router, public dialog: MatDialog, public blockchainService: BlockchainService) { }

  ngOnInit(): void {
 

    this.tabIndex = 0;
  }
  ngAfterViewInit() {
    this.volFlag = localStorage.getItem('voluntory1');
    this.aptFlag = localStorage.getItem('appllate');
    this.bagFlag = localStorage.getItem('baggage');
    this.disFlag = localStorage.getItem('disposal');
    this.manFlag = localStorage.getItem('mannual');
    this.forFlag = localStorage.getItem('foreign');
    this.othFlag = localStorage.getItem('other');
    setTimeout(() => {
      this.stepper.steps.forEach((step, idx) => {
        step.select = () => {

          if (this.tabIndex == 0) {
            this.voluntarty.saveHeads();
            this.voluntarty.grandCalculate();
            let vflag = localStorage.getItem('voluntory1');
           
            console.log(idx);
            if (vflag == 'false') {
              this.tabIndex = idx;
              this.applet.saveHeads();
              //this.voluntarty.saveHeads();
              this.baggage.saveHeads();
              this.manual.saveHeads();
              this.disposal.saveHeads();
              this.foreign.saveHeads();
              this.other.saveHeads();
              
            } else {
              this.tabIndex = this.tabIndex;
              
            }
          }
          if (this.tabIndex == 1) {
            this.applet.saveHeads();
            this.applet.grandCalculate();
            let aFlag = localStorage.getItem('appllate');
            console.log(this.tabIndex)
            if (aFlag == 'false') {
              this.tabIndex = idx;
              this.voluntarty.saveHeads();
              this.baggage.saveHeads();
              this.manual.saveHeads();
              this.disposal.saveHeads();
              this.foreign.saveHeads();
              this.other.saveHeads();
              this.applet.grandCalculate();
            } else {
              this.tabIndex = this.tabIndex;
              console.log("working");
            }
          }
          
          if (this.tabIndex == 2) {
            this.baggage.saveHeads();
            this.baggage.grandCalculate();
            let bFlag = localStorage.getItem('baggage');
            console.log(this.tabIndex)
            if (bFlag == 'false') {
              this.tabIndex = idx;
              this.applet.saveHeads();
              this.voluntarty.saveHeads();
              //this.baggage.saveHeads();
              this.manual.saveHeads();
              this.disposal.saveHeads();
              this.foreign.saveHeads();
              this.other.saveHeads();
              
            } else {
              this.tabIndex = this.tabIndex;
              
            }

          }
          if (this.tabIndex == 4) {
            this.disposal.saveHeads();
            this.disposal.grandCalculate();
            let dFlag = localStorage.getItem('disposal');

            if (dFlag == 'false') {
              this.tabIndex = idx;
              this.applet.saveHeads();
              this.voluntarty.saveHeads();
              this.baggage.saveHeads();
              this.manual.saveHeads();
              //this.disposal.saveHeads();
              this.foreign.saveHeads();
              this.other.saveHeads();
              
            } else {
              this.tabIndex = this.tabIndex;
              console.log("working");
            }

          }
          if (this.tabIndex == 3) {
            this.manual.saveHeads();
            this.manual.grandCalculate();
            let mFlag = localStorage.getItem('mannual');

            if (mFlag == 'false') {
              this.tabIndex = idx;
              this.applet.saveHeads();
              this.voluntarty.saveHeads();
              this.baggage.saveHeads();
              //this.manual.saveHeads();
              this.disposal.saveHeads();
              this.foreign.saveHeads();
              this.other.saveHeads();
              
            } else {
              this.tabIndex = this.tabIndex;
              console.log("working");
            }

          }
          if (this.tabIndex == 5) {
            this.foreign.saveHeads();
            this.foreign.grandCalculate();
            let fFlag = localStorage.getItem('foreign');

            if (fFlag == 'false') {
              this.tabIndex = idx;
              this.applet.saveHeads();
              this.voluntarty.saveHeads();
              this.baggage.saveHeads();
              this.manual.saveHeads();
              this.disposal.saveHeads();
              this.other.saveHeads();
            } else {
              this.tabIndex = this.tabIndex;
              console.log("working");
            }

          }
          if (this.tabIndex == 6) {
            this.other.saveHeads();
            this.other.grandCalculate();
            let oFlag = localStorage.getItem('other');

            if (oFlag == 'false') {
              this.tabIndex = idx;
              this.applet.saveHeads();
              this.voluntarty.saveHeads();
              this.baggage.saveHeads();
              this.manual.saveHeads();
              this.disposal.saveHeads();
              this.foreign.saveHeads();
              //this.other.saveHeads();
              
            } else {
              this.tabIndex = this.tabIndex;
              console.log("working");
            }

          }

        };
      });
    });
  }
  singleClick(flag, tab, id) {
    console.log(flag);
    console.log(tab);
    console.log(id);
    if (flag == false) {
      this.tabIndex = id;
    } else {
      // this.tabIndex = tab;
      console.log("working");
    }
  }
  paymentMode() {

    this.applet.saveHeads();

    this.baggage.saveHeads();
    this.manual.saveHeads();
    this.disposal.saveHeads();
    this.foreign.saveHeads();
    this.other.saveHeads();
    this.router.navigate(["/paymentMode"]);
    setTimeout(() => {
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Data Successfully Saved",
        "announcement"
      );
    }, 1000);

  }

  onStepChange(event: any): void {
    localStorage.removeItem('step');
    console.log("index", event);


    this.tabIndex = event.selectedIndex;
    localStorage.setItem('step', JSON.stringify(event.selectedIndex));
    if (this.tabIndex == 1) {
      //this.applet.saveHeads();

      this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      this.manual.saveHeads();
      this.disposal.saveHeads();
      this.foreign.saveHeads();
      this.other.saveHeads();
      this.applet.grandCalculate();
    }

    else if (this.tabIndex == 2) {

      this.applet.saveHeads();
      this.voluntarty.saveHeads();
      //this.baggage.saveHeads();
      this.manual.saveHeads();
      this.disposal.saveHeads();
      this.foreign.saveHeads();
      this.other.saveHeads();
      this.baggage.grandCalculate();
    }
    else if (this.tabIndex == 3) {

      this.applet.saveHeads();
      this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      //this.manual.saveHeads();
      this.disposal.saveHeads();
      this.foreign.saveHeads();
      this.other.saveHeads();
      this.manual.grandCalculate();
    }
    else if (this.tabIndex == 4) {
      this.applet.saveHeads();
      this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      this.manual.saveHeads();
      //this.disposal.saveHeads();
      this.foreign.saveHeads();
      this.other.saveHeads();
      this.disposal.grandCalculate();
    }
    else if (this.tabIndex == 5) {
      this.applet.saveHeads();
      this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      this.manual.saveHeads();
      this.disposal.saveHeads();
      //this.foreign.saveHeads();
      this.other.saveHeads();
      this.foreign.grandCalculate();
    }
    else if (this.tabIndex == 6) {
      this.applet.saveHeads();
      this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      this.manual.saveHeads();
      this.disposal.saveHeads();
      this.foreign.saveHeads();
      //this.other.saveHeads();
      this.other.grandCalculate();
    }
    else if (this.tabIndex == 0) {
      this.applet.saveHeads();
      //this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      this.manual.saveHeads();
      this.disposal.saveHeads();
      this.foreign.saveHeads();
      this.other.saveHeads();
      this.voluntarty.grandCalculate();
    }

  }



  saveData(stepper: MatStepper) {


    if (this.tabIndex == 1) {
      //this.applet.saveHeads();
      this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      this.manual.saveHeads();
      this.disposal.saveHeads();
      this.foreign.saveHeads();
      this.other.saveHeads();
      this.applet.grandCalculate();
    } else if (this.tabIndex == 2) {
      this.applet.saveHeads();
      this.voluntarty.saveHeads();
      //this.baggage.saveHeads();
      this.manual.saveHeads();
      this.disposal.saveHeads();
      this.foreign.saveHeads();
      this.other.saveHeads();
      this.baggage.grandCalculate();
    }
    else if (this.tabIndex == 3) {
      this.applet.saveHeads();
      this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      //this.manual.saveHeads();
      this.disposal.saveHeads();
      this.foreign.saveHeads();
      this.other.saveHeads();
      this.manual.grandCalculate();
    }
    else if (this.tabIndex == 4) {
      this.applet.saveHeads();
      this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      this.manual.saveHeads();
      //this.disposal.saveHeads();
      this.foreign.saveHeads();
      this.other.saveHeads();
      this.disposal.grandCalculate();
    }
    else if (this.tabIndex == 5) {
      this.applet.saveHeads();
      this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      this.manual.saveHeads();
      this.disposal.saveHeads();
      //this.foreign.saveHeads();
      this.other.saveHeads();
      this.foreign.grandCalculate();
    }
    else if (this.tabIndex == 6) {
      this.applet.saveHeads();
      this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      this.manual.saveHeads();
      this.disposal.saveHeads();
      this.foreign.saveHeads();
      //this.other.saveHeads();
      this.other.grandCalculate();
    }
    else if (this.tabIndex == 0) {
      this.applet.saveHeads();
      //this.voluntarty.saveHeads();
      this.baggage.saveHeads();
      this.manual.saveHeads();
      this.disposal.saveHeads();
      this.foreign.saveHeads();
      this.other.saveHeads();
      this.voluntarty.grandCalculate();
    }
    stepper.next();
  }

  openModalPayment() {
    // this.sectionDetails = section;
    // console.log(text)
    this.displayStyle = "block";
    const buttonModal2 = document.getElementById("openModalButton");
    // buttonModal2.click();
  }


  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  // openPopup() {
  //   this.displayStyle = "block";
  // }
  closePopup() {
    this.displayStyle = "none";
  }

  openDialog1(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      if (result.event === "paymentSum" && result.isUpdated === true) {
        this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }
  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
