import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService, ImportOUQuery } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { DateAdapter } from "@angular/material/core";
import { AlertService } from "app/_services";

declare var $: any;
export interface Status {
  value: string;
  viewValue: string;
}

export interface PeriodicElement {
  srNo: number;
  boeNo: string;
  boeDate: string;
  productQty: string;
  cifValue: string;
  // productDescr: string;
  // itchsCode: string;
  portImport: string;
  boeDoc:string;
  action:any
}



export interface ImportOU {
  application_id: number;
  created_date: string;
  deleted_date: string;
  import_serial_number: string;
  cif_for_foreign: string;
  cif_for_inr: string;
  itchs_code: string;
  input_description: string;
  item_id: number;
  items_names: string;
  quantity: string;
  uom: string;
}

@Component({
  selector: "app-import-ou",
  templateUrl: "./import-ou.component.html",
  styleUrls: ["./import-ou.component.css"],
})
export class ImportOUComponent implements OnInit {
  boeImpResp: PaginatedDataSource<ImportOU, ImportOUQuery>;
  initialSort: Sort<ImportOU> = { property: "created_date", order: "asc" };

  pipe = new DatePipe("en-US");


  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  modifiedCurrentDate: any;
  date: any;
  selectedFile: File;
  removeRowNo: any;
  url: string | ArrayBuffer;
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");

  displayedColumns = [
    "srNo",
    "boeNo",
    "boeDate",
    "productQty",
    "cifValue",
    // "productDescr",
    // "itchsCode",
    "portImport",
    "BOEDocument",
    "action",
  ];

  allAuth: any;
  selectedAuthNo: any;
  isShowMessage: boolean;
  showMessage: any;
  applicationType: string;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.getAllAuthNumber();
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationType = localStorage.getItem('applicationType')

  }

  addImportOU(data){
    data.update = true;
    this.router.navigate(["/addImportOU", data]);
  }

  deleteBillOfEntryImport(id) {
    console.log(id,'inside delete function');
    let input = {
      bill_of_entry_import_id: id
    }
    this.blockchainService.deleteBillOfEntryImport(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.getBillOfEntryByAuthNumber();
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      } else {
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  navigateExport() {
    this.router.navigate(["/exportOU"]);
  }

  getAllAuthNumber(){
    this.blockchainService.getAllAuthNumber().subscribe(resp => {
      this.allAuth = resp.body.obj;
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    )
  }

  authFn(e){
    console.log(e);
    this.selectedAuthNo = e
    this.getBillOfEntryByAuthNumber();
  }

  getBillOfEntryByAuthNumber() {
    let input = {
      "auth_number": this.selectedAuthNo,
    };
    console.log(input)
    this.blockchainService.getBillOfEntryByAuthNumber(input).subscribe((resp) => {
      this.boeImpResp = resp.body.obj;
      console.log(this.boeImpResp);
      if (this.boeImpResp !== null) {
        this.isShowMessage = false;
        var boeImpRespList = resp.body.obj;
        this.boeImpResp = new PaginatedDataSource<ImportOU, ImportOUQuery>(
          (request, query) =>
            this.blockchainService.pageImportOU(request, query, boeImpRespList),
          this.initialSort,
          { search: "", data: undefined },
          10
        );
      }
      else{
        this.isShowMessage = true;
        this.showMessage = resp.body.message
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }


  openModal1(srNo) {
    console.log(srNo,'element no')
    this.removeRowNo = srNo;
    const buttonModal1 = document.getElementById("openModalButton1");
    console.log("buttonModal", buttonModal1);
    buttonModal1.click();
  }

  onFileSelected(event) {
    console.log("clicked");
    console.log("clicked1", event);
    this.selectedFile = <File>event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); // read file as data url

    reader.onload = (event) => {
      // called once readAsDataURL is completed
      this.url = event.target.result;
    };
    this.onUpload();
  }

  onUpload() {
    const fd = new FormData();
    console.log(this.selectedFile, "selectedFile");
    fd.append("file", this.selectedFile, this.selectedFile.name);
    // this.submitRequest()
  }

  cancel() {
    this.router.navigate(["/applnSummary"]);
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log("Event", event);
    // this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    console.log("First Date=" + this.date);
    this.modifiedCurrentDate = this.date;
    console.log("helllooo", this.modifiedCurrentDate);
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  next() {
    this.router.navigate(["/exportOU"]);
  }

  back(){
    this.router.navigate(["/typeRole"])
  }

  save(){
    localStorage.setItem('saveFile',JSON.stringify(this.selectedFile));
    let input = {
      // aa_file:this.selectedFile,
      // iec_no:'abcd-efgh-ijkl-mnop',
      // application_type_id:'1',
      // application_type_name:'AA',
      // user_roles:'3',
    }
    console.log(input,'input')
    this.blockchainService.saveApplication(input).subscribe({next:(resp => {

    }),
    error:(error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }}
    )
    
  }
  statusFn(e, status) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
