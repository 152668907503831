import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { AlertService } from "app/_services";
import { locale } from "moment";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaymentsumComponent } from "../../payment/paymentsum/paymentsum.component";

declare var $: any;


@Component({
  selector: "app-iec-details",
  templateUrl: "./iec-details.component.html",
  styleUrls: ["./iec-details.component.css"],
})
export class IecDetailsComponent implements OnInit {
  pipe = new DatePipe("en-US");

  @BlockUI() blockUI: NgBlockUI | any;
  blockTemplate: BlockTemplateComponent | any;
  role: any;
  selectedDate: any;
  iecNo:any;
  iecForm = this.formBuilder.group({
    iecNo: ["",[
      Validators.required]],
    pan: ["",[
      Validators.required,
      Validators.pattern(
        "^[A-Za-z]{5}[0-9]{4}[A-Za-z]$"
      )]],
    firmName: [""],
    natureFirm: [""],
    selectedDate: [""],
    address: ["",Validators.required],
    signatory: [""],
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean |any;
  date: string |any;
  modifiedCurrentDate: string |any;
  lastDate: any;
  applicationIECResp: any;
  qrPath: any;
  iecId: string|any;
  isNext: boolean = false;
  isIecAuthorityLetter: boolean|any;
  selectedFile: File|any;
  url: string | ArrayBuffer|any;
  showPdf: boolean|any;
  pdfBaseUrl: string|any;
  authApplication: boolean|any;
  iecPdfUrl: string | any;
  sectionViewLetterDetails: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    let localstorageCurrentUser: any = localStorage.getItem("currentUser")
    this.currentUser = JSON.parse(localstorageCurrentUser);
    this.iecId = localStorage.getItem('iec_application_id');
     // this.pdfBaseUrl = "https://oms.accmumbai.gov.in/customs1.0/api/v1/downloadDocument/"
    //UAT URL IS SENSEZ9
    this.pdfBaseUrl = this.blockchainService.docDownload;
    this.iecForm.patchValue({
      iecNo: localStorage.getItem('iec_value')
    });
    this.iecNo = localStorage.getItem('iec_value')

    if(this.iecId != null && this.iecId != undefined){
      this.getIecApplicationById();
    } else{
      this.getIecApplicationByApplicationId();
    }
  }

  authorize(e: { value: string; }){
    console.log(e,'event that is being generated')
    if(e.value == "YES"){
      this.isIecAuthorityLetter = true;
    } else{
      this.isIecAuthorityLetter = false;
    }
  }

  onFileSelected(event:any) {
    let $img: any = document.querySelector('#file');
    this.selectedFile = <File><unknown>event.target.files[0];
    if(this.selectedFile.type == 'application/pdf'){
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFile); // read file as data url/
      reader.onload = (event:any) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      reader.readAsArrayBuffer($img.files[0]);
        this.onUpload();
    }
    else{
      event.target.files[0].value='';
      $img.value=''
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
   
  }

 
  onUpload() {
    const fd = new FormData();
    fd.append("file", this.selectedFile, this.selectedFile.name);
  }

  getIecApplicationByApplicationId(){
    let application_id = localStorage.getItem('application_id')
    let input = {
      application_id: application_id,
    };
    this.blockchainService.getIecApplicationByApplicationId(input).subscribe((resp) => {
      this.applicationIECResp = resp.obj;
    
      console.log(this.applicationIECResp,'checking the obj')
      this.qrPath = this.applicationIECResp.qr_code_path;
      this.iecForm.patchValue({
        iecNo: this.applicationIECResp.iec_no,
        pan: this.applicationIECResp.pan_no,
        firmName: this.applicationIECResp.firm_name,
        natureFirm: this.applicationIECResp.nature_of_firm,
        selectedDate: this.applicationIECResp.iec_issue_date,
        address: this.applicationIECResp.registered_address,
        signatory: this.applicationIECResp.name_of_authorized_signatory,
        // qr_code_path
      });
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        location.reload();
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
        location.reload();
      }
      else{}
    });
  }

  getIecApplicationById() {
    let application_id = localStorage.getItem('application_id')
    let input = {
      iec_id: this.iecId,
    };
    this.blockchainService.getIecApplicationById(input).subscribe((resp) => {
      this.applicationIECResp = resp.body.obj;
      this.qrPath = this.applicationIECResp.qr_code_path;
      this.iecForm.patchValue({
        iecNo: this.applicationIECResp.iec_no,
        pan: this.applicationIECResp.pan_no,
        firmName: this.applicationIECResp.firm_name,
        natureFirm: this.applicationIECResp.nature_of_firm,
        selectedDate: this.applicationIECResp.iec_issue_date,
        address: this.applicationIECResp.registered_address,
        signatory: this.applicationIECResp.name_of_authorized_signatory,
        
        // qr_code_path
      });
      this.modifiedCurrentDate = this.applicationIECResp.iec_issue_date;
      if(this.applicationIECResp.iec_authority_letter_path != null){
        this.showPdf = true
        this.isIecAuthorityLetter = true
        this.authApplication = true
        this.iecPdfUrl = this.pdfBaseUrl + this.applicationIECResp.iec_authority_letter_path
        this.selectedFile = this.applicationIECResp.iec_authority_letter_path
      } else{
        this.showPdf = false
        this.isIecAuthorityLetter = false
        this.authApplication = false
      }
      
    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        location.reload();
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
        location.reload();
      }
      else{}
    });
  }



  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    // this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date: string | number | Date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  next(event: any) {
    this.saveOrUpdate(event);
    localStorage.setItem("iec_value",this.iecForm.value.iecNo)
    this.isNext = true;
    // this.router.navigate(["/aaHeader"]);
  }

  checkIecNo(e: { target: { value: any; }; }){
    console.log(e.target.value,'checking iec no')
    let input = {
      iec_no : e.target.value
    }
    this.blockchainService.checkIecNo(input).subscribe(resp => {
      if(resp.body.status == 200){
        this.isButtonDisabled = false
      } 
  
      else{
        this.isButtonDisabled = true;
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        location.reload();
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
        location.reload();
      }
      else{}
    })
  }

  save(event: any) {
    this.saveOrUpdate(event);
  }

  back() {
    this.router.navigate(["/typeRole"]);
  }

  get f() {
    return this.iecForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.iecForm.controls[controlName].hasError(errorName);
  };

  saveOrUpdate(event: any) {
  
    if(this.iecForm.value.iecNo != '' && this.iecForm.value.iecNo != undefined && this.iecForm.value.iecNo != null){
        if(this.iecForm.value.pan != '' && this.iecForm.value.pan != undefined && this.iecForm.value.address != null && this.iecForm.value.pan != null){

          if(this.iecForm.value.firmName != '' && this.iecForm.value.firmName != undefined && this.iecForm.value.firmName != null ){

            if(this.iecForm.value.natureFirm != '' && this.iecForm.value.natureFirm != undefined && this.iecForm.value.natureFirm != null){

              if( this.modifiedCurrentDate != '' && this.modifiedCurrentDate != undefined && this.modifiedCurrentDate != null){

                if (
                  (this.applicationIECResp == undefined || this.applicationIECResp.iec_id == undefined ||
                  this.applicationIECResp.iec_id == null ||
                  this.applicationIECResp.iec_id == "0") && (this.iecId == null ||this.iecId == undefined)
                ) {
                if(this.isIecAuthorityLetter){
                  if(this.selectedFile  !== undefined){
                    let input = {
                      iec_no: this.iecForm.value.iecNo,
                      pan_no: this.iecForm.value.pan,
                      firm_name: this.iecForm.value.firmName,
                      nature_of_firm: this.iecForm.value.natureFirm,
                      iec_issue_date: this.modifiedCurrentDate,
                      registered_address: this.iecForm.value.address,
                      name_of_authorized_signatory: this.iecForm.value.signatory,
                      application_id: localStorage.getItem("application_id"),
                      created_by : this.currentUser.obj.user_id,
                      iec_authority_letter: this.selectedFile 
                    };
                    console.log(input);
                    this.blockUI.start("Please wait this may take few seconds ...");
                    this.blockchainService.saveIecApplicationV1(input).subscribe((resp) => {
                      console.log(resp);
                      if (resp.body.status == 200) {
                        
                        setTimeout(() => {
                          this.blockUI.stop();
                        }, 1500);
                        localStorage.setItem("iec_application_id", resp.body.obj);
                        localStorage.setItem('iec_no',this.iecForm.value.iecNo)
                        // location.reload();
                        this.showNotification(
                          "bottom",
                          "right",
                          "success",
                          resp.body.message,
                          "announcement"
                        );
                        if(this.isNext == true){
                          this.router.navigate(["/aaHeader"]);
                        } else{}
                      } 
                       else {
                        setTimeout(() => {
                          this.blockUI.stop();
                        }, 1500);
                        this.showNotification(
                          "bottom",
                          "right",
                          "success",
                          resp.body.message,
                          "announcement"
                        );
                      }
                    }, (error) => {
                      console.log(error)
                      this.alertService.error(error);
                      if(error.status == 401){
                        this.showNotification(
                          "bottom",
                          "right",
                          "success",
                          "Session Expired, Please Login Again",
                          "announcement"
                        );
                        // location.reload();
                        this.router.navigate(["/login"]);
                      }
                      else if(error.status == 500){
                        setTimeout(() => {
                          this.blockUI.stop();
                        }, 1500);
                        this.showNotification(
                          "bottom",
                          "right",
                          "success",
                          "Please Try Again",
                          "announcement"
                        );
                        // location.reload();
                      }
                      else{}
                    });
                  } else{
                    setTimeout(() => {
                      this.blockUI.stop();
                    }, 1500);
                    this.showNotification(
                      "bottom",
                      "right",
                      "success",
                      'Please Upload IEC Holders Authority Letter',
                      "announcement"
                    );
                  }
              } else{
                  let input = {
                    iec_no: this.iecForm.value.iecNo,
                    pan_no: this.iecForm.value.pan,
                    firm_name: this.iecForm.value.firmName,
                    nature_of_firm: this.iecForm.value.natureFirm,
                    iec_issue_date: this.modifiedCurrentDate,
                    registered_address: this.iecForm.value.address,
                    name_of_authorized_signatory: this.iecForm.value.signatory,
                    application_id: localStorage.getItem("application_id"),
                    created_by : this.currentUser.obj.user_id,
                    iec_authority_letter: null 
                  };
                  console.log(input);
                  this.blockUI.start("Please wait this may take few seconds ...");
                  this.blockchainService.saveIecApplicationV1(input).subscribe((resp) => {
                    console.log(resp);
                    if (resp.body.status == 200) {
                      
                      setTimeout(() => {
                        this.blockUI.stop();
                      }, 1500);
                      localStorage.setItem("iec_application_id", resp.body.obj);
                      localStorage.setItem('iec_no',this.iecForm.value.iecNo)
                      // location.reload();
                      this.showNotification(
                        "bottom",
                        "right",
                        "success",
                        resp.body.message,
                        "announcement"
                      );
                      if(this.isNext == true){
                        this.router.navigate(["/aaHeader"]);
                      } else{}
                    } else {
                      setTimeout(() => {
                        this.blockUI.stop();
                      }, 1500);
                      this.showNotification(
                        "bottom",
                        "right",
                        "success",
                        resp.body.message,
                        "announcement"
                      );
                    }
                  }, (error) => {
                    console.log(error)
                    this.alertService.error(error);
                    if(error.status == 401){
                      this.showNotification(
                        "bottom",
                        "right",
                        "success",
                        "Session Expired, Please Login Again",
                        "announcement"
                      );
                      // location.reload();
                      this.router.navigate(["/login"]);
                    }
                    else if(error.status == 500){
                      setTimeout(() => {
                        this.blockUI.stop();
                      }, 1500);
                      this.showNotification(
                        "bottom",
                        "right",
                        "success",
                        "Please Try Again",
                        "announcement"
                      );
                      // location.reload();
                    }
                    else{}
                  });
              }
            }
            //update function
                 else {
                  if(this.isIecAuthorityLetter){
                    if(this.selectedFile  !== undefined){
                      let input = {
                        iec_id: this.iecId ? this.iecId : this.applicationIECResp.iec_id,
                        iec_no: this.iecForm.value.iecNo,
                        pan_no: this.iecForm.value.pan,
                        firm_name: this.iecForm.value.firmName,
                        nature_of_firm: this.iecForm.value.natureFirm,
                        iec_issue_date: this.modifiedCurrentDate,
                        registered_address: this.iecForm.value.address,
                        name_of_authorized_signatory: this.iecForm.value.signatory,
                        application_id: localStorage.getItem("application_id"),
                        created_by : this.currentUser.obj.user_id,
                        iec_authority_letter: this.selectedFile 
                      };
                      this.blockUI.start("Please wait this may take few seconds ...");
                      this.blockchainService.updateIecApplicationV1(input).subscribe((resp) => {
                        console.log(resp);
                        if (resp.body.status == 200) {
                          localStorage.setItem('iec_no',this.iecForm.value.iecNo);
                          this.getIecApplicationById();
                         
                          setTimeout(() => {
                            this.blockUI.stop();
                          }, 1500);
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            resp.body.message,
                            "announcement"
                          );
                          if(this.isNext == true){
                            this.router.navigate(["/aaHeader"]);
                          } else{}
                        }  else {
                          setTimeout(() => {
                            this.blockUI.stop();
                          }, 1500);
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            resp.body.message,
                            "announcement"
                          );
                        }
                      }, (error) => {
                        console.log(error)
                        this.alertService.error(error);
                        if(error.status == 401){
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            "Session Expired, Please Login Again",
                            "announcement"
                          );
                          // location.reload();
                          this.router.navigate(["/login"]);
                        }
                        else if(error.status == 500){
                          setTimeout(() => {
                            this.blockUI.stop();
                          }, 1500);
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            "Please Try Again",
                            "announcement"
                          );
                          // location.reload();
                        }
                        else{}
                      });
                    } else{
                      setTimeout(() => {
                        this.blockUI.stop();
                      }, 1500);
                      this.showNotification(
                        "bottom",
                        "right",
                        "success",
                        'Please Upload IEC Holders Authority Letter',
                        "announcement"
                      ); 
                    }
                   
                  } else{
                    let input = {
                      iec_id: this.iecId ? this.iecId : this.applicationIECResp.iec_id,
                      iec_no: this.iecForm.value.iecNo,
                      pan_no: this.iecForm.value.pan,
                      firm_name: this.iecForm.value.firmName,
                      nature_of_firm: this.iecForm.value.natureFirm,
                      iec_issue_date: this.modifiedCurrentDate,
                      registered_address: this.iecForm.value.address,
                      name_of_authorized_signatory: this.iecForm.value.signatory,
                      application_id: localStorage.getItem("application_id"),
                      created_by : this.currentUser.obj.user_id,
                      iec_authority_letter: null
                    };
                    this.blockUI.start("Please wait this may take few seconds ...");
                    this.blockchainService.updateIecApplicationV1(input).subscribe((resp) => {
                      console.log(resp);
                      if (resp.body.status == 200) {
                        localStorage.setItem('iec_no',this.iecForm.value.iecNo);
                        this.getIecApplicationById();
                       
                        setTimeout(() => {
                          this.blockUI.stop();
                        }, 1500);
                        this.showNotification(
                          "bottom",
                          "right",
                          "success",
                          resp.body.message,
                          "announcement"
                        );
                        if(this.isNext == true){
                          this.router.navigate(["/aaHeader"]);
                        } else{}
                      }  else {
                        setTimeout(() => {
                          this.blockUI.stop();
                        }, 1500);
                        this.showNotification(
                          "bottom",
                          "right",
                          "success",
                          resp.body.message,
                          "announcement"
                        );
                      }
                    }, (error) => {
                      console.log(error)
                      this.alertService.error(error);
                      if(error.status == 401){
                        this.showNotification(
                          "bottom",
                          "right",
                          "success",
                          "Session Expired, Please Login Again",
                          "announcement"
                        );
                        // location.reload();
                        this.router.navigate(["/login"]);
                      }
                      else if(error.status == 500){
                        setTimeout(() => {
                          this.blockUI.stop();
                        }, 1500);
                        this.showNotification(
                          "bottom",
                          "right",
                          "success",
                          "Please Try Again",
                          "announcement"
                        );
                        // location.reload();
                      }
                      else{}
                    });
                  }
                 
                }
              } else{
                //date
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  'Please Select Date Of Issue',
                  "announcement"
                );
              }
            } else{
              //nature of firm
              this.showNotification(
                "bottom",
                "right",
                "success",
                'Please Enter Nature Of Firm',
                "announcement"
              );
            }
          } else{
            // firm name
            this.showNotification(
              "bottom",
              "right",
              "success",
              'Please Enter Firm Name',
              "announcement"
            );
          }
        }
        else{
          //pan no.
          this.showNotification(
            "bottom",
            "right",
            "success",
            'Please Enter PAN Number',
            "announcement"
          );
        }

        
 
      } else{
        this.showNotification(
          "bottom",
          "right",
          "success",
          'Please Enter IEC Number',
          "announcement"
        );
      }
    
  }


  openModalViewLetter(section: any) {
    // console.log(section.letter);
    this.sectionViewLetterDetails = section;
    // console.log(this.sectionViewLetterDetails)
    const buttonModal = document.getElementById("openModalButton");
    // buttonModal.click();
  } 
  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "auto";

    let dialogRef:any;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result: { event: string; isUpdated: boolean; }) => {
      console.log(result)
      if (result.event === "paymentSum" && result.isUpdated === true) {
        this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }
  showNotification(from: string, align: string, color: any | string, stringMessage: string, icons: string) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
