import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
  UntypedFormGroup,
} from "@angular/forms";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatTableDataSource } from '@angular/material/table';
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BlockTemplateComponent } from 'app/widgets/block-template/block-template.component';
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { data } from "jquery";
// import { THIS_EXPR } from "@angular/compiler/src/output/output_ast";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { PaymentsumComponent } from "app/blockchainImpExp/imp_exp/payment/paymentsum/paymentsum.component";
declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface PeriodicElement {
  denomination_no: string;
  denomination_type:string;
  denomination_qty:string;
  denomination_total_cash:string;
  challan_date:string;
}

export interface GroupBy {
  totalPayable: string;
  isGroupBy: boolean;
  totalAmt:string;
}



@Component({
  selector: 'app-cash-challan',
  templateUrl: './cash-challan.component.html',
  styleUrls: ['./cash-challan.component.css']
})
export class CashChallanComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  ELEMENT_DATA: PeriodicElement[] = [
  
    {
      denomination_no: '2000',
      denomination_type:"Note",
      denomination_qty:'',
      denomination_total_cash:"",
      challan_date:"",
      
    },
    {
      denomination_no: '500',
      denomination_type:"Note",
      denomination_qty: '',
      denomination_total_cash:"",
      challan_date:"",
    },
    {
      denomination_no: '200',
      denomination_type:"Note",
      denomination_qty: '',
      denomination_total_cash:"",
      challan_date:"",
      
    },
    {
      denomination_no: '100',
      denomination_type:"Note",
      denomination_qty: '',
      denomination_total_cash:"",
      challan_date:"",
     
    },
    {
      denomination_no: '50',
      denomination_type:"Note",
      denomination_qty: '',
      denomination_total_cash:"",
      challan_date:"",
     
    },
    {
      denomination_no: '10',
      denomination_type:"Note",
      denomination_qty: '',
      denomination_total_cash:"",
      challan_date:"",
      
    },
    {
      denomination_no: '20',
      denomination_type:"Note",
      denomination_qty: '',
      denomination_total_cash:"",
      challan_date:"",
     
    },
    {
      denomination_no: '10',
      denomination_type:"Coin",
      denomination_qty: '',
      denomination_total_cash:"",
      challan_date:"",
     
    },
    {
      denomination_no: '5',
      denomination_type:"Coin",
      denomination_qty: '',
      denomination_total_cash:"",
      challan_date:"",
      
    },
    {
      denomination_no: '2',
      denomination_type:"Coin",
      denomination_qty: '',
      denomination_total_cash:"",
      challan_date:"",
      
    },
    {
      denomination_no: '1',
      denomination_type:"Coin",
      denomination_qty: '',
      denomination_total_cash:"",
      challan_date:"",
      
    },
  //  {totalPayable: 'Grand Total Cash (In INR)', isGroupBy: true,totalAmt:"Auto Calculate"},
  ];
  selectedStatus = 'New';
  enableDownload:boolean=false;
  allStatus: Status[] = [
    {value: 'Incoming Date', viewValue: 'Incoming Date'},
    {value: 'IEC Code', viewValue: 'IEC Code'},
  ];
  fg:UntypedFormGroup;

  currentUser: any;
  role: any;
  totalCash:any;
  // cashChallanForm:FormGroup;
  // dataSource= new BehaviorSubject<AbstractControl[]>([]);
  dataSource=this.ELEMENT_DATA;
  data1:any;
  data:PeriodicElement[]=[];
  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  displayedColumns = [
    'denomination_no',
    'denomination_type',
    'denomination_qty',
    'denomination_total_cash',
  ];
  date: any;
  modifiedCurrentDate: any;
  pipe = new DatePipe("en-US");
  no: string;
  type: string;
   challans: UntypedFormArray = this.formBuilder.array([]);
   cash_challan: UntypedFormArray = this.formBuilder.array([]);
   cashChallanForm: UntypedFormGroup = this.formBuilder.group({ challan_master: this.cash_challan });
  challan_date: any;
  denominationTotal:any;
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  cashChallanData: any;
  printChallan:any;
  cashTotal: UntypedFormArray;
  msg: null;

  constructor( private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService) { }

  ngOnInit(): void {
    this.enableDownload = false;
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = this.currentUser.obj.user_type_id;
    // this.challan_date = Date.now();
    this.challan_date= this.pipe.transform(Date.now(), "yyyy-MM-dd HH:mm:ss");
    this.cashChallanForm=this.formBuilder.group({
      challan_date:[this.challan_date],
      cash_challan: this.formBuilder.array([
        this.ELEMENT_DATA.forEach(abc=>{
          const newRow = this.formBuilder.group({   
            denomination_no: [abc.denomination_no],
            denomination_type:[abc.denomination_type],
            denomination_qty: [abc.denomination_qty],
            denomination_total_cash:[abc.denomination_total_cash],
            challan_date:[this.challan_date]
          })  
          this.cash_challan.push(newRow);
        })
      ])
    });
  
  
  }

  caseDetailsDialogue(data){
    localStorage.setItem('casedetailsData',JSON.stringify(data));
  
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  
  sendit(data){
  
    this.challans = this.formBuilder.array([]);
    
    this.cashChallanForm=this.formBuilder.group({
      cash_challan: this.formBuilder.array([
        this.cash_challan.value.forEach(abc=>{
          const newRow = this.formBuilder.group({   
            denomination_no: [abc.denomination_no],
            denomination_type:[abc.denomination_type],
            denomination_qty: [abc.denomination_qty],
            denomination_total_cash:[abc.denomination_qty * abc.denomination_no],
            challan_date:[this.challan_date]
          })  
          this.challans.push(newRow);
          this.cash_challan = this.challans;
        })
      ])
    });
    // console.log(this.cash_challan.value);
    
 }

 getTotalCost() {
  return this.cash_challan.value.map(t => t.denomination_total_cash).reduce((acc, value) => acc + value, 0);
}
// printTotalCost() {
//   this.cashTotal = this.cash_challan
//   return this.cashTotal.value.map(t => t.denomination_total_cash).reduce((acc, value) => acc + value, 0);
// }
  isGroup(index, item): boolean{
    return item.isGroupBy;
  }
  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    // this.date = this.pipe.transform(event.value, "yyyy-MM-dd HH:mm:ss");
    this.challan_date = this.date;
    console.log(this.challan_date);
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  submitCashChallan(data){
  this.cashChallanData = data.value;
  data.value.forEach(a1=>{
    if(a1.denomination_qty==''){
      a1.denomination_qty='0';
    }
  });
  let input = {
    dm : data.value
  }
  this.blockUI.start("Please wait this may take few seconds ...");
  this.blockchainService.saveDenomination(input).subscribe((resp) => {
    // console.log(resp);
    this.data = resp.body.obj;
    if(resp.body.status==200){
      this.blockUI.stop();
      // setTimeout(() => {
      //   this.blockUI.stop();
      // }, 1500);
      this.enableDownload = true;
      this.blockUI.stop();
      this.openDialog("paymentSum", "The cash details you entered have been successfully recorded.", "");
      this.enableDownload = true;
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Cash Challan Submitted Successfully",
        "announcement"
      );	
      setTimeout( () => {
        this.msg = null;
        // location.reload()
      }, 3000);
     

      // location.reload()
      // this.router.navigate(["/payment_acceptance"])
    }else{
    this.enableDownload = false;
      this.showNotification(
        "bottom",
        "right",
        "success",
        resp.body.message,
        "announcement"
      );
      setTimeout( () => {
        this.msg = null;
      }, 3000);
      return;
    }
  });
 
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "paymentSum" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  reportGeneration() { 
    this.blockUI.start("Please wait this may take few seconds ...");
    var displayedCol = []
    displayedCol.push('Denomination');
    displayedCol.push('Type');
    displayedCol.push('Quantity');
    displayedCol.push('Total Cash');
    this.printChallan = [];
  // this.printChallan = this.cashChallanData;
  this.cashChallanData.forEach(e1=>{
    this.printChallan.push(e1);
  })
  
  var tableObj = this.printChallan;
  this.printChallan.push({denomination_no:'Total',denomination_type:"",denomination_qty:"",denomination_total_cash:this.getTotalCost()})
 

  console.log(tableObj,'tableobj')
    tableObj.forEach(element => {
      element['Denomination'] = element.denomination_no;
      element['Type'] = element.denomination_type;
      element['Quantity'] = element.denomination_qty;
      element['Total Cash'] = element.denomination_total_cash;
      
    });
    this.blockUI.stop();
    this.downloadFile(
      tableObj,
      'Cash Challan Report' + ' ' + this.todayDate,
      displayedCol
    );
}

downloadFile(data, filename = "data", headers) {
  let csvData = this.ConvertToCSV(data, headers);
  console.log(csvData);
  let blob = new Blob(["\ufeff" + csvData], {
    type: "text/csv;charset=utf-8;",
  });
  let dwldLink = document.createElement("a");
  let url = URL.createObjectURL(blob);
  let isSafariBrowser =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1;
  if (isSafariBrowser) {
    //if Safari open in new window to save file with random filename.
    dwldLink.setAttribute("target", "_blank");
  }
  dwldLink.setAttribute("href", url);
  dwldLink.setAttribute("download", filename + ".csv");
  dwldLink.style.visibility = "hidden";
  document.body.appendChild(dwldLink);
  dwldLink.click();
  document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray, headerList) {
  let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  let str = "";
  let row = "Sr.No,";

  for (let index in headerList) {
    row += headerList[index] + ",";
  }
  row = row.slice(0, -1);
  str += row + "\r\n";
  for (let i = 0; i < array.length; i++) {
    let line = i + 1 + "";
    for (let index in headerList) {
      let head = headerList[index];

      line += "," + array[i][head];
    }
    str += line + "\r\n";
  }
  return str;
}



  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
