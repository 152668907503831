import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

@Component({
  selector: 'app-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css']
})
export class ManageComponent implements OnInit {
  pipe = new DatePipe("en-US");
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  selectedDate: any;
  currentUser: any;
  isPaymentGuest: boolean=false;
  isPaymentAdmin:boolean;
  isUser:any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let payment = localStorage.getItem("paymentGuest");
    this.isUser = payment;
      this.isPaymentGuest = true;
     this.isPaymentAdmin = false;
  // if(payment == 'true'){
  //   console.log(payment)
  //   this.isPaymentGuest = true;
  //   this.isPaymentAdmin = false;
  //   //this.router.navigate(["/view1"]);
  // } else{
  //   this.isPaymentAdmin = true;
  //   this.isPaymentGuest = false
  // }
  //this.payment();
 
}
manageUsers(){
  this.router.navigate(['/manageUsers']);
}
manageSections(){
  this.router.navigate(['/manageSections']);
}
  payment(){
    //this.router.navigate(['/summaryPayment'])
     //var data='{"status":200,"message":"Login Sucessfull","obj":{"username":"Guest User","access_token":"90d00968-55c3-491e-b429-5c403552fbf4","token_type":"bearer","refresh_token":"9dc2f806-368b-4353-b0d8-c6b5e14dddda","expires_in":"11053","updated_at":"2022-05-30 20:20:26","created_at":"2022-05-30 20:20:26","user_id":0,"role":"0","role_name":"","level":null}}';
     localStorage.setItem("isLoggedIn", "true");
     //localStorage.setItem("currentUser", JSON.stringify(data));
     this.router.navigate(['/paymentsummary']);
    //this.router.navigate(["/view1"]);
  }



  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
