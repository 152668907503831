import { Component, OnInit } from '@angular/core';
import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
// import { FlexAlignDirective } from '@angular/flex-layout';
import { AuthenticationService } from 'app/_services/authentication.service';
import { AlertService } from 'app/_services';
import { NgForm } from '@angular/forms';
declare var $: any;

export interface IdentificationType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-paymentsummary',
  templateUrl: './paymentsummary.component.html',
  styleUrls: ['./paymentsummary.component.css']
})
export class PaymentsummaryComponent implements OnInit {

  allTypes: IdentificationType[] = [
    {value: 'IEC No', viewValue: 'IEC No'},
    {value: 'Custom Broker No', viewValue: 'Custom Broker No'},
    {value: 'PAN No', viewValue: 'PAN No'},
    {value: 'Aadhar No', viewValue: 'Aadhar No'},
    {value: 'Passport No', viewValue: 'Passport No'},
    {value: 'Other', viewValue: 'Other'},

  ];

  pipe = new DatePipe("en-US");
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  currentUser: any;
  iec_flag=false;
  token: string|undefined;
  selectedStatus:any;
  notiColor: any = "#06ffff";

  summaryForm = this.formBuilder.group({
    depositor_full_name: ["",[Validators.required]],
    identification_type:["",[Validators.required]],
    identification_no:["",[Validators.required]],
    mobile_no_of_depositor: ["",[Validators.required,Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    email_of_depositor: ["",[Validators.required]],
    name_of_customs_broker : [""],
    customs_broker_reg_id:[""],
    customs_broker_mob_no : ["",Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
    name_of_airlines_agent:[""],
    name_of_console_agent:[""],
    file_number:[""],
    comments_on_payments:[""],
    zone_id:[""],
    commissionerate_type:[""]
  });
  zoneList: any;
  commissionrateList: any;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {
    this.token = undefined;
   }

  ngOnInit(): void {
    
    localStorage.removeItem('commissionerate_type')
    // this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    var cuser = JSON.parse(localStorage.getItem("currentUser"));
   
    this.role = cuser.obj.role;
    console.log(this.role);
    if(this.role==0){
      this.iec_flag=false;
    }else{
      this.iec_flag=true;
    } 
    this.getZoneList();
    this.getCommissionrate();
  }

  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }else{
     this.summaryForm.value.secret_key = this.token;
     this.savePayments()
    }
    console.log(this.token)
    console.debug(`Token [${this.token}] generated`);
}

  getZoneList() {
    this.blockchainService.getAllZones().subscribe(
      (resp) => {
        this.zoneList = resp.obj;
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      }
      );
  }
  getCommissionrate() {
    this.blockchainService.getCommissionrate().subscribe(
      (resp) => {
        this.commissionrateList = resp.obj;
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      }
      );
  }

  get f() {
    return this.summaryForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.summaryForm.controls[controlName].hasError(errorName);
  };

  next(){
    // this.savePayments();
     this.router.navigate(["/paymentApplication"])
    
  }

  savePayments(){
      console.log(this.summaryForm.value);
      if(this.summaryForm.value.depositor_full_name==""){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Depostior Name Cannot be Empty",
          "announcement"
        );

      }
       if(this.summaryForm.value.mobile_no_of_depositor==""){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Depostior Mobile No Cannot be Empty",
          "announcement"
        );
      }
       if(this.summaryForm.value.email_of_depositor==""){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Depostior Email Cannot be Empty",
          "announcement"
        );
      }

       if(this.summaryForm.value.identification_type==undefined){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Identification Type Cannot be Empty",
          "announcement"
        );
      }

       if(this.summaryForm.value.identification_no=="" ){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Identification No Cannot be Empty",
          "announcement"
        );
      }

       if(this.summaryForm.value.zone_id=="" && this.summaryForm.value.zone_id==undefined){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Zone Name Cannot be Empty",
          "announcement"
        );
      }

       if(this.summaryForm.value.commissionerate_type=="" && this.summaryForm.value.commissionerate_type==undefined){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Commissionerate Type Cannot be Empty",
          "announcement"
        );
      }

      else{
        console.log(this.summaryForm.value);
        if(this.summaryForm.valid){
          this.blockUI.start("Please wait this may take few seconds ...");

          this.blockchainService.savePayments(this.summaryForm.value).subscribe(
            (data) => {
              console.log(data);
              if (data.body.status == 200) { 
                console.log(data.body);
                
                localStorage.setItem("payment_id", data.body.obj);
                setTimeout(() => {
                  this.blockUI.stop();
                }, 1000);
                this.router.navigate(["/paymentApplication"])
            }else{
              setTimeout(() => {
                this.blockUI.stop();
              }, 500);
              this.showNotification(
                "bottom",
                "right",
                "success",
                data.body.message,
                "announcement"
              );
            }
          },
            // (error) => {
            //   setTimeout(() => {
            //     this.blockUI.stop();
            //   }, 500);
            //   console.log("fail", error);
            //   this.showNotification(
            //     "bottom",
            //     "right",
            //     "warning",
            //     error.message,
            //     "announcement"
            //   );
            // }
            (error) => {
              console.log(error)
              this.alertService.error(error);
              if(error.status == 401){
                // location.reload();
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  "Session Expired, Please Login Again",
                  "announcement"
                );
                this.router.navigate(["/login"]);
              }
              else if(error.status == 500){
                setTimeout(() => {
                  this.blockUI.stop();
                }, 1500);
                // location.reload();
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  "Please Try Again",
                  "announcement"
                );
              }
              else{}
            }
          );
        }
      else{
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Enter Valid Details",
          "announcement"
        );
      }

      }

  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", this.notiColor, "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 5000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4' +
          ' alert alert-{0} alert-with-icon" role="alert"><button mat-button  type="butto' +
          'n" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i clas' +
          's="material-icons">close</i></button><i class="material-icons" data-notify="ic' +
          'on">notifications</i> <span data-notify="title">{1}</span> <span data-notify="' +
          'message">{2}</span><div class="progress" data-notify="progressbar"><div class=' +
          '"progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valu' +
          'emin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" targ' +
          'et="{4}" data-notify="url"></a></div>',
      }
    );
  }
}
