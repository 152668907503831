import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService, ImportAAQuery } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";

var $: any;

export interface PeriodicElement {
  sr_no:string;
  category: string;
  total_bond_amt: string;
  total_no_of_appln: string;
  view_details : string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    sr_no:"",
    category: "EODC APPLICATION NOT FILED",
    total_bond_amt: "",
    total_no_of_appln: "",
    view_details : ""
  },
  {
    sr_no:"",
    category: "EODC APPLIED BUT NOT YET GRANTED",
    total_bond_amt: "",
    total_no_of_appln: "",
    view_details : ""
  },
  {
    sr_no:"",
    category: "EODC APPLIED AND REJECTED BY DGFT",
    total_bond_amt: "",
    total_no_of_appln: "",
    view_details : ""
  },
  {
    sr_no:"",
    category: "EODC APPLIED AND  GRANTED BY DGFT  BUT BOND CANCELLATION NOT FILED",
    total_bond_amt: "",
    total_no_of_appln: "",
    view_details : ""
  },
  {
    sr_no:"",
    category: "EODC GRANTED BY DGFT AND BOND CANCELLATION FILED",
    total_bond_amt: "",
    total_no_of_appln: "",
    view_details : ""
  },
 
];


@Component({
  selector: 'app-auth-summary',
  templateUrl: './auth-summary.component.html',
  styleUrls: ['./auth-summary.component.css']
})
export class AuthSummaryComponent implements OnInit {
  displayedColumns = [
    "sr_no",
    "category",
    "total_bond_amt",
    "total_no_of_appln",
    "view_details",
  ];
  dataSource = ELEMENT_DATA;
  noOfAuth: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { }

  ngOnInit(): void {
  }

  viewDetails(e){
    console.log(e);
    if(e.category == 'EODC APPLICATION NOT FILED'){
      localStorage.setItem('eodcApplicationStatus','notfiled');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e.category == 'EODC APPLIED BUT NOT YET GRANTED'){
      localStorage.setItem('eodcApplicationStatus','notgranted');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e.category == 'EODC APPLIED AND REJECTED BY DGFT'){
      localStorage.setItem('eodcApplicationStatus','rejected');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e.category == 'EODC APPLIED AND  GRANTED BY DGFT  BUT BOND CANCELLATION NOT FILED'){
      localStorage.setItem('eodcApplicationStatus','granted');
      this.router.navigate(['/detailAuthSummaryValidity']);
    }
    if(e.category == 'EODC GRANTED BY DGFT AND BOND CANCELLATION FILED'){
      localStorage.setItem('eodcApplicationStatus','bondfiled');
     this.router.navigate(['/detailAuthSummaryValidity']);

    }
  }

  back(){
    this.router.navigate(['/processorView']);
  }
  // authSummaryValidityOver
}
