import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { is } from "date-fns/locale";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { MatStepper } from '@angular/material/stepper';
import { DateAdapter } from "@angular/material/core";

declare var $: any;


@Component({
  selector: 'app-grievance-module1',
  templateUrl: './grievance-module1.component.html',
  styleUrls: ['./grievance-module1.component.css']
})
export class GrievanceModule1Component implements OnInit {

  isLinear = true;
  firstFormGroup: UntypedFormGroup;  
  secondFormGroup: UntypedFormGroup;  
  tabIndex = 0 ;
  isGreen:boolean = false;
  isApprove:boolean = false;
  isReject:boolean = false;
  isDraft:boolean = false;
  currentStep:any;
  addGreenForm = this.formBuilder.group({
    noteDate: [""],
    name:[""],
    remarks:[""],
    designation:[""],
    application_id:[""],
    attached_document : [""],
    cancellation_id:[""]
  });
  addApprovalForm = this.formBuilder.group({
    application_approval_rejection_remarks:[""],
    application_id:[""]
  });
  addRejectForm = this.formBuilder.group({
    application_approval_rejection_remarks:[""],
    application_id:[""]
  });
  createJobForm = this.formBuilder.group({
    job_no:[""],
    application_id:[""]
  });
  bondAndBbgForm = this.formBuilder.group({
    bond_no:[""],
    bbg_cell_location:[""],
    application_id:[""]
  });
  acceptJobForm = this.formBuilder.group({
    job_no:[""],
    application_id:[""]
  })
  saveDraftForm = this.formBuilder.group({
    draft_letter:[""],
    application_id:[""],
    draft_letter_file:[""]
  })
  currentUser: any;
  caseDetailsData: any;
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  date: any;
  noteDate: any;
  acceptJob: any;
  isDocument:boolean = false;
  selectedFile: File;
  selectedFile1: File;
  url: string | ArrayBuffer;
  htmlContent = '';
  isNextDisabled=true;
  todayDate1= this.pipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");
  showTab: boolean;
  getDraftData: any;
  attached_document: any;
  stages:any
  draft_letter_status: number;
  getDraftDataFor1: any;
 
  constructor(
    private formBuilder: UntypedFormBuilder, public dialog: MatDialog,
    public blockchainService: BlockchainService,private router: Router,   
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));
    if(this.currentUser.obj.level !== null){
      if(this.currentUser.obj.level == "L1"){
        this.draft_letter_status = 0
      } else{
        this.draft_letter_status = 1
      }
    }
    if(this.caseDetailsData.stage=='Accept Job'){
      this.secondFormGroup
    }
    this.addGreenForm.controls['noteDate'].setValue(this.todayDate);
    this.firstFormGroup = this.formBuilder.group({  
      firstCtrl: ['', Validators.required]  
    }); 
    this.secondFormGroup = this.formBuilder.group({  
      secondCtrl: ['', Validators.required]  
    });  
    this.stages=localStorage.getItem("stages");
    this.isGreen = false;
    this.isApprove = false;
    this.isReject = false;
    this.isDraft = false;
    this.isDocument = false;
    this.getAARegistrationJobNumberByApplicationId(this.caseDetailsData.application_id);
    this.changedJob();
   if(this.currentUser.obj.level=='L2'){
    this.getDraftLetterByApplicationId(this.caseDetailsData.application_id)
   }
   
   if(this.stages=="Validate"){
    //this.stepper.selectedIndex=0
    this.isLinear = false;
    this.currentStep=0
    this.isLinear = true;

  } if(this.stages=="Create Job"){
   // this.stepper.selectedIndex=1
   this.isLinear = false;
    this.currentStep=1 
    this.isLinear = true;
  }
  else if(this.stages=="Accept Job"){
    this.isLinear = false;
   this.currentStep=1
    this.isLinear = true;
  }
   else if(this.stages=="Bond and Bbg"){
    this.isLinear = false;
    this.currentStep=2
    this.isLinear = true;
  }
  else if(this.stages=="Issue License"){
    this.isLinear = false;
    this.currentStep=2
    this.isLinear = true;
  }
  }

  omit_special_char(event)
{   
   var k;  
   k = event.charCode;  //         k = event.keyCode;  (Both can be used)
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
}

  changedJob(){
    if(this.createJobForm.value.job_no==''|| this.createJobForm.value.job_no==null){
    this.isNextDisabled = this.createJobForm.valid;
    }
 
     this.createJobForm.valueChanges.subscribe((v) => {
       this.isNextDisabled = !this.createJobForm.valid;
     });
   }
   changeTab(event){
     console.log(event.index)
     this.tabIndex = event.index;
   }
 
   addGreenNote(){
     this.isGreen = true;
   }
   saveGreenNote(){
     this.addGreenForm.controls['designation'].setValue("");
     this.addGreenForm.controls['name'].setValue(this.caseDetailsData.officer_name);
     this.addGreenForm.controls['application_id'].setValue(this.caseDetailsData.application_id);
     if(this.noteDate==undefined){
       this.addGreenForm.controls['noteDate'].setValue(this.todayDate1);
     }else{
       this.noteDate=this.pipe.transform(this.noteDate, "yyyy-MM-dd HH:mm:ss");
       this.addGreenForm.controls['noteDate'].setValue(this.noteDate);
     }
     this.addGreenForm.controls['attached_document'].setValue(null);
     this.addGreenForm.controls['cancellation_id'].setValue(0);
     this.addGreenForm.controls['html_flag'].setValue(0);
     this.blockchainService.saveGreenSheet(this.addGreenForm.value).subscribe(
       (resp) => {
         if(resp.status == 200){
           this.showNotification(
             "bottom",
             "right",
             "success",
             "Green Note Added Successfully",
             "announcement"
           );
         }
          this.addGreenForm.reset();
          setTimeout(() => {
           this.router.navigate(['/registration1']);
          }, 1500);
       });
   }
 
   addApproveRemarks(){
     this.isApprove = true;
   }
 
   saveApprovalRemarks(){
     this.addApprovalForm.controls['application_id'].setValue(this.caseDetailsData.application_id);
     this.blockchainService.saveAARegistrationApprovestatus(this.addApprovalForm.value).subscribe(
       (resp) => {
         console.log(resp.body.obj);
         if(resp.status == 200){
           this.showNotification(
             "bottom",
             "right",
             "success",
             "Approval Remarks Added Successfully",
             "announcement"
           );
         }
          this.addApprovalForm.reset();
         //  location.reload();
          setTimeout(() => {
           this.router.navigate(['/registration1']);
          }, 1500);
       });
 
       let input ={
         designation:'',
         name:this.caseDetailsData.officer_name,
         noteDate:this.todayDate1,
         remarks:'Approval_Remarks :'+this.addApprovalForm.value.application_approval_rejection_remarks,
         application_id:this.caseDetailsData.application_id,
         attached_document:null,
         cancellation_id:0,
         html_flag:0
       }
       this.blockchainService.saveGreenSheet(input).subscribe(
         (resp) => {
           console.log(resp);
         })
   }
  
 
   addRejectRemarks(){
     this.isReject = true;
   }
 
   saveRejectRemarks(){
     this.addRejectForm.controls['application_id'].setValue(this.caseDetailsData.application_id);
     this.blockchainService.saveAARegistrationRejectstatus(this.addRejectForm.value).subscribe(
       (resp) => {
         console.log(resp.body.obj);
         if(resp.status == 200){
           this.showNotification(
             "bottom",
             "right",
             "success",
             "Reject Remarks Added Successfully",
             "announcement"
           );
         }
          this.addRejectForm.reset();
          setTimeout(() => {
           this.router.navigate(['/registration1']);
         }, 1500);
   
       });
 
       let input ={
         designation:'',
         name:this.caseDetailsData.officer_name,
         noteDate:this.todayDate1,
         remarks:'Rejection_Remarks :'+this.addRejectForm.value.application_approval_rejection_remarks,
         application_id:this.caseDetailsData.application_id,
         attached_document:null,
         cancellation_id:0,
         html_flag:0
       }
       this.blockchainService.saveGreenSheet(input).subscribe(
         (resp) => {
           console.log(resp);
         })
   }
   addDraft(){
     this.isDraft = true;
     this.isDocument =  false;
   }
 
   addDocument(){
     this.isDocument = true;
     this.isDraft = false;
   }
   getDraftLetterByApplicationId(application_id){
     let input = {
       application_id:application_id
     }
     this.blockchainService.getDraftLetterByApplicationId(input).subscribe(
       (resp) => {
        console.log(resp.body.obj)
        this.getDraftData = resp.body.obj;
        this.getDraftDataFor1 = resp.body.obj;
        if(this.getDraftData !=null){
       
        this.isDraft = true;
        this.saveDraftForm.controls['application_id'].setValue(this.getDraftData.application_id);
        this.htmlContent = this.getDraftData.draft_letter;
        }
     }); 
 
   }
   discardDraft(){
     let input = {
       application_id:this.caseDetailsData.application_id,
       draft_letter_status:"2"
     }
     this.blockchainService.updateDraftLetterDiscardStatus(input).subscribe(
       (resp) => {
        console.log(resp)
        this.getDraftData = resp.body.obj;
        if(this.getDraftData !=null){
         this.saveDraftForm.controls['application_id'].setValue(this.getDraftData.application_id);
         this.htmlContent = this.getDraftData.draft_letter;
       }
        
    
     }); 
 
   }
   saveDraft(){
 
 
     if(this.getDraftData==null){
         
       let input = {
         draft_letter:this.htmlContent,
         attached_document:this.selectedFile,
         application_id:this.caseDetailsData.application_id,
         draft_letter_status:this.draft_letter_status,
         cancellation_id:0
       }
     this.blockchainService.saveDraftLetter(input).subscribe(
       (resp) => {
         console.log(resp.body.obj);
         if(resp.status == 200){
           this.showNotification(
             "bottom",
             "right",
             "success",
             "Draft Added Successfully",
             "announcement"
           );
         }
          this.saveDraftForm.reset();
          setTimeout(() => {
           this.router.navigate(['/registration1']);
         }, 1500);
   
       });
     }else{
       if(this.selectedFile==undefined && this.getDraftData.attached_document_path!=null){
         let input = {
           draft_letter:this.htmlContent,
           application_id:this.caseDetailsData.application_id,
           cancellation_id:0,
           attached_document_path:this.getDraftData.attached_document_path,
           green_sheet_draft_letter_id:this.getDraftData.green_sheet_draft_letter_id,
           is_approved:1
         }
         this.blockchainService.updateDraftLetter(input).subscribe(
           (resp) => {
             console.log(resp.body.obj);
             if(resp.status == 200){
               this.showNotification(
                 "bottom",
                 "right",
                 "success",
                 "Draft Added Successfully",
                 "announcement"
               );
             }
              this.saveDraftForm.reset();
              setTimeout(() => {
               this.router.navigate(['/registration1']);
             }, 1500);
       
           });
       }
       else{
         let input = {
         draft_letter:this.htmlContent,
         attached_document:this.selectedFile,
         
         application_id:this.caseDetailsData.application_id,
         cancellation_id:0,
         green_sheet_draft_letter_id:this.getDraftData.green_sheet_draft_letter_id,
         is_approved:1
       }
       this.blockchainService.updateDraftLetter(input).subscribe(
         (resp) => {
           console.log(resp.body.obj);
           if(resp.status == 200){
             this.showNotification(
               "bottom",
               "right",
               "success",
               "Draft Added Successfully",
               "announcement"
             );
           }
            this.saveDraftForm.reset();
            setTimeout(() => {
             this.router.navigate(['/registration1']);
           }, 1500);
     
         });
     }
 
     }
 
      if(this.currentUser.obj.level=='L2'){
 let input1 ={
         designation:'',
         name:this.caseDetailsData.officer_name,
         noteDate:this.todayDate1,
         remarks:'Draft_Remarks :'+this.htmlContent,
         application_id:this.caseDetailsData.application_id,
         attached_document:this.selectedFile,
         attached_document_path:this.getDraftData.attached_document_path,
         cancellation_id:0,
         html_flag:1
       }
       this.blockchainService.saveGreenSheet(input1).subscribe(
         (resp) => {
           console.log(resp);
         })
       }
 
   }
 
   createJobNo(){
     // console.log("Hii");
     this.createJobForm.controls['application_id'].setValue(this.caseDetailsData.application_id);
     this.blockchainService.saveAARegistrationJobNumber(this.createJobForm.value).subscribe(
       (resp) => {
         // console.log(resp.status);
         if(resp.status == 200){
           this.showNotification(
             "bottom",
             "right",
             "success",
             "Job Created Successfully",
             "announcement"
           );
         }
          this.createJobForm.reset();
          setTimeout(() => {
           this.router.navigate(['/registration1']);
          }, 1500);
       });
   }
 
   saveBondBbg(){
     this.bondAndBbgForm.controls['application_id'].setValue(this.caseDetailsData.application_id);
     this.blockchainService.saveAARegistrationBondNoAndBbg(this.bondAndBbgForm.value).subscribe(
       (resp) => {
         // console.log(resp.status);
         if(resp.status == 200){
           this.showNotification(
             "bottom",
             "right",
             "success",
             "Data Saved Successfully",
             "announcement"
           );
         }
          this.bondAndBbgForm.reset();
          setTimeout(() => {
           this.router.navigate(['/registration1']);
          }, 1500);
       });
 
   }
   getAARegistrationJobNumberByApplicationId(input){
 
     let application = {
       application_id: input
    }
    this.blockchainService.getAARegistrationJobNumberByApplicationId(application).subscribe(
      (resp) => {
       //  console.log(resp.body.obj);
         this.acceptJob = resp.body.obj;
         //  console.log(this.acceptJob);
          this.acceptJobForm.controls['job_no'].setValue(this.acceptJob);
         }
    )
       }
 
       updateAcceptJob(){
         this.acceptJobForm.controls['application_id'].setValue(this.caseDetailsData.application_id);
         if(this.currentUser.obj.level=='L1'){
         this.blockchainService.updateAARegistrationJobNumber(this.acceptJobForm.value).subscribe(
           (resp) => {
             // console.log(resp);
             if(resp.status == 200){
               this.showNotification(
                 "bottom",
                 "right",
                 "success",
                 resp.body.message,
                 "announcement"
               );
             }
              this.acceptJobForm.reset();
              setTimeout(() => {
               this.router.navigate(['/registration1']);
               // this.route.navigateByUrl('/SampleComponent', { skipLocationChange: true });
              }, 1500);
           });
         }
           else{
             this.blockchainService.saveAARegistrationLevelJobNumberByApplicationId(this.acceptJobForm.value).subscribe(
               (resp) => {
                 // console.log(resp.status);
                 if(resp.status == 200){
                   this.showNotification(
                     "bottom",
                     "right",
                     "success",
                     resp.body.message,
                     "announcement"
                   );
                 }
                  this.acceptJobForm.reset();
                  setTimeout(() => {
                   this.router.navigate(['/registration1']);
                   // this.route.navigateByUrl('/SampleComponent', { skipLocationChange: true });
                  }, 1500);
               });
           }
       }
 
       issueLiscense(){
         let application = {
           application_id: this.caseDetailsData.application_id
        }
        this.blockchainService.updateIssueLicenseStatus(application).subscribe(
         (resp) => {
           // console.log(resp.status);
           if(resp.status == 200){
             this.showNotification(
               "bottom",
               "right",
               "success",
               "Issue Lisence Updated Successfully",
               "announcement"
             );
           }
            this.acceptJobForm.reset();
            setTimeout(() => {
             this.router.navigate(['/registration1']);
             // this.route.navigateByUrl('/SampleComponent', { skipLocationChange: true });
            }, 1500);
         });
       }
 
       onFileSelected(event,action) {
         if(action == 'cust'){
           this.selectedFile = <File>event.target.files[0];
           if(this.selectedFile.type == 'application/pdf'){
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => {
              // called once readAsDataURL is completed
              this.url = event.target.result;
            };
            this.onUpload(); 
          }
          else{
            event.target.files[0].value='';
            this.showNotification(
              "bottom",
              "right",
              "success",
              "File Format Invalid",
              "announcement"
            );
          }
           
         } else{
           this.selectedFile1 = <File>event.target.files[0];
           if(this.selectedFile1.type == 'application/pdf'){
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => {
              // called once readAsDataURL is completed
              this.url = event.target.result;
            };
            this.onUpload();
          }
          else{
            event.target.files[0].value='';
            this.showNotification(
              "bottom",
              "right",
              "success",
              "File Format Invalid",
              "announcement"
            );
          }
          
         }
       }
 
       onUpload() {
         const fd = new FormData();
         fd.append("file", this.selectedFile, this.selectedFile.name);
       }
 
   dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
     this.date = this.pipe.transform(event.value, "yyyy-MM-dd HH:mm");
     this.noteDate = this.date;
   }
 
   formatDate(date) {
     let d = new Date(date),
       month = "" + (d.getMonth() + 1),
       year = d.getFullYear(),
       day = "" + d.getDay();
     if (month.length < 2) month = "0" + month;
     if (day.length < 2) day = "0" + day;
     return [year, month, day].join("-");
   }
 
   showNotification(from, align, color, stringMessage, icons) {
     const type = ["", "info", "success", "warning", "danger"];
 
     $.notify(
       {
         icon: icons,
         message: stringMessage,
       },
       {
         type: type[color],
         timer: 4000,
         placement: {
           from: from,
           align: align,
         },
         template:
           '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
           '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
           '<i class="material-icons" data-notify="icon">notifications</i> ' +
           '<span data-notify="title">{1}</span> ' +
           '<span data-notify="message">{2}</span>' +
           '<div class="progress" data-notify="progressbar">' +
           '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
           "</div>" +
           '<a href="{3}" target="{4}" data-notify="url"></a>' +
           "</div>",
       }
     );
   }
 
   config: AngularEditorConfig = {
     editable: true,
     spellcheck: true,
     height: '15rem',
     minHeight: '5rem',
     placeholder: 'Enter text here...',
     translate: 'no',
     defaultParagraphSeparator: 'p',
     defaultFontName: 'Arial',
     toolbarHiddenButtons: [
       ['bold']
       ],
     customClasses: [
       {
         name: "quote",
         class: "quote",
       },
       {
         name: 'redText',
         class: 'redText'
       },
       {
         name: "titleText",
         class: "titleText",
         tag: "h1",
       },
     ]
   };
   
}
