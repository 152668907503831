import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService, ImportAAQuery } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { DateAdapter } from "@angular/material/core";

declare var $: any;

export interface PeriodicElement {
  impSrNo:string;
  inputDescription: string;
  technicalFeatures: string;
  expSrNo: string;
  itchsCode: string;
  quantity: string;
  uom: string;
  cifInr: string;
  cifForeign: string;
 edit:string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    impSrNo:"",
    inputDescription: "",
    technicalFeatures: "",
  expSrNo: "",
  itchsCode: "",
  quantity: "",
  uom: "",
  cifInr: "",
  cifForeign: "",
 edit:"",

  },
];

export interface ImportAA {
  application_id: number;
  created_date: string;
  deleted_date: string;
  import_serial_number: string;
  cif_for_foreign: string;
  cif_for_inr: string;
  itchs_code: string;
  input_description: string;
  item_id: number;
  items_names: string;
  quantity: string;
  uom: string;
}

@Component({
  selector: 'app-imp-aa',
  templateUrl: './imp-aa.component.html',
  styleUrls: ['./imp-aa.component.css']
})
export class ImpAAComponent implements OnInit {
  impItemResp: PaginatedDataSource<ImportAA, ImportAAQuery>;
  initialSort: Sort<ImportAA> = { property: "created_date", order: "asc" };

  displayedColumns = [
    "impSrNo",
    "inputDescription",
    "technicalFeatures",
    "expSrNo",
    "itchsCode",
    "quantity",
    "uom",
    "cifInr",
    "cifForeign",
    "edit"
    ];
    dataSource = ELEMENT_DATA;
  
  
    pipe = new DatePipe("en-US");
  
   
  
    @BlockUI() blockUI: NgBlockUI | any;
    blockTemplate: BlockTemplateComponent  | any;
    role: any;
    authorizationDate: any;
    impDate: any;
    expDate: any;
  
    aaDetailsForm1 = this.formBuilder.group({
      officeName: [""],
      name: [""],
      address: [""],
      iecNo: [""],
      expType: [""],
      expStatus: [""],
      actualUser: [""],
      utilization: [""],
      fileNo: [""],
      normType: [""],
      expForeignCurrency: [""],
      expRate: [""],
    });
  
  
    currentUser: any;
    caseDetailsData: any;
    isButtonDisabled: boolean| any;
    date: string | any;
    modifiedCurrentDate: string | any;
    lastDate: any;
    isaaForm2Enable: boolean = false;
  applicationType: string | any;
  isShowMessage: boolean = false;
  showMessage: any;
  
    constructor(
      private formBuilder: UntypedFormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private overlay: Overlay,
      public dialog: MatDialog,
      public blockchainService: BlockchainService,
      private dateAdapter: DateAdapter<Date>
      ) {
        this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
      }
  
    ngOnInit(): void {
      let localstorageCurrentUser : any = localStorage.getItem("currentUser")
      this.currentUser = JSON.parse(localstorageCurrentUser);
      this.applicationType = localStorage.getItem('applicationType')
    this.getItemImportedById();
    }
  
   
  
    dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
      this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
      this.modifiedCurrentDate = this.date;
    }
  
    formatDate(date: string | number | Date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        year = d.getFullYear(),
        day = "" + d.getDay();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    }
  
  
    next(){
      this.router.navigate(['/supportingProjectAA'])
    }
   
  
    back(){
      this.router.navigate(['/exportAA'])
    }
  
    getItemImportedById() {
      let application_id = localStorage.getItem("application_id");
      let input = {
        application_id: application_id,
      };
      this.blockchainService.getItemImportedById(input).subscribe((resp) => {
        this.impItemResp = resp.body.obj;
        
        if (this.impItemResp !== null ) {
          this.isShowMessage = false;
          var impItemList = resp.body.obj;
          this.impItemResp = new PaginatedDataSource<ImportAA, ImportAAQuery>(
            (request, query) =>
              this.blockchainService.pageImportAA(request, query, impItemList),
            this.initialSort,
            { search: "", data: [] },
            10
          );
        } else{
          this.isShowMessage = true;
          this.showMessage = resp.body.message;
        }
      });
    }

    addImportAA(data: { update: boolean; }) {
      data.update = true;
      this.router.navigate(["/addImportAA", data]);
    }
  
   
  
    showNotification(from: any, align: any, color: any| string | number, stringMessage: any, icons: any) {
      const type = ["", "info", "success", "warning", "danger"];
  
      $.notify(
        {
          icon: icons,
          message: stringMessage,
        },
        {
          type: type[color],
          timer: 4000,
          placement: {
            from: from,
            align: align,
          },
          template:
            '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
            '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            "</div>" +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
            "</div>",
        }
      );
    }
}
