import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService, SummaryDetailsQuery1 } from "app/blockchainImpExp/blockchain.service";
import { AddUserComponent } from "../add-user/add-user.component";
import { AlertService } from "app/_services";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}
export interface PeriodicElement {
  no: number;
  zone_name: string;
  commissionerate: string;
  section:string;
  user_type:string;
  designation:string;
  name:string;
  email:string;
  mobile: string;
  level: string;
  section_list:string;
  username:string;
}

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnInit {

  selectedStatus = 'New';
  
  allStatus: Status[] = [
    {value: 'Incoming Date', viewValue: 'Incoming Date'},
    {value: 'Application Type', viewValue: 'Application Type'},
    {value: 'Application Stage', viewValue: 'Application Stage'},
    {value: 'File No', viewValue: 'File No'},
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  currentUser: any;
  role: any;
  dataSource:PeriodicElement[] = [];
 

  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  displayedColumns = [
    'no',
    'zone_name',
    'commissionerate',
    'section',
    'user_type',
    'designation',
    'level',
    'name',
    'email',
    'mobile',
    'section_list',
    // 'username',
    'action'
  ];

  autoFillForm = this.formBuilder.group({
    username: [""],
    companyname: [""],
    iecCode: [""],
  });
  getCustomsUsers: any;
  userDetails: any;
  summaryData: PaginatedDataSource<PeriodicElement, SummaryDetailsQuery1>;
  initialSort: Sort<PeriodicElement> = { property: "no", order: "desc" };
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = this.currentUser.obj.user_type_id;
    this.getAllCustomsUsers();

  }

  caseDetailsDialogue(data){
    localStorage.setItem('casedetailsData',JSON.stringify(data));
  }

  navigateToRegister(){
    this.router.navigate(['/registerTask']);
  }

  getAllCustomsUsers(){
    this.blockchainService.getAllCustomsUsers().subscribe(
      (resp) => {
        this.getCustomsUsers = resp.obj;
        this.dataSource = this.getCustomsUsers;
        console.log(this.dataSource);
        if (this.dataSource !== null) {
          console.log(this.dataSource);
          var summaryList = resp.obj;
          this.summaryData = new PaginatedDataSource<PeriodicElement, SummaryDetailsQuery1>(
            (request, query) =>
              this.blockchainService.pageUser(request, query, summaryList),
            this.initialSort,
            { search: "", data: undefined },
            10
          );
        }
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      });
  }

  addUser(){
  let action = "Add";
  // this.blockchainService.adminAction = action;
  localStorage.setItem('adminAction', JSON.stringify(action));
  this.router.navigate(["/addUser"]);
  }

  editUser(user){
  localStorage.setItem('adminUser', JSON.stringify(user));
  let action = "Edit";
  // this.blockchainService.adminAction = action;
  localStorage.setItem('adminAction', JSON.stringify(action));
  this.router.navigate(["/addUser"]);
 
  }

  deleteUser(){
  //  console.log(user);
   let user = this.userDetails;
   let input = {
    customs_user_id: user.customs_user_id,
  };
   this.blockchainService.deleteCustomsUsers(input).subscribe(
    (resp) => {
      this.getCustomsUsers = resp.body.obj;
      if(resp.status == 200){
        this.getAllCustomsUsers();
      this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );  
          setTimeout(() => {
            location.reload();
          }, 3000)
    }else{
      this.showNotification(
        "bottom",
        "right",
        "warning",
        "Failed To Delete User",
        "announcement"
      );
    }

  },
  (error) => {
    console.log(error)
    this.alertService.error(error);
    if(error.status == 401){
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Session Expired, Please Login Again",
        "announcement"
      );
      this.router.navigate(["/login"]);
    }
    else if(error.status == 500){
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please Try Again",
        "announcement"
      );
    }
    else{}
  });
}

openModal(user) {
  this.userDetails = user;
  const buttonModal = document.getElementById("openModalButton");
  buttonModal.click();
}

openDialog(action: any, obj: any, element: any) {
   
  const dialogConfig = new MatDialogConfig();
  obj.action = action;
  if (element != null) {
    const selectedUser = this.getCustomsUsers.find(
      (selected) => selected.customs_user_id === element.customs_user_id
    );
    obj.customs_user_id = selectedUser;
  }
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = obj;
  dialogConfig.height = "1000px";
  dialogConfig.width = "1440px";
  let dialogRef;
  if (action === "Edit") {
    dialogRef = this.dialog.open(AddUserComponent, dialogConfig);
  }
 
  dialogRef.afterClosed().subscribe((result) => {
    // alert('response: ' + result)
    if (result.event === "Edit" && result.isUpdated === true) {
      this.getAllCustomsUsers();
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Success",
        "announcement"
      );
    }
 
    
  });
}

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
