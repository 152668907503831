import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, Input, OnInit } from "@angular/core";
import {UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
// import { FindValueSubscriber } from "rxjs/internal/operators/find";
import { PaymentsumComponent } from "../imp_exp/payment/paymentsum/paymentsum.component";
import { DateAdapter } from "@angular/material/core";
import { AlertService } from "app/_services";
import { NgForm } from '@angular/forms';
declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.css"],
})
export class SignUpComponent implements OnInit {
  selectedStatus = "";
  pipe = new DatePipe("en-US");
  token: string|undefined;

  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI | any;
  blockTemplate: BlockTemplateComponent | any;
  role: any;

 

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean = false;
  isIecHolder: boolean = false;
  isApplicantDetails: boolean = false;
  iscbAuthorization: boolean = false;
  isAirline:boolean = false;
  isOther:boolean = false;
  isConsoleAgent:boolean = false;
  cbCity:any;
  cbState:any;
  aa_epcg_date:any;
  company_city:any;
  company_state:any;
  
  modifiedCurrentDate: any;
  date: any ;
  isCHA: boolean = false;
  active: boolean = false;
  saveIecDetails: any;
  iecId: any;
  updateIecDetails: any;
  getIecDetailsById: any;
  allStates: any;
  allCities: any;
  selectedStateName: any;
  selectedCityName: any;
  enableBtn: boolean = false;
  enableCbBtn: boolean = false;
  enableTimer:boolean = false;
  display:any;
  enableResend:boolean = true;
  airlineDetailsForm: UntypedFormGroup | any;
  consoleAgentDetailsForm: UntypedFormGroup | any;
  otherDetailsForm: UntypedFormGroup | any;
  companyDetailsForm: UntypedFormGroup | any;
  applicantDetailsForm: UntypedFormGroup | any;
  cbAuthorizationForm: UntypedFormGroup | any;
  chaDetailsForm: UntypedFormGroup | any;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
      this.token = undefined;
    }

  ngOnInit(): void {
    this.getStates();

    this.airlineDetailsForm = this.formBuilder.group({
      airline_name: ["", Validators.required],
      cb_cha_address:["",Validators.required],
      cbCity: [""],
      cbState: [""],
      spoc_name:["",Validators.required],
      cb_cha_mobile:["",[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      cb_cha_email:["",[Validators.required]],
      username:["",Validators.required],
      password:["",Validators.required],
      otp:[""]
    });
  
    this.consoleAgentDetailsForm = this.formBuilder.group({
      agent_name: ["", Validators.required],
      cb_cha_address:["",Validators.required],
      cbCity: [""],
      cbState: [""],
      name_of_depositor:["",Validators.required],
      cb_cha_mobile:["",Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      cb_cha_email:["",Validators.required],
      username:["",Validators.required],
      password:["",Validators.required],
      otp:[""]
    });
  
    this.otherDetailsForm = this.formBuilder.group({
      individual_or_organisation: ["", Validators.required],
      type_of_organisation:["",Validators.required],
      cbCity: [""],
      cbState: [""],
      cb_cha_address:["",Validators.required],
      cb_cha_mobile:["",Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      cb_cha_email:["",Validators.required],
      username:["",Validators.required],
      password:["",Validators.required],
      otp:[""]
    });

    this.companyDetailsForm = this.formBuilder.group({
      company_name: ["", Validators.required],
      company_city: [""],
      company_state: [""],
      company_address1: ["",Validators.required],
      company_address2: [""],
      company_phone: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      company_email: [
        "",
        [
          Validators.required
        ],
      ],
      iec_code: ["",[ Validators.required,Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern("^[a-zA-Z0-9]{10}$")]],
      gstin_no: ["",],
      // Validators.pattern("/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/")
    });
  
    this.applicantDetailsForm = this.formBuilder.group({
      applicant_name: ["", Validators.required],
      applicant_designation: ["", Validators.required],
      applicant_phone: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      applicant_email: [
        "",
        [
          Validators.required
        ],
      ],
      username: ["", Validators.required],
      password: ["", Validators.required],
      otp: [""],
    });
  
    this.cbAuthorizationForm = this.formBuilder.group({
      aa_epcg_no: [""],
      aa_epcg_date: [""],
      cb_cha_id: [""],
      cb_cha_mobile: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      cb_cha_name: [""],
      cb_cha_email: [
        "",
        [
          Validators.required
        ],
      ],
    });
  
    this.chaDetailsForm = this.formBuilder.group({
      cbName: ["", Validators.required],
      cbRegistrationNo: ["",],
      cbCity: [""],
      cbState: [""],
      cbAddress1: ["",Validators.required],
      cbAddress2: [""],
      cbMobile: [
        "",
        [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")],
      ],
      cbEmail: [
        "",
        [
          Validators.required
        ],
      ],
      username: [""],
      password: ["", Validators.required],
      otp: [""],
    });

  }

  backCb() {
    this.router.navigate(["/login"]);
  }

  get f() {
    return this.companyDetailsForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.companyDetailsForm.controls[controlName].hasError(errorName);
  };

  get f1() {
    return this.applicantDetailsForm.controls;
  }
  public hasError1 = (controlName: string, errorName: string) => {
    return this.applicantDetailsForm.controls[controlName].hasError(errorName);
  };

  get f2() {
    return this.cbAuthorizationForm.controls;
  }
  public hasError2 = (controlName: string, errorName: string) => {
    return this.cbAuthorizationForm.controls[controlName].hasError(errorName);
  };

  get f3() {
    return this.chaDetailsForm.controls;
  }
  public hasError3 = (controlName: string, errorName: string) => {
    return this.chaDetailsForm.controls[controlName].hasError(errorName);
  };
  public hasError4 = (controlName: string, errorName: string) => {
    return this.airlineDetailsForm.controls[controlName].hasError(errorName);
  };

  public hasError5 = (controlName: string, errorName: string) => {
    return this.consoleAgentDetailsForm.controls[controlName].hasError(errorName);
  };

  public hasError6 = (controlName: string, errorName: string) => {
    return this.otherDetailsForm.controls[controlName].hasError(errorName);
  };
  submit() {
    this.router.navigate(["/login"]);
  }

  selectRole(role: number) {
    if (role == 1) {
      this.isIecHolder = true;
      this.isCHA = false;
      this.isAirline = false;
      this.isConsoleAgent = false;
      this.isOther = false;
      this.active = true;
      localStorage.setItem("signUpRole", "IEC");
    } else if( (role == 2)){
      this.isIecHolder = false;
      this.isAirline = false;
      this.isConsoleAgent = false;
      this.isOther = false;
      this.isCHA = true;
      this.active = true;
      localStorage.setItem("signUpRole", "CB/CHA");
    }else if(role == 3){
      this.isIecHolder = false;
      this.isAirline = true;
      this.isConsoleAgent = false;
      this.isOther = false;
      this.isCHA = false;
      this.active = true;
      localStorage.setItem("signUpRole", "Airline");
    }else if(role == 4){
      this.isIecHolder = false;
      this.isAirline = false;
      this.isConsoleAgent = true;
      this.isOther = false;
      this.isCHA = false;
      this.active = true;
      localStorage.setItem("signUpRole", "ConsoleAgent");
    }else{
      this.isIecHolder = false;
      this.isAirline = false;
      this.isConsoleAgent = false;
      this.isOther = true;
      this.isCHA = false;
      this.active = true;
      localStorage.setItem("signUpRole", "Other");
    }
  }
  skip() {
    this.router.navigate(["/login"]);
  }

  back1() {
    this.router.navigate(["/login"]);
  }



  submit1() {
    if (
      this.companyDetailsForm.value.company_name == "" &&
      this.companyDetailsForm.value.iec_code == ""
    ) {
      this.showNotification(
        "bottom",
        "right",
        "warning",
        "Please fill all the mandatory details",
        "announcement"
      );
      this.isApplicantDetails = false;
    } else {
      this.isApplicantDetails = true;
    }
    // this.blockUI.start("Please wait a moment");
    // if (this.companyDetailsForm.valid) {
    //   this.isApplicantDetails = true;
    // } else {
    //   setTimeout(() => {
    //     this.blockUI.stop();
    //   }, 1500);
    // }
    // this.saveIECDetails()
  }

  public sendSubmit2(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }else{
     this.applicantDetailsForm.value.secret_key = this.token;
     this.submit2();
    }
    console.log(this.token)
  }

  submit2() {
    if(this.applicantDetailsForm.value.otp == '' || this.applicantDetailsForm.value.otp == null ||
    this.applicantDetailsForm.value.otp == undefined){
      this.openDialog(
        "paymentSum",
        "Please enter OTP",
        ""
      );
      this.blockUI.stop();
    } else{
      // let otpInput = {
      //   username: this.applicantDetailsForm.value.username,
      //   otp: this.applicantDetailsForm.value.otp,
      // };
      let otpInput :any = {
        secret_key : this.token,
        otp: this.applicantDetailsForm.value.otp,
        company_name: this.companyDetailsForm.value.company_name,
        username: this.applicantDetailsForm.value.username
          ? this.applicantDetailsForm.value.username
          : "",
        password: this.applicantDetailsForm.value.password
          ? this.applicantDetailsForm.value.password
          : "",
        company_city: this.companyDetailsForm.value.company_city
          ? this.companyDetailsForm.value.company_city
          : "",
        company_state: this.companyDetailsForm.value.company_state
          ? this.companyDetailsForm.value.company_state
          : "",
        company_address: this.companyDetailsForm.value.company_address1
          ? this.companyDetailsForm.value.company_address1 +
            this.companyDetailsForm.value.company_address2
          : "",
        company_email: this.companyDetailsForm.value.company_email
          ? this.companyDetailsForm.value.company_email
          : "",
        company_phone: this.companyDetailsForm.value.company_phone
          ? this.companyDetailsForm.value.company_phone
          : "",
        iec_code: this.companyDetailsForm.value.iec_code
          ? this.companyDetailsForm.value.iec_code
          : "",
        gstin_no: this.companyDetailsForm.value.gstin_no
          ? this.companyDetailsForm.value.gstin_no
          : "",
  
        applicant_name: this.applicantDetailsForm.value.applicant_name
          ? this.applicantDetailsForm.value.applicant_name
          : "",
        applicant_designation: this.applicantDetailsForm.value
          .applicant_designation
          ? this.applicantDetailsForm.value.applicant_designation
          : "",
        applicant_phone: this.applicantDetailsForm.value.applicant_phone
          ? this.applicantDetailsForm.value.applicant_phone
          : "",
        applicant_email: this.applicantDetailsForm.value.applicant_email
          ? this.applicantDetailsForm.value.applicant_email
          : "",
        aa_epcg_no: this.cbAuthorizationForm.value.aa_epcg_no
          ? this.cbAuthorizationForm.value.aa_epcg_no
          : "",
        aa_epcg_date: this.cbAuthorizationForm.value.aa_epcg_date
          ? this.cbAuthorizationForm.value.aa_epcg_date
          : "",
        cb_cha_id: this.cbAuthorizationForm.value.cb_cha_id
          ? this.cbAuthorizationForm.value.cb_cha_id
          : "",
        cb_cha_mobile: this.cbAuthorizationForm.value.cb_cha_mobile
          ? this.cbAuthorizationForm.value.cb_cha_mobile
          : "",
        cb_cha_name: this.cbAuthorizationForm.value.cb_cha_name
          ? this.cbAuthorizationForm.value.cb_cha_name
          : "",
        cb_cha_email: this.cbAuthorizationForm.value.cb_cha_email
          ? this.cbAuthorizationForm.value.cb_cha_email
          : "",
        zone_id: "1",
      };
      this.verifyOtpIec(otpInput);
    }
  }



  back2() {
    this.isIecHolder = true;
    this.isApplicantDetails = false;
    this.getIecById();
  }

  getIecById() {
    this.blockchainService
      .getIecById(this.companyDetailsForm.value)
      .subscribe((resp) => {
        this.getIecDetailsById = resp.body.obj;
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      }
      );
  }

  sendSubmit3() {
    
    this.saveIECDetails();
  }

  getStates() {
    this.blockchainService.getStates().subscribe((resp) => {
      this.allStates = resp.obj;
      // this.getCitesByState()
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  selectedState(event: { isUserInput: any; }, state: any) {
    if (event.isUserInput) {
      console.log(event);
      this.selectedStateName = state;
      this.getCitesByState(this.selectedStateName);
      this.selectedCityName = "";
    }
  }

  selectedCity(event: { isUserInput: any; }, city: any) {
    if (event.isUserInput) {
      this.selectedCityName = city;
    }
  }

  getCitesByState(state: any) {
    let input = {
      company_state: state,
    };
    this.blockchainService.getCitesByState(input).subscribe((resp) => {
      this.allCities = resp.body.obj;
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }
  public sendSendOtpAgent(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }else{
     this.consoleAgentDetailsForm.value.secret_key = this.token;
     this.sendOtpCbchaConsole()
    }
    console.log(this.token)
  }
  public sendSendOtpOthers(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }else{
     this.otherDetailsForm.value.secret_key = this.token;
     this.sendOtpCbchaOther();
    }
    console.log(this.token)
  }
  public sendSendOtpAirlines(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }else{
     this.airlineDetailsForm.value.secret_key = this.token;
     this.sendOtpCbchaAirline();
    }
    console.log(this.token)
  }
  public sendSendOtpIec(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }else{
     this.applicantDetailsForm.value.secret_key = this.token;
     this.sendOtpIec();
    }
    console.log(this.token)
  }

  sendOtpIec() {
        this.blockUI.start("Please wait a moment");
        
    if(this.applicantDetailsForm.value.applicant_email != null && this.applicantDetailsForm.value.applicant_email != undefined &&
      this.applicantDetailsForm.value.applicant_email != ''){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
      
          let input = {
            applicant_email:this.applicantDetailsForm.value.applicant_email ,
            applicant_phone:this.applicantDetailsForm.value.applicant_phone,
            secret_key : this.token
          };
          this.blockchainService.sendOtpIec(input).subscribe((resp) => {
            if (resp.body.status == 200) {
              this.enableTimer = true;
              this.timer(1);
              this.enableCbBtn = true;
              setTimeout(() => {
                    this.blockUI.stop();
                  }, 1500);
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.body.message,
                "announcement"
              );
            } else {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.showNotification(
                "bottom",
                "right",
                "warning",
                resp.body.message,
                "announcement"
              );
            }
          },
          (error) => {
            console.log(error)
            this.alertService.error(error);
            if(error.status == 401){
              location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Session Expired, Please Login Again",
                "announcement"
              );
              this.router.navigate(["/login"]);
            }
            else if(error.status == 500){
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Try Again",
                "announcement"
              );
            }
            else{}
          }  
          );
        
      } else{
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.openDialog(
          "paymentSum",
          "Please fill Username",
          ""
        );
      }
  
  }

  verifyOtpIec(input: { secret_key: string | undefined; otp: any; company_name: any; username: any; password: any; company_city: any; company_state: any; company_address: any; company_email: any; company_phone: any; iec_code: any; gstin_no: any; applicant_name: any; applicant_designation: any; applicant_phone: any; applicant_email: any; aa_epcg_no: any; aa_epcg_date: any; cb_cha_id: any; cb_cha_mobile: any; cb_cha_name: any; cb_cha_email: any; zone_id: string; }) {
    this.blockUI.start("Verifying OTP");
    this.blockchainService.verifyOtpIec(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        this.router.navigate(["/login"]);

        // if (this.applicantDetailsForm.valid) {
        //   // this.saveIECDetails();
        // } else {
        //   this.openDialog(
        //     "paymentSum",
        //     "Please fill the mandatory details",
        //     ""
        //   );
        // }
        // this.showNotification(
        //   "bottom",
        //   "right",
        //   "success",
        //   'User Created Successfully',
        //   "announcement"
        // );
      } else {
           setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "warning",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  checkIecNo(){
    if(this.companyDetailsForm.value.iec_code.length==10){
      let input = {
        iec_code: this.companyDetailsForm.value.iec_code,
      };
      this.blockchainService.getIecHolderByIecNoNoauth(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          this.showNotification(
            "bottom",
            "right",
            "success",
            "IEC Holder with this IEC code Already Exists with username : "+resp.body.obj.username,
            "announcement"
          );
        } else {
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
         
        }
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      } 
      );
    }
  }

  public sendSendOtpCbcha(form: NgForm): void {
    if (form.invalid) {
      console.log(form);
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }else{
     this.chaDetailsForm.value.secret_key = this.token;
     console.log(this.token)
     console.log(this.chaDetailsForm);
     this.sendOtpCbcha();
    }
    console.log(this.token)
  }

  sendOtpCbcha() {
    console.log(this.chaDetailsForm);
      console.log(this.chaDetailsForm);
    this.blockUI.start("Please wait a moment");
    if(this.chaDetailsForm.value.cbEmail != null && this.chaDetailsForm.value.cbEmail != undefined
      && this.chaDetailsForm.value.cbEmail != ''){
       
    
          let input = {
            cb_cha_email: this.chaDetailsForm.value.cbEmail,
            cb_cha_mobile:this.chaDetailsForm.value.cbMobile,
            secret_key : this.token
          };
          console.log(input);
          this.blockchainService.sendOtpCbcha(input).subscribe((resp) => {
            if (resp.body.status == 200) {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.enableTimer = true;
              // this.enableResend = true;
              this.timer(1);
              this.enableCbBtn = true;
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.body.message,
                "announcement"
              );
            } else {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.showNotification(
                "bottom",
                "right",
                "warning",
                resp.body.message,
                "announcement"
              );
            }
          },
          (error) => {
            console.log(error)
            this.alertService.error(error);
            if(error.status == 401){
              location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Session Expired, Please Login Again",
                "announcement"
              );
              this.router.navigate(["/login"]);
            }
            else if(error.status == 500){
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Try Again",
                "announcement"
              );
            }
            else{}
          }
          );
        
    } else {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.openDialog(
        "paymentSum",
        "Please fill all the mandatory details",
        ""
      );
    } 
  }

  sendOtpCbchaAirline() {
    this.blockUI.start("Please wait a moment");
    if(this.airlineDetailsForm.value.cb_cha_email != null && this.airlineDetailsForm.value.cb_cha_email != undefined
      && this.airlineDetailsForm.value.cb_cha_email != ''){
      
          let input = {
            cb_cha_email: this.airlineDetailsForm.value.cb_cha_email,
            cb_cha_mobile:this.airlineDetailsForm.value.cb_cha_mobile,
            secret_key : this.token
          };
          this.blockchainService.sendOtpCbcha(input).subscribe((resp) => {
            if (resp.body.status == 200) {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.enableTimer = true;
              // this.enableResend = true;
              this.timer(1);
              this.enableCbBtn = true;
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.body.message,
                "announcement"
              );
            } else {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.showNotification(
                "bottom",
                "right",
                "warning",
                resp.body.message,
                "announcement"
              );
            }
          },
          (error) => {
            console.log(error)
            this.alertService.error(error);
            if(error.status == 401){
              location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Session Expired, Please Login Again",
                "announcement"
              );
              this.router.navigate(["/login"]);
            }
            else if(error.status == 500){
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Try Again",
                "announcement"
              );
            }
            else{}
          }
          );
        
    } else {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.openDialog(
        "paymentSum",
        "Please fill all the mandatory details",
        ""
      );
    } 
  }
  sendOtpCbchaConsole() {
    this.blockUI.start("Please wait a moment");
    if(this.consoleAgentDetailsForm.value.cb_cha_email != null && this.consoleAgentDetailsForm.value.cb_cha_email != undefined
      && this.consoleAgentDetailsForm.value.cb_cha_email != ''){
          let input = {
            cb_cha_email: this.consoleAgentDetailsForm.value.cb_cha_email,
            cb_cha_mobile:this.consoleAgentDetailsForm.value.cb_cha_mobile,
            secret_key : this.token
          };
          this.blockchainService.sendOtpCbcha(input).subscribe((resp) => {
            if (resp.body.status == 200) {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.enableTimer = true;
              // this.enableResend = false;
              this.timer(1);
              this.enableCbBtn = true;
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.body.message,
                "announcement"
              );
            } else {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.showNotification(
                "bottom",
                "right",
                "warning",
                resp.body.message,
                "announcement"
              );
            }
          });
        
    } else {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.openDialog(
        "paymentSum",
        "Please fill all the mandatory details",
        ""
      );
    } 
  }
  sendOtpCbchaOther() {
    this.blockUI.start("Please wait a moment");
    this.enableTimer = false;
   
    if(this.otherDetailsForm.value.cb_cha_email != null && this.otherDetailsForm.value.cb_cha_email != undefined
      && this.otherDetailsForm.value.cb_cha_email != ''){
       
 
          let input = {
            cb_cha_email: this.otherDetailsForm.value.cb_cha_email,
            cb_cha_mobile:this.otherDetailsForm.value.cb_cha_mobile,
            secret_key : this.token
          };
          this.blockchainService.sendOtpCbcha(input).subscribe((resp) => {
            if (resp.body.status == 200) {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.enableTimer = true;
              // this.enableResend = false;
              this.timer(1);
              this.enableCbBtn = true;
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.body.message,
                "announcement"
              );
            } else {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.showNotification(
                "bottom",
                "right",
                "warning",
                resp.body.message,
                "announcement"
              );
            }
          },
          (error) => {
            console.log(error)
            this.alertService.error(error);
            if(error.status == 401){
              location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Session Expired, Please Login Again",
                "announcement"
              );
              this.router.navigate(["/login"]);
            }
            else if(error.status == 500){
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Try Again",
                "announcement"
              );
            }
            else{}
          }
          );
        
    } else {
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      this.openDialog(
        "paymentSum",
        "Please fill all the mandatory details",
        ""
      );
    } 
  }
  timer(minute: any) {
    // let minute = 1;
    this.enableResend = true;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.enableResend = false;
        console.log("finished");
        clearInterval(timer);
      }
    }, 1000);
  }
  public captchaResolved : boolean = false;
  checkCaptcha(captchaResponse : string) {
    this.captchaResolved = (captchaResponse && captchaResponse.length > 0) ? true : false
}
public captchaResolved1 : boolean = false;
checkCaptcha1(captchaResponse1 : string) {
  this.captchaResolved1 = (captchaResponse1 && captchaResponse1.length > 0) ? true : false
}
public captchaResolved2 : boolean = false;
checkCaptcha2(captchaResponse2 : string) {
  this.captchaResolved2 = (captchaResponse2 && captchaResponse2.length > 0) ? true : false
}
public captchaResolved3 : boolean = false;
checkCaptcha3(captchaResponse3 : string) {
  this.captchaResolved3 = (captchaResponse3 && captchaResponse3.length > 0) ? true : false
}
public captchaResolved4 : boolean = false;
checkCaptcha4(captchaResponse4 : string) {
  this.captchaResolved4 = (captchaResponse4 && captchaResponse4.length > 0) ? true : false
}
public captchaResolved5 : boolean = false;
checkCaptcha5(captchaResponse5 : string) {
  this.captchaResolved5 = (captchaResponse5 && captchaResponse5.length > 0) ? true : false
}

  verifyOtpCbcha() {
    this.blockUI.start("Please wait a moment");
    if(this.chaDetailsForm.value.otp == '' || this.chaDetailsForm.value.otp == null ||
    this.chaDetailsForm.value.otp == undefined){
      this.openDialog(
        "paymentSum",
        "Please enter OTP",
        ""
      );
      this.blockUI.stop();
    } else{
      // let otpInput = {
      //   cb_cha_email: this.chaDetailsForm.value.cbEmail,
      //   cb_cha_mobile:this.chaDetailsForm.value.cbMobile,
      //   otp: this.chaDetailsForm.value.otp,
      // };
      let otpInput = {
        secret_key : this.token,
        otp: this.chaDetailsForm.value.otp,
        cb_cha_company_name: "",
        cb_cha_username: this.chaDetailsForm.value.username ? this.chaDetailsForm.value.username : '',
        cb_cha_password: this.chaDetailsForm.value.password ? this.chaDetailsForm.value.password : '',
        cb_cha_address: this.chaDetailsForm.value.cbAddress1? this.chaDetailsForm.value.cbAddress1+"@@@"+this.chaDetailsForm.value.cbAddress2  : '',
        cb_cha_city: this.chaDetailsForm.value.cbCity ? this.chaDetailsForm.value.cbCity : '',
        cb_cha_state: this.chaDetailsForm.value.cbState ? this.chaDetailsForm.value.cbState: '',
        cb_cha_reg_no: this.chaDetailsForm.value.cbRegistrationNo ? this.chaDetailsForm.value.cbRegistrationNo : '',
        cb_cha_name: this.chaDetailsForm.value.cbName ? this.chaDetailsForm.value.cbName : '',
        cb_cha_mobile: this.chaDetailsForm.value.cbMobile ? this.chaDetailsForm.value.cbMobile :'',
        cb_cha_email: this.chaDetailsForm.value.cbEmail ? this.chaDetailsForm.value.cbEmail:'', 
        zone_id: "1",
      };
  
      this.blockchainService.verifyOtpCbcha(otpInput).subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.saveCbCha();
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.showNotification(
            "bottom",
            "right",
            "success",
            'User Created Successfully',
            "announcement"
          );
          this.router.navigate(["/login"]);
  
        } else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "warning",
            resp.body.message,
            "announcement"
          );
        }
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      }
      );
    }
  }
  verifyConsoleOtpCbcha(){
    this.blockUI.start("Please wait a moment");
    if(this.consoleAgentDetailsForm.value.otp == '' || this.consoleAgentDetailsForm.value.otp == null ||
    this.chaDetailsForm.value.otp == undefined){
      this.openDialog(
        "paymentSum",
        "Please enter OTP",
        ""
      );
      this.blockUI.stop();
    } else{
      // let otpInput = {
      //   cb_cha_email:this.consoleAgentDetailsForm.value.cb_cha_email,
      //   cb_cha_mobile:this.consoleAgentDetailsForm.value.cb_cha_mobile,
      //   otp: this.consoleAgentDetailsForm.value.otp,
      // };
      let otpInput = {
        secret_key : this.token,
        otp: this.consoleAgentDetailsForm.value.otp,
        agent_name:this.consoleAgentDetailsForm.value.agent_name,
        cb_cha_address:this.consoleAgentDetailsForm.value.cb_cha_address,
        name_of_depositor:this.consoleAgentDetailsForm.value.name_of_depositore,
        cb_cha_city: this.consoleAgentDetailsForm.value.cbCity,
        cb_cha_state: this.consoleAgentDetailsForm.value.cbState,
        cb_cha_mobile: this.consoleAgentDetailsForm.value.cb_cha_mobile,
        cb_cha_email: this.consoleAgentDetailsForm.value.cb_cha_email, 
        cb_cha_username: this.consoleAgentDetailsForm.value.username,
        cb_cha_password: this.consoleAgentDetailsForm.value.password,
      }
      this.blockchainService.verifyOtpCbcha(otpInput).subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.saveCbCha();
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.showNotification(
            "bottom",
            "right",
            "success",
            'User Created Successfully',
            "announcement"
          );
          this.router.navigate(["/login"]);
  
        } else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "warning",
            resp.body.message,
            "announcement"
          );
        }
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      }
      );
    }
  }

  verifyOtherOtpCbcha(){
    this.blockUI.start("Please wait a moment");
    if(this.otherDetailsForm.value.otp == '' || this.otherDetailsForm.value.otp == null ||
    this.chaDetailsForm.value.otp == undefined){
      this.openDialog(
        "paymentSum",
        "Please enter OTP",
        ""
      );
      this.blockUI.stop();
    } else{
      // let otpInput = {
      //   cb_cha_email: this.otherDetailsForm.value.cb_cha_email,
      //   cb_cha_mobile:this.otherDetailsForm.value.cb_cha_mobile,
      //   otp: this.otherDetailsForm.value.otp,
      // };
      let otpInput = {
        secret_key : this.token,
        otp: this.otherDetailsForm.value.otp,
        individual_or_organisation:this.otherDetailsForm.value.individual_or_organisation,
        cb_cha_address:this.otherDetailsForm.value.cb_cha_address,
        type_of_organisation:this.otherDetailsForm.value.type_of_organisation,
        cb_cha_city: this.otherDetailsForm.value.cbCity,
        cb_cha_state: this.otherDetailsForm.value.cbState,
        cb_cha_mobile: this.otherDetailsForm.value.cb_cha_mobile,
        cb_cha_email: this.otherDetailsForm.value.cb_cha_email,
        cb_cha_username: this.otherDetailsForm.value.username,
        cb_cha_password: this.otherDetailsForm.value.password, 
      }
      this.blockchainService.verifyOtpCbcha(otpInput).subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.saveCbCha();
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.showNotification(
            "bottom",
            "right",
            "success",
            'User Created Successfully',
            "announcement"
          );
          this.router.navigate(["/login"]);
  
        } else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "warning",
            resp.body.message,
            "announcement"
          );
        }
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      }
      );
    }
  }

  verifyAirlineOtpCbcha(){
    this.blockUI.start("Please wait a moment");
    if(this.airlineDetailsForm.value.otp == '' || this.airlineDetailsForm.value.otp == null ||
    this.chaDetailsForm.value.otp == undefined){
      this.openDialog(
        "paymentSum",
        "Please enter OTP",
        ""
      );
      this.blockUI.stop();
    } else{
      // let otpInput = {
      //   cb_cha_email: this.airlineDetailsForm.value.cb_cha_email,
      //   cb_cha_mobile:this.airlineDetailsForm.value.cb_cha_mobile,
      //   otp: this.airlineDetailsForm.value.otp,
      // };
      let otpInput = {
        secret_key : this.token,
        otp: this.airlineDetailsForm.value.otp,
        airline_name:this.airlineDetailsForm.value.airline_name,
        cb_cha_address:this.airlineDetailsForm.value.cb_cha_address,
        spoc_name:this.airlineDetailsForm.value.spoc_name,
        cb_cha_city: this.airlineDetailsForm.value.cbCity,
        cb_cha_state: this.airlineDetailsForm.value.cbState,
        cb_cha_mobile: this.airlineDetailsForm.value.cb_cha_mobile,
        cb_cha_email: this.airlineDetailsForm.value.cb_cha_email, 
        cb_cha_username: this.airlineDetailsForm.value.username,
        cb_cha_password: this.airlineDetailsForm.value.password,
      }
      this.blockchainService.verifyOtpCbcha(otpInput).subscribe((resp) => {
        if (resp.body.status == 200) {
          // this.saveCbCha();
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.showNotification(
            "bottom",
            "right",
            "success",
            'User Created Successfully',
            "announcement"
          );
          this.router.navigate(["/login"]);
  
        } else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "warning",
            resp.body.message,
            "announcement"
          );
        }
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      } 
      );
    }
  }
  public sendSaveIECDetails(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }else{
     this.companyDetailsForm.value.secret_key = this.token;
     this.saveIECDetails()
    }
    console.log(this.token)
  }

  saveIECDetails() {
 
      let input = {
        company_name: this.companyDetailsForm.value.company_name,
        username: this.applicantDetailsForm.value.username
          ? this.applicantDetailsForm.value.username
          : "",
        password: this.applicantDetailsForm.value.password
          ? this.applicantDetailsForm.value.password
          : "",
        company_city: this.companyDetailsForm.value.company_city
          ? this.companyDetailsForm.value.company_city
          : "",
        company_state: this.companyDetailsForm.value.company_state
          ? this.companyDetailsForm.value.company_state
          : "",
        company_address: this.companyDetailsForm.value.company_address1
          ? this.companyDetailsForm.value.company_address1 +
            this.companyDetailsForm.value.company_address2
          : "",
        company_email: this.companyDetailsForm.value.company_email
          ? this.companyDetailsForm.value.company_email
          : "",
        company_phone: this.companyDetailsForm.value.company_phone
          ? this.companyDetailsForm.value.company_phone
          : "",
        iec_code: this.companyDetailsForm.value.iec_code
          ? this.companyDetailsForm.value.iec_code
          : "",
        gstin_no: this.companyDetailsForm.value.gstin_no
          ? this.companyDetailsForm.value.gstin_no
          : "",
  
        applicant_name: this.applicantDetailsForm.value.applicant_name
          ? this.applicantDetailsForm.value.applicant_name
          : "",
        applicant_designation: this.applicantDetailsForm.value
          .applicant_designation
          ? this.applicantDetailsForm.value.applicant_designation
          : "",
        applicant_phone: this.applicantDetailsForm.value.applicant_phone
          ? this.applicantDetailsForm.value.applicant_phone
          : "",
        applicant_email: this.applicantDetailsForm.value.applicant_email
          ? this.applicantDetailsForm.value.applicant_email
          : "",
        aa_epcg_no: this.cbAuthorizationForm.value.aa_epcg_no
          ? this.cbAuthorizationForm.value.aa_epcg_no
          : "",
        aa_epcg_date: this.cbAuthorizationForm.value.aa_epcg_date
          ? this.cbAuthorizationForm.value.aa_epcg_date
          : "",
        cb_cha_id: this.cbAuthorizationForm.value.cb_cha_id
          ? this.cbAuthorizationForm.value.cb_cha_id
          : "",
        cb_cha_mobile: this.cbAuthorizationForm.value.cb_cha_mobile
          ? this.cbAuthorizationForm.value.cb_cha_mobile
          : "",
        cb_cha_name: this.cbAuthorizationForm.value.cb_cha_name
          ? this.cbAuthorizationForm.value.cb_cha_name
          : "",
        cb_cha_email: this.cbAuthorizationForm.value.cb_cha_email
          ? this.cbAuthorizationForm.value.cb_cha_email
          : "",
        zone_id: "1",
      };
  
      this.blockchainService.saveIECDetails(input).subscribe((resp) => {
        this.saveIecDetails = resp.body.obj;
        if (resp.body.status == 200) {
          this.token=undefined;
          this.sendOtpIec();
          // this.router.navigate(["/login"]);
          // this.showNotification(
          //   "bottom",
          //   "right",
          //   "success",
          //   resp.body.message,
          //   "announcement"
          // );
        } else {
          this.showNotification(
            "bottom",
            "right",
            "warning",
            resp.body.message,
            "announcement"
          );
        }
      },
      (error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          // location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          // location.reload();
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
        }
        else{}
      }
      );
    
  
  }

  submitCb() {
   
      // this.verifyOtpCbcha(otpInput);
      this.saveCbCha();
    
  
  }

  saveCbCha() {
   if(!this.chaDetailsForm.valid){
    this.showNotification(
          "bottom",
          "right",
          "success",
         "Please fill all the details",
          "announcement"
        );
   }else{

   
    let input = {
      cb_cha_company_name: "",
      cb_cha_username: this.chaDetailsForm.value.username ? this.chaDetailsForm.value.username : '',
      cb_cha_password: this.chaDetailsForm.value.password ? this.chaDetailsForm.value.password : '',
      cb_cha_address: this.chaDetailsForm.value.cbAddress1? this.chaDetailsForm.value.cbAddress1+"@@@"+this.chaDetailsForm.value.cbAddress2  : '',
      cb_cha_city: this.chaDetailsForm.value.cbCity ? this.chaDetailsForm.value.cbCity : '',
      cb_cha_state: this.chaDetailsForm.value.cbState ? this.chaDetailsForm.value.cbState: '',
      cb_cha_reg_no: this.chaDetailsForm.value.cbRegistrationNo ? this.chaDetailsForm.value.cbRegistrationNo : '',
      cb_cha_name: this.chaDetailsForm.value.cbName ? this.chaDetailsForm.value.cbName : '',
      cb_cha_mobile: this.chaDetailsForm.value.cbMobile ? this.chaDetailsForm.value.cbMobile :'',
      cb_cha_email: this.chaDetailsForm.value.cbEmail ? this.chaDetailsForm.value.cbEmail:'', 
      zone_id: "1",
    };

    this.blockUI.start("Please wait a moment");

    this.blockchainService.saveCbCha(input).subscribe((resp) => {
      this.saveIecDetails = resp.body.obj;
      // setTimeout(() => {
      //   this.blockUI.stop();
      // }, 1500);
      if (resp.body.status == 200) {
        this.sendOtpCbcha();
         setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        // this.showNotification(
        //   "bottom",
        //   "right",
        //   "success",
        //   resp.body.message,
        //   "announcement"
        // );
        // this.router.navigate(["/login"]);
      } else {
        setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        // this.showNotification(
        //   "bottom",
        //   "right",
        //   "warning",
        //   resp.body.message,
        //   "announcement"
        // );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
   }
  }

  saveAirlineDetails(){
    if(!this.airlineDetailsForm.valid){
      this.showNotification(
            "bottom",
            "right",
            "success",
           "Please fill all the details",
            "announcement"
          );
     }else{

     
    let input = {
      airline_name:this.airlineDetailsForm.value.airline_name,
      cb_cha_address:this.airlineDetailsForm.value.cb_cha_address,
      spoc_name:this.airlineDetailsForm.value.spoc_name,
      cb_cha_city: this.airlineDetailsForm.value.cbCity,
      cb_cha_state: this.airlineDetailsForm.value.cbState,
      cb_cha_mobile: this.airlineDetailsForm.value.cb_cha_mobile,
      cb_cha_email: this.airlineDetailsForm.value.cb_cha_email, 
      cb_cha_username: this.airlineDetailsForm.value.username,
      cb_cha_password: this.airlineDetailsForm.value.password,
    }
    this.blockchainService.saveCbCha(input).subscribe((resp) => {
      this.saveIecDetails = resp.body.obj;
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      if (resp.body.status == 200) {
        // saveAirlineDetails;
        this.sendOtpCbchaAirline();
         setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        //  this.router.navigate(["/login"]);
      } else {
        setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "warning",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
   }
  }
  saveConsoleDetails(){
    if(!this.consoleAgentDetailsForm.valid){
      this.showNotification(
            "bottom",
            "right",
            "success",
           "Please fill all the details",
            "announcement"
          );
     }else{
    let input = {
      agent_name:this.consoleAgentDetailsForm.value.agent_name,
      cb_cha_address:this.consoleAgentDetailsForm.value.cb_cha_address,
      name_of_depositor:this.consoleAgentDetailsForm.value.name_of_depositore,
      cb_cha_city: this.consoleAgentDetailsForm.value.cbCity,
      cb_cha_state: this.consoleAgentDetailsForm.value.cbState,
      cb_cha_mobile: this.consoleAgentDetailsForm.value.cb_cha_mobile,
      cb_cha_email: this.consoleAgentDetailsForm.value.cb_cha_email, 
      cb_cha_username: this.consoleAgentDetailsForm.value.username,
      cb_cha_password: this.consoleAgentDetailsForm.value.password,
    }
    this.blockchainService.saveCbCha(input).subscribe((resp) => {
      this.saveIecDetails = resp.body.obj;
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      if (resp.body.status == 200) {
        // this.sendOtpCbcha();
        this.sendOtpCbchaConsole();
         setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      // this.router.navigate(["/login"]);
      } else {
        setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "warning",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }
  }
  saveOtherDetails(){
    if(!this.otherDetailsForm.valid){
      this.showNotification(
            "bottom",
            "right",
            "success",
           "Please fill all the details",
            "announcement"
          );
     }else{
    let input = {
      individual_or_organisation:this.otherDetailsForm.value.individual_or_organisation,
      cb_cha_address:this.otherDetailsForm.value.cb_cha_address,
      type_of_organisation:this.otherDetailsForm.value.type_of_organisation,
      cb_cha_city: this.otherDetailsForm.value.cbCity,
      cb_cha_state: this.otherDetailsForm.value.cbState,
      cb_cha_mobile: this.otherDetailsForm.value.cb_cha_mobile,
      cb_cha_email: this.otherDetailsForm.value.cb_cha_email,
      cb_cha_username: this.otherDetailsForm.value.username,
      cb_cha_password: this.otherDetailsForm.value.password, 
    }
    this.blockchainService.saveCbCha(input).subscribe((resp) => {
      this.saveIecDetails = resp.body.obj;
      setTimeout(() => {
        this.blockUI.stop();
      }, 1500);
      if (resp.body.status == 200) {
        // this.sendOtpCbcha();
        this.sendOtpCbchaOther();
         setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      //  this.router.navigate(["/login"]);
      } else {
        setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "warning",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }
  }
  updateIECDetails() {
    let input;
    input = this.applicantDetailsForm.value;
    input.iec_holder_id = this.iecId;
    this.blockchainService.updateIECDetails(input).subscribe((resp) => {
      this.updateIecDetails = resp.body.obj;
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  statusFn(e: string, status: any) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date: string | number | Date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef:any;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(CustomScreeningMessageComponent, dialogConfig);
    }
  if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }
    dialogRef.afterClosed().subscribe((result: { event: string; isUpdated: boolean; }) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from: string, align: string, color: any, stringMessage: string, icons: string) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
