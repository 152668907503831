import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-aa-summary',
  templateUrl: './aa-summary.component.html',
  styleUrls: ['./aa-summary.component.css']
})
export class AaSummaryComponent implements OnInit {
  selectedStatus = "";
  pipe = new DatePipe("en-US");

  selectedDate:any;
  endDate:any;
  
  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI| any;
  blockTemplate: BlockTemplateComponent| any;
  role: any;
  validityDate:any;
  eoEndDate:any;
  aaSummaryForm = this.formBuilder.group({
    aaNo: [""],
    selectedDate: [""],
    port:["INBOM4"],
    cif: [""],
    fob:[""],
    // itchs:[""],
    validityDate:[""],
    eoEndDate:[""]
  });

  

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean | any;
  modifiedCurrentDate: any;
  date: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) {}

  ngOnInit(): void {
    let localstorageCurrentUser : any = localStorage.getItem("currentUser")
    this.currentUser = JSON.parse(localstorageCurrentUser);
  }

cancel(){
  this.router.navigate(["/applnSummary"])

}

dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
  console.log("Event", event);
  // this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
  this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
  console.log("First Date=" + this.date);
  this.modifiedCurrentDate = this.date;
  console.log("helllooo", this.modifiedCurrentDate);
}

formatDate(date: string | number | Date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    year = d.getFullYear(),
    day = "" + d.getDay();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}

next(){
  this.router.navigate(["/bondBg"])
}
  statusFn(e: string, status: any) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }
 

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef:any;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result: { event: string; isUpdated: boolean; }) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from: any, align: any, color: any|string | number, stringMessage: any, icons: any) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
