import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { UntypedFormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { MatTableDataSource } from '@angular/material/table';
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { Month } from "../payment-daily-summary/month";
import { isThisQuarter } from "date-fns";
import { DateAdapter } from "@angular/material/core";

var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface PeriodicElement {
  nature_of_payment: string;
  account_of_audit: string|number;
  account_of_insvestigation: string|number;
  against_EPCG_license: string|number;
  against_DEEC_or_advance_authorization_license: string|number;
  return_of_Export_incentive: string|number;
  commissioner_appeal: string|number;
  cestat: string|number;
  high_Court: string|number;
  supreme_Court: string|number;
  other: string|number;
  manual_Baggage: string|number;
  unaccompained_Baggage: string|number;
  bill_Of_Entry_through_Manual_Baggage: string|number;
  shipping_Bill_through_Manual_Baggage:string|number;
  seized_Goods: string|number;
  confiscated_Goods: string|number;
  uclaimed_Goods_or_Cargo: string|number;
  uncleared_Goods: string|number;
  business_to_Business: string|number;
  personal_imports: string|number;
  gifts: string|number;
  revenue_Stamp: string|number;
  miscellaneous: string|number;
}

export interface GroupBy {
  totalPayable: string;
  isGroupBy: boolean;
  totalAmt: string;
}

export interface MonthType {
  value: string;
  viewValue: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



export interface calendarView {
  value: string;
  viewValue: string;
}



@Component({
  selector: 'app-payment-received-detail-summary',
  templateUrl: './payment-received-detail-summary.component.html',
  styleUrls: ['./payment-received-detail-summary.component.css']
})
export class PaymentReceivedDetailSummaryComponent implements OnInit {
  selectedStatus = 'New';
  pipe = new DatePipe("en-US");
  allStatus: Status[] = [
    { value: 'Incoming Date', viewValue: 'Incoming Date' },
    { value: 'IEC Code', viewValue: 'IEC Code' },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  currentUser: any;
  role: any;
  dataSource: PeriodicElement[];

  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  displayedColumns = [
    'nature_of_payment',
    'account_of_audit',
    'account_of_insvestigation',
    'against_EPCG_license',
    'against_DEEC_or_advance_authorization_license',
    'return_of_Export_incentive',
    'commissioner_appeal',
    'cestat',
    'high_Court',
    'supreme_Court',
    'other',
    'manual_Baggage',
    'unaccompained_Baggage',
    'bill_Of_Entry_through_Manual_Baggage',
    'shipping_Bill_through_Manual_Baggage',
    'seized_Goods',
    'confiscated_Goods',
    'uclaimed_Goods_or_Cargo',
    'uncleared_Goods',
    'business_to_Business',
    'personal_imports',
    'gifts',
    'revenue_Stamp',
    'miscellaneous'

  ];
  date: any;
  modifiedCurrentDate: any;
  allPaymentSummary: any;
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");

  selectedView: any;

  allCalendarView: calendarView[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Weekly", viewValue: "Weekly" },
    { value: "Monthly", viewValue: "Monthly" },
  ];

  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  isButtonVisible = false;
  first_day_of_month: any;
  last_day_of_month: any;
  currentDate = new Date();
  firstDay: Date;
  lastDay: Date;

  events: string[] = [];
  events1: string[] = [];
  datepick = true;
  monthselectedValue: any;
  public selectedDate = new UntypedFormControl(new Date());
  public months: Month[];
  public selectedMonth: Month;
  isMonthly: boolean;
  isDay: boolean;
  isWeekly: boolean;
  showTable: boolean;
  getAllNatureOfPament: any;
  totalAmnt = 0;
  totalInvest = 0;
  totalEpcg = 0;
  totalAA = 0;
  totalReturn = 0;
  totalComm = 0;
  totalCestat = 0;
  totalHighCourt = 0;
  totalSupremeCourt = 0;
  totalOther = 0;
  totalManual = 0;
  totalUnaccompained = 0;
  totalBillOfEntry = 0;
  totalShippingBill = 0;
  totalSeizedGoods = 0;
  totalConfiscated = 0;
  totalUclaimed = 0;
  totaluncleared = 0;
  totalBusiness = 0;
  totalImports =0;
  totalGifts = 0;
  totalRevenue=0;
  totalMiscellaneous=0;
  dataSource1: any; dataSource2: any; dataSource3: any; dataSource4: any; dataSource5: any; dataSource6: any;
  dataSource7: any; dataSource8: any; dataSource9: any; dataSource10: any; dataSource11: any; dataSource12: any;
  dataSource13: any; dataSource14: any; dataSource15: any; dataSource16: any; dataSource17: any; dataSource18: any;
  dataSource19: any; dataSource20: any; dataSource21: any; dataSource22: any; dataSource23: any; dataSource24: any;
  dataSource25: any; dataSource26: any; dataSource27: any; dataSource28: any; dataSource29: any; dataSource30: any;
  dataSource31: any; dataSource32: any; dataSource33: any; dataSource34: any; dataSource35: any; dataSource36: any;
  dataSource37: any; dataSource38: any; dataSource39: any; dataSource40: any; dataSource41: any; dataSource42: any;


  constructor(private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService, private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = this.currentUser.obj.user_type_id;
    // this.getAllNatureOfPayments();
    this.loadMonths();
  }

  getPaymentDailySummaryMis(input) {
    this.allPaymentSummary = [];
    
    this.blockchainService.getAllPaymentAgainstCategory(input).subscribe(resp => {
      this.allPaymentSummary = resp.body.obj;
      this.dataSource = [];
      this.totalAmnt = 0;
      this.totalInvest = 0;
      this.totalEpcg = 0;
      this.totalAA = 0;
      this.totalReturn = 0;
      this.totalComm = 0;
      this.totalCestat = 0;
      this.totalHighCourt = 0;
      this.totalSupremeCourt = 0;
      this.totalOther = 0;
      this.totalManual = 0;
      this.totalUnaccompained = 0;
      this.totalBillOfEntry = 0;
      this.totalShippingBill = 0;
      this.totalSeizedGoods = 0;
      this.totalConfiscated = 0;
      this.totalUclaimed = 0;
      this.totaluncleared = 0;
      this.totalBusiness = 0;
      this.totalImports =0;
      this.totalGifts = 0;
      this.totalRevenue=0;
      this.totalMiscellaneous=0;
      // this.dataSource = this.allPaymentSummary;
      
      this.allPaymentSummary.forEach(e1 => {
        if (e1.nature_of_payment == 'Basic Customs Duty (BCD)') {
          this.dataSource1 = [
            {
              nature_of_payment: 'Basic Customs Duty (BCD)',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource1);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'IGST') {
          this.dataSource2 = [
            {
              nature_of_payment: 'IGST',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource2);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Counterveiling Duty (CVD)') {
          this.dataSource3 = [
            {
              nature_of_payment: 'Counterveiling Duty (CVD)',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource3);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Antidumping Duty') {
          this.dataSource4 = [
            {
              nature_of_payment: 'Antidumping Duty',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource4);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Safeguard Duty') {
          this.dataSource5 = [
            {
              nature_of_payment: 'Safeguard Duty',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource5);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'National Calamity Contingent Duty (NCCD)') {
          this.dataSource6 = [
            {
              nature_of_payment: 'National Calamity Contingent Duty (NCCD)',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource6);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Agriculture Infrastructure & Social Welfare Surcharge (SWS)') {
          this.dataSource7 = [
            {
              nature_of_payment: 'Agriculture Infrastructure & Social Welfare Surcharge (SWS)',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource7);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Compensation CESS') {
          this.dataSource8 = [
            {
              nature_of_payment: 'Compensation CESS',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource8);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Fine Import') {
          this.dataSource9 = [
            {
              nature_of_payment: 'Fine Import',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource9);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Penalty Import') {
          this.dataSource10 = [
            {
              nature_of_payment: 'Penalty Import',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource10);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Interest Import') {
          this.dataSource11 = [
            {
              nature_of_payment: 'Interest Import',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource11);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Others (Imports)') {
          this.dataSource12 = [
            {
              nature_of_payment: 'Others (Imports)',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: e1.against_EPCG_license,
              against_DEEC_or_advance_authorization_license: e1.against_DEEC_or_advance_authorization_license,
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: e1.manual_Baggage,
              unaccompained_Baggage: e1.unaccompained_Baggage,
              bill_Of_Entry_through_Manual_Baggage: e1.bill_Of_Entry_through_Manual_Baggage,
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: e1.seized_Goods,
              confiscated_Goods: e1.confiscated_Goods,
              uclaimed_Goods_or_Cargo: e1.uclaimed_Goods_or_Cargo,
              uncleared_Goods: e1.uncleared_Goods,
              business_to_Business: e1.business_to_Business,
              personal_imports: e1.personal_imports,
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource12);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Drawback') {
          this.dataSource13 = [
            {
              nature_of_payment: 'Drawback',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: e1.return_of_Export_incentive,
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: e1.shipping_Bill_through_Manual_Baggage,
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource13);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'MEIS') {
          this.dataSource14 = [
            {
              nature_of_payment: 'MEIS',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: e1.return_of_Export_incentive,
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: e1.shipping_Bill_through_Manual_Baggage,
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource14);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'RoDTEP') {
          this.dataSource15 = [
            {
              nature_of_payment: 'RoDTEP',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: e1.return_of_Export_incentive,
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: e1.shipping_Bill_through_Manual_Baggage,
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource15);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'RoSTEL') {
          this.dataSource16 = [
            {
              nature_of_payment: 'RoSTEL',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: e1.return_of_Export_incentive,
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: e1.shipping_Bill_through_Manual_Baggage,
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource16);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Interest Export') {
          this.dataSource17 = [
            {
              nature_of_payment: 'Interest Export',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: e1.return_of_Export_incentive,
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: e1.shipping_Bill_through_Manual_Baggage,
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource17);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Fine Export') {
          this.dataSource18 = [
            {
              nature_of_payment: 'Fine Export',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: e1.return_of_Export_incentive,
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: e1.shipping_Bill_through_Manual_Baggage,
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource18);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Penalty Export') {
          this.dataSource19 = [
            {
              nature_of_payment: 'Penalty Export',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: e1.return_of_Export_incentive,
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: e1.shipping_Bill_through_Manual_Baggage,
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource19);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Export Duty') {
          this.dataSource20 = [
            {
              nature_of_payment: 'Export Duty',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: e1.return_of_Export_incentive,
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: e1.shipping_Bill_through_Manual_Baggage,
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource20);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Export CESS') {
          this.dataSource21 = [
            {
              nature_of_payment: 'Export CESS',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: e1.return_of_Export_incentive,
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: e1.shipping_Bill_through_Manual_Baggage,
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource21);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Others (Exports)') {
          this.dataSource22 = [
            {
              nature_of_payment: 'Others (Exports)',
              account_of_audit: e1.account_of_audit,
              account_of_insvestigation: e1.account_of_insvestigation,
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: e1.return_of_Export_incentive,
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: e1.shipping_Bill_through_Manual_Baggage,
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: e1.gifts,
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource22);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Pre-deposit') {
          this.dataSource23 = [
            {
              nature_of_payment: 'Pre-deposit',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal:e1.commissioner_appeal,
              cestat: e1.cestat,
              other: e1.other,
              high_Court: e1.high_Court,
              supreme_Court: e1.supreme_Court,
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource23);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Final Payment') {
          this.dataSource24 = [
            {
              nature_of_payment: 'Final Payment',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal:e1.commissioner_appeal,
              cestat: e1.cestat,
              other: e1.other,
              high_Court: e1.high_Court,
              supreme_Court: e1.supreme_Court,
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: 'N/A',
            },
          ]
          this.dataSource.push(...this.dataSource24);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Other Duties') {
          this.dataSource25 = [
            {
              nature_of_payment: 'Other Duties',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource25);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Other Interest') {
          this.dataSource26 = [
            {
              nature_of_payment: 'Other Interest',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource26);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Redemption Fine') {
          this.dataSource27 = [
            {
              nature_of_payment: 'Redemption Fine',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource27);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Other Penalty') {
          this.dataSource28 = [
            {
              nature_of_payment: 'Other Penalty',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource28);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Other Fine') {
          this.dataSource29 = [
            {
              nature_of_payment: 'Other Fine',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource29);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Merchant Overtime Charges') {
          this.dataSource30 = [
            {
              nature_of_payment: 'Merchant Overtime Charges',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource30);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Amendment Fees') {
          this.dataSource31 = [
            {
              nature_of_payment: 'Amendment Fees',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource31);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'EOD Deposits') {
          this.dataSource32 = [
            {
              nature_of_payment: 'EOD Deposits',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource32);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Cost Recovery Charges') {
          this.dataSource33 = [
            {
              nature_of_payment: 'Cost Recovery Charges',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource33);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Trans Shipment Fees') {
          this.dataSource34 = [
            {
              nature_of_payment: 'Trans Shipment Fees',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource34);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Levy Fees') {
          this.dataSource35 = [
            {
              nature_of_payment: 'Levy Fees',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource35);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Bottle Seal Fees') {
          this.dataSource36 = [
            {
              nature_of_payment: 'Bottle Seal Fees',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource36);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Cancellation Charges') {
          this.dataSource37 = [
            {
              nature_of_payment: 'Cancellation Charges',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource37);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Book Reprint') {
          this.dataSource38 = [
            {
              nature_of_payment: 'Book Reprint',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource38);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Back to Town Fees') {
          this.dataSource39 = [
            {
              nature_of_payment: 'Back to Town Fees',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource39);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'RTI Fees') {
          this.dataSource40 = [
            {
              nature_of_payment: 'RTI Fees',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource40);
          //console.log(this.dataSource);
        }
        if (e1.nature_of_payment == 'Other <Enter>') {
          this.dataSource41 = [
            {
              nature_of_payment: 'Other <Enter>',
              account_of_audit: 'N/A',
              account_of_insvestigation: 'N/A',
              against_EPCG_license: 'N/A',
              against_DEEC_or_advance_authorization_license: 'N/A',
              return_of_Export_incentive: 'N/A',
              commissioner_appeal: 'N/A',
              cestat: 'N/A',
              other: 'N/A',
              high_Court: 'N/A',
              supreme_Court: 'N/A',
              manual_Baggage: 'N/A',
              unaccompained_Baggage: 'N/A',
              bill_Of_Entry_through_Manual_Baggage: 'N/A',
              shipping_Bill_through_Manual_Baggage: 'N/A',
              seized_Goods: 'N/A',
              confiscated_Goods: 'N/A',
              uclaimed_Goods_or_Cargo: 'N/A',
              uncleared_Goods: 'N/A',
              business_to_Business: 'N/A',
              personal_imports: 'N/A',
              gifts: 'N/A',
              revenue_Stamp: 'N/A',
              miscellaneous: e1.miscellaneous,
            },
          ]
          this.dataSource.push(...this.dataSource41);
          //console.log(this.dataSource);
        }

        if (e1.nature_of_payment == 'Revenue Stamp') {
          this.dataSource42 = [{
            nature_of_payment: 'Revenue Stamp',
            account_of_audit: 'N/A',
            account_of_insvestigation: 'N/A',
            against_EPCG_license: 'N/A',
            against_DEEC_or_advance_authorization_license: 'N/A',
            return_of_Export_incentive: 'N/A',
            commissioner_appeal: 'N/A',
            cestat: 'N/A',
            other: 'N/A',
            high_Court: 'N/A',
            supreme_Court: 'N/A',
            manual_Baggage: 'N/A',
            unaccompained_Baggage: 'N/A',
            bill_Of_Entry_through_Manual_Baggage: 'N/A',
            shipping_Bill_through_Manual_Baggage: 'N/A',
            seized_Goods: 'N/A',
            confiscated_Goods: 'N/A',
            uclaimed_Goods_or_Cargo: 'N/A',
            uncleared_Goods: 'N/A',
            business_to_Business: 'N/A',
            personal_imports: 'N/A',
            gifts: 'N/A',
            revenue_Stamp: e1.revenue_Stamp,
            miscellaneous: 'N/A',
          },
          ]
          this.dataSource.push(...this.dataSource42);
        }
      })
      //console.log(this.dataSource)

      this.dataSource.forEach(e1 => {
       if(e1.account_of_audit !='N/A'){
        e1.account_of_audit =+ e1.account_of_audit;
        this.totalAmnt += e1.account_of_audit;
       }
       if( e1.account_of_insvestigation != 'N/A'){
        e1.account_of_insvestigation =+ e1.account_of_insvestigation;
        this.totalInvest += e1.account_of_insvestigation;
       }
       if(e1.against_EPCG_license != 'N/A'){
        e1.against_EPCG_license =+ e1.against_EPCG_license;
        this.totalEpcg += e1.against_EPCG_license;
       }
       if(e1.against_DEEC_or_advance_authorization_license != 'N/A'){
        e1.against_DEEC_or_advance_authorization_license =+ e1.against_DEEC_or_advance_authorization_license;
        this.totalAA += e1.against_DEEC_or_advance_authorization_license;
       }
       if(e1.return_of_Export_incentive != 'N/A'){
        e1.return_of_Export_incentive =+ e1.return_of_Export_incentive;
        this.totalReturn += e1.return_of_Export_incentive;
       }
       if(e1.commissioner_appeal!= 'N/A'){
        e1.commissioner_appeal =+ e1.commissioner_appeal;
        this.totalComm += e1.commissioner_appeal;
       }
       if(e1.cestat!= 'N/A'){
        e1.cestat =+ e1.cestat;
        this.totalCestat += e1.cestat;
       }
       if(e1.other != 'N/A'){
        e1.other =+ e1.other;
        this.totalOther += e1.other;
       }
       if(e1.high_Court!= 'N/A'){
        e1.high_Court =+ e1.high_Court;
        this.totalHighCourt += e1.high_Court;
       }
       if(e1.supreme_Court!= 'N/A'){
        e1.supreme_Court =+ e1.supreme_Court;
        this.totalSupremeCourt += e1.supreme_Court;
       }
       if(e1.manual_Baggage != 'N/A'){
        e1.manual_Baggage =+ e1.manual_Baggage;
        this.totalManual += e1.manual_Baggage;
       }
       if(e1.unaccompained_Baggage != 'N/A'){
        e1.unaccompained_Baggage =+ e1.unaccompained_Baggage;
        this.totalUnaccompained += e1.unaccompained_Baggage;
       }
       if(e1.bill_Of_Entry_through_Manual_Baggage  !='N/A'){
        e1.bill_Of_Entry_through_Manual_Baggage =+ e1.bill_Of_Entry_through_Manual_Baggage;
        this.totalBillOfEntry += e1.bill_Of_Entry_through_Manual_Baggage;
       }
       if(e1.shipping_Bill_through_Manual_Baggage !='N/A'){
        e1.shipping_Bill_through_Manual_Baggage =+ e1.shipping_Bill_through_Manual_Baggage;
        this.totalShippingBill += e1.shipping_Bill_through_Manual_Baggage;     
       }
        if(e1.seized_Goods != 'N/A'){
          e1.seized_Goods =+ e1.seized_Goods;
          this.totalSeizedGoods += e1.seized_Goods;
        }
        if( e1.confiscated_Goods!= 'N/A' ){
          e1.confiscated_Goods =+ e1.confiscated_Goods;
          this.totalConfiscated += e1.confiscated_Goods;
        }
        if(e1.uclaimed_Goods_or_Cargo != 'N/A'){
          e1.uclaimed_Goods_or_Cargo =+ e1.uclaimed_Goods_or_Cargo;
          this.totalUclaimed += e1.uclaimed_Goods_or_Cargo;
        }
        if(e1.uncleared_Goods != 'N/A'){
          e1.uncleared_Goods =+ e1.uncleared_Goods;
          this.totaluncleared += e1.uncleared_Goods;
        }
        if(e1.business_to_Business != 'N/A'){
          e1.business_to_Business =+ e1.business_to_Business;
          this.totalBusiness += e1.business_to_Business;
        }
        if(e1.personal_imports != 'N/A'){
          e1.personal_imports =+ e1.personal_imports;
          this.totalImports +=e1.personal_imports;
        }
        if(e1.gifts!='N/A'){
          e1.gifts =+ e1.gifts;
          this.totalGifts +=e1.gifts;
        }
        if( e1.revenue_Stamp != 'N/A'){
          e1.revenue_Stamp =+ e1.revenue_Stamp;
          this.totalRevenue +=e1.revenue_Stamp;
        }
        if(e1.miscellaneous != 'N/A'){
          e1.miscellaneous =+ e1.miscellaneous;
          this.totalMiscellaneous+=e1.miscellaneous;
        }

       
      })


      console.log(this.totalAmnt);

      this.dataSource.push({ nature_of_payment: 'Total', account_of_audit: this.totalAmnt, account_of_insvestigation: this.totalInvest, against_EPCG_license: this.totalEpcg, against_DEEC_or_advance_authorization_license: this.totalAA, return_of_Export_incentive: this.totalReturn, commissioner_appeal: this.totalComm, cestat: this.totalCestat, high_Court: this.totalHighCourt, supreme_Court: this.totalSupremeCourt, other: this.totalOther, manual_Baggage: this.totalManual, unaccompained_Baggage: this.totalUclaimed, bill_Of_Entry_through_Manual_Baggage: this.totalBillOfEntry, shipping_Bill_through_Manual_Baggage: this.totalShippingBill, seized_Goods: this.totalSeizedGoods, confiscated_Goods: this.totalConfiscated, uclaimed_Goods_or_Cargo: this.totalUclaimed, uncleared_Goods: this.totaluncleared , business_to_Business:this.totalBusiness,personal_imports:this.totalImports,gifts:this.totalGifts,revenue_Stamp:this.totalRevenue,miscellaneous:this.totalMiscellaneous})

    })
  }

  caseDetailsDialogue(data) {
    localStorage.setItem('casedetailsData', JSON.stringify(data));
  }

  isGroup(index, item): boolean {
    return item.isGroupBy;
  }
  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    if (type == 'input') {
      this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
      let input = {
        start_date: this.date ? this.date : this.todayDate,
        end_date: this.date ? this.date : this.todayDate
      }
      this.showTable = true;
      // this.getAllNatureOfPayments();
      this.getPaymentDailySummaryMis(input);
    }
    else {
      this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
      let input = {
        start_date: this.date ? this.date : this.todayDate,
        end_date: this.date ? this.date : this.todayDate
      }
      this.showTable = true;
      // this.getAllNatureOfPayments();
      this.getPaymentDailySummaryMis(input);
    }
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  loadMonths() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months = [];
    for (let i = 0; i < 12; i++) {

      //console.log("MOnth : ", month);
      if (month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      let name = this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = { "name": name, "value": month + 1, "start": start + " 00:00:00", "end": end + " 23:59:59" };
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }

  }

  monthly(value, event) {
    if (event.isUserInput) {
      this.showTable = true;
      this.selectedMonth = value ? value : this.selectedMonth;
      let input = {
        start_date: this.pipe.transform(this.selectedMonth.start, "yyyy-MM-dd", "en-US"),
        end_date: this.pipe.transform(this.selectedMonth.end, "yyyy-MM-dd", "en-US"),
      }
      // this.getAllNatureOfPayments();
      this.getPaymentDailySummaryMis(input);

    }
  }

  selectCalendarView(event, value) {
    if (event.isUserInput == true) {
      if (value == "Monthly") {
        this.isMonthly = true;
        this.isDay = false;
        this.isWeekly = false;
        this.showTable = false;
      } else if (value == "Weekly") {
        this.isWeekly = true;
        this.isDay = false;
        this.isMonthly = false;
        this.showTable = false;
      } else {
        this.isDay = true;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  compareMonths(i1, i2) {
    // return i2 && i1 && i1.value === i2.value;
    return i1 && i2 && i1.value === i2.value;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    // //console.log(this.isMonthly);
    if (this.isMonthly) {
      if (type === "change") {
        this.showTable = true;

        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        //const start =
        let date = new Date(this.today);
        //date = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        this.first_day_of_month = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        let currentMonth = date.getMonth();
        this.last_day_of_month = new Date(
          date.getFullYear(),
          currentMonth,
          this.daysInMonth(currentMonth + 1, date.getFullYear())
        );
        let input = {
          start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
          end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        }
        // this.getAllNatureOfPayments();
        this.getPaymentDailySummaryMis(input);

      }
    } else {
      if (type === "input") {
        this.showTable = true;
        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        const start = new Date(this.today + 6 * 24 * 60 * 60 * 1000).getTime();
        const myStartDate = this.pipe.transform(start, "yyyy-MM-dd", "en-US");
        this.last6 = start;
        let input = {
          end_date : this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
          start_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        }
        // this.getAllNatureOfPayments();
        this.getPaymentDailySummaryMis(input);

        if (this.today == this.now) {
          this.isButtonVisible = false;
        } else {

          this.isButtonVisible = true;
        }
      }
    }
  }

  reportGeneration() {
    var displayedCol = []
    displayedCol.push('Nature Of Payment');
    displayedCol.push('Audit');
    displayedCol.push('Investigation');
    displayedCol.push('EPCG');
    displayedCol.push('AA');
    displayedCol.push('Return Of Export Incentive');
    displayedCol.push('Commissioner Appeal');
    displayedCol.push('CESTAT');
    displayedCol.push('High Court');
    displayedCol.push('Supreme Court');
    displayedCol.push('Other');
    displayedCol.push('Manual');
    displayedCol.push('Unaccompanied');
    displayedCol.push('Bill Of Entry');
    displayedCol.push('Shipping Bill');
    displayedCol.push('Seized');
    displayedCol.push('Confiscated');
    displayedCol.push('Unclaimed');
    displayedCol.push('Uncleared');
    displayedCol.push('Business');
    displayedCol.push('Personal Imports');
    displayedCol.push('Gifts');
    displayedCol.push('Revenue Stamp');
    displayedCol.push('Miscellaneous');
   



    var tableObj = this.dataSource;
    // this.allPaymentSummary.push({nature_of_payment:'Total',account_of_audit:this.totalAmnt,account_of_insvestigation:this.totalInvest,against_EPCG_license:this.totalEpcg,against_DEEC_or_advance_authorization_license:this.totalAA,return_of_Export_incentive:this.totalReturn,commissioner_appeal:this.totalComm,cestat:this.totalCestat,high_Court:this.totalHighCourt,supreme_Court:this.totalSupremeCourt,other:this.totalOther,manual_Baggage:this.totalManual,unaccompained_Baggage:this.totalUclaimed,bill_Of_Entry_through_Manual_Baggage:this.totalBillOfEntry,shipping_Bill_through_Manual_Baggage:this.totalShippingBill,seized_Goods:this.totalSeizedGoods,confiscated_Goods:this.totalConfiscated,uclaimed_Goods_or_Cargo:this.totalUclaimed,uncleared_Goods:this.totaluncleared})
    //console.log(tableObj, 'tableobj')
    tableObj.forEach(element => {
      element['Nature Of Payment'] = element.nature_of_payment;
      element['Audit'] = element.account_of_audit;
      element['Investigation'] = element.account_of_insvestigation;
      element['EPCG'] = element.against_EPCG_license;
      element['AA'] = element.against_DEEC_or_advance_authorization_license;
      element['Return Of Export Incentive'] = element.return_of_Export_incentive;
      element['Commissioner Appeal'] = element.commissioner_appeal;
      element['CESTAT'] = element.cestat;
      element['High Court'] = element.high_Court;
      element['Supreme Court'] = element.supreme_Court;
      element['Other'] = element.other;
      element['Manual'] = element.manual_Baggage;
      element['Unaccompanied'] = element.unaccompained_Baggage;
      element['Bill Of Entry'] = element.bill_Of_Entry_through_Manual_Baggage;
      element['Shipping Bill'] = element.shipping_Bill_through_Manual_Baggage;
      element['Seized'] = element.seized_Goods;
      element['Confiscated'] = element.confiscated_Goods;
      element['Unclaimed'] = element.uclaimed_Goods_or_Cargo;
      element['Uncleared'] = element.uncleared_Goods;
      element['Business'] = element.business_to_Business;
      element['Personal Imports'] = element.personal_imports;
      element['Gifts'] = element.gifts;
      element['Revenue Stamp'] = element.revenue_Stamp;
      element['Miscellaneous'] = element.miscellaneous;

    });
    this.downloadFile(
      tableObj,
      'Payment Received Detailed Summary Report' + ' ' + this.todayDate,
      displayedCol
    );
  }

  downloadFile(data, filename = "data", headers) {
    let csvData = this.ConvertToCSV(data, headers);
    //console.log(csvData);
    let blob = new Blob(["\ufeff" + csvData], {
      type: "text/csv;charset=utf-8;",
    });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  // getAllNatureOfPayments() {
  //   let input = {}
  //   this.blockchainService.getAllPaymentAgainstCategory(input).subscribe((resp) => {
  //     this.getAllNatureOfPament = resp.body.obj;
  //   });
  // }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "Sr.No,";

    for (let index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];

        line += "," + array[i][head];
      }
      str += line + "\r\n";
    }
    return str;
  }
}
