import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { AlertService } from "app/_services";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

@Component({
  selector: "app-add-project-site",
  templateUrl: "./add-project-site.component.html",
  styleUrls: ["./add-project-site.component.css"],
})
export class AddProjectSiteComponent implements OnInit {
  pipe = new DatePipe("en-US");

  @BlockUI() blockUI: NgBlockUI | any;
  blockTemplate: BlockTemplateComponent | any;
  role: any;
  authorizationDate: any;
  impDate: any;
  expDate: any;

  addProjectSiteAAForm = this.formBuilder.group({
    //sr_no:[""],
    name: ["",Validators.required],
    address: ["",Validators.required],
    gstn: ["",Validators.required],
    industrialRegistration: ["",Validators.required],
    exciseAddress: ["",Validators.required],
    type: ["",Validators.required],
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean | any;
  date: any;
  modifiedCurrentDate: string | any;
  lastDate: any;
  isaaForm2Enable: boolean = false;
  isUpdate: boolean | any;
  updateData: any;
  flag: boolean | any;
  item_id: any;
  applicationType: string | any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    private alertService: AlertService,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    let localstorageCurrentUser : any = localStorage.getItem("currentUser")
    this.currentUser = JSON.parse(localstorageCurrentUser);
    this.applicationType = localStorage.getItem('applicationType')
    if (this.route.snapshot.params["update"] == "true") {
      this.isUpdate = true;
      this.updateData = this.route.snapshot.params;
      this.addProjectSiteAAForm.patchValue({
        //sr_no:this.updateData.serial_no,
        name: this.updateData.name,
        address: this.updateData.address,
        gstn: this.updateData.gst_registration,
        industrialRegistration: this.updateData.industrial_registration,
        exciseAddress:
          this.updateData.address_jurisdictional_central_execise_authority,
        type: this.updateData.type,
      });
    }
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date: string | number | Date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  back() {
    this.router.navigate(["/supportingProjectAA"]);
  }

  next() {
    this.saveOrUpdate();
    this.router.navigate(["/supportingProjectAA"]);
  }

  save(){
    this.saveOrUpdate();
  }

  get f() {
    return this.addProjectSiteAAForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.addProjectSiteAAForm.controls[controlName].hasError(errorName);
  };

  saveOrUpdate() {
    this.blockUI.start("Please wait this may take few seconds ...");
    if( this.item_id == undefined  &&
      (this.updateData == undefined  || this.updateData.manufacturer_id == undefined ||
      this.updateData.manufacturer_id == null)
    ) {
      let input = {
      
        //serial_no:this.addProjectSiteAAForm.value.sr_no,
        name: this.addProjectSiteAAForm.value.name,
        address: this.addProjectSiteAAForm.value.address,
        gst_registration: this.addProjectSiteAAForm.value.gstn,
        industrial_registration:
          this.addProjectSiteAAForm.value.industrialRegistration,
        address_jurisdictional_central_execise_authority:
          this.addProjectSiteAAForm.value.exciseAddress,
        type: this.addProjectSiteAAForm.value.type ?  this.addProjectSiteAAForm.value.type : '',
        application_id: localStorage.getItem("application_id"),
      };
      console.log('input',input)
      this.blockchainService.saveManufacturer(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.router.navigate(['/supportingProjectAA'])
          this.item_id = resp.body.obj;
        
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.flag = true;
        } else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          // location.reload();
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
          // location.reload();
        }
        else{}
      });
    } else {
      let input = {
        //serial_no:this.addProjectSiteAAForm.value.sr_no,
        manufacturer_id: this.item_id ? this.item_id : this.updateData.manufacturer_id,
        name: this.addProjectSiteAAForm.value.name,
        address: this.addProjectSiteAAForm.value.address,
        gst_registration: this.addProjectSiteAAForm.value.gstn,
        industrial_registration:
          this.addProjectSiteAAForm.value.industrialRegistration,
        address_jurisdictional_central_execise_authority:
          this.addProjectSiteAAForm.value.exciseAddress,
        type: this.addProjectSiteAAForm.value.type,
        application_id: localStorage.getItem("application_id"),
      };
      this.blockchainService.updateManufacturer(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.router.navigate(['/supportingProjectAA'])
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.flag = true;
        }  else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      });
    }
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef:any;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result: { event: string; isUpdated: boolean; }) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from: string, align: string, color: any, stringMessage: string, icons: string) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
