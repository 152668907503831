import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

@Component({
  selector: 'app-amendment-aa',
  templateUrl: './amendment-aa.component.html',
  styleUrls: ['./amendment-aa.component.css']
})
export class AmendmentAaComponent implements OnInit {

  selectedStatus = "";
  pipe = new DatePipe("en-US");

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  aaDate: any;
  aaIsueDate: any;
  exportDate:any;
  importDate:any;

  // officerForm  = this.formBuilder.group({
  //   iecNo: [""],
  //   aaNo:[""],
  //   aaDate:[""]
   
  //  });

  amendmentAAForm = this.formBuilder.group({
    names: [""],
    sections: [""],
    designations: [""]
  
  });

  caseForm = this.formBuilder.group({
    iecName: [""],
    iecCode:[""],
    aaIsueDate:[""]
   
   });

  amendmentCaseForm = this.formBuilder.group({

    importDate: [""],
    exportDate:[""],

  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  modifiedCurrentDate: any;
  date: any;
  selectedFile: File;
  url: string | ArrayBuffer;
  showForm = false;

 
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  }

  cancel() {
    this.router.navigate(["/applnSummary"]);
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log("Event", event);
    // this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    console.log("First Date=" + this.date);
    this.modifiedCurrentDate = this.date;
    console.log("helllooo", this.modifiedCurrentDate);
    this.showForm = true;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  // next() {
  //   this.router.navigate(["/export-ou"]);
  // }

  submit(){

  }

  back(){
     this.router.navigate(["/amendment"]);
  }

  save(){
    localStorage.setItem('saveFile',JSON.stringify(this.selectedFile));
    let input = {
      // aa_file:this.selectedFile,
      // iec_no:'abcd-efgh-ijkl-mnop',
      // application_type_id:'1',
      // application_type_name:'AA',
      // user_roles:'3',
    }
    console.log(input,'input')
    this.blockchainService.saveApplication(input).subscribe(resp => {

    })
    
  }
  statusFn(e, status) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}

