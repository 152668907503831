import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService } from "app/_services";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;


@Component({
  selector: "app-add-import-ou",
  templateUrl: "./add-import-ou.component.html",
  styleUrls: ["./add-import-ou.component.css"],
})
export class AddImportOUComponent implements OnInit {
  pipe = new DatePipe("en-US");

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  boeDate: any;

  boeForm = this.formBuilder.group({
    authNo: ["",Validators.required],
    boeNo: ["",Validators.required],
    boeDoc:[""],
    boeDate: ["",Validators.required],
    productQty: ["",Validators.required],
    cifValue: ["",Validators.required],
    // productDescr: ["",Validators.required],
    // itchsCode: [""],
    portImport: ["",Validators.required],
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  modifiedCurrentDate: any;
  date: any;
  selectedFile: File;
  url: string | ArrayBuffer;
  isAdd: boolean;
  allAuth: any;
  selectedAuthNo: any;
  isUpdate: boolean;
  updateData: any;
  boeDoc: any;
  applicationType: string;
  selectedFileUrl1: boolean=false;
  selectedFileUrl: boolean=false;
  sectionViewLetterDetails: any;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationType = localStorage.getItem('applicationType')
    if (this.route.snapshot.params["update"] == "true") {
      this.isUpdate = true;
      this.updateData = this.route.snapshot.params;
      console.log(this.updateData);
      this.boeDoc = this.updateData.boe_document_path,
      this.selectedAuthNo = this.updateData.auth_no,
      this.boeForm.patchValue({
    authNo:this.updateData.auth_no,
    boeNo: this.updateData.boe_no,
    boeDoc:this.updateData.boe_document_path,
    boeDate:  this.pipe.transform(this.updateData.boe_date, "yyyy-MM-dd"),
    productQty: this.updateData.product_qty,
    cifValue: this.updateData.cif_value_in_inr,
    portImport: this.updateData.port_of_import_code,

   
      });
    }

    this.getAllAuthNumber();
  }

  get f() {
    return this.boeForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.boeForm.controls[controlName].hasError(errorName);
  };

  authFn(e) {
    console.log(e);
    this.selectedAuthNo = e;
  }

  addorUpdate(e) {
    if(this.selectedFile != null && this.selectedFile != undefined){
    let input = {
      application_id: 0,
      auth_no: this.selectedAuthNo,
      // bill_of_entry_import_id: "",
      boe_date: this.pipe.transform(this.boeForm.value.boeDate,"yyyy-MM-dd")+" " + "00:00:00",
      boe_document_file: this.selectedFile,
      boe_no: this.boeForm.value.boeNo,
      cif_value_in_inr: this.boeForm.value.cifValue,
      port_of_import_code: this.boeForm.value.portImport,
      product_qty: this.boeForm.value.productQty,
    };
    console.log(input,'save func')
    if(!this.isUpdate){
     
      this.saveBillOfEntryImport(input);
    }
   
    else{
      let input = {
        application_id: 0,
        auth_no: this.selectedAuthNo,
        bill_of_entry_import_id: this.updateData.bill_of_entry_import_id,
        boe_date: this.pipe.transform(this.boeForm.value.boeDate,"yyyy-MM-dd")+" " + "00:00:00",
        boe_document_file: this.selectedFile != undefined ? this.selectedFile : this.boeDoc,
        boe_no: this.boeForm.value.boeNo,
        cif_value_in_inr: this.boeForm.value.cifValue,
        port_of_import_code: this.boeForm.value.portImport,
        product_qty: this.boeForm.value.productQty,
      };
      this.updateBillOfEntryImport(input);
    }
  }
  else{
    this.showNotification(
      "bottom",
      "right",
      "success",
      'Please attach a document',
      "announcement"
    );
  }
    console.log(e, "addform");
    
  }

  getAllAuthNumber() {
    this.blockchainService.getAllAuthNumber().subscribe((resp) => {
      this.allAuth = resp.body.obj;
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  saveBillOfEntryImport(input) {
    this.blockUI.start("Please wait this may take few seconds ...");

    this.blockchainService.saveBillOfEntryImport(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.router.navigate(["/importOU"]);
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      } else {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }

  updateBillOfEntryImport(input) {
    this.blockUI.start("Please wait this may take few seconds ...");

    this.blockchainService.updateBillOfEntryImport(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.router.navigate(["/importOU"]);
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      } else {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "danger",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        // location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    }
    );
  }



  navigateExport() {
    this.router.navigate(["/exportOU"]);
  }

  navigateImport() {
    this.router.navigate(["/importOU"]);
  }
  onFileSelected(event) {
    console.log("clicked");
    console.log("clicked1", event);
    let $img: any = document.querySelector('#file');
    this.selectedFile = <File>event.target.files[0];
    if(this.selectedFile.type == 'application/pdf'){
      this.selectedFileUrl=true;
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); // read file as data url

    reader.onload = (event) => {
      // called once readAsDataURL is completed
      this.url = event.target.result;
    };
    this.onUpload();
  }
  else{
    this.selectedFileUrl=false;
    event.target.files[0].value='';
    $img.value=''
    this.showNotification(
      "bottom",
      "right",
      "success",
      "File Format Invalid",
      "announcement"
    );
  }
  }
  onFileSelectedUpdate(event) {
    console.log("clicked");
    console.log("clicked1", event);
    let $img: any = document.querySelector('#fileUpdate');
    this.selectedFile = <File>event.target.files[0];
    if(this.selectedFile.type == 'application/pdf'){
      this.selectedFileUrl1=true;
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); // read file as data url

    reader.onload = (event) => {
      // called once readAsDataURL is completed
      this.url = event.target.result;
    };
    this.onUpload();
  }
  else{
    this.selectedFileUrl1=false;
    event.target.files[0].value='';
    $img.value=''
    this.showNotification(
      "bottom",
      "right",
      "success",
      "File Format Invalid",
      "announcement"
    );
  }
  }

  onUpload() {
    const fd = new FormData();
    console.log(this.selectedFile, "selectedFile");
    fd.append("file", this.selectedFile, this.selectedFile.name);
    // this.submitRequest()
  }

  cancel() {
    this.router.navigate(["/applnSummary"]);
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log("Event", event);
    // this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    console.log("First Date=" + this.date);
    this.modifiedCurrentDate = this.date;
    console.log("helllooo", this.modifiedCurrentDate);
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  back() {
    this.router.navigate(["/importOU"]);
  }
  statusFn(e, status) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  openModalViewLetter(section) {
    this.sectionViewLetterDetails = section;
    const buttonModal = document.getElementById("openModalButton");
    // buttonModal.click();
  } 
  
  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
