import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { UntypedFormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { MatTableDataSource } from '@angular/material/table';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService, PaymentAcceptanceQuery } from "app/blockchainImpExp/blockchain.service";
import { Month } from "../payment-daily-summary/month";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { DateAdapter } from "@angular/material/core";
declare var $: any;


export interface PaymentAcceptance {
  systemId: number;
  challan_date: string;
  challan_number: string;
  iec_no: string;
  mobileNo: string;
  challan_path: string;
  payment_master_id: string;
  challanReceiptDate: string;
  challanReceiptNo: string;

}

export interface Status {
  value: string;
  viewValue: string;
}

export interface MonthType {
  value: string;
  viewValue: string;
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



export interface calendarView {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-payment-acceptance',
  templateUrl: './payment-acceptance.component.html',
  styleUrls: ['./payment-acceptance.component.css']
})
export class PaymentAcceptanceComponent implements OnInit {
  selectedStatus = 'New';

  allStatus: Status[] = [
    { value: 'Incoming Date', viewValue: 'Incoming Date' },
    { value: 'IEC Code', viewValue: 'IEC Code' },
  ];


  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  currentUser: any;
  role: any;
  dataSource: PaymentAcceptance[] = [];
  username: string;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  acceptanceData: PaginatedDataSource<PaymentAcceptance, PaymentAcceptanceQuery>;
  initialSort: Sort<PaymentAcceptance> = { property: "systemId", order: "asc" };

  displayedColumns = [
    'no',
    'challanDate',
    'challanNo',
    'iecCode',
    'mobileNo',
    'viewChallanDetails',
    'acceptCashChallan',
    'confirmCashChallan',
    'challanReceiptDate',
    'challanReceiptNo',
    'actionAwaited'
  ];
  selectedFilter: string;
  filterData: any;
  filterByData: Status[] = [
    { value: 'Challan No', viewValue: 'Challan No' },
    { value: 'IEC Code', viewValue: 'IEC Code' },
    { value: 'Mobile No', viewValue: 'Mobile No' },
    { value: 'Challan Receipt No', viewValue: 'Challan Receipt No' },
    { value: 'Challan Date', viewValue: 'Challan Date' },
    { value: 'Challan Recipient Date', viewValue: 'Challan Recipient Date' },
  ]
  confirmationStatus: Status[] = [
    { value: "ACCEPT", viewValue: "ACCEPTANCE PENDING" },
    { value: "ACCEPTED", viewValue: "ACCEPTED" },
    { value: "CONFIRM", viewValue: "CONFIRMATION PENDING" },
    { value: "CONFIRMED", viewValue: "CONFIRMED" },
    { value: "ACCEPTED & CONFIRM", viewValue: "ACCEPTED & CONFIRMATION PENDING" },
    { value: "ACCEPTED & CONFIRMED", viewValue: "ACCEPTED & CONFIRMED" },
  ]
  filterStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];
  licenseStatus: Status[] = [
    { value: "AA", viewValue: "AA" },
    { value: "EPCG", viewValue: "EPCG" },
  ];
  selectedView: any;

  allCalendarView: calendarView[] = [
    { value: "Day", viewValue: "Day" },
    { value: "Weekly", viewValue: "Weekly" },
    { value: "Monthly", viewValue: "Monthly" },
    { value: "Date Range", viewValue: "Date Range" },
  ];
  date: any;
  modifiedCurrentDate: any;
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  now = Date.now();
  today: number = Date.now();
  last6: number = 0;
  isButtonVisible = false;
  first_day_of_month: any;
  last_day_of_month: any;
  currentDate = new Date();
  firstDay: Date;
  lastDay: Date;

  events: string[] = [];
  events1: string[] = [];
  datepick = true;
  monthselectedValue: any;
  public selectedDate = new UntypedFormControl(new Date());
  public selectedDateStart = new UntypedFormControl(new Date());
  public selectedDateEnd = new UntypedFormControl(new Date());
  public months: Month[];
  public selectedMonth: Month;
  isMonthly: boolean;
  isDay: boolean;
  isWeekly: boolean;
  isRange: boolean;
  showTable: boolean;
  sendInput: any;
  dateData: any;
  startDate: any;
  endDate: any;
  totalEntries: any;
  selected: any;
  paletteColour: any = 'red';
  flag1: any;
  indx: any;
  disableSub: boolean;
  offsetData: any;
  pageSet: number;
  accpt: number;
  conf: number;
  pageSizeChange: number;

  constructor(private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('en-GB');
    this.offsetData = JSON.parse(localStorage.getItem("offset"));
    this.pageSet = this.offsetData - 1;
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = this.currentUser.obj.user_type_id;
    this.username = this.currentUser.obj.username;
    this.loadMonths();
    this.selected = 1;

    console.log(this.offsetData);
    if (this.offsetData == null) {
      this.offsetData = 1
    }
    this.pageSizeChange = 10;
    this.getAllPaymentsPagination(this.offsetData, this.selected, this.pageSizeChange,1, 0, 0);
   
  }
  caseDetailsDialogue(data) {
    localStorage.setItem('casedetailsData', JSON.stringify(data));
  }
  acceptRoute(data, action) {
    console.log(data);
    localStorage.setItem('action', action);
    localStorage.setItem('paymentAcceptance', JSON.stringify(data));
    this.router.navigate(['/accept_challan']);
  }

 
  getAllPaymentsPagination(offSetData, index, pageSizeSet,pendingApp, accept?, confirm?) {
   
    if (accept == 0 && confirm == 0) {
      this.reset('00', index);
    }
    if (accept == 1 && confirm == 0) {
      this.reset('10', index);
    }
    if (accept == 1 && confirm == 1) {
      this.reset('11', index);
    }
    if (accept == -1 && confirm == -1) {
      this.reset('-1-1', index);
    }

    console.log(this.pageSizeChange)

    this.paletteColour = 'warn';
    this.selected = index;
    let input = {
      offset: offSetData,
      challan_status: accept,
      challan_confirmation_status: confirm,
      entries_per_page: pageSizeSet,
      pending_app:pendingApp,
     
    }
    this.blockchainService.getAllPaymentsPagination(input).subscribe((resp) => {
      this.dataSource = resp.body.obj.pdr;
      this.totalEntries = resp.body.obj.total_entries;
      if (this.dataSource !== null) {
        var acceptanceList = resp.body.obj.pdr;
        this.acceptanceData = new PaginatedDataSource<PaymentAcceptance, PaymentAcceptanceQuery>(
          (request, query) =>
            this.blockchainService.pagePaymentAcceptance(request, query, acceptanceList),
          this.initialSort,
          { search: "", data: undefined },

        );
      }
    });
  }

  getNext(event: PageEvent) {
    let offset = event.pageIndex;
    this.pageSizeChange = event.pageSize;
    console.log(this.offsetData);
    if ((this.selectedFilter != '') && this.filterData != '') {
      
      this.pageSet = this.offsetData - 1;
      let filter = JSON.parse(localStorage.getItem("filter"));

      if (event.previousPageIndex == 0) {
        localStorage.setItem("offset", JSON.stringify(offset + 1));

        filter.offset = offset + 1;
        filter.entries_per_page = this.pageSizeChange;

        this.getAllFilterPayments(filter);
      }
      else {
        localStorage.setItem("offset", JSON.stringify(offset + 1));
        filter.offset = offset + 1;
        filter.entries_per_page = this.pageSizeChange;
        this.getAllFilterPayments(filter);
      }
    } else {

      if (event.previousPageIndex == 0) {
        if (this.selected == 1) {
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAllPaymentsPagination(offset + 1, this.selected, this.pageSizeChange,1, 0, 0);

        } else if (this.selected == 2) {
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAllPaymentsPagination(offset + 1, this.selected, this.pageSizeChange,1, 1, 0);

        } else if (this.selected == 3) {
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAllPaymentsPagination(offset + 1, this.selected, this.pageSizeChange,1, 1, 1);

        } else if (this.selected == 4) {
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAllPaymentsPagination(offset + 1, this.selected, this.pageSizeChange,1, -1, -1);
        }

      } else {
        if (this.selected == 1) {
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAllPaymentsPagination(offset + 1, this.selected, this.pageSizeChange,1, 0, 0);

        } else if (this.selected == 2) {
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAllPaymentsPagination(offset + 1, this.selected, this.pageSizeChange,1, 1, 0);

        } else if (this.selected == 3) {
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAllPaymentsPagination(offset + 1, this.selected, this.pageSizeChange,1, 1, 1);
        } else if (this.selected == 4) {
          localStorage.setItem("offset", JSON.stringify(offset + 1));
          this.getAllPaymentsPagination(offset + 1, this.selected, this.pageSizeChange,1, -1, -1);
        }
      }
    }
  }
  reset(flg, index) {
    this.flag1 = flg;
    this.pageSet = 0;
    this.offsetData = 1;

    // this.pageSizeChange = 10;
    localStorage.setItem("offset", JSON.stringify(this.offsetData));
    this.indx = index;
    this.selectedFilter = '';
    this.selectedFilter = null;
    this.filterData = '';
    this.isMonthly = false;
    this.isDay = false;
    this.isWeekly = false;
    this.isRange = false;
  }

  resetFilters() {
    this.dataSource = [];
    this.pageSet = 0;
    this.offsetData = 1
    localStorage.setItem("offset", JSON.stringify(this.offsetData));
    
    if (this.flag1 == '00') {
      this.getAllPaymentsPagination(1, this.indx, this.pageSizeChange,1, 0, 0);
    } else if (this.flag1 == '10') {
      this.getAllPaymentsPagination(1, this.indx, this.pageSizeChange,1, 1, 0);
    } else if (this.flag1 == '11') {
      this.getAllPaymentsPagination(1, this.indx, this.pageSizeChange,1, 1, 1);
    } else if (this.flag1 == '-1-1') {
      this.getAllPaymentsPagination(1, this.indx, this.pageSizeChange,1, -1, -1);
    }

    this.selectedFilter = '';
    this.selectedFilter = null;
    this.filterData = '';
    this.isMonthly = false;
    this.isDay = false;
    this.isWeekly = false;
    this.isRange = false;
  }


  onFilterSelected(event, status) {
    this.filterData = '';
    this.isMonthly = false;
    this.isDay = false;
    this.isWeekly = false;
    this.isRange = false;
    if (event.isUserInput) {
      this.selectedFilter = status;
    }
  }

  changeConfirmationStatus(status) {
    this.filterData = status;
  }

  getFilterData(data, filter) {
    this.dataSource = [];
    if (this.flag1 == '00') {
      this.accpt = 0;
      this.conf = 0;
      this.pageSet = 0;
      this.offsetData = 1
      localStorage.setItem("offset", JSON.stringify(this.offsetData));
    } else if (this.flag1 == '10') {
      this.accpt = 1;
      this.conf = 0;
      this.pageSet = 0;
      this.offsetData = 1
      localStorage.setItem("offset", JSON.stringify(this.offsetData));
    } else if (this.flag1 == '11') {
      this.accpt = 1;
      this.conf = 1;
      this.pageSet = 0;
      this.offsetData = 1
      localStorage.setItem("offset", JSON.stringify(this.offsetData));
    } else if (this.flag1 == '-1-1') {
      this.accpt = -1;
      this.conf = -1;
      this.pageSet = 0;
      this.offsetData = 1
      localStorage.setItem("offset", JSON.stringify(this.offsetData));
    }
    this.pageSet = 0;
    this.offsetData = 1
    if (filter == 'Challan No') {
      this.sendInput = {
        challan_number: data,
        offset: this.offsetData,
        challan_status: this.accpt,
        challan_confirmation_status: this.conf,
        entries_per_page: this.pageSizeChange,
        pending_app: 1
      }

    }
    else if (filter == 'IEC Code') {
      this.sendInput = {
        iec_no: data,
        offset: this.offsetData,
        challan_status: this.accpt,
        challan_confirmation_status: this.conf,
        entries_per_page: this.pageSizeChange,
        pending_app: 1
      }

    }
    else if (filter == 'Mobile No') {
      this.sendInput = {
        mobile_no_of_depositor: data,
        offset: this.offsetData,
        challan_status: this.accpt,
        challan_confirmation_status: this.conf,
        entries_per_page: this.pageSizeChange,
        pending_app: 1
      }

    } else if (filter == 'Challan Receipt No') {
      this.sendInput = {
        challan_receipt_no: data,
        offset: this.offsetData,
        challan_status: this.accpt,
        challan_confirmation_status: this.conf,
        entries_per_page: this.pageSizeChange,
        pending_app: 1
      }

    }
    else if (filter == 'Challan Date') {
      this.sendInput = {
        start_date: this.startDate,
        end_date: this.endDate,
        challan_date: this.startDate,
        offset: this.offsetData,
        challan_status: this.accpt,
        challan_confirmation_status: this.conf,
        entries_per_page: this.pageSizeChange,
        pending_app: 1

      }

    } else if (filter == 'Challan Recipient Date') {
      this.sendInput = {
        start_date: this.startDate,
        end_date: this.endDate,
        challan_confirmation_approval_date: this.startDate,
        offset: this.offsetData,
        challan_status: this.accpt,
        challan_confirmation_status: this.conf,
        entries_per_page: this.pageSizeChange,
        pending_app: 1

      }

    }
    
    localStorage.setItem('filter', JSON.stringify(this.sendInput));
    this.getAllFilterPayments(this.sendInput);


  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate,
      this.endDate = this.date ? this.date : this.todayDate
  }
  dateStartSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.startDate = this.date ? this.date : this.todayDate;
    console.log(this.startDate)
  }
  dateEndSelected(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.endDate = this.date ? this.date : this.todayDate;
    console.log(this.endDate)
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  loadMonths() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    this.months = [];
    for (let i = 0; i < 12; i++) {

      // console.log("MOnth : ",month);
      if (month < 0) {
        month = 11;
        year--;
      }
      let firstday = new Date(year, month, 1);
      let lastday = new Date(year, month + 1, 0);
      let name = this.pipe.transform(firstday, "MMMM yy", "en-US");
      let start = this.pipe.transform(firstday, "yyyy-MM-dd", "en-US");
      let end = this.pipe.transform(lastday, "yyyy-MM-dd", "en-US");
      let monthObj = { "name": name, "value": month + 1, "start": start + " 00:00:00", "end": end + " 23:59:59" };
      // this.selectedMonth =  monthObj;
      this.months.push(monthObj);

      month--;
    }

  }

  monthly(value, event) {
    if (event.isUserInput) {
      this.showTable = true;
      this.selectedMonth = value ? value : this.selectedMonth;
      this.startDate = this.pipe.transform(this.selectedMonth.start, "yyyy-MM-dd", "en-US");
      this.endDate = this.pipe.transform(this.selectedMonth.end, "yyyy-MM-dd", "en-US");

    }
  }

  selectCalendarView(event, value) {
    console.log(event)
    if (event.isUserInput == true) {
      if (value == "Monthly") {
        this.isMonthly = true;
        this.isDay = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Weekly") {
        this.isWeekly = true;
        this.isDay = false;
        this.isMonthly = false;
        this.showTable = false;
        this.isRange = false;
      } else if (value == "Day") {
        this.isDay = true;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = false;
      } else {
        this.isDay = false;
        this.isMonthly = false;
        this.isWeekly = false;
        this.showTable = false;
        this.isRange = true;
      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  compareMonths(i1, i2) {
    // return i2 && i1 && i1.value === i2.value;
    return i1 && i2 && i1.value === i2.value;
  }

  addEvent1(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events1.push(`${type}: ${event.value}`);
    if (this.isMonthly) {
      if (type === "change") {
        this.showTable = true;

        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        //const start =
        let date = new Date(this.today);
        //date = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        this.first_day_of_month = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
        );
        let currentMonth = date.getMonth();
        this.last_day_of_month = new Date(
          date.getFullYear(),
          currentMonth,
          this.daysInMonth(currentMonth + 1, date.getFullYear())
        );
        // let input = {
        //   start_date: this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   end_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        this.startDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        this.endDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);
        // this.dateData = input;
      }
    } else {
      if (type === "change") {
        this.showTable = true;
        this.today = event.value.getTime();
        const now = this.today;
        const myFormattedDate = this.pipe.transform(now, "yyyy-MM-dd", "en-US");
        const start = new Date(this.today + 6 * 24 * 60 * 60 * 1000).getTime();
        const myStartDate = this.pipe.transform(start, "yyyy-MM-dd", "en-US");
        this.last6 = start;
        // let input = {
        //   end_date : this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US"),
        //   start_date: this.pipe.transform(this.today, "yyyy-MM-dd", "en-US"),
        // }
        // this.dateData = input;
        this.endDate = this.pipe.transform(this.last6, "yyyy-MM-dd", "en-US");
        this.startDate = this.pipe.transform(this.today, "yyyy-MM-dd", "en-US");
        // this.getPaymentDailySummaryMis(input);

        if (this.today == this.now) {
          this.isButtonVisible = false;
        } else {

          this.isButtonVisible = true;
        }
      }
    }
  }

  getAllFilterPayments(input) {
  
    this.pageSet = 0;
    this.blockchainService.getAllFilterPayments(input).subscribe((resp) => {

      this.dataSource = resp.body.obj.pdr;
      this.totalEntries = resp.body.obj.total_entries;
      if (this.dataSource !== null) {
        var acceptanceList = resp.body.obj.pdr;
        this.acceptanceData = new PaginatedDataSource<PaymentAcceptance, PaymentAcceptanceQuery>(
          (request, query) =>
            this.blockchainService.pagePaymentAcceptance(request, query, acceptanceList),
          this.initialSort,
          { search: "", data: undefined },

        );
      }
    });
  }

}
