import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { is } from "date-fns/locale";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { DateAdapter } from "@angular/material/core";
import { Location } from "@angular/common";
import { SafeHtml } from "@angular/platform-browser";
declare var $: any;
export interface LetterType {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-scn-module",
  templateUrl: "./scn-module.component.html",
  styleUrls: ["./scn-module.component.css"],
})
export class ScnModuleComponent implements OnInit {
  allTypes: LetterType[] = [
    { value: "Non-Submission Letter", viewValue: "Non-Submission Letter" },
    { value: "Demand Letter/Notice", viewValue: "Demand Letter/Notice" },
    { value: "Summons", viewValue: "Summons" },
    { value: "Show Cause Notice", viewValue: "Show Cause Notice" },
  ];
  isLinear = false;
  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  tabIndex = 0;

  draftForm: UntypedFormGroup;
  currentUser: any;
  caseDetailsData: any;
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  date: any;
  htmlContent = "";
  noteDate: any;
  acceptJob: any;
  isDocument: boolean = false;
  selectedFile: File;
  selectedFile1: File;
  url: string | ArrayBuffer;
  draft_letter_status: number;
  scn_master_id: any;
  scn_draft_letter_master_id: any;
  getDraftLetterDetails: any;
  authNo: any;
  applicationType: string;
  letterInfo: any;
  letterInfoText: any;
  title = "Angular 2 RC.4";
  model = "some text";
  htmlStart;
  htmlEnd = `</body></html>`
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private router: Router,
    private location: Location,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale("en-GB");
    this.createForm();
  }
  @BlockUI() blockUI: NgBlockUI;
  ngOnInit(): void {
    this.letterInfo = "";
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));
    this.authNo = localStorage.getItem("authno");
    this.applicationType = localStorage.getItem("applicationType");
    // this.getScnDraftLetterByAuthNumber();
  }

  changeTab(event) {
    console.log(event.index);
    this.tabIndex = event.index;
  }

  selectTypeOfLetter(e, typeOfLetter) {
    if (e.isUserInput) {
      let input = {
        auth_number: this.authNo,
        created_by: this.currentUser.obj.user_id,
      };
      if (typeOfLetter == "Non-Submission Letter") {
        this.htmlStart = `<!DOCTYPE html> <html> <head><style>@page { size: A4;margin: 20mm 10mm; } body { font-family: Arial, sans-serif;}.container { margin: 0 auto; max-width: 800px;} .header {font-size: 16px; margin-bottom: 20px;display: flex; justify-content: space-between;flex-wrap: wrap;} .address { margin-top: 20px; } .section-title { text-align: center; font-size: 16px; margin-top: 20px; } .section-content {margin-left: 20px;} .date { margin-top: 20px;} .footer {margin-top: 20px; text-align: right;font-size: 14px;} table { width: 100%; border-collapse: collapse;table-layout: fixed; margin-top: 20px;} th, td { border: 1px solid #000; padding: 8px;text-align: center;}.page-break {page-break-before: always;}</style></head><body><div class="header"><img src="https://omsgate.sensez9.tech/customsuat/Doc_Header.png" width="100%" height="10%"/> </div>`
        this.getNonSubmissionLetter(input);
      }
      if (typeOfLetter == "Demand Letter/Notice") {
        this.htmlStart = `<!DOCTYPE html><html><head><style> @page {size: A4;margin: 20mm 10mm;}body {font-family: Arial, sans-serif;}.container {margin: 0 auto;max-width: 800px;} .header {font-size: 16px;margin-bottom: 20px;display: flex;justify-content: space-between;flex-wrap: wrap;}.address {margin-top: 20px;}.section-title {text-align: center;font-size: 16px;margin-top: 20px;}.section-content {margin-left: 20px;}.date {margin-top: 20px;}.footer {margin-top: 20px;text-align: right;font-size: 14px;}table {width: 100%;border-collapse: collapse;table-layout: fixed;margin-top: 20px;}th, td {border: 1px solid #000;padding: 8px;text-align: center;}th {background-color: #f2f2f2;}.page-break {page-break-before: always;}</style></head><body><div class="header"><img src="https://omsgate.sensez9.tech/customsuat/Doc_Header.png" width="100%" height="10%"/> </div>`;
        this.getDemandNoticeLetter(input);
      }
      if (typeOfLetter == "Summons") {
        this.htmlStart = `<!DOCTYPE html><html> <head>  <style>  @page {size: A4; margin: 20mm 10mm;  } body { font-family: Arial, sans-serif; } .container { margin: 0 auto;max-width: 800px;} .header {font-size: 16px;  margin-bottom: 20px; display: flex;justify-content: space-between; flex-wrap: wrap;  }  .address {  margin-top: 20px; } .section-title {text-align: center;font-size: 16px;margin-top: 20px; }.section-content { margin-left: 20px; }.date {margin-top: 20px; } .footer { margin-top: 20px;text-align: right;  font-size: 14px;  }table { width: 100%;border-collapse: collapse; table-layout: fixed;  margin-top: 20px; }th, td { border: 1px solid #000; padding: 8px; text-align: center;} th {background-color: #f2f2f2; }.page-break {page-break-before: always; }</style> </head> <body><div class="header"><img src="https://omsgate.sensez9.tech/customsuat/Doc_Header.png" width="100%" height="10%"/> </div>`
        this.getSummonsLetter(input);
      }
      if (typeOfLetter == "Show Cause Notice") {
        this.htmlStart = `<!DOCTYPE html> <html><head> <style>  @page { size: A4; margin: 20mm 10mm;} body {  font-family: Arial, sans-serif; }.container { margin: 0 auto; max-width: 800px; } .header {  font-size: 16px; margin-bottom: 20px;  display: flex; justify-content: space-between; flex-wrap: wrap;}.address {  margin-top: 20px; }.section-title {  text-align: center;  font-size: 16px; margin-top: 20px; }.section-content { margin-left: 20px; } .date { margin-top: 20px; } .footer {margin-top: 20px;text-align: right; font-size: 14px; }table { width: 100%;border-collapse: collapse;table-layout: fixed;  margin-top: 20px;}th, td { border: 1px solid #000; padding: 8px; text-align: center;} th {background-color: #f2f2f2;} .page-break { page-break-before: always;} </style> </head><body><div class="header"><img src="https://omsgate.sensez9.tech/customsuat/Doc_Header.png" width="100%" height="10%"/> </div>`
        this.getScnLetter(input);
      }
    }
  }

  getScnLetter(input) {
    this.blockchainService.getScnLetter(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.letterInfo = resp.body.obj;
      } else if (resp.body.status == 400) {
        this.router.navigate(["/login"]);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      } else {
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  getNonSubmissionLetter(input) {
    this.blockchainService.getNonSubmissionLetter(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.letterInfo = resp.body.obj;
      } else if (resp.body.status == 400) {
        this.router.navigate(["/login"]);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      } else {
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  getDemandNoticeLetter(input) {
    this.blockchainService.getDemandNoticeLetter(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.letterInfo = resp.body.obj;
      } else if (resp.body.status == 400) {
        this.router.navigate(["/login"]);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      } else {
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  getSummonsLetter(input) {
    this.blockchainService.getSummonsLetter(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.letterInfo = resp.body.obj;
      } else if (resp.body.status == 400) {
        this.router.navigate(["/login"]);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      } else {
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    });
  }

  createForm() {
    this.draftForm = this.formBuilder.group({
      date_of_letter: [""],
      type_of_letter: [""],
      amount_demanded_in_inr: [""],
      letter_auto_text: [""],
      letter_custom_text: [""],
    });
  }
  scnModuleOpen() {
    this.router.navigate(["/scnRecoveryModule"]);
  }
  // getScnDraftLetterByAuthNumber() {
  //   let input = {
  //     auth_number: this.authNo
  //   }
  //   this.blockchainService.getScnDraftLetterByAuthNumber(input).subscribe(
  //     (resp) => {

  //       this.getDraftLetterDetails = resp.body.obj;
  //       console.log(this.getDraftLetterDetails)
  //       this.htmlContent = this.getDraftLetterDetails.letter_custom_text
  //       this.scn_draft_letter_master_id = this.getDraftLetterDetails.scn_draft_letter_master_id;
  //       this.draftForm.setValue({
  //         date_of_letter: this.pipe.transform(this.getDraftLetterDetails.date_of_letter, "yyyy-MM-dd"),
  //         type_of_letter: this.getDraftLetterDetails.type_of_letter,
  //         amount_demanded_in_inr: this.getDraftLetterDetails.amount_demanded_in_inr,
  //         letter_auto_text: this.getDraftLetterDetails.letter_auto_text,
  //         letter_custom_text: this.htmlContent

  //       })

  //     })
  // }

  back() {
    let currentTable:any =localStorage.getItem('redirectedpage');
    console.log(currentTable)
    console.log(this.router.url);
    // this.router.navigate(["/processorView"]);
    if(currentTable=="authAfterExport"){
      console.log(currentTable);
    this.router.navigate(["/authAfterExport"]);
    }
    else if(currentTable=="cancelledBond"){
      console.log(currentTable);
    this.router.navigate(["/cancelledBond"]);
    }
    else if(currentTable=="liveBond"){
      console.log(currentTable);
    this.router.navigate(["/liveBond"]);
    }
    else if(currentTable=="detailAuthSummaryValidity"){
      console.log(currentTable);
    this.router.navigate(["/detailAuthSummaryValidity"]);
    }
    else{
      this.router.navigate(["/processorView"]);
    }

    // this.location.back();
  }
  loader: boolean=false;
  saveDraft() {

    let letter =
      document.getElementById("letterInfoText").innerHTML;
      this.draftForm.value.letter_auto_text = this.htmlStart.replaceAll('\n',' ') + letter + this.htmlEnd.replaceAll('\n',' ');
      console.log( this.draftForm.value.letter_auto_text);
    // if(this.scn_draft_letter_master_id !=null || this.scn_draft_letter_master_id !=undefined){
    //   let input = {
    //     date_of_letter: this.pipe.transform(this.draftForm.value.date_of_letter, "yyyy-MM-dd HH:mm:ss"),
    //     type_of_letter: this.draftForm.value.type_of_letter,
    //     amount_demanded_in_inr: this.draftForm.value.amount_demanded_in_inr,
    //     letter_auto_text: this.draftForm.value.letter_auto_text,
    //     letter_custom_text: this.draftForm.value.letter_custom_text,
    //     auth_number:"auth@9745",
    //     scn_draft_letter_master_id:this.scn_draft_letter_master_id
    //   }
    //   this.blockchainService.updateScnDraftLetter(input).subscribe(
    //     (resp) => {
    //       console.log(resp.body.obj)

    //       if (resp.body.status == 200) {
    //         this.showNotification(
    //           "bottom",
    //           "right",
    //           "success",
    //           resp.body.message,
    //           "announcement"
    //         );
    //       } else {
    //         this.showNotification(
    //           "bottom",
    //           "right",
    //           "success",
    //           resp.body.message,
    //           "announcement"
    //         );
    //       }
    //     })
    // }else{
    let input = {
      date_of_letter: this.pipe.transform(
        this.draftForm.value.date_of_letter,
        "yyyy-MM-dd HH:mm:ss"
      ),
      type_of_letter: this.draftForm.value.type_of_letter,
      amount_demanded_in_inr: this.draftForm.value.amount_demanded_in_inr,
      letter_auto_text: this.draftForm.value.letter_auto_text.replaceAll('\n',' '),
      letter_custom_text: this.htmlContent,
      auth_number: this.authNo,
      created_by: this.currentUser.obj.user_id,
      applicationType: this.applicationType ? this.applicationType : "AA",
    };
    console.log(input, "save scn");
    this.loader=true;
    this.blockUI.start();
    this.blockchainService.saveScnDraftLetter(input).subscribe((resp) => {
      console.log(resp.body.obj);
      localStorage.setItem(
        "scn_draft_letter_master_id",
        JSON.stringify(resp.body.obj)
      );
      this.scn_draft_letter_master_id = JSON.parse(
        localStorage.getItem("scn_draft_letter_master_id")
      );
      if (resp.body.status == 200) {
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        this.loader=false;
        this.blockUI.stop();
        setTimeout(() => {
          location.reload();
        }, 3000);
      } else {
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        this.loader=false;
        this.blockUI.stop();
      }
    });
    // }
  }
  printLetter() {
    //  window.print();

    let popupWin;

    // let print =  {
    //     'date_of_letter': this.draftForm.value.date_of_letter,
    //     'type_of_letter': this.draftForm.value.type_of_letter,
    //     'amount_demanded_in_inr': this.draftForm.value.amount_demanded_in_inr,
    //     'letter_auto_text': this.draftForm.value.letter_auto_text,
    //     'letter_custom_text': this.htmlContent,
    //     'auth_number':this.authNo,
    //     'created_by':this.currentUser.obj.user_id
    //   };
    //      printContents = print;

    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          footer {
  font-size: 9px;
  color: #f00;
  text-align: center;
}

@page {
  size: A4;
  margin: 11mm 17mm 17mm 17mm;
}

@media print {
  footer {
    position: fixed;
    font-size: 150px;
    bottom: 0;
  }

  .content-block, p {
    page-break-inside: avoid;
  }

  html, body {
    width: 210mm;
    height: 297mm;
  }
}
          </style>
        </head>
    
    <body onload="window.print();window.close()"> 
    <table border = "1" cellpadding = "5" cellspacing = "5">
    <tr>
       <th>DATE OF LETTER</th>
       <td>${this.draftForm.value.date_of_letter}</td>
    </tr>
    <tr>
       <th>TYPE OF LETTER</th>
       <td>${this.draftForm.value.type_of_letter}</td>
    </tr>
    <tr>
       <th>AMOUNT DEMANDED IN INR</th>
       <td>${this.draftForm.value.amount_demanded_in_inr}</td>
    </tr>
    <tr>
    <th>LETTER AUTO TEXT</th>
    <td>${this.draftForm.value.letter_auto_text}</td>
 </tr>
 <tr>
 <th>LETTER CUSTOM TEXT</th>
 <td>${this.htmlContent}</td>
</tr>

 </table>
 </body>
      </html>`);
    popupWin.document.close();
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd HH:mm:ss");
    this.noteDate = this.date;
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "15rem",
    minHeight: "5rem",
    placeholder: "Enter text here...",
    translate: "no",
    defaultParagraphSeparator: "p",
    defaultFontName: "Arial",
    toolbarHiddenButtons: [["bold"]],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
  };
}
