import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
// import "rxjs/add/operator/map";
import { environment } from "environments/environment";
import { access } from "fs";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  
  constructor(private http: HttpClient, private router: Router) {}

   
  oldbaseUrl = "https://omsgate.in/customs1.0/"
  oldprodBaseUrl = "https://omsgate.in/customs1.1/"
    
    prodBaseUrl="https://oms.accmumbai.gov.in/customs1.1/"
    uatBaseUrl = "https://omsgate.sensez9.tech/customs1.0UAT/"
    uatLocalBaseUrl = "https://omsgate.sensez9.tech/customs1.0UATLocal/"
    localBaseUrl ="http://3.86.184.35:8080/customs1.0UATLocal/"

    allBaseUrl = this.prodBaseUrl

  login(login: any) {
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post<any>(
         
              this.allBaseUrl + "userLogin",
      { username: login.email, password: login.password,secret_key:login.secret_key},
      {
        headers: httpHeaders,
      }
    );

    // .map(user => {

    //   // login successful if there's a jwt token in the response
    //   if (user && user.token) {
    //     // store user details and jwt token in local storage to keep user logged in between page refreshes
    //     localStorage.setItem('currentUser', JSON.stringify(user));
    //   }

    //   return user;
    // });
  }
  userLogout(emailId:any){
    
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.post<any>(
         
            this.allBaseUrl + "api/v1/logOut",
      { email: emailId},
      {
        headers: httpHeaders,
      }
    );
  
  }

  // getGeneratereports(fromdate: String, todate: String): Observable<HttpResponse<TemperaturetRportsResponse>> {

  //   console.log('FromDate' + fromdate);
  //   console.log('ToDate' + todate);

  //   const httpHeaders = new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   });
  //   return this.http.post<TemperaturetRportsResponse>(this.getGeneratereportsUrl,

  //     {
  //       'client_id': '37',
  //       'user_id': '250',
  //       'from_date': fromdate,
  //       'to_date': todate,
  //     }
  //     ,
  //     {
  //       headers: httpHeaders,
  //       observe: 'response'
  //     }
  //   );
  // };

  getAccessToken() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let accessToken = currentUser.obj.access_token;
    return accessToken;
  }

  getRefToken() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let accessToken = currentUser.obj.refresh_token;
    return accessToken;
  }


  public saveToken(token: any): void {
          // let refreshResp = JSON.parse(token);
          localStorage.setItem("access_token", token.access_token);
          const currentUser = JSON.parse(localStorage.getItem("currentUser"));
          currentUser.obj.access_token = token.access_token;
          currentUser.obj.login_date = new Date().getTime();
          currentUser.obj.expires_in = token.expires_in;
          currentUser.obj.refresh_token = token.refresh_token;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
  }
  

  async getCurrentUser() {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    return currentUser;
  }


  getRefreshToken(refreshToken: string) {
    const result = new Subject<any>();
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    // let refreshToken = currentUser.obj.refresh_token;
    let refreshResp;
   
      this.refreshToken(refreshToken).subscribe((resp) => {
        if(resp.status == 200) {
          refreshResp = JSON.parse(resp.obj);
          localStorage.setItem("access_token", refreshResp.access_token);
          const currentUser = JSON.parse(localStorage.getItem("currentUser"));
          currentUser.obj.access_token = refreshResp.access_token;
          currentUser.obj.login_date = new Date().getTime();
          currentUser.obj.expires_in = refreshResp.expires_in;
          currentUser.obj.refresh_token = refreshResp.refresh_token;
          localStorage.setItem("currentUser", JSON.stringify(currentUser));
          console.log(refreshResp.access_token, "inside subscribe of interceptor");
          result.next(refreshResp.access_token);
          
        }
        //  else {
        //   return null;
        // }
    
        // return refreshResp;
      });
   
     result.asObservable();
  }

  validateToken(){
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    let current_date = new Date().getTime();
    let difference_timestamp = (current_date - currentUser.obj.login_date)/1000;
    if(difference_timestamp > currentUser.obj.expires_in){
      return null;
    }
    else{
      console.log('else condn',difference_timestamp);
      return currentUser.obj.access_token;
    }
  }

  refreshToken(refresh_token) {
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));
    const httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      // Authorization: "Bearer" + " " + currentUser.obj.access_token,
    });
    console.log("before refresh token call : ", refresh_token);
    return this.http.post<any>(
    

      this.allBaseUrl + "refreshToken",

      { refresh_token: refresh_token },
      {
        headers: httpHeaders,
      }
    );
  }


  logout(): void {
    // remove user from local storage to log user out
    // this.userLogout();
    location.reload();
    localStorage.setItem("isLoggedIn", "false");
    // this.userLogout();
    localStorage.removeItem("currentUser");
    localStorage.clear();
    
    this.router.navigate(["/login"]);
  }
}
//LOGIN
 //  "https://oms.accmumbai.gov.in/customs1.0/userLogin",
            //prod
              //  "https://oms.accmumbai.gov.in/customs1.1/userLogin",
            //UAT
              //  "https://omsgate.sensez9.tech/customs1.0UAT/userLogin",
            //local 
            // "http://3.86.184.35:8080/customs1.0UATLocal/userLogin",
              //  "http://3.86.184.35:8080/customs1.0UAT/userLogin",

//REFRESH TOKEN

  //prod
      //  "https://oms.accmumbai.gov.in/customs1.1/refreshToken",

      //uat
      // "https://omsgate.sensez9.tech/customs1.0UATLocal/refreshToken",

      //local
      // "http://3.86.184.35:8080/customs1.0UAT/refreshToken",
      // "http://3.86.184.35:8080/customs1.0UATLocal/refreshToken",


//LOGOUT
 //  "https://oms.accmumbai.gov.in/customs1.0/userLogin",
            //prod
              //  "https://oms.accmumbai.gov.in/customs1.1/userLogin",
            //UAT
                //  "https://omsgate.sensez9.tech/customs1.0UATLocal/api/v1/logOut",
            //local 
            //  "http://3.86.184.35:8080/customs1.0UAT/api/v1/logOut",
            // "http://3.86.184.35:8080/customs1.0UATLocal/api/v1/logOut",
