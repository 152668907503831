import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

@Component({
  selector: "app-camendment",
  templateUrl: "./camendment.component.html",
  styleUrls: ["./camendment.component.css"],
})
export class CamendmentComponent implements OnInit {
  sectionViewLetterDetails:any;
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  selectedDate: any;
  ivDate: any;
  evDate: any;
  aiDate: any;
  aeDate: any;
  pipe = new DatePipe("en-US");
  now = Date.now();

  amendmentForm = this.formBuilder.group({
    iecNo: ["", Validators.required],
    license_no: ["", Validators.required],
    ivDate: [""],
    evDate: [""],
    aiDate: [""],
    aeDate: [""],
  });
  currentUser: any;
  selectedFile: File;
  url: string | ArrayBuffer;
  date: string;
  applicationType: string;
  notValid: boolean = false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.applicationType = localStorage.getItem('applicationType')
  }

  get f() {
    return this.amendmentForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.amendmentForm.controls[controlName].hasError(errorName);
  };

  submit() {
    this.blockUI.start("Please wait this may take few seconds ...");
    let input = {
      iec_no: this.amendmentForm.value.iecNo,
      license_no: this.amendmentForm.value.license_no,
      import_validity_date: this.pipe.transform(
        this.amendmentForm.value.ivDate,
        "yyyy-MM-dd"
      ),
      export_validity_date: this.pipe.transform(
        this.amendmentForm.value.evDate,
        "yyyy-MM-dd"
      ),

      amended_import_date: this.pipe.transform(
        this.amendmentForm.value.aiDate,
        "yyyy-MM-dd"
      ),
      amended_export_date: this.pipe.transform(
        this.amendmentForm.value.aeDate,
        "yyyy-MM-dd"
      ),
      upload_letter_path: this.selectedFile,
      created_by: this.currentUser.obj.user_id,
    };

    console.log(input, 'inputtt')
    this.blockchainService.saveCustomsAmendment(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );

        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
      } else {
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
      }
    });
  }


  
  focusOutIecFunction(event: any) {
    console.log(event.target.value);
    let input = {
      iec_no : event.target.value
    }

    this.blockchainService.checkIssuedIecNo(input).subscribe((resp) => {
      if (resp.body.status == 200) {
        this.notValid = false;
        
      } else {
        this.notValid = true;
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
      }
    });
  }


  onFileSelected(event) {
    let $img4: any = document.querySelector('#file');
    let fileType = <File>event.target.files[0];
    if(fileType.type == 'application/pdf'){
    this.selectedFile = <File>event.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]); // read file as data url

    reader.onload = (event) => {
      // called once readAsDataURL is completed
      this.url = event.target.result;
    };
    this.onUpload();
  }else{
    $img4.value=''
    this.showNotification(
      "bottom",
      "right",
      "success",
      "File Format Invalid",
      "announcement"
    );
  }
  }

  openModalViewLetter(section) {
    // console.log(section.letter);
    this.sectionViewLetterDetails = section;
    // console.log(this.sectionViewLetterDetails)
    const buttonModal = document.getElementById("openModalButton");
    // buttonModal.click();
  } 

  onUpload() {
    const fd = new FormData();
    fd.append("file", this.selectedFile, this.selectedFile.name);
    // this.submitRequest()
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
