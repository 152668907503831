import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface PeriodicElement {
  no: number;
  document: string;
  viewPdf: string;
  verified:string,
  remarks: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    no: 1,
    document: "IEC COPY",
    viewPdf:
      "content/customs/6ec6b3c2-16cc-4803-b3f3-b33e02051bcc/new_request/Holiday List 2021.pdf",
      verified:"",
    remarks: "",
  },
  {
    no: 2,
    document: "GST REGISTRATION COPY",
    viewPdf: "",
    verified:"",
    remarks: "",
  },
  {
    no: 3,
    document: "LICENSE SCRIPT",
    viewPdf: "",
    verified:"",
    remarks: "",
  },
  {
    no: 4,
    document: "STAR EXPORT HOUSE COPY",
    viewPdf: "",
    verified:"",
    remarks: "",
  },
  {
    no: 5,
    document: "POWER OF ATTORNEY ",
    viewPdf: "",
    verified:"",
    remarks: "",
  },
  {
    no: 6,
    document: "BANK GUARANTEE",
    viewPdf: "",
    verified:"",
    remarks: "",
  },
  {
    no: 7,
    document: "BOND",
    viewPdf: "",
    verified:"",
    remarks: "",
  },

  {
    no: 8,
    document: "AFFADAVIT",
    viewPdf: "",
    verified:"",
    remarks: "",
  },
  {
    no: 9,
    document: "RCMC",
    viewPdf: "",
    verified:"",
    remarks: "",
  },
  {
    no: 10,
    document: "TURNOVER CERTIFICATE",
    viewPdf: "",
    verified:"",
    remarks: "",
  },
  {
    no: 11,
    document: "IDENTITY PROOF - POA ",
    viewPdf: "",
    verified:"",
    remarks: "",
  },
  {
    no: 12,
    document: "IDENTITY PROOF - BOND WITNESS",
    viewPdf: "",
    verified: "",
    remarks: "",
  },
];



@Component({
  selector: 'app-bbg-accept',
  templateUrl: './bbg-accept.component.html',
  styleUrls: ['./bbg-accept.component.css']
})
export class BbgAcceptComponent implements OnInit {

  selectedStatus = "";

  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;
  dataSource = ELEMENT_DATA;
  initiateNotesheetForm = this.formBuilder.group({
    notesheetDetails: ["", Validators.required],
  });
  displayedColumns = [
    "no",
    "document",
    "viewPdf",
    "verified",
    "remarks",
  ];
  autoFillForm = this.formBuilder.group({
    name: [""],
    designation: [""],
  });

  caseDetailsFillForm = this.formBuilder.group({
    impexpname: [""],
    iecCode: [""],
    inititionDate: [""],
    fileNo: [""],
    jobNo:[""],
    newBondNo:[""],
    noAlert: [""],
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.caseDetailsData = JSON.parse(localStorage.getItem("casedetailsData"));

    this.autoFillForm.setValue({
      name: this.currentUser.obj.first_name + this.currentUser.obj.last_name,
      designation: this.currentUser.obj.designation,
    });
    this.caseDetailsFillForm.setValue({
      impexpname: this.caseDetailsData.impexpName,
      iecCode: this.caseDetailsData.iecCode,
      inititionDate: this.caseDetailsData.lastActionDate,
      jobNo:this.caseDetailsData.jobNo,
      newBondNo:this.caseDetailsData.newBondNo,
      fileNo: this.caseDetailsData.fileNo,
      noAlert: "",
    });
  }

  back() {
    this.router.navigate(["/validateDc"]);
  }

  acceptJob(){
    this.router.navigate(['/acceptJobDc']);
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  statusFn(e, status) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }
  
  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
