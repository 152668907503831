import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  UntypedFormBuilder,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { FormControl } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { HttpErrorResponse } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { Overlay } from "@angular/cdk/overlay";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { Sort } from "app/paging/page";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { PaymentsumComponent } from "app/blockchainImpExp/imp_exp/payment/paymentsum/paymentsum.component";

declare var $: any;

@Component({
  selector: 'app-deposit-challan',
  templateUrl: './deposit-challan.component.html',
  styleUrls: ['./deposit-challan.component.css']
})
export class DepositChallanComponent implements OnInit {
  currentUser: any;
  role: any;
  pipe = new DatePipe("en-US");
  todayDate = this.pipe.transform(new Date(), "yyyy-MM-dd");
  date: any;
  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  loader: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    this.role = this.currentUser.obj.user_type_id;

  }

  getSbiChallanPdfs() {
    this.loader = true;

    let input = {
      challan_date: this.date ? this.date : this.todayDate
    }
    this.blockUI.start("Please wait this may take few seconds ...");
    this.blockchainService.getSbiChallanPdfs(input).subscribe(resp => {
      if (resp.body.status == 200) {
        this.loader = false;

        let url = resp.body.obj;
        url = url+'/'+ this.currentUser.obj.access_token
        setTimeout(() => {
          window.open(url, '_blank')

        }, 500);
        this.openDialog(
          "paymentSum",
          "Successfully Viewing SBI Challan",
          ""
        );
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        // this.openDialog(
        //   "paymentSum",
        //   "EChallan Accepted Receipt Generated Successfully",
        //   "" 
        //      );
        //      setTimeout(() => {
        //       let url = resp.body.obj;
        // window.open(url, '_blank')
        //     }, 1000);
      }
      else {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    })

  }

  getCashChallanPdfs() {
    this.loader = true;

    let input = {
      challan_date: this.date ? this.date : this.todayDate
    }
    this.blockUI.start("Please wait this may take few seconds ...");
    this.blockchainService.getCashChallanPdfs(input).subscribe(resp => {
      if (resp.body.status == 200) {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.loader = false;

        let url = resp.body.obj;
        url = url+'/'+ this.currentUser.obj.access_token
        setTimeout(() => {
          window.open(url, '_blank')

        }, 500);
        this.openDialog(
          "paymentSum",
          "Successfully Viewing Cash Challan",
          ""
        );
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
      }
      else {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    })

  }

  getNonSbiChallanPdfs() {
    this.loader = true;

    let input = {
      challan_date: this.date ? this.date : this.todayDate
    }
    this.blockUI.start("Please wait this may take few seconds ...");
    this.blockchainService.getNonSbiChallanPdfs(input).subscribe(resp => {
      if (resp.body.status == 200) {

        let url = resp.body.obj;
        url = url+'/'+ this.currentUser.obj.access_token
        setTimeout(() => {
          window.open(url, '_blank')

        }, 500);
        this.openDialog(
          "paymentSum",
          "Successfully Viewing NON-SBI Challan",
          ""
        );
        setTimeout(() => {
          this.blockUI.stop();
        }, 500);
        this.loader = false;

      }
      else {
        setTimeout(() => {
          this.blockUI.stop();
        }, 1000);
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    })

  }


  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    // dialogConfig.height = "500px";
    // dialogConfig.width = "850px";

    let dialogRef;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "paymentSum" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

}
