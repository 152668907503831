import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { AlertService } from "app/_services";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaymentsumComponent } from "../../payment/paymentsum/paymentsum.component";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

export interface bgType {
  id: string;
  name: string;
}

@Component({
  selector: "app-bong-bg-details",
  templateUrl: "./bong-bg-details.component.html",
  styleUrls: ["./bong-bg-details.component.css"],
})
export class BongBgDetailsComponent implements OnInit {
  pipe = new DatePipe("en-US");

  selectedStatus = "";
  bgGuaranteeTypes: bgType[] = [
    { id: "NO BG REQUIRED", name: "NO BG REQUIRED" },
    { id: "15% BG REQUIRED", name: "15% BG REQUIRED" },
    { id: "25% BG REQUIRED", name: "25% BG REQUIRED" },
    { id: "100% BG REQUIRED", name: "100% BG REQUIRED" },
  ];

  exemptionReasonTypes: bgType[] = [
    { id: "Star Exporter", name: "Star Exporter" },
    { id: "Corporate Guarantee", name: "Corporate Guarantee" },
    { id: "Manufacturer Exporter", name: "Manufacturer Exporter" },
    { id: "GST Paid Is Greater Than 1 Crore", name: "GST Paid Is Greater Than 1 Crore" },
    { id: "Preceding Year Export Greater Than 1 Crore", name: "Preceding Year Export Greater Than 1 Crore" },
    { id: "Merchant Exporter for Greater Than 5 Years", name: "Merchant Exporter for Greater Than 5 Years" },
  ];

  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI | any;
  blockTemplate: BlockTemplateComponent | any;
  role: any;
  authorizationDate: any;
  impDate: any;
  expDate: any;
  bgLastDate:any;
  bgIssueDate:any;
  autoRenewal: any;
  isStarExp: any;
  showBothButtons: boolean = false;

  addBondForm = this.formBuilder.group({
    bondUpload: [""],
    bondAmount: ["", Validators.required],
    isStarExp: [""],
    no_bg_doc:[""],
    starExp: [""],
    bgDoc: [""],
    bgNo: [""],
    bgAmount: [""],
    bgIssueDate: [""],
    bgLastDate: [""],
    bankName: [""],
    bankAddress: [""],
    branchName: [""],
    ifsc: [""],
    remarks: [""],
    autoRenewal: [""],
    bgType:[""],
    exemptionType : [""],
    no_bg_exemption : [""],
    yes_bg_exemption:[""]
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean| any;
  date: string| any;
  modifiedCurrentDate: string| any;
  lastDate: any;
  isaaForm2Enable: boolean = false;
  selectedFile: any;
  url: string | ArrayBuffer| any;
  bondResp: any;
  showUploadBG: boolean| any;
  selectedFileStar: File| any;
  selectedFileBG: File| any;
  flag: boolean| any;
  isSelectedFile: boolean| any;
  isSelectedFileBG: boolean| any;
  isSelectedFileStar: boolean| any;
  pdfBaseUrl: string| any;
  isUpdate: boolean| any;
  bond_id: string| any;
  applicationType: string| any;
  isBGExemption: boolean | any;
  url1: any;
  sectionViewLetterDetails: any;
  isBGRequired: boolean | any;
  selectedFileSupportingDoc: File| any;
  urlSupportingDoc: any;
  isSelectedFileSupportingDoc: boolean | any;
  no_bg_exemption: any;
  yes_bg_exemption: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private alertService: AlertService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    this.bond_id = localStorage.getItem('bond_id');
    let localstorageCurrentUser : any = localStorage.getItem("currentUser")
    this.currentUser = JSON.parse(localstorageCurrentUser);
    this.applicationType = localStorage.getItem('applicationType')
    this.getBondById();
    // this.pdfBaseUrl = "https://oms.accmumbai.gov.in/customs1.0/api/v1/downloadDocument/"
    //UAT URL IS SENSEZ9
    this.pdfBaseUrl = this.blockchainService.docDownload;
  }

  selectedExempt(e: { isUserInput: any; source: { value: string; }; },name: any){
    if(e.isUserInput){
      console.log(e.source.value)
      if(e.source.value == 'NO BG REQUIRED'){
        this.isBGRequired = false
      } else{
        this.isBGRequired = true
      }
      
    }
  }

  getBondById() {
    let input = {
      application_id: localStorage.getItem("application_id"),
    };
    this.blockchainService.getBondById(input).subscribe((resp) => {
      this.bondResp = resp.body.obj;
      localStorage.setItem('bond_id',this.bondResp.bond_id)
      if (this.bondResp != null) {
        if(this.bondResp.bond_path != null && this.bondResp.bond_path != undefined){
          this.isSelectedFile = true
        } else{
          this.isSelectedFile = false
        }

        if(this.bondResp.no_bg_doc != null && this.bondResp.no_bg_doc != undefined){
          this.isSelectedFileSupportingDoc = true
        } else{
          this.isSelectedFileSupportingDoc = false
        }

        if(this.bondResp.bank_guarantee_path != null && this.bondResp.bank_guarantee_path != undefined){
          this.isSelectedFileBG = true
        } else{
          this.isSelectedFileBG = false
        }

        if(this.bondResp.start_exporter_house_copy_path != null && this.bondResp.start_exporter_house_copy_path != undefined){
          this.isSelectedFileStar = true
        } else{
          this.isSelectedFileStar = false
        }
        if(this.bondResp.yes_bg_exemption == 'NO BG REQUIRED'){
          this.isBGRequired = false
        } else{
          this.isBGRequired = true
        }

        this.no_bg_exemption = this.bondResp.no_bg_exemption
        this.yes_bg_exemption = this.bondResp.yes_bg_exemption
        this.selectedFile =   this.bondResp.bond_path;
        this.selectedFileSupportingDoc = this.bondResp.no_bg_doc;
        console.log('selectedFile',this.selectedFile)
        this.selectedFileBG = this.bondResp.bank_guarantee_path;
        this.selectedFileStar = this.bondResp.start_exporter_house_copy_path;
        this.addBondForm.patchValue({
          yes_bg_exemption:this.bondResp.yes_bg_exemption,
          no_bg_exemption:this.bondResp.no_bg_exemption,
          bankAddress: this.bondResp.address_of_bank,
          bgAmount: this.bondResp.amount_of_bank_guarantee,
          autoRenewal: this.bondResp.auto_renewal == 0 ? "YES" : "NO",
          bgNo: this.bondResp.bank_guarantee_number,
          ifsc: this.bondResp.ifsc_of_branch,
          bankName: this.bondResp.name_of_bank,
          branchName: this.bondResp.name_of_branch,
          isStarExp: this.bondResp.start_exporter == 0 ? "YES" : "NO",
          bondAmount: this.bondResp.bond_amount_inr,
          remarks: this.bondResp.remarks,
          bgIssueDate: this.bondResp.bank_gaurantee_issue_date,
          bgLastDate: this.bondResp.bank_gaurantee_last_validity_date,
        });

        console.log(this.addBondForm.value);
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        location.reload();
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
        location.reload();
      }
      else{}
    });
  }

  back() {
    this.router.navigate(["/supportingProjectAA"]);
  }

  next() {
    this.isUpdate = true
    this.addBondForm.value.bondUpload = this.selectedFile;
    this.addBondForm.value.starExp = this.selectedFileStar;
    this.addBondForm.value.bgDoc = this.selectedFileBG;
    this.addBondForm.value.bgIssueDate = this.pipe.transform(this.addBondForm.value.bgIssueDate, "yyyy-MM-dd")
    this.addBondForm.value.bgLastDate = this.pipe.transform(this.addBondForm.value.bgLastDate, "yyyy-MM-dd")
    this.addBondForm.value.no_bg_doc = this.selectedFileSupportingDoc;

      if(this.addBondForm.value.bondUpload != null && this.addBondForm.value.bondUpload != undefined && this.addBondForm.value.bondUpload != ''){
        if(this.addBondForm.value.bondAmount != null && this.addBondForm.value.bondAmount != undefined && this.addBondForm.value.bondAmount != ''){
          if(this.addBondForm.value.yes_bg_exemption != null && this.addBondForm.value.yes_bg_exemption != undefined && this.addBondForm.value.yes_bg_exemption != ''){
            if(this.isBGRequired){
              if(this.addBondForm.value.bgDoc != null && this.addBondForm.value.bgDoc != undefined && this.addBondForm.value.bgDoc != ''){
                if(this.addBondForm.value.bgNo != null && this.addBondForm.value.bgNo != undefined && this.addBondForm.value.bgNo != ''){
                  if(this.addBondForm.value.bgAmount != null && this.addBondForm.value.bgAmount != undefined && this.addBondForm.value.bgAmount != ''){
                    if(this.addBondForm.value.bgIssueDate != null && this.addBondForm.value.bgIssueDate != undefined && this.addBondForm.value.bgIssueDate != ''){
                      if(this.addBondForm.value.bgLastDate != null && this.addBondForm.value.bgLastDate != undefined && this.addBondForm.value.bgLastDate != ''){
                        if(this.addBondForm.value.autoRenewal != null && this.addBondForm.value.autoRenewal != undefined && this.addBondForm.value.autoRenewal != ''){
                          localStorage.setItem("bondForm", JSON.stringify(this.addBondForm.value));
                          // this.router.navigate(["/uploadDoc"]);
                          this.save()
                        } else{
                          this.showNotification(
                            "bottom",
                            "right",
                            "success",
                            "Please Select Auto Renewal",
                            "announcement"
                          );
                        }
                      }
                      else{
                        this.showNotification(
                          "bottom",
                          "right",
                          "success",
                          "Please Select Bank Guarantee Last Date",
                          "announcement"
                        );
                      }
                    }
                    else{
                      this.showNotification(
                        "bottom",
                        "right",
                        "success",
                        "Please Select Bank Guarantee Issue Date",
                        "announcement"
                      );
                    }
                  }
                  else{
                    this.showNotification(
                      "bottom",
                      "right",
                      "success",
                      "Please Enter Amount For Bank Guarantee",
                      "announcement"
                    );
                  }
                }
                else{
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Please Enter Bank Guarantee Number",
                    "announcement"
                  );
                }
              }
              else{
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  "Please Upload Bank Guarantee",
                  "announcement"
                );
              }
            }
            //if bg exemption is not required
          else{
            this.addBondForm.value.no_bg_doc =  this.selectedFileSupportingDoc
            if(this.addBondForm.value.no_bg_exemption != null && this.addBondForm.value.no_bg_exemption != undefined && this.addBondForm.value.no_bg_exemption != ''){
              if(this.addBondForm.value.no_bg_doc != null && this.addBondForm.value.no_bg_doc != undefined && this.addBondForm.value.no_bg_doc != ''){
                localStorage.setItem("bondForm", JSON.stringify(this.addBondForm.value));
                          // this.router.navigate(["/uploadDoc"]);
                          this.save()
              } else{
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  "Please Upload Exemption Supporting Document",
                  "announcement"
                );
              }
            } else{
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Enter Reason For Exemption",
                "announcement"
              );
            }
           
            
            
          }
          
          } else{
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Select Exempt From Bank Guarantee",
              "announcement"
            );
          }
        } else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Enter Bond Amount",
            "announcement"
          );
        }
      } else{
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Upload Bond",
          "announcement"
        );
      }
     
    
   
   
  
  }

  get f() {
    return this.addBondForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.addBondForm.controls[controlName].hasError(errorName);
  };

  save() {
    this.isUpdate = false
    this.addBondForm.value.bondUpload = this.selectedFile;
    this.addBondForm.value.starExp = this.selectedFileStar;
    this.addBondForm.value.bgDoc = this.selectedFileBG;
    this.addBondForm.value.bgIssueDate = this.pipe.transform(this.addBondForm.value.bgIssueDate, "yyyy-MM-dd")
    this.addBondForm.value.bgLastDate = this.pipe.transform(this.addBondForm.value.bgLastDate, "yyyy-MM-dd")
    this.addBondForm.value.no_bg_doc = this.selectedFileSupportingDoc;
    localStorage.setItem("bondForm", JSON.stringify(this.addBondForm.value));
    
    if(this.addBondForm.value.bondUpload != null && this.addBondForm.value.bondUpload != undefined && this.addBondForm.value.bondUpload != ''){
      if(this.addBondForm.value.bondAmount != null && this.addBondForm.value.bondAmount != undefined && this.addBondForm.value.bondAmount != ''){
        if(this.addBondForm.value.yes_bg_exemption != null && this.addBondForm.value.yes_bg_exemption != undefined && this.addBondForm.value.yes_bg_exemption != ''){
          if(this.isBGRequired){
            if(this.addBondForm.value.bgDoc != null && this.addBondForm.value.bgDoc != undefined && this.addBondForm.value.bgDoc != ''){
              if(this.addBondForm.value.bgNo != null && this.addBondForm.value.bgNo != undefined && this.addBondForm.value.bgNo != ''){
                if(this.addBondForm.value.bgAmount != null && this.addBondForm.value.bgAmount != undefined && this.addBondForm.value.bgAmount != ''){
                  if(this.addBondForm.value.bgIssueDate != null && this.addBondForm.value.bgIssueDate != undefined && this.addBondForm.value.bgIssueDate != ''){
                    if(this.addBondForm.value.bgLastDate != null && this.addBondForm.value.bgLastDate != undefined && this.addBondForm.value.bgLastDate != ''){
                      if(this.addBondForm.value.autoRenewal != null && this.addBondForm.value.autoRenewal != undefined && this.addBondForm.value.autoRenewal != ''){
                        // this.router.navigate(["/uploadDoc"]);
                        this.saveBond()
                      } else{
                        this.showNotification(
                          "bottom",
                          "right",
                          "success",
                          "Please Select Auto Renewal",
                          "announcement"
                        );
                      }
                    }
                    else{
                      this.showNotification(
                        "bottom",
                        "right",
                        "success",
                        "Please Select Bank Guarantee Last Date",
                        "announcement"
                      );
                    }
                  }
                  else{
                    this.showNotification(
                      "bottom",
                      "right",
                      "success",
                      "Please Select Bank Guarantee Issue Date",
                      "announcement"
                    );
                  }
                }
                else{
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Please Enter Amount For Bank Guarantee",
                    "announcement"
                  );
                }
              }
              else{
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  "Please Enter Bank Guarantee Number",
                  "announcement"
                );
              }
            }
            else{
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Upload Bank Guarantee",
                "announcement"
              );
            }
          }
          else{
            this.addBondForm.value.no_bg_doc =  this.selectedFileSupportingDoc
            if(this.addBondForm.value.no_bg_exemption != null && this.addBondForm.value.no_bg_exemption != undefined && this.addBondForm.value.no_bg_exemption != ''){
              if(this.addBondForm.value.no_bg_doc != null && this.addBondForm.value.no_bg_doc != undefined && this.addBondForm.value.no_bg_doc != ''){
                          // this.router.navigate(["/uploadDoc"]);
                          this.saveBond()              
                        } else{
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  "Please Upload Upload Exemption Supporting Document",
                  "announcement"
                );
              }
            } else{
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Enter Reason For Exemption",
                "announcement"
              );
            }
          }
        
        } else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Select Exempt From Bank Guarantee",
            "announcement"
          );
        }
      } else{
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Enter Bond Amount",
          "announcement"
        );
      }
    } else{
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Please Upload Bond",
        "announcement"
      );
    }
  
  }

  saveBond() {
    this.blockUI.start("Please wait this may take few seconds ...");
    let bondForm = this.addBondForm.value;
    if (
      (this.bond_id != null && this.bond_id != undefined ) ||
     (this.bondResp != null &&
      this.bondResp.bond_id != null &&
      this.bondResp.bond_id != "" && this.bond_id != "null") 
    ) {
    let input = {
      bond_id:this.bond_id ? this.bond_id :  this.bondResp.bond_id,        bond_path: bondForm.bondUpload,
        bond_amount_inr:bondForm.bondAmount ? bondForm.bondAmount : '',
        address_of_bank: bondForm.bankAddress ?  bondForm.bankAddress : '',
        amount_of_bank_guarantee: bondForm.bgAmount ? bondForm.bgAmount : '',
        auto_renewal: bondForm.autoRenewal == "YES" ? 0 : 1,
        bank_guarantee_number: bondForm.bgNo ? bondForm.bgNo : '',
        bank_guarantee_file: bondForm.bgDoc,
        bank_gaurantee_issue_date :bondForm.bgIssueDate ? bondForm.bgIssueDate : '',
        bank_gaurantee_last_validity_date : bondForm.bgLastDate ? bondForm.bgLastDate : '',
        ifsc_of_branch: bondForm.ifsc ?  bondForm.ifsc : '',
        name_of_bank: bondForm.bankName ? bondForm.bankName : '',
        name_of_branch: bondForm.branchName ? bondForm.branchName : '',
        start_exporter_house_copy_file: bondForm.starExp,
        application_id: localStorage.getItem("application_id"),
        start_exporter: bondForm.isStarExp == "YES" ? 0 : 1,
        remarks:bondForm.remarks ? bondForm.remarks : '',
        gst_registration_file : '' ,
        rcmc_path : '' ,
        turnover_certificate_file : '' ,
        any_other_document_file :   '',
        yes_bg_exemption:bondForm.yes_bg_exemption,
        no_bg_exemption:bondForm.no_bg_exemption,
        no_bg_doc:bondForm.no_bg_doc,
        created_by:this.currentUser.obj.user_id
      };
      this.blockchainService.updateBond(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          // localStorage.setItem('bond_id',resp.body.obj)
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          if(this.isUpdate == false){
            this.router.navigate(["/bondBgUpload"]);
          }
          // this.getBondById();
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          // this.flag=true;
        } else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          // location.reload();
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
          // location.reload();
        }
        else{}
      });
    }
    else {
      let input = {
        bond_path: bondForm.bondUpload,
        bond_amount_inr:bondForm.bondAmount ? bondForm.bondAmount : '',
        address_of_bank: bondForm.bankAddress ?  bondForm.bankAddress : '',
        amount_of_bank_guarantee: bondForm.bgAmount ? bondForm.bgAmount : '',
        auto_renewal: bondForm.autoRenewal == "YES" ? 0 : 1,
        bank_guarantee_number: bondForm.bgNo ? bondForm.bgNo : '',
        bank_guarantee_file: bondForm.bgDoc,
        bank_gaurantee_issue_date :bondForm.bgIssueDate ? bondForm.bgIssueDate : '',
        bank_gaurantee_last_validity_date : bondForm.bgLastDate ? bondForm.bgLastDate : '',
        ifsc_of_branch: bondForm.ifsc ?  bondForm.ifsc : '',
        name_of_bank: bondForm.bankName ? bondForm.bankName : '',
        name_of_branch: bondForm.branchName ? bondForm.branchName : '',
        start_exporter_house_copy_file: bondForm.starExp,
        application_id: localStorage.getItem("application_id"),
        start_exporter: bondForm.isStarExp == "YES" ? 0 : 1,
        remarks:bondForm.remarks ? bondForm.remarks : '',
        gst_registration_file : '' ,
        rcmc_path : '' ,
        turnover_certificate_file : '' ,
        any_other_document_file :   '',
        yes_bg_exemption:bondForm.yes_bg_exemption,
        no_bg_exemption:bondForm.no_bg_exemption,
        no_bg_doc:bondForm.no_bg_doc,
        created_by:this.currentUser.obj.user_id
      };
      this.blockchainService.saveBond(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          // localStorage.setItem('bond_id',resp.body.obj)
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          if(this.isUpdate == false){
            this.router.navigate(["/bondBgUpload"]);
          }
          // this.getBondById();
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          // this.flag=true;
        }else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      },(error) => {
        console.log(error)
        this.alertService.error(error);
        if(error.status == 401){
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Session Expired, Please Login Again",
            "announcement"
          );
          // location.reload();
          this.router.navigate(["/login"]);
        }
        else if(error.status == 500){
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            "Please Try Again",
            "announcement"
          );
          // location.reload();
        }
        else{}
      });
    }
    
  }
  statusFn(e: string, status: any) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  bgExemptionStatus(e: { value: string; }) {
    if (e.value == "YES") {
      this.isBGExemption= true;
    } else {
      this.isBGExemption = false;
    }
  }

  starExpStatus(e: { value: string; }) {
    if (e.value == "YES") {
      this.showUploadBG = true;
      this.showBothButtons = true;
    } else {
      this.showBothButtons = true;
      this.showUploadBG = false;
    }
  }

  onFileSelected(event: any , action: string) {
    if (action == "bond") {
      let $img: any = document.querySelector('#file');
      let fileType = <File>event.target.files[0];
      if(fileType.type == 'application/pdf'){
      this.selectedFile = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFile); // read file as data url/
      reader.onload = (event:any) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      reader.readAsArrayBuffer($img.files[0]);
      this.onUpload();
    }else{
      $img.value=''
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    }

    if (action == "star") {
      this.selectedFileStar = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        let eventTarget:any = event.target
        this.url = eventTarget.result;
      };
      this.onUpload1();
    }

    if (action == "bg") {
      
      let $img1: any = document.querySelector('#file1');
      let fileType = <File>event.target.files[0];
      if(fileType.type == 'application/pdf'){
      this.selectedFileBG = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFileBG); // read file as data url/
      reader.onload = (event:any) => {
        // called once readAsDataURL is completed
        this.url1 = event.target.result;
      };
      reader.readAsArrayBuffer($img1.files[0]);
      this.onUpload2();
    }else{
      $img1.value=''
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    }
    if (action == "supporting_doc") {
      
      let $img2: any = document.querySelector('#file2');
      let fileType = <File>event.target.files[0];
      if(fileType.type == 'application/pdf'){
      this.selectedFileSupportingDoc = <File>event.target.files[0];
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFileSupportingDoc); // read file as data url/
      reader.onload = (event:any) => {
        // called once readAsDataURL is completed
        this.urlSupportingDoc = event.target.result;
      };
      reader.readAsArrayBuffer($img2.files[0]);
      this.onUploadSupportingDoc();
    }else{
      $img2.value=''
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    }
    
  }

  //for bond
  onUpload() {
    const fd = new FormData();
    fd.append("file", this.selectedFile, this.selectedFile.name);
  }

  //for star
  onUpload1() {
    const fd = new FormData();
    fd.append("file", this.selectedFileStar, this.selectedFileStar.name);
  }

  //for bg
  onUpload2() {
    const fd = new FormData();
    fd.append("file", this.selectedFileBG, this.selectedFileBG.name);
  }

  onUploadSupportingDoc() {
    const fd = new FormData();
    fd.append("file", this.selectedFileSupportingDoc, this.selectedFileSupportingDoc.name);
  }
  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log("Event", event);
    // this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    console.log("First Date=" + this.date);
    this.modifiedCurrentDate = this.date;
    console.log("helllooo", this.modifiedCurrentDate);
  }

  formatDate(date: string | number | Date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  openModalViewLetter(section: any) {
    // console.log(section.letter);
    this.sectionViewLetterDetails = section;
    // console.log(this.sectionViewLetterDetails)
    const buttonModal = document.getElementById("openModalButton");
    // buttonModal.click();
  } 
  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "auto";

    let dialogRef:any;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result: { event: string; isUpdated: boolean; }) => {
      console.log(result)
      if (result.event === "paymentSum" && result.isUpdated === true) {
        this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }
  showNotification(from: string, align: string, color: any, stringMessage: string, icons: string) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
