import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService, ImportDetailsQuery } from "app/blockchainImpExp/blockchain.service";
import { Sort } from "app/paging/page";
import { PaginatedDataSource } from "app/paging/paginated-datasource";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { Import } from "../../import-cou/import-cou/import-cou.component";

var $: any;
export interface Status {
  value: string;
  viewValue: string;
}

export interface Export {
  srNo: number;
  boeNo: string;
  boeDate: string;
  productQty: string;
  cifValue: string;
  productDescr: string;
  itchsCode: string;
  portImport: string;
}

const ELEMENT_DATA: Export[] = [
  {
    srNo: 1,
    boeNo: "CBEXIII_BOM_2019-2020_1211_16579",
    boeDate: "01-02-2022",
    productQty: "50000",
    cifValue: "",
    productDescr: "Hardware Sensors",
    itchsCode: "CODE12345678",
    portImport: "",
  },
];

@Component({
  selector: 'app-export-cou',
  templateUrl: './export-cou.component.html',
  styleUrls: ['./export-cou.component.css']
})
export class ExportCouComponent implements OnInit {
  selectedStatus = "";
  pipe = new DatePipe("en-US");

  allStatus: Status[] = [
    { value: "LICENSE12_09_4567_678", viewValue: "LICENSE12_09_4567_678" },
    { value: "LICENSE12_09_4567_6777", viewValue: "LICENSE12_09_4567_6777" },
  ];

  @BlockUI() blockUI: NgBlockUI;
  blockTemplate: BlockTemplateComponent;
  role: any;

  updateForm = this.formBuilder.group({
    name: [""],
    section: [""],
    designation: [""]
  
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean;
  modifiedCurrentDate: any;
  date: any;
  selectedFile: File;
  removeRowNo: any;
  url: string | ArrayBuffer;

  dataSource = ELEMENT_DATA;

  displayedColumns = [
    "srNo",
    "boeNo",
    "boeDate",
    "productQty",
    "cifValue",
    "productDescr",
    "itchsCode",
    "portImport",
    "edit",
   
  ];
  summaryData: PaginatedDataSource<Import, ImportDetailsQuery>;
  initialSort: Sort<Import> = { property: "srNo", order: "desc" };


  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService
  ) {}

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (this.dataSource !== null) {
      var summaryList = this.dataSource;
      this.summaryData = new PaginatedDataSource<Import, ImportDetailsQuery>(
        (request, query) =>
          this.blockchainService.pageImport(request, query, summaryList),
        this.initialSort,
        { search: "", data: undefined },
        10
      );
    }
  }

  addImportOU(action){
    localStorage.setItem('action',action)
    this.router.navigate(["/addImportOU"]);

  }

  navigateImport() {
    this.router.navigate(["/import-ou"]);
  }


  addRow() {
    const newRow = {
      "srNo": Date.now(),
      "boeNo": "",
      "boeDate": "",
      "productQty": "",
      "cifValue": "",
      "productDescr":"",
      "itchsCode": "",
      "portImport": ""
    };
    this.dataSource = [...this.dataSource, newRow];
  }

  removeRow() {
    let no = this.removeRowNo;
    this.dataSource = this.dataSource.filter((u) => u.srNo !== no);
  }
  
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  openModal1(srNo) {
    console.log(srNo,'element no')
    this.removeRowNo = srNo;
    const buttonModal1 = document.getElementById("openModalButton1");
    console.log("buttonModal", buttonModal1);
    buttonModal1.click();
  }

  onFileSelected(event) {
    console.log("clicked");
    console.log("clicked1", event);
    this.selectedFile = <File>event.target.files[0];
    if(this.selectedFile.type == 'application/pdf'){
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      this.onUpload();
    }
    else{
      event.target.files[0].value='';
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
   
  }

  onUpload() {
    const fd = new FormData();
    console.log(this.selectedFile, "selectedFile");
    fd.append("file", this.selectedFile, this.selectedFile.name);
    // this.submitRequest()
  }

  cancel() {
    this.router.navigate(["/applnSummary"]);
  }

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    console.log("Event", event);
    // this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    console.log("First Date=" + this.date);
    this.modifiedCurrentDate = this.date;
    console.log("helllooo", this.modifiedCurrentDate);
  }

  formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

  next() {
    this.router.navigate(["/custom-interface"]);
  }

  back(){
    this.router.navigate(["/import-ou"]);
  }

  save(){
    localStorage.setItem('saveFile',JSON.stringify(this.selectedFile));
    let input = {
      // aa_file:this.selectedFile,
      // iec_no:'abcd-efgh-ijkl-mnop',
      // application_type_id:'1',
      // application_type_name:'AA',
      // user_roles:'3',
    }
    console.log(input,'input')
    this.blockchainService.saveApplication(input).subscribe(resp => {

    })
    
  }
  statusFn(e, status) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }

  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "850px";

    let dialogRef;

    if (action == "received") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      // dialogRef = this.dialog.open(RecievedFileComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === "create_request" && result.isUpdated === true) {
        //this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}

