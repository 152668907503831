import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appGreenNoteSpecialChar]'
})
export class GreenNoteSpecialCharDirective {
  // ^([\w,:\s/-]*)$
  // regexStr = "^[ A-Za-z,0-9_@./#%&+-='()\]*$";
   regexStr = /[ A-Za-z,0-9@#%&+(){}_\-\[\]'"\\,.\/?]/
  @Input() isAlphaNumeric: boolean;

  constructor(private el: ElementRef,private control : NgControl) { }


  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event']) onPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^\n[ A-Za-z,0-9@#%&+(){}_\-\[\]'"\\,.\/?]/g, '');
      // this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^A-Za-z ]/g, '').replace(/\s/g, '');
      this.control.control.setValue(this.el.nativeElement.value);
      event.preventDefault();
      console.log(this.el.nativeElement.value);
    }, 100)
  }
}
