import { MatSidenavModule, MatSidenav } from "@angular/material/sidenav";
import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { MatToolbarModule } from "@angular/material/toolbar";

declare const $: any;

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  design: number;
  children: any;
  id: number;
  isOpen: boolean;
}

export var ROUTES: RouteInfo[] = [
  {
    path: "/summary",
    title: "My Profile",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },

  {
    path: "/registerTask",
    title: "Registration  ",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },

  {
    path: "/summary",
    title: "Cancellation ",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },

  {
    path: "/summary",
    title: "Search  ",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },

  {
    path: "/summary",
    title: "MIS Reports",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },
  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [],
    isOpen: false,
  },
];
export var ROUTES1: RouteInfo[] = [
  {
    path: "/summary",
    title: "My Profile",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },

  {
    path: "/registerLicenseTa",
    title: "Registration ",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },

  {
    path: "/summary",
    title: "Cancellation ",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },

  // {
  //   path: "/summary",
  //   title: "Search  ",
  //   icon: "dashboard",
  //   class: "",
  //   design: 0,
  //   id: 1,
  //   children: [],
  //   isOpen: false,
  // },

  {
    path: "/summary",
    title: "MIS Reports",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },
  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [],
    isOpen: false,
  },
];

//trade side sidebar
export var ROUTES7: RouteInfo[] = [
  {
    path: "/welcomeUser",
    title: "Home",
    icon: "home",
    class: "",
    design: 0,
    id: 3,
    children: [ 
     ],
    isOpen: false,
  }, 
  {
    path: "/editProfile",
    title: "My Profile",
    icon: "account_box",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },

  {
    path: "/typeRole",
    title: "Registration   ",
    icon: "assignment",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },
  {
    path: "",
    title: "Obligation Update ",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 1,
    children: [
      {
        path: "/importOU",
        title: "Import ",
        icon: "fact_check",
        class: "",
        design: 0,
        id: 1,
        children: [],
        isOpen: false,
      },
      {
        path: "/exportOU",
        title: "Export",
        icon: "fact_check",
        class: "",
        design: 0,
        id: 1,
        children: [],
        isOpen: false,
      },
    ],
    isOpen: false,
  },
  {
    path: "/communicationToCustoms",
    // title: "Customs Intimation",
    // path: "/communication-to-customs",
    title: "Communication To Customs",
    icon: "collections",
    class: "",
    design: 0,
    id: 7,
    children: [ 
   
     ],
    isOpen: false,
  },
  
  {
    path: "/customsAmendment",
    title: "Amendment ",
    icon: "forward_to_inbox",
    class: "",
    design: 0,
    id: 1,
    children: [ 
   
     ],
    isOpen: false,
  },
  {
    path: "/enterProofOfPayment",
    title: "Proof of Payment",
    icon: "forward_to_inbox",
    class: "",
    design: 0,
    id: 6,
    children: [ 
   
     ],
    isOpen: false,
  },
  {
    path: "/cancelDocUpload",
    title: "Cancellation   ",
    icon: "event_busy",
    class: "",
    design: 0,
    id: 3,
    children: [],
    isOpen: false,
  },
  // {
  //   path: "/searchCase",
  //   title: "Search  ",
  //   icon: "manage_search",
  //   class: "",
  //   design: 0,
  //   id: 1,
  //   children: [],
  //   isOpen: false,
  // },

 {
    path: "/mailbox",
    title: "My Mailbox",
    icon: "forward_to_inbox",
    class: "",
    design: 0,
    id: 2,
    children: [],
    isOpen: false,
  },
  {
    path: "/history",
    title: "My History",
    icon: "assignment",
    class: "",
    design: 0,
    id: 2,
    children: [],
    isOpen: false,
  },
  {
    path: "/paymentsummary",
    title: "Payment",
    icon: "currency_rupees",
    class: "",
    design: 0,
    id: 5,
    children: [],
    isOpen: false,
  },
 
  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [],
    isOpen: false,
  },
];

export var ROUTES8: RouteInfo[] = [
  // {
  //   path: "/createProfile",
  //   title: "My Profile",
  //   icon: "account_box",
  //   class: "",
  //   design: 0,
  //   id: 1,
  //   children: [],
  //   isOpen: false,
  // },

  // {
  //   path: "/typeRole",
  //   title: "Registration   ",
  //   icon: "assignment",
  //   class: "",
  //   design: 0,
  //   id: 1,
  //   children: [],
  //   isOpen: false,
  // },
  // {
  //   path: "/importOU",
  //   title: "Obligation Update ",
  //   icon: "fact_check",
  //   class: "",
  //   design: 0,
  //   id: 1,
  //   children: [],
  //   isOpen: false,
  // },

  // {
  //   path: "/appSummary",
  //   title: "Cancellation   ",
  //   icon: "event_busy",
  //   class: "",
  //   design: 0,
  //   id: 3,
  //   children: [],
  //   isOpen: false,
  // },
  // {
  //   path: "/omSummary",
  //   title: "Obligation Management   ",
  //   icon: "event_busy",
  //   class: "",
  //   design: 0,
  //   id: 3,
  //   children: [],
  //   isOpen: false,
  // },
  
  // {
  //   path: "/summary",
  //   title: "Search  ",
  //   icon: "manage_search",
  //   class: "",
  //   design: 0,
  //   id: 1,
  //   children: [],
  //   isOpen: false,
  // },

  // {
  //   path: "/paymentsummary",
  //   title: "Payment",
  //   icon: "currency_rupees",
  //   class: "",
  //   design: 0,
  //   id: 5,
  //   children: [],
  //   isOpen: false,
  // },

  // {
  //   path: "/summary",
  //   title: "MIS Reports",
  //   icon: "dashboard",
  //   class: "",
  //   design: 0,
  //   id: 1,
  //   children: [],
  //   isOpen: false,
  // },
  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [],
    isOpen: false,
  },
];

export var ROUTES9: RouteInfo[] = [
  // {
  //   path: "/paymentsummary",
  //   title: "Payment",
  //   icon: "currency_rupees",
  //   class: "",
  //   design: 0,
  //   id: 5,
  //   children: [],
  //   isOpen: false,
  // },
  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [],
    isOpen: false,
  },
];

//payment level 1 user
export var ROUTES10: RouteInfo[] = [
  {
    path: "/paymentAcceptance",
    title: "Home",
    icon: "home",
    class: "",
    design: 0,
    id: 3,
    children: [ 
     ],
    isOpen: false,
  },
  {
    path: "/payment_acceptance",
    title: "Payment",
    icon: "currency_rupees",
    class: "",
    design: 0,
    id: 5,
    children: [],
    isOpen: false,
  },
  {
    path: "/payment_daily_mis",
    title: "Payment MIS",
    icon: "receipt",
    class: "",
    design: 0,
    id: 5,
    children: [
      // {
      //   path: "/mis_daily_summary",
      //   title: "Daily Summary",
      //   icon: "",
      //   class: "",
      //   children: [],
      // },
      // {
      //   path: "/payment_status",
      //   title: "Payment Status",
      //   icon: "",
      //   class: "",
      //   children: [],
      // },
      
      {
        path: "/payment_daily_summary",
        title: "Summary",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/daily_payment_register",
        title: "Daily Payment Register",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/accepted_challans",
        title: "Accepted Challan",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/payment_received_summary",
        title: "Payment MIS",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/payment_received_detail_summary",
        title: "Payment MIS Summary",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/paymentHeads",
        title: "Payment MIS By Heads",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/NOPSummary",
        title: "Summary By Nature of Payment",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/cash_challan",
        title: "Cash Collection",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/depositChallan",
        title: "Download Bank Challan",
        icon: "",
        class: "",
        children: [],
      },
    ],
    isOpen: false,
  },
  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [],
    isOpen: false,
  },
];

//payment level 2 user
export var ROUTESPayment2: RouteInfo[] = [
  // {
  //   path: "/payment_acceptance",
  //   title: "Payment",
  //   icon: "currency_rupees",
  //   class: "",
  //   design: 0,
  //   id: 5,
  //   children: [],
  //   isOpen: false,
  // },
  {
    path: "/paymentAcceptance",
    title: "Home",
    icon: "home",
    class: "",
    design: 0,
    id: 3,
    children: [ 
     ],
    isOpen: false,
  },
  {
    path: "/payment_daily_mis",
    title: "Payment MIS",
    icon: "receipt",
    class: "",
    design: 0,
    id: 5,
    children: [
    
      {
        path: "/payment_daily_summary",
        title: "Summary",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/daily_payment_register",
        title: "Daily Payment Register",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/accepted_challans",
        title: "Accepted Challan",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/payment_received_summary",
        title: "Payment MIS",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/payment_received_detail_summary",
        title: "Payment MIS Summary",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/paymentHeads",
        title: "Payment MIS By Heads",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/NOPSummary",
        title: "Summary By Nature of Payment",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/cash_challan",
        title: "Cash Collection",
        icon: "",
        class: "",
        children: [],
      },
      {
        path: "/depositChallan",
        title: "Download Bank Challan",
        icon: "",
        class: "",
        children: [],
      },
    ],
    isOpen: false,
  },
  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [],
    isOpen: false,
  },
];
export var ROUTES11: RouteInfo[] = [
  {
    path: "/manage_Users",
    title: "Home",
    icon: "home",
    class: "",
    design: 0,
    id: 3,
    children: [ 
     ],
    isOpen: false,
  },
  {
    path: "",
    title: "Management",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 5,
    children: [ 
      {
        path: "/manageUsers",
        title: "Manage Users",
        icon: "people",
        class: "",
        children: [],
      },
     
      {
        path: "/reassign",
        title: "Reassign File",
        icon: "engineering",
        class: "",
        children: [],
      }, 
      {
        path: "/officerDetailsTransfer",
        // title: "TRANSFER/ LONG LEAVE",
        title: "Transfer/ Long Leave",
        icon: "admin_panel_settings",
        class: "",
        children: [],
      },
      {
        path: "/transferAllFiles",
        title: "Transfer All files",
        icon: "drive_file_move_outline",
        class: "",
        children: [],
      },
      {
        path: "/changeAdmin",
        title: "Change Admin",
        icon: "person",
        class: "",
        children: [],
      }, 
      // {
      //   path: "/manageSections",
      //   title: "Manage Sections",
      //   icon: "account_box",
      //   class: "",
      //   children: [],
      // },
     ],
    isOpen: false,
  },

  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [ 
     ],
    isOpen: false,
  }

];

export var ROUTES12: RouteInfo[] = [

 
  {
    path: "/import-ou",
    title: "Obligation Update ",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 1,
    children: [ 
   
     ],
    isOpen: false,
  },

  {
    path: "/custom-interface",
    title: "Customs Intimation",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 7,
    children: [ 
   
     ],
    isOpen: false,
  },
  
  {
    path: "/amendment",
    title: "Amendment ",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 1,
    children: [ 
   
     ],
    isOpen: false,
  },

  // {
  //   path: "/amendment-aa",
  //   title: "Amendment ",
  //   icon: "fact_check",
  //   class: "",
  //   design: 0,
  //   id: 12,
  //   children: [ 
   
  //    ],
  //   isOpen: false,
  // },
  
  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [ 
     ],
    isOpen: false,
  },      

  
];

//customs routing for level 3
export var ROUTESCustoms3: RouteInfo[] = [
  {
    path: "/registration_1",
    title: "Home",
    icon: "home",
    class: "",
    design: 0,
    id: 3,
    children: [ 
     ],
    isOpen: false,
  }, 
  {
    path: "/myProfile",
    title: "My Profile",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 3,
    children: [ 
     ],
    isOpen: false,
  },
  {
    path: "/registration1",
    title: "Registration   ",
    icon: "assignment",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },
 
  {
    path: "/communication-to-customs",
    title: "Communication To Customs",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 7,
    children: [ 
   
     ],
    isOpen: false,
  },
  
  {
    path: "/amendmentList",
    title: "Amendment ",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 1,
    children: [ 
   
     ],
    isOpen: false,
  },
  {
    path: "/proofOfPayment",
    title: "Validate Proof of Payment",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 2,
    children: [ 
   
     ],
    isOpen: false,
  },
  {
    path: "/cancellation1",
    title: "Cancellation   ",
    icon: "event_busy",
    class: "",
    design: 0,
    id: 3,
    children: [],
    isOpen: false,
  },
  {
    path: "/processorView",
    title: "Obligation Management   ",
    icon: "table_chart",
    class: "",
    design: 0,
    id: 3,
    children: [],
    isOpen: false,
  },
  {
    path: "",
    title: "SCN & Recovery MIS",
    icon: "analytics",
    class: "",
    design: 0,
    id: 3,
    children: [
      {
        path: "/showCause",
        title: "SCN Complete Pendency",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/scnAdj1",
        title: "SCN Pending Adjudication",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/scnRecovery1",
        title: "SCN Adjudicated & Amount Due",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/monthRecovery",
        title: "Month Wise Recovery",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/revenueStake",
        title: "Revenue At Stake",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
    ],
    isOpen: false,
  },
    {
    path: "/searchCase",
    title: "Search Case",
    icon: "manage_search",
    class: "",
    design: 0,
    id: 5,
    children: [],
    isOpen: false,
  },
  {
    path: "/payment_acceptance",
    title: "Payment E-Challan",
    icon: "currency_rupees",
    class: "",
    design: 0,
    id: 5,
    children: [],
    isOpen: false,
  },
  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [],
    isOpen: false,
  },    

  

];


//Customs routing for level 2
export var ROUTESCustoms2: RouteInfo[] = [
  {
    path: "/registration_1",
    title: "Home",
    icon: "home",
    class: "",
    design: 0,
    id: 3,
    children: [ 
     ],
    isOpen: false,
  }, 
  {
    path: "/myProfile",
    title: "My Profile",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 3,
    children: [ 
     ],
    isOpen: false,
  },
  {
    path: "/registration1",
    title: "Registration   ",
    icon: "assignment",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },
 
  {
    path: "/communication-to-customs",
    title: "Communication To Customs",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 7,
    children: [ 
   
     ],
    isOpen: false,
  },
  
  {
    path: "/amendmentList",
    title: "Amendment ",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 1,
    children: [ 
   
     ],
    isOpen: false,
  },
  {
    path: "/proofOfPayment",
    title: "Validate Proof of Payment",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 2,
    children: [ 
   
     ],
    isOpen: false,
  },
  {
    path: "/cancellation1",
    title: "Cancellation   ",
    icon: "event_busy",
    class: "",
    design: 0,
    id: 3,
    children: [],
    isOpen: false,
  },
  {
    path: "/processorView",
    title: "Obligation Management   ",
    icon: "table_chart",
    class: "",
    design: 0,
    id: 3,
    children: [],
    isOpen: false,
  },
  {
    path: "",
    title: "SCN & Recovery MIS",
    icon: "analytics",
    class: "",
    design: 0,
    id: 3,
    children: [
      {
        path: "/showCause",
        title: "SCN Complete Pendency",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/scnAdj1",
        title: "SCN Pending Adjudication",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/scnRecovery1",
        title: "SCN Adjudicated & Amount Due",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/monthRecovery",
        title: "Month Wise Recovery",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/revenueStake",
        title: "Revenue At Stake",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
    ],
    isOpen: false,
  },
    {
    path: "/searchCase",
    title: "Search Case",
    icon: "manage_search",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },

  // {
  //   path: "",
  //   title: "Search  ",
  //   icon: "manage_search",
  //   class: "",
  //   design: 0,
  //   id: 1,
  //   children: [],
  //   isOpen: false,
  // },


  
  // {
  //   path: "",
  //   title: "Search  ",
  //   icon: "manage_search",
  //   class: "",
  //   design: 0,
  //   id: 1,
  //   children: [],
  //   isOpen: false,
  // },

  // {
  //   path: "/payment_acceptance",
  //   title: "Payment",
  //   icon: "currency_rupees",
  //   class: "",
  //   design: 0,
  //   id: 5,
  //   children: [],
  //   isOpen: false,
  // },

  // {
  //   path: "/payment_daily_mis",
  //   title: "Payment MIS",
  //   icon: "receipt",
  //   class: "",
  //   design: 0,
  //   id: 5,
  //   children: [
    
  //     {
  //       path: "/payment_daily_summary",
  //       title: "Summary",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/payment_received_summary",
  //       title: "Payment MIS",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/payment_received_detail_summary",
  //       title: "Payment MIS Summary",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/paymentHeads",
  //       title: "Payment MIS By Heads",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/NOPSummary",
  //       title: "Summary By Nature of Payment",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/cash_challan",
  //       title: "Cash Collection",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/depositChallan",
  //       title: "Download Bank Challan",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //   ],
  //   isOpen: false,
  // },

  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [],
    isOpen: false,
  },    

  
];

//customs routing for level 1
export var ROUTESCustoms1: RouteInfo[] = [
  {
    path: "/registration_1",
    title: "Home",
    icon: "home",
    class: "",
    design: 0,
    id: 3,
    children: [ 
     ],
    isOpen: false,
  }, 
  {
    path: "/myProfile",
    title: "My Profile",
    icon: "dashboard",
    class: "",
    design: 0,
    id: 3,
    children: [ 
     ],
    isOpen: false,
  },
  {
    path: "/registration1",
    title: "Registration   ",
    icon: "assignment",
    class: "",
    design: 0,
    id: 1,
    children: [],
    isOpen: false,
  },
 
  {
    path: "/communication-to-customs",
    title: "Communication To Customs",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 7,
    children: [ 
   
     ],
    isOpen: false,
  },
  
  {
    path: "/amendmentList",
    title: "Amendment ",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 1,
    children: [ 
   
     ],
    isOpen: false,
  },
  {
    path: "/proofOfPayment",
    title: "Validate Proof of Payment",
    icon: "fact_check",
    class: "",
    design: 0,
    id: 2,
    children: [ 
   
     ],
    isOpen: false,
  },
  {
    path: "/cancellation1",
    title: "Cancellation   ",
    icon: "event_busy",
    class: "",
    design: 0,
    id: 3,
    children: [],
    isOpen: false,
  },
  {
    path: "/processorView",
    title: "Obligation Management   ",
    icon: "table_chart",
    class: "",
    design: 0,
    id: 3,
    children: [],
    isOpen: false,
  },
  {
    path: "",
    title: "SCN & Recovery MIS",
    icon: "analytics",
    class: "",
    design: 0,
    id: 3,
    children: [
      {
        path: "/showCause",
        title: "SCN Complete Pendency",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/scnAdj1",
        title: "SCN Pending Adjudication",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/scnRecovery1",
        title: "SCN Adjudicated & Amount Due",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/monthRecovery",
        title: "Month Wise Recovery",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
      {
        path: "/revenueStake",
        title: "Revenue At Stake",
        icon: "analytics",
        class: "",
        design: 0,
        id: 3,
        children: [],
        isOpen: false,
      },
    ],
    isOpen: false,
  },
    {
    path: "/searchCase",
    title: "Search Case",
    icon: "manage_search",
    class: "",
    design: 0,
    id: 5,
    children: [],
    isOpen: false,
  },

  // {
  //   path: "",
  //   title: "Search  ",
  //   icon: "manage_search",
  //   class: "",
  //   design: 0,
  //   id: 1,
  //   children: [],
  //   isOpen: false,
  // },

  // {
  //   path: "/payment_acceptance",
  //   title: "Payment",
  //   icon: "currency_rupees",
  //   class: "",
  //   design: 0,
  //   id: 5,
  //   children: [],
  //   isOpen: false,
  // },
  // {
  //   path: "/payment_daily_mis",
  //   title: "Payment MIS",
  //   icon: "receipt",
  //   class: "",
  //   design: 0,
  //   id: 5,
  //   children: [
    
  //     {
  //       path: "/payment_daily_summary",
  //       title: "Summary",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/payment_received_summary",
  //       title: "Payment MIS",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/payment_received_detail_summary",
  //       title: "Payment MIS Summary",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/paymentHeads",
  //       title: "Payment MIS By Heads",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/NOPSummary",
  //       title: "Summary By Nature of Payment",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/cash_challan",
  //       title: "Cash Collection",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //     {
  //       path: "/depositChallan",
  //       title: "Download Bank Challan",
  //       icon: "",
  //       class: "",
  //       children: [],
  //     },
  //   ],
  //   isOpen: false,
  // },
 
  {
    path: "/login",
    title: "Log Out",
    icon: "unarchive",
    class: "",
    design: 0,
    id: 4,
    children: [],
    isOpen: false,
  },    

  
];


@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[]=[];
  subMenuItem: any[] = [];
  opened = true;
  user_name: any;
  isOpen = false;
  isOpen1 = false;
  isOpen2 = false;
  level: any;
  username: any;
  role: any;

  // username: string;
  constructor() {}

  ngOnInit() {
    let localstorageCurrentUser : any = localStorage.getItem("currentUser")
    var cuser = JSON.parse(localstorageCurrentUser);
    this.username = cuser.obj.user_name;
    
    this.menuItems = ROUTES8.filter((menuItem) => menuItem);
    let payment = localStorage.getItem("paymentGuest");
    if (payment == "true") {
      this.menuItems = ROUTES9.filter((menuItem) => menuItem);
    }
    if (cuser !== null) {
      this.username = cuser.obj.username;
      this.role = cuser.obj.role;
      if (this.role != 0) {
        //ONLY PAYMENT LOGIN
        if (this.role == 10) {
          if(cuser.obj.level == 'L2'){
            this.menuItems = ROUTESPayment2.filter((menuItem) => menuItem);
          } else{
            this.menuItems = ROUTES10.filter((menuItem) => menuItem);
          }
          
        }
       
       
        //ADMIN SECTION
        else if(this.role == 11){
          this.menuItems = ROUTES11.filter((menuItem) => menuItem);
        }
      
        //1 FOR CBCHA AND 3 FOR IEC HOLDER LOGIN
        else if(this.role == 3 || this.role == 1){
        //  localStorage.removeItem('application_id');
        //  localStorage.removeItem('applicationType');
        //  localStorage.removeItem('aaheader');
        //  localStorage.removeItem("history")
            this.menuItems = ROUTES7.filter((menuItem) => menuItem);
          
         

        } else if(this.role == 2){
          if(cuser.obj.level == 'L2'){
            this.menuItems = ROUTESCustoms2.filter((menuItem) => menuItem);
          }
          else if(cuser.obj.level == 'L3'){
            this.menuItems = ROUTESCustoms3.filter((menuItem) => menuItem);
          }
           else{
            this.menuItems = ROUTESCustoms1.filter((menuItem) => menuItem);
          }
        }
        else  {
          this.menuItems = ROUTES8.filter((menuItem) => menuItem);
        }
      } else {
      }
    } else {
      if (this.role === 9) {
        this.menuItems = ROUTES8.filter((menuItem) => menuItem);
      } else {
        this.menuItems = ROUTES9.filter((menuItem) => menuItem);
      }
    }
    // this.menuItems = ROUTES.filter((menuItem) => menuItem);

    // this.level = cuser.obj.level;
    // if (this.level == "4") {
    //   this.menuItems = ROUTES1.filter((menuItem) => menuItem);
    // }
    // else if(this.level == "3" || this.level == "2"){
    //   this.menuItems = ROUTES2.filter((menuItem) => menuItem);
    // }
    //  else {
    // }

    // if (cuser.obj.designation == "5") {
    //   this.menuItems = ROUTES.filter(menuItem => menuItem);
    // } else if (cuser.obj.designation == "6") {
    //   this.menuItems = ROUTES.filter(menuItem => menuItem.design == 0 || menuItem.design == 6);
    // } else {
    //   this.menuItems = ROUTES.filter(menuItem => menuItem.design == 0);
    // }
  }

  togleSubMenu(event: any, a: string, menuItem: any) {
    console.log("event : ", event.target.nextElementSibling);
    console.log("menuItem:", menuItem);
    console.log("Event:", event);
    console.log("Flag:", a);
    localStorage.setItem("offset", JSON.stringify(1));
    localStorage.setItem("pageSize", JSON.stringify(null));
    localStorage.removeItem('cancellation_id');
    localStorage.removeItem('application_id');
    if (this.menuItems != undefined) {
      if (this.menuItems != null) {
        this.menuItems.forEach((menu) => {
          if (menu.id == menuItem.id) {
            if (a == "1") {
              menu.isOpen = true;
            } else {
              menu.isOpen = false;
            }
          } else {
            menu.isOpen = false;
          }
        });
      }
    }

    console.log("update menuItem:", this.menuItems);

    // if (this.isOpen) {
    //   if (a == "1") {
    //     this.isOpen = false;
    //   } else {
    //     this.isOpen = false;
    //   }
    // } else {
    //   if (a == "1") {
    //     this.isOpen = false;
    //   } else {
    //     this.isOpen = true;
    //   }
    // }
  }

  isMobileMenu() {
    // if ($(window).width() > 991) {
    //   return false;
    // }
    return true;
  }
}
