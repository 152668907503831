import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { BehaviorSubject, Observable, from, throwError } from "rxjs";
import { AuthenticationService } from "./_services";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";
// import "rxjs/add/operator/toPromise";
const TOKEN_HEADER_KEY = "Authorization";

@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {


  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authenticationService: AuthenticationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {

    console.log(">>>>>>URL : ", req.url);
    let loginUrl = 
    "https://oms.accmumbai.gov.in/customs1.1/userLogin"
    // "https://omsgate.sensez9.tech/customs1.0UAT/userLogin";
    let refreshTokenUrl =
    "https://oms.accmumbai.gov.in/customs1.1/refreshToken"
      // "https://omsgate.sensez9.tech/customs1.0UAT/refreshToken";


    if (req.url.trim() == loginUrl.trim()) {
      return next.handle(req).pipe(catchError(error => {
        console.log(" inside handle error : ", error);
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(req, next);
        }

        return throwError(error);
      }));;
    }
    //making sure refresh token url is called only once
    else if (req.url.trim() == refreshTokenUrl.trim()) {
      // console.log("rewq : ", req )
      return next.handle(req);
    } else if (req.url.includes("/api/v1/")) {
      let authReq = req;
      const token = this.authenticationService.getAccessToken();
      if (token != null) {
        authReq = this.addTokenHeader(req, token);
      }

      return next.handle(authReq).pipe(catchError(error => {
        console.log(" inside handle error : ", error);
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(authReq, next);
        }

        return throwError(error);
      }));
    }
    else {
      return next.handle(req);
    }

  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const token = this.authenticationService.getRefToken();
      
      if (token)
      console.log("refrssh token : ", token)

      this.authenticationService.refreshToken(token).subscribe((resp) => {

        console.log("--------------------")
        console.log("resp : ", resp)
        if(resp.status === 401) {
          this.authenticationService.logout();  
        }
        this.isRefreshing = false;
        console.log("new token : ", resp.obj)
        this.authenticationService.saveToken(resp.obj);
        this.refreshTokenSubject.next(resp.obj.access_token);
        
        return next.handle(this.addTokenHeader(request, resp.obj.access_token));
        // location.reload();
      },(error) => {
        this.authenticationService.logout();
      });
            
            
      // });
        // return this.authenticationService.refreshToken(token).pipe(
        //   switchMap((token: any) => {
        //     this.isRefreshing = false;
        //     console.log("new token : ", token)
        //     this.authenticationService.saveToken(token.accessToken);
        //     this.refreshTokenSubject.next(token.accessToken);
            
        //     return next.handle(this.addTokenHeader(request, token.access_token));
        //   }),
        //   catchError((err) => {
        //     // console.log("error in catch : ", err)
        //     this.isRefreshing = false;
            
        //     // this.authenticationService.logout();
        //     return throwError(err);
        //   })
        // );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    /* for Spring Boot back-end */
    // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });

    /* for Node.js Express back-end */
    return request.clone({ headers: request.headers.set("Authorization", "Bearer "+ token) });
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorInterceptor, multi: true }
];