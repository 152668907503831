import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appNumberAndSpecialCharDirective]'
})
export class NumberAndSpecialCharDirectiveDirective {
  //  ^[a-zA-Z0-9_]*$
  // '^[ A-Za-z0-9\\\\,./_-]*$'
  
  regexStr = '^[0-9.,\\/-]+$'
  // regexStr = '^(\\,_-.*?[1-9])[0-9()-]+$';
  @Input() isAlphaNumeric: boolean;

  constructor(private el: ElementRef,private control : NgControl) { }


  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      // this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^ A-Za-z0-9._@-]/g, '').replace(/\s/g, '');
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^0-9.,/-]/g, '');
      this.control.control.setValue(this.el.nativeElement.value);
      event.preventDefault();

    }, 100)
  }

}
