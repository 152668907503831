import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DateAdapter } from 'angular-calendar';
import { BlockchainService } from '../blockchain.service';
import { AlertService } from 'app/_services';
import { NgForm } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  usernameForm = this.formBuilder.group({
    email: ["", Validators.required],
    otp:["",[Validators.required, Validators.minLength(6), 
      Validators.maxLength(6),Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
    password:["",Validators.required],

  });
  isValidUser: boolean=false;
  isOtpValidated: boolean=false;
  enableTimer: boolean;
  enableResend: boolean;
  display: string;
  enableCbBtn: boolean;
  token: string|undefined;
  constructor( private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    private alertService: AlertService,
    public blockchainService: BlockchainService,
    ) {
      this.token = undefined;
    }

  ngOnInit(): void {
  }
  get f() {
    return this.usernameForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.usernameForm.controls[controlName].hasError(errorName);
  };
  public sendGenerateOtp(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }else{
      this.usernameForm.value.secret_key = this.token;
     this.generateOtp()
    }
    console.log(this.token)
    console.debug(`Token [${this.token}] generated`);
}

public sendVerifyOtp(form: NgForm): void {
  if (form.invalid) {
    for (const control of Object.keys(form.controls)) {
      form.controls[control].markAsTouched();
    }
    return;
  }else{
    this.usernameForm.value.secret_key = this.token;
   this.verifyOtp()
  }
  console.log(this.token)
  console.debug(`Token [${this.token}] generated`);
}

public sendResetPassword(form: NgForm): void {
  if (form.invalid) {
    for (const control of Object.keys(form.controls)) {
      form.controls[control].markAsTouched();
    }
    return;
  }else{
    this.usernameForm.value.secret_key = this.token;
   this.resetPassword()
  }
  console.log(this.token)
  console.debug(`Token [${this.token}] generated`);
}

  generateOtp(){
    if(this.usernameForm.value.email==''){
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Username cannot be empty.",
        "announcement"
      );
    }else{
      let input={
        email:this.usernameForm.value.email,
        secret_key:this.usernameForm.value.secret_key
      }
      this.blockchainService.sendOtpResetPass(input).subscribe((resp) => {
           console.log(resp.body.obj)
            if (resp.body.status == 200) {
              this.isValidUser=true
              this.enableTimer = true;
              this.token=undefined;
              this.timer(1);
              this.enableCbBtn = true;
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.body.message,
                "announcement"
              );
             
            }else{
              this.isValidUser=false     
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.body.message,
                "announcement"
              );
              
            }
          },
          (error) => {
            console.log(error)
            this.alertService.error(error);
            if(error.status == 401){
              location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Session Expired, Please Login Again",
                "announcement"
              );
              this.router.navigate(["/login"]);
            }
            else if(error.status == 500){
              setTimeout(() => {
                // this.blockUI.stop();
              }, 1500);
              location.reload();
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Try Again",
                "announcement"
              );
            }
            else{}
          }
        );
    }
  }

  verifyOtp(){
    if(this.usernameForm.value.email==''){
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Username cannot be empty.",
        "announcement"
      );
    }else{
      let input={
        email:this.usernameForm.value.email,
        password:this.usernameForm.value.otp,
        secret_key:this.usernameForm.value.secret_key
      }
      this.blockchainService.verifyOtpResetPass(input).subscribe((resp) => {
        console.log(resp.body.obj)
         if (resp.body.status == 200) {
          this.token=undefined;
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
            this.isValidUser=false
            this.isOtpValidated=true
         }
         else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
         }
        },
        (error) => {
          console.log(error)
          this.alertService.error(error);
          if(error.status == 401){
            location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Session Expired, Please Login Again",
              "announcement"
            );
            this.router.navigate(["/login"]);
          }
          else if(error.status == 500){
            setTimeout(() => {
              // this.blockUI.stop();
            }, 1500);
            location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Try Again",
              "announcement"
            );
          }
          else{}
        }
        )

    }
  }


  resetPassword(){
    if(this.usernameForm.value.email==''){
      this.showNotification(
        "bottom",
        "right",
        "success",
        "Username cannot be empty.",
        "announcement"
      );
    }else{
      let input={
        email:this.usernameForm.value.email,
        password:this.usernameForm.value.password,
        secret_key:this.usernameForm.value.secret_key
      }
      this.blockchainService.resetPassword(input).subscribe((resp) => {
        console.log(resp.body.obj)
         if (resp.body.status == 200) {
          this.token=undefined;
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.router.navigate(["/login"]);
         }
         else{
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
         }
        },
        (error) => {
          console.log(error)
          this.alertService.error(error);
          if(error.status == 401){
            location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Session Expired, Please Login Again",
              "announcement"
            );
            this.router.navigate(["/login"]);
          }
          else if(error.status == 500){
            setTimeout(() => {
              // this.blockUI.stop();
            }, 1500);
            location.reload();
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Try Again",
              "announcement"
            );
          }
          else{}
        }
        )

    }
  }

  showNotification(from, align, color, stringMessage, icons) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }

  timer(minute) {
    // let minute = 1;
    this.enableResend = true;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.enableResend = false;
        console.log("finished");
        clearInterval(timer);
      }
    }, 1000);
  }

}
