import { Component, OnInit,VERSION } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertService, AuthenticationService } from "../_services/index";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { JsonPipe } from "@angular/common";
// import { BoshClient, $build } from "xmpp-bosh-client/browser";
import { NgForm } from '@angular/forms';

declare var $: any;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string = "";
  message: string = "";
  err: string ="";
  public selected : any;
  public isje = "";
  loginForm: UntypedFormGroup  
  notiColor: any = "#06ffff";
  // client : BoshClient;

  token: string|undefined;
  showLoginButton: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
  ) {
    this.token = undefined;
  }

  // secret_key
  ngOnInit(): void {
    this.logout()
    // this.authenticationService.logout();
    
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    });
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/";
   
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.loginForm.controls;
  }

  public send(form: NgForm): void {
    if (form.invalid) {
      for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsTouched();
      }
      return;
    }else{
      this.loginForm.value.secret_key = this.token;
     this.login()
    }
    console.log(this.token)
    console.debug(`Token [${this.token}] generated`);
}

 

  signUp() {
    console.log("inside signup fn");
    this.router.navigate(["/signUp"]);
  }

  paymentGuest(){
    let guestUser = {
      "status":200,"message":"Login Sucessfull","obj":{"username":"Guest User","access_token":"90d00968-55c3-491e-b429-5c403552fbf4","token_type":"bearer","refresh_token":"9dc2f806-368b-4353-b0d8-c6b5e14dddda","expires_in":"11053","updated_at":"2022-05-30 20:20:26","created_at":"2022-05-30 20:20:26","user_id":0,"role":"0","role_name":"","level":null}
    }
    localStorage.setItem('paymentGuest','true');
    localStorage.setItem('currentUser',JSON.stringify(guestUser));
    this.router.navigate(["/welcome"])
  }

  navigateToForgetPassword(){
    this.router.navigate(["/forget-password"])
  }
  logout(){
    let localStorageCurrentUser:any  = localStorage.getItem("currentUser")
   let currentUser = JSON.parse(localStorageCurrentUser);
    console.log(currentUser)
    if(currentUser != null){
    this.authenticationService.userLogout(currentUser.obj.username).subscribe(
      (data) => {
        // console.log(data)
        if (data.status == 200) {
          this.authenticationService.logout();
        }
      })
    }
  }
  login() {
    // if(action == 'paymentGuest'){
    //   this.paymentGuest();
    //     }
    // else{
      if (this.loginForm.invalid) {
        return;
      } else {
        this.loading = true;
        this.authenticationService.login(this.loginForm.value).subscribe(
          (data:any) => {
            
            if (data.status == 200) {
            
              // this.router.navigate(['/signUp']);
              //store the date and timestamp in localstorage
              let newDate = new Date().getTime()
              data.obj.login_date = newDate;
              
              localStorage.setItem("currentUser", JSON.stringify(data));
              localStorage.setItem("isLoggedIn", "true");
              localStorage.setItem('paymentGuest','false');

              // if(data.obj.role=="1"){
                if(data.obj.role=="1"|| data.obj.role=="3"){
                this.router.navigate(["/welcomeUser"]);
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  "Login Successful",
                  "announcement"
                );
              }
  
              if(data.obj.role==2){
                // this.router.navigate(["/registration1"]);
                this.router.navigate(["/registration_1"]);
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                  "Login Successful",
                  "announcement"
                );
              }
              if(data.obj.role==4 ||data.obj.role==6){
                this.router.navigate(["/view1"]);
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Login Successful",
                    "announcement"
                  );
              }
              if(data.obj.role==11){
                this.router.navigate(["/manage_Users"]);
                // this.router.navigate(["/manageUsers"]);
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Login Successful",
                    "announcement"
                  );
              }
              if(data.obj.role==5){
                this.router.navigate(["/registerLicenseTa"]);
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Login Successful",
                    "announcement"
                  );
              }
              if(data.obj.role==7){
                this.router.navigate(["/endorse"]);
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Login Successful",
                    "announcement"
                  );
              }
              if(data.obj.role==8){
                this.router.navigate(["/validateDc"]);
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Login Successful",
                    "announcement"
                  );
              }if(data.obj.role==9){
                this.router.navigate(["/bbgReciept"]);
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Login Successful",
                    "announcement"
                  );
              }
              if(data.obj.role==10){

                if(data.obj.level == 'L2'){
                  this.router.navigate(["/payment_daily_summary"]);
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Login Successful",
                    "announcement"
                  );
                } else{
                  // this.router.navigate(["/payment_acceptance"]);
                  this.router.navigate(["/paymentAcceptance"]);
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                    "Login Successful",
                    "announcement"
                  );
                }
               
              }

              
            } else {
              this.alertService.error(data.message);
              console.log("fail", data.message);
              this.loading = false;
              this.showNotification(
                "bottom",
                "right",
                "warning",
                data.message,
                "announcement"
              );
            }
          },
          (error) => {
            this.alertService.error(error);
            console.log("fail", error);
            this.loading = false;
            this.showNotification(
              "bottom",
              "right",
              "warning",
              error.message,
              "announcement"
            );
          }
        );
      }
    // }
   
  }

  // forgetPassword() {
  //     this.router.navigate(['/reset']);
  // }
  showNotification(from: string, align: string, color: any, stringMessage: string, icons: string) {
    const type = ["", this.notiColor, "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 5000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4' +
          ' alert alert-{0} alert-with-icon" role="alert"><button mat-button  type="butto' +
          'n" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i clas' +
          's="material-icons">close</i></button><i class="material-icons" data-notify="ic' +
          'on">notifications</i> <span data-notify="title">{1}</span> <span data-notify="' +
          'message">{2}</span><div class="progress" data-notify="progressbar"><div class=' +
          '"progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valu' +
          'emin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" targ' +
          'et="{4}" data-notify="url"></a></div>',
      }
    );
  }
}
