import { Overlay } from "@angular/cdk/overlay";
import { DatePipe } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { Console } from "console";
import { BlockUI, NgBlockUI } from "ng-block-ui";

declare var $: any;

export interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-addimp-aa",
  templateUrl: "./addimp-aa.component.html",
  styleUrls: ["./addimp-aa.component.css"],
})
export class AddimpAAComponent implements OnInit {
  pipe = new DatePipe("en-US");

  selectedStatus = "";

  allStatus: Status[] = [
    { value: "YES", viewValue: "YES" },
    { value: "NO", viewValue: "NO" },
  ];

  @BlockUI() blockUI: NgBlockUI| any;
  blockTemplate: BlockTemplateComponent | any;
  role: any;
  authorizationDate: any;
  impDate: any;
  expDate: any;

  addImpAAForm = this.formBuilder.group({
    //impSrNo: [""],
    inputDescription: ["",Validators.required],
    technicalFeatures: ["",Validators.required],
    expSrNo: ["",Validators.required],
    itchsCode: ["",Validators.required],
    quantity: ["",Validators.required],
    uom: ["",Validators.required],
    cifInr: ["",Validators.required],
    cifForeign: ["",Validators.required],
  });

  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean | any;
  date: string | any;
  modifiedCurrentDate: string | any;
  lastDate: any;
  isaaForm2Enable: boolean = false;
  isUpdate: boolean | any;
  updateData: any;
  flag: boolean | any;
  item_id: any;
  applicationType: string | any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    let localstorageCurrentUser: any = localStorage.getItem("currentUser")
    this.currentUser = JSON.parse(localstorageCurrentUser);
    this.applicationType = localStorage.getItem('applicationType')

    if (this.route.snapshot.params["update"] == "true") {
      this.isUpdate = true;
      this.updateData = this.route.snapshot.params;
      this.addImpAAForm.patchValue({
        //impSrNo: this.updateData.import_serial_number,
        inputDescription: this.updateData.input_description,
        // technicalFeatures: this.updateData.,
        expSrNo: this.updateData.export_serial_number,
        itchsCode: this.updateData.itchs_code,
        quantity: this.updateData.quantity,
        uom: this.updateData.uom,
        cifInr: this.updateData.cif_inr,
        cifForeign: this.updateData.cif_foreign,
      });
    }
  }

 

  dateMselect(type: string, event: MatDatepickerInputEvent<Date>) {
    this.date = this.pipe.transform(event.value, "yyyy-MM-dd");
    this.modifiedCurrentDate = this.date;
  }

  formatDate(date: string | number | Date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      year = d.getFullYear(),
      day = "" + d.getDay();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }

 

  back() {
    this.router.navigate(["/importAA"]);
  }

  next() {
    this.saveOrUpdate();
   
  }

  save() {
    this.saveOrUpdate();
  }

  get f() {
    return this.addImpAAForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.addImpAAForm.controls[controlName].hasError(errorName);
  };

  saveOrUpdate() {
    this.blockUI.start("Please wait this may take few seconds ...");
    if ( this.item_id == undefined &&
     ( this.updateData == undefined  || this.updateData.item_id == undefined ||
      this.updateData.item_id == null )
    ) {
      let input = {
       // import_serial_number: this.addImpAAForm.value.impSrNo,
        export_serial_number: this.addImpAAForm.value.expSrNo,
        input_description: this.addImpAAForm.value.inputDescription,
        technical_features: this.addImpAAForm.value.technicalFeatures,
        itchs_code: this.addImpAAForm.value.itchsCode,
        quantity: this.addImpAAForm.value.quantity,
        uom: this.addImpAAForm.value.uom,
        cif_inr: this.addImpAAForm.value.cifInr,
        cif_foreign: this.addImpAAForm.value.cifForeign,
        application_id: localStorage.getItem("application_id"),
      };
      this.blockchainService.saveItemImported(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.item_id = resp.body.obj
          this.router.navigate(["/importAA"]);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.flag = true;
        } else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      });
    } else {
      let input = {
        item_id: this.item_id? this.item_id :  this.updateData.item_id  ,
        //import_serial_number: this.addImpAAForm.value.impSrNo,
        export_serial_number: this.addImpAAForm.value.expSrNo,
        input_description: this.addImpAAForm.value.inputDescription,
        technical_features: this.addImpAAForm.value.technicalFeatures,
        itchs_code: this.addImpAAForm.value.itchsCode,
        quantity: this.addImpAAForm.value.quantity,
        uom: this.addImpAAForm.value.uom,
        cif_inr: this.addImpAAForm.value.cifInr,
        cif_foreign: this.addImpAAForm.value.cifForeign,
        application_id: localStorage.getItem("application_id"),

      };
      this.blockchainService.updateItemImported(input).subscribe((resp) => {
        if (resp.body.status == 200) {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.router.navigate(["/importAA"]);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
          this.flag = true;
        } else {
          setTimeout(() => {
            this.blockUI.stop();
          }, 1500);
          this.showNotification(
            "bottom",
            "right",
            "success",
            resp.body.message,
            "announcement"
          );
        }
      });
    }
  }



  showNotification(from: string, align: string, color: any, stringMessage: any, icons: string) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
