import { Overlay } from "@angular/cdk/overlay";
import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { DateAdapter } from "@angular/material/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { BlockchainService } from "app/blockchainImpExp/blockchain.service";
import { BlockTemplateComponent } from "app/widgets/block-template/block-template.component";
import { AlertService } from "app/_services";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { PaymentsumComponent } from "../../payment/paymentsum/paymentsum.component";

declare var $: any;

export interface roleType {
  id: string;
  name: string;
}

@Component({
  selector: 'app-appln-type-role',
  templateUrl: './appln-type-role.component.html',
  styleUrls: ['./appln-type-role.component.css']
})
export class ApplnTypeRoleComponent implements OnInit {
  selectedStatus = "";
  color = 'primary';
  backgroundColor = '#64c153'
  allRoleType: roleType[] = [
    { id: "IEC", name: "IEC" },
    { id: "Customs Broker", name: "Customs Broker" },
  ];

  @BlockUI() blockUI: NgBlockUI | any;
  blockTemplate: BlockTemplateComponent| any;
  role: any;

  registrationForm = this.formBuilder.group({
    applicationType: ["", Validators.required],
    role: [""],
    auth_number:  ["", Validators.required],
    iecNo:  ["", [ Validators.required,Validators.minLength(10),
      Validators.maxLength(10),
      Validators.pattern("^[a-zA-Z0-9]{10}$")]],
  });



  currentUser: any;
  caseDetailsData: any;
  isButtonDisabled: boolean | any;
  selectedFile: File | any;
  url: string | ArrayBuffer | any;
  allApplicationTypes: any;
  applicationType: any;
  selectedFile1: File | any;
  applicationIdResp: any;
  showPdf: boolean = false;
  aaPath: any;
  iecPath: any;
  showIECPdf: boolean = false;
  pdfBaseUrl: string| any;
  isNext: boolean = false;
  auth_number: any;
  iecNo: any;
  urlaa: string | ArrayBuffer| any;
  urlIec: string | ArrayBuffer| any;
  captureFile:any;
  imageToUpload: File | any = null;
  selectedImage: string = 'https://dummyimage.com/318x200/000/fff';
  selectedPdf: string = 'https://dummyimage.com/318x200/000/fff';

  caption = 'Choose an image';
  
  captionPdf = 'Choose a PDF';
  sectionViewLetterDetails: any;
  url1: any;
  appId: string| any;
  img1Value: any;
  selectedFileUrl: boolean=false;
  selectedFileUrl1: boolean=false;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private overlay: Overlay,
    public dialog: MatDialog,
    private alertService: AlertService,
    public blockchainService: BlockchainService,
    private dateAdapter: DateAdapter<Date>
    ) {
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    }

  ngOnInit(): void {
    let localstorageCurrentUser:any = localStorage.getItem("currentUser")
    this.currentUser = JSON.parse(localstorageCurrentUser);
    let history = localStorage.getItem("history")
    // if(history = 'history'){
      this.applicationType = localStorage.getItem("applicationType");
      this.appId=localStorage.getItem('application_id');
    // } else{
      
    //   localStorage.removeItem('applicationType')
    //   localStorage.removeItem('application_id')
    // }
    this.applicationType = localStorage.getItem("applicationType");
    this.appId=localStorage.getItem('application_id');
    // console.log(this.appId);
    // console.log(this.currentUser.obj.user_id);
    if (this.currentUser.obj.role == '3') {
      this.role = "IEC"
    } else if (this.currentUser.obj.role == '1') {
      this.role = "Customs Broker"
    } else { }
    this.getApplicationType();
    localStorage.removeItem('application_id')
    localStorage.removeItem('bond_id')
    localStorage.removeItem('aaHeader')
    localStorage.removeItem('auth_number')
    localStorage.removeItem('iec_value')
    localStorage.removeItem('iec_application_id')
    localStorage.removeItem('iec_no')
    localStorage.removeItem('auth_id')
    localStorage.removeItem('applicationType')
    localStorage.removeItem('aa_details1')
    localStorage.removeItem('bondForm')
    this.getApplicationById(this.appId);
    // this.pdfBaseUrl = "https://oms.accmumbai.gov.in/customs1.0/api/v1/downloadDocument/"
   //UAT URL IS SENSEZ9
    this.pdfBaseUrl = this.blockchainService.docDownload;
    this.registrationForm.patchValue({
      // aa_file: this.applicationIdResp.aa_path,
      // iec_file: this.applicationIdResp.iec_certificate_path,
      // applicationType: this.applicationIdResp.application_type_name,
      role: this.role,

    });
  }

  getApplicationType() {
    this.blockchainService.getApplicationType().subscribe((resp) => {
      this.allApplicationTypes = resp.obj;
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
     
    });
  }
  get f() {
    return this.registrationForm.controls;
  }
  public hasError = (controlName: string, errorName: string) => {
    return this.registrationForm.controls[controlName].hasError(errorName);
  };
  getApplicationById(appId: string) {
    let input = {
      application_id: appId
    }
    this.blockchainService.getApplicationById(input).subscribe(resp => {
      this.applicationIdResp = resp.body.obj;
     
      localStorage.setItem('applicationType', this.applicationIdResp.application_type_name)
      localStorage.setItem('application_id',this.applicationIdResp.application_id);
      if (this.applicationIdResp.aa_path != '' && this.applicationIdResp.aa_path != null) {
        this.showPdf = true;
      } else {
        this.showPdf = false
      }
      if (this.applicationIdResp.iec_certificate_path != '' && this.applicationIdResp.iec_certificate_path != null) {
        this.showIECPdf = true;
      } else {
        this.showIECPdf = false
      }
      this.aaPath = this.applicationIdResp.aa_path;
      this.selectedFile1 = this.aaPath;

      this.iecPath = this.applicationIdResp.iec_certificate_path;
      this.selectedFile = this.iecPath;

      this.role = this.currentUser.obj.role_name
      
      this.registrationForm.patchValue({
        iecNo:this.applicationIdResp.iec_code,
        auth_number:this.applicationIdResp.license_no,
        aa_file: this.applicationIdResp.aa_path,
        iec_file: this.applicationIdResp.iec_certificate_path,
        applicationType: this.applicationIdResp.application_type_name,
        role: this.role,

      });
      localStorage.removeItem('application_id');


    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        location.reload();
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
        location.reload();
      }
      else{}
    })
  }

  selectedApplicationType(event: { isUserInput: any; }, applicationType: string) {
    if (event.isUserInput) {
      console.log(event);
      this.applicationType = applicationType;
      this.registrationForm.value.applicationType = applicationType;
      localStorage.setItem('applicationType', applicationType)
      // this.selectedCityName = "";
      // location.reload();
    }
  }

  selectedRole(event: { isUserInput: any; }, role: any) {
    if (event.isUserInput) {
      console.log(event);
      this.role = role;
      this.registrationForm.value.role = role;
      
      // this.selectedCityName = "";
    }
  }

  checkIecNo(e: any){
    console.log(e.target.value,'checking iec no')
    let input = {
      iec_no : e.target.value
    }
    this.blockchainService.checkIecNo(input).subscribe(resp => {
      if(resp.body.status == 200){
        this.iecNo = e.target.value
        
        // localStorage.setItem('iec_value',e.target.value)
      } 
     
      else{

        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    },
    (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        location.reload();
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    })
  }

  checkAuthNo(e: any){
    console.log(e.target.value,'checking checkAuthNo')
    let input = {
      auth_number : e.target.value
    }
    this.blockchainService.checkAuthNo(input).subscribe(resp => {
      if(resp.body.status == 200){
        this.auth_number = e.target.value;
        // localStorage.setItem('auth_number',e.target.value)
      } else if(resp.body.status == -201){
        this.auth_number = "" ;
        this.registrationForm.patchValue({
          auth_number : ""
        }) ;
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
     
      else{
        this.showNotification(
          "bottom",
          "right",
          "success",
          resp.body.message,
          "announcement"
        );
      }
    }, (error) => {
      console.log(error)
      this.alertService.error(error);
      if(error.status == 401){
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Session Expired, Please Login Again",
          "announcement"
        );
        this.router.navigate(["/login"]);
      }
      else if(error.status == 500){
        setTimeout(() => {
          this.blockUI.stop();
        }, 1500);
        location.reload();
        this.showNotification(
          "bottom",
          "right",
          "success",
          "Please Try Again",
          "announcement"
        );
      }
      else{}
    })
  }

  save() {
    this.saveOrUpdate();
  }

  saveOrUpdate() {
    console.log("iecNo",this.iecNo)
    console.log("iecNoByForm",this.registrationForm.value.iecNo);
    console.log(this.selectedFile,'file 1')
    console.log(this.selectedFile,'file 2')
    var user_roles = this.currentUser.obj.role;
    this.auth_number = this.auth_number != undefined ? this.auth_number : this.registrationForm.value.auth_number;
    this.iecNo = this.iecNo != undefined ? this.iecNo : this.registrationForm.value.iecNo
    if(localStorage.getItem("application_id") != undefined || localStorage.getItem("application_id") != null){
       if(this.selectedFile==null || this.selectedFile == undefined){
        this.selectedFile = null
       }
       if(this.selectedFile1==null || this.selectedFile1 == undefined){
        this.selectedFile1 = null
       }
        let input = {
          aa_file: this.selectedFile1,
          iec_file: this.selectedFile,
          application_type_id: '1',
          application_type_name: this.registrationForm.value.applicationType,
          user_roles: user_roles,
          application_id: localStorage.getItem("application_id"),
          created_by: this.currentUser.obj.user_id,
        }
        console.log(input, 'input 2')
        this.auth_number = this.auth_number != undefined ? this.auth_number : this.registrationForm.value.auth_number
        localStorage.setItem('auth_number', this.auth_number);
        localStorage.setItem('iec_value', this.iecNo);
        this.blockchainService.updateApplication(input).subscribe({next:(resp:any) => {
          localStorage.setItem('application_id', input.application_id);
          if (resp.status == 200) {
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.message,
              "announcement"
            );
            if(this.isNext == true){
              this.router.navigate(["/iecDetails"])
            } else{
              
            }
          } else {
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              resp.message,
              "announcement"
            );
          }
        }, error:(error: any) => {
          console.log(error)
          this.alertService.error(error);
          if(error.status == 401){
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Session Expired, Please Login Again",
              "announcement"
            );
            // location.reload();
            this.router.navigate(["/login"]);
          }
          else if(error.status == 500){
            setTimeout(() => {
              this.blockUI.stop();
            }, 1500);
            this.showNotification(
              "bottom",
              "right",
              "success",
              "Please Try Again",
              "announcement"
            );
            // location.reload();
          }
          else{}
        }})
    }
    else if(this.registrationForm.value.applicationType != '' &&
    this.registrationForm.value.applicationType != null &&
    this.registrationForm.value.applicationType != undefined 
      ){
        if(this.selectedFile != null && this.selectedFile != undefined){

            if( this.selectedFile1 != null && this.selectedFile1 != undefined){

              if( this.auth_number != null &&  this.auth_number != undefined &&  this.auth_number != ''){

                if(this.iecNo != null &&  this.iecNo != undefined &&  this.iecNo != '' ){
  
                  // details filled
                  
        this.blockUI.start("Please wait this may take few seconds ...");
        localStorage.setItem('auth_number', this.auth_number);
        localStorage.setItem('iec_value', this.iecNo);
        if (localStorage.getItem("application_id") == undefined || localStorage.getItem("application_id") == null) {
          let input = {
            aa_file: this.selectedFile1,
            iec_file: this.selectedFile,
            application_type_id: '1',
            application_type_name: this.registrationForm.value.applicationType,
            user_roles: this.currentUser.obj.role,
            created_by: this.currentUser.obj.user_id,
          }
         
          this.blockchainService.saveApplication(input).subscribe({next:(resp: any) => {
            console.log(resp,'save appln resp')
            if (resp.status == 200) {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              localStorage.setItem('application_id', resp.obj);
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.message,
                "announcement"
              );
              if(this.isNext == true){
                this.router.navigate(["/iecDetails"])
              } else{

              }
            } else {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.message,
                "announcement"
              );
            }
          }, error:(error: any) => {
            console.log(error)
            this.alertService.error(error);
            if(error.status == 401){
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Session Expired, Please Login Again",
                "announcement"
              );
              // location.reload();
              this.router.navigate(["/login"]);
            }
            else if(error.status == 500){
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Try Again",
                "announcement"
              );
              // location.reload();
            }
            else{}
          }})
        } else {
          let input = {
            aa_file: this.selectedFile,
            iec_file: this.selectedFile1,
            application_type_id: '1',
            application_type_name: this.registrationForm.value.applicationType,
            user_roles: user_roles,
            application_id: localStorage.getItem("application_id"),
            created_by: this.currentUser.obj.user_id,
          }
          console.log(input, 'input 2')
          this.auth_number = this.auth_number != undefined ? this.auth_number : this.registrationForm.value.auth_number
          localStorage.setItem('auth_number', this.auth_number);
          localStorage.setItem('iec_value', this.iecNo); 
          this.blockchainService.updateApplication(input).subscribe({next:(resp: any) => {
            localStorage.setItem('application_id', input.application_id);
            if (resp.status == 200) {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.message,
                "announcement"
              );
              if(this.isNext == true){
                this.router.navigate(["/iecDetails"])
              } else{
                
              }
            } else {
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.showNotification(
                "bottom",
                "right",
                "success",
                resp.message,
                "announcement"
              );
            }
          }, error:(error: any) => {
            console.log(error)
            this.alertService.error(error);
            if(error.status == 401){
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Session Expired, Please Login Again",
                "announcement"
              );
              // location.reload();
              this.router.navigate(["/login"]);
            }
            else if(error.status == 500){
              setTimeout(() => {
                this.blockUI.stop();
              }, 1500);
              this.showNotification(
                "bottom",
                "right",
                "success",
                "Please Try Again",
                "announcement"
              );
              // location.reload();
            }
            else{}
          }})
        }
                } else{
                  //iec number
                  this.showNotification(
                    "bottom",
                    "right",
                    "success",
                   "Please Enter IEC Number",
                    "announcement"
                  );
                }
              }
              else{
                //auth number
                this.showNotification(
                  "bottom",
                  "right",
                  "success",
                 "Please Enter Authorization Number",
                  "announcement"
                );
              }
            } else{
              // selected file iec_file
              this.showNotification(
                "bottom",
                "right",
                "success",
               "Please Upload IEC Certificate",
                "announcement"
              );
            }
          
          }
          else{
            //selected file 1  aa_file
            this.showNotification(
              "bottom",
              "right",
              "success",
             "Please Upload Advanced Authorization",
              "announcement"
            );
          }


      } else{
                this.showNotification(
                "bottom",
                "right",
                "success",
               "Please Select Authorization Type",
                "announcement"
              );
      }
   
  }

  statusFn(e: string, status: any) {
    if (e == "NO") {
      this.isButtonDisabled = true;
    } else {
      this.isButtonDisabled = false;
    }
  }
  onFileSelected11() {
    let $img: any = document.querySelector('#file');
  
    if (typeof (FileReader) !== 'undefined') {
      let reader = new FileReader();
  
      reader.onload = (e: any) => {
        this.captionPdf = e.target.result;
      };
  
      reader.readAsArrayBuffer($img.files[0]);
    }
  }
  onFileSelected(event: any , action: string) {
    if (action == 'iec') {
      let $img: any = document.querySelector('#file');
      // var reader = new FileReader();
      this.selectedFile = <File>event.target.files[0];
      if(this.selectedFile.type == 'application/pdf'){
      this.selectedFileUrl1=true;
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFile); // read file as data url/
      reader.onload = (event:any) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      };
      reader.readAsArrayBuffer($img.files[0]);
      this.onUpload();
    }
    else{
      this.selectedFileUrl1=false;
      event.target.files[0].value='';
      $img.value=''
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    } else {
      let $img1: any = document.querySelector('#file1');
      // this.img1Value=$img1.value;
      var reader = new FileReader();
      this.selectedFile1 = <File>event.target.files[0];
      if(this.selectedFile1.type == 'application/pdf'){
        // this.img1Value=$img1.value;
        this.selectedFileUrl=true;
        console.log(this.img1Value);
        console.log(this.selectedFileUrl);
      var reader = new FileReader();
      reader.readAsDataURL(this.selectedFile1); // read file as data url/
      reader.onload = (event:any) => {
        // called once readAsDataURL is completed
        this.url1 = event.target.result;
      };
      reader.readAsArrayBuffer($img1.files[0]);
      this.onUpload1();
    }else{
      event.target.files[0].value='';
      console.log(this.selectedFile1);
      $img1.value='';
      this.selectedFileUrl=false;
      console.log(this.selectedFileUrl);
      this.img1Value=$img1.value;
      console.log(this.img1Value);
      console.log( $img1.value);
      this.showNotification(
        "bottom",
        "right",
        "success",
        "File Format Invalid",
        "announcement"
      );
    }
    }
  }

  //for iec  
  onUpload() {
    const fd = new FormData();
    this.captureFile = this.selectedFile.name
    fd.append("file", this.selectedFile, this.selectedFile.name);
  }

  //for aa  
  onUpload1() {
    const fd = new FormData();
    this.captureFile = this.selectedFile1.name
    fd.append("file", this.selectedFile1, this.selectedFile1.name);
  }


  back() {
    this.router.navigate(["/history"])
  }

  next() {
    this.saveOrUpdate();
    this.isNext = true;
  }
  openModalViewLetter(section: any) {
    // console.log(section.letter);
    this.sectionViewLetterDetails = section;
    // console.log(this.sectionViewLetterDetails)
    const buttonModal = document.getElementById("openModalButton");
    // buttonModal.click();
  } 
  openDialog(action: string, obj: any, element: any) {
    const dialogConfig = new MatDialogConfig();
    // obj.action = action;
    // obj.object_id = element;
    // dialogConfig.backdropClass = "bdrop";
    dialogConfig.panelClass = "dialog-responsive";
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = obj;
    dialogConfig.height = "500px";
    dialogConfig.width = "auto";

    let dialogRef:any;

    if (action == "paymentSum") {
      // dialogConfig.height = '500px';
      //   dialogConfig.width = '750px';
      dialogRef = this.dialog.open(PaymentsumComponent, dialogConfig);
    }

    dialogRef.afterClosed().subscribe((result: { event: string; isUpdated: boolean; }) => {
      console.log(result)
      if (result.event === "paymentSum" && result.isUpdated === true) {
        this.showNotification("bottom", "right", "success", "", "announcement");
      }
    });
  }

  showNotification(from: string, align: string, color: any, stringMessage: string, icons: string) {
    const type = ["", "info", "success", "warning", "danger"];

    $.notify(
      {
        icon: icons,
        message: stringMessage,
      },
      {
        type: type[color],
        timer: 4000,
        placement: {
          from: from,
          align: align,
        },
        template:
          '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert">' +
          '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
          '<i class="material-icons" data-notify="icon">notifications</i> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
          "</div>" +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          "</div>",
      }
    );
  }
}
